import { Readable } from "stream";

import { readableFromWeb } from "readable-from-web";

/**
 * Fetches a blob from the given URL.
 * @param url - The URL to fetch the blob from.
 * @returns A promise that resolves with the fetched blob.
 */
export async function fetchAssetBlob(url: URL | string): Promise<Blob> {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch asset: ${response.statusText}`);
  }
  return await response.blob();
}

export async function fetchAssetStream(url: URL | string): Promise<Readable> {
  const response = await fetch(url);
  if (!response.ok || !response.body) {
    throw new Error(`Failed to fetch asset: ${response.statusText}`);
  }
  return readableFromWeb(response.body);
}
