import { useCallback } from "react";

import { BackendServicesClient, useBackendServicesClient } from "~/context/BackendServicesContext";

import { useLatestRef } from "./helpers";

export type UsePollingFetchDetermineStatusResult<StatusResult, DoneResult> =
  | { type: "error"; error: Error }
  | { type: "pending"; status: StatusResult }
  | { type: "done"; result: DoneResult };

export interface UsePollingFetchOptions {
  // defaults to 100 attempts
  maxPollingAttempts?: number;
  // defaults to 1s
  pollingInterval?: number;
}

export interface FetchOptions {
  signal?: AbortSignal;
}

export function usePollingFetch<StatusResult, DoneResult = StatusResult, Args = void>(
  fetcher: (
    client: BackendServicesClient,
    args: Args,
    options: FetchOptions
  ) => Promise<StatusResult>,
  determineStatus: (
    status: StatusResult
  ) => UsePollingFetchDetermineStatusResult<StatusResult, DoneResult>,
  options: UsePollingFetchOptions = {}
) {
  const fetcherRef = useLatestRef(fetcher);
  const determineStatusRef = useLatestRef(determineStatus);
  const client = useBackendServicesClient();

  const { maxPollingAttempts = 100, pollingInterval = 1000 } = options;

  const trigger = useCallback(
    (args: Args, fetchOptions: FetchOptions = {}): Promise<DoneResult> => {
      return new Promise<DoneResult>((resolve, reject) => {
        const poll = async (attempt: number = 1) => {
          if (attempt > maxPollingAttempts) {
            reject(new Error("Max polling attempts exceeded"));
            return;
          }

          try {
            fetchOptions.signal?.throwIfAborted();

            const status = await fetcherRef.current(client, args, fetchOptions);
            const result = determineStatusRef.current(status);

            if (result.type === "done") {
              resolve(result.result);
            } else if (result.type === "pending") {
              setTimeout(() => poll(attempt + 1), pollingInterval);
            } else if (result.type === "error") {
              reject(result.error);
            }
          } catch (error) {
            reject(error);
          }
        };

        poll();
      });
    },
    [client, determineStatusRef, maxPollingAttempts, pollingInterval, fetcherRef]
  );

  return { trigger };
}
