import { AxiosError } from "axios";
import { useFeatureFlag } from "feature-flags";
import { useCallback, useRef, useState } from "react";

import { BackendRequestHeaders, useBackendServicesClient } from "~/context/BackendServicesContext";
import { createUpload, getUploadedFileInfo, uploadFile } from "~/services/UploadFile";
import type { CreateUploadOptions } from "~/services/UploadFile";
import type { GetUploadedFileUrlResponse } from "~/services/UploadFile/UploadFile.dto";

export enum SingleFileUploadStage {
  idle,
  uploading,
  finished,
  error,
  canceled,
}

export function useSingleFileUpload({
  onFinishedUploading,
  gcsUri,
}: {
  onFinishedUploading?: (res: GetUploadedFileUrlResponse) => void;
  gcsUri?: boolean;
} = {}) {
  const [totalSize, setTotalSize] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileId, setFileId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const nextFirstByte = useRef(0);
  const abortController = useRef(new AbortController());
  const [stage, setStage] = useState(SingleFileUploadStage.idle);
  const client = useBackendServicesClient();
  const [uploadedFile, setUploadedFile] = useState<GetUploadedFileUrlResponse | null>(null);
  const enableCreateMuxAsset = useFeatureFlag("enable_mux_create_asset");

  const handleError = (error: unknown, fileId?: string) => {
    if (abortController.current.signal.aborted) {
      setStage(SingleFileUploadStage.canceled);
    } else {
      setStage(SingleFileUploadStage.error);
      if (error instanceof AxiosError && error?.response?.data?.message) {
        setError(`FileId ${fileId}: ` + error?.response?.data?.message);
      } else {
        const serializedError = JSON.stringify(error, Object.getOwnPropertyNames(error));
        setError(`FileId ${fileId}: ` + (error instanceof Error ? error.message : serializedError));
      }
    }
  };

  const handleUploadProgress = useCallback((progress: number, currentLastByte: number) => {
    setUploadProgress(progress);
    nextFirstByte.current = currentLastByte;
  }, []);

  const doUpload = useCallback(
    async (file: File, fileId: string, headers?: BackendRequestHeaders) => {
      //abortController.current = new AbortController();
      try {
        const response = await uploadFile(client, file, fileId, {
          lastByteUploaded: nextFirstByte.current,
          onProgress: handleUploadProgress,
          signal: abortController.current.signal,
          headers,
        });

        if (response?.data?.lastByte) {
          nextFirstByte.current = response.data.lastByte;
          if (response.data.lastByte + 1 >= file.size) {
            try {
              const response = await getUploadedFileInfo(client, fileId!, {
                enableCreateMuxAsset,
                gcsUri,
                headers,
              });
              setUploadedFile(response);
              setStage(SingleFileUploadStage.finished);
              onFinishedUploading?.(response);
            } catch (error) {
              handleError(error, fileId);
            }
          } else {
            doUpload(file, fileId, headers).then();
          }
        }
      } catch (e) {
        handleError(e, fileId);
      }
    },
    [enableCreateMuxAsset, client, handleUploadProgress, onFinishedUploading]
  );

  const startUpload = useCallback(
    async (
      file: File,
      existingAbortController?: AbortController,
      options?: CreateUploadOptions
    ) => {
      abortController.current = existingAbortController ?? new AbortController();
      setTotalSize(0);
      setUploadProgress(0);
      setStage(SingleFileUploadStage.uploading);
      try {
        const { fileId } = await createUpload(client, file, {
          signal: abortController.current.signal,
          ...options,
        });
        setFileId(fileId);
        setTotalSize(file.size);
        setUploadProgress(0);
        nextFirstByte.current = 0;
        doUpload(file, fileId, options?.headers).then();
        return fileId;
      } catch (error) {
        handleError(error);
      }
    },
    [enableCreateMuxAsset, client, doUpload]
  );

  const cancelUpload = () => {
    if (abortController.current) {
      abortController.current.abort();
    }
  };

  const resetUpload = useCallback(() => {
    if (stage === SingleFileUploadStage.uploading) {
      throw new Error("Cannot reset an upload in progress!");
    }
    setFileId(null);
    setError(null);
    setTotalSize(0);
    setUploadProgress(0);
    setStage(SingleFileUploadStage.idle);
    setUploadedFile(null);
    nextFirstByte.current = 0;
  }, [stage]);

  return {
    totalSize,
    uploadProgress,
    fileId,
    error,
    stage,
    uploadedFile,
    cancelUpload,
    startUpload,
    resetUpload,
  };
}
