export default function CaptionsLogoSmall({ size = 40 }: { size?: number | string }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40 40H20.0091C16.0527 40.0018 12.1845 38.8299 8.89412 36.633C5.60375 34.436 3.03891 31.3127 1.52404 27.6578C0.00916196 24.003 -0.387515 19.9809 0.383903 16.1005C1.15532 12.22 3.06013 8.65541 5.85773 5.85782C8.65533 3.06023 12.2199 1.1552 16.1004 0.383788C19.9809 -0.387628 24.0029 0.00948894 27.6578 1.52436C31.3127 3.03923 34.4362 5.60384 36.6332 8.8942C38.8301 12.1846 40.0018 16.0525 40 20.0089V40ZM20.0091 8.13544C18.4229 8.09168 16.844 8.36634 15.3657 8.94314C13.8874 9.51994 12.5397 10.3872 11.4023 11.4937C10.2649 12.6001 9.36068 13.9236 8.74334 15.3854C8.12599 16.8472 7.80799 18.4177 7.80799 20.0045C7.80799 21.5913 8.12599 23.1619 8.74334 24.6237C9.36068 26.0855 10.2649 27.4089 11.4023 28.5154C12.5397 29.6218 13.8874 30.4891 15.3657 31.0659C16.844 31.6427 18.4229 31.9173 20.0091 31.8736H31.8736V20.0089C31.8712 16.8622 30.6208 13.8448 28.3965 11.6189C26.1723 9.39292 23.1559 8.14022 20.0091 8.13544Z"
        fill="currentColor"
      />
    </svg>
  );
}
