import localFont from "next/font/local";

const localFonts = localFont({
  src: [
    {
      path: "assets/60d0f0e183e378eae2749c52_Silka-Bold.otf",
      weight: "700",
      style: "normal",
    },
    {
      path: "assets/60d0f0e19a61236944f9b0ed_Silka-ExtraLight.otf",
      weight: "200",
      style: "normal",
    },
    {
      path: "assets/60d0f0e156ae92a8210f4550_Silka-Light.otf",
      weight: "300",
      style: "normal",
    },
    {
      path: "assets/60d0f0e1edf9b5275736c9c2_Silka-Black.otf",
      weight: "900",
      style: "normal",
    },
    {
      path: "assets/60d0f0e1ee81f120e8bd9436_Silka-Medium.otf",
      weight: "500",
      style: "normal",
    },
    {
      path: "assets/60d0f0e1932090372c458322_Silka-Regular.otf",
      weight: "400",
      style: "normal",
    },
    {
      path: "assets/60d0f0e14cbee3e7a81e0105_Silka-SemiBold.otf",
      weight: "600",
      style: "normal",
    },
    {
      path: "assets/60d0f0e1507a2759ba5128cd_Silka-Thin.otf",
      weight: "100",
      style: "normal",
    },
  ],
});

export default localFonts;

export const avenirNext = localFont({
  variable: "--font-avenir-next",
  src: [
    {
      path: "assets/AvenirNext-Regular-08.ttf",
      weight: "400",
      style: "normal",
    },
    {
      path: "assets/AvenirNext-Medium-06.ttf",
      weight: "500",
      style: "normal",
    },
    {
      path: "assets/AvenirNext-DemiBold-03.ttf",
      weight: "600",
      style: "normal",
    },
    {
      path: "assets/AvenirNext-Bold-01.ttf",
      weight: "700",
      style: "normal",
    },
  ],
  preload: true,
  fallback: ["Arial", "sans-serif"],
});

export const unboundedFont = localFont({
  variable: "--font-unbounded",
  src: "assets/Unbounded-VariableFont_wght.ttf",
  style: "normal",
  weight: "100 900",
  preload: true,
  fallback: ["Arial", "sans-serif"],
});
