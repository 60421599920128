import { z } from "zod";

import { makeBrandedId, makeBrandedIdSchema } from "~/utils/brand";

import { anySourceAssetDescriptionSchema } from "./source-asset";
import { anyTimelineBlockSchema } from "./timeline-block";

/**
 * 
 * how does Timeline Project work? we have an asset pool, and a base track blocks.
 * the blocks are an ordered list that represent the timeline a user will see and
 * they can reference anything in the asset pool in any way they want.
 * we will compile the asset pool and the base track blocks into a flattened
 * series of "clips" with explicit start and end times.
 *
  ┌────────────┐                                      
  │ asset pool │                                      
  └────────────┘                                      
  ┌───────────────────────────────┐                   
  │ ┌─────┐    ┌─────┐    ┌─────┐ │                   
  │ │vid 1│    │vid 2│    │img 1│ │                   
  │ └─────┘    └─────┘    └─────┘ │                   
  │                               │                   
  │      ┌─────┐    ┌───────┐     │                   
  │      │img 2│    │trans 1│     │                   
  │      └─────┘    └───────┘     │                   
  └───────────────────────────────┘                   
                                                      
                                                      
  ┌───────────────────┐                               
  │ base track blocks │                               
  └───────────────────┘                               
  ┌────────────────┐┌───────┐┌────────────┬─┬────────┐
  │shot 1          ││shot 2 ││shot 3      │*│shot 4  │
  │vid 1           ││vid 2  ││vid 1       │*│vid 1   │
  │trim: [0, 20]   ││[0, 10]││[20, 35]    │*│[35, 50]│
  └────────────────┘└───────┘└────────────┴─┴────────┘
*/

export const TimelineProject_Id = makeBrandedId("tm_proj");
export type TimelineProject_Id = ReturnType<typeof TimelineProject_Id>;

export const timelineProjectSchema = z.object({
  id: makeBrandedIdSchema(TimelineProject_Id),
  assetDescriptionPool: z.set(anySourceAssetDescriptionSchema),
  baseTrackBlocks: z.array(anyTimelineBlockSchema),
});

/**
 * the persisted type that represents a project built on the "relative" mindset.
 * currently, it will live alongside "classic projects" in the database which will
 * still keep the majority of the data related to a project.
 *
 * we can slowly move other data over when we want to build things on top of the
 * track + timeline "relative" model.
 */
export type TimelineProject = z.infer<typeof timelineProjectSchema>;
