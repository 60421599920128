export interface AnimationFontItem {
  familyName: string;
  fileName: string;
  url: URL;
}

export const animationFontItems: AnimationFontItem[] = [
  {
    familyName: "Impact Label",
    fileName: "ImpactLabel.ttf",
    url: new URL("./assets/ImpactLabel.ttf", import.meta.url),
  },
  {
    familyName: "Impact Label Fill",
    fileName: "ImpactLabel-Fill.ttf",
    url: new URL("./assets/ImpactLabel-Fill.ttf", import.meta.url),
  },
  {
    familyName: "Fredericka the Great",
    fileName: "FrederickatheGreat-Regular.ttf",
    url: new URL("./assets/FrederickatheGreat-Regular.ttf", import.meta.url),
  },
  {
    familyName: "Montserrat",
    fileName: "Montserrat-ExtraBold.ttf",
    url: new URL("./assets/Montserrat-ExtraBold.ttf", import.meta.url),
  },
  {
    familyName: "Instrument Sans",
    fileName: "InstrumentSans-SemiBold.ttf",
    url: new URL("https://captions-cdn.xyz/SmartVideo/Fonts/InstrumentSans-SemiBold.ttf"),
  },
  {
    familyName: "Pinyon Script",
    fileName: "PinyonScript-Regular.ttf",
    url: new URL("https://captions-cdn.xyz/SmartVideo/Fonts/PinyonScript-Regular.ttf"),
  },
];
