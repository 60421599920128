export interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export type SupportedImageSource =
  | HTMLOrSVGImageElement
  | HTMLVideoElement
  | HTMLCanvasElement
  | ImageBitmap
  | OffscreenCanvas
  | ImageData;

type SupportedTexImageSource =
  | HTMLImageElement
  | HTMLVideoElement
  | HTMLCanvasElement
  | OffscreenCanvas
  | ImageBitmap
  | VideoFrame;

function isSupportedTexImageSource(source: unknown): source is SupportedTexImageSource {
  return (
    source instanceof HTMLImageElement ||
    source instanceof HTMLVideoElement ||
    source instanceof HTMLCanvasElement ||
    source instanceof OffscreenCanvas ||
    source instanceof ImageBitmap ||
    source instanceof VideoFrame
  );
}

export function createCanvas(width: number, height: number) {
  if (typeof self.OffscreenCanvas !== "undefined") {
    return new OffscreenCanvas(width, height);
  } else if (typeof self.document !== "undefined") {
    const canvasElement = document.createElement("canvas");
    canvasElement.hidden = true;
    canvasElement.width = width;
    canvasElement.height = height;
    document.body.appendChild(canvasElement);
    return canvasElement;
  } else {
    throw new Error("No drawing canvas is available!");
  }
}

export function freeCanvas(canvas: OffscreenCanvas | HTMLCanvasElement) {
  if ("remove" in canvas) {
    canvas.remove();
  }
}

export function freeWebGLCanvas(canvas: OffscreenCanvas | HTMLCanvasElement | null) {
  if (!canvas) {
    return;
  }

  const gl = canvas.getContext("webgl") || canvas.getContext("webgl2");
  if (gl instanceof WebGLRenderingContext || gl instanceof WebGL2RenderingContext) {
    gl.getExtension("WEBGL_lose_context")?.loseContext();
  }

  if ("remove" in canvas) {
    canvas.remove();
  }
}

/**
 * Determines the size in pixels of a given image.
 *
 * @param image - the image or image data whose size needs to be determined.
 * @return the size of the given image in pixels.
 */
export function getImageSize(image: SupportedImageSource) {
  let width: number;
  let height: number;
  if (typeof image.width === "number" || typeof image.height === "number") {
    width = +image.width;
    height = +image.height;
  } else {
    image.width.baseVal.convertToSpecifiedUnits(SVGLength.SVG_LENGTHTYPE_PX);
    image.height.baseVal.convertToSpecifiedUnits(SVGLength.SVG_LENGTHTYPE_PX);
    width = image.width.baseVal.valueInSpecifiedUnits;
    height = image.height.baseVal.valueInSpecifiedUnits;
  }
  return { width, height };
}

/**
 * Gets the equivalent ImageData instance from an image source.
 *
 * @param image the image source.
 * @returns the ImageData instance.
 */
export function getImageData(image: SupportedImageSource) {
  if (image instanceof ImageData) {
    return image;
  } else if ("getContext" in image) {
    // Explicit type cast to workaround type misdetection
    const ctx = image.getContext("2d") as
      | OffscreenCanvasRenderingContext2D
      | CanvasRenderingContext2D
      | null;
    if (!ctx) {
      throw new Error("Failed to create canvas rendering context");
    }
    return ctx.getImageData(0, 0, image.width, image.height);
  } else {
    const { width, height } = getImageSize(image);
    const tempCanvas = createCanvas(width, height);
    try {
      // Explicit type cast to workaround type misdetection
      const ctx = tempCanvas.getContext("2d") as
        | CanvasRenderingContext2D
        | OffscreenCanvasRenderingContext2D
        | null;
      if (!ctx) {
        throw new Error("Failed to create canvas rendering context");
      }
      ctx.drawImage(image, 0, 0);
      return ctx.getImageData(0, 0, width, height);
    } finally {
      freeCanvas(tempCanvas);
    }
  }
}

/**
 * Encodes a given image as a binary PNG data buffer.
 *
 * @param image - the image to be encoded.
 * @return the raw PNG data.
 */
export async function encodeImageAsPNG(image: SupportedImageSource): Promise<ArrayBuffer> {
  let buff: ArrayBuffer;
  if ("convertToBlob" in image) {
    buff = await image.convertToBlob().then((blob) => blob.arrayBuffer());
  } else if ("toBlob" in image) {
    buff = await new Promise<ArrayBuffer>((resolve, reject) =>
      image.toBlob((blob) => {
        if (blob) {
          resolve(blob.arrayBuffer());
        } else {
          reject("Failed rendering PNG frame");
        }
      })
    );
  } else {
    const { width, height } = getImageSize(image);
    const tempCanvas = createCanvas(width, height);
    try {
      // Explicit type cast to workaround type misdetection
      const context = tempCanvas.getContext("2d") as
        | OffscreenCanvasRenderingContext2D
        | CanvasRenderingContext2D
        | null;
      if (!context) {
        throw new Error("Failed to create canvas rendering context");
      }
      if (image instanceof ImageData) {
        context.putImageData(image, 0, 0);
      } else {
        context.drawImage(image, 0, 0);
      }
      buff = await encodeImageAsPNG(tempCanvas);
    } finally {
      freeCanvas(tempCanvas);
    }
  }
  return buff;
}

function isImageLineTransparent(imageData: ImageData, line: number) {
  for (let column = 0; column <= imageData.width; column++) {
    if (imageData.data[(line * imageData.width + column) * 4 + 3] !== 0) {
      return false;
    }
  }
  return true;
}

function isImageColumnTransparent(
  imageData: ImageData,
  column: number,
  top: number,
  bottom: number
) {
  for (let line = top; line <= bottom; line++) {
    if (imageData.data[(line * imageData.width + column) * 4 + 3] !== 0) {
      return false;
    }
  }
  return true;
}

function isImageLineEqual(imageData1: ImageData, imageData2: ImageData, line: number) {
  const imBuff1 = new Uint32Array(imageData1.data.buffer, imageData1.data.byteOffset);
  const imBuff2 = new Uint32Array(imageData2.data.buffer, imageData2.data.byteOffset);
  let offset = line * imageData1.width;
  for (let column = 0; column <= imageData1.width; column++) {
    if (imBuff1[offset] !== imBuff2[offset]) {
      return false;
    }
    offset++;
  }
  return true;
}

function isImageColumnEqual(
  imageData1: ImageData,
  imageData2: ImageData,
  column: number,
  top: number,
  bottom: number
) {
  const imBuff1 = new Uint32Array(imageData1.data.buffer, imageData1.data.byteOffset);
  const imBuff2 = new Uint32Array(imageData2.data.buffer, imageData2.data.byteOffset);
  let offset = top * imageData1.width + column;
  for (let line = top; line <= bottom; line++) {
    if (imBuff1[offset] !== imBuff2[offset]) {
      return false;
    }
    offset += imageData1.width;
  }
  return true;
}

/**
 * Scans an image and returns the rectangular area in which all the
 * non-transparent pixels are contained.
 *
 * @param imageData - the image to be scanned.
 * @return the area where the pixels of the new image are not fully
 * transparent.
 */
export function getNonTransparentArea(imageData: ImageData): Rect {
  const { width, height } = imageData;
  if (width === 1 && height === 1) {
    return {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    };
  }
  let dx = 0;
  let dy = 0;
  let newRight: number = imageData.width - 1;
  let newBottom: number = imageData.height - 1;
  while (dy < imageData.height && isImageLineTransparent(imageData, dy)) {
    dy = dy + 1;
  }
  while (newBottom >= dy && isImageLineTransparent(imageData, newBottom)) {
    newBottom = newBottom - 1;
  }
  while (dx < imageData.width && isImageColumnTransparent(imageData, dx, dy, newBottom)) {
    dx = dx + 1;
  }
  while (newRight >= dx && isImageColumnTransparent(imageData, newRight, dy, newBottom)) {
    newRight = newRight - 1;
  }
  if (newRight < dx || newBottom < dy) {
    return {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    };
  }
  const newHeight = newBottom - dy + 1;
  const newWidth = newRight - dx + 1;
  return {
    x: dx,
    y: dy,
    width: newWidth,
    height: newHeight,
  };
}

/**
 * Scans two images and determines the rectangular area in which all the
 * differences are contained.
 *
 * @param imageData - the new image being scanned.
 * @param previousImageData - the previous image that will be replaced.
 * @return the area where the pixels of the new image do not match the ones
 * in the previous one.
 */
export function getDifferenceArea(imageData: ImageData, previousImageData: ImageData): Rect {
  if (
    imageData.width !== previousImageData.width &&
    imageData.height !== previousImageData.height
  ) {
    return {
      x: 0,
      y: 0,
      width: imageData.width,
      height: imageData.height,
    };
  }
  let dx = 0;
  let dy = 0;
  let newRight: number = imageData.width - 1;
  let newBottom: number = imageData.height - 1;
  while (dy < imageData.height && isImageLineEqual(imageData, previousImageData, dy)) {
    dy = dy + 1;
  }
  while (newBottom >= dy && isImageLineEqual(imageData, previousImageData, newBottom)) {
    newBottom = newBottom - 1;
  }
  while (
    dx < imageData.width &&
    isImageColumnEqual(imageData, previousImageData, dx, dy, newBottom)
  ) {
    dx = dx + 1;
  }
  while (
    newRight >= dx &&
    isImageColumnEqual(imageData, previousImageData, newRight, dy, newBottom)
  ) {
    newRight = newRight - 1;
  }
  if (newRight < dx || newBottom < dy) {
    return {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    };
  }
  const newHeight = newBottom - dy + 1;
  const newWidth = newRight - dx + 1;
  return {
    x: dx,
    y: dy,
    width: newWidth,
    height: newHeight,
  };
}

/**
 * Crops an image to a given square area.
 *
 * @param imageData - the original image data.
 * @param sx - the starting x point of the cropped image.
 * @param sy - the starting y point of the cropped image.
 * @param width - the width of the cropped image.
 * @param height - the height of the cropped image.
 * @return a new ImageData instance containing the cropped image.
 */
export function cropImage(
  imageData: ImageData,
  sx: number,
  sy: number,
  width: number,
  height: number
): ImageData {
  if (sx === 0 && sy === 0 && width === imageData.width && height === imageData.height) {
    return imageData;
  }
  if (width === 0 && height === 0) {
    return new ImageData(1, 1);
  }
  const newImageDataArray = new Uint8ClampedArray(height * width * 4);
  for (let y = sy; y < sy + height; y++) {
    const startOffset = (y * imageData.width + sx) * 4;
    const endOffset = startOffset + width * 4;
    const newImageSetOffset = (y - sy) * width * 4;
    newImageDataArray.set(imageData.data.slice(startOffset, endOffset), newImageSetOffset);
  }
  return new ImageData(newImageDataArray, width, height, {
    colorSpace: imageData.colorSpace,
  });
}

export function getVideoDimensions(
  source: TexImageSource | null,
  defaultWidth: number = 0,
  defaultHeight: number = 0
): { width: number; height: number } {
  try {
    if (!source) {
      return { width: defaultWidth, height: defaultHeight };
    }
    if (source instanceof HTMLImageElement) {
      return {
        width: source.naturalWidth || defaultWidth,
        height: source.naturalHeight || defaultHeight,
      };
    } else if (source instanceof HTMLVideoElement) {
      return {
        width: source.videoWidth || defaultWidth,
        height: source.videoHeight || defaultHeight,
      };
    } else if (source instanceof HTMLCanvasElement || source instanceof ImageBitmap) {
      return { width: source.width || defaultWidth, height: source.height || defaultHeight };
    } else if ("videoWidth" in source && "videoHeight" in source) {
      // For OffscreenVideo
      return {
        width: (source.videoWidth as number) || defaultWidth,
        height: (source.videoHeight as number) || defaultHeight,
      };
    } else {
      // If we can't determine the type or extract dimensions, return default values
      return { width: defaultWidth, height: defaultHeight };
    }
  } catch (error) {
    // If any error occurs during dimension extraction, return default values
    console.error("Error extracting dimensions:", error);
    return { width: defaultWidth, height: defaultHeight };
  }
}

/**
 * Converts a TexImageSource to an HTMLImageElement by drawing it onto a temporary canvas.
 *
 * @param {TexImageSource} source - The source to be converted, which can be an HTMLImageElement, HTMLVideoElement, HTMLCanvasElement, OffscreenCanvas, ImageBitmap, or VideoFrame.
 * @returns {Promise<HTMLImageElement>} A promise that resolves to an HTMLImageElement containing the drawn image.
 * @throws {Error} If the 2D context cannot be obtained or if the source type is unsupported.
 */
export async function texImageSourceToHtmlImage(source: TexImageSource): Promise<HTMLImageElement> {
  // Create a temporary canvas to draw the TexImageSource
  const canvas = document.createElement("canvas");

  // Handle different source types for dimensions
  if (source instanceof HTMLVideoElement) {
    canvas.width = source.videoWidth;
    canvas.height = source.videoHeight;
  } else if ("format" in source) {
    // source instanceof VideoFrame
    canvas.width = source.displayWidth;
    canvas.height = source.displayHeight;
  } else {
    canvas.width = source.width as number;
    canvas.height = source.height as number;
  }

  // Draw the source onto the canvas
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("Failed to get 2D context");
  }

  if (isSupportedTexImageSource(source)) {
    ctx.drawImage(source, 0, 0);
  } else {
    throw new Error("Unsupported TexImageSource type for drawing");
  }

  // Convert canvas to image
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = canvas.toDataURL("image/png");
  });
}
