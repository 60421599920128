import { createAssetMap } from "../../../utils/assetUtils";

export const OVERLAY_CINEMATIC_ASSETS = createAssetMap({
  folder: "ai-edit-assets/cinematic_assets",
  assets: {
    // https://captions-cdn.xyz/desktop-site/ai-edit-assets/cinematic_assets/overlay_cinematic_film_burn_1080p.tar
    overlay_cinematic_film_burn: {
      type: "tar-frames",
      resolution: "1080p",
    },
    // https://captions-cdn.xyz/desktop-site/ai-edit-assets/cinematic_assets/overlay_cinematic_light_leak_1080p.tar
    overlay_cinematic_light_leak: {
      type: "tar-frames",
      resolution: "1080p",
    },
    // https://captions-cdn.xyz/desktop-site/ai-edit-assets/cinematic_assets/overlay_cinematic_grain100_1080p.tar
    overlay_cinematic_grain100: {
      type: "tar-frames",
      resolution: "1080p",
      drawAs: "imageBitmap",
    },
  },
});

export const OVERLAY_PAPER_ASSETS = createAssetMap({
  folder: "ai-edit-assets/paper_assets",
  assets: {
    // https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/overlay_paper_crumpleframe_1A_1080p.tar
    overlay_paper_crumpleframe: {
      type: "tar-frames",
      variants: [
        "1A",
        "1B",
        "1C",
        "1D",
        "1E",
        "2A",
        "2B",
        "2C",
        "2D",
        "2E",
        "3A",
        "3B",
        "3C",
        "3D",
        "3E",
        "4A",
        "4B",
        "4C",
        "4D",
        "4E",
      ],
      resolution: "1080p",
      drawAs: "canvas",
    },
  },
});

export const OVERLAY_SCRAPBOOK_ASSETS = createAssetMap({
  folder: "ai-edit-assets/scrapbook_assets/frame_borders",
  assets: {
    // https://captions-cdn.xyz/desktop-site/ai-edit-assets/scrapbook_assets/frame_borders/marker_frame_arrow_pink_1080p.tar
    marker_frame_arrow: {
      type: "tar-frames",
      variants: ["pink"],
      resolution: "1080p",
      drawAs: "imageBitmap",
    },
  },
});
