import { z } from "zod";

// all of these schemas are partially copy-pasted from the backend,
// along with any schemas they depend on.
// TODO: move them to a separate package and share between backend and frontend

export type JobStatus = z.infer<typeof JobStatusSchema>;
export const JobStatusSchema = z.enum(["started", "progress", "processing", "error", "finished"]);

// submit request
export const SceneChangeDetectionRequestSchema = z.object({
  fileId: z.string(),
});
export type SceneChangeDetectionRequest = z.infer<typeof SceneChangeDetectionRequestSchema>;

// submit response
export const DtoRes_SubmitSceneChangeDetectionSchema = z.object({
  sceneDetectJobId: z.string(),
});
export type DtoRes_SubmitSceneChangeDetection = z.infer<
  typeof DtoRes_SubmitSceneChangeDetectionSchema
>;

// job request
// for now assume req is same as submit response
export const DtoReq_GetSceneChangeDetectionSchema = DtoRes_SubmitSceneChangeDetectionSchema;
export type DtoReq_GetSceneChangeDetection = z.infer<typeof DtoReq_GetSceneChangeDetectionSchema>;

// job response
export const DtoRes_GetSceneChangeDetectionSchema = z.object({
  status: JobStatusSchema,
  timestamps: z.number().array().optional().nullable(),
});
export type DtoRes_GetSceneChangeDetection = z.infer<typeof DtoRes_GetSceneChangeDetectionSchema>;
