import { nanoid } from "nanoid";
import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useAnalytics } from "~/hooks/useAnalytics";
import { project_creation_status } from "~/utils/analytics/ProductEvents";
import { getTranslationMode } from "~/utils/getTranslationMode";
import { VideoFileMetadata } from "~/utils/getVideoFileMetadata";
import { buildProjectsQueryKey } from "~/utils/query-keys";

import { queryClient } from "../QueryContext";

import {
  ProjectCreationAIAdsSettings,
  ProjectCreationAICreatorSettings,
  ProjectCreationProps,
  ProjectCreationSettings,
  ProjectCreationStage,
} from "./ProjectCreationContext.types";
import { ProjectCreationFromAIAd } from "./ProjectCreationFromAIAd";
import { ProjectCreationFromCreators } from "./ProjectCreationFromCreators";
import { ProjectCreationFromVideo } from "./ProjectCreationFromVideo";

interface ProjectCreationContextData {
  projectsToCreate: ProjectCreationProps[];
  startProjectCreation: (file: File, onError?: () => void) => string;
  startAIEditProjectCreation: (file: File, onError?: () => void) => string;
  startAiAdsProjectCreation: (genSettings: ProjectCreationAIAdsSettings) => string;
  startAiCreatorsProjectCreation: (genSettings: ProjectCreationAICreatorSettings) => string;
  finishProjectCreation: () => void;
  setProjectCreationSettings: (settings: ProjectCreationSettings, projectId?: string) => void;
  cancelProjectCreation: (projectId?: string, isModalClose?: boolean) => void;
  currentVideoFileMetadata?: VideoFileMetadata;
  windowTitle: string;
  currentProjectId: string;
}

export const ProjectCreationContext = createContext<ProjectCreationContextData>({
  projectsToCreate: [] as ProjectCreationProps[],
  startProjectCreation: () => "",
  startAIEditProjectCreation: () => "",
  startAiAdsProjectCreation: () => "",
  startAiCreatorsProjectCreation: () => "",
  finishProjectCreation: () => {},
  setProjectCreationSettings: () => {},
  cancelProjectCreation: () => {},
  windowTitle: "",
  currentProjectId: "",
});

export function ProjectCreationProvider({ children, ...props }: PropsWithChildren) {
  const [projectsToCreate, setProjectsToCreate] = useState<ProjectCreationProps[]>([]);
  const [currentProjectId, setCurrentProjectId] = useState<string>("");
  const [currentVideoFileMetadata, setCurrentVideoFileMetadata] = useState<
    VideoFileMetadata | undefined
  >();
  const analytics = useAnalytics();

  useEffect(() => {
    const proj = projectsToCreate.find((p) => p.projectId === currentProjectId);
    if (proj) {
      setCurrentVideoFileMetadata(proj.videoFileMetadata ?? undefined);
    }
  }, [currentProjectId, projectsToCreate]);

  // this allows the child project components to set mutations up to this component
  // maybe this is better done as a ref
  const setProjectAttributesUp = useCallback(
    (
      projectId: string,
      videoFileMetadata: VideoFileMetadata | null,
      creationStage: ProjectCreationStage,
      progress: number,
      thumbnailPath?: string,
      estimatedTime?: number
    ) => {
      setProjectsToCreate((prev: ProjectCreationProps[]) => {
        const proj = prev.find((p) => p.projectId === projectId);

        // do nothing if project wasn't found
        if (!proj) {
          return prev;
        }

        proj.videoFileMetadata = videoFileMetadata;
        proj.creationStage = creationStage;
        proj.progress = progress;
        proj.thumbnailPath = proj.thumbnailPath || thumbnailPath;
        proj.estimatedTime = estimatedTime;

        // if stage is finished we remove it
        if (proj.creationStage === "finished") {
          queryClient.invalidateQueries({ queryKey: buildProjectsQueryKey() });
          return prev.filter((p) => p.projectId !== projectId);
        }

        // if latest update latest
        if (proj.projectId === currentProjectId) {
          setCurrentVideoFileMetadata(videoFileMetadata ?? undefined);
        }

        return [...prev];
      });
    },
    []
  );

  const startProjectCreation = useCallback((file: File, onError = () => {}) => {
    // append new project to create
    const projectIdToCreate = nanoid();
    setProjectsToCreate((prev: ProjectCreationProps[]) => {
      const newProjectToCreate: ProjectCreationProps = {
        projectId: projectIdToCreate,
        file,
        onError,
        videoFileMetadata: null,
        creationStage: "start" as ProjectCreationStage,
        progress: 0,
        projectType: "video",
      };
      return [...prev, newProjectToCreate];
    });
    setCurrentProjectId(projectIdToCreate);
    return projectIdToCreate;
  }, []);

  const startAIEditProjectCreation = useCallback((file: File, onError = () => {}) => {
    // append new project to create
    const projectIdToCreate = nanoid();
    setProjectsToCreate((prev: ProjectCreationProps[]) => {
      const newProjectToCreate: ProjectCreationProps = {
        projectId: projectIdToCreate,
        file,
        onError,
        videoFileMetadata: null,
        creationStage: "start" as ProjectCreationStage,
        progress: 0,
        projectType: "ai-edit",
      };
      return [...prev, newProjectToCreate];
    });
    setCurrentProjectId(projectIdToCreate);
    return projectIdToCreate;
  }, []);

  const startAiAdsProjectCreation = useCallback((genSettings: ProjectCreationAIAdsSettings) => {
    // append new project to create
    const projectIdToCreate = nanoid();
    setProjectsToCreate((prev: ProjectCreationProps[]) => {
      const newProjectToCreate = {
        projectId: projectIdToCreate,
        file: new File([], "foo"), // placeholder for types
        onError: () => {},
        settings: {
          spokenLanguageCode: "foo",
          translationLanguageCode: "foo",
          aiAds: genSettings,
        },
        videoFileMetadata: null,
        creationStage: "start" as ProjectCreationStage,
        progress: 0,
        thumbnailPath: genSettings.avatarThumbnailUrl ?? undefined,
        title: genSettings.title,
      };
      return [...prev, newProjectToCreate];
    });
    setCurrentProjectId(projectIdToCreate);
    return projectIdToCreate;
  }, []);

  const startAiCreatorsProjectCreation = (genSettings: ProjectCreationAICreatorSettings) => {
    // append new project to create
    const projectIdToCreate = nanoid();
    setProjectsToCreate((prev: ProjectCreationProps[]) => {
      const newProjectToCreate = {
        projectId: projectIdToCreate,
        file: new File([], "foo"), // placeholder for types
        onError: () => {},
        settings: {
          spokenLanguageCode: "foo",
          translationLanguageCode: "foo",
          aiCreators: genSettings,
        },
        videoFileMetadata: null,
        creationStage: "start" as ProjectCreationStage,
        progress: 0,
        thumbnailPath: genSettings.avatarThumbnailUrl ?? undefined,
        title: genSettings.title,
      };
      return [...prev, newProjectToCreate];
    });
    setCurrentProjectId(projectIdToCreate);
    return projectIdToCreate;
  };

  const setProjectCreationSettings = useCallback(
    (settings: ProjectCreationSettings, projectId?: string) => {
      const pId = projectId ?? currentProjectId;

      setProjectsToCreate((prev: ProjectCreationProps[]) => {
        const project = projectsToCreate.find((proj) => proj.projectId === pId);
        if (project) {
          project.settings = settings;
        }
        return [...prev];
      });
    },
    [projectsToCreate, currentProjectId]
  );

  // cancel process for given projectId
  const cancelProjectCreation = useCallback(
    (projectId?: string, isModalClose?: boolean) => {
      const pId = projectId ?? currentProjectId;

      // removing from projects list should unmount component and cancel creation
      setProjectsToCreate((prev: ProjectCreationProps[]) => {
        return prev.filter((proj) => proj.projectId !== pId);
      });

      const cancelledProject = projectsToCreate.find((proj) => proj.projectId === pId);

      if (isModalClose) {
        analytics.track(
          ...project_creation_status({
            status: "close",
            project_id: projectId || "",
            video_duration_seconds: currentVideoFileMetadata?.duration ?? 0,
          })
        );
      } else {
        const translationMode = getTranslationMode(cancelledProject?.settings);

        analytics.track(
          ...project_creation_status({
            status: "cancel",
            project_id: projectId || "",
            translation_mode: translationMode,
            video_duration_seconds: currentVideoFileMetadata?.duration ?? 0,
          })
        );
      }
    },
    [currentProjectId]
  );

  // unsets meta for project that's currently being created
  const finishProjectCreation = useCallback(() => {
    setCurrentProjectId("");
    setCurrentVideoFileMetadata(undefined);
  }, []);

  // alert browser before close if there are still projects to create
  useEffect(() => {
    const handleBeforeunload = (event: BeforeUnloadEvent) => {
      if (projectsToCreate.length >= 1) {
        event.preventDefault();
      }
    };
    window.addEventListener("beforeunload", handleBeforeunload);
    return () => window.removeEventListener("beforeunload", handleBeforeunload);
  }, [projectsToCreate.length]);

  const windowTitle = useMemo(() => {
    const numProjects = projectsToCreate.length;
    if (numProjects === 0) {
      return "";
    }

    if (numProjects === 1) {
      const soloProject = projectsToCreate[0];
      return `${soloProject.progress.toFixed(0)}% · Creating Project · ${
        soloProject.videoFileMetadata?.name ?? ""
      }`;
    }
    const sumProgress = projectsToCreate.reduce((acc, project) => acc + project.progress, 0);
    return `${(sumProgress / numProjects).toFixed(0)}%  · Creating ${numProjects} Projects`;
  }, [projectsToCreate]);
  return (
    <ProjectCreationContext.Provider
      value={{
        projectsToCreate,
        startProjectCreation,
        startAIEditProjectCreation,
        startAiAdsProjectCreation,
        startAiCreatorsProjectCreation,
        finishProjectCreation,
        setProjectCreationSettings,
        cancelProjectCreation,
        currentVideoFileMetadata,
        windowTitle,
        currentProjectId,
      }}
      {...props}
    >
      {children}
      {projectsToCreate.map((proj) => {
        // existence of ai creators used to signal this is an ai ad project to create
        if (proj.settings?.aiCreators) {
          return (
            <ProjectCreationFromCreators
              key={proj.projectId}
              {...proj}
              setProjectAttributesUp={setProjectAttributesUp}
            />
          );
        }

        // existence of ai ads used to signal this is an ai ad project to create
        if (proj.settings?.aiAds) {
          return (
            <ProjectCreationFromAIAd
              key={proj.projectId}
              {...proj}
              setProjectAttributesUp={setProjectAttributesUp}
            />
          );
        }

        // else normal video creation
        return (
          <ProjectCreationFromVideo
            key={proj.projectId}
            {...proj}
            setProjectAttributesUp={setProjectAttributesUp}
          />
        );
      })}
    </ProjectCreationContext.Provider>
  );
}
