import { useFeatureFlag } from "feature-flags";
import { I18nProvider } from "i18n/I18nProvider";
import { PropsWithChildren } from "react";
import { I18nProvider as AriaI18nProvider } from "react-aria-components";

import { useLocalStorage } from "~/hooks/useLocalStorage";

export function AppI18nProvider({ children }: PropsWithChildren) {
  const i18nEnabled = useFeatureFlag("enable_i18n");
  const [initialLanguage, setInitialLanguage] = useLocalStorage<string | null>(
    "user_locale_language",
    null
  );

  return (
    <I18nProvider
      initialLanguage={initialLanguage}
      i18nEnabled={i18nEnabled}
      onLanguageChange={setInitialLanguage}
    >
      <AriaI18nProvider locale={initialLanguage || "en"}>{children}</AriaI18nProvider>
    </I18nProvider>
  );
}
