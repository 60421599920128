import { t } from "i18next";

import { AvailableLanguageOption } from "../services/AvailableTranscriptLanguages";

/**
 * Get the localized name of a language.
 * @param language - The language to get the localized name for.
 * @returns The localized name of the language.
 */
export function getLocalizedLanguageName(language: AvailableLanguageOption): string {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const localizedName = t(`languages:${language.code}`) as string | undefined;
  // Fallback to English display name if localized name is not available
  return localizedName || language.englishDisplayName || language.code;
}

export function getLocalizedLanguageLabel(language: AvailableLanguageOption): string {
  const localizedName = getLocalizedLanguageName(language);
  if (!localizedName) {
    return language.display;
  }
  return `${language.display} (${localizedName})`;
}
