import { CommandList } from "../../CommandList.types";
import { getPrimarySceneSegments } from "../../utils/genPrimarySceneSegments";

import { EffectsCommandGeneratorFunction } from "./types";

export const generateZoomCommandsForScene: EffectsCommandGeneratorFunction = (
  scene,
  _,
  { faceData }
) => {
  if (!scene.style.zoomStyle) {
    return null;
  }

  const faceCoords = faceData?.getFacesAtSpan(
    scene.mainOffset,
    scene.mainOffset + scene.endTime - scene.startTime
  )?.[0];

  const faceFocal = faceCoords
    ? { x: faceCoords.x + faceCoords.w / 2, y: faceCoords.y + faceCoords.h / 2 }
    : undefined;

  const segments = getPrimarySceneSegments(scene);

  const commands: CommandList = [
    {
      edl: { segments },
      renderEffect: {
        renderEffectType: "zoom",
        zoomStyle: scene.style.zoomStyle,
        focalPoint: faceFocal,
      },
    },
  ];

  if (scene.style.zoomStyle === "camera-shake") {
    commands.push({
      edl: { segments },
      renderEffect: {
        renderEffectType: "shake",
      },
    });
  }

  return commands;
};
