import { getDefaultSizeFactor } from "../../project/utils/getDefaultSizeFactor";
import { AIVideoMetadata } from "../AIScenes.types";

export const IMAGE_SIZES = {
  small: 0.33,
  medium: 0.5,
  large: 0.9,
};

type AIEditImageSize = keyof typeof IMAGE_SIZES;

export function getImageScale(
  size: AIEditImageSize | undefined,
  videoMetadata: AIVideoMetadata,
  image: ImageBitmap | HTMLImageElement
) {
  if (!size || !IMAGE_SIZES[size]) {
    return getDefaultSizeFactor(videoMetadata.width, videoMetadata.height);
  }

  const sizePercentageWidth = IMAGE_SIZES[size];
  const desiredImageWidth = videoMetadata.width * sizePercentageWidth;
  const desiredImageHeight = videoMetadata.height * sizePercentageWidth;
  const resizedScale = Math.min(desiredImageHeight / image.height, desiredImageWidth / image.width);

  return resizedScale;
}
