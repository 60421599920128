import { Effects, Project } from "~/database/database.types";

import { TimelineProject, TimelineProject_Id } from "../types/project";
import { SourceAsset_Id } from "../types/source-asset";
import { createSourceAssetDescription } from "../types/source-asset";

import { convertProjectClipsToTimelineBlocks } from "./convert-project-clips";

export const timelineProjectIdForClassicProject = (project: Project) =>
  TimelineProject_Id(project.id);

export function convertClassicProjectToTimelineProject(
  project: Project,
  effects: Effects | null
): TimelineProject {
  const sourceVideo = createSourceAssetDescription("video", {
    id: SourceAsset_Id("imported_main_video"),
    fileId: project.sourceFileId,
    videoMetadata: project.sourceVideoMetadata,
  });

  const blocks = convertProjectClipsToTimelineBlocks(sourceVideo, effects?.clips ?? []);

  return {
    id: timelineProjectIdForClassicProject(project),
    assetDescriptionPool: new Set([sourceVideo]),
    baseTrackBlocks: blocks,
  };
}
