export function getPainterModuleScript() {
  const rawScript = `function smoothRoundRect(context, x, y, width, height, radius, smoothFactor) {
  if (width < 0) {
    x += width;
    width = -width;
  }
  if (height < 0) {
    y += height;
    height = -height;
  }
  const radiusH = Math.min(width / 2, radius);
  const radiusV = Math.min(height / 2, radius);
  const segLengthH = Math.min(width / 2, (1 + smoothFactor) * radiusH);
  const segLengthV = Math.min(height / 2, (1 + smoothFactor) * radiusV);
  const dH = radiusH - radiusH / Math.sqrt(2);
  const cH = dH;
  const dV = radiusV - radiusV / Math.sqrt(2);
  const cV = dV;
  const bH = (segLengthH - dH - cH) / 3;
  const aH = bH * 2;
  const bV = (segLengthV - dV - cV) / 3;
  const aV = bV * 2;

  context.moveTo(x + width / 2, y + height);
  context.lineTo(x + width - segLengthH, y + height); // P1
  context.bezierCurveTo(
    x + width - segLengthH + aH,
    y + height, // P2
    x + width - segLengthH + aH + bH,
    y + height, // P3
    x + width - segLengthH + aH + bH + cH,
    y + height - dV // P4
  );
  context.bezierCurveTo(
    x + width,
    y + height - segLengthV + aV + bV, // P3
    x + width,
    y + height - segLengthV + aV, // P2
    x + width,
    y + height - segLengthV // P1
  );
  context.lineTo(x + width, y + segLengthV); // P1
  context.bezierCurveTo(
    x + width,
    y + segLengthV - aV, // P2
    x + width,
    y + segLengthV - aV - bV, // P3
    x + width - dH,
    y + segLengthV - aV - bV - cV // P4
  );
  context.bezierCurveTo(
    x + width - segLengthH + aH + bH,
    y, // P3
    x + width - segLengthH + aH,
    y, // P2
    x + width - segLengthH,
    y // P1
  );
  context.lineTo(x + segLengthH, y); // P1
  context.bezierCurveTo(
    x + segLengthH - aH,
    y, // P2
    x + segLengthH - aH - bH,
    y, // P3
    x + segLengthH - aH - bH - cH,
    y + dV // P4
  );
  context.bezierCurveTo(
    x,
    y + segLengthV - aV - bV, // P3
    x,
    y + segLengthV - aV, // P2
    x,
    y + segLengthV // P1
  );
  context.lineTo(x, y + height - segLengthV); // P1
  context.bezierCurveTo(
    x,
    y + height - segLengthV + aV, // P2
    x,
    y + height - segLengthV + aV + bV, // P3
    x + dH,
    y + height - segLengthV + aV + bV + cV // P4
  );
  context.bezierCurveTo(
    x + segLengthH - aH - bH,
    y + height, // P3
    x + segLengthH - aH,
    y + height, // P2
    x + segLengthH,
    y + height // P1
  );
  context.closePath();
}

class SmoothRoundRectPainter {
  static get contextOptions() {
    return { alpha: true };
  }

  static get inputProperties() {
    return ["--smooth-factor", "--smooth-border-radius"];
  }

  paint(ctx, geom, properties) {
    const radius = parseInt(properties.get("--smooth-border-radius")?.toString()) || 0;
    const smoothFactor = (+properties.get("--smooth-factor")?.toString()) || 0;
    const width = geom.width;
    const height = geom.height;
    ctx.fillStyle = "#000";
    ctx.beginPath();
    smoothRoundRect(ctx, 0, 0, width, height, radius, smoothFactor);
    ctx.fill();
  }
}

class SmoothRoundRectBorderPainter {
  static get contextOptions() {
    return { alpha: true };
  }

  static get inputProperties() {
    return ["--smooth-factor", "--smooth-border-radius", "--smooth-border-color", "--smooth-border-width"];
  }

  paint(ctx, geom, properties) {
    const radius = parseInt(properties.get("--smooth-border-radius")?.toString()) || 0;
    const thickness = parseInt(properties.get("--smooth-border-width")?.toString()) || 0;
    const smoothFactor = (+properties.get("--smooth-factor")?.toString()) || 0;
    const color = properties.get("--smooth-border-color")?.toString() || "black";
    if (thickness === 0) {
      return;
    }
    const width = geom.width;
    const height = geom.height;
    ctx.strokeStyle = color;
    // Multiplies by 2 because Canvas's stroke width is centered on the path
    ctx.lineWidth = thickness * 2;
    ctx.beginPath();
    smoothRoundRect(ctx, 0, 0, width, height, radius, smoothFactor);
    ctx.clip();
    ctx.stroke();
  }
}

// Register our class under a specific name
registerPaint("smooth-round-rect", SmoothRoundRectPainter);
registerPaint("smooth-round-rect-border", SmoothRoundRectBorderPainter);
`;
  return `data:application/javascript;charset=utf8,${encodeURIComponent(rawScript)}`;
}
