import { Readable } from "stream";

import tar from "tar-stream";
import { z } from "zod";

import { StoredImageFrame, StoredUnpackedVideoAsset } from "~/database/database.types";

import { FixedPackedAsset } from "./assetUtils.types";
import { fetchAssetStream } from "./common";

const FrameSequencePlaceholderSchema = z.object({
  bounds: z.object({
    left: z.number(),
    top: z.number(),
    right: z.number(),
    bottom: z.number(),
  }),
  transform: z.object({
    scaleX: z.number(),
    scaleY: z.number(),
    skewY: z.number(),
    skewX: z.number(),
    translationX: z.number(),
    translationY: z.number(),
    rotation: z.number().default(0),
  }),
});

const FrameSequenceMetadataSchema = z.object({
  placeholders: z.array(FrameSequencePlaceholderSchema).optional(),
  placeholdersPerFrame: z.array(z.array(FrameSequencePlaceholderSchema)).optional(),
});

const FrameSequenceFrameSchema = z.object({
  image: z.string(),
  timestamp: z.number(),
});

const FrameSequenceInfoSchema = z.object({
  width: z.number(),
  height: z.number(),
  duration: z.number(),
  frames: z.array(FrameSequenceFrameSchema).nonempty(),
  metadata: FrameSequenceMetadataSchema.optional().default({}),
});

/**
 * The schema for the `info.json` file in a frame sequence asset.
 *
 * @remarks The schema is defined by {@link FrameSequenceInfoSchema}.
 * @property {number} width - The width of the frames in the sequence.
 * @property {number} height - The height of the frames in the sequence.
 * @property {number} duration - The duration of the sequence in seconds.
 * @property {Object[]} frames - An array of frames in the sequence.
 * @property {Object} [metadata] - Additional metadata about the sequence.
 */
type FrameSequenceInfo = z.infer<typeof FrameSequenceInfoSchema>;

async function getBlobFromStream(stream: Readable): Promise<Blob> {
  const chunks: BlobPart[] = [];
  for await (const chunk of stream) {
    chunks.push(chunk);
  }
  return new Blob(chunks);
}

/**
 * Load a frame sequence asset from a tar file.
 *
 * Frame sequence assets are simple TAR files containing a series of images and a JSON file
 * containing metadata about the sequence called `info.json`.
 *
 * @param id - The ID of the asset.
 * @param asset - The FixedAsset containing the URL of the tar file.
 * @remarks The tar file must contain a file called `info.json` at the root of the archive obeying
 * the schema defined by {@link FrameSequenceInfo }.
 */
export async function loadTarFrameSequenceAsset(
  id: string,
  asset: FixedPackedAsset
): Promise<StoredUnpackedVideoAsset & { drawAs?: FixedPackedAsset["drawAs"] }> {
  const assetUrl = asset.url;
  const input = await fetchAssetStream(assetUrl);
  const extractStream = tar.extract();
  input.pipe(extractStream);
  const loadedFrames = new Map<string, Blob>();
  let info: FrameSequenceInfo | null = null;
  for await (const entry of extractStream) {
    if (entry.header.type !== "file") {
      entry.resume();
      continue;
    }
    const contents = await getBlobFromStream(entry);
    if (entry.header.name === "info.json") {
      info = FrameSequenceInfoSchema.parse(JSON.parse(await contents.text()));
    } else {
      loadedFrames.set(entry.header.name, contents);
    }
    entry.resume(); // the entry is the stream also
  }
  if (!info) {
    throw new Error("No info.json found in frame sequence file!");
  }
  const frames: StoredImageFrame[] = [];
  for (const frame of info.frames) {
    const imageBlob = loadedFrames.get(frame.image);
    if (!imageBlob) {
      throw new Error(`Frame ${frame.image} not found in frame sequence file!`);
    }
    frames.push({
      timestamp: frame.timestamp,
      blob: imageBlob,
    });
  }
  return {
    id,
    name: id,
    duration: info.duration,
    frames,
    type: "unpacked-video",
    metadata: {
      width: info.width,
      height: info.height,
      placeholders: info.metadata.placeholders,
      placeholdersPerFrame: info.metadata.placeholdersPerFrame,
    },
    drawAs: asset.drawAs,
  };
}
