import { AnyCanvasRenderingContext2D } from "./captionDrawing.types";
import { Color, Glow, Gradient, Shadow } from "./captionStyle.types";

/**
 * HTML5 Canvas shadow blur factor. Used to convert from a shadow radius in pixels
 * to a shadow blur level. Equivalence tested on Firefox, Safari and Chromium.
 */
const SHADOW_BLUR_FACTOR = 2;

export function getGlowStyle(glow: Glow | null, textColor: Color) {
  if (!glow?.enabled) {
    return {
      shadowBlur: 0,
      shadowColor: "transparent",
      shadowOffsetX: 0,
      shadowOffsetY: 0,
    };
  }
  return {
    shadowBlur: glow.radius * SHADOW_BLUR_FACTOR,
    shadowColor: getColorStyle({
      ...textColor,
      alpha: glow.opacity * textColor.alpha,
    }),
    shadowOffsetX: glow.offset.x,
    shadowOffsetY: glow.offset.y,
  };
}

export function getShadowStyle(shadow?: Shadow | null) {
  return {
    shadowBlur: (shadow?.radius ?? 0) * SHADOW_BLUR_FACTOR,
    shadowColor: shadow ? getColorStyle(shadow.color) : "transparent",
    shadowOffsetX: shadow?.offset?.x ?? 0,
    shadowOffsetY: shadow?.offset?.y ?? 0,
  };
}

export function getColorStyle(color: Color) {
  if (color.alpha === 0) {
    return "transparent";
  }
  return `rgba(${Math.round(color.red * 255)}, ${Math.round(color.green * 255)}, ${Math.round(
    color.blue * 255
  )}, ${color.alpha})`;
}

export function applyShadowStyle(ctx: AnyCanvasRenderingContext2D, shadow: Shadow | null) {
  Object.assign(ctx, getShadowStyle(shadow));
}

/**
 * Creates a linear gradient style for a canvas context based on the provided gradient configuration.
 * The gradient is defined by start and end positions as well as two colors to transition between.
 *
 * @param {AnyCanvasRenderingContext2D} ctx - The canvas rendering context on which to apply the gradient.
 * @param {Gradient} gradient - The gradient definition, including start and end positions and colors.
 * @param {{ x: number; y: number; width: number; height: number }} gradientBounds - The bounding box for the gradient, used to determine the area on which the gradient transitions between two colors.
 * @returns {CanvasGradient} The created linear gradient to be used as a fill or stroke style.
 */
export function getGradientStyle(
  ctx: AnyCanvasRenderingContext2D,
  gradient: Gradient,
  gradientBounds: { x: number; y: number; width: number; height: number }
): CanvasGradient {
  const { startPosition, endPosition, firstColor, secondColor } = gradient;
  // Use the provided (normalized) gradientBounds to calculate start and end position of the gradient in canvas space
  // FIXME: the x + y are flipped here, to make the gradient match the way the gradient defintion is interepreted on iOS
  // This is: [x0,y0,x1,y1]
  const coords: [number, number, number, number] = [
    gradientBounds.y + startPosition.y * gradientBounds.width,
    gradientBounds.x + startPosition.x * gradientBounds.height,
    gradientBounds.y + endPosition.y * gradientBounds.width,
    gradientBounds.x + endPosition.x * gradientBounds.width,
  ];
  const gradientFillStyle = ctx.createLinearGradient(...coords);
  gradientFillStyle.addColorStop(0, getColorStyle(firstColor));
  gradientFillStyle.addColorStop(0.5, getColorStyle(secondColor));
  return gradientFillStyle;
}
