import { useEffect } from "react";

import { useKeyboardShortcutManager } from "~/context";

/**
 * Blocks keyboard shortcuts from being triggered.
 *
 * @param shouldBlock - Whether to block keyboard shortcuts or not.
 * @see useKeyboardShortcut
 * @example
 * function MyModal({ open }) {
 *   useKeyboardShortcutBlocker(open);
 *
 *   return (
 *     <Modal open={open}>
 *       <p>Modal content</p>
 *     </Modal>
 *   );
 * }
 */
export function useKeyboardShortcutBlocker(shouldBlock: boolean) {
  const { blockShortcuts, unblockShortcuts } = useKeyboardShortcutManager();

  useEffect(() => {
    if (!shouldBlock) {
      return;
    }
    const block = blockShortcuts();
    return () => unblockShortcuts(block);
  }, [blockShortcuts, unblockShortcuts, shouldBlock]);
}
