import { EffectsCommandGeneratorFunction } from "./types";

export const generateCaptionCommandsForScene: EffectsCommandGeneratorFunction = (
  scene,
  sceneIndex,
  { style, faceData, videoMetadata }
) => {
  const assetId = `captions-scene-${sceneIndex}`;

  const positionFactor = { x: 0.5, y: 0.5 };

  if (scene.style.capsPosition === "bottom-half") {
    // If the caption position is set to "bottom-half", set positionY to 1/6
    positionFactor.y = 1 / 6;
  } else if (scene.style.capsPosition === "top-half") {
    // If the caption position is set to "top-half", set positionY to 5/6
    positionFactor.y = 5 / 6;
  } else if (scene.style.capsPosition === "avoid-head") {
    // If the caption position is set to "avoid-head", adjust positionY based on face position
    const sceneRect = faceData?.getSceneMaxRect(scene.startTime, scene.endTime, true);

    if (sceneRect) {
      const faceStart = sceneRect.y;
      const faceEnd = sceneRect.y + sceneRect.h;

      const spaceAbove = faceStart;
      const spaceBelow = videoMetadata.height - faceEnd;

      // Determine the positionY based on the space above and below the face
      if (spaceBelow > spaceAbove) {
        positionFactor.y = 1 - (faceEnd + spaceBelow / 2) / videoMetadata.height;
      } else {
        positionFactor.y = 1 - spaceAbove / 2 / videoMetadata.height;
      }
    }
  }

  return {
    edl: {
      segments: [
        [
          scene.mainOffset,
          scene.mainOffset + scene.endTime - scene.startTime,
          scene.startTime,
          scene.endTime,
        ],
      ],
    },
    renderEffect: {
      renderEffectType: "captions",
      assetId: assetId,
      templateId: style.templateId,
      positionFactor: positionFactor,
      hidden: scene.style.hideCaptions,
      dynamicCaptions: scene.style.dynamicCaptions,
    },
  };
};
