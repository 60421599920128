import { useCallback } from "react";

import { useOnceEffect, useOnChangeEffect, useOnMountEffect } from "~/hooks/helpers";

import { Analytics } from "./track";

// input/def types
type EventDefProperties = Record<string, unknown> | void;

type EventDefinitions = Record<string, EventDefProperties>;

type EventOptions<T extends EventDefinitions> = {
  // if you want to prefix event names with a namespace.
  namespacePrefix?: string;
  // if you want to transform the properties before they are sent to the analytics tool, you can do so here
  // for any of the events you declare in the EventDefinitions type.
  transformers?: { [K in keyof T]?: (properties: T[K]) => Record<string, unknown> };
};

// return type
type EventNamePair<P extends EventDefProperties = EventDefProperties> = [string, P];
type EventDefFn<P extends EventDefProperties> = P extends void
  ? () => EventNamePair<P>
  : (properties: P) => EventNamePair<P>;
type PropertiesOrCallback<P extends EventDefProperties, Arg> = P | ((arg: Arg) => P);

type EventCallbackHook<P extends EventDefProperties> = () => EventDefFn<P>;

type EventHookWithArgs<P extends EventDefProperties, ArgRequirement = unknown> = P extends void
  ? <Arg extends ArgRequirement>(arg: Arg) => EventNamePair<P>
  : <Arg extends ArgRequirement>(
      arg: Arg,
      properties: PropertiesOrCallback<P, Arg>
    ) => EventNamePair<P>;

type EventHookWithoutArgs<P extends EventDefProperties> = EventDefFn<P>;

type EventHooks<P extends EventDefProperties> = {
  /**
   * Track event directly. If event properties are required, then you must pass them
   * when calling the function.
   *
   * Examples:
   *
   * ```
   * EvenNamespace.event_name.track() // no properties
   * EvenNamespace.event_name.track({value: 1}) // with properties
   * ```
   */
  track: EventDefFn<P>;
  /**
   * Emit event when component is mounted. If event properties are required, then you must
   * pass them directly as an object or a function that returns an object.
   *
   * Examples:
   *
   * ```
   * EvenNamespace.event_name.useTrackEventOnMount() // no properties
   * EvenNamespace.event_name.useTrackEventOnMount({value: 1}) // with properties as object
   * EvenNamespace.event_name.useTrackEventOnMount(() => ({value: 1})) // with function that returns properties
   * ```
   */
  useTrackEventOnMount: EventHookWithoutArgs<P>;
  /**
   * Emit event when callback is called. If event properties are required, then you must
   * pass them them when calling the function.
   *
   * Examples:
   *
   * ```
   * const callback = EvenNamespace.event_name.useTrackEventCallback()
   * callback(); // no properties
   * callback({value: 1}); // with properties
   * ```
   */
  useTrackEventCallback: EventCallbackHook<P>;
  /**
   * Emit event when value changes. If event properties are required, then you must
   * pass them directly as an object or a function that takes the vaue and returns an object.
   *
   * Examples:
   *
   * ```
   * EvenNamespace.event_name.useTrackEventOnChange(state.isSelected) // no properties
   * EvenNamespace.event_name.useTrackEventOnChange(state.isSelected, {selected: state.isSelected}) // with properties as object
   * EvenNamespace.event_name.useTrackEventOnChange(state.isSelected, (selected) => ({selected})) // with function that returns properties
   * ```
   */
  useTrackEventOnChange: EventHookWithArgs<P>;
  /**
   * Emit event when value is `true`. If event properties are required, then you must
   * pass them directly as an object or a function that takes the vaue and returns an object.
   *
   * Examples:
   *
   * ```
   * EvenNamespace.event_name.useTrackEventOnTrue(state.isSelected) // no properties
   * EvenNamespace.event_name.useTrackEventOnTrue(state.isSelected, {selected: state.isSelected}) // with properties as object
   * EvenNamespace.event_name.useTrackEventOnTrue(state.isSelected, (selected) => ({selected})) // with function that returns properties
   * ```
   */
  useTrackEventOnTrue: EventHookWithArgs<P, boolean>;
  /**
   * Emit event when condition is met, only once. If event properties are required, then you must
   * pass them directly as an object or a function that takes the vaue and returns an object.
   *
   * Examples:
   *
   * ```
   * EvenNamespace.event_name.useTrackEventOnce(state.isSelected) // no properties
   * EvenNamespace.event_name.useTrackEventOnce(state.isSelected, {selected: state.isSelected}) // with properties as object
   * EvenNamespace.event_name.useTrackEventOnce(state.isSelected, (selected) => ({selected})) // with function that returns properties
   * ```
   */
  useTrackEventOnce: EventHookWithArgs<P, boolean>;
};

type EventDefs<T extends EventDefinitions> = Readonly<{
  [K in keyof T]: EventHooks<T[K]>;
}>;

export function createEventDefs<T extends EventDefinitions>(options: EventOptions<T> = {}) {
  // we use a proxy to allow for dynamic event names after you specify the names
  // in the keys of the type T.
  return new Proxy(
    {},
    {
      get(_, prop: string) {
        const trackEventWithProps = (properties: T[string]) => {
          const eventName = `${options.namespacePrefix ?? ""}${prop}`;
          const eventProperties = options.transformers?.[eventName]?.(properties) ?? properties;
          Analytics.track(eventName, eventProperties);
        };

        return {
          track: (properties: PropertiesOrCallback<T[string], void>) => {
            const props = typeof properties === "function" ? properties() : properties;
            trackEventWithProps(props);
          },
          useTrackEventOnMount: (properties: PropertiesOrCallback<T[string], void>) => {
            useOnMountEffect(() => {
              const props = typeof properties === "function" ? properties() : properties;
              trackEventWithProps(props);
            });
          },
          useTrackEventCallback: () => {
            return useCallback((properties: PropertiesOrCallback<T[string], void>) => {
              const props = typeof properties === "function" ? properties() : properties;
              trackEventWithProps(props);
            }, []);
          },
          useTrackEventOnChange: <Arg>(
            arg: Arg,
            properties: PropertiesOrCallback<T[string], Arg>
          ) => {
            useOnChangeEffect(arg, () => {
              const props = typeof properties === "function" ? properties(arg) : properties;
              trackEventWithProps(props);
            });
          },
          useTrackEventOnTrue: (
            condition: boolean,
            properties: PropertiesOrCallback<T[string], boolean>
          ) => {
            useOnChangeEffect(condition, () => {
              if (condition) {
                const props = typeof properties === "function" ? properties(condition) : properties;
                trackEventWithProps(props);
              }
            });
          },
          useTrackEventOnce: (
            condition: boolean,
            properties: PropertiesOrCallback<T[string], boolean>
          ) => {
            useOnceEffect(condition, () => {
              const props = typeof properties === "function" ? properties(condition) : properties;
              trackEventWithProps(props);
            });
          },
          // NOTE: if we ever decouple analytics outside of the hook,
          // we can have a `track` function here that just takes the params directly
        };
      },
    }
  ) as EventDefs<T>;
}
