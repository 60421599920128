import { useFeatureFlag } from "feature-flags";

import { PROJECT_FFMPEG_ERROR } from "~/constants/mixpanel.constants";
import { useImageGenerator } from "~/context";
import { useBackendServicesClient } from "~/context/BackendServicesContext";
import { Project } from "~/database/database.types";
import { getUploadedFileInfo } from "~/services/UploadFile";

import { useMixpanel } from "./useMixpanel";

export function useProjectThumbnail() {
  const client = useBackendServicesClient();
  const imageGenerator = useImageGenerator();
  const mixpanel = useMixpanel();
  const enableCreateMuxAsset = useFeatureFlag("enable_mux_create_asset");

  const createThumbnail = async (project: Project) => {
    try {
      const response = await getUploadedFileInfo(client, project.sourceFileId, {
        enableCreateMuxAsset,
      });
      const createThumbnailResponse = await imageGenerator.generateVideoThumbnail({
        fileName: project?.title,
        fileId: project.sourceFileId,
        projectId: project.id,
        inputVideoPath: response.url,
        targetFrameSize: {
          targetWidth: 112,
          targetHeight: 112,
        },
      });
      if (createThumbnailResponse.status === "error" || !createThumbnailResponse.path) {
        throw new Error(
          createThumbnailResponse.message ?? "Failed to create thumbnail due to unknown error"
        );
      }
      if (createThumbnailResponse.path) {
        return createThumbnailResponse.path;
      }
    } catch (error) {
      if (error instanceof Error) {
        mixpanel.track(PROJECT_FFMPEG_ERROR, {
          message: error.message,
          fileId: project.id,
          transcriptionId: project.transcriptionJobId,
        });
      }
      throw error;
    }
  };

  return {
    createThumbnail,
  };
}
