import React, { useEffect, useState } from "react";

import { LOGIN_VIEW } from "~/constants/mixpanel.constants";
import { useMixpanel } from "~/hooks/useMixpanel";

import { SignInByPhone } from "../SignInByPhone";
import { Text } from "../Text";
import { TopBar, TopBarContext } from "../TopBar/TopBar";
import { WindowTitle } from "../WindowTitle";

import * as S from "./SignIn.styles";
import { LoginStep, SignInProps } from "./SignIn.types";
import SignInLayoutContent from "./SignInLayoutContent";

const SPLASH_PNG_SRC = "https://captions-cdn.xyz/desktop-site/sign-in-2x.png";

const SPLASH_PNG_SRCSET = [
  "https://captions-cdn.xyz/desktop-site/sign-in-1x.png 1x",
  "https://captions-cdn.xyz/desktop-site/sign-in-2x.png 2x",
  "https://captions-cdn.xyz/desktop-site/sign-in-4x.png 4x",
].join(", ");

export default function SignIn({
  hideTopBar,
  title,
  version,
  onAppleClick,
  onGoogleClick,
  onMicrosoftClick,
  onLegalCenterClick,
  areActionsBlocked = false,
}: SignInProps) {
  const [step, setStep] = useState<LoginStep>(LoginStep.Initial);
  const { track } = useMixpanel();

  useEffect(() => {
    track(LOGIN_VIEW);
  }, []);

  const [topBarElem, setTopBarElem] = useState<HTMLDivElement | undefined>(undefined);

  return (
    <TopBarContext.Provider value={{ topBarElem }}>
      <S.SignInLayoutContainer hideTopBar={hideTopBar}>
        <WindowTitle title="Sign in" />
        {!hideTopBar && <TopBar topBarRef={(elem: HTMLDivElement) => setTopBarElem(elem)} />}
        <S.SignInLeftPanel>
          <S.LeftPanelGradient />
          <S.LeftPanelLogo />
          <source type="image/png" srcSet={SPLASH_PNG_SRCSET} />
          <S.LeftPanelImage draggable={false} src={SPLASH_PNG_SRC} alt="Captions app screenshot" />
        </S.SignInLeftPanel>
        <S.RightPanel>
          {step !== LoginStep.Initial ? (
            <SignInByPhone step={step} setStep={setStep} />
          ) : (
            <SignInLayoutContent
              title={title}
              onAppleClick={onAppleClick}
              onGoogleClick={onGoogleClick}
              onMicrosoftClick={onMicrosoftClick}
              onContinueAnotherWayClick={() => setStep(LoginStep.EnterPhoneNumber)}
              areActionsBlocked={areActionsBlocked}
            />
          )}
          <S.Footer>
            <Text color="grey-600" variant="body-1">
              By creating an account, you agree to the Captions <br />
              <Text
                as="a"
                color="grey-500"
                variant="body-2-bold"
                href="https://www.captions.ai/legal/terms"
                target="_blank"
              >
                Terms of Use
              </Text>{" "}
              and{" "}
              <Text
                as="a"
                color="grey-500"
                variant="body-2-bold"
                href="https://www.captions.ai/legal/privacy"
                target="_blank"
              >
                Privacy Policy
              </Text>
            </Text>
          </S.Footer>
        </S.RightPanel>
      </S.SignInLayoutContainer>
    </TopBarContext.Provider>
  );
}
