import { styled } from "ui";

export const CheckboxButtonIcon = styled("div", {
  "& svg": {
    color: "$grey-600",
  },
});

export const CheckboxButtonCheckmark = styled("div", {
  "& svg": {
    color: "$grey-300",
  },
});

export const CheckboxButtonContainer = styled("button", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  borderRadius: 8,
  padding: "4px 12px",
  gap: "12px",
  textAlign: "left",
  variants: {
    checked: {
      true: {
        backgroundColor: "rgba(255, 255, 255, 0.04)",
        [`${CheckboxButtonCheckmark}`]: {
          visibility: "visible",
        },
      },
      false: {
        [`${CheckboxButtonCheckmark}`]: {
          visibility: "hidden",
        },
      },
    },
    disabled: {
      true: {
        cursor: "not-allowed",
      },
      false: {
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.04)",
        },
      },
    },
  },
});
CheckboxButtonContainer.defaultProps = { type: "button" };

export const CheckboxButtonContent = styled("div", {
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  width: "100%",
});

export const CheckboxButtonList = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 16,
  borderRadius: 8,
});
