import { IconProps } from "./types";

function Search({ height = "24", width = "24", ...props }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.0002 21.0002L17.3125 17.3125"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 11.379C3 16.0066 6.75141 19.758 11.379 19.758C16.0066 19.758 19.758 16.0066 19.758 11.379C19.758 6.75141 16.0066 3 11.379 3V3C6.75155 3.00034 3.00034 6.75155 3 11.379"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Search;
