import { MICROSECONDS_IN_SECOND } from "./constants/time.constants";
import { FrameBorderItem, FrameBorderPlaceholder, ContentDimensions } from "./framePainter.types";
import { getCoverPixelMapping } from "./utils/coordMapping";
import {
  calculateRotatedBoundingBox,
  MappingCoordinates,
  PixelMapping,
  rotatePoint,
  updateTransparentTexture,
} from "./utils/webgl";
import {
  drawBlendOverlay,
  initBlend,
  BlendProgramData,
  blendModeNumbers,
} from "./utils/webgl/blendOverlayProgram";

export class FramePainter {
  private _frames: FrameBorderItem[] = [];
  private _activeFrame: FrameBorderItem | null = null;
  private _timestamp: number = 0;
  private _program: BlendProgramData | null = null;

  public setFrames(frames: FrameBorderItem[]) {
    this._frames = frames;
    this._updateActiveFrame();
  }

  public setTimestamp(timestamp: number) {
    this._timestamp = timestamp;
    this._updateActiveFrame();
  }

  /**
   * Sets up the internal WebGL program for the given context.
   *
   * @param ctx - The WebGL context to use.
   */
  public setupContext(ctx: WebGLRenderingContext | null) {
    if (ctx) {
      this._program = initBlend(ctx);
    } else {
      this._program = null;
    }
  }

  private _updateActiveFrame() {
    const newActiveFrame =
      this._frames.find((frame) => {
        // TODO: Handle timestamps after the last frame
        return frame.startTime <= this._timestamp && frame.endTime > this._timestamp;
      }) ?? null;
    if (newActiveFrame !== this._activeFrame) {
      this._activeFrame = newActiveFrame;
    }
  }

  private _getPlaceholderParams(placeholder: FrameBorderPlaceholder) {
    const { scaleX, scaleY, translationX, translationY, rotation } = placeholder.transform;
    const bounds = placeholder.bounds;
    const width = (bounds.right - bounds.left) * scaleX;
    const height = (bounds.bottom - bounds.top) * scaleY;
    const x = translationX;
    const y = translationY;

    const corners = [
      { x: 0, y: 0 },
      { x: width, y: 0 },
      { x: 0, y: height },
      { x: width, y: height },
    ].map((point) => rotatePoint(point.x + x, point.y + y, x, y, -rotation));

    // Get center point
    const centerX = (corners[0].x + corners[1].x + corners[2].x + corners[3].x) / 4;
    const centerY = (corners[0].y + corners[1].y + corners[2].y + corners[3].y) / 4;

    // Calculate the original (unrotated) top-left corner
    const originalPoint = rotatePoint(x, y, centerX, centerY, rotation);

    const bb = calculateRotatedBoundingBox(width, height, rotation);
    const diffX = rotation !== 0 ? (bb.width - width) / 2 : 0;
    const diffY = rotation !== 0 ? (bb.height - height) / 2 : 0;

    const boundingBox = {
      x: originalPoint.x - diffX,
      y: originalPoint.y - diffY,
      width: bb.width,
      height: bb.height,
    };

    return { rotation, originalPoint, x, y, width, height, boundingBox };
  }

  /**
   * Adjusts the pixel mappings to match the current frame's image layers.
   *
   * @remarks If there are multiple image layers, the same frame will be drawn multiple times.
   * @param pixelMappings - The pixel mappings to adjust.
   * @param videoFrame - The dimensions of the source video that will be applied in the frame.
   * @param imageFrame - The dimensions of the broll image that will be applied in the frame.
   * @returns The adjusted pixel mappings.
   */
  public adjustPixelMappings(
    pixelMappings: PixelMapping[],
    videoFrame: ContentDimensions,
    bRoll: ContentDimensions | null
  ): PixelMapping[] {
    const activeFrame = this._activeFrame;
    if (!activeFrame || !activeFrame.asset.placeholders.length) {
      return pixelMappings;
    }

    // TODO: Handle placeholders with distinct contents
    return activeFrame.asset.placeholders.map((placeholder): PixelMapping => {
      const parentWidth = activeFrame.asset.width;
      const parentHeight = activeFrame.asset.height;

      const { originalPoint, boundingBox, rotation, width, height } =
        this._getPlaceholderParams(placeholder);

      // Calculate the actual bounds of the rotated rectangle
      const actualBounds: MappingCoordinates = {
        left: originalPoint.x / parentWidth,
        top: originalPoint.y / parentHeight,
        right: (originalPoint.x + width) / parentWidth,
        bottom: (originalPoint.y + height) / parentHeight,
      };

      const boundsMappping: MappingCoordinates = {
        left: boundingBox.x / parentWidth,
        top: boundingBox.y / parentHeight,
        right: (boundingBox.x + boundingBox.width) / parentWidth,
        bottom: (boundingBox.y + boundingBox.height) / parentHeight,
      };

      const source = placeholder.contentType !== "source-video" && bRoll ? bRoll : videoFrame;

      const fromCoords = getCoverPixelMapping(width, height, source.width, source.height).from;

      return {
        from: fromCoords,
        to: actualBounds,
        rotation,
        contentType: placeholder.contentType,
        bounds: boundsMappping,
      };
    });
  }

  /**
   * Draws the current frame to the given context.
   *
   * @param ctx - The WebGL context to draw to.
   */
  public paint(ctx: WebGLRenderingContext) {
    const activeFrame = this._activeFrame;
    if (!activeFrame || !this._program) {
      return;
    }
    const localTimestamp =
      ((this._timestamp - activeFrame.startTime) * MICROSECONDS_IN_SECOND) %
      activeFrame.asset.duration;
    const currentImage =
      activeFrame.asset.frames.findLast((frame) => frame.timestamp <= localTimestamp) ??
      activeFrame.asset.frames.at(-1);
    if (!currentImage) {
      return;
    }
    updateTransparentTexture(ctx, this._program.textures.sourceTexture, currentImage.image);
    updateTransparentTexture(ctx, this._program.textures.backdropTexture, ctx.canvas);
    drawBlendOverlay(ctx, blendModeNumbers.sourceover, this._program);
  }

  public destroy() {
    this._frames = [];
    this._activeFrame = null;
  }
}
