import { PopoverContent, PopoverTrigger } from "@radix-ui/react-popover";
import { Command } from "cmdk";

import { styled } from "~/theme";

import ArrowDown from "../icons/ArrowDown";
import Search from "../icons/Search";
import * as ScrollArea from "../ScrollArea";

export const ComboBoxTriggerLabel = styled("div", {
  flex: 1,
  textAlign: "left",
  overflow: "hidden",
  whiteSpace: "nowrap",
  height: "100%",
  textOverflow: "ellipsis",
});

export const ComboBoxDropdownIcon = styled(ArrowDown, {
  display: "block",
});

export const ComboBoxTrigger = styled(PopoverTrigger, {
  alignItems: "center",
  display: "flex",
  gap: "$8",
  backgroundColor: "$grey-800",
  border: "none",
  color: "$grey-600",
  borderRadius: "$6",
  padding: "10px 10px 10px 16px",
  height: "44px",
  '&[data-state="open"]': {
    backgroundColor: "$grey-800-highlighted",
    color: "$grey-300",
  },
  outlineColor: "$grey-500",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "$grey-800-highlighted",
  },
  "&:disabled": {
    cursor: "not-allowed",
    backgroundColor: "$grey-800",
  },
});

export const ComboBoxSearchIcon = styled(Search, {
  position: "absolute",
  top: "34px",
  left: 16,
  color: "$grey-200",
  pointerEvents: "none",
});

export const ComboBoxSearchContainer = styled("div", {
  borderBottom: "1px solid $grey-700",
  "& > div": {
    backgroundColor: "transparent !important",
  },
});

export const ComboBoxEmptyText = styled(Command.Empty, {
  display: "flex",
  gap: "$16",
  padding: "$8",
  alignItems: "center",
  "> svg": {
    color: "$grey-600",
  },
});

export const ComboBoxContent = styled(PopoverContent, {
  backgroundColor: "$grey-800",
  marginTop: 4,
  marginBottom: 8,
  width: "var(--radix-popover-trigger-width)",
  border: "1px solid $grey-700",
  boxShadow: "0px 16px 48px 0px #00000036",
  borderRadius: 8,
  zIndex: "$z-select",
});

ComboBoxContent.defaultProps = { side: "bottom", avoidCollisions: false };

export const ComboBoxScrollRoot = styled(ScrollArea.Root, {
  width: "100%",
  height: "100%",
});

export const ComboBoxScrollViewport = styled(ScrollArea.Viewport, {
  wordBreak: "break-all",
  width: "100%",
  maxHeight: "calc(var(--radix-popover-content-available-height) - 56px - 20px)",
  padding: "8px",
});

export const ComboBoxScrollBar = styled(ScrollArea.Scrollbar, {});

export const ComboBoxScrollThumb = styled(ScrollArea.Thumb, {});
