import { getImageAnimation } from "./animationUtils";
import { AnimationTimes } from "./animationUtils.types";
import { getStartingPoint } from "./captionDrawing";
import { AnyCanvasRenderingContext2D, PositionFactor } from "./captionDrawing.types";
import { BackgroundImage, ImageItem, ImageSource, OuterBoxInfo } from "./captionEngine.types";

export class ImageLayer {
  public activeBRoll: ImageSource | null = null;
  private _images: ImageItem[] = [];
  private _videoScale: PositionFactor = { x: 1, y: 1 };
  private _size: { width: number; height: number } = { width: 0, height: 0 };
  private _timestamp: number = 0;
  private _currentImages: ImageItem[] = [];
  private _currentImageBoxes: OuterBoxInfo[] = [];

  constructor() {}

  public get imageBoxes() {
    return this._currentImageBoxes;
  }

  private updateCurrentImages() {
    this._currentImages = this._images.filter(
      (image) => image.startTime <= this._timestamp && image.endTime > this._timestamp
    );

    this.activeBRoll =
      this._currentImages.find((image) => image.positionType === "b-roll")?.image || null;
    this._currentImages = this._currentImages.filter((image) => image.positionType !== "b-roll");
  }

  public getBackgroundImages(): BackgroundImage[] {
    const backgroundImages: BackgroundImage[] = this._currentImages
      .filter((image) => image.isBackground)
      .map((image) => {
        return {
          ...image,
          imageBox: this._currentImageBoxes.find((box) => box.id === image.id),
        };
      });

    return backgroundImages;
  }

  private adjustBoxPositionToStayInBounds(x: number, y: number, width: number, height: number) {
    // TODO DESK-1656: take into account box rotation when calculating out of bounds
    const left = x - width / 2;
    const right = x + width / 2;
    const top = y - height / 2;
    const bottom = y + height / 2;

    const videoWidth = this._size.width;
    const videoHeight = this._size.height;
    const videoBounds = {
      left: 0,
      right: videoWidth,
      top: 0,
      bottom: videoHeight,
    };

    let adjustedX = x;
    let adjustedY = y;

    // only adjust position if box can be contained within video
    if (width <= videoWidth) {
      if (left < videoBounds.left) {
        adjustedX += videoBounds.left - left;
      } else if (right > videoBounds.right) {
        adjustedX -= right - videoBounds.right;
      }
    }

    // only adjust position if box can be contained within video
    if (height <= videoHeight) {
      if (top < videoBounds.top) {
        adjustedY += videoBounds.top - top;
      } else if (bottom > videoBounds.bottom) {
        adjustedY -= bottom - videoBounds.bottom;
      }
    }

    return { x: adjustedX, y: adjustedY };
  }

  private createImageBox(image: ImageItem): OuterBoxInfo {
    const { image: img, position, rotation, scale, id, startTime, endTime, animations } = image;
    const { x: startX, y: startY } = getStartingPoint(
      { width: this._size.width, height: this._size.height },
      { x: 0, y: 0 },
      position
    );

    const scaledWidth = img.width * scale;
    const scaledHeight = img.height * scale;

    if (!animations) {
      return {
        boxType: "image",
        id,
        ...this.adjustBoxPositionToStayInBounds(startX, startY, scaledWidth, scaledHeight),
        width: scaledWidth,
        height: scaledHeight,
        rotation,
        scale,
      };
    }

    const animatedImage = getImageAnimation({ startTime, endTime }, this._timestamp, animations, {
      ...this.adjustBoxPositionToStayInBounds(startX, startY, scaledWidth, scaledHeight),
      width: scaledWidth,
      height: scaledHeight,
    });

    return {
      boxType: "image",
      id,
      opacity: animatedImage.opacity,
      x: animatedImage.imageBox.x,
      y: animatedImage.imageBox.y,
      width: animatedImage.imageBox.width,
      height: animatedImage.imageBox.height,
      rotation,
      scale,
    };
  }

  private calculateStartingPoint() {
    this._currentImageBoxes = this._currentImages.map((image) => this.createImageBox(image));
  }

  setVideoScale(scale: PositionFactor) {
    this._videoScale = scale;
    this.calculateStartingPoint();
  }

  setArea(width: number, height: number) {
    this._size = { width, height };
    this.calculateStartingPoint();
  }

  setImages(images: ImageItem[]) {
    this._images = images;
    this.updateCurrentImages();
    this.calculateStartingPoint();
  }

  setTimestamp(timestamp: number) {
    this._timestamp = timestamp;
    this.updateCurrentImages();
    this.calculateStartingPoint();
  }

  getAnimationTimes(): AnimationTimes[] {
    return this._images.map((image) => ({
      startAnimation: { startTime: image.startTime, endTime: image.startTime },
      endAnimation: { startTime: image.endTime, endTime: image.endTime },
    }));
  }

  drawLayer(ctx: AnyCanvasRenderingContext2D, x: number, y: number) {
    for (const currentImage of this._currentImages) {
      if (currentImage.isBackground) {
        continue;
      }
      const imageBox = this._currentImageBoxes.find((box) => box.id === currentImage.id);
      if (!imageBox) {
        continue;
      }
      const { x: startX, y: startY, width: imageWidth, height: imageHeight, rotation } = imageBox;
      ctx.save();
      ctx.globalAlpha = imageBox.opacity ?? 1;
      ctx.translate(x, y);
      ctx.beginPath();
      ctx.rect(0, 0, this._size.width, this._size.height);
      ctx.clip();
      ctx.scale(1 / this._videoScale.x, 1 / this._videoScale.y);
      ctx.translate(startX * this._videoScale.x, startY * this._videoScale.y);
      ctx.rotate(rotation);
      ctx.drawImage(currentImage.image, -imageWidth / 2, -imageHeight / 2, imageWidth, imageHeight);
      ctx.restore();
    }
  }
}
