import { createCanvas, freeCanvas } from "canvas-utils";
import {
  AnyCanvasRenderingContext2D,
  CaptionStyle,
  CaptionStylePreset,
  EmojiPosition,
  EmojiTiming,
  loadCaptionFont,
} from "captions-engine";
import { DEFAULT_LINE_FIT_WRAP_WIDTH_CONSTRAINT } from "captions-engine";

import { getFontFamily } from "~/theme/fonts/subtitles";

import { CAPTION_TEMPLATES, CaptionEmphasisSettings } from "../services/CaptionStylePreset";

import { CaptionWord, generateCaptionPages } from "./captionProcessing";
import { createCaptionEngine } from "./createCaptionEngine";

const SIZE_FACTOR_THRESHOLD = 0.16;
const BASE_FONT_SIZE = 96;
const CHAR_COUNT_SAFE_FACTOR = 1.5;

const TEMPLATE_FACTOR_OVERRIDES: Record<
  string,
  [factor4k: number, factor1080p: number, factor720p: number, factor480p?: number]
> = {
  // Suzy
  [CAPTION_TEMPLATES.Suzy]: [1.76, 0.87, 0.64, 0.5],
  // Impact
  [CAPTION_TEMPLATES.Impact]: [2.46, 1.54, 1, 0.72],
  // default scales (based on Sirus)
  default: [1.8, 1, 0.72, 0.48],
};

/**
 * Returns the default size factor for the given width and height.
 *
 * @param width - The width of the video area.
 * @param height - The height of the video area.
 * @param templateId - The template ID to check for overrides.
 * @returns The default size factor.
 */
export function getDefaultSizeFactor(width: number, height: number, templateId?: string) {
  const closeTo = (number: number) => number - number * 0.05;

  const minDimension = Math.min(width, height);
  const maxDimension = Math.max(width, height);

  const [factor4k, factor1080p, factor720p, factor480p] =
    TEMPLATE_FACTOR_OVERRIDES[templateId!] || TEMPLATE_FACTOR_OVERRIDES.default;

  const resolution = {
    _4K: 2160,
    _1080p: 1080,
    _720p: 720,
  };

  // 4k videos
  if (minDimension >= closeTo(resolution._4K)) {
    return factor4k - SIZE_FACTOR_THRESHOLD;
  }
  // 1080p videos
  if (minDimension >= closeTo(resolution._1080p)) {
    return factor1080p - SIZE_FACTOR_THRESHOLD;
  }
  // 720p videos
  if (minDimension >= closeTo(resolution._720p)) {
    return factor720p - SIZE_FACTOR_THRESHOLD;
  }
  // 240p/320p/480p videos (fallback)
  return (factor480p || minDimension / maxDimension) - SIZE_FACTOR_THRESHOLD;
}

/**
 * Returns the default size factor meant for an AI shorts video for the given width, height and
 * style preset.
 *
 * @remarks This was made into a separate function to not interfere with the default size used
 * in the project view.
 *
 * @param width - The width of the video area.
 * @param height - The height of the video area.
 * @param stylePreset - The caption style preset.
 * @returns The default size factor.
 */
export function getDefaultShortsSizeFactor(
  width: number,
  height: number,
  stylePreset: CaptionStylePreset
) {
  const safeWidth = 0.9 * width;
  // For styles with automatic line wraps, we can scale them up so lines fit the safe width.
  if (stylePreset.lineFitWrapEnabled) {
    const lineWidth = stylePreset.sizeConstraints?.width || DEFAULT_LINE_FIT_WRAP_WIDTH_CONSTRAINT;
    return safeWidth / lineWidth;
  }
  // For styles that have a character count for line breaks, we use a crude aproximation to keep
  // lines within the constraints.
  if ("onPunctuationAndPause" in stylePreset.lineBreakMode) {
    const charCount =
      stylePreset.lineBreakMode.onPunctuationAndPause.characterCount * CHAR_COUNT_SAFE_FACTOR;
    // While we know that characters aren't square on most fonts, this is a good enough
    // calculation that avoids the text being too large.
    return safeWidth / (charCount * stylePreset.font.fontSize);
  }
  // Falls back to the default algorithm
  // Since it was tuned for the Sirius preset, we scale it according to the template's
  // font size.
  const fontSizeExtraFactor = stylePreset.font.fontSize / BASE_FONT_SIZE;
  return getDefaultSizeFactor(width, height) * fontSizeExtraFactor;
}

export async function calculateCaptionSizeFactor(
  width: number,
  height: number,
  captionData: {
    stylePreset: CaptionStylePreset;
    emphasisSettings: CaptionEmphasisSettings;
    words: CaptionWord[];
    countryCode: string;
    templateId?: string;
    rotation?: number;
  }
) {
  const isLandscape = width > height;
  const targetWidth = isLandscape ? 0.7 * width : 0.9 * width;
  const safeWidthRange = {
    max: targetWidth + 0.05 * width,
    min: targetWidth - 0.1 * width,
    target: targetWidth,
  };
  const pages = generateCaptionPages(captionData.words, captionData.stylePreset, {
    isLandscape,
  });
  const fontFamily = getFontFamily(captionData.stylePreset.font.fontName, captionData.countryCode);
  const canvas = createCanvas(1, 1);
  const context = canvas.getContext("2d") as AnyCanvasRenderingContext2D | null;
  if (!context) {
    freeCanvas(canvas);
    throw new Error("Failed to create a context to calculate the caption size");
  }
  const defaultSizeFactor = getDefaultSizeFactor(width, height, captionData.templateId);
  const style: CaptionStyle = {
    activeColor: { red: 0, green: 0, blue: 0, alpha: 1 },
    textColor: { red: 0, green: 0, blue: 0, alpha: 1 },
    wordBackground: { red: 0, green: 0, blue: 0, alpha: 1 },
    emojiSettings: {
      aiEnabled: false,
      size: 10,
      position: EmojiPosition.emojiPositionUnspecified,
      timing: EmojiTiming.emojiTimingUnspecified,
    },
    emphasisSettings: captionData.emphasisSettings,
    positionFactor: { x: 0, y: 0 },
    rotation: captionData.rotation,
    sizeFactor: defaultSizeFactor,
  };
  const engine = createCaptionEngine();
  try {
    await loadCaptionFont(fontFamily, captionData.stylePreset).catch((error) =>
      console.warn(error)
    );
    await engine.setCaptions(pages, captionData.countryCode, isLandscape);
    engine.setArea(width, height);
    engine.setStyle(captionData.stylePreset, style, true);
    await engine.setContext(context);
    return await engine.autoFitToWidthRange(safeWidthRange);
  } finally {
    engine.destroy();
    freeCanvas(canvas);
  }
}
