import { isAxiosError } from "axios";

import {
  BackendServicesClient,
  BackendServicesResponse,
  ProxyResponse,
} from "~/context/BackendServicesContext";

import { Account, CreditsBalanceResponse } from "./ProfileService.dto";

function handleData<T>(res: BackendServicesResponse<T>) {
  return res?.data;
}

function handleProxyData<T>(res: BackendServicesResponse<ProxyResponse<T>>) {
  return res?.data?.data;
}

export function getProfile(client: BackendServicesClient) {
  return client.get<Account>("account/v1/profile").then(handleData);
}

export function deleteProfile(client: BackendServicesClient) {
  return client.delete<Account>("account/v1/profile").then(handleData);
}

export function getUserHash(client: BackendServicesClient) {
  return client
    .get<{ userHash: string }>("account/v1/intercom-id-verification")
    .then((res) => res?.data?.userHash);
}

export function getPaidApiKey(client: BackendServicesClient) {
  return client
    .post<ProxyResponse<{ apiKey: string }>>("/proxy/v1/paidapi/fetchKey", {})
    .then(handleProxyData)
    .catch((error) => {
      if (
        isAxiosError(error) &&
        error.response?.data?.result &&
        !error.response.data.result.success
      ) {
        // this api errors out when when the user does not have a paid api key
        // but we just want to return null in that case
        return { apiKey: null };
      }

      throw error;
    });
}

export function generatePaidApiKey(client: BackendServicesClient) {
  return client
    .post<ProxyResponse<{ apiKey: string }>>("/proxy/v1/paidapi/generateKey", {})
    .then(handleProxyData);
}

export function getCreditsBalance(client: BackendServicesClient) {
  return client
    .post<ProxyResponse<CreditsBalanceResponse>>("/proxy/v1/credits/balance", {})
    .then(handleProxyData);
}

export function generateStripeCheckoutSessionForCredits(
  client: BackendServicesClient,
  quantity: number
) {
  return client
    .post<{ embeddedCheckoutClientSecret: string; stripeKeyMode?: "test" | "live" }>(
      "/account/v1/credits-checkout",
      { quantity }
    )
    .then(handleData);
}

export function getCreditsPrice(client: BackendServicesClient) {
  return client.get<{ price_cents: number }>("/account/v1/credits-price").then(handleData);
}
