import { styled } from "~/theme";

import { Text } from "../Text";

export const Container = styled("div", {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gridRow: "2 / 3",
  maxWidth: "320px",
  textAlign: "center",
  width: "100%",
});

export const Top = styled("div", {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  padding: "48px 0px",
});

export const IconWrapper = styled("span", {
  fontSize: "34px",
});

export const InfoWrapper = styled("div", {
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "$8",
});

export const Bottom = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "$8",
});

export const BottomWrapper = styled("div", {
  display: "flex",
  width: "100%",
  gap: "$4",
});

export const Input = styled("input", {
  flex: 1,
  fontFamily: "$avenir-next",
  fontWeight: "$medium",
  fontSize: "15px",
  color: "$grey-200",
  borderRadius: "$6",
  padding: "10px 10px 10px 16px",
  outline: "none",
  backgroundColor: "$grey-800",
  border: "1px solid $grey-800",
  variants: {
    isError: {
      true: {
        border: "1px solid $secondary-error",
      },
    },
  },
  defaultVariants: {
    isError: false,
  },
});

export const Label = styled(Text, {
  display: "flex",
  alignItems: "center",
  gap: "$12",
});

export const LabelCode = styled(Text, {
  fontSize: "10px !important",
  color: "$grey-500 !important",
  minWidth: 40,
  defaultVariants: {
    variant: "body-4",
  },
});
