import { styled } from "~/theme";

import { ProgressBarIndicator, ProgressBarRoot } from "./ProgressBar.styles";
import { ProgressBarProps } from "./ProgressBar.types";

/**
 * Creates a styled progress bar component.
 *
 * @param progress - value from 0 to 100 indicating the progress percentage
 * that should be displayed.
 * @param className - the CSS class name to assign to the component.
 * @constructor
 */
export const ProgressBar = styled(function ProgressBar({
  progress,
  className,
  variant = "primary",
}: ProgressBarProps) {
  return (
    <ProgressBarRoot className={className}>
      <ProgressBarIndicator
        css={{ transform: `translateX(-${100 - progress}%)` }}
        variant={variant}
      />
    </ProgressBarRoot>
  );
});
