import { captionTemplateSchema } from "~/modules/project/services/CaptionStylePreset";
import { getCanonicalFontName } from "~/theme/fonts/subtitles";

import { CustomTemplate } from "../database.types";
import { withRetry } from "../repositoryWrapper";
import { getDB } from "../store";

function sanitizeTemplate(template: CustomTemplate | undefined): CustomTemplate | undefined {
  if (!template) {
    return;
  }
  const parsedTemplate = captionTemplateSchema.safeParse(template.template);
  if (parsedTemplate.success) {
    parsedTemplate.data.style.content.font.fontName = getCanonicalFontName(
      parsedTemplate.data.style.content.font.fontName
    );
    return {
      ...template,
      template: parsedTemplate.data,
    };
  }
}

class BaseCustomTemplatesRepository {
  static async addCustomTemplate(customTemplate: CustomTemplate): Promise<void> {
    const db = await getDB();

    await db.add("customTemplates", customTemplate);
  }

  static async removeCustomTemplate(customTemplateId: string): Promise<void> {
    const db = await getDB();

    await db.delete("customTemplates", customTemplateId);
  }

  static async updateCustomTemplate(
    customTemplateId: string,
    data: Partial<Omit<CustomTemplate["template"], "id">>
  ) {
    const db = await getDB();

    const tx = db.transaction(["customTemplates"], "readwrite");
    const customTemplate = await tx.objectStore("customTemplates").get(customTemplateId);
    if (!customTemplate) {
      tx.abort();
      throw new Error("Custom Template not found");
    }
    Object.assign(customTemplate, { updatedAt: new Date() });
    Object.assign(customTemplate.template, data);
    customTemplate.template.style.styleName = customTemplate.template.displayName;
    await tx.objectStore("customTemplates").put(customTemplate);
    await tx.done;
  }

  static async getCustomTemplate(customTemplateId: string): Promise<CustomTemplate | undefined> {
    const db = await getDB();

    return await db.get("customTemplates", customTemplateId).then(sanitizeTemplate);
  }

  static async getAllCustomTemplates(): Promise<Array<CustomTemplate>> {
    const db = await getDB();

    const customTemplates = await db.getAll("customTemplates");
    return customTemplates.flatMap<CustomTemplate>((template) => {
      return sanitizeTemplate(template) ?? [];
    });
  }
}

export const CustomTemplatesRepository = withRetry(BaseCustomTemplatesRepository);
