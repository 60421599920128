import { createContext, ReactNode, useContext } from "react";

export interface TargetImageSize {
  targetWidth: number;
  targetHeight: number;
}

export type ImageGenerationStatus = "pending" | "in-progress" | "complete" | "error";

export interface ThumbnailGeneratorRequest {
  fileName: string;
  fileId: string;
  projectId: string;
  userId?: string;
  inputVideoPath: string;
  targetFrameSize: TargetImageSize;
}

export interface FilmStripGeneratorRequest {
  fileName: string;
  fileId: string;
  projectId: string;
  userId?: string;
  inputVideoPath: string;
  targetFrameSize: TargetImageSize;
  frameExtracted: number;
  stripInterval?: [number, number];
}

export interface ImageGeneratorResponse {
  path?: string | null;
  status: ImageGenerationStatus;
  message?: string | null;
}

export type ThumbnailGenerator = (
  options: ThumbnailGeneratorRequest
) => Promise<ImageGeneratorResponse>;
export type FilmStripGenerator = (
  options: FilmStripGeneratorRequest
) => Promise<ImageGeneratorResponse>;
export type ImageUrlRetriever = (path: string) => Promise<string>;

export interface ImageGeneratorContextData {
  thumbnailGenerator: ThumbnailGenerator;
  filmStripGenerator: FilmStripGenerator;
  imageUrlRetriever: ImageUrlRetriever;
}

export interface ImageGeneratorProviderProps {
  children: ReactNode;
  thumbnailGenerator: ThumbnailGenerator;
  filmStripGenerator: FilmStripGenerator;
  imageUrlRetriever: ImageUrlRetriever;
}

export const ImageGeneratorContext = createContext<ImageGeneratorContextData | null>(null);

export function ImageGeneratorProvider({
  children,
  thumbnailGenerator,
  filmStripGenerator,
  imageUrlRetriever,
}: ImageGeneratorProviderProps) {
  return (
    <ImageGeneratorContext.Provider
      value={{
        thumbnailGenerator,
        filmStripGenerator,
        imageUrlRetriever,
      }}
    >
      {children}
    </ImageGeneratorContext.Provider>
  );
}

export function useImageGenerator() {
  const context = useContext(ImageGeneratorContext);

  if (!context) {
    throw new Error("useImageGenerator must be within its context");
  }
  return {
    generateVideoThumbnail: context.thumbnailGenerator,
    generateFilmStrip: context.filmStripGenerator,
    getImageUrl: context.imageUrlRetriever,
  };
}
