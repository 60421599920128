export const parseSemver = (
  version: string
): { major: number; minor: number; patch: number } | null => {
  const semverRegex = /^(\d+)\.(\d+)\.(\d+)$/;
  const match = version.match(semverRegex);

  if (!match) {
    return null; // Invalid semver format
  }

  const [_, major, minor, patch] = match;

  return {
    major: parseInt(major, 10),
    minor: parseInt(minor, 10),
    patch: parseInt(patch, 10),
  };
};
