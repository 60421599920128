export const UnfurlAnimations: { [key: string]: URL } = {
  plainFold1: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/plainFold1.pag"
  ),
  plainFold2: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/plainFold2.pag"
  ),
  plainFold3: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/plainFold3.pag"
  ),
  paperRotate: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/paperRotate.pag"
  ),
  plainFold1Reverse: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/plainFold1-reverse.pag"
  ),
  plainFold2Reverse: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/plainFold2-reverse.pag"
  ),
  plainFold3Reverse: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/plainFold3-reverse.pag"
  ),
};
