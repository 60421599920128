const BASE_URL = "https://captions-cdn.xyz/desktop-site/ai-edit-assets/recess_assets/frame_borders";

export const SimpleThbrFrames: { [key: string]: URL } = {
  simple_thbr_frame_1_DURATION: new URL(`${BASE_URL}/simple_thbr_frame_1_DURATION.pag`),
  simple_thbr_frame_1_IN: new URL(`${BASE_URL}/simple_thbr_frame_1_IN.pag`),
  simple_thbr_frame_1_OUT: new URL(`${BASE_URL}/simple_thbr_frame_1_OUT.pag`),

  simple_thbr_frame_2_DURATION: new URL(`${BASE_URL}/simple_thbr_frame_2_DURATION.pag`),
  simple_thbr_frame_2_IN: new URL(`${BASE_URL}/simple_thbr_frame_2_IN.pag`),
  simple_thbr_frame_2_OUT: new URL(`${BASE_URL}/simple_thbr_frame_2_OUT.pag`),
};
