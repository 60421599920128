import { styled } from "~/theme";

import { IconProps } from "./types";

function TranslationHand({ height = "24", width = "25", ...props }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.054 11H4.5C3.395 11 2.5 10.105 2.5 9V4C2.5 2.895 3.395 2 4.5 2H20.5C21.605 2 22.5 2.895 22.5 4V9C22.5 10.105 21.605 11 20.5 11H11.054"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0542 13V8.5C11.0542 7.672 10.3822 7 9.55419 7V7C8.72619 7 8.05419 7.672 8.05419 8.5V11V16L7.15319 15.099C6.54619 14.492 5.56219 14.492 4.95519 15.099V15.099C4.43119 15.623 4.35019 16.444 4.76119 17.06L7.46019 21.109C7.83119 21.666 8.45619 22 9.12519 22H14.4132C15.4222 22 16.2722 21.249 16.3982 20.248L16.9402 15.911C17.0732 14.844 16.3392 13.862 15.2782 13.689L11.0542 13Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 5.5L16.5 8L15 6.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default styled(TranslationHand, {
  width: 25,
  height: 24,
  color: "$grey-200",
});
