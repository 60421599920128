import type { Captions } from "../database.types";
import { withRetry } from "../repositoryWrapper";
import { getDB } from "../store";

class BaseCaptionsRepository {
  static async addCaptions(captions: Omit<Captions, "id">): Promise<string | undefined> {
    const db = await getDB();
    const id = window.crypto.randomUUID();
    await db.add("captions", { ...captions, id });
    return id;
  }

  static async updateCaptions(captions: Captions): Promise<void> {
    const db = await getDB();
    const tx = db.transaction(["captions"], "readwrite");
    const found = await tx.objectStore("captions").count(captions.id);
    if (found === 0) {
      tx.abort();
      throw new Error("Captions not found");
    }
    await tx.objectStore("captions").put(captions);
    await tx.done;
  }

  static async updateCaptionWords(captionsId: string, words: Captions["words"]): Promise<void> {
    const db = await getDB();
    const tx = db.transaction(["captions"], "readwrite");
    const captions = await tx.objectStore("captions").get(captionsId);
    if (!captions) {
      tx.abort();
      throw new Error("Captions not found");
    }
    captions.words = words;
    await tx.objectStore("captions").put(captions);
    await tx.done;
  }

  static async updateCaptionPhrases(
    captionsId: string,
    phrases: Captions["phrases"]
  ): Promise<void> {
    const db = await getDB();
    const tx = db.transaction(["captions"], "readwrite");
    const captions = await tx.objectStore("captions").get(captionsId);
    if (!captions) {
      tx.abort();
      throw new Error("Captions not found");
    }
    captions.phrases = phrases;
    await tx.objectStore("captions").put(captions);
    await tx.done;
  }

  static async updateCaptionPhraseAudios(
    captionsId: string,
    phraseAudios: Captions["phraseAudios"]
  ): Promise<void> {
    const db = await getDB();
    const tx = db.transaction(["captions"], "readwrite");
    const captions = await tx.objectStore("captions").get(captionsId);
    if (!captions) {
      tx.abort();
      throw new Error("Captions not found");
    }
    captions.phraseAudios = phraseAudios;
    await tx.objectStore("captions").put(captions);
    await tx.done;
  }

  static async removeCaptions(captionsId: string): Promise<void> {
    const db = await getDB();
    await db.delete("captions", captionsId);
  }

  static async getCaptions(captionsId: string): Promise<Captions | undefined> {
    const db = await getDB();
    return await db.get("captions", captionsId);
  }
}

export const CaptionsRepository = withRetry(BaseCaptionsRepository);
