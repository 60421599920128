import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

import { ConfirmDialog, ConfirmDialogType } from "~/components/ConfirmDialog";

export type DialogType = "confirm";

export interface ConfirmDialogOptions {
  title?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  type?: ConfirmDialogType;
  icon?: ReactNode;
}

export type ConfirmDialogCaller = (
  message: string,
  options?: ConfirmDialogOptions
) => Promise<boolean>;

export interface StandardDialogsContext {
  confirmDialog: ConfirmDialogCaller;
}

interface StandardDialogsStackItem {
  type: DialogType;
  message: string;
  options?: ConfirmDialogOptions;
  resolve?: (value: boolean) => void;
}

const StandardDialogsConfirmContext = createContext<StandardDialogsContext | null>(null);

export function StandardDialogsProvider({ children }: Readonly<PropsWithChildren>) {
  const [dialogStack, setDialogStack] = useState<StandardDialogsStackItem[]>([]);

  const confirmDialog = useCallback((message: string, options?: ConfirmDialogOptions) => {
    return new Promise<boolean>((resolve) => {
      setDialogStack((stack) => [
        ...stack,
        {
          type: "confirm",
          message,
          options,
          resolve: (value) => {
            resolve(value);
            setDialogStack((stack) => stack.slice(0, -1));
          },
        },
      ]);
    });
  }, []);

  return (
    <StandardDialogsConfirmContext.Provider value={{ confirmDialog }}>
      {children}
      {dialogStack.length > 0 && (
        <ConfirmDialog
          message={dialogStack[dialogStack.length - 1].message}
          title={dialogStack[dialogStack.length - 1].options?.title}
          confirmLabel={dialogStack[dialogStack.length - 1].options?.confirmLabel}
          cancelLabel={dialogStack[dialogStack.length - 1].options?.cancelLabel}
          type={dialogStack[dialogStack.length - 1].options?.type}
          icon={dialogStack[dialogStack.length - 1].options?.icon}
          onConfirm={() => {
            dialogStack[dialogStack.length - 1].resolve?.(true);
          }}
          onCancel={() => {
            dialogStack[dialogStack.length - 1].resolve?.(false);
          }}
          open={true}
        />
      )}
    </StandardDialogsConfirmContext.Provider>
  );
}

export function useStandardDialogs() {
  const standardDialogs = useContext(StandardDialogsConfirmContext);
  if (!standardDialogs) {
    throw new Error("useStandardDialogs must be used within a StandardDialogsProvider");
  }
  const { confirmDialog } = standardDialogs;
  return {
    confirmDialog,
  };
}
