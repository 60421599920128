import type { TransitionAnimation } from "../transitionLayer.types";

/**
 * Query all transitions for ones that should currently be playing.
 * All times should be absolute video timestamps in seconds.
 */
export function getCurrentTransitionAnimation(
  animations: TransitionAnimation[],
  timestamp: number
): TransitionAnimation | undefined {
  return animations.findLast(
    ({ startTime, config }) => timestamp >= startTime && timestamp <= startTime + config.duration
  );
}
