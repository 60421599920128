import { ZodError } from "zod";

import { BackendServicesClient } from "~/context/BackendServicesContext";

import {
  type DtoReq_StartAudioDubJob,
  DtoRes_GetAudioDubJob,
  DtoRes_GetAudioDubJobSchema,
  type DtoRes_StartAudioDubJob,
  DtoRes_StartAudioDubJobSchema,
} from "./DubbingService.dto";

const DUBBING_JOB_URL = "/audio/v1/dub";

export async function startDubbingJob(
  client: BackendServicesClient,
  dubbingJob: DtoReq_StartAudioDubJob
): Promise<DtoRes_StartAudioDubJob> {
  try {
    const result = await client.post(DUBBING_JOB_URL, dubbingJob);
    return DtoRes_StartAudioDubJobSchema.parse(result.data);
  } catch (error) {
    console.error(error);
    if (error instanceof ZodError) {
      throw new Error("Invalid response received from the server!");
    } else {
      throw error;
    }
  }
}

export async function getDubbingJobStatus(
  client: BackendServicesClient,
  dubbingJobId: string,
  signal?: AbortSignal
): Promise<DtoRes_GetAudioDubJob> {
  try {
    const result = await client.get(`${DUBBING_JOB_URL}/${dubbingJobId}/status`, { signal });
    return DtoRes_GetAudioDubJobSchema.parse(result.data);
  } catch (error) {
    console.error(error);
    if (error instanceof ZodError) {
      throw new Error("Invalid response received from the server!");
    } else {
      throw error;
    }
  }
}
