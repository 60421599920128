import { freeCanvas } from "canvas-utils";
import { PAGComposition } from "libpag/types/web/src/pag-composition";
import { PAGFile } from "libpag/types/web/src/pag-file";
import { PAGImage } from "libpag/types/web/src/pag-image";
import { PAGPlayer } from "libpag/types/web/src/pag-player";
import { PAGView } from "libpag/types/web/src/pag-view";
import { PAG, TextDocument } from "libpag/types/web/src/types";

import { AnimationReplacementsText } from "../animationPainter.types";
import { PAGBackend } from "../captionEngine.types";
import { measureText } from "../textDrawingUtils";

// Due to typescript limitations, we need to manually define these constants instead of importing
// the const enums from libPAG
export const LAYER_TYPE_IMAGE = 5;
export const SCALE_MODE_STRETCH = 1;
export const TIME_STRETCH_MODE_SCALE = 1;
export const TIME_STRETCH_MODE_REPEAT = 2;

/**
 * Set the duration of a PAGFile to the nearest rounded up value that matches the given duration.
 * This ensures that the animation will have at least `duration` microseconds of playback time.
 *
 * @remarks When you set the duration of a PAGFile it will be truncated to display whole frames,
 * which can cause "gaps" between animations that are supposed to be played back-to-back.
 * @param pagFile - The PAGFile to set the duration of
 * @param duration - The duration in microseconds
 */
export function setRoundedDuration(pagFile: PAGFile, duration: number) {
  const numFrames = Math.ceil((pagFile.frameRate() * duration) / 1e6);
  const durationToSet = Math.ceil((numFrames * 1e6) / pagFile.frameRate());
  pagFile.setDuration(durationToSet);
}

export async function preloadAnimations(
  ids: Set<string>,
  backend: PAGBackend,
  logger?: { log: (...args: unknown[]) => void; error: (...args: unknown[]) => void }
) {
  for (const id of Array.from(ids)) {
    if (backend.isAnimationLoaded(id)) {
      continue;
    }
    logger?.log("Preloading pag animation:", id);
    const pag = await backend.loadAnimation(id);
    if (!pag) {
      logger?.error("Failed to load animation", id);
    }
  }
}

/**
 * Sets whether a PAGView should clear the canvas before drawing.
 *
 * @remarks Unfortunately libPAG does not expose a method to set autoClear on a PAGView, only on a
 * PAGPlayer. We then need to access the internal player (which is a protected member) to be able
 * to set that.
 * @param pagView - The PAGView to set
 * @param autoClear - Whether it should clear the background or not
 */
export function setPAGViewAutoClear(pagView: PAGView, autoClear: boolean) {
  (pagView as unknown as { player: PAGPlayer }).player.setAutoClear(autoClear);
}

/**
 * Moves and scales a layer inside a composition
 * @param pag - The PAG instance
 * @param layer - The layer to scale and move
 * @param composition - The composition
 * @param x - A factor fro 0 to 1, where 0 means the left-most position and 1 means the right-most
 * position
 * @param y - A factor fro 0 to 1, where 0 means the top-most position and 1 means the bottom-most
 * position
 * @param [scale] - A scale factor to be applied. Assumed to be 1 if not given
 */
export function moveAndScaleLayer(
  pag: PAG,
  layer: PAGFile,
  composition: PAGComposition,
  x: number,
  y: number,
  scale?: number
) {
  scale ??= 1;
  const scaledWidth = layer.width() * scale;
  const scaledHeight = layer.height() * scale;
  const tx = Math.round(x * composition.width() - scaledWidth / 2);
  const ty = Math.round(y * composition.height() - scaledHeight / 2);
  const matrix = pag.Matrix.makeAll(scale, 0, tx, 0, scale, ty);
  try {
    layer.setMatrix(matrix);
  } finally {
    matrix.destroy();
  }
}

/**
 * Sets the transformation matrix to make a layer cover an entire composition.
 *
 * @param pag - The PAG instance
 * @param layer - The layer to stretch
 * @param composition - The composition to be filled
 * @param [fit] - The fit mode to use. Can be "stretch" (default), "contain" or "cover"
 */
export function stretchLayerToFitComposition(
  pag: PAG,
  layer: PAGFile,
  composition: PAGComposition,
  fit?: "stretch" | "contain" | "cover"
) {
  // Stretch to fit
  const scaleX = composition.width() / layer.width();
  const scaleY = composition.height() / layer.height();
  const containScale = Math.min(scaleX, scaleY);
  const coverScale = Math.max(scaleX, scaleY);
  if (fit === "contain") {
    moveAndScaleLayer(pag, layer, composition, 0.5, 0.5, containScale);
    return;
  } else if (fit === "cover") {
    moveAndScaleLayer(pag, layer, composition, 0.5, 0.5, coverScale);
    return;
  }
  const matrix = pag.Matrix.makeScale(scaleX, scaleY);
  try {
    layer.setMatrix(matrix);
  } finally {
    matrix.destroy();
  }
}

/**
 * Moves and scales a image inside a layer
 * @param pag - The PAG instance
 * @param image - The image to scale and move
 * @param layer - The layer
 * @param x - A factor from 0 to 1, where 0 means the left-most position and 1 means the right-most
 * position
 * @param y - A factor from 0 to 1, where 0 means the top-most position and 1 means the bottom-most
 * position
 * @param [scale] - A scale factor to be applied to the image. Assumed to be 1 if not given
 */
export function moveAndScaleImage(
  pag: PAG,
  image: PAGImage,
  layer: PAGFile,
  x: number,
  y: number,
  scale?: number
) {
  scale ??= 1;
  const scaledWidth = image.width() * scale;
  const scaledHeight = image.height() * scale;
  const tx = Math.round(x * layer.width() - scaledWidth / 2);
  const ty = Math.round(y * layer.height() - scaledHeight / 2);
  const matrix = pag.Matrix.makeAll(scale, 0, tx, 0, scale, ty);
  try {
    image.setMatrix(matrix);
  } finally {
    matrix.destroy();
  }
}

/**
 * Sets the transformation matrix to make an image cover an entire layer.
 *
 * @param pag - The PAG instance
 * @param image - The image to stretch
 * @param layer - The layer to be filled
 * @param [fit] - The fit mode to use. Can be "stretch" (default), "contain" or "cover"
 */
export function stretchImageToFitLayer(
  pag: PAG,
  image: PAGImage,
  layer: PAGFile,
  fit?: "stretch" | "contain" | "cover"
) {
  // Stretch to fit
  const scaleX = layer.width() / image.width();
  const scaleY = layer.height() / image.height();
  const containScale = Math.min(scaleX, scaleY);
  const coverScale = Math.max(scaleX, scaleY);
  if (fit === "contain") {
    moveAndScaleImage(pag, image, layer, 0.5, 0.5, containScale);
    return;
  } else if (fit === "cover") {
    moveAndScaleImage(pag, image, layer, 0.5, 0.5, coverScale);
    return;
  }
  const matrix = pag.Matrix.makeScale(scaleX, scaleY);
  try {
    image.setMatrix(matrix);
  } finally {
    matrix.destroy();
  }
}

function getNumberOfLinesForText(
  text: string,
  containerWidth: number,
  fontSize: number,
  fontFamily: string
): number {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) {
    return 1;
  }

  try {
    context.font = `${fontSize}px ${fontFamily}`;
    const textWidth = measureText(context, text, 0).width;
    return Math.ceil(textWidth / containerWidth);
  } finally {
    freeCanvas(canvas);
  }
}

export function getNewlinedTextAndScaledFontSize(textDocument: TextDocument) {
  const words = textDocument.text.trim().split(" ");
  const containerWidth = textDocument.boxTextSize.x;

  const minFontSize = 12;
  let fontSize = textDocument.fontSize;
  const fontFamily = textDocument.fontFamily;

  let currentLine = "";
  let currentLineWordsIndex = 0;
  const lineWords: string[][] = [];
  for (const [index, word] of Object.entries(words)) {
    currentLine = currentLine ? `${currentLine} ${word}` : `${word}`;

    const numLines = getNumberOfLinesForText(
      currentLine,
      containerWidth - fontSize,
      fontSize,
      fontFamily
    );
    if (numLines > 1) {
      const isFirstWord = +index === 0;
      if (!isFirstWord) {
        currentLine = word;
        currentLineWordsIndex++;
      }

      // If line is wrapping, try decreasing the font size until it fits into a single line
      while (
        getNumberOfLinesForText(currentLine, containerWidth - fontSize, fontSize, fontFamily) > 1 &&
        fontSize > minFontSize
      ) {
        fontSize -= 5;
      }
    }

    lineWords[currentLineWordsIndex] ||= [];
    lineWords[currentLineWordsIndex].push(word);
  }

  const text = lineWords.map((words) => words.join(" ")).join("\n");

  return { text, fontSize };
}

export function replaceCompositionText(file: PAGFile, textReplacements: AnimationReplacementsText) {
  const autoSize = textReplacements?.autoSize;
  const color = textReplacements?.color;
  const layers = textReplacements?.layers || {};

  for (const [key, text] of Object.entries(layers)) {
    const textDocument = file.getTextData(+key);
    textDocument.text = text;
    if (color) {
      textDocument.fillColor = color;
    }
    if (autoSize) {
      const { text, fontSize } = getNewlinedTextAndScaledFontSize(textDocument);
      textDocument.text = text;
      textDocument.fontSize = fontSize;
    }

    file.replaceText(+key, textDocument);
  }
}

/**
 * Prepares a list of image replacements to be applied on PAGFile objects.
 * @param pag - The PAG instance
 * @param imageReplacements - A record enumerating all the image replacements
 * @returns A record with the image replacements prepared as PAGImage objects
 */
export function prepareImageReplacements(
  pag: PAG,
  imageReplacements: Record<number, TexImageSource>
): Record<number, PAGImage> {
  const images: Record<number, PAGImage> = {};
  for (const [key, value] of Object.entries(imageReplacements)) {
    images[+key] = pag.PAGImage.fromSource(value);
  }
  return images;
}

export function replaceCompositionImage(
  file: PAGFile,
  imageReplacements: Record<number, PAGImage>
) {
  for (const [key, image] of Object.entries(imageReplacements)) {
    file.replaceImage(+key, image);
  }
}
