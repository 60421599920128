import { createContext, useContext } from "react";

import { FFMpegTask } from "~/utils/ffmpegCmdBuilder";

export interface FFMpegInputFileSpec {
  /**
   * The file name.
   */
  fileName: string;
  /**
   * The file contents.
   */
  contents: Blob | URL;
}

export interface FFMpegOutputFileSpec {
  /**
   * The file name.
   */
  fileName: string;
  /**
   * The MIME type of the file.
   */
  mimeType?: string;
}

export interface FFMpegJob {
  /**
   * The task to execute.
   */
  task: FFMpegTask;
  /**
   * The input files used by the task.
   */
  inputFiles: FFMpegInputFileSpec[];
  /**
   * The output files used by the task.
   */
  outputFiles: FFMpegOutputFileSpec[];
}

export interface FFMpegOutputFileItem {
  /**
   * The file name.
   */
  fileName: string;
  /**
   * The file URL.
   */
  url: string;
  /**
   * The output file size
   */
  size: number;
  /**
   * Whether the URL is an object URL.
   * @remarks Object URLs must be revoked after use.
   */
  isObjectUrl: boolean;
}

export interface FFMpegJobResult {
  /**
   * The output files.
   */
  outputUrls: FFMpegOutputFileItem[];
}

export interface FFMpegJobOptions {
  /**
   * The abort signal used to cancel the job.
   */
  abortSignal?: AbortSignal;
  /**
   * Called when the job is completed.
   */
  onJobCompleted: (result: FFMpegJobResult) => void;
  /**
   * Called when the job has failed.
   */
  onJobFailed: (error: Error) => void;
  /**
   * Called when the job has made progress.
   */
  onProgress?: (
    stage: "loading" | "starting" | "executing",
    progress: number,
    encodedTime: number
  ) => void;
}

export interface FFMpegJobRunner {
  /**
   * Run the job.
   */
  executeJob: (job: FFMpegJob, options: FFMpegJobOptions) => void;
}

export const FFMpegJobRunnerContext = createContext<FFMpegJobRunner | undefined>(undefined);

export function useFFMpegJobRunner(): FFMpegJobRunner {
  const runner = useContext(FFMpegJobRunnerContext);

  if (!runner) {
    throw new Error("useFFMpegJobRunner must be used within a FFMpegJobRunnerContext");
  }

  return runner;
}
