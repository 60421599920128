import { getAuthStore } from "~/stores/auth";
import { getEditorStore } from "~/stores/editor";

import { Project, ProjectFolder } from "../database.types";
import { getDB } from "../store";

export class ProjectRepository {
  static async addProjectFolder(projectFolder: ProjectFolder) {
    const db = await getDB();

    await db.add("projectFolders", projectFolder);
  }

  static async addProject(project: Project) {
    const userId = getAuthStore().user?.userId;
    if (!userId) {
      return;
    }

    const db = await getDB();

    if (!project.userId) {
      project.userId = userId;
    }

    if (!project.lastUpdatedByEditorType) {
      // the caller can specify the editor type, but if not, we use the current editor type
      project.lastUpdatedByEditorType = getEditorStore().editorType;
    }

    await db.add("projects", project);

    return project;
  }

  static async removeProject(projectId: string) {
    const db = await getDB();
    const userId = getAuthStore().user?.userId;

    const tx = db.transaction(["projects"], "readwrite");

    const project = await tx.objectStore("projects").get(projectId);

    if (!project || (project.userId && project.userId !== userId)) {
      tx.abort();
      throw new Error("Project not found");
    }

    await tx.objectStore("projects").delete(projectId);
    await tx.done;
  }

  static async getAllProjects() {
    const userId = getAuthStore().user?.userId;

    if (!userId) {
      return [];
    }

    const db = await getDB();

    return db.getAll("projects").then((projects) => {
      return projects.filter((p) => !p.userId || p.userId === userId);
    });
  }

  static async getAllProjectFolders() {
    const db = await getDB();
    return db.getAll("projectFolders");
  }

  static async removeProjectFolder(projectFolderId: string) {
    const userId = getAuthStore().user?.userId;
    if (!userId) {
      return;
    }

    const db = await getDB();

    const tx = db.transaction(["projects", "projectFolders"], "readwrite");

    const projectFolder = await tx.objectStore("projectFolders").get(projectFolderId);

    const allProjects = await tx.objectStore("projects").getAll();
    const projectsInFolder = allProjects.filter((p) => p.folderId === projectFolderId);

    if (!projectFolder || projectsInFolder.some((p) => p.userId && p.userId !== userId)) {
      tx.abort();
      throw new Error("Project folder not found");
    }

    try {
      await tx.objectStore("projectFolders").delete(projectFolderId);
      for (const project of projectsInFolder) {
        await tx.objectStore("projects").delete(project.id);
      }
      await tx.done;
    } catch (error) {
      tx.abort();
      throw error;
    }
  }

  static async getProject(projectId: string) {
    const userId = getAuthStore().user?.userId;
    if (!userId) {
      return;
    }

    const db = await getDB();

    return db.get("projects", projectId).then((project) => {
      return project?.userId === userId ? project : undefined;
    });
  }

  static async updateProject(
    projectId: string,
    data: Partial<Omit<Project, "id">>,
    options?: {
      // for "internal" calls where we do not want to override the editor type
      // (e.g. when we are updating the effects id)
      skipOverrideEditorType?: boolean;
    }
  ) {
    const userId = getAuthStore().user?.userId;
    if (!userId) {
      return;
    }

    const db = await getDB();

    const tx = db.transaction(["projects"], "readwrite");

    const project = await tx.objectStore("projects").get(projectId);

    if (!project || (project.userId && project.userId !== userId)) {
      tx.abort();
      throw new Error("Project not found");
    } else if (data.userId && data.userId !== userId) {
      tx.abort();
      throw new Error("User ID mismatch");
    }
    if (!data.lastUpdatedByEditorType && !options?.skipOverrideEditorType) {
      // the caller can specify the editor type, but if not, we use the current editor type
      // as long as they don't specify skipOverrideEditorType
      data.lastUpdatedByEditorType = getEditorStore().editorType;
    }

    Object.assign(project, data);

    await tx.objectStore("projects").put(project);
    await tx.done;
    return project;
  }

  static async takeOwnership(projectId: string) {
    return ProjectRepository.updateProject(projectId, { userId: getAuthStore().user?.userId });
  }
}
