import { ZodError } from "zod";

import { BackendServicesClient } from "~/context/BackendServicesContext";

import {
  DetectFacesJobState,
  type DetectFacesStartRequest,
  type DetectFacesStartResponse,
  DetectFacesStartResSchema,
  DtoRes_GetDetectFacesSchema,
} from "./DetectFacesService.dto";

const DETECT_FACES_JOB_URL = "/video/v1/detect-faces";

export async function startDetectFacesJob(
  client: BackendServicesClient,
  detectFacesRequest: DetectFacesStartRequest
): Promise<DetectFacesStartResponse> {
  try {
    const result = await client.post(DETECT_FACES_JOB_URL, detectFacesRequest);
    return DetectFacesStartResSchema.parse(result.data);
  } catch (error) {
    console.error(error);
    if (error instanceof ZodError) {
      throw new Error("Invalid response received from the server!");
    } else {
      throw error;
    }
  }
}

export async function getDetectFacesStatus(
  client: BackendServicesClient,
  detectFacesJobId: string,
  signal?: AbortSignal
): Promise<DetectFacesJobState> {
  try {
    const result = await client.get(`${DETECT_FACES_JOB_URL}/${detectFacesJobId}/status`, {
      signal,
    });
    return DtoRes_GetDetectFacesSchema.parse(result.data);
  } catch (error) {
    console.error(error);
    if (error instanceof ZodError) {
      throw new Error("Invalid response received from the server!");
    } else {
      throw error;
    }
  }
}
