import { z } from "zod";

export const startAudioExtractionJobRequestSchema = z.object({
  inputFileId: z.string(),
});

export type StartAudioExtractionJobRequest = z.infer<typeof startAudioExtractionJobRequestSchema>;

export const startAudioExtractionJobResponseSchema = z.object({
  jobId: z.string(),
  errorMessage: z.string().nullable().default(null),
  progress: z.number().nullable().default(null),
  status: z.string(),
});

export type StartOverlayJobResponse = z.infer<typeof startAudioExtractionJobResponseSchema>;

export const AudioExtractionJobStatusSchema = z.enum(["started", "progress", "error", "finished"]);

export type AudioExtractionJobStatus = z.infer<typeof AudioExtractionJobStatusSchema>;

export const getAudioExtractionJobResponseSchema = z.object({
  job: z.object({
    audioExtractionJobId: z.string(),
    status: AudioExtractionJobStatusSchema,
    inputFileId: z.string(),
    outputFileId: z.string(),
    errorMessage: z.string().nullable().optional(),
    progress: z.number(),
  }),
});

export type GetAudioExtractionJobResponse = z.infer<typeof getAudioExtractionJobResponseSchema>;
