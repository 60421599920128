import localFont from "next/font/local";

import localFonts, { avenirNext } from "./index";

const theBoldSlay = localFont({
  src: [
    {
      path: "assets/TheBoldSlay-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["Arial Black", "Arial", "sans-serif"],
});

const ansonRegular = localFont({
  src: [
    {
      path: "assets/Anson-Regular.otf",
      weight: "400",
    },
  ],
  weight: "400",
  preload: false,
  fallback: ["Arial", "sans-serif"],
});

const avenirBold = localFont({
  src: [
    {
      path: "assets/AvenirNext-Bold-01.ttf",
      weight: "700",
      style: "normal",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["Arial Black", "Arial", "sans-serif"],
});

const barriecitoRegular = localFont({
  src: [
    {
      path: "assets/Barriecito-Regular.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["Arial Black", "sans-serif"],
});

const boldFontBold = localFont({
  src: [
    {
      path: "assets/BoldFont-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["Arial Black", "Arial", "sans-serif"],
});

const interSemiBold = localFont({
  src: [
    {
      path: "assets/Inter-SemiBold.ttf",
      weight: "600",
    },
  ],
  weight: "600",
  preload: false,
  fallback: ["Arial Black", "Arial", "sans-serif"],
});

const interBlack = localFont({
  src: [
    {
      path: "assets/Inter-Black.ttf",
      weight: "900",
    },
  ],
  weight: "900",
  preload: false,
  fallback: ["Arial Black", "Arial", "sans-serif"],
});

const changaExtraBold = localFont({
  src: [
    {
      path: "assets/Changa-ExtraBold.ttf",
      weight: "800",
    },
  ],
  weight: "800",
  preload: false,
  fallback: ["Arial Black", "Arial", "sans-serif"],
});

const bungeeRegular = localFont({
  src: [
    {
      path: "assets/Bungee-Regular.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["Arial Black", "sans-serif"],
});

const bangersRegular = localFont({
  src: [
    {
      path: "assets/Bangers-Regular.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["Arial Black", "Arial", "sans-serif"],
});

const fredokaOneRegular = localFont({
  src: [
    {
      path: "assets/FredokaOne-Regular.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["Arial Black", "Arial", "sans-serif"],
});

const fredokaBold = localFont({
  src: [
    {
      path: "assets/Fredoka-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["Arial Black", "Arial", "sans-serif"],
});

const lilitaOneRegular = localFont({
  src: [
    {
      path: "assets/LilitaOne-Regular.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["Arial Black", "Arial", "sans-serif"],
});

const montserratLight = localFont({
  src: [
    {
      path: "assets/Montserrat-Light.ttf",
      weight: "300",
    },
    {
      path: "assets/Montserrat-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "300",
  preload: false,
  fallback: ["Arial", "sans-serif"],
});

const montserratBold = localFont({
  src: [
    {
      path: "assets/Montserrat-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["Arial", "sans-serif"],
});

const montserratExtraBold = localFont({
  src: [
    {
      path: "assets/Montserrat-ExtraBold.ttf",
      weight: "800",
    },
  ],
  weight: "800",
  preload: false,
  fallback: ["Arial Black", "Arial", "sans-serif"],
});

const montserratBlack = localFont({
  src: [
    {
      path: "assets/Montserrat-Black.ttf",
      weight: "900",
    },
  ],
  weight: "900",
  preload: false,
  fallback: ["Arial Black", "Arial", "sans-serif"],
});

const nootRegular = localFont({
  src: [
    {
      path: "assets/Noot Regular.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["cursive"],
});

const staatlichesRegular = localFont({
  src: [
    {
      path: "assets/Staatliches-Regular.ttf",
      weight: "400",
    },
  ],
  weight: "400",
  preload: false,
  fallback: ["Arial Black", "Arial", "sans-serif"],
});

const playfairDisplayBold = localFont({
  src: [
    {
      path: "assets/PlayfairDisplay-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["Arial Black", "Arial", "sans-serif"],
});

const robotoCondensedBold = localFont({
  src: [
    {
      path: "assets/RobotoCondensed-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["Arial Black", "Arial", "sans-serif"],
});

const pacificoRegular = localFont({
  src: [
    {
      path: "assets/Pacifico-Regular.ttf",
      weight: "400",
    },
  ],
  weight: "400",
  preload: false,
  fallback: ["cursive"],
});

const rubikWetPaintRegular = localFont({
  src: [
    {
      path: "assets/RubikWetPaint-Regular.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["Arial Black", "sans-serif"],
});

const rubikBlack = localFont({
  src: [
    {
      path: "assets/Rubik-Black.ttf",
      weight: "900",
    },
  ],
  weight: "900",
  preload: false,
  fallback: ["Arial Black", "Arial", "sans-serif"],
});

const ryeRegular = localFont({
  src: [
    {
      path: "assets/Rye-Regular.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["Arial", "sans-serif"],
});

const secularOneRegular = localFont({
  src: [
    {
      path: "assets/SecularOne-Regular.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["cursive"],
});

const silkscreenRegular = localFont({
  src: [
    {
      path: "assets/Silkscreen-Regular.ttf",
      weight: "400",
    },
    {
      path: "assets/Silkscreen-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "400",
  preload: false,
  fallback: ["Arial", "sans-serif"],
});

const lobsterRegular = localFont({
  src: [
    {
      path: "assets/Lobster-Regular.ttf",
      weight: "400",
    },
  ],
  weight: "400",
  preload: false,
  fallback: ["cursive"],
});

const acmeRegular = localFont({
  src: [
    {
      path: "assets/Acme-Regular.ttf",
      weight: "400",
    },
  ],
  weight: "400",
  preload: false,
  fallback: ["sans-serif"],
});

const alefBold = localFont({
  src: [
    {
      path: "assets/Alef-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["sans-serif"],
});

const alexandriaSemiBold = localFont({
  src: [
    {
      path: "assets/Alexandria-SemiBold.ttf",
      weight: "600",
    },
  ],
  weight: "600",
  preload: false,
  fallback: ["sans-serif"],
});

const amaticScBold = localFont({
  src: [
    {
      path: "assets/AmaticSC-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["cursive"],
});

const arialBoldMT = localFont({
  src: [
    {
      path: "assets/Arial-BoldMT.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["sans-serif"],
});

const assistantExtraBold = localFont({
  src: [
    {
      path: "assets/Assistant-ExtraBold.ttf",
      weight: "800",
    },
  ],
  weight: "800",
  preload: false,
  fallback: ["sans-serif"],
});

const balooBhaijaan2Bold = localFont({
  src: [
    {
      path: "assets/BalooBhaijaan2-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["cursive"],
});

const bebasNeueRegular = localFont({
  src: [
    {
      path: "assets/BebasNeue-Regular.ttf",
      weight: "400",
    },
  ],
  weight: "400",
  preload: false,
  fallback: ["sans-serif"],
});

const blakaRegular = localFont({
  src: [
    {
      path: "assets/Blaka-Regular.ttf",
      weight: "400",
    },
  ],
  weight: "400",
  preload: false,
  fallback: ["cursive"],
});

const bonaNovaBold = localFont({
  src: [
    {
      path: "assets/BonaNova-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["serif"],
});

const frankRuhlLibreBold = localFont({
  src: [
    {
      path: "assets/FrankRuhlLibre-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["serif"],
});

const frederickatheGreatRegular = localFont({
  src: [
    {
      path: "assets/FrederickatheGreat-Regular.ttf",
      weight: "400",
    },
  ],
  weight: "400",
  preload: false,
  fallback: ["cursive"],
});

const greybeard16pxBold = localFont({
  src: [
    {
      path: "assets/Greybeard-16px-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["monospace"],
});

const gulzarRegular = localFont({
  src: [
    {
      path: "assets/Gulzar-Regular.ttf",
      weight: "400",
    },
  ],
  weight: "400",
  preload: false,
  fallback: ["serif"],
});

const heeboBlack = localFont({
  src: [
    {
      path: "assets/Heebo-Black.ttf",
      weight: "900",
    },
  ],
  weight: "900",
  preload: false,
  fallback: ["sans-serif"],
});

const heeboLight = localFont({
  src: [
    {
      path: "assets/Heebo-Light.ttf",
      weight: "300",
    },
    {
      path: "assets/Heebo-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "300",
  preload: false,
  fallback: ["sans-serif"],
});

const ibmPlexSansHebrewBold = localFont({
  src: [
    {
      path: "assets/IBMPlexSansHebrew-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["sans-serif"],
});

const karantinaBold = localFont({
  src: [
    {
      path: "assets/Karantina-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["cursive"],
});

const madaBold = localFont({
  src: [
    {
      path: "assets/Mada-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["sans-serif"],
});

const marheySemiBold = localFont({
  src: [
    {
      path: "assets/Marhey-SemiBold.ttf",
      weight: "600",
    },
  ],
  weight: "600",
  preload: false,
  fallback: ["cursive"],
});

const mirzaBold = localFont({
  src: [
    {
      path: "assets/Mirza-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["serif"],
});

const notoKufiArabicBold = localFont({
  src: [
    {
      path: "assets/NotoKufiArabic-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["sans-serif"],
});

const notoSansArabicBold = localFont({
  src: [
    {
      path: "assets/NotoSansArabic-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["sans-serif"],
});

const notoSansArabicSemiBold = localFont({
  src: [
    {
      path: "assets/NotoSansArabic-SemiBold.ttf",
      weight: "600",
    },
  ],
  weight: "600",
  preload: false,
  fallback: ["sans-serif"],
});

const notoSansArabicExtraCondensedBold = localFont({
  src: [
    {
      path: "assets/NotoSansArabic_ExtraCondensed-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["sans-serif"],
});

const notoSansHebrewBold = localFont({
  src: [
    {
      path: "assets/NotoSansHebrew-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["sans-serif"],
});

const notoSansHebrewSemiBold = localFont({
  src: [
    {
      path: "assets/NotoSansHebrew-SemiBold.ttf",
      weight: "600",
    },
  ],
  weight: "600",
  preload: false,
  fallback: ["sans-serif"],
});

const notoSansHebrewExtraCondensedBold = localFont({
  src: [
    {
      path: "assets/NotoSansHebrew_ExtraCondensed-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["sans-serif"],
});

const notoSansHebrewBlack = localFont({
  src: [
    {
      path: "assets/NotoSerifHebrew-Black.ttf",
      weight: "900",
    },
  ],
  weight: "900",
  preload: false,
  fallback: ["sans-serif"],
});

const openSansBold = localFont({
  src: [
    {
      path: "assets/OpenSans-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["sans-serif"],
});

const reemKufiSemiBold = localFont({
  src: [
    {
      path: "assets/ReemKufi-SemiBold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["sans-serif"],
});

const roundedMplus1cBlack = localFont({
  src: [
    {
      path: "assets/RoundedMplus1c-Black.ttf",
      weight: "900",
    },
  ],
  weight: "900",
  preload: false,
  fallback: ["sans-serif"],
});

const shareb = localFont({
  src: [
    {
      path: "assets/Shareb.ttf",
      weight: "400",
    },
  ],
  weight: "400",
  preload: false,
  fallback: ["cursive"],
});

const solitreoRegular = localFont({
  src: [
    {
      path: "assets/Solitreo-Regular.ttf",
      weight: "400",
    },
  ],
  weight: "400",
  preload: false,
  fallback: ["cursive"],
});

// TODO: Include in font group
const srisakdiRegular = localFont({
  src: [
    {
      path: "assets/Srisakdi-Regular.ttf",
      weight: "400",
    },
    {
      path: "assets/Srisakdi-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "400",
  preload: false,
  fallback: ["cursive"],
});

const suezOneRegular = localFont({
  src: [
    {
      path: "assets/SuezOne-Regular.ttf",
      weight: "400",
    },
  ],
  weight: "400",
  preload: false,
  fallback: ["serif"],
});

const tajawalBlack = localFont({
  src: [
    {
      path: "assets/Tajawal-Black.ttf",
      weight: "900",
    },
  ],
  weight: "900",
  preload: false,
  fallback: ["sans-serif"],
});

const tekoMedium = localFont({
  src: [
    {
      path: "assets/Teko-Medium.ttf",
      weight: "500",
    },
    {
      path: "assets/Teko-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "500",
  preload: false,
  fallback: ["sans-serif"],
});

const timesNewRomanPSMT = localFont({
  src: [
    {
      path: "assets/Times New Roman.ttf",
      weight: "400",
    },
    {
      path: "assets/Times New Roman-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "400",
  preload: false,
  fallback: ["serif"],
});

const timesNewRomanPSBoldMT = localFont({
  src: [
    {
      path: "assets/Times New Roman-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["serif"],
});

const tinosBold = localFont({
  src: [
    {
      path: "assets/Tinos-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["serif"],
});

const tinosRegular = localFont({
  src: [
    {
      path: "assets/Tinos-Regular.ttf",
      weight: "400",
    },
    {
      path: "assets/Tinos-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "400",
  preload: false,
  fallback: ["serif"],
});

const titanOneRegular = localFont({
  src: [
    {
      path: "assets/TitanOne-Regular.ttf",
      weight: "400",
    },
  ],
  weight: "400",
  preload: false,
  fallback: ["sans-serif"],
});

const tsAnamilRegular = localFont({
  src: [
    {
      path: "assets/TSAnamil-Regular.otf",
      weight: "400",
    },
  ],
  weight: "400",
  preload: false,
  fallback: ["cursive"],
});

const tsMacaroniRegular = localFont({
  src: [
    {
      path: "assets/TSMacaroni-Regular.otf",
      weight: "400",
    },
  ],
  weight: "400",
  preload: false,
  fallback: ["cursive"],
});

const tsMorabaatBlack = localFont({
  src: [
    {
      path: "assets/TSMorabaat-Black.otf",
      weight: "900",
    },
  ],
  weight: "900",
  preload: false,
  fallback: ["sans-serif"],
});

const tsPixopediaRegular = localFont({
  src: [
    {
      path: "assets/TSPixopedia-Regular.otf",
      weight: "400",
    },
  ],
  weight: "400",
  preload: false,
  fallback: ["monospace"],
});

const ubuntuMonoBold = localFont({
  src: [
    {
      path: "assets/UbuntuMono-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["sans-serif"],
});

const vazirmatnLight = localFont({
  src: [
    {
      path: "assets/Vazirmatn-Light.ttf",
      weight: "300",
    },
    {
      path: "assets/Vazirmatn-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "300",
  preload: false,
  fallback: ["sans-serif"],
});

const komikaAxisRegular = localFont({
  src: [
    {
      path: "assets/KomikaAxis-Regular.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["sans-serif"],
});

const poppinsBold = localFont({
  src: [
    {
      path: "assets/Poppins-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["sans-serif"],
});

const dmSerifRegular = localFont({
  src: [
    {
      path: "assets/DMSerifDisplay-Regular.ttf",
      weight: "400",
    },
  ],
  weight: "400",
  preload: false,
  fallback: ["serif"],
});

const interBold = localFont({
  src: [
    {
      path: "assets/Inter-Bold.ttf",
      weight: "700",
    },
  ],
  weight: "700",
  preload: false,
  fallback: ["Arial Bold", "Arial", "sans-serif"],
});

const instrumentSansSemiBold = localFont({
  src: [
    {
      path: "assets/InstrumentSans-Semibold.ttf",
      weight: "600",
    },
  ],
  weight: "600",
  preload: false,
  fallback: ["Arial Bold", "Arial", "sans-serif"],
});

export interface FontItem {
  name: string;
  displayName: string;
  capitalized?: boolean;
  hasBold?: boolean;
}

export interface SubtitleFontItem extends FontItem {
  groupName: string;
  overrides?: {
    [languageCode: string]: FontItem;
  };
}

export const captionFontList: SubtitleFontItem[] = [
  {
    groupName: "Bold",
    name: "TheBoldFont-Bold",
    displayName: "Bold Font",
    capitalized: true,
    overrides: {
      ar: { displayName: "Cairo (Black)", name: "Cairo-Black" },
      iw: { displayName: "Heebo (Black)", name: "Heebo-Black" },
    },
  },
  {
    groupName: "Bold V2",
    name: "TheBoldSlay-Bold",
    displayName: "Bold Slay",
    capitalized: true,
    overrides: {
      ar: { displayName: "Cairo (Black)", name: "Cairo-Black" },
      iw: { displayName: "Heebo (Black)", name: "Heebo-Black" },
    },
  },
  {
    groupName: "Classic",
    name: "AvenirNext-Bold",
    displayName: "Avenir Next (Bold)",
    overrides: {
      ar: { displayName: "Arial (Bold)", name: "Arial-BoldMT" },
      iw: { displayName: "Alef (Bold)", name: "Alef-Bold" },
    },
  },
  {
    groupName: "Bar",
    name: "Helvetica",
    displayName: "Helvetica",
    hasBold: true,
    overrides: {
      ar: {
        displayName: "Noto Sans (Arabic, Extra Condensed, Bold)",
        name: "NotoSansArabicExtraCondensed-Bold",
      },
      iw: { displayName: "Noto Sans (Hebrew, Bold)", name: "NotoSansHebrew-Bold" },
    },
  },
  {
    groupName: "Pacific",
    name: "Pacifico-Regular",
    displayName: "Pacifico",
    hasBold: true,
    overrides: {
      ar: { displayName: "Mirza (Bold)", name: "Mirza-Bold" },
      iw: { displayName: "Solitreo", name: "Solitreo-Regular" },
    },
  },
  {
    groupName: "Comic",
    name: "Bangers-Regular",
    displayName: "Bangers",
    capitalized: true,
    overrides: {
      ar: { displayName: "Marhey (Semi-Bold)", name: "Marhey-SemiBold" },
      iw: { displayName: "Karantina (Bold)", name: "Karantina-Bold" },
    },
  },
  {
    groupName: "Lobster",
    name: "Lobster-Regular",
    displayName: "Lobster",
    hasBold: true,
    overrides: {
      ar: { displayName: "TS Macaroni", name: "TSMacaroni-Regular" },
      iw: { displayName: "Noto Serif (Hebrew, Black)", name: "NotoSerifHebrew-Black" },
    },
  },
  {
    groupName: "Capital",
    name: "Staatliches-Regular",
    displayName: "Staatliches",
    capitalized: true,
    hasBold: true,
    overrides: {
      ar: { displayName: "Noto Kufi (Arabic, Bold)", name: "NotoKufiArabic-Bold" },
      iw: { displayName: "Open Sans (Bold)", name: "OpenSans-Bold" },
    },
  },
  {
    groupName: "Sharp",
    name: "RobotoCondensed-Bold",
    displayName: "Roboto (Condensed, Bold)",
    overrides: {
      ar: {
        displayName: "Noto Sans (Arabic, Extra Condensed, Bold)",
        name: "NotoSansArabicExtraCondensed-Bold",
      },
      iw: {
        displayName: "Noto Sans (Hebrew, Extra Condensed, Bold)",
        name: "NotoSansHebrewExtraCondensed-Bold",
      },
    },
  },
  {
    groupName: "Pop",
    name: "Bungee-Regular",
    displayName: "Bungee",
    capitalized: true,
    overrides: {
      ar: { displayName: "TS Morabaat (Black)", name: "TSMorabaat-Black" },
      iw: { displayName: "Suez One", name: "SuezOne-Regular" },
    },
  },
  {
    groupName: "Reels",
    name: "HelveticaNeue-Bold",
    displayName: "Reels",
    overrides: {
      ar: { displayName: "Noto Sans (Arabic, Semi-Bold)", name: "NotoSansArabic-SemiBold" },
      iw: { displayName: "Noto Sans (Hebrew, Semi-Bold)", name: "NotoSansHebrew-SemiBold" },
    },
  },
  {
    groupName: "Barriecito",
    name: "Barriecito-Regular",
    displayName: "Barriecito",
    overrides: {
      ar: { displayName: "TS Anamil", name: "TSAnamil-Regular" },
      iw: { displayName: "Amatic SC (Bold)", name: "AmaticSC-Bold" },
    },
  },
  {
    groupName: "Wetpaint",
    name: "RubikWetPaint-Regular",
    displayName: "Rubik Wet Paint",
    overrides: {
      ar: { displayName: "Blaka", name: "Blaka-Regular" },
    },
  },
  {
    groupName: "Rubik",
    name: "Rubik-Black",
    displayName: "Rubik (Black)",
  },
  {
    groupName: "Rye",
    name: "Rye-Regular",
    displayName: "Rye",
    overrides: {
      ar: { displayName: "Reem Kufi (Semi-Bold)", name: "ReemKufi-SemiBold" },
      iw: { displayName: "Frank Ruhl Libre (Bold)", name: "FrankRuhlLibre-Bold" },
    },
  },
  {
    groupName: "Lilita",
    name: "LilitaOne-Regular",
    displayName: "Lilita One",
    overrides: {
      ar: { displayName: "Tajawal (Black)", name: "Tajawal-Black" },
      iw: { displayName: "M PLUS Rounded 1c (Black)", name: "RoundedMplus1c-Black" },
    },
  },
  {
    groupName: "Fredoka",
    name: "FredokaOne-Regular",
    displayName: "Fredoka One",
    overrides: {
      ar: { displayName: "Baloo Bhaijaan 2 (Bold)", name: "BalooBhaijaan2-Bold" },
      iw: { displayName: "Fredoka (Bold)", name: "Fredoka-Bold" },
    },
  },
  {
    groupName: "Silkscreen",
    name: "Silkscreen-Regular",
    displayName: "Silkscreen",
    hasBold: true,
    capitalized: true,
    overrides: {
      ar: { displayName: "TS Pixopedia", name: "TSPixopedia-Regular" },
      iw: { displayName: "Greybeard (Bold)", name: "Greybeard16px-Bold" },
    },
  },
  {
    groupName: "Inter",
    name: "Inter-Black",
    displayName: "Inter (Black)",
    overrides: {
      ar: { displayName: "Times New Roman PS MT", name: "TimesNewRomanPSMT", hasBold: true },
      iw: { displayName: "Tinos (Bold)", name: "Tinos-Bold" },
    },
  },
  {
    groupName: "Semi-Bold",
    name: "Inter-SemiBold",
    displayName: "Inter (Semi-Bold)",
    overrides: {
      ar: { displayName: "Times New Roman PS MT (Bold)", name: "TimesNewRomanPS-BoldMT" },
      iw: { displayName: "Tinos", name: "Tinos-Regular", hasBold: true },
    },
  },
  {
    groupName: "Changa",
    name: "Changa-ExtraBold",
    displayName: "Changa (Extra-Bold)",
    overrides: { iw: { displayName: "Assistant (Extra-Bold)", name: "Assistant-ExtraBold" } },
  },
  {
    groupName: "Playfair",
    displayName: "Playfair Display (Bold)",
    name: "PlayfairDisplay-Bold",
    overrides: {
      ar: { displayName: "Gulzar", name: "Gulzar-Regular" },
      iw: { displayName: "Bona Nova (Bold)", name: "BonaNova-Bold" },
    },
  },
  {
    groupName: "Poppins",
    displayName: "Poppins (Bold)",
    name: "Poppins-Bold",
  },
  {
    groupName: "Montserrat",
    name: "Montserrat-ExtraBold",
    displayName: "Montserrat (Extra-Bold)",
    overrides: {
      ar: { displayName: "Alexandria (Semi-Bold)", name: "Alexandria-SemiBold" },
      iw: { displayName: "IBM Plex Sans Hebrew (Bold)", name: "IBMPlexSansHebrew-Bold" },
    },
  },
  {
    groupName: "Montserrat Black",
    name: "Montserrat-Black",
    displayName: "Montserrat (Black)",
    overrides: {
      // TODO: Check Montserrat Black overrides
      ar: { displayName: "Alexandria (Semi-Bold)", name: "Alexandria-SemiBold" },
      iw: { displayName: "IBM Plex Sans Hebrew (Bold)", name: "IBMPlexSansHebrew-Bold" },
    },
  },
  {
    groupName: "Montserrat Bold",
    name: "Montserrat-Bold",
    displayName: "Montserrat (Bold)",
    overrides: {
      // TODO: Check Montserrat Bold overrides
      ar: { displayName: "Alexandria (Semi-Bold)", name: "Alexandria-SemiBold" },
      iw: { displayName: "IBM Plex Sans Hebrew (Bold)", name: "IBMPlexSansHebrew-Bold" },
    },
  },
  {
    groupName: "Montserrat Light",
    name: "Montserrat-Light",
    displayName: "Montserrat (Light)",
    hasBold: true,
  },
  {
    groupName: "Anson",
    name: "Anson-Regular",
    displayName: "Anson",
    hasBold: true,
    overrides: {
      ar: { displayName: "Vazirmatn (Light)", name: "Vazirmatn-Light", hasBold: true },
      iw: { displayName: "Heebo (Light)", name: "Heebo-Light", hasBold: true },
    },
  },
  {
    groupName: "Secular",
    name: "SecularOne-Regular",
    displayName: "Secular One",
    overrides: {
      ar: { displayName: "Mada (Bold)", name: "Mada-Bold" },
      iw: { displayName: "", name: "" },
    },
  },
  {
    groupName: "Noot",
    name: "Noot Regular",
    displayName: "Noot",
    overrides: { ar: { displayName: "Shareb", name: "Shareb" } },
  },
  {
    groupName: "Komika Axis",
    name: "Komika-Axis",
    displayName: "Komika Axis",
    overrides: {},
  },
  {
    groupName: "Avenir",
    name: "AvenirNext-Regular",
    displayName: "Avenir Next",
    hasBold: true,
    overrides: {},
  },
  {
    groupName: "Silka",
    name: "Silka-Regular",
    displayName: "Silka",
    hasBold: true,
    overrides: {},
  },
  {
    groupName: "Teko",
    name: "Teko-Medium",
    displayName: "Teko",
    hasBold: true,
    overrides: {},
  },
  {
    groupName: "DM Serif",
    name: "DMSerifDisplay-Regular",
    displayName: "Dm Serif Regular",
    hasBold: true,
    overrides: {},
  },
  {
    groupName: "Instrument Sans",
    name: "InstrumentSans-SemiBold",
    displayName: "Instrument Sans SemiBold",
    hasBold: true,
    overrides: {},
  },
];

const captionFontAliases: Record<string, string> = {
  "the bold font": "TheBoldSlay-Bold",
  "the bold slay": "TheBoldSlay-Bold",
  "boldfont-bold": "TheBoldFont-Bold",
  "avenir next": "AvenirNext-Regular",
  avenirnext: "AvenirNext-Regular",
  silka: "Silka-Regular",
};

const captionFontMap: Record<string, string> = {
  "anson-regular": ansonRegular.style.fontFamily,
  "avenirnext-bold": avenirBold.style.fontFamily,
  "bangers-regular": bangersRegular.style.fontFamily,
  "barriecito-regular": barriecitoRegular.style.fontFamily,
  "bungee-regular": bungeeRegular.style.fontFamily,
  "changa-extrabold": changaExtraBold.style.fontFamily,
  "fredokaone-regular": fredokaOneRegular.style.fontFamily,
  "inter-black": interBlack.style.fontFamily,
  "inter-semibold": interSemiBold.style.fontFamily,
  "lilitaone-regular": lilitaOneRegular.style.fontFamily,
  "lobster-regular": lobsterRegular.style.fontFamily,
  "montserrat-extrabold": montserratExtraBold.style.fontFamily,
  "montserrat-bold": montserratBold.style.fontFamily,
  "montserrat-black": montserratBlack.style.fontFamily,
  "montserrat-light": montserratLight.style.fontFamily,
  "noot regular": nootRegular.style.fontFamily,
  "pacifico-regular": pacificoRegular.style.fontFamily,
  "playfairdisplay-bold": playfairDisplayBold.style.fontFamily,
  "robotocondensed-bold": robotoCondensedBold.style.fontFamily,
  "rubikwetpaint-regular": rubikWetPaintRegular.style.fontFamily,
  "rubik-black": rubikBlack.style.fontFamily,
  "rye-regular": ryeRegular.style.fontFamily,
  "secularone-regular": secularOneRegular.style.fontFamily,
  "silkscreen-regular": silkscreenRegular.style.fontFamily,
  "staatliches-regular": staatlichesRegular.style.fontFamily,
  "theboldslay-bold": theBoldSlay.style.fontFamily,
  "theboldfont-bold": boldFontBold.style.fontFamily,
  "avenirnext-regular": avenirNext.style.fontFamily,
  "silka-regular": localFonts.style.fontFamily,
  "acme-regular": acmeRegular.style.fontFamily,
  "alef-bold": alefBold.style.fontFamily,
  "alexandria-semibold": alexandriaSemiBold.style.fontFamily,
  "amaticsc-bold": amaticScBold.style.fontFamily,
  "arial-boldmt": arialBoldMT.style.fontFamily,
  "assistant-extrabold": assistantExtraBold.style.fontFamily,
  "baloobhaijaan2-bold": balooBhaijaan2Bold.style.fontFamily,
  "bebasneue-regular": bebasNeueRegular.style.fontFamily,
  "blaka-regular": blakaRegular.style.fontFamily,
  "bonanova-bold": bonaNovaBold.style.fontFamily,
  "frankruhllibre-bold": frankRuhlLibreBold.style.fontFamily,
  "frederickathegreat-regular": frederickatheGreatRegular.style.fontFamily,
  "fredoka-bold": fredokaBold.style.fontFamily,
  "greybeard16px-bold": greybeard16pxBold.style.fontFamily,
  "gulzar-regular": gulzarRegular.style.fontFamily,
  "heebo-black": heeboBlack.style.fontFamily,
  "heebo-light": heeboLight.style.fontFamily,
  "ibmplexsanshebrew-bold": ibmPlexSansHebrewBold.style.fontFamily,
  "karantina-bold": karantinaBold.style.fontFamily,
  "mada-bold": madaBold.style.fontFamily,
  "marhey-semibold": marheySemiBold.style.fontFamily,
  "mirza-bold": mirzaBold.style.fontFamily,
  "notokufiarabic-bold": notoKufiArabicBold.style.fontFamily,
  "notosansarabic-bold": notoSansArabicBold.style.fontFamily,
  "notosansarabic-semibold": notoSansArabicSemiBold.style.fontFamily,
  "notosansarabicextracondensed-bold": notoSansArabicExtraCondensedBold.style.fontFamily,
  "notosanshebrew-bold": notoSansHebrewBold.style.fontFamily,
  "notosanshebrew-semibold": notoSansHebrewSemiBold.style.fontFamily,
  "notosanshebrewextracondensed-bold": notoSansHebrewExtraCondensedBold.style.fontFamily,
  "notoserifhebrew-black": notoSansHebrewBlack.style.fontFamily,
  "opensans-bold": openSansBold.style.fontFamily,
  "reemkufi-semibold": reemKufiSemiBold.style.fontFamily,
  "roundedmplus1c-black": roundedMplus1cBlack.style.fontFamily,
  shareb: shareb.style.fontFamily,
  "solitreo-regular": solitreoRegular.style.fontFamily,
  "srisakdi-regular": srisakdiRegular.style.fontFamily,
  "suezone-regular": suezOneRegular.style.fontFamily,
  "tajawal-black": tajawalBlack.style.fontFamily,
  "teko-medium": tekoMedium.style.fontFamily,
  timesnewromanpsmt: timesNewRomanPSMT.style.fontFamily,
  "timesnewromanps-boldmt": timesNewRomanPSBoldMT.style.fontFamily,
  "tinos-bold": tinosBold.style.fontFamily,
  "tinos-regular": tinosRegular.style.fontFamily,
  "titanone-regular": titanOneRegular.style.fontFamily,
  "tsanamil-regular": tsAnamilRegular.style.fontFamily,
  "tsmacaroni-regular": tsMacaroniRegular.style.fontFamily,
  "tsmorabaat-black": tsMorabaatBlack.style.fontFamily,
  "tspixopedia-regular": tsPixopediaRegular.style.fontFamily,
  "ubuntumono-bold": ubuntuMonoBold.style.fontFamily,
  "vazirmatn-light": vazirmatnLight.style.fontFamily,
  "komika-axis": komikaAxisRegular.style.fontFamily,
  "poppins-bold": poppinsBold.style.fontFamily,
  "helveticaneue-bold": interBold.style.fontFamily, // Use Inter Bold as a fallback
  "dmserifdisplay-regular": dmSerifRegular.style.fontFamily,
  "instrumentsans-semibold": instrumentSansSemiBold.style.fontFamily, // Use Inter Bold as a fallback
};

export function getCanonicalFontName(fontName: string) {
  const lowerCaseFontName = fontName.toLowerCase();
  // If the name is an alias, return the canonical name
  if (lowerCaseFontName in captionFontAliases) {
    return captionFontAliases[lowerCaseFontName];
  }
  // If the name is a known font, return it
  if (lowerCaseFontName in captionFontMap) {
    return fontName;
  }
  // Check if the name is an internal font family name,
  // and return the canonical name if it is
  const foundFont = captionFontList.find(
    (font) => captionFontMap[font.name.toLowerCase()] === fontName
  );
  if (foundFont) {
    return foundFont.name;
  }
  return fontName;
}

export function getLocalizedFontFromItem(
  fontItem: SubtitleFontItem,
  countryCode: string
): FontItem {
  if (fontItem.overrides) {
    countryCode = countryCode.toLowerCase();
    const languageCode = countryCode.split("-")[0];
    if (countryCode in fontItem.overrides) {
      return fontItem.overrides[countryCode];
    } else if (languageCode in fontItem.overrides) {
      return fontItem.overrides[languageCode];
    }
  }
  return fontItem;
}

export function getSubtitleFontItem(fontName: string) {
  const foundFont = captionFontList.find(
    (font) => font.name.toLowerCase() === fontName.toLowerCase()
  );
  return foundFont ?? null;
}

export function getFontFamily(fontName: string, countryCode: string) {
  const foundFont = captionFontList.find(
    (font) => font.name.toLowerCase() === fontName.toLowerCase()
  );
  let trueFontName = fontName;
  if (foundFont) {
    trueFontName = getLocalizedFontFromItem(foundFont, countryCode).name;
  }
  return captionFontMap[trueFontName.toLowerCase()] || fontName;
}
