import glRotateVert from "./gl-rotate.vert";
import glSimpleFrag from "./gl-simple.frag";
import {
  createIdentityMatrix,
  createRotationMatrix,
  createScaleMatrix,
  createWebGLArrayBuffer,
  initShaderProgram,
  setVertexAttributeBuffer,
} from "./webgl";

export function initShakeProgram(gl: WebGLRenderingContext) {
  const shaderProgram = initShaderProgram(gl, glRotateVert, glSimpleFrag);
  const programInfo = {
    program: shaderProgram,
    attribLocations: {
      vertexPosition: gl.getAttribLocation(shaderProgram, "aVertexPosition"),
      textureCoord: gl.getAttribLocation(shaderProgram, "aTextureCoord"),
    },
    uniformLocations: {
      identityMatrix: gl.getUniformLocation(shaderProgram, "uIdentityMatrix"),
      scaleUpMatrix: gl.getUniformLocation(shaderProgram, "uScaleUpMatrix"),
      scaleDownMatrix: gl.getUniformLocation(shaderProgram, "uScaleDownMatrix"),
      rotationMatrix: gl.getUniformLocation(shaderProgram, "uRotationMatrix"),
      uSampler: gl.getUniformLocation(shaderProgram, "uSampler"),
    },
  };
  const buffers = {
    positionBuffer: createWebGLArrayBuffer(gl, [-1.0, -1.0, 1.0, -1.0, 1.0, 1.0, -1.0, 1.0]),
    textureCoordBuffer: createWebGLArrayBuffer(gl, [0.0, 1.0, 1.0, 1.0, 1.0, 0.0, 0.0, 0.0]),
    indicesBuffer: createWebGLArrayBuffer(gl, [0, 1, 2, 0, 2, 3], "element_array_buffer"),
  };

  return {
    programInfo,
    buffers,
  };
}

export type ShakeProgramData = ReturnType<typeof initShakeProgram>;

export function drawShakeProgram(
  gl: WebGLRenderingContext,
  glTexture: WebGLTexture,
  { programInfo, buffers }: ShakeProgramData,
  rotationAngle: number
) {
  gl.blendEquation(gl.FUNC_ADD);
  gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

  const canvasWidth = gl.canvas.width;
  const canvasHeight = gl.canvas.height;

  setVertexAttributeBuffer(
    gl,
    buffers.positionBuffer,
    programInfo.attribLocations.vertexPosition,
    2
  );
  setVertexAttributeBuffer(
    gl,
    buffers.textureCoordBuffer,
    programInfo.attribLocations.textureCoord,
    2
  );

  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffers.indicesBuffer);

  gl.useProgram(programInfo.program);

  const aspectRatio = canvasWidth / canvasHeight;
  const scaleUp = { x: 1, y: aspectRatio };
  const scaleDown = { x: 1, y: 1 / aspectRatio };

  const identityMatrix = createIdentityMatrix();
  const rotationMatrix = createRotationMatrix(rotationAngle);
  const scaledUpMatrix = createScaleMatrix(scaleUp);
  const scaledDownMatrix = createScaleMatrix(scaleDown);

  gl.uniformMatrix4fv(programInfo.uniformLocations.identityMatrix, false, identityMatrix.data);
  gl.uniformMatrix4fv(programInfo.uniformLocations.rotationMatrix, false, rotationMatrix.data);
  gl.uniformMatrix4fv(programInfo.uniformLocations.scaleUpMatrix, false, scaledUpMatrix.data);
  gl.uniformMatrix4fv(programInfo.uniformLocations.scaleDownMatrix, false, scaledDownMatrix.data);

  gl.activeTexture(gl.TEXTURE0);
  gl.bindTexture(gl.TEXTURE_2D, glTexture);
  gl.uniform1i(programInfo.uniformLocations.uSampler, 0);

  gl.viewport(0, 0, canvasWidth, canvasHeight);

  const vertexCount = 6;
  const type = gl.UNSIGNED_SHORT;
  const offset = 0;
  gl.drawElements(gl.TRIANGLES, vertexCount, type, offset);
}
