import { ProjectEntityBag } from "../types";

import { CURRENT_PROJECT_VERSION, UnsupportedProjectVersionError } from "./defs";
import { AnyProjectVersion, PROJECT_VERSION_CHANGELOG } from "./defs";

export type ProjectMigrationResult =
  | { type: "unsupported" }
  | { type: "up-to-date" }
  | { type: "migrated"; entities: ProjectEntityBag; version: AnyProjectVersion };

// NOTE: this does not actually save the updated entities to the database
// it only returns the updated entities and the new version
export async function migrateProjectToCurrentVersion(
  entities: ProjectEntityBag
): Promise<ProjectMigrationResult> {
  const projectVersion = entities.project.version ?? null;

  const projectVersionIndex = PROJECT_VERSION_CHANGELOG.findIndex(
    (version) => version.version === projectVersion
  );

  if (projectVersionIndex === -1) {
    return { type: "unsupported" };
  } else if (projectVersionIndex === 0) {
    return { type: "up-to-date" };
  }

  try {
    let updatedEntities = entities;

    // migrate backwards through the changelog to the most recent version
    for (let i = projectVersionIndex - 1; i >= 0; i--) {
      const migration = PROJECT_VERSION_CHANGELOG[i];
      updatedEntities = await migration.migrate(updatedEntities);
    }

    updatedEntities.project.version = CURRENT_PROJECT_VERSION;

    return { type: "migrated", entities: updatedEntities, version: CURRENT_PROJECT_VERSION };
  } catch (error) {
    if (error instanceof UnsupportedProjectVersionError) {
      return { type: "unsupported" };
    }

    throw error;
  }
}
