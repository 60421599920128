import React from "react";

import { styled } from "~/theme";

function Google({ ...props }) {
  return (
    <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.1874 8.27576C15.94 8.27576 17.1222 9.0328 17.7963 9.66539L20.4304 7.09354C18.8126 5.58983 16.7074 4.66687 14.1874 4.66687C10.537 4.66687 7.38442 6.76168 5.84961 9.81057L8.86739 12.1543C9.62442 9.90391 11.7192 8.27576 14.1874 8.27576Z"
        fill="#EA4335"
      />
      <path
        d="M23.1475 14.2076C23.1475 13.4402 23.0853 12.8802 22.9505 12.2995H14.1875V15.7632H19.3312C19.2275 16.6239 18.6675 17.9202 17.4231 18.7913L20.3682 21.0728C22.1312 19.4447 23.1475 17.0491 23.1475 14.2076Z"
        fill="#4285F4"
      />
      <path
        d="M8.87722 15.8461C8.68018 15.2654 8.56611 14.6432 8.56611 14.0002C8.56611 13.3572 8.68018 12.735 8.86685 12.1543L5.84907 9.81058C5.21648 11.0758 4.85352 12.4965 4.85352 14.0002C4.85352 15.5039 5.21648 16.9247 5.84907 18.1898L8.87722 15.8461Z"
        fill="#FBBC05"
      />
      <path
        d="M14.1868 23.3335C16.7068 23.3335 18.8223 22.5039 20.3675 21.0728L17.4223 18.7913C16.6342 19.3409 15.5764 19.7246 14.1868 19.7246C11.7186 19.7246 9.62382 18.0965 8.87715 15.8461L5.85938 18.1898C7.39419 21.2387 10.5364 23.3335 14.1868 23.3335Z"
        fill="#34A853"
      />
    </svg>
  );
}

export default styled(Google, {
  width: 24,
  height: 24,
});
