import { PseudoRandomGenerator } from "stable-pseudo-rng";

import type { FaceData } from "../../project/utils/faceData/face-data";
import type { AISceneDataWithStyles, AISceneWithStyle, AIVideoMetadata } from "../AIScenes.types";
import type { CommandList } from "../CommandList.types";
import type { ClientDrivenAIEditVideoStyle } from "../services/AIEditStyles";
import type { AICollageWithSceneIndex } from "../services/Collages";
import { parseCommandListTimestamps } from "../utils/parseCommandListTimestamps";
import { populateAndGetMainVideo } from "../utils/populateAndGetMainVideo";

import { generateAnimationCommandsForScene } from "./effects/gen-animation-commands";
import { generateBackgroundCommandsForScene } from "./effects/gen-background-commands";
import { generateCaptionCommandsForScene } from "./effects/gen-captions-commands";
import { generateFilterCommandsForScene } from "./effects/gen-filter-commands";
import { generateFrameCommandsForScene } from "./effects/gen-frame-commands";
import { generateGlobalCommands } from "./effects/gen-global-commands";
import { generateImageCommandsForScene } from "./effects/gen-image-commands";
import { generateImageFontCommandsForScene } from "./effects/gen-imagefont-commands";
import { generateOverlayCommandsForScene } from "./effects/gen-overlay-commands";
import { generateSoundCommandsForScene } from "./effects/gen-sound-commands";
import { generateGlobalTransitionCommands } from "./effects/gen-transition-commands";
import { generateZoomCommandsForScene } from "./effects/gen-zoom-commands";
import {
  EffectsCommandGeneratorFunction,
  EffectsCommandGeneratorFunctionContext,
} from "./effects/types";

const COMMAND_GENERATORS: EffectsCommandGeneratorFunction[] = [
  generateFrameCommandsForScene,
  generateSoundCommandsForScene,
  generateOverlayCommandsForScene,
  generateCaptionCommandsForScene,
  generateImageCommandsForScene,
  generateAnimationCommandsForScene,
  generateZoomCommandsForScene,
  generateBackgroundCommandsForScene,
  generateFilterCommandsForScene,
  generateImageFontCommandsForScene,
];

// NOTE: many of the generators assume `mainOffset` is populated on the scene
export function generateCommandListForScene(
  scene: AISceneWithStyle,
  sceneIndex: number,
  ctx: EffectsCommandGeneratorFunctionContext
): CommandList {
  const commands = COMMAND_GENERATORS.flatMap((generator) =>
    generator(scene, sceneIndex, ctx)
  ).filter(Boolean) as CommandList;

  return parseCommandListTimestamps(commands);
}

/**
 * translates scenes into commands
 */
export function generateRecipeCommandList(
  style: ClientDrivenAIEditVideoStyle,
  sceneData: AISceneDataWithStyles,
  collages: AICollageWithSceneIndex[],
  faceData: FaceData | undefined,
  videoMetadata: AIVideoMetadata
): CommandList {
  const prng = new PseudoRandomGenerator();

  const { mainVideoCommand, mainEDL, mainTotal } = populateAndGetMainVideo(
    sceneData,
    videoMetadata
  );

  const ctx: EffectsCommandGeneratorFunctionContext = {
    prng,
    style,
    faceData,
    videoMetadata,
    collages,
    mainEDL,
  };

  // scene based commands
  const combinedCommands = sceneData.scenes.flatMap((scene, i) =>
    generateCommandListForScene(scene, i, ctx)
  );

  // special global commands -- new types of commands should NOT be added to this list
  const transitionCommands = generateGlobalTransitionCommands(style, sceneData.scenes, prng);
  const globalCommands = generateGlobalCommands(style, mainTotal, prng);

  // todo - generate music
  // todo - need to check ordering
  const commandList: CommandList = [
    mainVideoCommand,
    ...transitionCommands,
    ...globalCommands,
    ...combinedCommands,
  ];

  return parseCommandListTimestamps(commandList);
}
