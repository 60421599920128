import { keyframes, styled } from "~/theme";

const shine = keyframes({
  "0%": {
    backgroundPositionX: "100%",
  },
  "100%": {
    backgroundPositionX: "-300%",
  },
});

export const BaseSkeleton = styled("span", {
  animation: `3s ${shine} cubic-bezier(0.25, 0.75, 1, 0.5) 0s infinite`,
  backgroundImage:
    "linear-gradient(to right, $grey-800 0%, $grey-900 20%, $grey-800 40%, $grey-800 100%)",
  backgroundSize: "200% 100%",
  backgroundRepeat: "no-repeat",
  borderRadius: "6px",
  backgroundColor: "$grey-800",
  display: "inline-block",
});
