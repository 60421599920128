import { keyframes, styled } from "~/theme";

const spin = keyframes({
  "0%, 25%, 37.5%, 50%, 62.5%, 75%, 87.5%, 100%": {
    transform: "rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%))",
  },

  "50%": {
    transform: "rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1.5%))",
  },
});

export const Spinner = styled("div", {
  "& div": {
    borderRadius: "8px",
    position: "absolute",
    width: "calc(var(--element-width) * 0.5 * 1px)",
    height: "calc(var(--element-height) * 1.5 * 1px)",
    transform: "rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%))",
    animation: `${spin} 2s calc(var(--delay) * 2s) infinite ease`,

    "&:nth-child(1)": {
      "--delay": "0.125",
      "--rotation": "45",
      "--translation": "150",
    },

    "&:nth-child(2)": {
      "--delay": "0.25",
      "--rotation": "90",
      "--translation": "150",
    },

    "&:nth-child(3)": {
      "--delay": "0.375",
      "--rotation": "135",
      "--translation": "150",
    },

    "&:nth-child(4)": {
      "--delay": "0.5",
      "--rotation": "180",
      "--translation": "150",
    },

    "&:nth-child(5)": {
      "--delay": "0.625",
      "--rotation": "225",
      "--translation": "150",
    },

    "&:nth-child(6)": {
      "--delay": "0.75",
      "--rotation": "270",
      "--translation": "150",
    },

    "&:nth-child(7)": {
      "--delay": "0.875",
      "--rotation": "315",
      "--translation": "150",
    },

    "&:nth-child(8)": {
      "--delay": "1",
      "--rotation": "360",
      "--translation": "150",
    },
  },
  defaultVariants: {
    size: "sm",
    theme: "dark",
    inline: false,
  },
  variants: {
    theme: {
      dark: {
        "& div": {
          background: "#7E12FF",
        },
      },
      white: {
        "& div": {
          background: "$grey-100",
        },
      },
      currentColor: {
        "& div": {
          background: "currentColor",
        },
      },
    },
    size: {
      xs: {
        "--element-height": "2",
        "--element-width": "2.2",
      },
      sm: {
        "--element-height": "3.4",
        "--element-width": "4",
      },
      md: {
        "--element-height": "5.1",
        "--element-width": "6",
      },
    },
    inline: {
      true: {
        display: "inline-block",
        position: "relative",
        "& div": {
          left: "calc(50% - var(--element-width) * 0.25 * 1px)",
          top: "calc(50% - var(--element-height) * 0.75 * 1px)",
        },
      },
      false: {
        left: "50%",
        position: "absolute",
        top: "50%",
      },
    },
  },
  compoundVariants: [
    {
      inline: true,
      size: "xs",
      css: {
        height: "16px",
        width: "16px",
      },
    },
    {
      inline: true,
      size: "sm",
      css: {
        height: "24px",
        width: "24px",
      },
    },
    {
      inline: true,
      size: "md",
      css: {
        height: "32px",
        width: "32px",
      },
    },
  ],
});
