import { BackendServicesClient } from "~/context/BackendServicesContext";

import { AIScene } from "../../AIScenes.types";

interface GetSceneTitleResponse {
  success: boolean;
  data: {
    title: string;
  };
}

export async function getTitleFromScene(
  client: BackendServicesClient,
  scene: AIScene,
  languageCode: string,
  wordLimit?: number
): Promise<string> {
  const phrase = scene.words.map((word) => word.word).join(" ");
  const result = await client.post<GetSceneTitleResponse>("/proxy/v1/ai-edit/scene-title", {
    scene: phrase,
    languageCode: languageCode || "en-US",
    wordLimit,
  });
  if (!result.data.success) {
    throw new Error("Failed to get title from scene", { cause: result.data });
  }

  return result.data.data.title;
}
