import * as Dialog from "@radix-ui/react-dialog";
import { t } from "i18next";
import { PropsWithChildren } from "react";

import { BaseDialogCloseButton, ButtonSectionContainer } from "../BaseDialog";
import { Button } from "../Button";

import { LoadingDialogProps } from "./LoadingDialog.types";
import { LoadingDialogBody } from "./LoadingDialogBody";

/**
 * This component should always be used inside `BaseDialog` component.
 * */
export function LoadingDialog({
  onCancel,
  cancelLabel,
  buttons,
  children,
  ...bodyProps
}: PropsWithChildren<LoadingDialogProps>) {
  return (
    <>
      <LoadingDialogBody {...bodyProps} />
      {children}
      <ButtonSectionContainer>
        {buttons}
        <Dialog.Close asChild>
          <Button onClick={onCancel} disabled={!onCancel} variant="cancel">
            {cancelLabel ?? t("common:cancel")}
          </Button>
        </Dialog.Close>
      </ButtonSectionContainer>
      {onCancel && <BaseDialogCloseButton onClick={onCancel} />}
    </>
  );
}
