import { Clip, getRelativeTimestamp } from "~/utils/videoClips";

import type { CaptionPhrase, CaptionPhraseAudio, CaptionWord } from "../captionProcessing";

import { getWordsWithClipsRemoved } from "./clipDeleting";

/**
 * @param oldWords words which may include timestamps inside deleted clips
 * @param oldPhrases phrases which may include timestamps inside deleted clips
 * @param clips list of Clip objects describing the video clips
 * @returns an object with a transformed `CaptionWord` array (using {@link getWordsWithClipsRemoved})
 * and a `CaptionPhrase` array with the following properties:
 *
 * - Deleted words are removed from phrases
 * - Deleted phrases are removed the array
 * - Timestamps are mapped to relative timestamps
 */
export function getWordsAndPhrasesWithClipsRemoved(
  oldPhrases: CaptionPhrase[],
  oldWords: CaptionWord[],
  clips: Clip[]
): { phrases: CaptionPhrase[]; words: CaptionWord[] } {
  if (!clips.length) {
    return { words: oldWords, phrases: oldPhrases };
  }

  const newWords = getWordsWithClipsRemoved(oldWords, clips);
  const newPhrases =
    oldPhrases
      ?.map((phrase) => {
        const phraseWords = newWords.filter(({ phraseId }) => phraseId === phrase.id);
        return {
          ...phrase,

          // Remove deleted words from phrases
          text: phraseWords.map((word) => word.text).join(" "),

          // Update start/end times to account for removed words
          startTime: getRelativeTimestamp(clips, phrase.startTime),
          endTime: getRelativeTimestamp(clips, phrase.endTime),
        };
      })
      // Remove phrases that have no text
      ?.filter((phrase) => phrase.text.length > 0) ?? null;
  return { words: newWords, phrases: newPhrases };
}

/**
 * @param oldPhraseAudios phrase audios which may include timestamps inside deleted clips
 * @param clips list of Clip objects describing the video clips
 * @returns a transformed `DubbedPhraseAudio` array with the following properties:
 *
 * - Deleted phrase audios are removed the array
 * - Audios with deleted sections have their "Clip" property properly set
 * - Timestamps are mapped to relative timestamps
 */
export function getPhraseAudiosWithClipsRemoved(
  oldPhraseAudios: CaptionPhraseAudio[],
  clips: Clip[]
): CaptionPhraseAudio[] {
  if (!clips.length) {
    return oldPhraseAudios;
  }

  if (oldPhraseAudios.some(({ audioClips }) => audioClips?.length)) {
    // This function is only meant to be used for phrases using absolute timestamps and no cut audio clips
    throw new Error(
      "getPhrasesWithClipsRemoved does not support phrases with clips already applied"
    );
  }

  return (
    oldPhraseAudios
      ?.map((phraseAudio) => {
        const originalDuration = phraseAudio.endTime - phraseAudio.startTime;
        // Calculates the audio clips for the phrase audio
        // The calculations take the video clips, get the intersection with the phrase and then
        // makes them relative to the phrase
        const audioClips = clips
          .filter(
            (clip) => clip.endTime > phraseAudio.startTime && clip.startTime < phraseAudio.endTime
          )
          .map((clip) => ({
            startTime: Math.max(clip.startTime - phraseAudio.startTime, 0),
            endTime: Math.min(clip.endTime - phraseAudio.startTime, originalDuration),
            deleted: clip.deleted,
          }));
        return {
          ...phraseAudio,

          // Update start/end times to account for removed words
          startTime: getRelativeTimestamp(clips, phraseAudio.startTime),
          endTime: getRelativeTimestamp(clips, phraseAudio.endTime),
          audioClips,
        };
      })
      // Remove phrases that have only deleted clips
      ?.filter(
        (phraseAudio) =>
          !phraseAudio.audioClips?.length || phraseAudio.audioClips.some((clip) => !clip.deleted)
      ) ?? null
  );
}

/**
 * Checks if the given phrase audios have deleted clips applied to them.
 *
 * @param phraseAudios - The array of phrase audios to check.
 * @returns True if clips are not applied, false otherwise
 */
export function arePhraseAudiosMissingClips(phraseAudios: CaptionPhraseAudio[]): boolean {
  return (
    phraseAudios.length > 0 &&
    !phraseAudios.some(
      (phraseAudio) =>
        // If the phrase audio has any clips, then assume clips were applied
        phraseAudio.audioClips?.length ||
        // If the phrase audio has a different start time than the original, then assume clips
        // were applied
        phraseAudio.startTime !== phraseAudio.originalStartTime
    )
  );
}
