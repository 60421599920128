import { getLanguageLocale } from "i18n/utils/getLanguageLocale";
import i18n from "i18next";
import { useRouter } from "next/router";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useOnMountEffect } from "~/hooks/helpers";
import { mailto } from "~/modules/project/utils/contactSupport/mailto";
import { getUserHash } from "~/services/Profile";

import { useBackendServicesClient } from "./BackendServicesContext";
import { useAuthState } from "./CaptionsAuthContext";

const SUPPORT_EMAIL = "hello@captions.ai";
const SUPPORT_EMAIL_SUBJECT = "Captions Desktop Support Request";

interface IntercomAvatar {
  type?: "avatar";
  image_url?: string;
}

interface IntercomCompany {
  id?: string;
  name?: string;
  created_at?: number;
  plan?: string;
  monthly_spend?: number;
  user_count?: number;
  size?: number;
  website?: string;
  industry?: string;
}

interface IntercomUserInfo {
  user_id?: string;
  user_hash?: string;

  email?: string;
  name?: string;
  phone?: string;
  avatar?: IntercomAvatar;
}

interface IntercomSettings extends IntercomUserInfo {
  app_id: string;
  custom_launcher_selector?: string;
  alignment?: "left" | "right";
  horizontal_padding?: number;
  vertical_padding?: number;
  hide_default_launcher?: boolean;
  session_duration?: number;
  action_color?: string;
  background_color?: string;
  created_at?: number;
  unsubscribed_from_emails?: boolean;
  language_override?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
  company?: IntercomCompany;
  companies?: IntercomCompany[];
  page_title?: string;
}

export interface IntercomProviderProps {
  helpCenterMenuItemClickListener?: (open: () => void) => void;
}

export interface IntercomContextData {
  show: () => void;
  hide: () => void;
  shutdown: () => void;
  update: (options?: Partial<IntercomSettings>) => void;
  visible: boolean;
  unreadCount: number;
}

export const IntercomContext = createContext<IntercomContextData | null>(null);

const createIntercom = () => {
  const _call = <A,>(method: string, arg?: A) => {
    "Intercom" in window && typeof window.Intercom === "function" && window.Intercom(method, arg);
  };

  return {
    isLoaded() {
      return "Intercom" in window && typeof window.Intercom === "function";
    },

    // Loads Intercom with the snippet
    // This must be run before boot, it initializes window.Intercom
    load(appId: string) {
      const Intercom = "Intercom" in window && window.Intercom;
      if (typeof Intercom === "function") {
        const settings =
          "intercomSettings" in window ? (window.intercomSettings as IntercomSettings) : undefined;
        Intercom("reattach_activator");
        Intercom("update", settings);
      } else {
        const i = function (...args: unknown[]) {
          i.c(args);
        };
        i.q = [] as unknown[];
        i.c = function (args: unknown[]) {
          i.q.push(args);
        };
        Object.assign(window, { Intercom: i });
        const l = function () {
          const s = document.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = "https://widget.intercom.io/widget/" + appId;
          const x = document.getElementsByTagName("script")[0];
          x.parentNode?.insertBefore(s, x);
        };
        if (document.readyState === "complete") {
          l();
        } else if ("attachEvent" in window && typeof window.attachEvent === "function") {
          window.attachEvent("onload", l);
        } else {
          window.addEventListener("load", l, false);
        }
      }
    },

    show() {
      try {
        _call("show");
      } catch {
        window.open(mailto(SUPPORT_EMAIL, SUPPORT_EMAIL_SUBJECT), "_blank");
      }
    },

    shutdown: () => _call("shutdown"),

    hide: () => _call("hide"),

    update: (options?: Partial<IntercomSettings>) => _call("update", options),

    boot: (settings: IntercomSettings) => _call("boot", settings),

    onShow: (callback: () => void) => _call("onShow", callback),

    onHide: (callback: () => void) => _call("onHide", callback),

    onUnreadCountChange: (callback: (unreadCount: number) => void) =>
      _call("onUnreadCountChange", callback),
  };
};

const intercom = createIntercom();

const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;

export function IntercomProvider({
  children,
  helpCenterMenuItemClickListener,
}: PropsWithChildren<IntercomProviderProps>) {
  const router = useRouter();
  const [visible, setVisible] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const client = useBackendServicesClient();
  const { user, state: authState } = useAuthState();

  const userInfo = useMemo((): IntercomUserInfo | undefined => {
    if (authState === "signedIn" && user?.userId) {
      return {
        user_id: user?.userId,
        email: user?.email,
        name: user?.name,
        avatar: user?.image ? { image_url: user?.image } : undefined,
        phone: user?.phoneNumber,
      };
    }
  }, [authState, user?.userId, user?.email, user?.name, user?.image, user?.phoneNumber]);

  const boot = useCallback(
    async (appId: string) => {
      const userHash = await getUserHash(client);

      intercom.boot({
        hide_default_launcher: true,
        alignment: "right",
        horizontal_padding: 24,
        vertical_padding: 64,

        app_id: appId,
        user_hash: userHash,
        language_override: getLanguageLocale(i18n),

        ...userInfo,
      });
      intercom.onShow(() => setVisible(true));
      intercom.onHide(() => setVisible(false));
      intercom.onUnreadCountChange((count) => setUnreadCount(count));
    },
    [userInfo, client]
  );

  useOnMountEffect(() => {
    function updateIntercomLanguage() {
      if (intercom.isLoaded()) {
        intercom.update({
          language_override: getLanguageLocale(i18n),
        });
      }
    }

    i18n.on("languageChanged", updateIntercomLanguage);
    return () => i18n.off("languageChanged", updateIntercomLanguage);
  });

  useEffect(() => {
    if (!INTERCOM_APP_ID || !userInfo) {
      return;
    }

    if (!intercom.isLoaded()) {
      intercom.load(INTERCOM_APP_ID);
    }

    boot(INTERCOM_APP_ID);
  }, [userInfo, boot]);

  useEffect(() => {
    function handleRouteChange() {
      intercom.update();
    }

    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (helpCenterMenuItemClickListener) {
      helpCenterMenuItemClickListener(intercom.show);
    }
  }, [helpCenterMenuItemClickListener]);

  return (
    <IntercomContext.Provider
      value={{
        show: intercom.show,
        hide: intercom.hide,
        shutdown: intercom.shutdown,
        update: intercom.update,
        visible,
        unreadCount,
      }}
    >
      {children}
    </IntercomContext.Provider>
  );
}

export function useIntercom() {
  const ctx = useContext(IntercomContext);

  if (!ctx) {
    throw new Error("IntercomContext not initialized");
  }

  return ctx;
}
