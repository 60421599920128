import { Text } from "~/components";
import * as SideForm from "~/components/SideForm";

import { LanguageItemLabelContainer } from "./LanguageItemLabel.styles";
import { LanguageItemLabelProps } from "./LanguageItemLabel.types";

export function LanguageItemLabel({
  displayName,
  localizedDisplayName,
  tag,
}: LanguageItemLabelProps) {
  return (
    <LanguageItemLabelContainer>
      <div>
        <Text variant="body-2-bold" color="grey-200">
          {displayName} {tag && <SideForm.Tag css={{ marginLeft: 8 }}>{tag}</SideForm.Tag>}
        </Text>
      </div>
      {localizedDisplayName && (
        <div>
          <Text variant="body-2-bold" color="grey-500">
            {localizedDisplayName}
          </Text>
        </div>
      )}
    </LanguageItemLabelContainer>
  );
}
