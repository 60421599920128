import { createContext as reactCreateContext, useContext, useRef } from "react";
import { createStore, StoreApi } from "zustand";
import { combine } from "zustand/middleware";
import { shallow } from "zustand/shallow";
import { useStoreWithEqualityFn } from "zustand/traditional";

import { promiseWithResolvers } from "~/utils/promise-with-resolvers";

export type StoreActionCreatorContext<StoreType> = {
  set: StoreApi<StoreType>["setState"];
  get: StoreApi<StoreType>["getState"];
};

// use this if the store creator needs to take arguments or the parent needs to reference the store.
// you will manually create the store with `useStoreCreator`
export const createStoreContextWithCreator = <
  State,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  CreatorFn extends (...args: any) => StoreApi<State>,
>(
  createStore: CreatorFn
) => {
  const useStoreCreator = (...args: Parameters<CreatorFn>) => {
    const storeRef = useRef<ReturnType<CreatorFn>>();

    if (!storeRef.current) {
      storeRef.current = createStore(...args) as ReturnType<CreatorFn>;
    }

    return storeRef.current;
  };

  const StoreContext = reactCreateContext<ReturnType<CreatorFn> | null>(null);

  const useStore = <T,>(
    selector: (state: ExtractState<ReturnType<CreatorFn>>) => T,
    // shallow allows us to select arrays or objects without having to use useMemo
    equalityFn: (left: T, right: T) => boolean = shallow
  ) => {
    const store = useContext(StoreContext);
    if (!store) {
      throw new Error("Seems like you have not used zustand provider as an ancestor.");
    }

    return useStoreWithEqualityFn(store, selector, equalityFn);
  };

  return [useStoreCreator, StoreContext.Provider, useStore] as const;
};

// use this if the store creator does not need to take arguments and parent doesn't to reference the store.
// the store will be automatically created for you
export const createStoreContext = <State, CreatorFn extends () => StoreApi<State>>(
  createStore: CreatorFn
) => {
  const StoreContext = reactCreateContext<ReturnType<CreatorFn> | null>(null);

  const useStoreCreator = () => {
    const storeRef = useRef<ReturnType<CreatorFn>>();

    if (!storeRef.current) {
      storeRef.current = createStore() as ReturnType<CreatorFn>;
    }

    return storeRef.current;
  };

  const Provider = (props: React.PropsWithChildren) => {
    const store = useStoreCreator();
    return <StoreContext.Provider value={store} {...props} />;
  };

  const useStore = <T,>(
    selector: (state: ExtractState<ReturnType<CreatorFn>>) => T,
    // shallow allows us to select arrays or objects without having to use useMemo
    equalityFn: (left: T, right: T) => boolean = shallow
  ) => {
    const store = useContext(StoreContext);
    if (!store) {
      throw new Error("Seems like you have not used zustand provider as an ancestor.");
    }

    return useStoreWithEqualityFn(store, selector, equalityFn);
  };

  return [Provider, useStore] as const;
};

export type ExtractState<Store> = Store extends { getState: () => infer T } ? T : never;

export const createUseStoreWithSelector = <State, Store extends StoreApi<State>>(store: Store) => {
  return <T,>(
    selector: (state: ExtractState<Store>) => T,
    // shallow allows us to select arrays or objects without having to use useMemo
    equalityFn: (left: T, right: T) => boolean = shallow
  ) => {
    return useStoreWithEqualityFn(store, selector, equalityFn);
  };
};

interface GenericAsyncStore<Instance> {
  status: "empty" | "initializing" | "ready" | "error";
  instance: Instance | null;
  promise: Promise<Instance>;

  queuedActions: ((instance: Instance) => void)[];
}

// if the init function takes no arguments, you can choose to initialize it immediately or lazily if you want
// - `immediate`: the store is initialized immediately when the store is created
// - `lazy`: the store is initialized when the first action is queued
type AutoInitializationModes<InitParams> = InitParams extends [] ? "immediate" | "lazy" : never;

interface AdditionalMethodsContext<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  InitFn extends (...args: any[]) => any | Promise<any>,
  Instance,
> {
  init: (...args: Parameters<InitFn>) => void;
  set: StoreApi<GenericAsyncStore<Instance>>["setState"];
  get: StoreApi<GenericAsyncStore<Instance>>["getState"];
  queue: (action: (instance: Instance) => void) => void;
}

interface AsyncStoreOptions<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  InitFn extends (...args: any[]) => any | Promise<any>,
  Methods,
  Instance,
> {
  name: string;
  initializer: InitFn;
  methods: (context: AdditionalMethodsContext<InitFn, Instance>) => Methods;

  enableAutoInitialization?: AutoInitializationModes<Parameters<InitFn>>;
}

// this is helpful to capture a store that is initialized asynchronously
// with methods to:
// - queue actions to be run after the instance is initialized
// - await for the instance to be initialized
// - a hook to use the store in React
export const createAsyncInitializedStore = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  InitFn extends (...args: any[]) => any | Promise<any>,
  Methods,
  Instance = Awaited<ReturnType<InitFn>>,
>({
  name,
  initializer,
  methods,
  enableAutoInitialization,
}: AsyncStoreOptions<InitFn, Methods, Instance>) => {
  const { promise, resolve, reject } = promiseWithResolvers<Instance>();

  const store = createStore(
    combine(
      {
        status: "empty",
        instance: null,
        promise,
        queuedActions: [],
      } as GenericAsyncStore<Instance>,
      (set, get) => {
        const init = (...args: Parameters<InitFn>) => {
          if (get().instance || get().status === "initializing") {
            console.warn(`${name} instance already exists or is already initializing, skipping`);
            return;
          }

          set({ status: "initializing" });

          const instanceOrPromise = initializer(...args);

          const handleReady = (instance: Instance) => {
            resolve(instance);
            set({ status: "ready", instance });

            get().queuedActions.forEach((event) => {
              event(instance);
            });
          };

          if (instanceOrPromise instanceof Promise) {
            instanceOrPromise.then(handleReady).catch((e) => {
              reject(e);
              set({ status: "error" });
            });
          } else {
            handleReady(instanceOrPromise);
          }
        };

        const queue = (action: (instance: Instance) => void) => {
          const instance = get().instance;

          if (instance) {
            action(instance);
          } else {
            set((state) => ({
              queuedActions: [...state.queuedActions, action],
            }));

            // for lazy, we queue the action in case the init is async and then start the initialization.
            // once the instance is ready, we will flush the queue. additionally the init method makes
            // sure we only initialize once.
            if (enableAutoInitialization === "lazy") {
              // ts is not smart enough to know that the init method has no params in this case
              (init as () => void)();
            }
          }
        };

        return {
          init,
          queue,

          error: () => {
            reject();
            set({ status: "error" });
          },

          ...methods?.({ set, get, queue, init }),
        };
      }
    )
  );

  const getStore = () => store.getState();

  const useStore = createUseStoreWithSelector(store);

  const getInstance = () => promise;

  if (enableAutoInitialization === "immediate") {
    // ts is not smart enough to know that the init method has no params in this case
    (store.getState().init as () => void)();
  }

  // ideally you should use the `getStore` methods unless you really need the raw store instance
  // for subscriptions, etc.
  return [getStore, useStore, getInstance, store] as const;
};
