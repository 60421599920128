import { GetAiEditStylesResponse } from "@shared/generated/typescript/aiEdit/api/CreateAiEditVideoTemplate";
import {
  getAiEditStylesResponseSchema,
  getAiEditStylesRequestSchema,
} from "@shared/generated/typescript/aiEdit/api/CreateAiEditVideoTemplateSchema";
import { z } from "zod";

import { createUseQueryHookWithParams } from "~/utils/network/query-helpers";

export const useBackendAIEditStylesList = createUseQueryHookWithParams({
  queryKey: ["ai-edit-styles"],
  path: "ai-edit/styles",
  responseSchema: z.preprocess((value) => {
    return GetAiEditStylesResponse.fromJson(value as string, { ignoreUnknownFields: true });
  }, getAiEditStylesResponseSchema),
  paramsSchema: getAiEditStylesRequestSchema,
  proxied: true,
  method: "POST",
});
