import * as Select from "@radix-ui/react-select";
import { ReactElement } from "react";

import SmallCheck from "../icons/SmallCheck";

import {
  FormSelectItemIndicator,
  FormSelectItemIndicatorWrapper,
  FormSelectPopupItem,
  FormSelectPopupText,
} from "./FormSelectItem.styles";
import { FormSelectItemProps } from "./FormSelectItem.types";

export function FormSelectItem({ value, label }: FormSelectItemProps): ReactElement {
  return (
    <FormSelectPopupItem key={value} value={value}>
      <Select.ItemText asChild>
        <FormSelectPopupText>{label}</FormSelectPopupText>
      </Select.ItemText>
      <FormSelectItemIndicatorWrapper>
        <FormSelectItemIndicator>
          <SmallCheck />
        </FormSelectItemIndicator>
      </FormSelectItemIndicatorWrapper>
    </FormSelectPopupItem>
  );
}
