export const BOX_MARGIN = 28;
/**
 * Size factor used to enlarge keywords
 * @constant
 */
export const SUPER_SIZE_FACTOR = 2;
export const SUPER_SIZE_NOBREAK_FACTOR = 1.5;
/**
 * Default width constraint when lineFitWrapEnabled is true
 */
export const DEFAULT_LINE_FIT_WRAP_WIDTH_CONSTRAINT = 650;

/**
 * Factor applied to the maximum caption width when the layout is landscape
 */
export const LANDSCAPE_MAX_WIDTH_FACTOR = 2;

/**
 * The factor between the template values for emoji size to the actual size in pixels.
 */
export const EMOJI_SIZE_TO_PIXELS_FACTOR = 5 * 1.14;

/**
 * The default size of emojis in pixels.
 */
export const DEFAULT_EMOJI_SIZE = 21;

/**
 * The base spacing between the emojis and the text box
 */
export const BASE_EMOJI_SPACING = 10;

/**
 * The base horizontal padding between the text and the active text box
 */
export const BOX_HORIZONTAL_PADDING = 16;

/**
 * The base vertical padding between the text and the active text box
 */
export const BOX_VERTICAL_PADDING = 32;

/**
 * Default extra size values from iOS
 */
export const DEFAULT_EXTRA_SIZE = {
  width: 12,
  height: 6,
};

/**
 * Maximum number of attempts that autoFitToWidthRange can make while trying to calculate a size
 * factor.
 */
export const MAX_SIZE_CALCULATION_ATTEMPTS = 10;
