/**
 * A class that loads a video from a given URL and provides a way to fetch frames at specific times.
 */
export class VideoLoader {
  private readonly _video: HTMLVideoElement;
  private _hasError: boolean = false;
  private _seekPromise: Promise<void> | null = null;
  private _loadPromise: Promise<void> | null = null;
  private _seekAbortController: AbortController | null = null;

  constructor(videoUrl: URL | string) {
    this._video = document.createElement("video");
    this._loadPromise = new Promise((resolve, reject) => {
      this._video.onloadeddata = () => {
        this._loadPromise = null;
        this._video.onloadeddata = null;
        resolve();
      };

      this._video.onerror = () => {
        this._hasError = true;
        this._seekAbortController?.abort();
        if (this._loadPromise) {
          this._loadPromise = null;
          this._video.onloadeddata = null;
          reject();
        }
      };
    });
    this._video.crossOrigin = "anonymous";
    this._video.preload = "auto";
    this._video.muted = true;
    this._video.src = typeof videoUrl === "string" ? videoUrl : videoUrl.toString();
    this._video.load();
  }

  /**
   * Frees up resources used by the video loader.
   */
  destroy() {
    URL.revokeObjectURL(this._video.src);
    this._video.src = "";
    this._video.remove();
  }

  get duration() {
    return this._video.duration;
  }

  get videoWidth() {
    return this._video.videoWidth;
  }

  get videoHeight() {
    return this._video.videoHeight;
  }

  get currentTime() {
    return this._video.currentTime;
  }

  /**
   * Set the current time of the video file.
   * @remarks Will create a promise that resolves when the video has seeked to the correct time.
   * @remarks Will abort any ongoing seek promises
   * @param timestamp - The timestamp to set the video to.
   */
  set currentTime(timestamp: number) {
    if (this._video.currentTime === timestamp) {
      return;
    }
    if (!this._video.duration || this._hasError) {
      throw new Error("Video is not ready or has an error");
    }

    this._seekAbortController?.abort();
    this._seekAbortController = null;
    // Creates a seek promise that will be resolved when the video element has finished seeking,
    // which indicates that the video frame is ready
    this._seekPromise = new Promise((resolve) => {
      this._seekAbortController = new AbortController();
      const seekedHandler = () => {
        this._seekAbortController?.signal.removeEventListener("abort", abortHandler);
        this._seekAbortController = null;
        resolve();
      };
      const abortHandler = () => {
        this._video.removeEventListener("seeked", seekedHandler);
        this._seekAbortController = null;
        resolve();
      };
      this._seekAbortController.signal.addEventListener("abort", abortHandler, {
        once: true,
        passive: true,
      });
      this._video.addEventListener("seeked", seekedHandler, { once: true, passive: true });
      this._video.currentTime = timestamp;
    });
  }

  /**
   * Ensures that any ongoing load and seek operations are finished.
   */
  async flush(): Promise<void> {
    await this._loadPromise;
    await this._seekPromise;
  }

  /**
   * Get the video image source if the video is not past its end.
   * @returns The video image source or null if the video is past its end.
   */
  get videoImage(): TexImageSource {
    return this._video;
  }
}
