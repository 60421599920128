import { styled } from "~/theme";

function PartyConfetti({ ...props }) {
  return (
    <svg
      stroke="currentColor"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8728 16.5757L6.29322 20.3655C5.53043 20.7469 4.60918 20.5974 4.00615 19.9944V19.9944C3.40312 19.3913 3.25362 18.4701 3.63501 17.7073L7.4248 10.1277C7.56854 9.84019 7.84175 9.63929 8.15907 9.5878C8.47638 9.53631 8.7991 9.6405 9.0264 9.86782L14.1327 14.9741C14.36 15.2014 14.4642 15.5242 14.4127 15.8415C14.3612 16.1588 14.1603 16.432 13.8728 16.5757Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5 3V3.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 7V6.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 5H6.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 5H3.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12 3C12.5064 3.25322 12.8797 3.7117 13.025 4.25892C13.1704 4.80614 13.0737 5.38942 12.7596 5.86052L12 7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 12L18.1395 11.2403C18.6106 10.9263 19.1939 10.8296 19.7411 10.975C20.2883 11.1203 20.7468 11.4936 21 12"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M19 17V17.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19 21V20.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21 19H20.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17 19H17.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19 3V3.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19 7V6.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21 5H20.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17 5H17.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 10L15 9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default styled(PartyConfetti, {
  color: "$primary-purple",
  width: 24,
  height: 24,
});
