import { MIN_WORD_DURATION_TIMING_MODE } from "~/constants/pixelsPerSecond.constants";
import { type Result, Err, Ok } from "~/utils/result";

import type { CaptionWord } from "./index";

export const MAX_EMPTY_WORD_DURATION = 2;

/**
 * Attempts to create an empty word at the given timestamp, resolving the logic
 * of whether to add it before/after an existing word, and constraining the max
 * duration to a reasonable value.
 *
 * @returns either the created word or an error message
 */
export function createEmptyWord(
  timestamp: number,
  videoDuration: number,
  words: CaptionWord[],
  newId: number
): Result<CaptionWord> {
  // Find the words that will be before and after the newly-added word
  const prevWordIdx = words.findLastIndex((word) => getMidpoint(word) < timestamp);
  const prevWord: CaptionWord | undefined = words[prevWordIdx];
  const nextWord: CaptionWord | undefined = words[prevWordIdx + 1];
  const startTime = prevWord?.endTime ?? 0;
  let endTime = nextWord?.startTime ?? videoDuration;

  // Clamp word max length
  const duration = endTime - startTime;
  if (duration > MAX_EMPTY_WORD_DURATION) {
    const difference = duration - MAX_EMPTY_WORD_DURATION;
    endTime -= difference; // Shrink word towards startTime
  }

  // Assumption: enough space
  if (startTime === endTime) {
    return Err("No space to add word.");
  }

  // Sanity check
  if (startTime > endTime) {
    return Err("Start time is greater than end time.");
  }

  // Find which phrase it should belong to
  const prevDist = timestamp - (prevWord?.endTime ?? -Infinity);
  const nextDist = (nextWord?.startTime ?? Infinity) - timestamp;
  const closestWord = prevDist < nextDist ? prevWord : nextWord;
  const phraseId = closestWord?.phraseId ?? null;

  const newWord: CaptionWord = {
    id: newId,
    startTime,
    endTime,
    text: "",
    supersize: false,
    emphasize: false,
    phraseId,
    isKeyword: false,
    keywordSettings: {
      supersize: false,
      emphasize: false,
    },
  };

  return Ok(newWord);
}

/**
 * Adds a new word to the list of words, moving any existing words that have a matching startTime
 * to the end of the new word.
 *
 * **NOTE**: This function assumes that the list of words is sorted and does not contain words
 * with repeated startTime values.
 *
 * @param newWord - The new word to add
 * @param words - The list of existing words
 * @returns The new list of words
 */
export function addNewWord(newWord: CaptionWord, words: CaptionWord[] | null): CaptionWord[] {
  if (!words) {
    return [newWord];
  }
  let { startTime, endTime } = newWord;
  return [
    ...words.map((word) => {
      // If an existing word starts during the new word, move it to the end of the new word
      if (word.startTime >= startTime && word.startTime < endTime) {
        // If the existing word ends during the new word, increase its endTime and
        // goes on to check if any words overlap with the new values,
        console.log("Moving word to end of new word", word, endTime);
        if (word.endTime < endTime + MIN_WORD_DURATION_TIMING_MODE) {
          startTime = endTime;
          endTime = startTime + MIN_WORD_DURATION_TIMING_MODE;
          return {
            ...word,
            startTime,
            endTime,
          };
        } else {
          return {
            ...word,
            startTime: endTime,
          };
        }
      }
      return word;
    }),
    newWord,
  ].sort((a, b) => a.startTime - b.startTime);
}

export function getMidpoint(interval: { startTime: number; endTime: number }) {
  return (interval.startTime + interval.endTime) / 2;
}
