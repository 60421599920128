import glBlurHorizontalFrag from "./gl-blur-horizontal.frag";
import glBlurVerticalFrag from "./gl-blur-vertical.frag";
import glTransformFlipVert from "./gl-transform-flip.vert";
import glTransformVert from "./gl-transform.vert";
import {
  createWebGLArrayBuffer,
  initShaderProgram,
  setVertexAttributeBuffer,
  createFramebufferTexture,
  createEmptyTexture,
} from "./webgl";

export function initBlur(gl: WebGLRenderingContext) {
  const shaderProgramHoriz = initShaderProgram(gl, glTransformVert, glBlurHorizontalFrag);
  const shaderProgramVert = initShaderProgram(gl, glTransformFlipVert, glBlurVerticalFrag);

  const programInfoHoriz = {
    program: shaderProgramHoriz,
    attribLocations: {
      vertexPosition: gl.getAttribLocation(shaderProgramHoriz, "aVertexPosition"),
      textureCoord: gl.getAttribLocation(shaderProgramHoriz, "aTextureCoord"),
    },
    uniformLocations: {
      transformMatrix: gl.getUniformLocation(shaderProgramHoriz, "uTransformMatrix"),
      uSampler: gl.getUniformLocation(shaderProgramHoriz, "uSampler"),
      uTextureSize: gl.getUniformLocation(shaderProgramHoriz, "uTextureSize"),
      uBlurRadius: gl.getUniformLocation(shaderProgramHoriz, "uBlurRadius"),
    },
  };

  const programInfoVert = {
    program: shaderProgramVert,
    attribLocations: {
      vertexPosition: gl.getAttribLocation(shaderProgramVert, "aVertexPosition"),
      textureCoord: gl.getAttribLocation(shaderProgramVert, "aTextureCoord"),
    },
    uniformLocations: {
      transformMatrix: gl.getUniformLocation(shaderProgramVert, "uTransformMatrix"),
      uSampler: gl.getUniformLocation(shaderProgramVert, "uSampler"),
      uTextureSize: gl.getUniformLocation(shaderProgramVert, "uTextureSize"),
      uBlurRadius: gl.getUniformLocation(shaderProgramVert, "uBlurRadius"),
    },
  };

  const buffers = {
    positionBuffer: createWebGLArrayBuffer(gl, [-1.0, -1.0, 1.0, -1.0, 1.0, 1.0, -1.0, 1.0]),
    textureCoordBuffer: createWebGLArrayBuffer(gl, [0.0, 1.0, 1.0, 1.0, 1.0, 0.0, 0.0, 0.0]),
    indicesBuffer: createWebGLArrayBuffer(gl, [0, 1, 2, 0, 2, 3], "element_array_buffer"),
  };

  const textures = {
    texture: createEmptyTexture(gl, [0, 0, 0, 0]),
  };

  return {
    programInfoHoriz,
    programInfoVert,
    buffers,
    textures,
  };
}

export type BlurProgramData = ReturnType<typeof initBlur>;

/**
 * Applies a blur to the given texture using a two-pass Gaussian blur algorithm.
 *
 * @param gl the webgl context
 * @param programData the shader program data
 * @param texture the texture to apply the blur to
 * @param blurRadius float <= 50
 */
export function applyBlurToTexture(
  gl: WebGLRenderingContext,
  { programInfoHoriz, programInfoVert, buffers }: BlurProgramData,
  texture: WebGLTexture,
  blurRadius: number
) {
  const canvasWidth = gl.canvas.width;
  const canvasHeight = gl.canvas.height;

  // Create a framebuffer and texture for intermediate rendering
  const { texture: intermediateTexture, framebuffer: intermediateFramebuffer } =
    createFramebufferTexture(gl, canvasWidth, canvasHeight);

  // Horizontal Pass: Render to the framebuffer
  gl.bindFramebuffer(gl.FRAMEBUFFER, intermediateFramebuffer);
  gl.useProgram(programInfoHoriz.program);
  setVertexAttributeBuffer(
    gl,
    buffers.positionBuffer,
    programInfoHoriz.attribLocations.vertexPosition,
    2
  );
  setVertexAttributeBuffer(
    gl,
    buffers.textureCoordBuffer,
    programInfoHoriz.attribLocations.textureCoord,
    2
  );
  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffers.indicesBuffer);
  gl.uniformMatrix4fv(
    programInfoHoriz.uniformLocations.transformMatrix,
    false,
    [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1]
  );
  gl.activeTexture(gl.TEXTURE0);
  gl.bindTexture(gl.TEXTURE_2D, texture);
  gl.uniform1i(programInfoHoriz.uniformLocations.uSampler, 0);
  gl.uniform2f(programInfoHoriz.uniformLocations.uTextureSize, canvasWidth, canvasHeight);
  gl.uniform1f(programInfoHoriz.uniformLocations.uBlurRadius, blurRadius);
  gl.viewport(0, 0, canvasWidth, canvasHeight);
  gl.drawElements(gl.TRIANGLES, 6, gl.UNSIGNED_SHORT, 0);

  // Vertical Pass: Render to the screen using the intermediate texture
  gl.bindFramebuffer(gl.FRAMEBUFFER, null); // Render back to the screen
  gl.useProgram(programInfoVert.program);
  setVertexAttributeBuffer(
    gl,
    buffers.positionBuffer,
    programInfoVert.attribLocations.vertexPosition,
    2
  );
  setVertexAttributeBuffer(
    gl,
    buffers.textureCoordBuffer,
    programInfoVert.attribLocations.textureCoord,
    2
  );
  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffers.indicesBuffer);
  gl.uniformMatrix4fv(
    programInfoVert.uniformLocations.transformMatrix,
    false,
    [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1]
  );

  gl.activeTexture(gl.TEXTURE0);
  gl.bindTexture(gl.TEXTURE_2D, intermediateTexture); // Use the horizontally blurred texture
  gl.uniform1i(programInfoVert.uniformLocations.uSampler, 0);
  gl.uniform2f(programInfoVert.uniformLocations.uTextureSize, canvasWidth, canvasHeight);
  gl.uniform1f(programInfoVert.uniformLocations.uBlurRadius, blurRadius);
  gl.viewport(0, 0, canvasWidth, canvasHeight);
  gl.drawElements(gl.TRIANGLES, 6, gl.UNSIGNED_SHORT, 0);
}

/**
 * Applies a blur to programData.textures.texture using a two-pass Gaussian blur algorithm.
 *
 * @param gl the webgl context
 * @param programData the shader program data
 * @param blurRadius float <= 50
 */
export function applyBlur(
  gl: WebGLRenderingContext,
  programData: BlurProgramData,
  blurRadius: number
) {
  applyBlurToTexture(gl, programData, programData.textures.texture, blurRadius);
}
