/**
 * Constructs and returns a `mailto:` protocol link, optionally prefilling
 * subject and body.
 */
export function mailto(email: string, subject?: string, body?: string): string {
  const params = [];

  if (subject) {
    params.push(`subject=${escapeMailtoUrlParams(subject)}`);
  }
  if (body) {
    params.push(`body=${escapeMailtoUrlParams(body)}`);
  }

  let url = `mailto:${email}`;
  if (params.length > 0) {
    url += `?${params.join("&")}`;
  }

  return url;
}

/**
 * Note: both `encodeUriComponent()` and `UrlSearchParams.toString()` don't
 * behave properly for this use case. Spaces need to be left unescaped,
 * while other characters, like newlines and ampersands, must be handled.
 */
export function escapeMailtoUrlParams(raw: string): string {
  return raw.replaceAll("\n", "%0A").replaceAll("&", "%26");
}
