import { AnimationStyle } from "captions-engine";

import { FRAME_BORDER_SCRAPBOOK_ASSETS } from "~/modules/project/hooks/useImageEffects/assets";
import { BACKGROUND_SCRAPBOOK_ASSETS } from "~/modules/project/hooks/useImageEffects/assets/backgrounds";
import { SCRAPBOOK_IMAGE_ASSETS } from "~/modules/project/hooks/useImageEffects/assets/images";
import { CAPTION_TEMPLATES } from "~/modules/project/services/CaptionStylePreset";
import { TransitionAnimationGroupId } from "~/modules/project/services/PagAnimations/TransitionAnimations.config";
import { randPick } from "~/utils/rand-pick";

import {
  AIOverlayAnimationStyle,
  AISceneStyle,
  GlobalStyleConfig,
  SceneStyleConfig,
  SceneStyleEntry,
  TransitionConfig,
} from "../AIEditStyles.types";

type BackgroundAssetKey = keyof typeof BACKGROUND_SCRAPBOOK_ASSETS;
type FrameBorderAssetKey = keyof typeof FRAME_BORDER_SCRAPBOOK_ASSETS;
type ImageAssetKey = keyof typeof SCRAPBOOK_IMAGE_ASSETS;

// todo
const SCRAPBOOK_SOUND_VOLUME = 0.2;

export const SCRAPBOOK_PREVIEW_URL =
  "https://captions-cdn.xyz/desktop-site/ai-edit-scrapbook-preview.webm";

export const SCRAPBOOK_SCENE_STYLE_CONFIG = {
  // Scrapbook Hook Style
  "scrapbook-hook": {
    builder: (prng): AISceneStyle => {
      const imageFontIds = [
        "ransom_font_sprite_1",
        "ransom_font_sprite_2",
        "ransom_font_sprite_3",
        "ransom_font_sprite_4",
        "ransom_font_sprite_5",
      ];

      const backgroundIds = [
        "bg_crumpledpaper_pink",
        "bg_plainmarker_pink",
        "bg_loopiemarker_pink",
        "bg_explosionmarker_pink",
      ];
      const backgroundId = randPick(backgroundIds, prng.next());

      const imageFontPositions = ["top", "bottom-half"] as const;
      const imageFontPosition = randPick(imageFontPositions, prng.next());
      const capsPosition = imageFontPosition === "top" ? "bottom-half" : "top-half";

      return {
        id: "scrapbook-hook",
        isOnlyUseOnce: true,
        sceneBackground: {
          assetId: backgroundId,
          cutout: true,
          stroke: true,
          foregroundAnimation: "swipe-up",
        },
        imageFont: {
          assetIds: imageFontIds,
          position: imageFontPosition,
        },
        capsPosition,
      };
    },
  },

  // Scrapbook Big Text
  "scrapbook-big-text": {
    builder: (prng): AISceneStyle => {
      const backgroundIds = [
        "bg_crumpledpaper_blue",
        "bg_crumpledpaper_yellow",
        "bg_crumpledpaper_purple",
        "bg_crumpledpaper_pink",
        "bg_crumpledpaper_green",
      ];
      const backgroundId = randPick(backgroundIds, prng.next());

      const titleAnimations = ["big_bold_text_black", "big_bold_text_white"];
      const titleAnimationPagFileId = randPick(titleAnimations, prng.next());

      const titleAnimation: AIOverlayAnimationStyle = {
        holdId: titleAnimationPagFileId,
        positioning: {
          type: "fullscreen",
        },
        titleReplacement: {
          numberOfLines: "auto",
        },
      };

      return {
        id: "scrapbook-big-text",
        animation: titleAnimation,
        sceneBackground: {
          assetId: backgroundId,
        },
        capsPosition: "bottom-half",
      };
    },
  },

  // Scrapbook Talking Head Cutout
  "scrapbook-cutout": {
    builder: (_, variant): AISceneStyle => {
      return {
        id: "scrapbook-cutout",
        sceneBackground: {
          assetId: variant ?? "",
          cutout: true,
        },
        capsPosition: "avoid-head",
      };
    },
    variants: [
      null,
      "bg_crumpledpaper_pink",
      "bg_plainmarker_pink",
      "bg_loopiemarker_pink",
      "bg_explosionmarker_pink",
      "bg_scribblemarker_pink",
    ],
  } satisfies SceneStyleEntry<BackgroundAssetKey | null>,

  // Scrapbook Cutout Bottom Right
  "scrapbook-cutout-bottom-right": {
    builder: (_, variant): AISceneStyle => {
      return {
        id: "scrapbook-cutout-bottom-right",
        sceneBackground: {
          assetId: variant ?? "",
          cutout: true,
          rotation: -11,
          stroke: true,
          foregroundPosition: "bottom-right",
        },
        capsPosition: "top-half",
        staticImageRules: {
          assetIds: ["diamond-flower_1", "diamond_4", "black_glitter_5"] as ImageAssetKey[],
          imageLayout: "scrapbook_talking_head_4",
          imageAnimations: {
            animationIn: AnimationStyle["animationStyleImmediate"],
            animationOut: AnimationStyle["animationStyleUnspecified"],
            activeAnimation: AnimationStyle["animationStyleImmediate"],
            type: "classic",
          },
          backgroundInfo: {
            black_glitter_5: true,
          } as Record<ImageAssetKey, boolean>,
        },
      };
    },
    variants: ["bg_explosionmarker_pink"],
  } satisfies SceneStyleEntry<BackgroundAssetKey>,

  // Scrapbook Cutout Marker Frame Clean
  "scrapbook-marker-frame-clean": {
    builder: (): AISceneStyle => {
      const frameAssetId = "marker_frame_clean_pink";

      return {
        id: "scrapbook-marker-frame-clean",
        frame: {
          assetId: frameAssetId,
        },
        capsPosition: "avoid-head",
      };
    },
  },

  // Scrapbook Cutout Marker Frame Arrow
  "scrapbook-marker-frame-arrow": {
    builder: (): AISceneStyle => {
      const overlayType = "marker_frame_arrow_pink";

      return {
        id: "scrapbook-marker-frame-arrow",
        overlay: overlayType,
        overlayBlend: "source-over",
        capsPosition: "avoid-head",
      };
    },
  },

  // Scrapbook Cutout Text
  "scrapbook-cutout-text": {
    builder: (): AISceneStyle => {
      const titleAnimation: AIOverlayAnimationStyle = {
        holdId: "cutout_text_pink",
        positioning: {
          type: "fullscreen",
        },
        titleReplacement: {
          numberOfLines: "auto",
        },
      };

      return {
        id: "scrapbook-cutout-text",
        animation: titleAnimation,
        capsPosition: "bottom-half",
      };
    },
  },

  // Scrapbook Talking Head Cutout Stroke
  "scrapbook-cutout-stroke": {
    builder: (_, variant): AISceneStyle => {
      return {
        id: "scrapbook-cutout-stroke",
        sceneBackground: {
          assetId: variant ?? "",
          cutout: true,
          stroke: true,
        },
      };
    },
    variants: [
      "bg_crumpledpaper_pink",
      "bg_plainmarker_pink",
      "bg_loopiemarker_pink",
      "bg_explosionmarker_pink",
      "bg_scribblemarker_pink",
    ],
  } as SceneStyleEntry<BackgroundAssetKey>,

  // Scrapbook Talking Head Collage
  "scrapbook-talking-head-collage": {
    builder: (_, variant): AISceneStyle => {
      return {
        id: "scrapbook-talking-head-collage",
        frame: {
          assetId: variant ?? "",
        },
        capsPosition: "avoid-head",
      };
    },
    variants: [
      "talkinghead_or_broll_1_pink",
      "talkinghead_or_broll_2_pink",
      "talkinghead_or_broll_3_pink",
      "talkinghead_or_broll_4_pink",
      "talkinghead_or_broll_5_pink",
    ],
  } satisfies SceneStyleEntry<FrameBorderAssetKey>,

  // Scrapbook BRoll
  "scrapbook-broll": {
    builder: (_, variant): AISceneStyle => {
      return {
        id: "scrapbook-broll",
        imageRules: {
          style: "SCRAPBOOK_SQUARE",
          numImages: 1,
          imagePositionType: "b-roll",
          imageLayout: "fullscreen",
        },
        frame: {
          assetId: variant ?? "",
        },
      };
    },
    isImageStyle: true,
    variants: [
      "talkinghead_or_broll_1_pink",
      "talkinghead_or_broll_2_pink",
      "talkinghead_or_broll_3_pink",
      "talkinghead_or_broll_4_pink",
      "talkinghead_or_broll_5_pink",
    ],
  } satisfies SceneStyleEntry<FrameBorderAssetKey>,

  // Scrapbook Talking Head and BRoll
  "scrapbook-talking-head-and-broll": {
    builder: (_, variant): AISceneStyle => {
      return {
        id: "scrapbook-talking-head-and-broll",
        imageRules: {
          style: "SCRAPBOOK_SQUARE",
          numImages: 1,
          imagePositionType: "b-roll",
          imageLayout: "fullscreen",
        },
        frame: {
          assetId: variant?.frame ?? "",
          placeholders: variant?.placeholders,
        },
      };
    },
    variants: [
      { frame: "talkinghead_and_broll_1_pink", placeholders: ["source-video", "image"] },
      { frame: "talkinghead_and_broll_2_pink", placeholders: ["source-video", "image"] },
      { frame: "talkinghead_and_broll_3_pink", placeholders: ["source-video", "image"] },
    ],
  } satisfies SceneStyleEntry<{
    frame: FrameBorderAssetKey;
    placeholders: ("image" | "source-video")[];
  }>,

  // Ransom Text
  "scrapbook-ransom-text": {
    builder: (prng) => {
      const backgroundIds = [
        "bg_crumpledpaper_pink",
        "bg_plainmarker_pink",
        "bg_loopiemarker_pink",
        "bg_explosionmarker_pink",
        "bg_scribblemarker_pink",
        "bg_crumpledgrid_pink",
      ];
      const backgroundId = randPick(backgroundIds, prng.next());

      const imageFontIds = [
        "ransom_font_sprite_1",
        "ransom_font_sprite_2",
        "ransom_font_sprite_3",
        "ransom_font_sprite_4",
        "ransom_font_sprite_5",
      ];

      return {
        id: "scrapbook-ransom-text",
        sceneBackground: {
          assetId: backgroundId,
        },
        imageFont: {
          assetIds: imageFontIds,
          position: "top-half",
        },
        capsPosition: "bottom-half",
      };
    },
  },
} as const satisfies SceneStyleConfig;

export type ScrapbookSceneId = keyof typeof SCRAPBOOK_SCENE_STYLE_CONFIG;

export const SCRAPBOOK_GLOBAL_STYLE_CONFIG: GlobalStyleConfig = [];

export const SCRAPBOOK_TRANSITIONS: TransitionConfig = {
  builder: (currentSceneId, nextSceneId) => {
    const allowedTransitions: TransitionAnimationGroupId[] = [
      "tornPaper",
      "markerWipe",
      "gemstones",
    ];

    const talkingHeadScenes: string[] = [
      "scrapbook-cutout",
      "scrapbook-cutout-bottom-right",
      "scrapbook-cutout-stroke",
      "scrapbook-marker-frame-arrow",
      "scrapbook-marker-frame-clean",
      "scrapbook-talking-head-collage",
      "scrapbook-talking-head-and-broll",
    ] satisfies ScrapbookSceneId[];
    const nonTalkingHeadScenes: string[] = [
      "scrapbook-hook",
      "scrapbook-big-text",
      "scrapbook-cutout-text",
      "scrapbook-ransom-text",
      "scrapbook-broll",
    ] satisfies ScrapbookSceneId[];

    const isCurrentSceneTalkingHead = talkingHeadScenes.includes(currentSceneId);
    const isNextSceneTalkingHead = Boolean(nextSceneId && talkingHeadScenes.includes(nextSceneId));
    const isCurrentSceneNonTalkingHead = nonTalkingHeadScenes.includes(currentSceneId);
    const isNextSceneNonTalkingHead = Boolean(
      nextSceneId && nonTalkingHeadScenes.includes(nextSceneId)
    );

    if (isCurrentSceneTalkingHead && isNextSceneTalkingHead) {
      return []; // hard cut
    }
    if (isCurrentSceneNonTalkingHead && isNextSceneNonTalkingHead) {
      return []; // hard cut
    }

    return allowedTransitions;
  },
  library: [
    {
      assetId: "tornPaper",
      volume: SCRAPBOOK_SOUND_VOLUME,
    },
    {
      assetId: "markerWipe",
      volume: SCRAPBOOK_SOUND_VOLUME,
    },
    {
      assetId: "gemstones",
      volume: SCRAPBOOK_SOUND_VOLUME,
    },
  ],
};

// ID for the "Runway" caption template
export const SCRAPBOOK_CAPTION_TEMPLATE = CAPTION_TEMPLATES.Runway;
