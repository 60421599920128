import type { AiEditShot } from "@shared/generated/typescript/aiEdit/api/CreateAiEditVideoTemplate";

import { AIVideoMetadata } from "../../AIScenes.types";
import { Command } from "../../CommandList.types";
import { EDLSegment } from "../../EDL.types";

export function generateSourceVideoCommands(shots: AiEditShot[], videoMetadata: AIVideoMetadata) {
  const commands: Command[] = [];

  let mainTotal = 0;
  const segments: EDLSegment[] = [];
  for (const shot of shots) {
    const sceneDuration = shot.endTime - shot.startTime;
    const segment: EDLSegment = [
      mainTotal,
      mainTotal + sceneDuration,
      shot.startTime,
      shot.endTime,
    ];
    segments.push(segment);
    mainTotal += sceneDuration;
  }

  const sourceVideoCommand: Command = {
    edl: { segments },
    renderEffect: {
      renderEffectType: "overlay",
      assetId: "video-source",
      metadata: videoMetadata,
    },
  };
  commands.push(sourceVideoCommand);

  return commands;
}
