export interface CurvePoint {
  x: number;
  y: number;
}

export interface CurveControlPoints {
  p1: CurvePoint;
  p2: CurvePoint;
}

/**
 * Cubic Bèzier function.
 *
 * @param t - desired position on the curve.
 * @param initial - initial value.
 * @param p1 - first control point.
 * @param p2 - second control point.
 * @param final - final value.
 * @returns the value at the given position.
 */
export function cubicBezier(t: number, initial: number, p1: number, p2: number, final: number) {
  return (
    (1 - t) * (1 - t) * (1 - t) * initial +
    3 * (1 - t) * (1 - t) * t * p1 +
    3 * (1 - t) * t * t * p2 +
    t * t * t * final
  );
}

export function cubicBezierPoint(
  t: number,
  initial: CurvePoint,
  p1: CurvePoint,
  p2: CurvePoint,
  final: CurvePoint
): CurvePoint {
  return {
    x: cubicBezier(t, initial.x, p1.x, p2.x, final.x),
    y: cubicBezier(t, initial.y, p1.y, p2.y, final.y),
  };
}

/**
 * Calculates the point on a cubic spline curve at a given position.
 *
 * @param t - relative desired position on the curve (0 to 1).
 * @param knots - the points that define the curve.
 * @param controlPoints - the control points for each knot.
 * @returns the point at the given position.
 */
export function cubicSplinePoint(
  t: number,
  knots: CurvePoint[],
  controlPoints: CurveControlPoints[]
): CurvePoint {
  if (t <= 0 || !controlPoints.length) {
    return knots[0];
  }
  if (t >= 1) {
    return knots[knots.length - 1];
  }
  const n = knots.length;
  const i = Math.floor(t * (n - 1));
  // Calculates the relative position on the current segment.
  const deltaT = 1 / (n - 1);
  const t1 = i * deltaT;
  const relativeT = (t - t1) / deltaT;
  // Calculates the point on the current segment.
  const p1 = knots[i];
  const p2 = knots[i + 1];
  const cp1 = controlPoints[i].p1;
  const cp2 = controlPoints[i].p2;
  return cubicBezierPoint(relativeT, p1, cp1, cp2, p2);
}

/**
 * Calculates the first control points for a cubic Bézier curve by solving
 * a tri-diagonal system for a single coordinate.
 * @see https://www.codeproject.com/Articles/31859/Draw-a-Smooth-Curve-through-a-Set-of-2D-Points-wit
 *
 * @param rhs - the right hand side of the equation.
 * @returns the first control points.
 */
function calculateFirstControlPoints(rhs: number[]): number[] {
  const n = rhs.length;
  const x: number[] = Array(n).fill(0);
  const tmp: number[] = Array(n).fill(0);

  let b = 2.0;
  x[0] = rhs[0] / b;
  for (let i = 1; i < n; i++) {
    tmp[i] = 1 / b;
    b = (i < n - 1 ? 4.0 : 3.5) - tmp[i];
    x[i] = (rhs[i] - x[i - 1]) / b;
  }
  for (let i = 1; i < n; i++) {
    x[n - i - 1] -= tmp[n - i] * x[n - i];
  }
  return x;
}

export function calculateSplineControlPoints(knots: CurvePoint[]): CurveControlPoints[] {
  if (knots.length < 2) {
    return [];
  }
  if (knots.length === 2) {
    // Special case: just a straight line.
    const knot = knots[0];
    const nextKnot = knots[1];
    const controlPoint1 = {
      x: knot.x + (nextKnot.x - knot.x) / 3,
      y: knot.y + (nextKnot.y - knot.y) / 3,
    };
    const controlPoint2 = {
      x: knot.x + (nextKnot.x - knot.x) / 3,
      y: knot.y + (nextKnot.y - knot.y) / 3,
    };
    return [{ p1: controlPoint1, p2: controlPoint2 }];
  }
  // Calculates the first control points for the X axis.
  const rhs: number[] = Array(knots.length - 1).fill(0);
  for (let i = 1; i < rhs.length - 1; i++) {
    rhs[i] = 4 * knots[i].x + 2 * knots[i + 1].x;
  }
  rhs[0] = knots[0].x + 2 * knots[1].x;
  rhs[rhs.length - 1] = (8 * knots[knots.length - 2].x + knots[knots.length - 1].x) / 2;
  const x = calculateFirstControlPoints(rhs);
  // Calculates the first control points for the Y axis.
  for (let i = 1; i < rhs.length - 1; i++) {
    rhs[i] = 4 * knots[i].y + 2 * knots[i + 1].y;
  }
  rhs[0] = knots[0].y + 2 * knots[1].y;
  rhs[rhs.length - 1] = (8 * knots[knots.length - 2].y + knots[knots.length - 1].y) / 2;
  const y = calculateFirstControlPoints(rhs);
  // Calculates the other control points and returns everything.
  return x.map((_, i) => ({
    p1: { x: x[i], y: y[i] },
    p2:
      i < x.length - 1
        ? { x: 2 * knots[i + 1].x - x[i + 1], y: 2 * knots[i + 1].y - y[i + 1] }
        : { x: (knots[i + 1].x - x[i]) / 2, y: (knots[i + 1].y - y[i]) / 2 },
  }));
}
