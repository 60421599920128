import { useCallback, useEffect, useRef, useState } from "react";

import { useBackendServicesClient } from "~/context/BackendServicesContext";

import {
  DetectFacesJobState,
  getDetectFacesStatus,
  startDetectFacesJob,
} from "../services/DetectFaces";
import type { ProjectFaceData } from "../utils/faceData/face-data.types";

/** Delay in milliseconds before polling backend for status again. */
export const POLLING_INTERVAL = 500;

/**
 * Handles starting and polling a face detection job on the backend.
 * Modelled after `useDubbing`.
 */
export function useFacesBackend() {
  const abortControllerRef = useRef(new AbortController());
  const client = useBackendServicesClient();
  const [jobState, setJobState] = useState<DetectFacesJobState & { message?: string }>({
    status: "idle",
  });
  const [onFinished, setOnFinished] = useState<(projectFaceData: ProjectFaceData) => void>(
    () => () => {}
  );
  const pollingStartTimeRef = useRef<number>();

  useEffect(() => {
    const { status, result } = jobState;
    if (status === "finished") {
      if (!result) {
        console.error("[face-detection] job finished with empty result");
        return;
      }

      // transform from sparse array (frames omitted with no faces)
      const faceData: ProjectFaceData = {
        fps: result.fps,
        faceFrames: [],
      };
      let lastId = 0;
      result.frames.forEach(({ id, faces }) => {
        // fill in missing frames with empty array
        while (id > lastId + 1) {
          faceData.faceFrames.push([]);
          lastId++;
        }
        faceData.faceFrames.push(faces);
        lastId = id;
      });

      console.log("[face-detection] job finished", faceData);
      onFinished(faceData);
    }
  }, [jobState?.result, onFinished]);

  const handleError = (error: unknown) => {
    console.error(error);
    if (abortControllerRef.current.signal.aborted) {
      setJobState({ status: "canceled" });
    } else {
      const message = error instanceof Error ? error.message : "error";
      setJobState({
        status: "error",
        message,
      });
    }
  };

  const updateScenesJobStatus = useCallback((scenesJobId: string) => {
    if (Date.now() - pollingStartTimeRef.current! > 5 * 60 * 1000) {
      // stop job if polling for more than 5 minutes
      console.error("[face-detection] polling timed out");
      setJobState({ status: "error", message: "polling timed out" });
      return;
    }

    getDetectFacesStatus(client, scenesJobId, abortControllerRef.current.signal)
      .then((job) => {
        if (job.status === "started") {
          console.log("[face-detection] job started");
          setTimeout(() => updateScenesJobStatus(scenesJobId), POLLING_INTERVAL);
        } else if (["processing", "progress"].includes(job.status)) {
          setJobState({ status: "progress" });
          setTimeout(() => updateScenesJobStatus(scenesJobId), POLLING_INTERVAL);
        } else if (job.status === "finished") {
          setJobState({
            status: "finished",
            result: job.result,
          });
        } else if (job.status === "error") {
          console.error("[face-detection] job failed", job);
          setJobState({ status: "error", message: "backend job failed" });
        } else {
          console.error("[face-detection]", job);
        }
      })
      .catch(handleError);
  }, []);

  const startDetectFaces = (
    sourceFileId: string,
    onFinished: (projectFaceData: ProjectFaceData) => void
  ) => {
    setOnFinished(() => onFinished);

    if (!["idle", "finished", "error", "canceled"].includes(jobState.status)) {
      console.warn(`[face-detection] job already started with status ${jobState.status}`);
      return;
    }
    setJobState({ status: "started" });
    abortControllerRef.current = new AbortController();

    console.log("[face-detection] starting job");
    startDetectFacesJob(client, { fileId: sourceFileId })
      .then((scenesJob) => {
        pollingStartTimeRef.current = Date.now();
        setTimeout(() => updateScenesJobStatus(scenesJob.jobId), POLLING_INTERVAL);
      })
      .catch(handleError);
  };

  return { startDetectFaces, detectFacesState: jobState };
}
