/* eslint-disable @typescript-eslint/no-explicit-any */

import { Fragment } from "react";

type OptionKeys<T> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [K in keyof T]-?: {} extends { [P in K]: T[K] } ? K : never;
}[keyof T];

type ExcludingOptionalFields<T> = {
  [K in keyof Omit<T, OptionKeys<T>>]-?: T[K];
};

type ProviderComponent<P = unknown> = React.JSXElementConstructor<React.PropsWithChildren<P>>;

type ProviderWithProps<P> = keyof Omit<P, "children"> extends never
  ? // if a provider has no props, you can specify it directly
    ProviderComponent<P>
  : keyof ExcludingOptionalFields<Omit<P, "children">> extends never
  ? // if a provider only has optional props, you can specify it directly or as a tuple
    ProviderComponent<P> | [ProviderComponent<P>, Omit<P, "children">]
  : // if a provider has required, you must specify it as a tuple
    [ProviderComponent<P>, Omit<P, "children">];

interface ComposeContextProvidersProps<Arr extends any[]> {
  providers: [...{ [K in keyof Arr]: ProviderWithProps<Arr[K]> }];
  children: React.ReactNode;
}

export function ComposeContextProviders<Arr extends any[]>({
  providers,
  children,
}: ComposeContextProvidersProps<Arr>) {
  return (
    <Fragment>
      {providers.reduceRight((acc, provider) => {
        if (Array.isArray(provider)) {
          const [Component, props] = provider;
          return <Component {...props}>{acc}</Component>;
        }

        const Component = provider as ProviderComponent;

        return <Component>{acc}</Component>;
      }, children)}
    </Fragment>
  );
}
