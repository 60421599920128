import * as ScrollArea from "@radix-ui/react-scroll-area";
import * as Select from "@radix-ui/react-select";

import { styled } from "~/theme";

import { Text } from "../Text";

import { FormSelectItemIndicator } from "./FormSelectItem.styles";

export const FormSelectArrowIcon = styled(Select.Icon, {
  "& > *": {
    display: "block",
  },
  transition: "transform 200ms cubic-bezier(0.22, 1, 0.36, 1)",
});

export const FormSelectTrigger = styled(Select.Trigger, {
  backgroundColor: "$grey-800",
  border: "none",
  color: "$grey-600",
  borderRadius: 8,
  padding: "10px 16px",
  "&:focus-visible": {
    outline: "1px solid $primary-purple",
    color: "$primary-purple",
  },
  '&:hover, &[data-state="open"]': {
    color: "$grey-300",
    backgroundColor: "$grey-800-highlighted",
  },
  '&[data-state="open"]': {
    [`& ${FormSelectArrowIcon}`]: {
      transform: "rotate(180deg)",
    },
  },
});

export const FormSelectContainer = styled("div", {
  alignItems: "center",
  display: "flex",
  gap: "$12",
});

export const FormSelectValue = styled(Text, {
  flex: 1,
  textAlign: "left",
  defaultVariants: {
    color: "grey-150",
    variant: "body-2",
  },
});

export const FormSelectContent = styled(Select.Content, {
  backgroundColor: "$grey-800",
  marginTop: 8,
  marginBottom: 8,
  width: "var(--radix-popper-anchor-width)",
  maxHeight: "calc(var(--radix-select-content-available-height) - 8px)",
  border: "1px solid $grey-700",
  borderRadius: 8,
  zIndex: "$z-select",
  variants: {
    density: {
      comfortable: {
        padding: 18,
      },
      compact: {
        padding: 8,
      },
    },
    iconBg: {
      true: {},
      false: {
        [`& ${FormSelectItemIndicator}`]: {
          backgroundColor: "transparent",
          color: "$grey-300",
        },
      },
    },
  },
  defaultVariants: {
    density: "comfortable",
    iconBg: true,
  },
});
FormSelectContent.defaultProps = { position: "popper", side: "bottom", avoidCollisions: false };

export const FormSelectScrollRoot = styled(ScrollArea.Root, {
  width: "100%",
  height: "100%",
  maxHeight: "calc(var(--radix-select-content-available-height) - 32px)",
  flex: "1 1 0",
});

export const FormSelectScrollViewport = styled(ScrollArea.Viewport, {
  width: "100%",
  height: "100%",
  padding: "$12 $6",
});

export const FormSelectScrollBar = styled(ScrollArea.Scrollbar, {
  width: "10px",
  padding: "5px 2px",
  marginRight: -14,
});

export const FormSelectScrollThumb = styled(ScrollArea.Thumb, {
  width: "8px",
  background: "$grey-700",
  borderRadius: "4px",
});

export const FormSelectLabelText = styled(Text, {
  display: "block",
  padding: "$12 10px",
  letterSpacing: "150%",
  lineHeight: "200%",
  textTransform: "uppercase",
  defaultVariants: {
    variant: "body-3",
    color: "grey-500",
  },
});

export const FormSelectItemSeparator = styled(Select.Separator, {
  backgroundColor: "transparent",
  height: "2px",
  width: "100%",
});
