import bidiFactory from "bidi-js";

import { Line, Word } from "../captions.types";

const bidi = bidiFactory();

function forceLTR(text: string): string {
  return `\u202d${text}\u202c`;
}

export function getLineInViewingOrder(line: Line): Line {
  const words = line.words;
  const text = words.map((word) => word.text).join(" ");
  const textWordIds = words.flatMap((word) => {
    const result: (number | null)[] = Array(word.text.length).fill(word.id);
    result.push(null);
    return result;
  });
  const embeddingLevels = bidi.getEmbeddingLevels(text);
  const indices = bidi.getReorderedIndices(text, embeddingLevels);
  const reorderedText = text.split("");
  const reorderedWordIds = [...textWordIds];
  indices.forEach(function (charIndex, i) {
    reorderedText[i] =
      (embeddingLevels.levels[charIndex] & 1 ? bidi.getMirroredCharacter(text[charIndex]) : null) ||
      text[charIndex];
    reorderedWordIds[i] = textWordIds[charIndex];
  });

  const reorderedWords: Word[] = [];
  let firstCharIndex = -1;
  let currentWordId: number | null = null;
  reorderedText.forEach((char, index) => {
    if (reorderedWordIds[index] !== currentWordId) {
      if (currentWordId !== null) {
        const word = words.find((word) => word.id === currentWordId);
        if (word) {
          reorderedWords.push({
            ...word,
            text: forceLTR(reorderedText.slice(firstCharIndex, index).join("")),
          });
        }
        firstCharIndex = -1;
      }
      if (reorderedWordIds[index] !== null) {
        firstCharIndex = index;
      }
      currentWordId = reorderedWordIds[index];
    }
  });
  if (currentWordId !== null) {
    const word = words.find((word) => word.id === currentWordId);
    if (word) {
      reorderedWords.push({
        ...word,
        text: forceLTR(reorderedText.slice(firstCharIndex).join("")),
      });
    }
  }

  return { words: reorderedWords };
}
