import { createStitches } from "@stitches/react";

import { avenirNext } from "./fonts";

const { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config } =
  createStitches({
    media: {
      bp1: "(max-width: 768px)",
      "bp1-sidebar": "(max-width: 990px)",
      bp2: "(max-width: 1140px)",
      bp3: "(max-width: 1439px)",
    },
    theme: {
      colors: {
        // 'official' design system primary colors
        "primary-purple-pressed": "#761BD0",
        "primary-purple": "#8C20F8",
        "primary-purple_12": "#8C20F81F",
        "primary-purple_30": "#8C20F84C",
        "primary-purple-hovered": "#7B1CDA",

        // 'official' design system secondary colors
        "secondary-plum": "#6C4DF7",
        "secondary-plum-hovered": "#5437d4",
        "secondary-sky": "#4D9FF7",
        "secondary-teal": "#15B0BD",
        "secondary-water": "#15B0BD",
        "secondary-eucalyptus": "#40CDA0", // also called "sea glass" in designs
        "secondary-eucalyptus_20": "#40CDA033",
        "secondary-mint": "#85E1A2",
        "secondary-brick": "#CD552C",
        "secondary-sunny": "#FFC333",
        "secondary-sunny_20": "#FFC33333",
        "secondary-gold": "#FFE540",
        "secondary-dark-violet": "#6108CC",
        "secondary-grass": "#89D229",
        "secondary-niagara": "#4D72F7",
        "secondary-error": "#FF2D55",
        "secondary-purple": "#A652FA",
        "secondary-lilac": "#B19FFF",

        // 'official' design system greyscale colors
        "grey-10": "#F6F6F8",
        "grey-10_21": "#F6F6F836",
        "grey-50": "#F0F1F5",
        "grey-100": "#EAEBEF",
        "grey-150": "#DFE1E6",
        "grey-150-hovered": "#CDCFD3",
        "grey-200": "#CED0D7",
        "grey-300": "#BDBFC7",
        "grey-400": "#9EA0A8",
        "grey-500": "#7C7F87",
        "grey-600": "#575961",
        "grey-700": "#41434A",
        "grey-700-40": "#41434A4D",
        "grey-800": "#292A30",
        "grey-800-50": "#292A3080",
        "grey-800-highlighted": "#313236",
        "grey-900": "#1C1D21",
        "grey-950": "#131417",

        "primary-violet": "#7E12FF",
        "primary-violet_12": "#7E12FF1F",
        "primary-dark": "#100919",
        "secondary-plum-1": "#6C4DF7",
        "secondary-plum-2": "#4D9FF7",
        "secondary-plum-3": "#15B0BD", // alias of `secondary-teal`
        "secondary-plum-4": "#40CDA0", // alias of `secondary-eucalyptus`
        "secondary-plum-5": "#85E1A2", // alias of `secondary-mint`
        "secondary-light-brick": "#422A34",
        "secondary-lime": "#AAFF4E",
        "neutral-dark-grey": "#2C3137",
        white: "#FFFFFF",
        "pure-black": "#000000",
        "off-black": "#131417",
        "off-black-2": "#181818",
        "grey-10_06": "#F6F6F80F",
        "grey-900_70": "#1C1D21B3",
        "dark-10": "#646672",
        "dark-20": "#5B5C69",
        "dark-30": "#525260",
        "dark-40": "#4A4957",
        "dark-50": "#41404E",
        "dark-60": "#383645",
        "dark-70": "#302D3C",
        "dark-80": "#282433",
        "dark-90": "#1F1B2A",
        "dark-100": "#171221",
        "gradient-gold":
          "linear-gradient(90deg, #CA7900 -5.44%, #CD7F06 3.73%, #D79016 16.13%, #E6AD31 30.41%, #FBD456 45.96%, #FFDC5E 48.77%, #CA7900 89.71%)",
        "gradient-gold_30":
          "linear-gradient(90deg, #CA79004C -5.44%, #CD7F064C 3.73%, #D790164C 16.13%, #E6AD314C 30.41%, #FBD4564C 45.96%, #FFDC5E4C 48.77%, #CA79004C 89.71%)",
        "surface-highlight": "rgba(255, 255, 255, 0.10)",
        "surface-overlay": "rgba(0, 0, 0, 0.50)",
        "error-highlight": "rgba(205, 85, 44, 0.08)",
        "gradient-purple": "linear-gradient(135deg, #8758FF 14.84%, #6C36FD 86.43%)",
        "gradient-purple-hovered":
          "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(135deg, #8758FF 14.84%, #6C36FD 86.43%)",
        "gradient-yellow": "linear-gradient(90deg, #FFC333 0%, #CD7F06 100%)",
        "gradient-green": "linear-gradient(135deg, #5DD78E 14.84%, #11AA4F 86.43%)",
        "secondary-lilac-tint": "rgba(177, 159, 255, 0.2)",

        // AI Edit Colors
        "gradient-cinematic": "linear-gradient(90deg, #FD3F16, #FF9842)",
        "gradient-impact": "linear-gradient(90deg, #5C42FF, #16FDE2)",
        "gradient-paper": "linear-gradient(90deg, #6842FF, #D116FD)",
        "gradient-scrapbook": "linear-gradient(90deg, #FF00D9, #FFB590)",
      },
      fonts: {
        "avenir-next": `${avenirNext.style.fontFamily}, sans-serif`,
        silka: "Silka, sans-serif",
        "neue-haas-display": "NeueHaasDisplay, sans-serif",
      },
      fontSizes: {
        default: "0.9375rem",
        56: "3.5rem",
        32: "2rem",
        28: "1.75rem",
        24: "1.5rem",
        18: "1.125rem",
        16: "1rem",
        15: "0.9375rem",
        14: "0.875rem",
        12: "0.75rem",
        11: "0.6875rem",
        10: "0.635rem",
        8: "0.5rem",
      },
      fontWeights: {
        medium: 500,
        "demi-bold": 600,
        bold: 700,
      },
      lineHeights: {
        body1: "1.5rem",
        40: "2.5rem",
        32: "2rem",
        24: "1.5rem",
        18: "1.125rem",
        16: "1rem",
        12: "0.75rem",
      },
      letterSpacings: {
        // note that figma letterspacing conversion is % to em
        "spacing-1": "0.01em",
        "spacing-1_5": "0.015em",
        "spacing-2_0": "0.02em",
        "spacing-2_5": "0.025em",
        "spacing-3": "0.03em",
        body1: "0.015em%",
      },
      radii: {
        2: "2px",
        3: "3px",
        4: "4px",
        5: "5px",
        6: "6px",
        8: "8px",
        12: "12px",
        16: "16px",
        24: "24px",
        full: "9999px",
      },
      shadows: {
        "level-1": "0px 8px 24px rgba(0, 0, 0, 0.21)",
        "level-2": "0px 12px 32px rgba(0, 0, 0, 0.21)",
        "level-3": "0px 16px 48px rgba(0, 0, 0, 0.21)",
        "level-4": "0px 24px 64px rgba(0, 0, 0, 0.21)",
        "highlight-1": "inset 0px 0px 0px 1px rgba(255, 255, 255, 0.1)",
      },
      sizes: {
        32: "32px",
        48: "48px",
      },
      space: {
        2: "2px",
        4: "4px",
        6: "6px",
        8: "8px",
        10: "10px",
        16: "16px",
        18: "18px",
        24: "24px",
        32: "32px",
        40: "40px",
        48: "48px",
        56: "56px",
        64: "64px",
        72: "72px",
        80: "80px",
        88: "88px",
        96: "96px",
        12: "12px",
        20: "20px",
        28: "28px",
        36: "36px",
        44: "44px",
        52: "52px",
        60: "60px",
        68: "68px",
        76: "76px",
        84: "84px",
        92: "92px",
      },
      transitions: {
        all: "all 150ms",
      },
      zIndices: {
        "z-dialog": 9999,
        "z-select": 99999,
        "z-toast": 999999,
        "z-tooltip": 9999999,
      },
    },
  });

// CSS reset and global styles
const globalStyles = globalCss({
  ":root": {
    colorScheme: "dark",
    "@supports not selector(::-webkit-scrollbar-thumb)": {
      // Fallback for Firefox so the native scroll bar is correctly colored
      scrollbarColor: "$colors$grey-700 $colors$grey-800",
    },
  },
  "*, *::before, *::after": {
    boxSizing: "border-box",
    outlineColor: "$primary-violet",
  },
  ":focus": {
    // Explicitly declaring the focus style here to prevent chrome
    // from adding a white halo around the default outline, which caused
    // a small glitch in the button clipping
    outlineStyle: "solid",
    "&:not(:focus-visible)": {
      outline: "none",
    },
  },
  // The default scrollbar styling on webkit/Chromium browsers is very inconsistent between
  // different platforms. While it looks good on macOS, it looks very out of place on Windows and
  // Linux. By using a custom scrollbar styling it looks consistent across all platforms, but
  // disables the auto hide feature on macOS.
  // In cases where this is undesirable (e.g. layout shifts), <ScrollArea> component should be used
  // instead.
  "*::-webkit-scrollbar": {
    width: 10,
    height: 10,
  },
  "*::-webkit-scrollbar-thumb": {
    backgroundColor: "$grey-700",
    borderRadius: 4,
    border: "2px solid transparent",
    backgroundClip: "content-box",
  },
  "html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video":
    {
      margin: 0,
      padding: 0,
      border: 0,
      fontSize: "100%",
      font: "inherit",
      verticalAlign: "baseline",
    },
  "html, body": {
    height: "100%",
    width: "100%",
    minWidth: "265px",
    minHeight: "265px",
    overscrollBehavior: "none",
  },
  "#__next": {
    height: "100%",
  },
  body: {
    backgroundColor: "$grey-900",
    color: "$grey-400",
    fontFamily: "$avenir-next",
    display: "flow-root",
    userSelect: "none",
  },
  a: {
    color: "$primary-violet",
    textDecoration: "none",
    "&:hover": {
      color: "$primary-purple",
    },
  },
  button: {
    backgroundColor: "transparent",
    color: "inherit",
    border: "inherit",
    cursor: "pointer",
    backgroundImage: "none",
  },
  "@font-face": [
    {
      fontFamily: "'NeueHaasDisplay'",
      fontWeight: 500,
      fontStyle: "normal",
      fontDisplay: "swap",
      src: `url(https://captions-cdn.xyz/SmartVideo/Fonts/NeueHaasDisplayMedium.ttf) format("truetype")`,
    },
  ],
});

const availableColorSchemeVariantStyles = {
  white: {
    "--primary-color": "$colors$white",
    "--primary-color-hovered": "$colors$grey-50",
    "--primary-text": "$colors$grey-900",
    "--primary-text-hovered": "$colors$grey-950",
    "--primary-text-active": "$colors$grey-950",
  },
  purple: {
    "--primary-color": "$colors$primary-purple",
    "--primary-color-hovered": "$colors$primary-purple-hovered",
    "--primary-text": "$colors$grey-100",
    "--primary-text-hovered": "$colors$grey-50",
    "--primary-text-active": "$colors$grey-50",
  },
  teal: {
    "--primary-color": "$colors$secondary-teal",
    "--primary-color-hovered": "$colors$secondary-teal",
    "--primary-text": "$colors$grey-150",
    "--primary-text-hovered": "$colors$grey-150",
    "--primary-text-active": "$colors$grey-150",
  },
  eucalyptus: {
    "--primary-color": "$colors$secondary-eucalyptus",
    "--primary-color-hovered": "$colors$secondary-eucalyptus",
    "--primary-text": "$colors$grey-800",
    "--primary-text-hovered": "$colors$grey-800",
    "--primary-text-active": "$colors$grey-800",
  },
  sunny: {
    "--primary-color": "$colors$secondary-sunny",
    "--primary-color-hovered": "$colors$secondary-sunny",
    "--primary-text": "$colors$grey-800",
    "--primary-text-hovered": "$colors$grey-800",
    "--primary-text-active": "$colors$grey-800",
  },
  plum: {
    "--primary-color": "$colors$secondary-plum",
    "--primary-color-hovered": "$colors$secondary-plum-hovered",
    "--primary-text": "$colors$grey-100",
    "--primary-text-hovered": "$colors$grey-100",
    "--primary-text-active": "$colors$grey-100",
  },
  niagara: {
    "--primary-color": "$colors$secondary-niagara",
    "--primary-color-hovered": "$colors$secondary-niagara",
    "--primary-text": "$colors$grey-100",
    "--primary-text-hovered": "$colors$grey-100",
    "--primary-text-active": "$colors$grey-100",
  },
  grey: {
    "--primary-color": "$colors$grey-800",
    "--primary-color-hovered": "$colors$grey-700",
    "--primary-text": "$colors$grey-200",
    "--primary-text-hovered": "$colors$grey-200",
    "--primary-text-active": "$colors$grey-200",
    fontWeight: "normal",
  },
  overlay: {
    "--primary-color": "$colors$surface-overlay",
    "--primary-color-hovered": "rgba(0, 0, 0, 0.60)",
    "--primary-text": "$colors$grey-10",
    "--primary-text-hovered": "$colors$grey-10",
    "--primary-text-active": "$colors$grey-10",
    fontWeight: "normal",
  },
  gold: {
    "--primary-color": "$gradient-gold",
    "--primary-color-hovered": "$gradient-gold",
    "--primary-text": "$colors$grey-900",
    "--primary-text-hovered": "$colors$grey-900",
    "--primary-text-active": "$colors$grey-900",
  },
  cinematic: {
    "--primary-color": "$colors$gradient-cinematic",
    "--primary-text": "$colors$gradient-cinematic",
  },
  impact: {
    "--primary-color": "$colors$gradient-impact",
    "--primary-text": "$colors$gradient-impact",
  },
  paper: {
    "--primary-color": "$colors$gradient-paper",
    "--primary-text": "$colors$gradient-paper",
  },
  scrapbook: {
    "--primary-color": "$colors$gradient-scrapbook",
    "--primary-text": "$colors$gradient-scrapbook",
  },
};

export type ColorScheme = keyof typeof availableColorSchemeVariantStyles;

export {
  config,
  createTheme,
  css,
  getCssText,
  globalCss,
  globalStyles,
  keyframes,
  styled,
  availableColorSchemeVariantStyles,
  theme,
};
