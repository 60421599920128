import { CreateAiEditVideoPollResponse } from "@shared/generated/typescript/aiEdit/api/CreateAiEditVideoTemplate";
import { HttpResponse, http } from "msw";

import { proxied } from "../utils";

import { createAiEditVideoPollResponse as createAiEditVideoPollResponseMock } from "./createAiEditVideoPollResponse.mock";

export const aiEditHandlers = [
  http.post("*/ai-edit/v1/submit", async () => {
    return HttpResponse.json(proxied({ operationId: "123" }));
  }),
  http.post("*/ai-edit/v1/poll", async () => {
    return HttpResponse.json(
      proxied(
        CreateAiEditVideoPollResponse.fromJsonString(
          JSON.stringify(createAiEditVideoPollResponseMock)
        )
      )
    );
  }),
];
