import { createStore } from "zustand";
import { combine } from "zustand/middleware";
import { shallow } from "zustand/shallow";
import { useStoreWithEqualityFn } from "zustand/traditional";

import { promiseWithResolvers } from "~/utils/promise-with-resolvers";
import { ExtractState } from "~/utils/zustand-store";

import { CaptionsDatabaseInstance, dbFactory } from "./factory";
import {
  trackDBEvent,
  enhanceTransaction,
  enhanceClose,
  handleVersionChange,
  handleDatabaseError,
  handleDatabaseAbort,
} from "./utils";

type IndexedDBStore =
  | {
      status: "loading" | "error" | "reconnecting";
      instance: CaptionsDatabaseInstance | null;
      dbPromise: Promise<CaptionsDatabaseInstance>;
      retryCount: number;
    }
  | {
      status: "ready";
      instance: CaptionsDatabaseInstance;
      dbPromise: Promise<CaptionsDatabaseInstance>;
      retryCount: number;
    };

export type CaptionsDatabaseStatus = IndexedDBStore["status"];

// NOTE: this has a slightly different Zustand pattern since we want to create this store OUTSIDE
// of React so it can be used outside or inside components/hooks and it does not need any props/params to create.
// this also means this function will be called on the server and client so that's taken into account below.
const createDatabaseStore = () => {
  let currentPromise = promiseWithResolvers<CaptionsDatabaseInstance>();

  const store = createStore(
    combine(
      {
        status: "loading",
        instance: null,
        dbPromise: currentPromise.promise,
        retryCount: 0,
      } as IndexedDBStore,
      (set, get) => ({
        ready: (db: CaptionsDatabaseInstance) => {
          enhanceTransaction(db);
          enhanceClose(db);
          currentPromise.resolve(db);
          set({ status: "ready", instance: db, retryCount: 0 });
        },
        error: () => {
          currentPromise.reject();
          set({ status: "error" });
          trackDBEvent("initialization_error");
        },
        resetConnection: async () => {
          const state = get();
          if (state.status === "reconnecting") {
            return state.dbPromise;
          }

          set({ status: "reconnecting" });

          // Create new promise resolvers
          currentPromise = promiseWithResolvers<CaptionsDatabaseInstance>();
          set({ dbPromise: currentPromise.promise });

          try {
            const newDb = await dbFactory();
            // Set up handlers for the new connection
            newDb.onversionchange = (event) =>
              handleVersionChange(event, newDb, () => {
                store.getInitialState().resetConnection();
              });
            newDb.onerror = (event) => handleDatabaseError((event.target as IDBRequest)?.error);
            newDb.onabort = (event) => handleDatabaseAbort((event.target as IDBRequest)?.error);

            store.getState().ready(newDb);
            return newDb;
          } catch (error) {
            store.getState().error();
            throw error;
          }
        },
      })
    )
  );

  if (typeof window !== "undefined" && window.indexedDB) {
    dbFactory()
      .then((db) => {
        db.onversionchange = (event) =>
          handleVersionChange(event, db, () => {
            store.getInitialState().resetConnection();
          });
        db.onerror = (event) => handleDatabaseError((event.target as IDBRequest)?.error);
        db.onabort = (event) => handleDatabaseAbort((event.target as IDBRequest)?.error);
        store.getState().ready(db);
      })
      .catch(handleDatabaseError);
  }

  return store;
};

export const databaseStore = createDatabaseStore();

// you are free to use the store directly or inside react with the hook below. if you are writing
// code that is only business logic and not UI, please try to write that logic outside of React
// and use the store directly.
export const getDB = async () => {
  const store = databaseStore.getState();

  if (store.status === "ready" && store.instance) {
    return store.instance;
  }

  return store.resetConnection();
};

export const useDatabaseStore = <T>(
  selector: (state: ExtractState<typeof databaseStore>) => T,
  // shallow allows us to select arrays or objects without having to use useMemo
  equalityFn: (left: T, right: T) => boolean = shallow
) => {
  return useStoreWithEqualityFn(databaseStore, selector, equalityFn);
};
