export const NewspaperAnimations: { [key: string]: URL } = {
  Newspaper_Blank_2_Letter_A_HOLD: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/newspaper_blank_2_letter_A_HOLD.pag"
  ),
  Newspaper_Blank_2_Letter_A_OUT: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/newspaper_blank_2_letter_A_OUT.pag"
  ),
  Newspaper_Blank_2_Letter_B_HOLD: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/newspaper_blank_2_letter_B_HOLD.pag"
  ),
  Newspaper_Blank_2_Letter_B_OUT: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/newspaper_blank_2_letter_B_OUT.pag"
  ),
  Newspaper_Blank_2_Letter_C_HOLD: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/newspaper_blank_2_letter_C_HOLD.pag"
  ),
  Newspaper_Blank_2_Letter_C_OUT: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/newspaper_blank_2_letter_C_OUT.pag"
  ),
  Newspaper_Blank_5_Letter_A_HOLD: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/newspaper_blank_5_letter_A_HOLD.pag"
  ),
  Newspaper_Blank_5_Letter_A_OUT: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/newspaper_blank_5_letter_A_OUT.pag"
  ),
  Newspaper_Blank_5_Letter_B_HOLD: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/newspaper_blank_5_letter_B_HOLD.pag"
  ),
  Newspaper_Blank_5_Letter_B_OUT: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/newspaper_blank_5_letter_B_OUT.pag"
  ),
  Newspaper_Blank_5_Letter_C_HOLD: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/newspaper_blank_5_letter_C_HOLD.pag"
  ),
  Newspaper_Blank_5_Letter_C_OUT: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/newspaper_blank_5_letter_C_OUT.pag"
  ),
  Newspaper_Blank_8_Letter_A_HOLD: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/newspaper_blank_8_letter_A_HOLD.pag"
  ),
  Newspaper_Blank_8_Letter_A_OUT: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/newspaper_blank_8_letter_A_OUT.pag"
  ),
  Newspaper_Blank_8_Letter_B_HOLD: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/newspaper_blank_8_letter_B_HOLD.pag"
  ),
  Newspaper_Blank_8_Letter_B_OUT: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/newspaper_blank_8_letter_B_OUT.pag"
  ),
  Newspaper_Blank_8_Letter_C_HOLD: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/newspaper_blank_8_letter_C_HOLD.pag"
  ),
  Newspaper_Blank_8_Letter_C_OUT: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/newspaper_blank_8_letter_C_OUT.pag"
  ),
  Newspaper_Blank_11_Letter_A_HOLD: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/newspaper_blank_11_letter_A_HOLD.pag"
  ),
  Newspaper_Blank_11_Letter_A_OUT: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/newspaper_blank_11_letter_A_OUT.pag"
  ),
  Newspaper_Blank_11_Letter_B_HOLD: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/newspaper_blank_11_letter_B_HOLD.pag"
  ),
  Newspaper_Blank_11_Letter_B_OUT: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/newspaper_blank_11_letter_B_OUT.pag"
  ),
  Newspaper_Blank_11_Letter_C_HOLD: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/newspaper_blank_11_letter_C_HOLD.pag"
  ),
  Newspaper_Blank_11_Letter_C_OUT: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/newspaper_blank_11_letter_C_OUT.pag"
  ),
};
