export const TitleCardAnimations: { [key: string]: URL } = {
  PAG_Search_Results_1_HOLD: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/pag_search_results/PAG_Search_Results_1_HOLD.pag"
  ),
  PAG_Search_Results_1_IN: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/pag_search_results/PAG_Search_Results_1_IN.pag"
  ),
  PAG_Search_Results_1_OUT: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/pag_search_results/PAG_Search_Results_1_OUT.pag"
  ),
  PAG_Search_Results_2_HOLD: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/pag_search_results/PAG_Search_Results_2_HOLD.pag"
  ),
  PAG_Search_Results_2_IN: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/pag_search_results/PAG_Search_Results_2_IN.pag"
  ),
  PAG_Search_Results_2_OUT: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/pag_search_results/PAG_Search_Results_2_OUT.pag"
  ),
  PAG_Search_Results_3_HOLD: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/pag_search_results/PAG_Search_Results_3_HOLD.pag"
  ),
  PAG_Search_Results_3_IN: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/pag_search_results/PAG_Search_Results_3_IN.pag"
  ),
  PAG_Search_Results_3_OUT: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/pag_search_results/PAG_Search_Results_3_OUT.pag"
  ),
  PAG_Search_Results_4_HOLD: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/pag_search_results/PAG_Search_Results_4_HOLD.pag"
  ),
  PAG_Search_Results_4_IN: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/pag_search_results/PAG_Search_Results_4_IN.pag"
  ),
  PAG_Search_Results_4_OUT: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/pag_search_results/PAG_Search_Results_4_OUT.pag"
  ),
  PAG_Search_Results_5_HOLD: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/pag_search_results/PAG_Search_Results_5_HOLD.pag"
  ),
  PAG_Search_Results_5_IN: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/pag_search_results/PAG_Search_Results_5_IN.pag"
  ),
  PAG_Search_Results_5_OUT: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/pag_search_results/PAG_Search_Results_5_OUT.pag"
  ),
  PAG_Search_Results_6_HOLD: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/pag_search_results/PAG_Search_Results_6_HOLD.pag"
  ),
  PAG_Search_Results_6_IN: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/pag_search_results/PAG_Search_Results_6_IN.pag"
  ),
  PAG_Search_Results_6_OUT: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/pag_search_results/PAG_Search_Results_6_OUT.pag"
  ),
  PAG_Search_Results_7_HOLD: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/pag_search_results/PAG_Search_Results_7_HOLD.pag"
  ),
  PAG_Search_Results_7_IN: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/pag_search_results/PAG_Search_Results_7_IN.pag"
  ),
  PAG_Search_Results_7_OUT: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/pag_search_results/PAG_Search_Results_7_OUT.pag"
  ),
  PAG_Search_Results_8_HOLD: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/pag_search_results/PAG_Search_Results_8_HOLD.pag"
  ),
  PAG_Search_Results_8_IN: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/pag_search_results/PAG_Search_Results_8_IN.pag"
  ),
  PAG_Search_Results_8_OUT: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/pag_search_results/PAG_Search_Results_8_OUT.pag"
  ),
  label_v2_IN: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/label_v2_in.pag"
  ),
  label_v2_HOLD: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/label_v2_duration.pag"
  ),
};
