import { DialogClose, Root } from "@radix-ui/react-dialog";
import { t } from "i18next";
import { ReactNode, useMemo } from "react";

import { useKeyboardShortcutBlocker } from "~/hooks/useKeyboardShortcutBlocker";

import * as D from "../BaseDialog";
import { Button } from "../Button";
import Delete from "../icons/Delete";

import { ConfirmDialogProps } from "./ConfirmDialog.types";

function getDialogIcon(variant: ConfirmDialogProps["type"]): ReactNode | null {
  switch (variant) {
    case "none":
      return null;
    case "delete":
      return <Delete />;
  }
}

export function ConfirmDialog({
  title,
  message,
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
  open,
  icon: _icon,
  type = "none",
  hideCancelButton = false,
}: Readonly<ConfirmDialogProps>) {
  const icon = useMemo(() => _icon ?? getDialogIcon(type), [type, _icon]);
  useKeyboardShortcutBlocker(open);

  /*
   * Must have modal={false} to prevent delays.
   *
   * Modal dialogs go through the DOM tree to "aria-hide" everything behind them, causing
   * a delay when opening the dialog. This is especially noticeable when using it with a large
   * project.
   */
  return (
    <Root open={open} modal={false}>
      <D.BaseDialogContent>
        <D.SectionContainer>
          {icon && <D.Icon isError={type === "delete"}>{icon}</D.Icon>}
          <D.Title>{title ?? t("common:confirm-dialog.are-you-sure")}</D.Title>
          <D.Description>{message}</D.Description>
        </D.SectionContainer>
        <D.ButtonSectionContainer>
          <Button
            onClick={onConfirm}
            disabled={!onConfirm}
            variant={type === "delete" ? "delete" : "primary-gradient"}
          >
            {confirmLabel ??
              (type === "delete"
                ? t("common:confirm-dialog.delete")
                : t("common:confirm-dialog.yes"))}
          </Button>

          {!hideCancelButton && (
            <DialogClose asChild>
              <Button onClick={onCancel} disabled={!onCancel} variant="cancel">
                {cancelLabel ?? t("common:confirm-dialog.cancel")}
              </Button>
            </DialogClose>
          )}
        </D.ButtonSectionContainer>
        <D.CloseButton onClick={onCancel} />
      </D.BaseDialogContent>
    </Root>
  );
}
