import { CommandList } from "../../CommandList.types";
import { EDLSegment } from "../../EDL.types";

export function parseSegmentTime(value: number, precision: number = 6) {
  if (isNaN(value)) {
    return value;
  }
  return parseFloat(value.toFixed(precision));
}

export function parseCommandListTimestamps(commands: CommandList): CommandList {
  const parsedCommands = commands.map((command) => {
    const parsedSegments = command.edl.segments.map(
      (segment) => segment.map((timestamp) => parseSegmentTime(timestamp)) as EDLSegment
    );
    return {
      ...command,
      edl: {
        ...command.edl,
        segments: parsedSegments,
      },
    };
  });

  return parsedCommands;
}
