import { HttpResponse, http } from "msw";

import sceneDataResponse from "./sceneDataResponse.mock.json";
import sceneTimestampResponse from "./sceneTimestampResponse.mock.json";

export const sceneHandlers = [
  http.post("*/video/v1/scene-timestamps", () => {
    return HttpResponse.json({ sceneDetectJobId: "CsXszv5LLI63fjii2gaE" });
  }),

  http.get("*/video/v1/scene-timestamps/*", () => {
    return HttpResponse.json(sceneTimestampResponse);
  }),
  http.post("*/proxy/v1/ai-edit/scene-data", () => {
    return HttpResponse.json(sceneDataResponse);
  }),
];
