import { CaptionSettingsRepository } from "~/database/repo/CaptionSettingsRepository";
import { CaptionsRepository } from "~/database/repo/CaptionsRepository";
import { EffectsRepository } from "~/database/repo/EffectsRepository";
import { ProjectRepository } from "~/database/repo/ProjectRepository";
import { TimelineProjectRepo } from "~/database/repo/TimelineProjectRepository";
import {
  convertClassicProjectToTimelineProject,
  timelineProjectIdForClassicProject,
} from "~/modules/timeline-project/migrate/convert-project";
import { ProjectEvents } from "~/utils/analytics/ProjectEvents";

import { getDubbingJobResult } from "../hooks/useDubbing";
import { getUpdatedPhraseAudioURLsFromTranscript } from "../utils/captionProcessing";

import { ProjectEntityBag } from "./types";
import { migrateProjectToCurrentVersion } from "./versioning/migrate";

type ProjectLoadResult =
  | { type: "not-found" }
  | { type: "unsupported" }
  | { type: "loaded"; entities: ProjectEntityBag };

type ProjectLoadTarget = "default" | "pro-editor";

interface ProjectLoadParams {
  projectId: string;
  target?: ProjectLoadTarget;
}

export async function loadProjectFromStorage({
  projectId,
  target = "default",
}: ProjectLoadParams): Promise<ProjectLoadResult> {
  // Fetches everything from our persistent repositories
  const project = await ProjectRepository.getProject(projectId);

  if (!project) {
    return { type: "not-found" };
  }

  const captions = project.captionsId
    ? await CaptionsRepository.getCaptions(project.captionsId)
    : undefined;

  const untranslatedCaptions = project.untranslatedCaptionsId
    ? await CaptionsRepository.getCaptions(project.untranslatedCaptionsId)
    : undefined;

  const captionSettings = project.captionSettingsId
    ? await CaptionSettingsRepository.getCaptionSettings(project.captionSettingsId)
    : undefined;

  const effects = project.effectsId
    ? await EffectsRepository.getEffects(project.effectsId)
    : undefined;

  const entities: ProjectEntityBag = {
    project,
    captions: captions ?? null,
    untranslatedCaptions: untranslatedCaptions ?? null,
    effects: effects ?? null,
    captionSettings: captionSettings ?? null,
    timelineProject: null,
  };

  // once we fully roll out the pro-editor, we can remove this and make it a regular migration
  // however, we only want to run this "load/migration" when opening the Pro Editor for now
  if (target === "pro-editor") {
    // make sure we have a timeline project and it's up to date

    const existingTimelineProject = await TimelineProjectRepo.findById(
      timelineProjectIdForClassicProject(entities.project)
    );

    if (!existingTimelineProject) {
      const timelineProject = convertClassicProjectToTimelineProject(
        entities.project,
        entities.effects
      );

      await TimelineProjectRepo.add(timelineProject, timelineProject.id);
      entities.timelineProject = timelineProject;
    } else {
      entities.timelineProject = existingTimelineProject;
    }
  }

  const initialVersion = project.version ?? "unknown";

  // Migrate the project to the current version
  const migrationResult = await migrateProjectToCurrentVersion(entities);

  ProjectEvents.loaded.track({
    project_id: project.id,
    version: initialVersion,
    migration_result: migrationResult.type,
    migrated_to_version: migrationResult.type === "migrated" ? migrationResult.version : undefined,
  });

  if (migrationResult.type === "unsupported") {
    return { type: "unsupported" };
  } else if (migrationResult.type === "up-to-date") {
    // NOTE: ideally we could skip any writes if the project version is already up to date
    // however, we need a way right now to do this audio URL refreshing.
    // so we will always run these "evergreen" migrations on every load right now.
    // i quite dislike that every load also causes the database to be written to, but
    // it's a necessary evil for now.
    const updatedEntities = await updateStaleProjectData(entities);

    return { type: "loaded", entities: updatedEntities };
  } else {
    // save the updated entities to the database
    await ProjectRepository.updateProject(project.id, migrationResult.entities.project);

    if (migrationResult.entities.captionSettings) {
      await CaptionSettingsRepository.updateCaptionSettings(
        migrationResult.entities.captionSettings.id,
        migrationResult.entities.captionSettings
      );
    }

    if (migrationResult.entities.captions) {
      await CaptionsRepository.updateCaptions(migrationResult.entities.captions);
    }

    if (migrationResult.entities.untranslatedCaptions) {
      await CaptionsRepository.updateCaptions(migrationResult.entities.untranslatedCaptions);
    }

    if (migrationResult.entities.effects) {
      await EffectsRepository.updateEffects(
        migrationResult.entities.effects.id,
        migrationResult.entities.effects
      );
    }

    const updatedEntities = await updateStaleProjectData(migrationResult.entities);

    return { type: "loaded", entities: updatedEntities };
  }
}

// this refreshes potentially out of date signed URLs
export async function updateStaleProjectData(
  entities: ProjectEntityBag
): Promise<ProjectEntityBag> {
  if (entities.project.audioDubJobId && entities.captions) {
    const dubbingJobResult = await getDubbingJobResult(entities.project.audioDubJobId);
    if (dubbingJobResult) {
      entities.captions.phraseAudios = getUpdatedPhraseAudioURLsFromTranscript(
        entities.captions.phraseAudios ?? [],
        dubbingJobResult
      );
      entities.project.backgroundAudioUri = dubbingJobResult.backgroundAudioUri;
      entities.project.backgroundAudioSignedUrl = dubbingJobResult.backgroundAudioSignedUrl;

      await ProjectRepository.updateProject(entities.project.id, {
        backgroundAudioUri: dubbingJobResult.backgroundAudioUri,
        backgroundAudioSignedUrl: dubbingJobResult.backgroundAudioSignedUrl,
      });
      await CaptionsRepository.updateCaptions(entities.captions);
    }
  }

  return entities;
}
