import type { Effects } from "../database.types";
import { withRetry } from "../repositoryWrapper";
import { getDB } from "../store";

class BaseEffectsRepository {
  static async addEffects(effects: Omit<Effects, "id">): Promise<string | undefined> {
    const db = await getDB();
    const id = window.crypto.randomUUID();
    await db.add("effects", { ...effects, id });
    return id;
  }

  static async updateEffects(
    effectsId: string,
    settings: Partial<Omit<Effects, "id" | "projectId">>
  ): Promise<void> {
    const db = await getDB();
    const tx = db.transaction(["effects"], "readwrite");
    const effects = await tx.objectStore("effects").get(effectsId);
    if (!effects) {
      tx.abort();
      throw new Error("Effects settings not found");
    }
    await tx.objectStore("effects").put({
      ...effects,
      ...settings,
    });
    await tx.done;
  }

  static async removeEffects(effectsId: string): Promise<void> {
    const db = await getDB();
    await db.delete("effects", effectsId);
  }

  static async getEffects(effectsId: string): Promise<Effects | undefined> {
    const db = await getDB();
    return await db.get("effects", effectsId);
  }
}

export const EffectsRepository = withRetry(BaseEffectsRepository);
