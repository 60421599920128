import { ComponentProps } from "react";

import { BaseSkeleton } from "./Skeleton.styles";
import { SkeletonProps } from "./Skeleton.types";

export function Skeleton({
  height = "100%",
  width = "100%",
  ...props
}: SkeletonProps & ComponentProps<typeof BaseSkeleton>) {
  return (
    <BaseSkeleton
      style={{
        height,
        width,
      }}
      {...props}
    />
  );
}
