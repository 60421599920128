import { BackendServicesClient } from "~/context/BackendServicesContext";

import { Transcript } from "../../../project/services/Transcription";
import { AISceneData, AIWord } from "../../AIScenes.types";

interface GetScenesFromTranscriptResponse {
  success: boolean;
  data: AISceneData;
}

export async function getScenesFromTranscript(
  client: BackendServicesClient,
  transcript: Transcript
): Promise<AISceneData> {
  const words: AIWord[] = transcript.words.map((word) => ({
    startTime: word.startTime,
    endTime: word.endTime,
    word: word.text,
  }));
  const transcriptText = words.map((word) => word.word).join(" ");
  const result = await client.post<GetScenesFromTranscriptResponse>(
    "/proxy/v1/ai-edit/scene-data",
    {
      transcript: transcriptText,
      words,
    }
  );
  if (!result.data.success) {
    throw new Error("Failed to get scenes from transcript", { cause: result.data });
  }
  return result.data.data;
}
