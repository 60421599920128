import { AnyCanvasRenderingContext2D } from "./captionDrawing.types";
import { CharacterMap, ImageFontEffect, ImageFontPhrase } from "./imageFontGenerator.types";
import { normalizePhrase } from "./utils/normalizePhrase";

export class ImageFontGenerator {
  private _timestamp: number = 0;
  private _width: number = 0;
  private _height: number = 0;
  private _characterVariations: { [assetId: string]: CharacterMap } = {};
  private _phrases: Array<ImageFontPhrase> = [];

  private static _characterSet: Array<Array<string>> = [
    ["!", "?", "&", "#", "%", "$", "-", ":", '"', "'"],
    ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j"],
    ["k", "l", "m", "n", "o", "p", "q", "r", "s", "t"],
    ["u", "v", "w", "x", "y", "z"],
  ];

  async init(imageFontEffects: ImageFontEffect[]): Promise<void> {
    if (!this._width || !this._height) {
      return;
    }

    // Base Image Font dimensions
    const baseWidth = 1080;
    const baseHeight = 1920;
    const baseCharWidth = 145;
    const baseCharHeight = 245;

    // Calculate the scaling factor based on the target resolution
    const targetWidth = this._width;
    const targetHeight = this._height;
    const widthScale = targetWidth / baseWidth || 1;
    const heightScale = targetHeight / baseHeight || 1;

    // Apply scaling to the base character dimensions
    const resizeWidth = baseCharWidth * widthScale;
    const resizeHeight = baseCharHeight * heightScale;

    const phrasesPromises = imageFontEffects.map(async (effect) => {
      const assetIds = await Promise.all(
        effect.assets.map(async ({ id, image }) => {
          if (this._characterVariations[id]) {
            return id;
          }

          const _characters = new Map<string, ImageBitmap>();
          const columns = ImageFontGenerator._characterSet[0].length;
          const rows = ImageFontGenerator._characterSet.length;
          const charWidth = image.width / columns;
          const charHeight = image.height / rows;

          const characterPromises = ImageFontGenerator._characterSet.flatMap((row, rowIndex) => {
            const rowPromises = row.map(async (char, colIndex) => {
              const charX = charWidth * colIndex;
              const charY = charHeight * rowIndex;
              const charImage = await createImageBitmap(
                image,
                charX,
                charY,
                charWidth,
                charHeight,
                { resizeWidth, resizeHeight }
              );

              _characters.set(char, charImage);
            });

            return rowPromises;
          });
          await Promise.all(characterPromises);

          this._characterVariations[id] = _characters;
          return id;
        })
      );

      const phrase: ImageFontPhrase = {
        text: effect.text,
        startTime: effect.startTime,
        endTime: effect.endTime,
        positionFactor: effect.positionFactor,
        assetIds,
      };

      return phrase;
    });
    this._phrases = await Promise.all(phrasesPromises);
  }

  public async setArea(width: number, height: number) {
    this._width = width;
    this._height = height;
  }

  setTimestamp(timestamp: number) {
    this._timestamp = timestamp;
  }

  private _getImageForCharacter(
    characters: Map<string, ImageBitmap>,
    char: string
  ): ImageBitmap | undefined {
    return characters.get(char);
  }

  /*
   * Returns an image bitmap using the character images of the given phrase.
   * Each word in phrase takes up one line.
   */
  private async _getImageForPhrase(phrase: string, characters: CharacterMap): Promise<ImageBitmap> {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (!context) {
      throw new Error("Could not get 2d context");
    }

    const charWidth = this._getImageForCharacter(characters, "a")?.width;
    const charHeight = this._getImageForCharacter(characters, "a")?.height;

    if (!charWidth || !charHeight) {
      throw new Error("Could not get character width or height");
    }

    const normalizedPhrase = normalizePhrase(phrase);
    const words = normalizedPhrase.split(" ");
    const longestWord = words.slice().sort((a, b) => b.length - a.length)[0];

    canvas.width = charWidth * longestWord.length;
    canvas.height = charHeight * words.length;

    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      const wordWidth = word.length * charWidth;
      const wordX = (canvas.width - wordWidth) / 2;
      for (let j = 0; j < word.length; j++) {
        const char = word[j];
        const charImage = this._getImageForCharacter(characters, char);
        if (!charImage) {
          throw new Error(`Could not find image for character: ${char}`);
        }

        context.drawImage(charImage, wordX + j * charWidth, i * charHeight);
      }
    }

    return createImageBitmap(canvas);
  }

  async draw(context: AnyCanvasRenderingContext2D, x: number, y: number) {
    if (!this._width || !this._height) {
      return;
    }

    const timestamp = this._timestamp;
    const phrase = this._phrases.find(
      (phrase) => timestamp >= phrase.startTime && timestamp < phrase.endTime
    );
    if (!phrase) {
      return;
    }
    const { assetIds, positionFactor, text, startTime } = phrase;

    const possibleCharacterVariations = assetIds.map(
      (assetId) => this._characterVariations[assetId]
    );

    const timeInPhrase = timestamp - startTime;
    const characterSetIndex = Math.floor(timeInPhrase * 3) % possibleCharacterVariations.length;
    const characters = possibleCharacterVariations[characterSetIndex];

    let image;
    try {
      image = await this._getImageForPhrase(text, characters);
    } catch (error) {
      console.error(error);
      return;
    }

    const titleY = y + positionFactor * this._height;
    if (image.width > this._width) {
      // if image is wider than width of destination, scale it down
      context.drawImage(image, x, titleY, this._width, (image.height * this._width) / image.width);
    } else {
      const titleX = x + (this._width - image.width) / 2;
      context.drawImage(image, titleX, titleY);
    }
  }

  destroy() {
    Object.values(this._characterVariations).forEach((asset) =>
      asset.forEach((char) => char.close())
    );
    this._characterVariations = {};
    this._phrases = [];
    this._timestamp = 0;
  }
}
