export const EmojiAnimations: { [key: string]: URL } = {
  "🥺": new URL("./assets/emoji-pleading-face.pag", import.meta.url),
  "🤮": new URL("./assets/emoji_ nauseated-face.pag", import.meta.url),
  "⏰": new URL("./assets/emoji_Alarm clock.pag", import.meta.url),
  "😰": new URL("./assets/emoji_anoxious-face-with-sweat.pag", import.meta.url),
  "🎯": new URL("./assets/emoji_bullseye.pag", import.meta.url),
  "🎂": new URL("./assets/emoji_cake.pag", import.meta.url),
  "✅": new URL("./assets/emoji_Check mark button.pag", import.meta.url),
  "👏": new URL("./assets/emoji_clapping-hands.pag", import.meta.url),
  "🍻": new URL("./assets/emoji_Clinking_beer.pag", import.meta.url),
  "❌": new URL("./assets/emoji_cross.pag", import.meta.url),
  "😭": new URL("./assets/emoji_cry.pag", import.meta.url),
  "‼️": new URL("./assets/emoji_Double exclamation.pag", import.meta.url),
  "🥁": new URL("./assets/emoji_Drum.pag", import.meta.url),
  "😛": new URL("./assets/emoji_face-with-tongue.pag", import.meta.url),
  "🔥": new URL("./assets/emoji_fire.pag", import.meta.url),
  "😳": new URL("./assets/emoji_flushed.pag", import.meta.url),
  "👍": new URL("./assets/emoji_good.pag", import.meta.url),
  "😆": new URL("./assets/emoji_Grinning squinting face.pag", import.meta.url),
  "😀": new URL("./assets/emoji_grinning-face-big-eye.pag", import.meta.url),
  "🤯": new URL("./assets/emoji_head-explode.pag", import.meta.url),
  "🤔": new URL("./assets/emoji_hmm.pag", import.meta.url),
  "☕️": new URL("./assets/emoji_hot-beverage.pag", import.meta.url),
  "😚": new URL("./assets/emoji_kiss-with-closed-eyes.pag", import.meta.url),
  "💡": new URL("./assets/emoji_light-bulb.pag", import.meta.url),
  "💋": new URL("./assets/emoji_lipstick-mark.pag", import.meta.url),
  "👀": new URL("./assets/emoji_look.pag", import.meta.url),
  "🫠": new URL("./assets/emoji_melting.pag", import.meta.url),
  "🤑": new URL("./assets/emoji_money-face.pag", import.meta.url),
  "💸": new URL("./assets/emoji_money-with-wings.pag", import.meta.url),
  "🎶": new URL("./assets/emoji_music-notes.pag", import.meta.url),
  "💯": new URL("./assets/emoji_one-hundred.pag", import.meta.url),
  "🥳": new URL("./assets/emoji_party-face.pag", import.meta.url),
  "🎉": new URL("./assets/emoji_party-popper.pag", import.meta.url),
  "🐾": new URL("./assets/emoji_paw-print.pag", import.meta.url),
  "➕": new URL("./assets/emoji_plus.pag", import.meta.url),
  "💩": new URL("./assets/emoji_poopy.pag", import.meta.url),
  "😡": new URL("./assets/emoji_pouting.pag", import.meta.url),
  "🌈": new URL("./assets/emoji_rainbow.pag", import.meta.url),
  "❤️": new URL("./assets/emoji_red-heart.pag", import.meta.url),
  "🚀": new URL("./assets/emoji_rocket-ship.pag", import.meta.url),
  "🙄": new URL("./assets/emoji_rolling-eyes.pag", import.meta.url),
  "😋": new URL("./assets/emoji_savoring-food.pag", import.meta.url),
  "😱": new URL("./assets/emoji_scream-in-fear.pag", import.meta.url),
  "🚨": new URL("./assets/emoji_siren.pag", import.meta.url),
  "😴": new URL("./assets/emoji_sleeping.pag", import.meta.url),
  "🥰": new URL("./assets/emoji_smiling-with-hearts.pag", import.meta.url),
  "😈": new URL("./assets/emoji_smiling-with-horns.pag", import.meta.url),
  "✨": new URL("./assets/emoji_sparkles.pag", import.meta.url),
  "🥀": new URL("./assets/emoji_wilted-flower.pag", import.meta.url),
};

export const knownEmojis = Object.keys(EmojiAnimations);
