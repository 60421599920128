import { styled } from "~/theme";

import { Text } from "../Text";

export const ComboBoxGroupLabelText = styled(Text, {
  display: "block",
  padding: "$12",
  defaultVariants: {
    variant: "body-3-caps",
    color: "grey-600",
  },
});

export const ComboBoxGroupLabelNone = styled("div", {
  display: "block",
  padding: "$12 0 0 0",
});
