import { z } from "zod";

export const sendVerificationCodeRequestSchema = z.object({
  region: z.string(),
  phoneNumber: z.string(),
});

export type SendVerificationCodeRequest = z.infer<typeof sendVerificationCodeRequestSchema>;

export interface SendVerificationCodeResponse {
  success: boolean;
  reason?: string;
  error?: string;
}

export const checkVerificationCodeRequestSchema = z.object({
  region: z.string(),
  phoneNumber: z.string(),
  code: z.string(),
});

export type CheckVerificationCodeRequest = z.infer<typeof checkVerificationCodeRequestSchema>;

export interface CheckVerificationCodeResponse {
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  userId: string;
  isNewAccount?: boolean;
}

export interface GetSupportedCountriesResponse {
  success: boolean;
  data: {
    countries: object;
  };
}
