import { z } from "zod";

/** [Source](../../../../../../../../backend/core/src/lib/models/VideoJob/VideoQuality.ts) */
export enum VideoQuality {
  Auto = "Auto",
  Low = "Low",
  Medium = "Medium",
  High = "High",
}

/** [Source](../../../../../../../../backend/core/src/lib/models/VideoJob/VideoQuality.ts) */
export const VideoQualitySchema = z.enum([
  VideoQuality.Auto,
  VideoQuality.Low,
  VideoQuality.Medium,
  VideoQuality.High,
]);

export type VideoExportTrimInterval = z.infer<typeof VideoExportTrimIntervalSchema>;

/** [Source](../../../../../../../../backend/core/src/lib/models/VideoJob/VideoExportTrimInterval.ts) */
export const VideoExportTrimIntervalSchema = z.object({
  startTime: z.number(),
  endTime: z.number(),
});

export type TimedAudioUrl = z.infer<typeof TimedAudioUrlSchema>;

/** [Source](../../../../../../../../backend/core/src/lib/models/VideoJob/TimedAudioUrl.ts) */
export const TimedAudioUrlSchema = z.object({
  startTime: z.number(),
  endTime: z.number(),
  downloadUrl: z.string(),
});

export type VideoExportAudioDubbing = z.infer<typeof VideoExportAudioDubbingSchema>;

/** [Source](../../../../../../../../backend/core/src/lib/models/VideoJob/VideoExportAudioDubbing.ts) */
export const VideoExportAudioDubbingSchema = z.object({
  backgroundAudioUri: z.string().optional(),
  phrases: TimedAudioUrlSchema.array(),
});

/** [Source](../../../../../../../../backend/core/src/lib/models/VideoJob/VideoExportJobConfig.ts) */
export const startOverlayJobRequestSchema = z.object({
  inputFileId: z.string(),
  overlayFileId: z.string().nullable().optional(),
  projectName: z.string().optional(),
  width: z.number().optional(),
  height: z.number().optional(),
  fps: z.number().optional(),
  reframe: z
    .object({
      dstWidth: z.number(),
      dstHeight: z.number(),
      scale: z.number(),
      cropCoordX: z.number(),
      cropCoordY: z.number(),
    })
    .optional(),
  videoQuality: VideoQualitySchema.optional(),
  trimVideoTimeInterval: VideoExportTrimIntervalSchema.array().optional(),
  audioDubbing: VideoExportAudioDubbingSchema.optional(),
  soundEffects: z
    .object({
      id: z.string(),
      startTime: z.number(),
    })
    .array()
    .optional(),
  appendOutro: z.boolean().optional(),
});

export type StartOverlayJobRequest = z.infer<typeof startOverlayJobRequestSchema>;

export const startOverlayJobResponseSchema = z.object({
  jobId: z.string(),
  errorMessage: z.string().nullable().default(null),
  progress: z.number().nullable().default(null),
  status: z.string(),
});

export type StartOverlayJobResponse = z.infer<typeof startOverlayJobResponseSchema>;

export const VideoOverlayJobStatusSchema = z.enum(["started", "progress", "error", "finished"]);

export type VideoOverlayJobStatus = z.infer<typeof VideoOverlayJobStatusSchema>;

export const getVideoOverlayJobResponseSchema = z.object({
  job: z.object({
    videoExportJobId: z.string(),
    status: VideoOverlayJobStatusSchema,
    inputFileId: z.string(),
    overlayFileId: z.string().nullable(),
    outputFileId: z.string(),
    outputDownloadUrl: z.string().nullable().optional(),
    outputDownloadSize: z.number().nullable().optional(),
    errorMessage: z.string().nullable().optional(),
    progress: z.number(),
  }),
});

export type GetVideoOverlayJobResponse = z.infer<typeof getVideoOverlayJobResponseSchema>;
