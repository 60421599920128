import { BackendServicesClient } from "~/context/BackendServicesContext";
import { getCanonicalFontName } from "~/theme/fonts/subtitles";

import { CaptionTemplateWithDates } from "../../hooks/useCaptionStyleTemplates";

import captionTemplates from "./CaptionStylePreset.data.json";
import {
  CaptionStylePresetEntry,
  CaptionTemplate,
  captionTemplateSchema,
} from "./CaptionStylePreset.dto";

const GET_STYLES_URL = "/style/v1/templates";

export const CAPTION_TEMPLATES = {
  Suzy: "128152FF-FE67-48D2-90D4-D8A79A3CE6AB",
  Impact: "C05E7E1F-01E0-4890-8BC3-4DE48E275DC6",
  Energy: "2EEEFFCD-F841-4BE4-94C6-9C1210C84203",
  Runway: "A0E4F2FD-5A47-4210-8A98-BFBE291696C9",
} as const;

function replacePresetFontFamily(preset: CaptionStylePresetEntry): CaptionStylePresetEntry {
  const fontName = getCanonicalFontName(preset.content.font.fontName);
  return {
    ...preset,
    content: {
      ...preset.content,
      font: {
        ...preset.content.font,
        fontName,
      },
    },
  };
}

export async function getCaptionStyleTemplates(
  client: BackendServicesClient
): Promise<CaptionTemplate[]> {
  const result = await client.get<{ data: unknown | { template: unknown | unknown[] } }>(
    GET_STYLES_URL
  );

  const templates =
    "templates" in result.data && Array.isArray(result.data.templates)
      ? (result.data.templates as CaptionTemplate[])
      : null;
  if (!templates) {
    console.warn("No templates returned from backend");
  }

  const remoteTemplates: CaptionTemplate[] = templates ? templates.flatMap(parseTemplate) : [];

  const defaultTemplates = captionTemplates
    .map((preset) => captionTemplateSchema.parse(preset))
    .map((preset) => {
      // Add remote metadata to local default templates
      const matchingRemotePreset = remoteTemplates.find(
        (remotePreset) => remotePreset.id === preset.id
      );
      if (!matchingRemotePreset) {
        return preset;
      }
      return {
        ...preset,
        style: {
          ...preset.style,
          numViews: matchingRemotePreset.style.numViews,
        },
        colors: {
          ...preset.colors,
          ...matchingRemotePreset.colors,
        },
      };
    });

  // Move the "Default" template to the end of the list
  const DEFAULT_TEMPLATE_ID = "default";
  const captionsDefaultTemplate = defaultTemplates.filter(
    (preset) => preset.id === DEFAULT_TEMPLATE_ID
  );

  const presetIdsToRemove = captionTemplates.map((preset) => preset.id);
  return [
    ...defaultTemplates.filter((preset) => preset.id !== DEFAULT_TEMPLATE_ID),
    ...remoteTemplates.filter((preset) => !presetIdsToRemove.includes(preset.id)),
    ...captionsDefaultTemplate,
  ].map((template) => ({
    ...template,
    style: replacePresetFontFamily(template.style),
  }));
}

function parseTemplate(style: unknown): CaptionTemplate | [] {
  const parsed = captionTemplateSchema.safeParse(style);
  if (parsed.success) {
    return parsed.data;
  } else {
    const issues = parsed.error.issues;
    const displayName =
      style && typeof style === "object" && "displayName" in style
        ? style["displayName"]
        : "Unknown";
    console.warn(`Failed to parse template "${displayName}"`, { style, issues });
    return [];
  }
}
