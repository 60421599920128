import { getPrimarySceneSegments } from "../../utils/genPrimarySceneSegments";

import { EffectsCommandGeneratorFunction } from "./types";

export const generateImageFontCommandsForScene: EffectsCommandGeneratorFunction = (scene) => {
  if (!scene.style.imageFont) {
    return null;
  }

  if (!scene.title) {
    return null;
  }

  const segments = getPrimarySceneSegments(scene);
  const { assetIds, position } = scene.style.imageFont;

  let positionFactor = 0;
  if (position === "top") {
    positionFactor = 0.07;
  } else if (position === "top-half") {
    positionFactor = 0.15;
  } else if (position === "bottom-half") {
    positionFactor = 0.6;
  }

  return {
    edl: {
      segments,
      wrapMode: "repeat",
    },
    renderEffect: {
      renderEffectType: "image-font",
      assetIds: assetIds,
      text: scene.title,
      positionFactor,
    },
  };
};
