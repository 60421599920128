import type { AiEditShot } from "@shared/generated/typescript/aiEdit/api/CreateAiEditVideoTemplate";

import { FaceData } from "~/modules/project/utils/faceData/face-data";

import { AIVideoMetadata } from "../../AIScenes.types";
import { Command } from "../../CommandList.types";
import { EDLSegment } from "../../EDL.types";

export function generateCaptionsCommands(
  captionTemplateId: string,
  shots: AiEditShot[],
  videoMetadata: AIVideoMetadata,
  faceData?: FaceData
) {
  const commands: Command[] = [];

  let mainTotal = 0;
  shots.forEach((shot, idx) => {
    // step 1: create edl for shot
    const sceneDuration = shot.endTime - shot.startTime;
    const segment: EDLSegment = [
      mainTotal,
      mainTotal + sceneDuration,
      shot.startTime,
      shot.endTime,
    ];
    mainTotal += sceneDuration;

    // step 2: calculate positionFactor
    const positionFactor = { x: 0.5, y: 0.5 };
    const captionType = shot.shotTemplate?.captions?.type.oneofKind;
    switch (captionType) {
      case "regular": {
        const layout = shot.shotTemplate?.captions?.type.regular.layout?.type.oneofKind;
        switch (layout) {
          case "bottomHalf":
            positionFactor.y = 3 / 10; // 30% from the bottom
            break;
          case "topHalf":
            positionFactor.y = 8 / 10; // 80% from the bottom
            break;
          case "avoidHead": {
            const sceneRect = faceData?.getSceneMaxRect(shot.startTime, shot.endTime, true);
            if (sceneRect) {
              const faceTop = sceneRect.y;
              const faceBottom = sceneRect.y + sceneRect.h;
              const spaceAbove = faceTop;
              const spaceBelow = videoMetadata.height - faceBottom;
              if (spaceBelow > spaceAbove) {
                positionFactor.y = 1 - (faceBottom + spaceBelow / 2) / videoMetadata.height;
              } else {
                positionFactor.y = 1 - spaceAbove / 2 / videoMetadata.height;
              }
            }
          }
        }
      }
    }

    // step 3: create command for shot
    const captionsCommand: Command = {
      edl: { segments: [segment] },
      renderEffect: {
        renderEffectType: "captions",
        assetId: `captions-shot-${idx}`,
        templateId: captionTemplateId,
        positionFactor,
      },
    };
    commands.push(captionsCommand);
  });

  return commands;
}
