import { HttpResponse, HttpResponseResolver, JsonBodyType } from "msw";

export function makeSequencedMockHandler(...responses: JsonBodyType[]): HttpResponseResolver {
  return async function* () {
    await new Promise((resolve) => setTimeout(resolve, 1000));

    while (responses.length > 1) {
      yield HttpResponse.json(responses.shift());
    }

    return HttpResponse.json(responses[responses.length - 1]);
  };
}

export function proxied(response: JsonBodyType) {
  return {
    data: response,
    success: true,
  };
}

export function makeWithCounter(creator: (count: number) => Promise<JsonBodyType>) {
  let count = 0;

  return async function () {
    const response = await creator(count++);

    return HttpResponse.json(response);
  };
}
