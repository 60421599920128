import { PseudoRandomGenerator } from "stable-pseudo-rng";

import {
  getCaptionAnimationParameters,
  getCaptionAnimationStage,
  getCaptionAnimationTimes,
} from "./animationUtils";
import {
  CaptionAnimationParameters,
  AnimationTimeInterval,
  AnimationState,
  AnimationStyle,
  AnimationTarget,
  AnimationTimes,
  CaptionAnimationAuxMetrics,
  CaptionAnimationModifiers,
} from "./animationUtils.types";
import {
  AnyCanvasRenderingContext2D,
  BoxDrawData,
  CaptionFrameLineInfo,
  CaptionFrameWordInfo,
  CaptionStyle,
  CaptionWordColors,
  PositionFactor,
  WordDrawData,
} from "./captionDrawing.types";
import { LineInfo, WordInfo } from "./captionEngine.types";
import { Line, Page, Word } from "./captions.types";
import {
  CapitalizationStyle,
  CaptionStylePreset,
  Color,
  Glow,
  LayoutAlignment,
  Point,
  Shadow,
  Stroke,
} from "./captionStyle.types";
import { CAPTION_PUNCTUATION_CHARACTERS } from "./constants/characters.constants";
import {
  applyShadowStyle,
  getColorStyle,
  getGlowStyle,
  getShadowStyle,
  getGradientStyle,
} from "./styleUtils";
import { drawText } from "./textDrawingUtils";
import { Polygon } from "./utils/polygon";
import { getLineInViewingOrder } from "./utils/textDirection";

/**
 * Minimum active duration for a word to be animated
 * @constant
 */

function randomCapitalization(seed: number, text: string): string {
  const random = new PseudoRandomGenerator(seed);
  return text
    .split("")
    .map((char) => (random.next() > 0.5 ? char.toUpperCase() : char.toLowerCase()))
    .join("");
}

export function capitalizePageWords(
  page: Page,
  style: CapitalizationStyle,
  hidePunctuation: boolean
): Page {
  function capitalize(text: string) {
    switch (style) {
      case CapitalizationStyle.capitalizationStyleLower:
        return text.toLowerCase();
      case CapitalizationStyle.capitalizationStyleUpper:
        return text.toUpperCase();
      case CapitalizationStyle.capitalizationStyleTitle:
        return text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase();
      case CapitalizationStyle.capitalizationStyleUnspecified:
      case CapitalizationStyle.capitalizationStyleNoChanges:
        return text;
      case CapitalizationStyle.capitalizationStyleRandom:
        return randomCapitalization(page.startTime * 1000, text);
    }
  }

  function trimPunctuation(text: string): string {
    let start = 0;
    let end = text.length;

    while (start < end && CAPTION_PUNCTUATION_CHARACTERS.includes(text[start])) {
      start = start + 1;
    }

    while (end > start && CAPTION_PUNCTUATION_CHARACTERS.includes(text[end - 1])) {
      end = end - 1;
    }

    return start > 0 || end < text.length ? text.substring(start, end) : text;
  }

  return {
    ...page,
    lines: page.lines.flatMap((line) => {
      const words = line.words.flatMap((word) => {
        let text = capitalize(word.text).trim();
        if (hidePunctuation) {
          text = trimPunctuation(text).trim();
        }
        return text.length
          ? [
              {
                ...word,
                text: text,
              },
            ]
          : [];
      });
      return words.length
        ? [
            {
              ...line,
              words,
            },
          ]
        : [];
    }),
  };
}

export async function loadCaptionFont(
  fontFamily: string,
  stylePreset: CaptionStylePreset
): Promise<void> {
  const fonts = fontFamily.split(",");
  fonts.splice(1, 1);
  const fontFamilyWithoutFirstFallback = fonts.join(",");

  const shorthand = `${stylePreset.font.fontSize}px ${fontFamilyWithoutFirstFallback}`;
  if (typeof window !== "undefined") {
    return Promise.all([
      document.fonts.load(shorthand),
      document.fonts.load("bold " + shorthand),
    ]).then();
  } else if ("fonts" in self) {
    const fonts = (self as unknown as { fonts: FontFaceSet }).fonts;
    return Promise.all([fonts.load(shorthand), fonts.load("bold " + shorthand)]).then();
  }
}

export function getUpdatedTextStyle(
  stroke: Stroke,
  shadow: Shadow,
  glow: Glow,
  style: CaptionStyle
) {
  const newStroke: Stroke = {
    ...stroke,
    width: stroke.width * style.sizeFactor,
  };
  const newShadow: Shadow = {
    ...shadow,
    radius: shadow.radius * style.sizeFactor,
    offset: {
      x: shadow.offset.x * style.sizeFactor,
      y: shadow.offset.y * style.sizeFactor,
    },
  };
  const newGlow: Glow = {
    ...glow,
    radius: glow.radius * style.sizeFactor,
    offset: {
      x: glow.offset.x * style.sizeFactor,
      y: glow.offset.y * style.sizeFactor,
    },
  };
  return { stroke: newStroke, shadow: newShadow, glow: newGlow };
}

function getBoxBoundaries(
  area: { width: number; height: number }
  // should we try to be smart using the overall box size?
) {
  const { width, height } = area;
  return {
    left: 0,
    right: width,
    bottom: height,
    top: 0,
  };
}

export function getPositionFactorFromStartingPoint(
  area: { width: number; height: number },
  position: Point
): PositionFactor {
  const { left, right, bottom, top } = getBoxBoundaries(area);
  const factorX = (position.x - left) / (right - left);
  const factorY = (position.y - bottom) / (top - bottom);

  return {
    x: factorX,
    y: factorY,
  };
}

export function getStartingPoint(
  area: { width: number; height: number },
  offset: Point,
  positionFactor: PositionFactor
): Point {
  const { left, right, bottom, top } = getBoxBoundaries(area);
  const defaultX = left + positionFactor.x * (right - left);
  const defaultY = bottom + positionFactor.y * (top - bottom);

  return {
    x: defaultX + offset.x,
    y: defaultY + offset.y,
  };
}

export function getWordColors(
  stylePreset: CaptionStylePreset,
  style: CaptionStyle,
  hasBackground: boolean
): CaptionWordColors {
  const textColor = style.textColor;
  // Only uses the picked color if the preset has a non-transparent background
  const activeWordBackgroundColor =
    style.activeWordBackground ?? stylePreset.activeWordBackground.color;
  const activeWordColor = style.activeColor ?? style.textColor;
  const keywordColor = style.emphasisColor ?? style.textColor;

  const boxBackgroundColor = {
    ...style.wordBackground,
    alpha: hasBackground ? style.wordBackground.alpha : 0.0,
  };

  const upcomingTextColor = stylePreset.upcomingWords.enabled
    ? stylePreset.upcomingWords.color
    : { red: 0, green: 0, blue: 0, alpha: 0 };

  return {
    textColor,
    activeWordBackgroundColor,
    activeWordColor,
    keywordColor,
    boxBackgroundColor,
    upcomingTextColor,
  };
}

function drawActiveWordBox(ctx: AnyCanvasRenderingContext2D, drawData: BoxDrawData) {
  applyShadowStyle(ctx, null);
  if (drawData.boxColor?.alpha) {
    const baseRect = {
      x: drawData.wordBox.x + drawData.boxOffset.x - drawData.boxOuterPadding.horizontal,
      y: drawData.wordBox.y + drawData.boxOffset.y - drawData.boxOuterPadding.vertical,
      width:
        drawData.wordBox.width + drawData.boxOffset.width + 2 * drawData.boxOuterPadding.horizontal,
      height:
        drawData.wordBox.height + drawData.boxOffset.height + 2 * drawData.boxOuterPadding.vertical,
    };
    const boxGrowthOffset = {
      x: (baseRect.width * (drawData.boxSizeFactor - 1)) / 2,
      y: (baseRect.height * (drawData.boxSizeFactor - 1)) / 2,
    };
    const roundRectWidth = baseRect.width * drawData.boxSizeFactor;
    const roundRectHeight = baseRect.height * drawData.boxSizeFactor;
    const roundRectX = baseRect.x - boxGrowthOffset.x;
    const roundRectY = baseRect.y - boxGrowthOffset.y;
    ctx.beginPath();
    ctx.fillStyle = drawData?.gradient
      ? getGradientStyle(ctx, drawData.gradient, {
          width: roundRectWidth,
          height: roundRectHeight,
          x: roundRectX,
          y: roundRectY,
        })
      : getColorStyle(drawData.boxColor);
    ctx.roundRect(roundRectX, roundRectY, roundRectWidth, roundRectHeight, drawData.cornerRadius);
    ctx.fill();
  }
}

function drawCaptionWord(
  ctx: AnyCanvasRenderingContext2D,
  wordData: WordDrawData,
  letterSpacing: number,
  textShadow: Shadow,
  textGlow: Glow | null,
  textStroke: Stroke
) {
  ctx.fillStyle = getColorStyle(wordData.textColor);
  ctx.strokeStyle = getColorStyle(textStroke.color);
  const shadowStyle = getShadowStyle(textShadow);
  const innerShadowStyle = getGlowStyle(textGlow, wordData.textColor);
  drawText(
    ctx,
    wordData.word,
    letterSpacing,
    wordData.wordBox.x + wordData.offset.x,
    wordData.wordBox.y + wordData.offset.y,
    shadowStyle,
    innerShadowStyle,
    textStroke.width
  );
}

function getPageAnimationStyle(stylePreset: CaptionStylePreset) {
  return stylePreset.animationTarget === AnimationTarget.animationTargetPage
    ? stylePreset.animationStyle
    : AnimationStyle.animationStyleImmediate;
}

function getLineAnimationStyle(stylePreset: CaptionStylePreset) {
  return stylePreset.animationTarget === AnimationTarget.animationTargetLine
    ? stylePreset.animationStyle
    : AnimationStyle.animationStyleImmediate;
}

function getWordAnimationStyle(stylePreset: CaptionStylePreset) {
  return stylePreset.animationTarget === AnimationTarget.animationTargetWord
    ? stylePreset.animationStyle
    : AnimationStyle.animationStyleImmediate;
}

function getLineTimes(line: Line): AnimationTimeInterval {
  const [startTime, endTime] = line.words.reduce(
    (result, word) => [Math.min(word.startTime, result[0]), Math.max(word.endTime, result[1])],
    [Infinity, -Infinity]
  );
  return {
    startTime,
    endTime,
  };
}

export function getAllPageAnimationTimes(
  page: Page,
  stylePreset: CaptionStylePreset
): AnimationTimes[] {
  return [
    //TODO: do pages have an exit animation?
    getCaptionAnimationTimes(page, getPageAnimationStyle(stylePreset), false),
    ...page.lines.flatMap((line) => {
      if (!line.words.length) {
        return [];
      }
      const lineTarget = getLineTimes(line);
      return [
        getCaptionAnimationTimes(lineTarget, getLineAnimationStyle(stylePreset), false),
        ...line.words.flatMap((word) => [
          getCaptionAnimationTimes(word, getWordAnimationStyle(stylePreset), false),
          getCaptionAnimationTimes(word, stylePreset.activeWordBackground.animationStyle, true),
        ]),
      ];
    }),
  ];
}

export function isWordActive(word: Word, stylePreset: CaptionStylePreset, timestamp: number) {
  const times = getCaptionAnimationTimes(
    word,
    stylePreset.activeWordBackground.animationStyle,
    true
  );
  return timestamp >= times.startAnimation.startTime && timestamp < times.endAnimation.endTime;
}

export function isLineActive(line: CaptionFrameLineInfo | LineInfo, timestamp: number) {
  return timestamp >= line.startTime && timestamp < line.endTime;
}

export function getPageAnimationStage(
  page: Page,
  stylePreset: CaptionStylePreset,
  timestamp: number
): AnimationState {
  return getCaptionAnimationStage(page, getPageAnimationStyle(stylePreset), false, timestamp);
}

export function getLineAnimationStage(
  line: Line | CaptionFrameLineInfo,
  stylePreset: CaptionStylePreset,
  timestamp: number
): AnimationState {
  if (line.words.length === 0) {
    return {
      factor: 1,
      stage: "none",
    };
  }
  const lineTarget = "startTime" in line ? line : getLineTimes(line);
  return getCaptionAnimationStage(lineTarget, getLineAnimationStyle(stylePreset), false, timestamp);
}

export function getWordAnimationStage(
  word: Word,
  stylePreset: CaptionStylePreset,
  timestamp: number
): AnimationState {
  return getCaptionAnimationStage(word, getWordAnimationStyle(stylePreset), false, timestamp);
}

export function getActiveWordBackgroundAnimationStage(
  word: AnimationTimeInterval,
  stylePreset: CaptionStylePreset,
  timestamp: number
) {
  return getCaptionAnimationStage(
    word,
    stylePreset.activeWordBackground.animationStyle,
    true,
    timestamp
  );
}

/**
 * Get the animation stage for the background of a line
 * @param line - The line to get the background animation stage for
 * @param stylePreset - The style preset to use
 * @param timestamp - The current timestamp
 * @returns The animation stage for the background of the line
 */
export function getLineBackgroundAnimationStage(
  line: Line | CaptionFrameLineInfo,
  stylePreset: CaptionStylePreset,
  timestamp: number
): AnimationState {
  if (line.words.length === 0) {
    // Sanity check, should never really happen
    return {
      factor: 1,
      stage: "none",
    };
  }
  let target: AnimationTimeInterval;
  switch (stylePreset.animationTarget) {
    case AnimationTarget.animationTargetLine:
      target = "startTime" in line ? line : getLineTimes(line);
      break;
    case AnimationTarget.animationTargetWord:
      target = line.words[0];
      break;
    default:
      return {
        factor: 1,
        stage: "none",
      };
  }
  return getCaptionAnimationStage(target, stylePreset.animationStyle, false, timestamp);
}

export function getPageAnimationParameters(
  animationState: AnimationState,
  stylePreset: CaptionStylePreset,
  auxMetrics: CaptionAnimationAuxMetrics
) {
  return getCaptionAnimationParameters(
    animationState,
    getPageAnimationStyle(stylePreset),
    auxMetrics
  );
}

export function getLineAnimationParameters(
  animationState: AnimationState,
  stylePreset: CaptionStylePreset,
  auxMetrics: CaptionAnimationAuxMetrics
) {
  return getCaptionAnimationParameters(
    animationState,
    getLineAnimationStyle(stylePreset),
    auxMetrics
  );
}

export function getWordAnimationParameters(
  animationState: AnimationState,
  stylePreset: CaptionStylePreset,
  auxMetrics: CaptionAnimationAuxMetrics
) {
  return getCaptionAnimationParameters(
    animationState,
    getWordAnimationStyle(stylePreset),
    auxMetrics
  );
}

export function applyActiveAnimationParameters(
  animationParameters: CaptionAnimationParameters,
  drawData: BoxDrawData
) {
  if (drawData.boxColor) {
    drawData.boxColor = {
      ...drawData.boxColor,
      alpha: drawData.boxColor.alpha * animationParameters.alpha,
    };
  }
  drawData.boxSizeFactor = animationParameters.outsetFactor;
  drawData.boxOffset = animationParameters.boxOffset;
}

export function drawActiveBoxes(
  ctx: AnyCanvasRenderingContext2D,
  words: BoxDrawData[],
  fontFamily: string
) {
  for (const drawData of words) {
    ctx.font = `${drawData.fontSize}px ${fontFamily}`;
    drawActiveWordBox(ctx, drawData);
  }
}

export function drawWords(
  ctx: AnyCanvasRenderingContext2D,
  words: WordDrawData[],
  fontFamily: string,
  letterSpacing: number,
  shadow: Shadow,
  stroke: Stroke,
  glow: Glow
) {
  /*for (const drawData of words) {
    ctx.font = `${drawData.fontSize}px ${fontFamily}`;
    drawActiveWordBox(ctx, drawData, cornerRadius);
  }*/
  for (const drawData of words) {
    ctx.font = `${drawData.bold ? "bold " : ""}${drawData.fontSize}px ${fontFamily}`;
    drawCaptionWord(
      ctx,
      {
        ...drawData,
        textColor: { ...drawData.textColor, alpha: drawData.textColor.alpha * drawData.opacity },
      },
      letterSpacing,
      shadow,
      drawData.enableGlow ? glow : null,
      {
        ...stroke,
        color: {
          ...stroke.color,
          alpha: stroke.color.alpha * drawData.opacity,
        },
      }
    );
  }
}

export function shouldSkipLine(
  line: CaptionFrameLineInfo | LineInfo,
  stylePreset: CaptionStylePreset,
  timestamp: number
) {
  return (
    [AnimationTarget.animationTargetLine, AnimationTarget.animationTargetWord].includes(
      stylePreset.animationTarget
    ) && line.startTime > timestamp
  );
}

export function shouldShowFocusedLineAsActive(stylePreset: CaptionStylePreset) {
  return [AnimationTarget.animationTargetLine, AnimationTarget.animationTargetPage].includes(
    stylePreset.animationTarget
  );
}

export function shouldSkipWord(
  word: CaptionFrameWordInfo,
  stylePreset: CaptionStylePreset,
  timestamp: number
) {
  return (
    stylePreset.animationTarget == AnimationTarget.animationTargetWord && word.startTime > timestamp
  );
}

export function getStartOffsetFromMiddle(
  lineWidth: number,
  stylePreset: CaptionStylePreset,
  innerBoxWidth: number,
  seed: number,
  lineHorizontalPadding: number
) {
  const left = -(innerBoxWidth / 2) + lineHorizontalPadding;
  const right = innerBoxWidth / 2 - lineWidth - lineHorizontalPadding;
  const center = -(lineWidth / 2);
  const random = left + new PseudoRandomGenerator(seed).next() * (right - left);

  switch (stylePreset.layoutAlignment) {
    case LayoutAlignment.layoutAlignmentUnspecified:
    case LayoutAlignment.layoutAlignmentCenter:
      return center;
    case LayoutAlignment.layoutAlignmentNatural:
    case LayoutAlignment.layoutAlignmentLeft:
      return left;
    case LayoutAlignment.layoutAlignmentRight:
      return right;
    case LayoutAlignment.layoutAlignmentRandom: {
      return random;
    }
  }
}

export function getWordColor(
  word: Word,
  stylePreset: CaptionStylePreset,
  isFocused: boolean,
  isUpcoming: boolean,
  wordColors: CaptionWordColors
): Color {
  if (isUpcoming) {
    return wordColors.upcomingTextColor;
  } else if (word.emphasize) {
    return wordColors.keywordColor;
  } else if (isFocused && stylePreset.activeColorEnabled) {
    return wordColors.activeWordColor;
  } else {
    return wordColors.textColor;
  }
}

export function getBackgroundStartOffsetFromMiddle(
  stylePreset: CaptionStylePreset,
  outerBoxStartX: number,
  backgroundPadding: PositionFactor,
  outerBoxWidth: number,
  maxBoxSizeOuterBoxWidth: number
) {
  const usePadding = stylePreset.isMultilineBox && stylePreset.colorApplicationBackgroundEnabled;
  switch (stylePreset.layoutAlignment) {
    case LayoutAlignment.layoutAlignmentNatural:
    case LayoutAlignment.layoutAlignmentLeft:
      return {
        backgroundX: -(maxBoxSizeOuterBoxWidth / 2),
        wordXOffsetWithBackground: usePadding ? backgroundPadding.x : 0,
      };
    case LayoutAlignment.layoutAlignmentRight:
      return {
        backgroundX: maxBoxSizeOuterBoxWidth / 2 - outerBoxWidth,
        wordXOffsetWithBackground: usePadding ? -backgroundPadding.x : 0,
      };
    default:
      return {
        backgroundX: outerBoxStartX,
        wordXOffsetWithBackground: 0,
      };
  }
}

export function returnOrderedPage(page: Page): Page {
  return {
    ...page,
    lines: page.lines.map(getLineInViewingOrder),
  };
}

export function shouldApplyLineAnimationToBox(page: Page, stylePreset: CaptionStylePreset) {
  return (
    stylePreset.animationTarget === AnimationTarget.animationTargetLine &&
    (!stylePreset.isMultilineBox || page.lines.length === 1)
  );
}

export function drawOuterBox(
  ctx: AnyCanvasRenderingContext2D,
  stylePreset: CaptionStylePreset,
  style: CaptionStyle,
  wordColors: CaptionWordColors,
  backgroundX: number,
  outerBoxStartY: number,
  outerBoxWidth: number,
  outerBoxHeight: number,
  animationModifiers: CaptionAnimationModifiers | null
) {
  if (wordColors.boxBackgroundColor.alpha) {
    const animSizeFactor = animationModifiers?.sizeFactor ?? 1;
    const offsetX =
      (animationModifiers?.offset.x ?? 0) + (outerBoxWidth * (animSizeFactor - 1)) / 2;
    const offsetY =
      (animationModifiers?.offset.y ?? 0) + (outerBoxHeight * (animSizeFactor - 1)) / 2;
    const backgroundColor = {
      ...wordColors.boxBackgroundColor,
      alpha: wordColors.boxBackgroundColor.alpha * (animationModifiers?.opacity ?? 1),
    };
    ctx.beginPath();
    ctx.fillStyle = getColorStyle(backgroundColor);
    applyShadowStyle(ctx, stylePreset.backgroundShadow);
    ctx.roundRect(
      backgroundX + offsetX,
      outerBoxStartY + offsetY,
      outerBoxWidth * animSizeFactor,
      outerBoxHeight * animSizeFactor,
      stylePreset.backgroundStyle.cornerRadius * style.sizeFactor * animSizeFactor
    );
    ctx.fill();
    applyShadowStyle(ctx, null);
  }
}

/**
 * Take words (which may be too long to display on the screen),
 * and wrap them into multiple lines based on a fixed max width.
 */
export function wrapLines(
  originalWords: WordInfo[],
  width: number,
  wordPaddingHorizontalPixels: number
): LineInfo[] {
  const wrappedLines: LineInfo[] = [];
  const horizPadding = wordPaddingHorizontalPixels;
  const [startTime, endTime] = originalWords.reduce(
    (result, word) => [Math.min(word.startTime, result[0]), Math.max(word.endTime, result[1])],
    [Infinity, -Infinity]
  );
  let currentLineWords: WordInfo[] = [];
  for (const word of originalWords) {
    // Calculate the new width of the line after the next word
    const newCurrentLineWidth = calcActualLineWidth([...currentLineWords, word], horizPadding);

    // Non-wrapping case:
    if (newCurrentLineWidth < width || currentLineWords.length === 0) {
      currentLineWords.push(word);
      continue;
    }

    // Wrapping case:
    wrappedLines.push({
      words: currentLineWords,
      width: calcActualLineWidth(currentLineWords, horizPadding),
      activeWidth: calcActiveLineWidth(currentLineWords, horizPadding),
      startTime,
      endTime,
      fontFactor: getLineFontFactor(currentLineWords),
    });
    currentLineWords = [word];
  }

  // Push the last line with any remaining un-wrapped words
  if (currentLineWords.length > 0) {
    wrappedLines.push({
      words: currentLineWords,
      width: calcActualLineWidth(currentLineWords, horizPadding),
      activeWidth: calcActiveLineWidth(currentLineWords, horizPadding),
      startTime,
      endTime,
      fontFactor: getLineFontFactor(currentLineWords),
    });
  }

  return wrappedLines.sort((a, b) => a.words[0].startTime - b.words[0].startTime);
}

export function calcActualLineWidth(words: WordInfo[], horizPadding: number) {
  return words.reduce((acc, item) => acc + item.width + 2 * (horizPadding * item.fontFactor), 0);
}

export function calcActiveLineWidth(words: WordInfo[], horizPadding: number) {
  return words.reduce(
    (acc, item) => acc + item.activeWidth + 2 * (horizPadding * item.fontFactor),
    0
  );
}

export function getLineFontFactor(words: WordInfo[]) {
  return Math.max(...words.map((word) => word.fontFactor));
}

export function calculateFontFactor(lineArea: number, frameArea: number, fontFactor: number) {
  if (lineArea === 0) {
    return NaN;
  }
  return fontFactor * Math.sqrt(frameArea / lineArea);
}

export function scaleWords(
  words: WordInfo[],
  fontFactorIncrease: number,
  measureText: (wordInfo: WordInfo) => TextMetrics
) {
  const currentLineWordsScaled = words.map((word) => ({
    ...word,
    fontFactor: word.fontFactor * fontFactorIncrease,
  }));
  currentLineWordsScaled.forEach((item) => {
    const metrics = measureText(item);
    item.width = metrics.width;
    item.activeWidth = metrics.width;
  });

  return currentLineWordsScaled;
}

export function calculateTextBoxDimensions(lines: LineInfo[], baseLineHeight: number) {
  const [boxWidth, boxHeight] = lines.reduce(
    ([width, height], line) => [
      Math.max(line.width, line.activeWidth, width),
      height + line.fontFactor * baseLineHeight,
    ],
    [-Infinity, 0]
  );

  return [boxWidth, boxHeight];
}

/**
 * Calculates word layout within a given polygon, if one exists.
 * Assumptions: polygon is simple, convex, regular, and centered at the center of the video frame at (0, 0).
 *
 * @param words - Array of words to layout.
 * @param baseLineHeight - The baseline height for text font.
 * @param horizPadding  - The horizontal padding to apply between words in pixels.
 * @param polygon - The polygon to layout the words within.
 * @returns An array of line information if the words can be laid out within the polygon, otherwise `undefined`.
 */
function fillPolygon(
  words: WordInfo[],
  baseLineHeight: number,
  horizPadding: number,
  polygon: Polygon
) {
  const [startTime, endTime] = words.reduce(
    (result, word) => [Math.min(word.startTime, result[0]), Math.max(word.endTime, result[1])],
    [Infinity, -Infinity]
  );

  const polyContainingRect = polygon.containingRect();

  const wrappedLines: LineInfo[] = [];
  let currentLineWords: WordInfo[] = [];
  let currentLineY = polyContainingRect.y + polyContainingRect.height;
  let currentLineX = undefined;

  for (const word of words) {
    let wordX;
    const wordWidthWithPadding = word.width + 2 * horizPadding * word.fontFactor;

    if (
      (wordX = polygon.firstXValueContaining({
        y: currentLineY,
        pastX: currentLineX,
        size: {
          width: wordWidthWithPadding,
          height: word.fontFactor * baseLineHeight,
        },
      }))
    ) {
      // continue line case
      currentLineWords.push(word);
      currentLineX = wordX + wordWidthWithPadding;
    } else {
      // new line case
      let nextY = currentLineY;

      // 1. push previous line if exists
      if (currentLineWords.length > 0) {
        wrappedLines.push({
          words: currentLineWords,
          startTime,
          endTime,
          fontFactor: getLineFontFactor(currentLineWords),
          width: calcActualLineWidth(currentLineWords, horizPadding),
          activeWidth: calcActiveLineWidth(currentLineWords, horizPadding),
        });
        nextY -= baseLineHeight * getLineFontFactor(currentLineWords);
      }

      while (nextY > polyContainingRect.y) {
        wordX = polygon.firstXValueContaining({
          y: nextY,
          size: {
            width: wordWidthWithPadding,
            height: word.fontFactor * baseLineHeight,
          },
        });
        if (wordX) {
          break;
        }
        nextY--; // search down polygon for a place to put the word
      }
      if (!wordX) {
        return;
      }
      // 2. create new line
      currentLineWords = [word];
      currentLineY = nextY;
      currentLineX = wordX + wordWidthWithPadding;
    }
  }

  // Push the last line with any remaining un-wrapped words
  if (currentLineWords.length > 0) {
    wrappedLines.push({
      words: currentLineWords,
      width: calcActualLineWidth(currentLineWords, horizPadding),
      activeWidth: calcActiveLineWidth(currentLineWords, horizPadding),
      startTime,
      endTime,
      fontFactor: getLineFontFactor(currentLineWords),
    });
  }

  return wrappedLines;
}

/**
 * Calculates a dynamic captions layout within the video frame, scaling the text to fit optimally within the frame dimensions.
 * If the line should not be dynmically laid out, or no fitting layout is found, the function returns `undefined`.
 *
 * @param {WordInfo[]} currentLineWords - Array of word information objects for the current line.
 * @param {number} currentLineWidth - The width of the current line of text.
 * @param {number} currentLineHeight - The height of the current line of text.
 * @param {number} maxWidth - The width of the bounding box within which the text needs to fit.
 * @param {number} maxHeight - The height of the bounding box within which the text needs to fit.
 * @param {function(WordInfo): TextMetrics} measureText - Function to measure the text metrics of a word.
 * @param {number} baseLineHeight - The baseline height for text font.
 * @param {number} wordPaddingHorizontalPx - The horizontal padding to apply between words in pixels.
 * @returns {LineInfo[] | undefined} - Returns an array of line information if the line should be dynamically laid out and a fitting layout is found, otherwise `undefined`.
 */
export function getDynamicLayout(
  currentLineWords: WordInfo[],
  currentLineWidth: number,
  currentLineHeight: number,
  maxWidth: number,
  maxHeight: number,
  measureText: (wordInfo: WordInfo) => TextMetrics,
  baseLineHeight: number,
  wordPaddingHorizontalPx: number,
  polygon?: Polygon
): LineInfo[] | undefined {
  if (currentLineWords[0].dynamicPlacement?.type === "start") {
    const currentLineArea = currentLineWidth * currentLineHeight;
    const maxArea = polygon ? polygon?.area() : maxWidth * maxHeight;

    const fontFactors = [1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2];
    let dynamicLayoutResult: [score: number, lines: LineInfo[]] | undefined = undefined;
    let dynamicLayoutResultIdx: number | undefined = undefined;

    for (let i = 0; i < fontFactors.length; i++) {
      if (typeof dynamicLayoutResultIdx === "number" && dynamicLayoutResultIdx + 1 < i) {
        // only allow for going 1 past the result idx
        break;
      }
      // 1. calculate font factor i
      const fontFactorIncrease = calculateFontFactor(currentLineArea, maxArea, fontFactors[i]);
      // TODO: ensure that font factor does not cause font size to go below some minimum

      // 2. apply font factor to all words
      const currentLineWordsScaled = scaleWords(currentLineWords, fontFactorIncrease, measureText);

      // 3. try wrapping lines with font factor
      let wrappedLines: LineInfo[] | undefined;
      if (polygon) {
        wrappedLines = fillPolygon(
          currentLineWordsScaled,
          baseLineHeight,
          wordPaddingHorizontalPx,
          polygon
        );
      } else {
        wrappedLines = wrapLines(currentLineWordsScaled, maxWidth, wordPaddingHorizontalPx);
      }

      if (!wrappedLines) {
        continue;
      }

      // 4. calculate dimensions of wrapped line layout
      const [boxWidth, boxHeight] = calculateTextBoxDimensions(wrappedLines, baseLineHeight);

      if (boxWidth <= maxWidth && boxHeight <= maxHeight) {
        // if wrapped word layout fits within video frame, calculate percentage filled score
        const wrappedLinesArea = polygon
          ? wrappedLines.reduce((acc, line) => acc + line.width * line.fontFactor, 0)
          : boxWidth * boxHeight;
        const score = wrappedLinesArea / maxArea;
        if (!dynamicLayoutResult) {
          dynamicLayoutResult = [score, wrappedLines];
          dynamicLayoutResultIdx = i;
        } else if (dynamicLayoutResult && score > dynamicLayoutResult[0]) {
          // if score is greater than previous score, update result to be this layout
          dynamicLayoutResult = [score, wrappedLines];
          dynamicLayoutResultIdx = i;
        }
      }
    }

    if (dynamicLayoutResult) {
      return dynamicLayoutResult[1];
    }
  }
  return;
}

export function getPageStylePresetOverrides(page: Page) {
  const stylePresetOverrides: Partial<CaptionStylePreset> = {};

  if (page.dynamicPlacement) {
    stylePresetOverrides.animationTarget = AnimationTarget.animationTargetWord;
    stylePresetOverrides.movementAutoMotion = {
      enabled: false,
      speed: 0,
      offset: { x: 0, y: 0 },
    };
  }

  return stylePresetOverrides;
}
