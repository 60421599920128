import { GenericAbortSignal } from "axios";

export function mergeAbortSignals(
  ...signals: (AbortSignal | GenericAbortSignal | undefined)[]
): AbortSignal {
  // Filter out undefined signals
  const definedSignals = signals.filter((signal): signal is AbortSignal => !!signal);

  if (definedSignals.length === 0) {
    // if no signals, return a new abort signal that will be empty/never aborted
    return new AbortController().signal;
  }

  // If any signal is already aborted, return it
  const abortedSignal = definedSignals.find((signal) => signal.aborted);
  if (abortedSignal) {
    return abortedSignal;
  }

  // If only one signal, return it
  if (definedSignals.length === 1) {
    return definedSignals[0];
  }

  // Create a new AbortController to merge signals
  const controller = new AbortController();

  // Listen to all signals and abort the controller if any of them abort
  definedSignals.forEach((signal) => {
    signal.addEventListener("abort", () => controller.abort(), { once: true });
  });

  return controller.signal;
}

// roughly copied from: https://github.com/tjmehta/race-abort/blob/master/src/index.ts

// note: this is not a real error for performance reasons
// if abort errors occur frequently, creating error stacks
// can slow down your application.
export class AbortError {
  name = "AbortError";
  message = "aborted";
  stack = "AbortError: aborted";
}
Object.setPrototypeOf(AbortError.prototype, Error.prototype);

/**
 * Races an abort signal with a promise. If the signal is aborted, the promise is rejected with an AbortError.
 * This is useful if you want to cancel a promise if the abort signal is aborted.
 */
export async function raceAbort<T>(signal: AbortSignal, value: Promise<T>): Promise<T> {
  let handleAbort: () => void;

  try {
    return await new Promise((resolve, reject) => {
      handleAbort = () => reject(new AbortError());
      if (signal.aborted) {
        // handle already aborted signal
        if (value?.catch) {
          value.catch(() => {});
        }

        return void handleAbort();
      }

      signal.addEventListener("abort", handleAbort);

      value.then(resolve, reject);
    });
  } finally {
    signal.removeEventListener("abort", handleAbort!);
  }
}

// if the signal gets triggered from inside Axios, it throws a CanceledError
// instead of an AbortError
export function isAbortOrCanceledError(error: unknown) {
  if (
    typeof error === "object" &&
    error !== null &&
    "name" in error &&
    (error.name === "AbortError" || error.name === "CanceledError")
  ) {
    return true;
  }

  return false;
}
