import { z } from "zod";

export const colorDecimalSchema = z.object({
  red: z.number().default(0),
  green: z.number().default(0),
  blue: z.number().default(0),
  alpha: z.number().default(0),
});

export type ColorDecimalType = z.infer<typeof colorDecimalSchema>;

export const colorCssSchema = z.object({
  red: z.number().int().min(0).max(255).default(0),
  green: z.number().int().min(0).max(255).default(0),
  blue: z.number().int().min(0).max(255).default(0),
  alpha: z.number().int().min(0).max(255).default(0).optional(),
});

export type ColorCssType = z.infer<typeof colorCssSchema>;
