import type { CaptionSettings } from "~/database/database.types";

import { ProjectEntityBag } from "../../types";

export async function migrateTo20241205(entities: ProjectEntityBag): Promise<ProjectEntityBag> {
  const captionSettings = entities.captionSettings;
  if (captionSettings && hasKeywordSettings(captionSettings)) {
    const emphasize = captionSettings.keywordSettings?.emphasizeKeywords;
    const supersize = captionSettings.keywordSettings?.supersizeKeywords;

    let aiEnabled = false;
    if (emphasize || supersize) {
      aiEnabled = true;
    }

    captionSettings.emphasisSettings = {
      aiEnabled,
      keywords: { emphasize, supersize },
      pictoralWords: {},
      nouns: {},
    };

    delete captionSettings.keywordSettings;
  }

  return { ...entities, captionSettings };
}

function hasKeywordSettings(
  captionSettings: CaptionSettings
): captionSettings is CaptionSettings & {
  keywordSettings?: { emphasizeKeywords: boolean; supersizeKeywords: boolean };
} {
  return "keywordSettings" in captionSettings;
}
