import { useMemo } from "react";
import { useDebouncedCallback } from "use-debounce";

import { Analytics } from "~/utils/analytics/track";

import { useOnChangeEffect, useOnMountEffect, useOnceEffect } from "./helpers";

export const useAnalytics = () => {
  return useMemo(() => {
    return {
      track: Analytics.track,
      trackConversion: Analytics.trackConversion,
    };
  }, []);
};

type UseTrackEventProperties<T = void, R = Record<string, unknown>> = R | ((value: T) => R);

export const useTrackEventOnMount = (event: string, properties?: UseTrackEventProperties) => {
  useOnMountEffect(() => {
    const props = typeof properties === "function" ? properties() : properties;

    Analytics.track(event, props);
  });
};

/**
 - Use this to track an event with regards to value changes.
 - You can return `null` from the `event` function and no event will be logged.
 */
export const useTrackEventOnChange = <T>(
  value: T,
  event: string | ((value: T) => string | null),
  properties?: UseTrackEventProperties<T>
) => {
  useOnChangeEffect(value, (value) => {
    const props = typeof properties === "function" ? properties(value) : properties;

    const eventString = typeof event === "function" ? event(value) : event;

    if (eventString) {
      Analytics.track(eventString, props);
    }
  });
};

// Use this to track an event when the value changes to true
export const useTrackEventOnTrue = (
  value: boolean,
  event: string,
  properties?: UseTrackEventProperties<boolean>
) => {
  useTrackEventOnChange(value, (isTrue) => (isTrue ? event : null), properties);
};

/** Debounces the `track` function for usage with high-frequency user input */
export function useTrackDebounced(delayMs = 500) {
  const { track } = useAnalytics();
  const trackDebounced = useDebouncedCallback(track, delayMs);
  return trackDebounced;
}

export function useTrackEventOnce(
  condition: boolean | (() => boolean),
  event:
    | [event: string, properties?: Record<string, unknown>]
    | (() => [event: string, properties?: Record<string, unknown>])
) {
  useOnceEffect(condition, () => {
    if (typeof event !== "function") {
      Analytics.track(...event);
    } else {
      Analytics.track(...event());
    }
  });
}
