import { useEffect, useState, ClipboardEvent, ChangeEvent, KeyboardEvent, useRef } from "react";

import * as S from "./CodeInput.styles";
import { CodeInputProps } from "./CodeInput.types";

export const CodeInput = ({ size, setCode, isError }: CodeInputProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [segments, setSegments] = useState<Array<string>>([]);

  useEffect(() => {
    setSegments(Array(size).fill(""));
  }, [size]);

  useEffect(() => {
    setCode(segments.join(""));
  }, [segments]);

  const focusInput = (index: number) => {
    const inputElement = containerRef.current?.children[index] as HTMLInputElement;
    inputElement?.focus();
  };

  const handleOnPaste = (event?: ClipboardEvent<HTMLInputElement> | undefined) => {
    const pastedText = event?.clipboardData.getData("text/plain");
    if (pastedText) {
      const pastedSegments = pastedText.split("").splice(0, size);

      const updatedSegments =
        pastedSegments.length === segments.length
          ? pastedSegments
          : [...pastedSegments, ...segments.slice(pastedSegments.length, segments.length)];

      setSegments(updatedSegments);

      const indexOfEmptyValue = updatedSegments.findIndex((value) => value === "");

      focusInput(indexOfEmptyValue !== -1 ? indexOfEmptyValue : pastedSegments.length - 1);
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedSegments = [...segments];
    const { value } = event.target;
    const { inputType } = event.nativeEvent as InputEvent;

    if (inputType === "insertFromPaste") {
      return;
    }
    updatedSegments[index] = value;

    setSegments(updatedSegments);

    if (value && index < segments.length - 1) {
      focusInput(index + 1);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>, index: number) => {
    const { key } = event;

    if (key === "Backspace" && index > 0 && !segments[index]) {
      focusInput(index - 1);
    }
  };

  return (
    <S.Container ref={containerRef}>
      {segments.map((value, index) => (
        <S.Input
          key={index}
          maxLength={1}
          onPaste={handleOnPaste}
          value={value ?? ""}
          onChange={(event) => handleOnChange(event, index)}
          onKeyDown={(event) => handleKeyDown(event, index)}
          isError={isError}
        />
      ))}
    </S.Container>
  );
};
