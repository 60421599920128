import glBackgroundFrag from "./gl-background-filter.frag";
import glTransformVert from "./gl-transform.vert";
import {
  applyPixelMapping,
  createEmptyTexture,
  createWebGLArrayBuffer,
  initShaderProgram,
  setVertexAttributeBuffer,
} from "./webgl";
import { PixelMapping } from "./webgl.types";

const defaultPixelMap: PixelMapping = {
  from: { left: 0, right: 1, top: 0, bottom: 1 },
  to: { left: 0, right: 1, top: 0, bottom: 1 },
};

export function initBackgroundRemover(gl: WebGLRenderingContext) {
  const shaderProgram = initShaderProgram(gl, glTransformVert, glBackgroundFrag);

  const programInfo = {
    program: shaderProgram,
    attribLocations: {
      vertexPosition: gl.getAttribLocation(shaderProgram, "aVertexPosition"),
      textureCoord: gl.getAttribLocation(shaderProgram, "aTextureCoord"),
    },
    uniformLocations: {
      transformMatrix: gl.getUniformLocation(shaderProgram, "uTransformMatrix"),
      backgroundSampler: gl.getUniformLocation(shaderProgram, "backgroundSampler"),
      maskSampler: gl.getUniformLocation(shaderProgram, "maskSampler"),
      videoSampler: gl.getUniformLocation(shaderProgram, "videoSampler"),
      imageSampler: gl.getUniformLocation(shaderProgram, "imageSampler"),
      texelSize: gl.getUniformLocation(shaderProgram, "texelSize"),
      stroke: gl.getUniformLocation(shaderProgram, "stroke"),
      canvasSize: gl.getUniformLocation(shaderProgram, "uCanvasSize"),
      backgroundPixelMap: {
        from: {
          bottomRight: gl.getUniformLocation(shaderProgram, `backgroundPixelMap.from.bottomRight`),
          topLeft: gl.getUniformLocation(shaderProgram, `backgroundPixelMap.from.topLeft`),
        },
        to: {
          bottomRight: gl.getUniformLocation(shaderProgram, `backgroundPixelMap.to.bottomRight`),
          topLeft: gl.getUniformLocation(shaderProgram, `backgroundPixelMap.to.topLeft`),
        },
      },
      foregroundPixelMap: {
        from: {
          bottomRight: gl.getUniformLocation(shaderProgram, `foregroundPixelMap.from.bottomRight`),
          topLeft: gl.getUniformLocation(shaderProgram, `foregroundPixelMap.from.topLeft`),
        },
        to: {
          bottomRight: gl.getUniformLocation(shaderProgram, `foregroundPixelMap.to.bottomRight`),
          topLeft: gl.getUniformLocation(shaderProgram, `foregroundPixelMap.to.topLeft`),
        },
        rotation: gl.getUniformLocation(shaderProgram, `foregroundPixelMap.rotation`),
      },
    },
  };

  const buffers = {
    positionBuffer: createWebGLArrayBuffer(gl, [-1.0, -1.0, 1.0, -1.0, 1.0, 1.0, -1.0, 1.0]),
    textureCoordBuffer: createWebGLArrayBuffer(gl, [0.0, 1.0, 1.0, 1.0, 1.0, 0.0, 0.0, 0.0]),
    indicesBuffer: createWebGLArrayBuffer(gl, [0, 1, 2, 0, 2, 3], "element_array_buffer"),
  };

  const textures = {
    backgroundTexture: createEmptyTexture(gl, [0, 0, 0, 0]),
    videoTexture: createEmptyTexture(gl, [0, 0, 0, 0]),
    maskTexture: createEmptyTexture(gl, [0, 0, 0, 0]),
    imageTexture: createEmptyTexture(gl, [0, 0, 0, 0]),
  };

  return {
    programInfo,
    buffers,
    textures,
  };
}

export type BackgroundPainterProgramData = ReturnType<typeof initBackgroundRemover>;

export function drawBackgroundEffect(
  gl: WebGLRenderingContext,
  programData: BackgroundPainterProgramData,
  zoomedPixelMaps: {
    background: PixelMapping | null;
    foreground: PixelMapping | null;
  } | null,
  stroke: boolean = false
) {
  if (!programData) {
    console.error("Missing necessary components for rendering.");
    return;
  }
  const { textures, buffers, programInfo } = programData;

  gl.blendEquation(gl.FUNC_ADD);
  gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
  gl.clearColor(0, 0, 0, 0);

  const canvasWidth = gl.canvas.width;
  const canvasHeight = gl.canvas.height;

  setVertexAttributeBuffer(
    gl,
    buffers.positionBuffer,
    programInfo.attribLocations.vertexPosition,
    2
  );
  setVertexAttributeBuffer(
    gl,
    buffers.textureCoordBuffer,
    programInfo.attribLocations.textureCoord,
    2
  );

  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffers.indicesBuffer);

  gl.useProgram(programInfo.program);

  const transform = [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1];
  gl.uniformMatrix4fv(programInfo.uniformLocations.transformMatrix, false, transform);
  gl.uniform2fv(programInfo.uniformLocations.canvasSize, [canvasWidth, canvasHeight]);

  const backgroundZoomedMap = zoomedPixelMaps?.background ?? defaultPixelMap;
  const foregroundZoomedMap = zoomedPixelMaps?.foreground ?? defaultPixelMap;
  applyPixelMapping(gl, programInfo.uniformLocations.backgroundPixelMap, backgroundZoomedMap);
  applyPixelMapping(gl, programInfo.uniformLocations.foregroundPixelMap, foregroundZoomedMap);

  const texelWidth = 1 / canvasWidth;
  const texelHeight = 1 / canvasHeight;
  gl.uniform2f(programInfo.uniformLocations.texelSize, texelWidth, texelHeight);
  gl.uniform1f(programInfo.uniformLocations.stroke, stroke ? 1.0 : 0.0);

  gl.activeTexture(gl.TEXTURE0);
  gl.bindTexture(gl.TEXTURE_2D, textures.imageTexture);
  gl.uniform1i(programInfo.uniformLocations.imageSampler, 0);

  gl.activeTexture(gl.TEXTURE1);
  gl.bindTexture(gl.TEXTURE_2D, textures.backgroundTexture);
  gl.uniform1i(programInfo.uniformLocations.backgroundSampler, 1);

  gl.activeTexture(gl.TEXTURE2);
  gl.bindTexture(gl.TEXTURE_2D, textures.maskTexture);
  gl.uniform1i(programInfo.uniformLocations.maskSampler, 2);

  gl.activeTexture(gl.TEXTURE3);
  gl.bindTexture(gl.TEXTURE_2D, textures.videoTexture);
  gl.uniform1i(programInfo.uniformLocations.videoSampler, 3);

  gl.viewport(0, 0, canvasWidth, canvasHeight);

  const vertexCount = 6;
  const type = gl.UNSIGNED_SHORT;
  const offset = 0;
  gl.drawElements(gl.TRIANGLES, vertexCount, type, offset);
}
