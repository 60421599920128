import type { AiEditShot } from "@shared/generated/typescript/aiEdit/api/CreateAiEditVideoTemplate";
import { DevLogger } from "dev-logger";

import { Command } from "../../CommandList.types";
import { EDLSegment } from "../../EDL.types";
import { AIEditExternalAsset } from "../../hooks/useAIEditGenerator";

import {
  getImageOverlayRenderEffect,
  getPagOverlayRenderEffect,
} from "./helpers/getOverlayRenderEffect";

const logger = new DevLogger("geneate-commands-from-server-driven-ai-edit");

export function generateOverlayCommands(shots: AiEditShot[]) {
  const commands: Command[] = [];
  const externalAssets: AIEditExternalAsset[] = [];

  let mainTotal = 0;
  for (const shot of shots) {
    // step 1: create edl for command
    const sceneDuration = shot.endTime - shot.startTime;
    const segment: EDLSegment = [
      mainTotal,
      mainTotal + sceneDuration,
      shot.startTime,
      shot.endTime,
    ];
    mainTotal += sceneDuration;

    // step 2: extract overlays
    const foregroundOverlays = shot.shotTemplate?.foregroundOverlays || [];
    const backgroundOverlays = shot.shotTemplate?.backgroundOverlays || [];

    // step 3: create foreground overlay commands
    foregroundOverlays.forEach((overlay) => {
      const overlayType = overlay.type.oneofKind;
      switch (overlayType) {
        case "image": {
          try {
            const { renderEffect, externalAsset, wrapMode } = getImageOverlayRenderEffect(
              overlay.type.image
            );
            externalAssets.push({
              id: externalAsset,
              type: "image",
              url: new URL(externalAsset),
            });
            const overlayCommand: Command = {
              edl: {
                segments: [segment],
                wrapMode,
              },
              renderEffect,
            };
            commands.push(overlayCommand);
          } catch (e) {
            logger.error("Error generating foreground image overlay command", e);
          }

          break;
        }
        case "pag": {
          try {
            const { renderEffect, externalAssets: effectExternalAssets } =
              getPagOverlayRenderEffect(overlay.type.pag);
            effectExternalAssets.forEach((externalAsset) =>
              externalAssets.push({
                id: externalAsset,
                type: "image",
                url: new URL(externalAsset),
              })
            );
            const overlayCommand: Command = {
              edl: {
                segments: [segment],
              },
              renderEffect,
            };
            commands.push(overlayCommand);
          } catch (e) {
            console.error("Error generating foreground pag overlay command", e);
          }

          break;
        }
        case "video": {
          const videoAssetId =
            overlay.type.video.source?.type.oneofKind === "staticAsset"
              ? overlay.type.video.source?.type.staticAsset.id
              : "";
          externalAssets.push({
            id: videoAssetId,
            type: "video",
          });
          const overlayCommand: Command = {
            edl: {
              segments: [segment],
              wrapMode: "repeat",
            },
            renderEffect: {
              renderEffectType: "overlay-effect",
              assetId: videoAssetId,
            },
            blendMode: "source-over",
          };
          commands.push(overlayCommand);
          break;
        }
      }
    });

    // step 4: create background overlay commands
    backgroundOverlays.forEach((overlay) => {
      const overlayType = overlay.type.oneofKind;
      switch (overlayType) {
        case "image": {
          try {
            const { renderEffect, externalAsset, wrapMode } = getImageOverlayRenderEffect(
              overlay.type.image,
              { isBackground: true }
            );
            externalAssets.push({
              id: externalAsset,
              type: "image",
              url: new URL(externalAsset),
            });
            const overlayCommand: Command = {
              edl: {
                segments: [segment],
                wrapMode,
              },
              renderEffect,
            };
            commands.push(overlayCommand);
          } catch (e) {
            logger.error("Error generating background image overlay command", e);
          }

          break;
        }
        case "pag": {
          try {
            const { renderEffect: pagSequenceRenderEffect, externalAssets: effectExternalAssets } =
              getPagOverlayRenderEffect(overlay.type.pag, { isBackground: true });
            effectExternalAssets.forEach((externalAsset) =>
              externalAssets.push({
                id: externalAsset,
                type: "image",
                url: new URL(externalAsset),
              })
            );
            const overlayCommands: Command[] = [
              {
                edl: {
                  segments: [segment],
                },
                renderEffect: pagSequenceRenderEffect,
              },
              {
                edl: {
                  segments: [segment],
                  wrapMode: "repeat",
                },
                renderEffect: {
                  renderEffectType: "scene-background",
                  assetType: "pag-sequence",
                  cutout: true,
                  assetId: pagSequenceRenderEffect.sequenceItems
                    .map((item) => item.assetId)
                    .join("__"),
                },
              },
            ];
            commands.push(...overlayCommands);
          } catch (e) {
            logger.error("Error generating background pag overlay command", e);
          }

          break;
        }
        case "video": {
          // TODO
          throw new Error("Background video overlays are not yet supported");
        }
      }
    });
  }

  return { commands, externalAssets };
}
