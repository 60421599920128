import * as T from "@radix-ui/react-toast";

import { keyframes, styled } from "~/theme";

import InfoFilled from "../icons/InfoFilled";

const hide = keyframes({
  from: { opacity: 1 },
  to: { opacity: 0 },
});

const show = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
});

export const Root = styled(T.Root, {
  backgroundColor: "$grey-700",
  borderRadius: 6,
  borderStyle: "solid",
  borderWidth: "1px",
  top: 0,
  color: "$white",
  display: "flex",
  fontSize: "$default",
  gap: "$12",
  justifyContent: "space-between",
  lineHeight: "24px",
  maxWidth: "calc(100% - 96px)",
  padding: "$12 $16",
  position: "absolute",
  zIndex: "$z-toast",
  '&[data-state="closed"]': {
    animation: `${hide} 150ms ease-in`,
  },
  '&[data-state="open"]': {
    animation: `${show} 150ms ease-in`,
  },
  "&:first-child": {
    boxShadow: "0px 12px 32px rgba(0, 0, 0, 0.08)",
  },
  variants: {
    severity: {
      error: {
        borderColor: "$secondary-error",
      },
      info: {
        borderColor: "$grey-700",
      },
      success: {
        borderColor: "$secondary-plum-4",
      },
      warning: {
        borderColor: "$secondary-sunny",
      },
    },
  },
});

export const TitleBox = styled("div", {
  alignItems: "center",
  display: "flex",
  gap: "$8",
});

export const ToastInfoFilledIcon = styled(InfoFilled, {
  minWidth: 24,
  minHeight: 24,
  variants: {
    severity: {
      error: {
        color: "$secondary-error",
      },
      info: {
        color: "$white",
      },
      success: {
        color: "$secondary-plum-4",
      },
      warning: {
        color: "$secondary-sunny",
      },
    },
  },
});

export const Close = styled("button", {
  border: "none",
  fontFamily: "$avenir-next",
  fontWeight: "$demi-bold",
  padding: "0 $8",
  variants: {
    severity: {
      error: {
        color: "$secondary-error",
      },
      info: {
        color: "$white",
      },
      success: {
        color: "$secondary-plum-4",
      },
      warning: {
        color: "$secondary-sunny",
      },
    },
  },
});

export const Action = styled("button", {
  padding: "0 $8",
  fontWeight: "$demi-bold",
  textTransform: "capitalize",
  fontFamily: "$avenir-next",
});

export const Viewport = styled("div", {
  position: "absolute",
  bottom: "calc(100vh - 96px)",
  left: 0,
  display: "flex",
  justifyContent: "center",
  width: "100%",
  boxSizing: "border-box",
  padding: 0,
});
