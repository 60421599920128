import { PseudoRandomGenerator } from "stable-pseudo-rng";

import { CaptionAnimationModifiers } from "./animationUtils.types";
import { CaptionPrecalcData } from "./captionDrawing.types";
import { CaptionStylePreset, MovementAutoMotion, ScaleAutoMotion } from "./captionStyle.types";
import { cubicSplinePoint, CurvePoint } from "./utils/bezier";

const SCALE_AUTO_MOTION_SPEED_BASE = 0.6;
const SCALE_AUTO_MOTION_ZONE_SIZE_FACTOR = 0.3;
const MOVEMENT_AUTO_MOTION_SPEED_BASE = 0.5;
const MOVEMENT_AUTO_MOTION_ZONE_SIZE_FACTOR = 0.3333;

export function applyMovementAutoMotion(
  autoMotion: CaptionPrecalcData["movementAutoMotionData"] | undefined | null,
  pageAnimation: CaptionAnimationModifiers,
  stylePreset: CaptionStylePreset,
  {
    timestamp,
    baseScale,
  }: {
    timestamp: number;
    baseScale: number;
  }
) {
  if (!stylePreset.movementAutoMotion.enabled) {
    return;
  }

  if (!autoMotion?.knots.length || autoMotion.maxTime === 0) {
    return;
  }
  const timeFactor = timestamp / autoMotion.maxTime;
  const point = cubicSplinePoint(timeFactor, autoMotion.knots, autoMotion.controlPoints);
  pageAnimation.offset.x ??= 0;
  pageAnimation.offset.y ??= 0;
  pageAnimation.offset.x += point.x * baseScale;
  pageAnimation.offset.y += point.y * baseScale;
}

export function applyScaleAutoMotion(
  autoMotion: CaptionPrecalcData["scaleAutoMotionData"] | undefined | null,
  pageAnimation: CaptionAnimationModifiers,
  {
    timestamp,
  }: {
    timestamp: number;
  }
) {
  if (!autoMotion?.keyPoints.length || autoMotion.maxTime === 0) {
    return;
  }
  const timeFactor = timestamp / autoMotion.maxTime;
  const index = Math.floor(timeFactor * (autoMotion.keyPoints.length - 1));
  if (index < 0 || index >= autoMotion.keyPoints.length - 1) {
    return;
  }
  // Calculates the relative position on the current segment.
  const deltaT = 1 / (autoMotion.keyPoints.length - 1);
  const t1 = index * deltaT;
  const relativeT = (timeFactor - t1) / deltaT;
  pageAnimation.sizeFactor *=
    autoMotion.keyPoints[index] +
    (autoMotion.keyPoints[index + 1] - autoMotion.keyPoints[index]) * relativeT;
}

export function calculateMovementAutoMotionKnots(
  seed: number,
  maxTime: number,
  settings: MovementAutoMotion
): CurvePoint[] {
  if (!settings.enabled || !settings.speed || !isFinite(maxTime)) {
    return [];
  }
  if (settings.offset.x === 0 && settings.offset.y === 0) {
    return [];
  }
  const random = new PseudoRandomGenerator(seed * 1000);
  const interval = MOVEMENT_AUTO_MOTION_SPEED_BASE / settings.speed;
  const n = Math.ceil(maxTime / interval);
  const zoneSize = {
    x: settings.offset.x * MOVEMENT_AUTO_MOTION_ZONE_SIZE_FACTOR,
    y: settings.offset.y * MOVEMENT_AUTO_MOTION_ZONE_SIZE_FACTOR,
  };
  /**
   * Contains the upper-left coordinates of the knot zones.
   * <pre>
   * +---+---+---+
   * | 0 |   | 1 |
   * +---+---+---+
   * |   |   |   |
   * +---+---+---+
   * | 2 |   | 3 |
   * +---+---+---+--------------------+
   *             |                    |
   *             |   Captions frame   |
   *             |                    |
   *             +--------------------+
   * </pre>
   */
  const knotZoneCoords: CurvePoint[] = [
    { x: -settings.offset.x, y: -settings.offset.y },
    { x: -zoneSize.x, y: -settings.offset.y },
    { x: -settings.offset.x, y: -zoneSize.y },
    { x: -zoneSize.x, y: -zoneSize.y },
  ];
  const knots = new Array<CurvePoint>(n);
  const lastUsedZones = [0, 2];
  const availableZones = [1, 3];
  for (let i = 0; i < n; i++) {
    const zoneIndex = random.next() < 0.5 ? 0 : 1;
    const zone = availableZones[zoneIndex];
    availableZones[zoneIndex] = lastUsedZones.shift() ?? 0;
    lastUsedZones.push(zone);
    const zoneCoords = knotZoneCoords[zone];
    knots[i] = {
      x: zoneCoords.x + random.next() * zoneSize.x,
      y: zoneCoords.y + random.next() * zoneSize.y,
    };
  }
  return knots;
}

export function calculateScaleAutoMotionKeyPoints(
  seed: number,
  maxTime: number,
  settings: ScaleAutoMotion
): number[] {
  if (!settings.enabled || !settings.speed || settings.maxScale === 1 || !isFinite(maxTime)) {
    return [];
  }
  const random = new PseudoRandomGenerator(seed * 1000);
  const interval = SCALE_AUTO_MOTION_SPEED_BASE / settings.speed;
  const n = Math.ceil(maxTime / interval);
  const zoneSize = (settings.maxScale - 1) * SCALE_AUTO_MOTION_ZONE_SIZE_FACTOR;
  /**
   * Contains the final coordinates of the key point zones.
   * <pre>
   * +------+--------+------+
   * +  0   |        |  1   |
   * +------+--------+------+
   * </pre>
   */
  const zoneCoords = [1 + zoneSize, settings.maxScale];
  let currentZoneIndex = 0;
  const keyPoints = new Array<number>(n).fill(1);
  for (let i = 0; i < n; i++) {
    keyPoints[i] = zoneCoords[currentZoneIndex] - random.next() * zoneSize;
    currentZoneIndex = +!currentZoneIndex;
  }
  return keyPoints;
}
