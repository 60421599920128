import { ZodError } from "zod";

import { BackendServicesClient } from "~/context/BackendServicesContext";

import {
  type DtoRes_GetSceneChangeDetection,
  DtoRes_GetSceneChangeDetectionSchema,
  type DtoRes_SubmitSceneChangeDetection,
  DtoRes_SubmitSceneChangeDetectionSchema,
  type SceneChangeDetectionRequest,
} from "./SceneChangeDetection.dto";

const SCENES_JOB_URL = "/video/v1/scene-timestamps";

export async function startScenesJob(
  client: BackendServicesClient,
  scenesJob: SceneChangeDetectionRequest
): Promise<DtoRes_SubmitSceneChangeDetection> {
  try {
    const result = await client.post(SCENES_JOB_URL, scenesJob);
    return DtoRes_SubmitSceneChangeDetectionSchema.parse(result.data);
  } catch (error) {
    console.error(error);
    if (error instanceof ZodError) {
      throw new Error("Invalid response received from the server!");
    } else {
      throw error;
    }
  }
}

export async function getScenesJobStatus(
  client: BackendServicesClient,
  scenesJobId: string,
  signal?: AbortSignal
): Promise<DtoRes_GetSceneChangeDetection> {
  try {
    const result = await client.get(`${SCENES_JOB_URL}/${scenesJobId}/status`, { signal });
    return DtoRes_GetSceneChangeDetectionSchema.parse(result.data);
  } catch (error) {
    console.error(error);
    if (error instanceof ZodError) {
      throw new Error("Invalid response received from the server!");
    } else {
      throw error;
    }
  }
}
