import type { Line, Page, Word } from "captions-engine";

import type { Clip } from "~/utils/videoClips";

import type { SoundEffectId } from "../../services/SoundEffectsService/SoundEffects.config";

export enum LineBreakOverride {
  lineBreak,
  pageBreak,
  noBreak,
}

/**
 * Describes a single caption word
 *
 * @property {number} id - the word's unique identifier
 * @property {string} text - the word's text contents
 * @property {string | null} [emoji] - the emoji associated with the word
 * @property {boolean} supersize - whether the supersize effect should be applied
 * @property {boolean} emphasize - whether the emphasis effect should be applied
 * @property {boolean} startTime - the timestamp the word starts being active
 * @property {boolean} endTime - the timestamp the word stops being active
 * @property {string} [phraseId] - the unique identifier of the phrase the word belongs to
 * @property {SoundEffectId} [soundId] - the sound effect associated with the word
 * @property {boolean} [isKeyword] - whether the word is a keyword or not
 * @property {KeywordSettings} [keywordSettings] - the settings for the keyword
 * @property {LineBreakOverride | null} [lineBreakOverride] - whether the word should be forced to break
 */
export interface CaptionWord extends Word {
  lineBreakOverride?: LineBreakOverride | null;
  phraseId?: string | null;
  soundId?: SoundEffectId;
  isKeyword?: boolean;
  keywordSettings?: {
    supersize: boolean;
    emphasize: boolean;
  };
  isHidden?: boolean;
}

/**
 * Describes a single caption line
 *
 * @property {CaptionWord[]} words - the words contained in the line
 */
export interface CaptionLine extends Line {
  words: CaptionWord[];
}

/**
 * Describes a single caption page.
 *
 * @property {CaptionLine[]} lines - an array containing all lines shown on the page
 * @property {number} rotation - the page's rotation in radians
 * @property {number} startTime - the timestamp the page starts being active
 * @property {number} endTime - the timestamp the page stops being active
 */
export interface CaptionPage extends Page {
  lines: CaptionLine[];
}

/**
 * Describes a single caption phrase
 *
 * @property {string} id - the phrase's unique identifier
 * @property {string} text - the phrase's text contents
 * @property {number} startTime - the timestamp the phrase starts being active
 * @property {number} endTime - the timestamp the phrase stops being active
 * @property {string} [speaker] - the phrase's speaker
 */
export interface CaptionPhrase {
  id: string;
  startTime: number;
  endTime: number;
  text: string;
  speaker?: string | null;
}

/**
 * Describes a single phrase dubbing audio clip
 *
 * **NOTE:** *originalStartTime* and *originalEndTime* are used to store the original
 * start and end times returned from the server, which are needed to overlay them
 * to the uncut video on the backend. Once the audio overlay routines are updated
 * to use relative timestamps, these fields can be removed.
 *
 * @property {string} id - the clip's unique identifier
 * @property {string} phraseId - the identifier of the phrase the clip belongs to
 * @property {number} startTime - the timestamp the audio should start playing
 * @property {number} endTime - the timestamp the audio should stop playing
 * @property {number} originalStartTime - the original start timestamp returned from the server
 * @property {number} originalEndTime - the original end timestamp returned from the server
 * @property {string} gcsUri - the phrase's audio GCS URI
 * @property {string} downloadUrl - the phrase's audio download URL
 * @property {Clip[]} audioClips - the phrase's audio clips
 */
export interface CaptionPhraseAudio {
  id: string;
  phraseId: string;
  startTime: number;
  endTime: number;
  originalStartTime: number;
  originalEndTime: number;
  gcsUri: string;
  downloadUrl: string;
  audioClips?: Clip[];
}

export interface ProjectCaptions {
  words: CaptionWord[];
  phrases: CaptionPhrase[];
  phraseAudios: CaptionPhraseAudio[];
  countryCode: string;
  skipAdjustments?: boolean;
}
