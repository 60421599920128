import type { Clip } from "~/utils/videoClips";

import type { TimeSpan } from "../EDL.types";

export function generateClipsFromTimespans(duration: number, timespans: TimeSpan[]) {
  const clips: Clip[] = [];

  let previousEndTime = 0;

  for (const { startTime, endTime } of timespans) {
    if (startTime > previousEndTime) {
      clips.push({
        startTime: previousEndTime,
        endTime: startTime,
        deleted: true,
      });
    }

    clips.push({
      startTime: startTime,
      endTime: endTime,
    });

    previousEndTime = endTime;
  }

  const lastClip = clips.at(-1);
  if (lastClip && duration > lastClip.endTime) {
    clips.push({
      startTime: lastClip.endTime,
      endTime: duration,
      deleted: true,
    });
  }

  return clips;
}
