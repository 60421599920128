import { styled } from "~/theme";

export const SideFormContainer = styled("form", {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 24,
});

export const SideFormDescription = styled("div", {
  display: "flex",
  flexDirection: "column",
});

export const SideFormHR = styled("div", {
  borderBottom: "1px solid $grey-800",
});

export const SideFormButtonBar = styled("div", {
  alignSelf: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  gap: 12,
  flexGrow: 1,
  justifyContent: "flex-end",
  variants: {
    fullWidth: {
      true: {
        width: "100%",
      },
    },
  },
});
