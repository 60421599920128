import { useRouter } from "next/router";
import { useEffect } from "react";

import { SESSION_START } from "~/constants/mixpanel.constants";
import { useAuthState } from "~/context/CaptionsAuthContext";
import { useAnalytics } from "~/hooks/useAnalytics";
import { BackendServicesClient } from "~/services/BackendServicesClient";

import { AuthGuardProps } from "./AuthGuard.types";

/**
 * AuthGuard is a client-side component that can be used to redirect to the
 * login page if the user is not authenticated. Typically, next-auth's
 * getServersideProps is used to redirect to the login page if the user
 * is not authenticated. However, this is not possible for pages
 * that are not server-side rendered - for example, in the electron app.
 *
 * @param requireAuth {boolean}  If true, will redirect to login page if not authenticated
 * @param signInCallbackUrl {string}  If provided, will redirect to this URL after successful login
 */
const AuthGuard = ({ children, requireAuth = true, signInCallbackUrl }: AuthGuardProps) => {
  const { state } = useAuthState();
  const router = useRouter();
  const analyics = useAnalytics();

  useEffect(() => {
    const canvas = document.createElement("canvas");
    const isWebGLSupported = !!(
      window.WebGLRenderingContext &&
      (canvas.getContext("webgl") || canvas.getContext("experimental-webgl"))
    );

    analyics.track(
      SESSION_START,
      {
        browser_webgl_toggle: isWebGLSupported,
      },
      ["mixpanel"]
    );
  }, []);

  useEffect(() => {
    if (state === "signedIn" && router.pathname === "/login") {
      router.push("/projects", undefined, { shallow: true });
    } else if (requireAuth && state === "signedOut") {
      const destination = {
        pathname: "/login",
        query: {},
      };
      const { callbackUrl } = router.query;

      if (signInCallbackUrl || callbackUrl) {
        destination.query = {
          ...destination.query,
          callbackUrl: signInCallbackUrl || callbackUrl,
        };
      }

      // cancel any pending requests when we redirect to login
      BackendServicesClient.shared.cancelPendingRequests();

      router.push(destination, undefined, { shallow: true });
    }
  }, [state]);

  return <>{children}</>;
};

export default AuthGuard;
