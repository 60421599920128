import * as Select from "@radix-ui/react-select";

import { styled } from "~/theme";

import { Text } from "../Text";

export const FormSelectItemIndicatorStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 24,
  height: 24,
  backgroundColor: "$primary-violet",
  color: "$white",
  borderRadius: "50%",
};

export const FormSelectItemIndicator = styled(Select.ItemIndicator, FormSelectItemIndicatorStyles);

export const FormSelectItemIndicatorWrapper = styled("div", {
  width: FormSelectItemIndicatorStyles.width,
  height: FormSelectItemIndicatorStyles.height,
});

export const FormSelectPopupItem = styled(Select.Item, {
  alignItems: "center",
  borderRadius: 8,
  color: "$grey-150",
  cursor: "pointer",
  display: "flex",
  flexDirection: "row",
  gap: 8,
  justifyContent: "space-between",
  padding: "$12 10px",
  "&:focus": {
    background: "$grey-800-highlighted",
    outline: "none",
  },
  "&[data-state=checked]": {
    backgroundColor: "$grey-800-highlighted",
  },
});

export const FormSelectPopupItemText = styled(Select.ItemText, {
  flex: 1,
});

export const FormSelectPopupText = styled(Text, {
  display: "flex",
  gap: 8,
  alignItems: "center",
  defaultVariants: {
    variant: "body-1",
    color: "grey-150",
  },
  width: "100%",
});
