import { QueryStatus } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";

import { createUseQueryHook } from "~/utils/network/query-helpers";

import { availableLanguageOptionsResponseSchema } from "../services/AvailableTranscriptLanguages";

export type AvailableTranscriptLanguagesStatus = "loading" | "loaded" | "error";

const useGetAvailableTranscriptLanguages = createUseQueryHook({
  queryKey: ["available-transcript-languages"],
  path: "/form/v1/v1-get-supported-language",
  method: "GET",
  proxied: false,
  responseSchema: availableLanguageOptionsResponseSchema,
  transformer(response) {
    return response.form.options;
  },
});

const QUERY_STATUS_TO_OLD_STATUS: Record<QueryStatus, AvailableTranscriptLanguagesStatus> = {
  pending: "loading",
  success: "loaded",
  error: "error",
};

export function useAvailableTranscriptLanguages() {
  const {
    data: availableLanguages = [],
    status: rawStatus,
    error,
  } = useGetAvailableTranscriptLanguages();

  const availableLanguagesForShorts = useMemo(
    () => availableLanguages.filter((language) => language.isShortsAvailable),
    [availableLanguages]
  );

  // convert language code to full english display language string
  const codeToLanguage = useCallback(
    (code?: string) => {
      if (!code) {
        return;
      }
      const match = availableLanguages.find((l) => l.code === code);
      if (!match) {
        return;
      }
      return match.englishDisplayName;
    },
    [availableLanguages]
  );

  return {
    status: QUERY_STATUS_TO_OLD_STATUS[rawStatus],
    availableLanguages,
    availableLanguagesForShorts,
    codeToLanguage,
    errorMessage: error?.message,
  };
}
