export const WEBGL_CONTEXT_TYPE = "webgl" as const;

export const WEBGL_OPTIONS: WebGLContextAttributes = {
  // The drawing buffer doesn't need to be preserved between frames, so this allows
  // the browser to swap buffers instead of copying to improve performance
  preserveDrawingBuffer: false,
  // We don't have a fallback, so ensure that we don't fail even if using software mode
  failIfMajorPerformanceCaveat: false,
  // Some libPAG animations need the canvas to have an alpha channel, so we can't disable it to
  // improve performance
  alpha: true,
  // Disabling unnecessary features for performance
  depth: false,
  stencil: false,
  antialias: false,
  // This canvas doesn't need to be synchronized with the event loop
  desynchronized: true,
};
