/** The minimum width required to show both drag handles on a word item */
export const MIN_WORD_WIDTH =
  1 + // border left
  4 + // padding left
  8 + // drag handle width
  7 + // gap
  2 + // clipper width
  4 + // padding right
  1; // border right

/** The minimum duration a word can be adjusted to by dragging the clipper */
export const MIN_WORD_DURATION_TIMING_MODE = 0.02;

/**
 * The minimum pixels-per-second ratio such that the shortest possible word
 * duration (given by a constant) is still wide enough to show both drag handles.
 * This will be used as the smallest end of the timeline zoom scale.
 */
export const MIN_PIXELS_PER_SECOND_TIMING_MODE = MIN_WORD_WIDTH / MIN_WORD_DURATION_TIMING_MODE;

// In Effects mode, we decrease the pixels-per-second.
// There are no drag handles so the min width is not a concern,
// and this decreases the speed at which the timeline moves during playback.
// You can also scrub further from a single drag.

export const MIN_WORD_DURATION_EFFECTS_MODE = 0.1;
export const MIN_PIXELS_PER_SECOND_EFFECTS_MODE = MIN_WORD_WIDTH / MIN_WORD_DURATION_EFFECTS_MODE;
