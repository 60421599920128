import { DevLogger } from "dev-logger";
import { StoreNames } from "idb";

import { getMixpanelInstance } from "~/stores/analytics/mixpanel";

import { CaptionsDatabaseInstance, CaptionsData } from "./factory";

const logger = new DevLogger("[indexeddb]");

const formatStackTrace = (stackTrace: string, limit: number = 3) => {
  // Split stack trace into lines, reverse the order, and take the first `limit` lines
  return stackTrace
    .split("\n")
    .reverse()
    .slice(0, limit) // Limit to the first `limit` lines after reversing
    .join("\n");
};

export const trackDBEvent = async (eventName: string, properties?: Record<string, unknown>) => {
  const mixpanel = await getMixpanelInstance();
  if (mixpanel) {
    mixpanel.track(`idb_${eventName}`, {
      ...properties,
    });
  }
};

export const logError = (eventKey: string, error: Error, properties?: Record<string, unknown>) => {
  logger.error(error);
  trackDBEvent(eventKey, {
    error_name: error.name,
    error_message: error.message,
    stack: formatStackTrace(error.stack || "Stack trace unavailable", 10),
    ...properties,
  });
};

export const enhanceTransaction = (db: CaptionsDatabaseInstance) => {
  const originalTransaction = db.transaction.bind(db);
  db.transaction = (storeNames: StoreNames<CaptionsData>, mode: IDBTransactionMode) => {
    try {
      const tx = originalTransaction(storeNames, mode, { durability: "strict" });
      tx.addEventListener("error", (event) => handleTransactionError(event, storeNames, mode));
      tx.addEventListener("abort", () => handleTransactionAbort(storeNames, mode));
      return tx;
    } catch (error) {
      if (error instanceof Error) {
        logError("transaction_error", error);
      }
      return originalTransaction(storeNames, mode);
    }
  };
};

export const handleTransactionError = (
  event: Event,
  storeNames: StoreNames<CaptionsData>,
  mode: IDBTransactionMode
) => {
  const error = (event.target as IDBRequest)?.error;
  logError("transaction_error", error || new Error("Transaction error"), { storeNames, mode });
};

export const handleTransactionAbort = (
  storeNames: StoreNames<CaptionsData>,
  mode: IDBTransactionMode
) => {
  logger.log("Transaction aborted", storeNames, mode);
  trackDBEvent("transaction_aborted", { storeNames, mode });
};

export const enhanceClose = (db: CaptionsDatabaseInstance) => {
  const originalClose = db.close.bind(db);
  db.close = () => {
    try {
      originalClose();
      trackDBEvent("database_closed", { caller: new Error().stack }); // Track the caller
    } catch (error) {
      if (error instanceof Error) {
        logError("close_error", error);
      }
    }
  };
};

export const handleDatabaseError = (error: Error | unknown) => {
  logError(
    "initialization_failed",
    error instanceof Error ? error : new Error("Error opening database")
  );
};

export const handleVersionChange = (
  event: IDBVersionChangeEvent,
  db: CaptionsDatabaseInstance,
  onVersionChange: () => void
) => {
  logger.error(
    "Database version changed elsewhere, closing db and refreshing page",
    `Went from version ${event.oldVersion} to ${event.newVersion}`
  );
  trackDBEvent("version_change", { oldVersion: event.oldVersion, newVersion: event.newVersion });
  db.close();
  onVersionChange();
  location.reload();
};

export const handleDatabaseAbort = (error: DOMException | null) => {
  if (error) {
    logError("database_aborted", error);
  }
};
