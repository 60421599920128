import type {
  CaptionStylePreset,
  DynamicCaptionsShape,
  FilterEffect,
  OverlayEffectBlendMode,
  ScenePositionFactor,
  ShakeData,
} from "captions-engine";
import { DevLogger } from "dev-logger";
import { nanoid } from "nanoid";

import type {
  AIEditData,
  NewCaptionSettings,
  Project,
  StoredAsset,
} from "~/database/database.types";
import {
  BackgroundEffect,
  FrameBorder,
  FullScreenOverlayEffect,
  ImageEffect,
  OverlayAnimationEffect,
  Sound,
  ZoomEffect,
  ImageFontEffect,
  PagSequenceEffect,
  PagSequenceEffectAsset,
  PagSequenceEffectAssetSlot,
} from "~/database/effects.types";
import type { Transition } from "~/database/transition.types";

import { DEFAULT_POSITION_FACTOR } from "../../project/constants/captionStyle";
import { IMAGE_EFFECT_OVERRIDE } from "../../project/hooks/useImageEffects/assets/fixedAssets";
import type {
  CaptionEmphasisSettings,
  CaptionTemplate,
} from "../../project/services/CaptionStylePreset";
import { TransitionSoundIds } from "../../project/services/PagAnimations/TransitionAnimations.config";
import { SoundEffectId } from "../../project/services/SoundEffectsService/SoundEffects.config";
import { FixedAsset, loadAssetImage, loadFixedAsset } from "../../project/utils/assetUtils";
import { ProjectCaptions, getAdjustedCaptions } from "../../project/utils/captionProcessing";
import { getAbsoluteTransitions } from "../../project/utils/clipDeleting";
import { ProjectFaceData } from "../../project/utils/faceData/face-data.types";
import {
  calculateCaptionSizeFactor,
  getDefaultSizeFactor,
} from "../../project/utils/getDefaultSizeFactor";
import type { CommandList } from "../CommandList.types";
import { EDL } from "../EDL";
import { TimeSpan } from "../EDL.types";
import { AIEditExternalAsset } from "../hooks/useAIEditGenerator";
import { getAiEditAssetById } from "../services/AIEditStyles/network/service";

import { generateClipsFromTimespans } from "./generateClipsFromTimespans";
import { getImageScale } from "./getImageScale";
import { getVideoCommand } from "./getVideoCommand";

export interface ProjectStateCallbacks {
  onLookupAsset: (assetId: string) => AIEditExternalAsset | null;
  onStoreAsset: (
    blob: Blob,
    name: string,
    type: "video" | "image",
    sourceURL: string
  ) => Promise<StoredAsset | undefined>;
  onFetchAssetFromCache: (sourceURL: string) => StoredAsset | undefined;
}

const logger = new DevLogger("[ai-edit-state-extract]");

/**
 * Extracts the project state (as stored in the database) from a command list.
 *
 * @param commandList - The command list to extract the project state from.
 * @param captionStyleTemplates - A list of the available caption style templates.
 * @param captions - The project captions.
 * @param onLookupAsset - A function to look up an asset by its ID.
 * @param onStoreAsset - A function to store an asset in the database.
 * @param onFetchAssetFromCache - A function to fetch an asset from the cache.
 * @returns The project state extracted from the command list.
 */
export async function extractProjectStateFromCommandList(
  commandList: CommandList,
  captionStyleTemplates: CaptionTemplate[] | undefined,
  captions: ProjectCaptions,
  { onLookupAsset, onStoreAsset, onFetchAssetFromCache }: ProjectStateCallbacks
) {
  const videoCommand = getVideoCommand(commandList);
  if (!videoCommand) {
    throw new Error("No video source command found");
  }
  const videoMetadata = videoCommand.renderEffect.metadata;
  const videoLocalTimespans = EDL.getLocalTimespans(videoCommand.edl);
  const clips = generateClipsFromTimespans(videoMetadata.duration, videoLocalTimespans);

  const images: ImageEffect[] = [];
  const scenePositionFactors: ScenePositionFactor[] = [];
  const backgroundEffects: BackgroundEffect[] = [];
  const overlayEffects: FullScreenOverlayEffect[] = [];
  const frameBorders: FrameBorder[] = [];
  const pagSequences: PagSequenceEffect[] = [];
  const sounds: Sound[] = [];
  const transitions: Transition[] = [];
  const zoomPoints: ZoomEffect[] = [];
  const cameraShake: ShakeData[] = [];
  const animationItems: OverlayAnimationEffect[] = [];
  const hideCaptionsAt: TimeSpan[] = [];
  const filters: FilterEffect[] = [];
  const dynamicCaptions: { startTime: number; endTime: number; shape: DynamicCaptionsShape }[] = [];
  const imageFontEffects: ImageFontEffect[] = [];

  let captionSettings: NewCaptionSettings | undefined;
  let captionTemplateId: string | undefined;

  async function fetchAndStoreExternalAsset(
    asset: AIEditExternalAsset
  ): Promise<StoredAsset | undefined> {
    if (asset.type === "value") {
      return undefined;
    }

    if (!asset.url) {
      // Fetch AI Edit assets from backend
      try {
        const url = (await getAiEditAssetById(asset.id)).url;
        const response = await fetch(url);
        const imageData = await response.blob();

        if (!imageData) {
          throw new Error(`imageData not found for ${asset.id}`);
        }

        return onStoreAsset(imageData, asset.id, asset.type, asset.id);
      } catch (error) {
        logger.warn("Failed to fetch asset", error);
        return undefined;
      }
    }

    const cachedAsset = onFetchAssetFromCache(asset.url.toString());
    if (cachedAsset) {
      return cachedAsset;
    }

    const imageData = await fetch(asset.url)
      .then((response) => (response.ok ? response.blob() : null))
      .catch((error) => {
        logger.warn("Failed to fetch asset", error);
        return null;
      });

    if (!imageData) {
      return undefined;
    }

    return onStoreAsset(imageData, asset.id, asset.type, asset.url.toString());
  }

  for (const cmd of commandList) {
    const timespans = EDL.getMainTimespans(cmd.edl);

    if (cmd.renderEffect.renderEffectType === "captions") {
      const captionEffect = cmd.renderEffect;
      const positionFactor = captionEffect.positionFactor ?? { x: 0.5, y: 0.5 };
      scenePositionFactors.push({
        positionFactor,
        startTime: timespans[0].startTime,
        endTime: timespans[0].endTime,
      });

      if (captionEffect.hidden) {
        hideCaptionsAt.push(...timespans);
      }

      captionTemplateId = cmd.renderEffect.templateId;
      const captionTemplate = captionStyleTemplates?.find(
        (template) => template.id === captionTemplateId
      );
      if (!captionSettings && captionTemplate) {
        captionSettings = {
          preset: captionTemplate.style.content,
          textColor: captionTemplate.colors.primary,
          activeColor: captionTemplate.colors.active,
          emphasisColor: captionTemplate.colors.emphasis,
          wordBackground: captionTemplate.colors.wordBackground,
          activeWordBackground: captionTemplate.style.content.activeWordBackground.color,
          emojiSettings: captionTemplate.emojiSettings,
          emphasisSettings: captionTemplate.emphasisSettings,
          positionFactor: DEFAULT_POSITION_FACTOR,
          sizeFactor: 0,
        };
      }
      if (captionEffect.dynamicCaptions) {
        dynamicCaptions.push({
          startTime: timespans[0].startTime,
          endTime: timespans[0].endTime,
          shape: captionEffect.dynamicCaptions,
        });
      }
    }

    if (cmd.renderEffect.renderEffectType === "overlay-animation") {
      const {
        introAssetId,
        holdAssetId,
        outroAssetId,
        textReplacements,
        imageReplacements,
        positioning,
      } = cmd.renderEffect;
      if (timespans.length !== 1) {
        logger.warn("Cannot have overlay animations with more than one timespan");
      }
      const imageLayers: Record<number, string> = {};
      const textLayers = textReplacements?.replacements?.reduce(
        (acc, value, index) => {
          acc[index] = value;
          return acc;
        },
        {} as Record<number, string>
      );
      let scale =
        positioning && positioning.type === "freeform" && typeof positioning.scale === "number"
          ? positioning.scale
          : getDefaultSizeFactor(videoMetadata.width, videoMetadata.height);
      let scaleCalculated = false;
      for (let i = 0; imageReplacements && i < imageReplacements.length; i++) {
        let assetId = imageReplacements[i];
        const asset = onLookupAsset(assetId);
        if (asset) {
          const newImage = await fetchAndStoreExternalAsset(asset);
          if (!newImage) {
            logger.warn("Could not fetch image asset", assetId);
            continue;
          }
          assetId = newImage.id;
          if (
            positioning &&
            positioning.type === "freeform" &&
            typeof positioning.scale !== "number" &&
            newImage.type === "image" &&
            !scaleCalculated
          ) {
            scaleCalculated = true;
            const image = await loadAssetImage(newImage.blob);
            scale = getImageScale(positioning.scale, videoMetadata, image);
          }
        }

        imageLayers[i] = assetId;
      }
      animationItems.push({
        startTime: timespans[0].startTime,
        endTime: timespans[0].endTime,
        introId: introAssetId,
        holdId: holdAssetId,
        outroId: outroAssetId,
        replacements: {
          text: {
            autoSize: textReplacements?.autoSize,
            color: textReplacements?.color,
            layers: textLayers,
          },
          imageLayers,
        },
        positioning:
          positioning && positioning.type === "freeform"
            ? {
                type: "freeform",
                x: positioning.centerX ?? 0.5,
                y: positioning.centerY ?? 0.5,
                scale: scale,
              }
            : {
                type: "fullscreen",
                fit: positioning?.fit,
              },
      });
    }

    if (cmd.renderEffect.renderEffectType === "image") {
      const {
        assetId,
        position,
        positionType,
        animations,
        staticImage,
        size,
        isBackground,
        rotation,
      } = cmd.renderEffect;

      let newImage: StoredAsset | undefined = undefined;

      if (staticImage) {
        const id = assetId as keyof typeof IMAGE_EFFECT_OVERRIDE;
        const override = IMAGE_EFFECT_OVERRIDE[id] as FixedAsset;

        newImage = await loadFixedAsset(assetId, override);
      } else {
        const externalAsset = onLookupAsset(assetId);
        if (!externalAsset) {
          // Images need an external image asset to work
          logger.warn("No asset found for image effect", assetId);
          continue;
        }

        newImage = await fetchAndStoreExternalAsset(externalAsset);
      }

      if (!newImage || !("blob" in newImage)) {
        logger.warn("Could not fetch image asset", assetId);
        continue;
      }

      const imageBitmap = await createImageBitmap(newImage.blob);
      const scale = getImageScale(size, videoMetadata, imageBitmap);

      const _images: ImageEffect[] = timespans.map(({ startTime, endTime }) => ({
        imageId: newImage.id,
        staticImage,
        startTime,
        endTime,
        positionType,
        position,
        animations,
        rotation: rotation ?? 0,
        scale,
        isBackground,
      }));

      images.push(..._images);
    }

    if (cmd.renderEffect.renderEffectType === "transition") {
      const { assetId, volume } = cmd.renderEffect;

      const commandTransitions: Transition[] = timespans.flatMap(({ startTime, endTime }) => ({
        id: nanoid(),
        effect: {
          animationId: assetId,
          type: "animation",
          soundId: assetId in TransitionSoundIds ? TransitionSoundIds[assetId] : undefined,
          volume,
        },
        // Non-intuitive, but the start time for transitions as they are stored on indexDB is the
        // middle of the transition, not the start of the transition
        startTime: (startTime + endTime) / 2,
      }));

      transitions.push(...commandTransitions);
    }

    if (cmd.renderEffect.renderEffectType === "scene-background") {
      let id = cmd.renderEffect.assetId;
      const assetType = cmd.renderEffect.assetType;
      const cutout = cmd.renderEffect.cutout;
      const stroke = cmd.renderEffect.stroke;
      const rotation = cmd.renderEffect.rotation;
      const foregroundPosition = cmd.renderEffect.foregroundPosition;
      const foregroundAnimation = cmd.renderEffect.foregroundAnimation;
      const asset = onLookupAsset(id);
      const loop = Boolean(cmd.edl.wrapMode === "repeat");
      const zoomStyle = cmd.renderEffect.zoomStyle;

      if (asset && asset.type != "value") {
        const newImage = await fetchAndStoreExternalAsset(asset);
        if (!newImage) {
          continue;
        }
        id = newImage.id;
      }
      const _backgroundEffects: BackgroundEffect[] = timespans.flatMap(({ startTime, endTime }) => {
        const zoomPoints: BackgroundEffect["zoomPoints"] = {
          background: undefined,
          foreground: undefined,
        };

        if (zoomStyle?.background) {
          zoomPoints.background = {
            timestamp: startTime,
            duration: endTime - startTime,
            zoomStyle: zoomStyle.background,
          };
        }

        if (zoomStyle?.foreground) {
          zoomPoints.foreground = {
            timestamp: startTime,
            duration: endTime - startTime,
            zoomStyle: zoomStyle.foreground,
          };
        }

        return {
          imageId: id,
          loop,
          cutout,
          stroke,
          rotation,
          foregroundPosition,
          foregroundAnimation,
          startTime,
          endTime,
          zoomPoints,
          assetType,
        };
      });

      backgroundEffects.push(..._backgroundEffects);
    }

    if (cmd.renderEffect.renderEffectType === "overlay-effect") {
      let id = cmd.renderEffect.assetId;
      const asset = onLookupAsset(id);

      if (asset && asset.type != "value") {
        // If an image or video asset was found, store it in the database and use its ID instead
        // of the original asset ID
        const newImage = await fetchAndStoreExternalAsset(asset);
        if (!newImage) {
          continue;
        }
        id = newImage.id;
      }
      // If no valid asset was found, just use the ID as-is (e.g. for built-in effects such as grain
      // or light leak)

      const _effects: FullScreenOverlayEffect[] = timespans.flatMap(({ startTime, endTime }) => {
        let blendMode: OverlayEffectBlendMode | undefined;

        switch (cmd.blendMode) {
          case "lighter":
            blendMode = "add";
            break;
          case "soft-light":
            blendMode = "softlight";
            break;
          case "screen":
            blendMode = "screen";
            break;
          case "source-over":
            blendMode = "sourceover";
            break;
        }

        if (!blendMode) {
          logger.warn(`blendMode "${cmd.blendMode}" not support yet`);
          return [];
        }

        const loop = Boolean(cmd.edl.wrapMode === "repeat");

        return { imageId: id, loop, blendMode, startTime, endTime };
      });

      overlayEffects.push(..._effects);
    }

    if (cmd.renderEffect.renderEffectType === "frame") {
      const frameEffect = cmd.renderEffect;
      const assetId = frameEffect.assetId;
      const placeholders = frameEffect.placeholders;

      const _frames = timespans.map<FrameBorder>(({ startTime, endTime }) => ({
        id: assetId,
        placeholders,
        startTime,
        endTime,
      }));

      frameBorders.push(..._frames);
    }

    if (cmd.renderEffect.renderEffectType === "pag-sequence") {
      const pagSequenceRenderEffect = cmd.renderEffect;
      const { sequenceItems } = pagSequenceRenderEffect;

      const assetsWithImageIds: PagSequenceEffectAsset[] = [];

      for (const asset of sequenceItems) {
        const slotsWithImageIds: PagSequenceEffectAssetSlot[] = [];

        for (const slot of asset.slots) {
          if (slot.type === "image") {
            const assetUrl = slot.assetUrl;
            const newImage = await fetchAndStoreExternalAsset({
              type: "image",
              url: new URL(assetUrl),
              id: assetUrl.split("/").pop() ?? "",
            });
            if (newImage) {
              slotsWithImageIds.push({
                type: "image",
                assetId: newImage.id,
                layerIndices: slot.layerIndices,
              });
            } else {
              console.warn(`Failed to load external asset: ${assetUrl}`);
            }
          } else {
            slotsWithImageIds.push(slot);
          }
        }

        assetsWithImageIds.push({
          assetId: asset.assetId,
          slots: slotsWithImageIds,
          timing: asset.timing,
        });
      }
      const _pagSequences = timespans.map<PagSequenceEffect>(({ startTime, endTime }) => ({
        startTime,
        endTime,
        sequenceItems: assetsWithImageIds,
        isBackground: pagSequenceRenderEffect.isBackground,
      }));
      pagSequences.push(..._pagSequences);
    }

    if (cmd.renderEffect.renderEffectType === "sound") {
      const { assetId, volume } = cmd.renderEffect;
      const _sounds = timespans.map<Sound>(({ startTime, endTime }) => ({
        soundId: assetId as SoundEffectId,
        startTime,
        endTime,
        volume,
      }));

      sounds.push(..._sounds);
    }

    if (cmd.renderEffect.renderEffectType === "zoom") {
      const renderEffectZoom = cmd.renderEffect;
      const _zoomPoints = timespans.map<ZoomEffect>(({ startTime, endTime }) => ({
        timestamp: startTime,
        duration: endTime - startTime,
        zoomStyle: renderEffectZoom.zoomStyle,
        focalPoint: renderEffectZoom.focalPoint,
      }));

      zoomPoints.push(..._zoomPoints);
    }

    if (cmd.renderEffect.renderEffectType === "shake") {
      const renderEffectShake = cmd.renderEffect;
      const _cameraShakeData = timespans.map(({ startTime, endTime }) => ({
        timestamp: startTime,
        duration: endTime - startTime,
        maxRotation: renderEffectShake.maxRotation,
        maxOffset: renderEffectShake.maxOffset,
      }));

      cameraShake.push(...(_cameraShakeData as ShakeData[]));
    }

    if (cmd.renderEffect.renderEffectType === "filter") {
      const renderEffectFilter = cmd.renderEffect;
      const _filters = timespans.map<FilterEffect>(({ startTime, endTime }) => ({
        startTime,
        endTime,
        filters: renderEffectFilter.filters,
      }));

      filters.push(..._filters);
    }

    if (cmd.renderEffect.renderEffectType === "image-font") {
      const renderEffectImageFont = cmd.renderEffect;
      const _fonts = timespans.map(({ startTime, endTime }) => ({
        startTime,
        endTime,
        assetIds: renderEffectImageFont.assetIds,
        text: renderEffectImageFont.text,
        positionFactor: renderEffectImageFont.positionFactor,
      }));

      imageFontEffects.push(..._fonts);
    }
  }

  const adjustedTransitions = getAbsoluteTransitions(clips, transitions);

  // Adjusts the generated captions to account for the clips deleted by AI Edit
  const { phraseAudios, phrases, words } = getAdjustedCaptions(
    captions,
    clips,
    hideCaptionsAt,
    dynamicCaptions
  );

  if (captionSettings?.preset && captionTemplateId) {
    const supersizeKeywords = Boolean(
      captionSettings?.emphasisSettings?.aiEnabled &&
        captionSettings?.emphasisSettings?.keywords?.supersize
    );
    const emphasizeKeywords = Boolean(
      captionSettings?.emphasisSettings?.aiEnabled &&
        captionSettings?.emphasisSettings.keywords.emphasize
    );

    words.forEach((word) => {
      if (word.keywordSettings) {
        word.supersize = word.keywordSettings.supersize ? supersizeKeywords : word.supersize;
        word.emphasize = word.keywordSettings.emphasize ? emphasizeKeywords : word.emphasize;
      }
    });

    captionSettings.sizeFactor = await calculateCaptionSizeFactor(
      videoMetadata.width,
      videoMetadata.height,
      {
        stylePreset: captionSettings.preset as CaptionStylePreset,
        emphasisSettings: captionSettings.emphasisSettings as CaptionEmphasisSettings,
        words,
        countryCode: captions.countryCode,
        templateId: captionTemplateId,
      }
    );
  }

  return {
    captionSettings,
    clips,
    images,
    scenePositionFactors,
    backgroundEffects,
    overlayEffects,
    frameBorders,
    captions: {
      words,
      phrases,
      phraseAudios,
    },
    // Transitions on indexDB were never migrated from the old Project entity and were never updated
    // to use timestamps relative to project time instead of source video time, so we have to adjust
    // the timestamps here
    transitions: adjustedTransitions,
    zoomPoints,
    cameraShake,
    sounds,
    animationItems,
    filters,
    imageFontEffects,
    pagSequences,
  };
}

type ExtractedStateFromCommandList = Awaited<ReturnType<typeof extractProjectStateFromCommandList>>;

export function getEffectsStateToUpdate(extractedState: ExtractedStateFromCommandList) {
  return {
    clips: extractedState.clips,
    frameBorders: extractedState.frameBorders,
    zoomPoints: extractedState.zoomPoints,
    images: extractedState.images,
    scenePositionFactors: extractedState.scenePositionFactors,
    backgroundEffects: extractedState.backgroundEffects,
    overlayEffects: extractedState.overlayEffects,
    imageFontEffects: extractedState.imageFontEffects,
    sounds: extractedState.sounds,
    cameraShake: extractedState.cameraShake,
    animationEffects: extractedState.animationItems,
    filters: extractedState.filters,
    pagSequences: extractedState.pagSequences,
  };
}

export function getProjectStateToUpdateForAiEdit(
  extractedState: ExtractedStateFromCommandList,
  aiEditData: AIEditData,
  faceData?: ProjectFaceData,
  bypassAIEditor?: boolean
): Partial<Omit<Project, "id">> {
  return {
    transitions: extractedState.transitions,
    faceData,
    aiEditData,
    // ai-editor is the default editor type for AI Edit
    lastUpdatedByEditorType: bypassAIEditor ? "timeline" : "ai-editor",
  };
}
