import { useCallback, useState } from "react";

import { Captions } from "~/database/database.types";
import { unwrap } from "~/utils/result";

import type { CaptionEmphasisSettings } from "../services/CaptionStylePreset";
import { Transcript } from "../services/Transcription";
import {
  addNewWord,
  CaptionPhrase,
  CaptionPhraseAudio,
  CaptionWord,
  createEmptyWord,
  ensureWordPhraseIds,
  getCaptionPhraseAudiosFromTranscript,
  getCaptionPhrasesFromTranscript,
  getCaptionWordsFromTranscript,
} from "../utils/captionProcessing";
import { replacePhraseWords } from "../utils/phrases/replacePhraseWords";

import { CaptionWordUpdateData } from "./useCaptions";

export function useCaptionWordsAndPhrases() {
  const [captionWords, setCaptionWords] = useState<CaptionWord[] | null>(null);
  const [captionPhrases, setCaptionPhrases] = useState<CaptionPhrase[] | null>(null);
  const [captionPhraseAudios, setCaptionPhraseAudios] = useState<CaptionPhraseAudio[] | null>(null);

  const generateFromTranscript = useCallback(
    (transcript: Transcript, emphasisSettings?: CaptionEmphasisSettings) => {
      const phrases = getCaptionPhrasesFromTranscript(transcript);
      const phraseAudios = getCaptionPhraseAudiosFromTranscript(transcript);
      const words =
        ensureWordPhraseIds(getCaptionWordsFromTranscript(transcript, emphasisSettings), phrases) ??
        [];
      setCaptionPhrases(phrases);
      setCaptionPhraseAudios(phraseAudios);
      setCaptionWords(words);
      return { words, phrases, phraseAudios };
    },
    []
  );

  const updateKeywordFlagFromTranscript = useCallback((transcript: Transcript) => {
    const transcriptWords = transcript.words;

    setCaptionWords((words) => {
      if (!words) {
        return null;
      }
      return words.map((word) => {
        const transcriptWord = transcriptWords.at(word.id);
        return {
          ...word,
          isKeyword: word.isKeyword ?? !!transcriptWord?.keyword,
        };
      });
    });
  }, []);

  const updateKeywordSettings = () => {
    setCaptionWords((words) => {
      if (!words) {
        return null;
      }
      return words.map((word) => {
        return {
          ...word,
          keywordSettings: {
            supersize: word.isKeyword ? word.supersize : false,
            emphasize: word.isKeyword ? word.emphasize : false,
          },
        };
      });
    });
  };

  const restoreFromCaptionsEntity = useCallback((captions: Captions) => {
    setCaptionPhrases(captions.phrases ?? []);
    setCaptionPhraseAudios(captions.phraseAudios ?? []);
    setCaptionWords(ensureWordPhraseIds(captions.words, captions.phrases) ?? []);
  }, []);

  const updatePhrase = useCallback(
    (id: CaptionPhrase["id"], updateData: Exclude<Partial<CaptionPhrase>, "id">) =>
      setCaptionPhrases(
        (phrases) =>
          phrases?.map((phrase) => (phrase.id === id ? { ...phrase, ...updateData } : phrase)) ??
          null
      ),
    []
  );

  const addEmptyWord = useCallback(
    (
      timestamp: number,
      videoDuration: number,
      onAdjustWords?: (oldWords: CaptionWord[], newWords: CaptionWord[]) => void,
      onError?: (reason: unknown) => void
    ): CaptionWord["id"] => {
      const newId = captionWords?.reduce((id, word) => Math.max(id, word.id + 1), 0) ?? 0;
      setCaptionWords((words) => {
        try {
          const newWord = unwrap(
            createEmptyWord(timestamp, videoDuration, captionWords || [], newId)
          );

          // Update phrase text to match
          const { phraseId } = newWord;
          if (phraseId) {
            setCaptionPhrases(
              (phrases) =>
                phrases?.map((phrase) =>
                  phrase.id === phraseId
                    ? {
                        ...phrase,
                        startTime: Math.min(phrase.startTime, newWord.startTime),
                        endTime: Math.max(phrase.endTime, newWord.endTime),
                      }
                    : phrase
                ) ?? null
            );
          }
          const newWords = addNewWord(newWord, words);
          if (words && newWords) {
            onAdjustWords?.(words, newWords);
          }
          return newWords;
        } catch (e) {
          onError?.(e);
          return words;
        }
      });
      return newId;
    },
    [captionWords]
  );

  const deleteWord = useCallback(
    (id: CaptionWord["id"], onSuccess?: () => void, onError?: (reason: unknown) => void) => {
      setCaptionWords((oldWords) => {
        try {
          const deletedWord = oldWords?.find((word) => word.id === id);
          const newWords = oldWords?.filter((word) => word.id !== id);

          // Update phrase text and timestamps to match
          if (deletedWord?.phraseId != null) {
            setCaptionPhrases((oldPhrases) => {
              try {
                const newPhrases =
                  oldPhrases?.map((phrase) => {
                    if (phrase.id === deletedWord.phraseId) {
                      const phraseWords = newWords?.filter((word) => word.phraseId === phrase.id);
                      return {
                        ...phrase,
                        text: phraseWords?.map((word) => word.text).join(" ") ?? "",
                        startTime:
                          phrase.startTime === deletedWord.startTime
                            ? phraseWords?.[0]?.startTime ?? phrase.startTime
                            : phrase.startTime,
                        endTime:
                          phrase.endTime === deletedWord.endTime
                            ? phraseWords?.[phraseWords.length - 1]?.endTime ?? phrase.endTime
                            : phrase.endTime,
                      };
                    } else {
                      return phrase;
                    }
                  }) ?? null;
                onSuccess?.();
                return newPhrases;
              } catch (e) {
                onError?.(e);
                setCaptionWords(oldWords);
                return oldPhrases;
              }
            });
          } else {
            onSuccess?.();
          }
          return newWords ?? null;
        } catch (e) {
          onError?.(e);
          return oldWords;
        }
      });
    },
    []
  );
  const updateWord = useCallback((id: CaptionWord["id"], updateData: CaptionWordUpdateData) => {
    setCaptionWords((words) => {
      if (!words) {
        return null;
      }

      let phraseId: string | null = null;
      const newWords = words.map((word) => {
        if (word.id === id) {
          phraseId = word.phraseId ?? null;
          return {
            ...word,
            ...updateData,
          };
        } else {
          return word;
        }
      });

      // Update phrase text to match
      if (phraseId) {
        updatePhrase(phraseId, {
          text: newWords
            .filter((word) => word.phraseId === phraseId)
            .map((word) => word.text)
            .join(" "),
        });
      }

      return newWords;
    });
  }, []);

  /**
   * Replace a phrase with new words, and reflect changes into the list of words.
   *
   * @param phrase - The updated phrase to replace.
   * @param newWords - The new words to replace the existing phrase words with.
   * @see replacePhraseWords
   */
  const replacePhrase = (
    phrase: CaptionPhrase,
    newWords: Pick<CaptionWord, "text" | "startTime" | "endTime">[]
  ) => {
    const firstNewId = captionWords?.reduce((id, word) => Math.max(id, word.id + 1), 1) ?? 1;
    setCaptionWords((words) => {
      const updatedWords = replacePhraseWords(phrase.id, words ?? [], newWords, firstNewId);
      setCaptionPhrases((phrases) => {
        if (!phrases) {
          return [phrase];
        }
        let found = false;
        const newPhrases = phrases.map((p) => {
          if (p.id === phrase.id) {
            found = true;
            return phrase;
          } else {
            return p;
          }
        });
        if (!found) {
          newPhrases.push(phrase);
        }
        return newPhrases.sort((a, b) => a.startTime - b.startTime);
      });
      return updatedWords;
    });
  };

  return {
    words: captionWords,
    phrases: captionPhrases,
    phraseAudios: captionPhraseAudios,
    setCaptions: setCaptionWords,
    setPhrases: setCaptionPhrases,
    setPhraseAudios: setCaptionPhraseAudios,
    replacePhrase,
    updatePhrase,
    generateFromTranscript,
    restoreFromCaptionsEntity,
    updateKeywordFlagFromTranscript,
    updateKeywordSettings,
    updateWord,
    addEmptyWord,
    deleteWord,
    areGenerated: captionWords !== null,
  };
}
