import type {
  ImageOverlay,
  OverlayLayout as ImageOverlayLayout,
} from "@shared/generated/typescript/aiEdit/models/overlays/imageOverlay/ImageOverlay";
import type { PagOverlay } from "@shared/generated/typescript/aiEdit/models/overlays/pagOverlay/PagOverlay";
import invariant from "tiny-invariant";

import { RenderEffect } from "~/modules/command-list/CommandList.types";
import { EDLWrapMode } from "~/modules/command-list/EDL.types";

type ImageOverlayLayoutType = NonNullable<ImageOverlayLayout["type"]> extends {
  oneofKind: infer K;
}
  ? Extract<K, string>
  : never;

const imageOverlayLayouts: Record<ImageOverlayLayoutType, (imageAssetUrl: string) => RenderEffect> =
  {
    bottomHalf: (imageAssetUrl: string) => ({
      renderEffectType: "pag-sequence",
      sequenceItems: [
        {
          assetId: "simple_thbr_frame_2_IN",
          timing: "playOnceIntrinsic",
          slots: [
            {
              type: "image",
              assetUrl: imageAssetUrl,
              layerIndices: [0],
            },
            {
              type: "source-video",
              layerIndices: [1],
            },
          ],
        },
        {
          assetId: "simple_thbr_frame_2_DURATION",
          timing: "playOnceScaleToFit",
          slots: [
            {
              type: "image",
              layerIndices: [0],
              assetUrl: imageAssetUrl,
            },
            {
              type: "source-video",
              layerIndices: [1],
            },
          ],
        },
        {
          assetId: "simple_thbr_frame_2_OUT",
          timing: "playOnceIntrinsic",
          slots: [
            {
              type: "image",
              layerIndices: [0],
              assetUrl: imageAssetUrl,
            },
            {
              type: "source-video",
              layerIndices: [1],
            },
          ],
        },
      ],
    }),
    topHalf: (imageAssetUrl: string) => ({
      renderEffectType: "pag-sequence",
      sequenceItems: [
        {
          assetId: "simple_thbr_frame_1_IN",
          timing: "playOnceIntrinsic",
          slots: [
            {
              type: "image",
              assetUrl: imageAssetUrl,
              layerIndices: [0],
            },
            {
              type: "source-video",
              layerIndices: [1],
            },
          ],
        },
        {
          assetId: "simple_thbr_frame_1_DURATION",
          timing: "playOnceScaleToFit",
          slots: [
            {
              type: "image",
              layerIndices: [0],
              assetUrl: imageAssetUrl,
            },
            {
              type: "source-video",
              layerIndices: [1],
            },
          ],
        },
        {
          assetId: "simple_thbr_frame_1_OUT",
          timing: "playOnceIntrinsic",
          slots: [
            {
              type: "image",
              layerIndices: [0],
              assetUrl: imageAssetUrl,
            },
            {
              type: "source-video",
              layerIndices: [1],
            },
          ],
        },
      ],
    }),
    fill: (imageAssetUrl: string) => ({
      renderEffectType: "image",
      assetId: imageAssetUrl,
      positionType: "b-roll",
      position: { x: 0.5, y: 0.5 },
    }),
  };

/**
 * Generates a render effect configuration for an image overlay.
 *
 * @param {ImageOverlay} imageOverlay - The image overlay configuration containing source and layout information.
 * @param {Object} [options] - Optional settings.
 * @param {boolean} [options.isBackground=false] - Determines if the render effect is for a background.
 *
 * @returns {Object} An object containing:
 *  - renderEffect: The configured render effect (either "scene-background" or based on layout type).
 *  - wrapMode: The wrap mode for the render effect, if applicable.
 *  - externalAsset: URL of the image asset being used.
 *
 * @throws {Error} Will throw if imageAssetUrl or layoutType are missing.
 */
export function getImageOverlayRenderEffect(
  imageOverlay: ImageOverlay,
  options: { isBackground: boolean } = { isBackground: false }
) {
  const { source, layout } = imageOverlay;
  const imageAssetUrl = source?.type?.oneofKind === "aiImage" ? source.type.aiImage.assetUrl : "";
  const layoutType = layout?.type?.oneofKind;

  invariant(imageAssetUrl, "imageAssetUrl is required to create ImageOverlay effect");
  invariant(layoutType, "layoutType is required to create ImageOverlay effect");

  let renderEffect: RenderEffect;
  let wrapMode: EDLWrapMode | undefined;
  if (options.isBackground) {
    renderEffect = {
      renderEffectType: "scene-background",
      assetId: imageAssetUrl,
      cutout: true,
    };
    wrapMode = "repeat";
  } else {
    renderEffect = imageOverlayLayouts[layoutType](imageAssetUrl);
  }

  return {
    renderEffect,
    wrapMode,
    externalAsset: imageAssetUrl,
  };
}

/**
 * Generates a render effect configuration for a PagOverlay.
 *
 * @param {PagOverlay} pagOverlay - The PagOverlay configuration containing pagItems, imageInserts, and textInserts.
 * @param {Object} [options] - Optional settings.
 * @param {boolean} [options.isBackground=false] - Determines if the render effect is for a background.
 * @returns {Object} An object containing:
 *  - renderEffect: The configured render effect of type "pag-sequence".
 *  - externalAssets: An array of URLs for the image assets used in the render effect.
 * @throws {Error} Will throw if required properties like assetUrl, assetId, or timingType are missing.
 */
export function getPagOverlayRenderEffect(
  pagOverlay: PagOverlay,
  options: {
    isBackground: boolean;
  } = {
    isBackground: false,
  }
): {
  renderEffect: Extract<RenderEffect, { renderEffectType: "pag-sequence" }>;
  externalAssets: string[];
} {
  const { pagItems, imageInserts, textInserts } = pagOverlay;

  const imageReplacements = imageInserts.map(({ slotId, content }) => {
    const contentType = content?.type.oneofKind;
    switch (contentType) {
      case "aiImage": {
        const aiImage = content?.type?.aiImage;
        invariant(
          aiImage?.assetUrl,
          "assetUrl is required to create image replacements for PagOverlay effect"
        );

        return {
          id: slotId,
          assetType: "image",
          assetUrl: aiImage.assetUrl,
        };
      }
      case "baseVideo": {
        return {
          id: slotId,
          assetType: "source-video",
        };
      }
      default: {
        throw new Error("Unsupported image insert content type");
      }
    }
  });

  const textReplacements = textInserts.map(({ slotId, content }) => {
    const contentType = content?.type.oneofKind;
    switch (contentType) {
      case "aiText": {
        const aiText = content?.type?.aiText;
        return {
          id: slotId,
          assetType: "text",
          text: aiText?.text,
        };
      }
      default: {
        throw new Error("Unsupported text insert content type");
      }
    }
  });

  const pagItemsWithSlots = pagItems.map((pagItem) => {
    const { asset, timing, imageSlots, textSlots } = pagItem;

    const assetId = asset?.id;
    invariant(assetId, "pagItem assetId is required to create PagOverlay effect");

    const slots: { id: string; layerIndices: number[] }[] = [];
    [...imageSlots, ...textSlots].forEach((slot) => {
      const layerIndices = slot.layerReferences
        .map((layerReference) => {
          if (layerReference.type.oneofKind === "indexSelector") {
            return layerReference.type.indexSelector.index;
          }
          return undefined;
        })
        .filter((index): index is number => index !== undefined);
      slots.push({
        id: slot.id,
        layerIndices: layerIndices,
      });
    });

    const timingType = timing?.type.oneofKind;
    invariant(timingType, "timingType is required to create PagOverlay effect");

    return {
      pagAsset: assetId,
      slots,
      timing: timingType,
    };
  });

  type Slot =
    | {
        type: "image";
        assetUrl: string;
        layerIndices: number[];
      }
    | {
        type: "source-video";
        layerIndices: number[];
      };

  const sequenceItems = pagItemsWithSlots.map((pagItem) => {
    const slots = pagItem.slots
      .map((slot) => {
        const imageReplacement = imageReplacements.find(
          (replacement) => replacement?.id === slot.id
        );
        const textReplacement = textReplacements.find((replacement) => replacement?.id === slot.id);

        if (imageReplacement?.assetType === "image") {
          return {
            type: "image",
            assetUrl: imageReplacement.assetUrl,
            layerIndices: slot.layerIndices,
          };
        }
        if (imageReplacement?.assetType === "source-video") {
          return {
            type: "source-video",
            layerIndices: slot.layerIndices,
          };
        }
        if (textReplacement) {
          return {
            type: "text",
            layerIndices: slot.layerIndices,
            text: textReplacement.text,
          };
        }
      })
      .filter((slot): slot is Slot => slot !== undefined);

    return {
      assetId: pagItem.pagAsset,
      slots,
      timing: pagItem.timing ?? "",
    };
  });

  const imageUrls = sequenceItems.flatMap((item) =>
    item.slots.filter((slot) => slot.type === "image").map((slot) => slot.assetUrl)
  );

  return {
    renderEffect: {
      renderEffectType: "pag-sequence",
      sequenceItems,
      isBackground: options.isBackground,
    },
    externalAssets: imageUrls,
  };
}
