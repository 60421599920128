import { useCallback, useRef, useState } from "react";

import { useBackendServicesClient } from "~/context/BackendServicesContext";

import { getAudioExtractionJobStatus, startAudioExtractionJob } from "../services/AudioExtraction";
import {
  getTranscriptionJobStatus,
  startTranscriptionJob,
  Transcript,
} from "../services/Transcription";
import Lock from "../utils/Lock";

import { useProjectPersistence } from "./useProjectPersistence";

// global transcription lock
const transcriptionLock = new Lock();

export interface TranscriptOptions {
  languageCode: string;
  targetLanguageCode?: string;
  translateAudioEnabled?: boolean;
  isShortsJob?: boolean;
}

export enum TranscriptStage {
  idle,
  extractingAudio,
  generatingTranscript,
  finished,
  error,
  canceled,
}

export function useTranscriptGenerator(
  fileId: string,
  projectId: string,
  skipProjectUpdate = false
) {
  const { updateProject } = useProjectPersistence(projectId);
  const [transcript, setTranscript] = useState<Transcript | null>(null);
  const [transcriptionJobId, setTranscriptionJobId] = useState<string | null>(null);
  const [translateId, setTranslateId] = useState<string | null>(null);
  const [stage, setStage] = useState(TranscriptStage.idle);
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const abortController = useRef(new AbortController());
  const client = useBackendServicesClient();

  const handleTranscriptionError = (error: unknown) => {
    transcriptionLock.release();
    handleError(error);
  };

  const handleError = (error: unknown) => {
    console.error(error);
    if (abortController.current.signal.aborted) {
      setStage(TranscriptStage.canceled);
    } else {
      setStage(TranscriptStage.error);
      setErrorMessage(error instanceof Error ? error.message : `${error}`);
    }
  };
  const cancelTranscript = useCallback(() => {
    if (abortController.current) {
      abortController.current.abort();
    }
    setStage((stage) => (stage === TranscriptStage.error ? TranscriptStage.canceled : stage));
  }, []);

  const updateTranscriptionJobStatus = useCallback(
    (transcriptionJob: string, isShortsJob?: boolean) => {
      getTranscriptionJobStatus(
        client,
        transcriptionJob,
        abortController.current.signal,
        isShortsJob
      )
        .then(({ job }) => {
          if (job.translateId && translateId !== job.translateId) {
            setTranslateId(job.translateId);
          }
          if (job.status === "started") {
            setTimeout(() => updateTranscriptionJobStatus(transcriptionJob, isShortsJob), 500);
          } else if (job.status === "progress" || job.status === "processing") {
            setProgress(job.progress * 0.5 + 50);
            setTimeout(() => updateTranscriptionJobStatus(transcriptionJob, isShortsJob), 2 * 1000);
          } else if (job.status === "finished" && job.transcript) {
            setTranscript(job.transcripts[0]);
            setStage(TranscriptStage.finished);
            transcriptionLock.release();
          } else if (job.status === "error") {
            setErrorMessage(job.errorMessage ?? "An error occurred while processing");
            setStage(TranscriptStage.error);
            transcriptionLock.release();
          } else {
            console.error(job);
            transcriptionLock.release();
          }
        })
        .catch(handleTranscriptionError);
    },
    []
  );

  const startAudioTranscription = useCallback((audioFileId: string, options: TranscriptOptions) => {
    setProgress(50);
    setStage(TranscriptStage.generatingTranscript);
    transcriptionLock
      .acquire()
      .then(() => {
        return startTranscriptionJob(client, {
          inputFileId: audioFileId,
          languageCode: options.languageCode,
          targetLanguageCode:
            options.targetLanguageCode === "none" ? undefined : options.targetLanguageCode,
          isShortsJob: options.isShortsJob,
        });
      })
      .then((transcriptionJob) => {
        setTranscriptionJobId(transcriptionJob.jobId);
        setTimeout(() => updateTranscriptionJobStatus(transcriptionJob.jobId, options.isShortsJob));
      })
      .catch(handleTranscriptionError);
  }, []);

  const updateAudioExtractionJobStatus = useCallback(
    (audioExtractionJobId: string, options: TranscriptOptions) => {
      getAudioExtractionJobStatus(client, audioExtractionJobId, abortController.current.signal)
        .then(({ job }) => {
          if (job.status === "started") {
            setTimeout(() => updateAudioExtractionJobStatus(audioExtractionJobId, options), 500);
          } else if (job.status === "progress") {
            setProgress(job.progress * 0.5);
            setTimeout(() => updateAudioExtractionJobStatus(audioExtractionJobId, options), 500);
          } else if (job.status === "finished") {
            if (!skipProjectUpdate) {
              updateProject({
                sourceFileAudioId: job.outputFileId,
              });
            }
            startAudioTranscription(job.outputFileId, options);
          } else if (job.status === "error") {
            setErrorMessage(job.errorMessage ?? "An error occurred while processing");
            setStage(TranscriptStage.error);
          } else {
            console.error(job);
          }
        })
        .catch(handleError);
    },
    []
  );

  const generateTranscript = useCallback((options: TranscriptOptions, overrideFileId?: string) => {
    if (
      ![
        TranscriptStage.idle,
        TranscriptStage.finished,
        TranscriptStage.error,
        TranscriptStage.canceled,
      ].includes(stage)
    ) {
      return;
    }
    setStage(TranscriptStage.extractingAudio);
    setProgress(0);
    abortController.current = new AbortController();

    startAudioExtractionJob(client, { inputFileId: overrideFileId ?? fileId })
      .then((audioExtractionJob) => {
        setTimeout(() => updateAudioExtractionJobStatus(audioExtractionJob.jobId, options));
      })
      .catch(handleError);
  }, []);

  return {
    transcript,
    transcriptionJobId,
    translateId,
    stage,
    generateTranscript,
    setTranscript,
    setTranscriptionJobId,
    progress,
    errorMessage,
    cancelTranscript,
  };
}
