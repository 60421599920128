import { ButtonHTMLAttributes } from "react";

import AppleIcon from "../icons/Apple";
import GoogleIcon from "../icons/Google";
import MicrosoftIcon from "../icons/Microsoft";

import * as S from "./SignIn.styles";

export const AppleButton = ({
  children = "Continue with Apple",
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <S.Apple variant="tertiary" {...props}>
      <AppleIcon />
      {children}
    </S.Apple>
  );
};

export const GoogleButton = ({
  children = "Continue with Google",
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <S.Google variant="tertiary" {...props}>
      <GoogleIcon />
      {children}
    </S.Google>
  );
};

export const MicrosoftButton = ({
  children = "Continue with Microsoft",
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <S.Google variant="tertiary" {...props}>
      <MicrosoftIcon />
      {children}
    </S.Google>
  );
};
