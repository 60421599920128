export const SECONDS_IN_MINUTE = 60;
export const SECONDS_IN_HOUR = 3600;
export const MILLISECONDS_IN_SECOND = 1000;
export const MICROSECONDS_IN_SECOND = 1e6;

/**
 * Formats a timestamp for SRT files, in the format HH:MM:SS,mmm
 * @param time - the timestamp in seconds
 * @returns the formatted string representing the timestamp
 */
export function formatTimestampSrt(time: number): string {
  const hours = Math.floor(time / SECONDS_IN_HOUR)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((time % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE)
    .toString()
    .padStart(2, "0");
  const seconds = Math.floor(time % SECONDS_IN_MINUTE)
    .toString()
    .padStart(2, "0");
  const milliseconds = Math.floor((time % 1) * MILLISECONDS_IN_SECOND)
    .toString()
    .padStart(3, "0");

  return `${hours}:${minutes}:${seconds},${milliseconds}`;
}

/**
 * Formats a timestamp in seconds as MM:SS
 *
 * @param time - the timestamp in seconds
 * @param withHundredths - if true show hundredths of a second
 * @returns the formatted string representing the timestamp
 */
function formatTimeMinutes(time: number, withHundredths?: boolean): string {
  const minutes = Math.floor(time / 60)
    .toString()
    .padStart(2, "0");
  const seconds = Math.floor(time % SECONDS_IN_MINUTE)
    .toString()
    .toString()
    .padStart(2, "0");

  if (!withHundredths) {
    return `${minutes}:${seconds}`;
  }

  const hundredths = Math.floor(100 * (time % 1))
    .toString()
    .padStart(2, "0");

  return `${minutes}:${seconds}.${hundredths}`;
}

/**
 * Formats a timestamp in seconds as HH:MM:SS
 *
 * @param time - the timestamp in seconds
 * @param withHundredths - if true show hundredths of a second
 * @returns the formatted string representing the timestamp
 */
function formatTimeHours(time: number, withHundredths?: boolean): string {
  const hours = Math.floor(time / SECONDS_IN_HOUR)
    .toString()
    .padStart(2, "0");
  return `${hours}:${formatTimeMinutes(time % SECONDS_IN_HOUR, withHundredths)}`;
}

/**
 * Formats a timestamp in seconds as [HH:]MM:SS
 *
 * @param time - the timestamp in seconds
 * @param showHours - if true, the format will add hours to the format
 * @param withHundredths - if true, also display hundredths of a second
 * @returns the formatted string representing the timestamp
 */
export function formatTimestamp(
  time: number,
  showHours?: boolean,
  withHundredths?: boolean
): string {
  if (showHours === undefined) {
    showHours = time >= SECONDS_IN_HOUR;
  }
  return showHours
    ? formatTimeHours(time, withHundredths)
    : formatTimeMinutes(time, withHundredths);
}

/**
 * Formats a timestamp in seconds as readable text for accessibility features
 *
 * @param time - the timestamp in seconds
 * @returns the formatted string representing the timestamp
 */
export function formatTimestampReadable(time: number): string {
  time = Math.floor(time);
  const labelsSingular = ["hour", "minute", "second"];
  const labelsPlural = ["hours", "minutes", "seconds"];
  const labelsSeparator = [", ", ", ", " and "];
  const values = [
    Math.floor(time / SECONDS_IN_HOUR),
    Math.floor((time % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE),
    Math.floor(time % SECONDS_IN_MINUTE),
  ];
  return values.reduce((text, value, index) => {
    if (text === "" && value === 0 && index < values.length - 1) {
      return text;
    }
    const newText = `${value} ${value === 1 ? labelsSingular[index] : labelsPlural[index]}`;
    if (text === "") {
      return newText;
    }
    return `${text}${labelsSeparator[index]}${newText}`;
  }, "");
}

/**
 * Formats a timestamp for AI Shorts (under 2 minutes)
 *
 * @param time - the timestamp in seconds
 * @returns the formatted string representing the timestamp as M:SS
 */
export function formatTimestampShorts(duration: number): string {
  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration % 60);
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
}

/** Convert "00:00" to "0:00". */
export function stripExtraLeadingZero(timeString: string): string {
  if (timeString.startsWith("00")) {
    return timeString.slice(1);
  } else {
    return timeString;
  }
}
