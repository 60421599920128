import { useCallback, useEffect, useState } from "react";

import { useBackendServicesClient } from "~/context/BackendServicesContext";
import {
  getVideoOverlayJobStatus,
  startVideoOverlayJob,
} from "~/modules/project/services/VideoOverlayJob";

const POLLING_INTERVAL = 1000;
export const useTrimVideoFile = (sourceFileId: string, startTime: number, endTime: number) => {
  const [trimmedVideoFileId, setTrimmedVideoFileId] = useState<string | undefined>();
  const [jobStatus, setJobStatus] = useState<
    "idle" | "started" | "progress" | "finished" | "error"
  >("idle");

  const client = useBackendServicesClient();

  const handleError = (e: Error) => {
    console.error(e);
    setJobStatus("error");
  };

  // export status polling
  const updateJobStatus = useCallback((jobId: string) => {
    getVideoOverlayJobStatus(client, jobId)
      .then(({ job }) => {
        if (job.status == "finished") {
          setTrimmedVideoFileId(job.outputFileId);
          setJobStatus("finished");
        } else if (job.status === "progress") {
          setTimeout(() => updateJobStatus(jobId), POLLING_INTERVAL);
          setJobStatus("progress");
        } else if (job.status === "error") {
          handleError(new Error("trim export error"));
        }
      })
      .catch(handleError);
  }, []);

  // use export to create a trimmed version video file
  useEffect(() => {
    startVideoOverlayJob(client, {
      inputFileId: sourceFileId,
      trimVideoTimeInterval: [{ startTime, endTime }],
    })
      .then(({ jobId }) => {
        updateJobStatus(jobId);
      })
      .catch(handleError);
    setJobStatus("started");
  }, []);

  return {
    trimmedVideoFileId,
    trimmedVideoStatus: jobStatus,
  };
};
