import adsStudio from "./ads-studio.json";
import aiEditor from "./ai-editor.json";
import apiDashboard from "./api-dashboard.json";
import common from "./common.json";
import languages from "./languages.json";
import profile from "./profile.json";
import projects from "./projects.json";
import projectView from "./projectView.json";
import socialStudio from "./social-studio.json";
import subscription from "./subscription.json";

export default {
  common,
  languages,
  projects,
  projectView,
  profile,
  subscription,
  "ai-editor": aiEditor,
  "api-dashboard": apiDashboard,
  "social-studio": socialStudio,
  "ads-studio": adsStudio,
};
