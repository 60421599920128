import { createStore } from "zustand";
import { combine } from "zustand/middleware";

import { ProjectEditorType } from "~/database/database.types";
import { createUseStoreWithSelector } from "~/utils/zustand-store";

interface EditorStoreData {
  editorType: ProjectEditorType;
}

export const createEditorStore = () => {
  return createStore(
    combine(
      {
        // default to timeline since that is the current behavior
        editorType: "timeline",
      } as EditorStoreData,
      (set, _get) => ({
        setEditorType: (editorType: ProjectEditorType) => set({ editorType }),
      })
    )
  );
};

const editorStore = createEditorStore();

export const getEditorStore = () => editorStore.getState();

export const useEditorStore = createUseStoreWithSelector(editorStore);
