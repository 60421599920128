import { useFeatureFlag } from "feature-flags";
import { useState } from "react";

import { useBackendServicesClient } from "~/context/BackendServicesContext";
import { getUploadedFileInfo } from "~/services/UploadFile";

import { DetectFacesJobState } from "../services/DetectFaces";
import type { ProjectFaceData } from "../utils/faceData/face-data.types";

import { useFaceDetection } from "./useFaceDetection";
import { useFacesBackend } from "./useFacesBackend";

export function useFaces(): {
  detectFacesState: DetectFacesJobState & { message?: string };
  startDetectFaces: (
    sourceFileId: string,
    onFinished: (projectFaceData: ProjectFaceData) => void
  ) => Promise<void> | void;
} {
  const clientSide = useFeatureFlag("client_side_face_detection");
  const client = useBackendServicesClient();
  const [clientJobState, setClientJobState] = useState<DetectFacesJobState & { message?: string }>({
    status: "idle",
  });
  const backendFaces = useFacesBackend();
  const clientFaces = useFaceDetection({
    onError: (error) => {
      setClientJobState({
        status: "error",
        message: error.message,
      });
    },
    onProgress: () => {
      setClientJobState({ status: "progress" });
    },
  });

  if (!clientSide) {
    return backendFaces;
  }

  const startDetectFaces = async (
    sourceFileId: string,
    onFinished: (projectFaceData: ProjectFaceData) => void
  ): Promise<void> => {
    setClientJobState({
      status: "started",
    });
    try {
      const fileInfo = await getUploadedFileInfo(client, sourceFileId);
      if (!fileInfo.url) {
        setClientJobState({
          status: "error",
          message: "Video URL not found for the given file id",
        });
        return;
      }
      clientFaces.startDetection(fileInfo.url, {
        fps: fileInfo.fileMetadata?.fps,
        onFinalized: (projectFaceData) => {
          setClientJobState({
            status: "finished",
            result: {
              fps: projectFaceData.fps,
              frames: projectFaceData.faceFrames.map((faces, id) => ({
                id,
                faces: faces,
              })),
            },
          });
          onFinished(projectFaceData);
        },
      });
    } catch (error) {
      setClientJobState({
        status: "error",
        message: error instanceof Error ? error.message : `${error}`,
      });
    }
  };

  return {
    startDetectFaces,
    detectFacesState: clientJobState,
  };
}
