import { DevLogger } from "dev-logger";

import {
  getLineBackgroundAnimationParameters,
  getWordBackgroundAnimationParameters,
} from "./animationUtils";
import { CaptionAnimationModifiers, AnimationTarget } from "./animationUtils.types";
import {
  applyMovementAutoMotion,
  applyScaleAutoMotion,
  calculateMovementAutoMotionKnots,
  calculateScaleAutoMotionKeyPoints,
} from "./autoMotion";
import {
  applyActiveAnimationParameters,
  calcActiveLineWidth,
  calcActualLineWidth,
  capitalizePageWords,
  drawActiveBoxes,
  drawOuterBox,
  drawWords,
  getActiveWordBackgroundAnimationStage,
  getAllPageAnimationTimes,
  getBackgroundStartOffsetFromMiddle,
  getDynamicLayout,
  getLineAnimationParameters,
  getLineAnimationStage,
  getLineBackgroundAnimationStage,
  getLineFontFactor,
  getPageAnimationParameters,
  getPageAnimationStage,
  getPageStylePresetOverrides,
  getStartingPoint,
  getStartOffsetFromMiddle,
  getUpdatedTextStyle,
  getWordAnimationParameters,
  getWordAnimationStage,
  getWordColor,
  getWordColors,
  isLineActive,
  isWordActive,
  loadCaptionFont,
  returnOrderedPage,
  shouldApplyLineAnimationToBox,
  shouldShowFocusedLineAsActive,
  shouldSkipLine,
  shouldSkipWord,
  wrapLines,
} from "./captionDrawing";
import {
  AnyCanvasRenderingContext2D,
  BoxDrawData,
  CaptionStyle,
  CaptionTextStyle,
  CaptionWordColors,
  PositionFactor,
  WordBox,
  WordDrawData,
  ScenePositionFactor,
} from "./captionDrawing.types";
import {
  BasicMeasurements,
  FontBackend,
  LineInfo,
  MovementAutoMotionData,
  OuterBoxInfo,
  OuterBoxItem,
  PAGBackend,
  PageLinesWithInfo,
  ScaleAutoMotionData,
  WordInfo,
} from "./captionEngine.types";
import { Line, Page } from "./captions.types";
import { CaptionStylePreset, EmojiPosition, LayoutAlignment } from "./captionStyle.types";
import { EmojiLayer } from "./emojiLayer";
import { EmojiFramePosition, EmojiPage } from "./emojiLayer.types";
import { getWordFontMetrics, measureText } from "./textDrawingUtils";
import { calculateSplineControlPoints } from "./utils/bezier";
import {
  DEFAULT_EXTRA_SIZE,
  BOX_MARGIN,
  BASE_EMOJI_SPACING,
  LANDSCAPE_MAX_WIDTH_FACTOR,
  DEFAULT_LINE_FIT_WRAP_WIDTH_CONSTRAINT,
  SUPER_SIZE_FACTOR,
  SUPER_SIZE_NOBREAK_FACTOR,
  BOX_VERTICAL_PADDING,
  BOX_HORIZONTAL_PADDING,
  DEFAULT_EMOJI_SIZE,
  EMOJI_SIZE_TO_PIXELS_FACTOR,
  MAX_SIZE_CALCULATION_ATTEMPTS,
} from "./utils/captions/constants";
import { getSynchronizedFrameTimes } from "./utils/getSynchronizedFrameTimes";
import { preloadAnimations } from "./utils/pagUtils";

const logger = new DevLogger("[caption-painter]");

export class CaptionPainter {
  private _timestamp: number = 0;
  private _fontBackend: FontBackend | null = null;
  private _width: number = 0;
  private _height: number = 0;
  private _scale: PositionFactor = { x: 1, y: 1 };

  private _pages: Page[] = [];
  private _linesWithInfo: PageLinesWithInfo[] = [];
  private _countryCode: string = "";
  private _isLandscape: boolean = false;
  private _stylePreset: CaptionStylePreset | null = null;
  private _style: CaptionStyle | null = null;
  private _scenePositionFactors: ScenePositionFactor[] = [];
  private _enableSuperSize: boolean = true;
  private _overallBoxes: OuterBoxItem[] = [];
  private _defaultOverallBox: OuterBoxInfo | null = null;
  private _currentOverallBox: OuterBoxInfo | null = null;
  private _currentPageIndex: number = -1;
  private _basicMeasurements: BasicMeasurements | null = null;
  private _wordColors: CaptionWordColors | null = null;
  private _scaleAutoMotionData: ScaleAutoMotionData | null = null;
  private _movementAutoMotionData: MovementAutoMotionData | null = null;
  private _textStyle: CaptionTextStyle | null = null;

  // TODO: remove dependency of emojiLayer on captionLayer
  private _emojiLayer: EmojiLayer | null = null;
  private _pagBackend: PAGBackend | null = null;

  public get currentOverallBox() {
    return this._currentOverallBox;
  }

  public async init(
    fontBackend: FontBackend,
    pagBackend: PAGBackend,
    pages: Page[],
    countryCode: string,
    isLandscape: boolean,
    width: number,
    height: number,
    scale: PositionFactor
  ) {
    this._fontBackend = fontBackend;
    this._pagBackend = pagBackend;

    this._pages = pages;
    this._countryCode = countryCode;
    this._isLandscape = isLandscape;
    this._currentPageIndex = this._findCurrentPage();
    this._width = width;
    this._height = height;
    this._scale = scale;
    await this._setupEmojiLayer();
  }

  public setStyle(
    stylePreset: CaptionStylePreset,
    style: CaptionStyle,
    enableSuperSize: boolean = true
  ) {
    this._stylePreset = stylePreset;
    this._style = style;
    this._enableSuperSize = enableSuperSize;
  }

  public setPositionFactors(positionFactors: ScenePositionFactor[]) {
    this._scenePositionFactors = positionFactors;
  }

  public getCaptionTimestamps(fps: number) {
    const stylePreset = this._stylePreset;
    if (!stylePreset) {
      return [];
    }

    const captionTimestamps = this._pages.flatMap((page) => {
      const timesList = getAllPageAnimationTimes(page, stylePreset);
      const hasEmojis = page.lines.some((line) => line.words.some((word) => !!word.emoji));
      if (
        stylePreset.movementAutoMotion.enabled ||
        stylePreset.scaleAutoMotion.enabled ||
        hasEmojis
      ) {
        // When any auto-motion is enabled, the caption is animated throughout the whole page's
        // duration, so we need to interpolate between the first and last animation times.
        // This is also the case for animated emojis.
        return getSynchronizedFrameTimes(
          timesList[0].startAnimation.startTime,
          timesList[timesList.length - 1].endAnimation.endTime,
          fps
        );
      } else {
        return timesList.flatMap((times) => [
          ...getSynchronizedFrameTimes(
            times.startAnimation.startTime,
            times.startAnimation.endTime,
            fps
          ),
          ...getSynchronizedFrameTimes(
            times.endAnimation.startTime,
            times.endAnimation.endTime,
            fps
          ),
        ]);
      }
    });
    return captionTimestamps;
  }

  public async autoFitToWidthRange(
    widthRange: {
      min: number;
      target: number;
      max: number;
    },
    context: AnyCanvasRenderingContext2D
  ): Promise<number> {
    if (!this._pages.length || !this._style || !this._stylePreset) {
      logger.error("Cannot auto-fit to width without pages, style, style preset, or context");
      throw new Error("Cannot auto-fit to width without pages, style, style preset, or context");
    }
    let captionWidth = 0;
    let attempts = 0;
    const baseStyle = this._style;
    const basePages = this._pages;
    let sizeFactor = this._style.sizeFactor;
    try {
      // Filters out pages with dynamic placement, as they are already automatically adjusted
      // to occupy an arbitrary shape
      this._pages = basePages.filter((page) => !page.dynamicPlacement);
      while (captionWidth < widthRange.min || captionWidth > widthRange.max) {
        if (captionWidth) {
          sizeFactor = (sizeFactor * widthRange.target) / captionWidth;
        }
        if (attempts > MAX_SIZE_CALCULATION_ATTEMPTS) {
          logger.warn("Maximum number of attempts to calculate the default caption size exceeded");
          break;
        }
        this._style = {
          ...baseStyle,
          sizeFactor,
        };
        await this.precalculateParameters(context);
        captionWidth = this._currentOverallBox?.width ?? 0;
        attempts += 1;
      }
      return sizeFactor;
    } finally {
      this._pages = basePages;
    }
  }

  public async precalculateParameters(context: AnyCanvasRenderingContext2D) {
    this._movementAutoMotionData = null;
    this._scaleAutoMotionData = null;
    this._wordColors = null;
    this._linesWithInfo = [];
    this._textStyle = null;
    await this._calculateBasicMeasures(context);
    if (
      !this._stylePreset ||
      !this._style ||
      !this._width ||
      !this._height ||
      !this._basicMeasurements
    ) {
      // Only sets the overall box to null if we know it doesn't exist
      // Since this is an async function, clearing it before that would
      // cause the UI to think no box existed during the short window of
      // time between setting it to null and reassigining it to the new
      // box dimensions.
      this._currentOverallBox = null;
      this._defaultOverallBox = null;
      this._overallBoxes = [];
      return;
    }
    const stylePreset = this._stylePreset;
    const hasBackground = Boolean(
      stylePreset.colorApplicationPageEnabled || stylePreset.colorApplicationLineEnabled
    );

    this._wordColors = getWordColors(stylePreset, this._style, hasBackground);

    const { insets, baseLineHeight } = this._basicMeasurements;
    const horizontalPaddingSize = 2 * insets.x;
    const verticalPaddingSize = 2 * insets.y;
    const lineSpacing = stylePreset.lineSpacing * this._style.sizeFactor;

    let nonDynamicOuterBoxWidth = 0;
    let nonDynamicOuterBoxHeight = 0;
    let outerBoxWidth = 0;
    let outerBoxHeight = 0;
    const linesWithInfo: PageLinesWithInfo[] = [];
    for (let page of this._pages) {
      page = capitalizePageWords(
        page,
        this._stylePreset.capitalization,
        this._stylePreset.hidePunctuation
      );
      page = returnOrderedPage(page);
      if (page.lines.length === 0) {
        linesWithInfo.push({ lines: [] });
        continue;
      }

      const textLines = this._getLinesWithInfo(page.lines, context);
      const [innerBoxWidth, innerBoxHeight] = textLines.reduce(
        ([width, height], line) => [
          // innerBoxWidth
          Math.max(line.width, line.activeWidth, width),
          // innerBoxHeight
          height + line.fontFactor * baseLineHeight,
        ],
        [-Infinity, 0]
      );

      const currentBoxWidth = innerBoxWidth + horizontalPaddingSize;
      const currentBoxHeight = !stylePreset.isMultilineBox
        ? innerBoxHeight +
          verticalPaddingSize * textLines.length +
          lineSpacing * (textLines.length - 1)
        : innerBoxHeight + verticalPaddingSize;

      outerBoxWidth = Math.max(currentBoxWidth, outerBoxWidth);
      outerBoxHeight = Math.max(currentBoxHeight, outerBoxHeight);

      if (!page.dynamicPlacement) {
        nonDynamicOuterBoxWidth = Math.max(currentBoxWidth, nonDynamicOuterBoxWidth);
        nonDynamicOuterBoxHeight = Math.max(currentBoxHeight, nonDynamicOuterBoxHeight);
      }

      linesWithInfo.push({ lines: textLines });
    }
    this._linesWithInfo = linesWithInfo;

    const { x, y } = getStartingPoint(
      { width: this._width, height: this._height },
      { x: 0, y: 0 },
      this._style.positionFactor
    );
    const sceneStartingPoints = this._scenePositionFactors.map(
      ({ startTime, endTime, positionFactor }) => ({
        startTime,
        endTime,
        position: getStartingPoint(
          { width: this._width, height: this._height },
          { x: 0, y: 0 },
          positionFactor
        ),
      })
    );
    context.restore();

    const currentPage = this._pages[this._currentPageIndex];

    const maxTime = this._pages[this._pages.length - 1]?.endTime + 1;
    const startTime = this._pages[0]?.startTime ?? 0;

    if (this._stylePreset.movementAutoMotion.enabled) {
      const knots = calculateMovementAutoMotionKnots(
        startTime,
        maxTime,
        this._stylePreset.movementAutoMotion
      );
      const controlPoints = calculateSplineControlPoints(knots);
      this._movementAutoMotionData = { knots, controlPoints, maxTime };
    }

    if (this._stylePreset.scaleAutoMotion.enabled) {
      this._scaleAutoMotionData = {
        keyPoints: calculateScaleAutoMotionKeyPoints(
          startTime,
          maxTime,
          this._stylePreset.scaleAutoMotion
        ),
        maxTime,
      };
    }

    this._textStyle = getUpdatedTextStyle(
      this._stylePreset.stroke,
      this._stylePreset.shadow,
      this._stylePreset.glow,
      this._style
    );

    const activeBoxPadding = this._getActiveBoxPadding(this._style, this._textStyle);

    const extraHorizSpacing = insets.x ? 0 : activeBoxPadding.horizontal * 2;
    const extraVertSpacing = insets.y ? 0 : activeBoxPadding.vertical * 2;
    nonDynamicOuterBoxWidth += extraHorizSpacing;
    nonDynamicOuterBoxHeight += extraVertSpacing;
    outerBoxWidth += extraHorizSpacing;
    outerBoxHeight += extraVertSpacing;

    const defaultBox: OuterBoxInfo = {
      ...this._adjustBoxPositionToStayInBounds(
        x,
        y,
        nonDynamicOuterBoxWidth,
        nonDynamicOuterBoxHeight
      ),
      boxType: "captions",
      id: "default",
      width: nonDynamicOuterBoxWidth,
      height: nonDynamicOuterBoxHeight,
      rotation: this._style.rotation ?? currentPage?.rotation ?? 0,
      scale: this._style.sizeFactor,
    };

    // Sometimes pages and scene starting points are slightly misaligned, therefore
    // we need a small tolerance so that pages adjacent to scenes with dynamic placement
    // are not mistakenly considered dynamic
    const SCENE_PAGE_TOLERANCE = 0.1;

    this._defaultOverallBox = defaultBox;
    this._overallBoxes = sceneStartingPoints.map(({ startTime, endTime, position }) => {
      const hasDynPage = this._pages
        // Filters out pages that are not in the current scene
        .filter(
          (page) =>
            !(
              page.endTime - SCENE_PAGE_TOLERANCE < startTime ||
              page.startTime + SCENE_PAGE_TOLERANCE >= endTime
            )
        )
        // Checks if any of the pages in the scene have dynamic placement
        .some((page) => page.dynamicPlacement);
      // Non-dynamic box dimensions are used if no dynamic pages are present, as they should not be
      // constrained by the dynamic pages when moving
      const boxWidth = hasDynPage ? outerBoxWidth : nonDynamicOuterBoxWidth;
      const boxHeight = hasDynPage ? outerBoxHeight : nonDynamicOuterBoxHeight;
      return {
        startTime,
        endTime,
        box: {
          ...defaultBox,
          ...this._adjustBoxPositionToStayInBounds(position.x, position.y, boxWidth, boxHeight),
          id: `${startTime}-${endTime}`,
          width: boxWidth,
          height: boxHeight,
        },
      };
    });
    this._updateCurrentBox();
  }

  public setTimestamp(timestamp: number) {
    if (this._timestamp === timestamp) {
      // No change, no need to run a possibly expensive setup
      return;
    }
    this._timestamp = timestamp;

    this._updateCurrentBox();

    if (
      this._currentPageIndex < 0 ||
      this._timestamp < this._pages[this._currentPageIndex].startTime ||
      this._timestamp > this._pages[this._currentPageIndex].endTime
    ) {
      // Avoids scanning the pages vector unless the current page isn't current anymore
      this._currentPageIndex = this._findCurrentPage();
      const currentPage = this._pages[this._currentPageIndex];
      if (this._currentOverallBox) {
        this._currentOverallBox.rotation = this._style?.rotation ?? currentPage?.rotation ?? 0;
      }
    }

    this._emojiLayer?.setTimestamp(timestamp);
  }

  /**
   * Draws the current frame's caption page on the canvas.
   *
   * @param context - The canvas context to use.
   * @param x - The x coordinate of the top left corner of the canvas where the frame should be drawn.
   * @param y - The y coordinate of the top left corner of the canvas where the frame should be drawn.
   * @private
   */
  public async drawCaptions(
    context: AnyCanvasRenderingContext2D,
    x: number,
    y: number
  ): Promise<OuterBoxInfo | null> {
    await this._emojiLayer?.prepare();

    if (
      this._currentPageIndex < 0 ||
      this._pages.length <= this._currentPageIndex ||
      this._linesWithInfo.length !== this._pages.length ||
      !this._currentOverallBox ||
      !this._basicMeasurements ||
      !this._width ||
      !this._height ||
      !this._textStyle ||
      !this._style ||
      !this._stylePreset ||
      !this._wordColors
    ) {
      return Promise.resolve(null);
    }

    const page = this._pages[this._currentPageIndex];
    const pageStylePresetOverrides = getPageStylePresetOverrides(page);
    const stylePreset = {
      ...this._stylePreset,
      ...pageStylePresetOverrides,
    };
    const textLines = this._linesWithInfo[this._currentPageIndex].lines;
    const rotation = this._style.rotation ?? page.rotation;
    const {
      fontSize,
      fontFamily,
      letterSpacingPx,
      wordPaddingHorizontalPx,
      baseLineHeight,
      insets,
    } = this._basicMeasurements;
    // Prepares the canvas context
    context.save();
    context.resetTransform();
    context.translate(x, y);
    context.beginPath();
    context.rect(0, 0, this._width, this._height);
    context.clip();
    context.beginPath();
    context.scale(1 / this._scale.x, 1 / this._scale.y);
    // Animate the whole page (if applicable)
    const pageAnimation = getPageAnimationParameters(
      getPageAnimationStage(page, stylePreset, this._timestamp),
      stylePreset,
      {
        baseSize: fontSize,
        elementWidth: 0,
        elementHeight: 0,
      }
    );
    applyMovementAutoMotion(this._movementAutoMotionData, pageAnimation, stylePreset, {
      timestamp: this._timestamp,
      baseScale: this._style.sizeFactor,
    });
    applyScaleAutoMotion(this._scaleAutoMotionData, pageAnimation, { timestamp: this._timestamp });

    const { x: startX, y: startY } = this._currentOverallBox;
    // Animation offsets aren't considered the "true" position of the box
    // so the returned startX and startY aren't modified
    context.translate(
      (startX + pageAnimation.offset.x) * this._scale.x,
      (startY + pageAnimation.offset.y) * this._scale.y
    );
    context.rotate(rotation);

    const [innerBoxWidth, innerBoxHeight] = textLines.reduce(
      ([width, height], line) => [
        // innerBoxWidth
        Math.max(line.width, line.activeWidth, width),
        // innerBoxHeight
        height + line.fontFactor * baseLineHeight,
      ],
      [-Infinity, 0]
    );

    const horizontalPaddingSize = 2 * insets.x;
    const verticalPaddingSize = 2 * insets.y;
    const lineSpacing = stylePreset.lineSpacing * this._style.sizeFactor;

    const outerBoxWidth = pageAnimation.sizeFactor * (innerBoxWidth + horizontalPaddingSize);
    const outerBoxHeight =
      pageAnimation.sizeFactor *
      (!stylePreset.isMultilineBox
        ? innerBoxHeight +
          verticalPaddingSize * textLines.length +
          lineSpacing * Math.max(0, textLines.length - 1)
        : innerBoxHeight + verticalPaddingSize);

    // Draws the outer offset if applicable
    // These half values are negative for convenience
    const outerBoxStartX = -(outerBoxWidth / 2);
    const outerBoxStartY = -(outerBoxHeight / 2);

    // Now that we have all the necessary info, trigger drawing the animations
    const animLayerCanvas = this._emojiLayer?.getFrame(
      this._getEmojiPosition(
        startX + pageAnimation.offset.x,
        startY + pageAnimation.offset.y,
        outerBoxStartY,
        -(this._currentOverallBox.width || outerBoxWidth) / 2,
        rotation
      )
    );

    const { backgroundX, wordXOffsetWithBackground } = getBackgroundStartOffsetFromMiddle(
      stylePreset,
      outerBoxStartX,
      insets,
      outerBoxWidth,
      this._currentOverallBox.width || outerBoxWidth
    );

    if (stylePreset.colorApplicationPageEnabled) {
      let outerBoxAnimationModifiers: CaptionAnimationModifiers | null = null;
      if (shouldApplyLineAnimationToBox(page, stylePreset)) {
        outerBoxAnimationModifiers = getLineAnimationParameters(
          getLineAnimationStage(textLines[0], stylePreset, this._timestamp),
          stylePreset,
          {
            baseSize: pageAnimation.sizeFactor * fontSize * textLines[0].fontFactor,
            elementWidth: Math.max(textLines[0].width, textLines[0].activeWidth),
            elementHeight: fontSize * textLines[0].fontFactor,
          }
        );
      }

      drawOuterBox(
        context,
        stylePreset,
        this._style,
        this._wordColors,
        backgroundX,
        outerBoxStartY,
        outerBoxWidth,
        outerBoxHeight,
        outerBoxAnimationModifiers
      );
    }

    let lineY = outerBoxStartY + insets.y;
    let wordsToDraw: WordDrawData[] = [];
    let boxesToDraw: BoxDrawData[] = [];
    for (const line of textLines) {
      const isUpcomingLine = shouldSkipLine(line, stylePreset, this._timestamp);
      if (isUpcomingLine && !this._wordColors.upcomingTextColor.alpha) {
        break;
      }
      const lineFocused = isLineActive(line, this._timestamp);
      const lineBold = lineFocused && stylePreset.activeBoldEnabled;
      const baseLineWidth = lineBold ? line.activeWidth : line.width;
      const forceActive = lineFocused && shouldShowFocusedLineAsActive(stylePreset);
      const lineAnimationStage = getLineAnimationStage(line, stylePreset, this._timestamp);
      const lineAnimationAuxMetrics = {
        baseSize: pageAnimation.sizeFactor * fontSize * line.fontFactor,
        elementWidth: baseLineWidth,
        elementHeight: pageAnimation.sizeFactor * fontSize * line.fontFactor,
      };
      const lineAnimation = getLineAnimationParameters(
        lineAnimationStage,
        stylePreset,
        lineAnimationAuxMetrics
      );
      const lineFontFactor = pageAnimation.sizeFactor * line.fontFactor * lineAnimation.sizeFactor;
      const lineHeight = baseLineHeight * lineFontFactor;
      let actualLineHeight = lineHeight;

      const actualWordPaddingHorizontalPx = wordPaddingHorizontalPx * lineFontFactor;
      const actualFontSize = fontSize * lineFontFactor;

      const activeBoxPadding = this._getActiveBoxPadding(this._style, this._textStyle);
      const lineBoxPadding =
        insets.x || insets.y
          ? {
              horizontal: insets.x,
              vertical: insets.y,
            }
          : activeBoxPadding;

      const isRTL = line.words[0].startTime > line.words[line.words.length - 1].startTime;
      let wordX =
        getStartOffsetFromMiddle(
          baseLineWidth,
          stylePreset,
          this._currentOverallBox.width || outerBoxWidth,
          line.startTime * 100000,
          lineBoxPadding.horizontal
        ) + lineAnimation.offset.x;
      if (isRTL) {
        const lineWidth = pageAnimation.sizeFactor * lineAnimation.sizeFactor * baseLineWidth;
        wordX += lineWidth;
      }
      let previousWordBox: WordBox | null = null;
      const wordY = lineY + lineAnimation.offset.y;
      const lineWords = isRTL ? [...line.words].reverse() : line.words;

      if (!stylePreset.isMultilineBox) {
        const actualLineWidth = baseLineWidth * pageAnimation.sizeFactor;

        const boxDrawData: BoxDrawData = {
          wordBox: {
            x: wordX + wordXOffsetWithBackground,
            y: wordY,
            width: actualLineWidth * lineAnimation.sizeFactor,
            height: lineHeight,
          },
          fontSize: actualFontSize,
          boxColor: this._wordColors.boxBackgroundColor,
          boxOuterPadding: lineBoxPadding,
          boxSizeFactor: 1,
          boxOffset: { x: 0, y: 0, width: 0, height: 0 },
          cornerRadius: stylePreset.backgroundStyle.cornerRadius * this._style.sizeFactor,
        };
        const lineBackgroundAnimationFactor = getLineBackgroundAnimationStage(
          line,
          stylePreset,
          this._timestamp
        );
        const animationParameters = getLineBackgroundAnimationParameters(
          lineBackgroundAnimationFactor,
          stylePreset
        );
        applyActiveAnimationParameters(animationParameters, boxDrawData);
        boxesToDraw.push(boxDrawData);
        // If background is enabled, we need to adjust the line height
        actualLineHeight += 2 * lineBoxPadding.vertical;
      }

      for (const word of lineWords) {
        const animationFontFactor = pageAnimation.sizeFactor * lineAnimation.sizeFactor;
        const actualWordWidth =
          (lineBold ? word.activeWidth : word.width) * animationFontFactor +
          2 * actualWordPaddingHorizontalPx;
        if (isRTL) {
          wordX = wordX - actualWordWidth;
        }
        const isUpcomingWord = shouldSkipWord(word, stylePreset, this._timestamp);
        if (isUpcomingWord && !this._wordColors.upcomingTextColor.alpha) {
          if (!isRTL) {
            wordX = wordX + actualWordWidth;
          }
          continue;
        }
        const wordAnimation = getWordAnimationParameters(
          getWordAnimationStage(word, stylePreset, this._timestamp),
          stylePreset,
          {
            baseSize: animationFontFactor * fontSize * word.fontFactor,
            elementWidth: actualWordWidth,
            elementHeight: animationFontFactor * fontSize * word.fontFactor,
          }
        );
        const wordFontFactor = animationFontFactor * wordAnimation.sizeFactor * word.fontFactor;
        const actualWordFontSize = fontSize * wordFontFactor;
        const baselineWordFontSize = fontSize * lineFontFactor;

        // Get accurate font metrics for the word
        const { ascent, descent } = getWordFontMetrics(
          context,
          word.text,
          actualWordFontSize,
          fontFamily
        );
        // Calculate word box dimensions
        const wordHeight = ascent + descent;

        // Adjust `x` and `y` positions
        const wordBoxX = wordX + wordAnimation.offset.x + wordXOffsetWithBackground;
        const wordBoxY = wordY + wordAnimation.offset.y - ascent + baselineWordFontSize;

        const focused = isWordActive(word, stylePreset, this._timestamp);

        const drawData: WordDrawData = {
          word: word.text,
          wordBox: {
            x: wordBoxX,
            y: wordBoxY,
            width: actualWordWidth,
            height: wordHeight,
          },
          opacity: lineAnimation.opacity * wordAnimation.opacity,
          offset: {
            x: actualWordPaddingHorizontalPx,
            y: ascent,
          },
          textColor: getWordColor(
            word,
            stylePreset,
            focused || forceActive,
            isUpcomingWord || isUpcomingLine,
            this._wordColors
          ),
          fontSize: actualWordFontSize,
          enableGlow:
            this._textStyle.glow.enabled && (!this._textStyle.glow.emphasisOnly || word.emphasize),
          bold: lineBold,
        };
        if (focused) {
          const boxDrawData: BoxDrawData = {
            wordBox: drawData.wordBox,
            fontSize: drawData.fontSize,
            boxColor: this._wordColors.activeWordBackgroundColor,
            boxOuterPadding: activeBoxPadding,
            boxSizeFactor: 1, // can be modified by the animation when `applyActiveAnimationParameters` is called.
            boxOffset: { x: 0, y: 0, width: 0, height: 0 },
            cornerRadius: stylePreset.activeWordBackground.cornerRadius * this._style.sizeFactor,
            gradient: stylePreset.activeWordBackground.gradient,
          };
          const activeBackgroundAnimationFactor = getActiveWordBackgroundAnimationStage(
            word,
            stylePreset,
            this._timestamp
          );
          const animationParameters = getWordBackgroundAnimationParameters(
            activeBackgroundAnimationFactor,
            stylePreset.activeWordBackground.animationStyle,
            {
              baseSize: actualFontSize,
              previousWordOffset: previousWordBox
                ? {
                    x: previousWordBox.x - boxDrawData.wordBox.x,
                    y: previousWordBox.y - boxDrawData.wordBox.y,
                    width: previousWordBox.width - boxDrawData.wordBox.width,
                    height: previousWordBox.height - boxDrawData.wordBox.height,
                  }
                : null,
            }
          );
          applyActiveAnimationParameters(animationParameters, boxDrawData);
          boxesToDraw.push(boxDrawData);
        }
        previousWordBox = drawData.wordBox;
        wordsToDraw.push(drawData);
        if (!isRTL) {
          wordX += actualWordWidth;
        }
      }

      lineY += actualLineHeight + lineSpacing;
      if (stylePreset.animationTarget === AnimationTarget.animationTargetLine) {
        drawActiveBoxes(context, boxesToDraw, fontFamily);
        drawWords(
          context,
          wordsToDraw,
          fontFamily,
          letterSpacingPx,
          this._textStyle.shadow,
          this._textStyle.stroke,
          this._textStyle.glow
        );
        wordsToDraw = [];
        boxesToDraw = [];
      }
    }
    drawActiveBoxes(context, boxesToDraw, fontFamily);
    drawWords(
      context,
      wordsToDraw,
      fontFamily,
      letterSpacingPx,
      this._textStyle.shadow,
      this._textStyle.stroke,
      this._textStyle.glow
    );
    context.restore();

    const captionsBox: OuterBoxInfo = {
      boxType: "captions",
      id: "",
      x: startX,
      y: startY,
      width: outerBoxWidth,
      height: outerBoxHeight,
      rotation,
      scale: this._style?.sizeFactor ?? 1,
    };

    if (animLayerCanvas) {
      return animLayerCanvas.then((canvas) => {
        if (canvas) {
          context.drawImage(canvas, x, y);
        }
        return captionsBox;
      });
    }

    return Promise.resolve(captionsBox);
  }

  private async _setupEmojiLayer() {
    if (!this._pagBackend) {
      return;
    }
    const emojiPages: EmojiPage[] = this._pages.flatMap((currentPage) => {
      const emojis =
        currentPage?.lines.flatMap((line) =>
          line.words
            .filter((word) => word.emoji)
            .map((word) => ({
              emoji: word.emoji!,
              startTime: word.startTime - currentPage.startTime,
            }))
        ) ?? [];
      if (emojis.length === 0) {
        return [];
      }
      return [
        {
          items: emojis,
          startTime: currentPage.startTime,
          endTime: currentPage.endTime,
        },
      ];
    });
    if (emojiPages.length === 0) {
      this._emojiLayer?.destroy();
      this._emojiLayer = null;
      return;
    }
    if (!this._emojiLayer) {
      const pagInstance = await this._pagBackend.getPAGInstance();
      this._emojiLayer = new EmojiLayer(pagInstance, this._pagBackend.loadAnimation);
    }
    this._emojiLayer.setArea(this._width, this._height);
    const animationIds = new Set(
      emojiPages.flatMap((page) => page.items.map((item) => item.emoji))
    );
    await preloadAnimations(animationIds, this._pagBackend, logger);
    this._emojiLayer.setEmojiPages(emojiPages);
  }

  private _findCurrentPage(): number {
    return this._pages.findIndex((page) => {
      return page.startTime <= this._timestamp && this._timestamp <= page.endTime;
    });
  }

  private _updateCurrentBox() {
    this._currentOverallBox =
      this._overallBoxes.find(
        (box) => this._timestamp >= box.startTime && this._timestamp < box.endTime
      )?.box ?? this._defaultOverallBox;
  }

  private async _calculateBasicMeasures(context: AnyCanvasRenderingContext2D) {
    if (!this._stylePreset || !this._style || !this._width || !this._height || !this._fontBackend) {
      this._basicMeasurements = null;
      return;
    }

    const { sizeFactor, emojiSettings } = this._style;
    const { backgroundPadding, backgroundStyle, textOffset, font } = this._stylePreset;

    const fontSize = font.fontSize * sizeFactor;

    const measuredText = await this._getMeasuredText("Hg", context);
    if (!measuredText) {
      return;
    }

    // NOTE(Sep.17.23): negative widths are insets borders in Swift. see: https://developer.apple.com/documentation/quartzcore/calayer/1410917-borderwidth
    // However the styles currently supported on Desktop don't demonstrably show insets borders.
    const { fontMeasure, fontFamily, letterSpacingPx, wordPaddingHorizontalPx } = measuredText;

    // NOTE: The insets are the padding between the text and the box border
    // This naming is consistent with the iOS implementation
    const insets = {
      x:
        (textOffset.x + backgroundPadding.x + backgroundStyle.width + DEFAULT_EXTRA_SIZE.width) *
        sizeFactor,
      y: (textOffset.y + backgroundPadding.y + backgroundStyle.width) * sizeFactor,
    };

    const maxSuperSizeWidth = this._width - 2 * (BOX_MARGIN + insets.x);
    const widthConstraintFactor = this._isLandscape ? LANDSCAPE_MAX_WIDTH_FACTOR : 1;
    const widthConstraint =
      this._stylePreset.sizeConstraints?.width ??
      (this._stylePreset.lineFitWrapEnabled ? DEFAULT_LINE_FIT_WRAP_WIDTH_CONSTRAINT : Infinity);
    const maxInnerWidth = Math.min(
      widthConstraint * widthConstraintFactor * sizeFactor - 2 * insets.x,
      maxSuperSizeWidth
    );

    const baseLineHeight =
      fontMeasure.fontBoundingBoxAscent +
      fontMeasure.fontBoundingBoxDescent +
      DEFAULT_EXTRA_SIZE.height * sizeFactor;

    const emojiSize = emojiSettings.size * sizeFactor;
    const emojiSpacing = BASE_EMOJI_SPACING * sizeFactor;

    this._basicMeasurements = {
      fontSize,
      fontFamily,
      letterSpacingPx,
      wordPaddingHorizontalPx,
      insets,
      maxSuperSizeWidth,
      maxInnerWidth,
      baseLineHeight,
      emojiSize,
      emojiSpacing,
    };
  }

  private async _getMeasuredText(
    text: string,
    context: AnyCanvasRenderingContext2D,
    customFontSize?: number
  ): Promise<{
    fontMeasure: TextMetrics;
    fontFamily: string;
    letterSpacingPx: number;
    wordPaddingHorizontalPx: number;
  } | null> {
    if (!this._stylePreset || !this._style || !this._fontBackend) {
      this._basicMeasurements = null;
      return null;
    }
    const { sizeFactor } = this._style;
    const { font, letterSpacing } = this._stylePreset;

    const fontFamily = this._fontBackend.getFontFamily(font.fontName, this._countryCode);

    await loadCaptionFont(fontFamily, this._stylePreset);
    const fontSize = font.fontSize * sizeFactor;
    // Saves old context font-related values
    const oldFont = context.font;
    const oldTextAlign = context.textAlign;
    const oldTextBaseline = context.textBaseline;
    const oldDirection = context.direction;

    // Sets the context font-related values
    context.font = `${customFontSize ?? fontSize}px ${fontFamily}`;
    context.textAlign = "left";
    context.textBaseline = "alphabetic";
    context.direction = "inherit";

    // Measures the text
    const fontMeasure = context.measureText(text);
    const letterSpacingPx = (letterSpacing ?? 0) * sizeFactor;
    const wordPaddingHorizontalPx = (context.measureText(" ").width + letterSpacingPx) / 2;

    // Restores old context font-related values
    context.font = oldFont;
    context.textAlign = oldTextAlign;
    context.textBaseline = oldTextBaseline;
    context.direction = oldDirection;

    return { fontMeasure, fontFamily, letterSpacingPx, wordPaddingHorizontalPx };
  }

  private _getLinesWithInfo(lines: Line[], context: AnyCanvasRenderingContext2D): LineInfo[] {
    if (!this._basicMeasurements || !this._stylePreset || !this._style) {
      return [];
    }
    const result: LineInfo[] = [];
    const oldFont = context.font;

    const {
      fontSize,
      fontFamily,
      letterSpacingPx,
      wordPaddingHorizontalPx,
      maxInnerWidth,
      maxSuperSizeWidth,
      baseLineHeight,
    } = this._basicMeasurements;

    for (const line of lines) {
      const shouldSuperSize = this._enableSuperSize && line.words.some((word) => word.supersize);
      let lineFontFactor = 1;

      const [startTime, endTime] = line.words.reduce(
        (result, word) => [Math.min(word.startTime, result[0]), Math.max(word.endTime, result[1])],
        [Infinity, -Infinity]
      );

      const currentLineWords: WordInfo[] = line.words.map((word) => {
        return {
          ...word,
          width: 0,
          activeWidth: 0,
          fontFactor: 1,
        };
      });

      const recalculateLineWidth = (words: WordInfo[]) => {
        words.forEach((item) => {
          context.font = `${fontSize * item.fontFactor}px ${fontFamily}`;
          const metrics = measureText(context, item.text, letterSpacingPx);
          item.width = metrics.width;
          item.activeWidth = metrics.width;
        });
        return calcActualLineWidth(words, wordPaddingHorizontalPx);
      };

      let currentLineWidth = recalculateLineWidth(currentLineWords);

      if (shouldSuperSize) {
        let wordFactor = SUPER_SIZE_NOBREAK_FACTOR;

        if (line.words.length === 1 && !this._stylePreset.superSizeNoBreak) {
          const wordsSuperSize = currentLineWords.filter((word) => word.supersize);
          const wordsSuperSizeWidth = calcActualLineWidth(wordsSuperSize, wordPaddingHorizontalPx);
          const diff = currentLineWidth - wordsSuperSizeWidth;
          const desiredSuperSizeWidth = maxSuperSizeWidth - diff;
          const superSizeFactor = desiredSuperSizeWidth / wordsSuperSizeWidth;
          wordFactor = Math.max(1, Math.min(superSizeFactor, SUPER_SIZE_FACTOR));
        }

        currentLineWords.forEach((word) => {
          word.fontFactor = word.supersize ? wordFactor : word.fontFactor;
        });

        currentLineWidth = recalculateLineWidth(currentLineWords);
      }

      // Dynamic captions
      const currentLineHeight = baseLineHeight * getLineFontFactor(currentLineWords);
      const activeBoxPadding = this._getActiveBoxPadding(
        this._style,
        getUpdatedTextStyle(
          this._stylePreset.stroke,
          this._stylePreset.shadow,
          this._stylePreset.glow,
          this._style
        )
      );
      const eligibleFrameWidth = this._width * this._scale.x - 2 * activeBoxPadding.horizontal;
      const eligibleFrameHeight = this._height * this._scale.y - 2 * activeBoxPadding.vertical;

      const dynamicLayout = getDynamicLayout(
        currentLineWords,
        currentLineWidth,
        currentLineHeight,
        eligibleFrameWidth,
        eligibleFrameHeight,
        (wordInfo) => {
          context.font = `${fontSize * wordInfo.fontFactor}px ${fontFamily}`;
          return measureText(context, wordInfo.text, letterSpacingPx);
        },
        baseLineHeight,
        wordPaddingHorizontalPx
      );
      if (dynamicLayout) {
        result.push(...dynamicLayout);
        continue;
      }

      if (this._stylePreset.autoSizeFitLines) {
        const minFactor =
          (this._stylePreset.sizeConstraints.minFontSize ?? 0) / this._stylePreset.font.fontSize;

        currentLineWords.forEach((word) => {
          word.fontFactor *= Math.min(Math.max(maxInnerWidth / currentLineWidth, minFactor), 1);
        });
        currentLineWidth = recalculateLineWidth(currentLineWords);
      }

      // Line wrapping
      if (this._stylePreset.lineFitWrapEnabled && currentLineWidth > maxInnerWidth) {
        const wrappedLines = wrapLines(currentLineWords, maxInnerWidth, wordPaddingHorizontalPx);
        result.push(...wrappedLines);
        continue;
      }

      let activeWidth = currentLineWidth;
      if (this._stylePreset.activeBoldEnabled) {
        currentLineWords.forEach((item) => {
          context.font = `bold ${fontSize * item.fontFactor}px ${fontFamily}`;
          const metrics = measureText(context, item.text, letterSpacingPx);
          item.activeWidth = metrics.width;
        });
        activeWidth = calcActiveLineWidth(currentLineWords, wordPaddingHorizontalPx);
      }

      lineFontFactor = getLineFontFactor(currentLineWords);
      result.push({
        words: currentLineWords,
        width: currentLineWidth,
        activeWidth,
        startTime,
        endTime,
        fontFactor: lineFontFactor,
      });
    }
    context.font = oldFont;
    return result;
  }

  private _getActiveBoxPadding(style: CaptionStyle, textStyle: CaptionTextStyle) {
    return {
      horizontal: BOX_HORIZONTAL_PADDING * style.sizeFactor + textStyle.stroke.width,
      vertical: BOX_VERTICAL_PADDING * style.sizeFactor + textStyle.stroke.width,
    };
  }

  private _adjustBoxPositionToStayInBounds(x: number, y: number, width: number, height: number) {
    // TODO DESK-1656: take into account box rotation when calculating out of bounds
    const left = x - width / 2;
    const right = x + width / 2;
    const top = y - height / 2;
    const bottom = y + height / 2;

    const videoWidth = this._width;
    const videoHeight = this._height;
    const videoBounds = {
      left: 0,
      right: videoWidth,
      top: 0,
      bottom: videoHeight,
    };

    let adjustedX = x;
    let adjustedY = y;

    // only adjust position if box can be contained within video
    if (width <= videoWidth) {
      if (left < videoBounds.left) {
        adjustedX += videoBounds.left - left;
      } else if (right > videoBounds.right) {
        adjustedX -= right - videoBounds.right;
      }
    }

    // only adjust position if box can be contained within video
    if (height <= videoHeight) {
      if (top < videoBounds.top) {
        adjustedY += videoBounds.top - top;
      } else if (bottom > videoBounds.bottom) {
        adjustedY -= bottom - videoBounds.bottom;
      }
    }

    return { x: adjustedX, y: adjustedY };
  }

  private _getEmojiPosition(
    startX: number,
    startY: number,
    outerBoxStartY: number,
    outerBoxStartX: number,
    rotation: number
  ): EmojiFramePosition {
    const emojiSize =
      (this._basicMeasurements?.emojiSize ?? DEFAULT_EMOJI_SIZE) * EMOJI_SIZE_TO_PIXELS_FACTOR;
    const emojiSpacing = this._basicMeasurements?.emojiSpacing ?? BASE_EMOJI_SPACING;
    const result: EmojiFramePosition = {
      center: { x: startX, y: startY },
      offset: { x: 0, y: 0 },
      rotation: rotation,
      videoScale: this._scale,
      emojiSize,
      horizontalAlignment: "center",
    };
    switch (this._stylePreset?.layoutAlignment) {
      case LayoutAlignment.layoutAlignmentNatural:
      case LayoutAlignment.layoutAlignmentLeft:
        result.offset.x = outerBoxStartX;
        result.horizontalAlignment = "left";
        break;
      case LayoutAlignment.layoutAlignmentRight:
        result.offset.x = -outerBoxStartX;
        result.horizontalAlignment = "right";
        break;
    }
    switch (this._style?.emojiSettings?.position) {
      case EmojiPosition.emojiPositionUnspecified:
      case EmojiPosition.emojiPositionFreeform:
      case EmojiPosition.emojiPositionAbove:
        result.offset.y = outerBoxStartY - emojiSize - emojiSpacing;
        break;
      case EmojiPosition.emojiPositionBelow:
        result.offset.y = -outerBoxStartY + emojiSpacing;
    }
    return result;
  }

  public destroy() {
    this._emojiLayer?.destroy();
    this._emojiLayer = null;
  }
}
