import { datadogRum } from "@datadog/browser-rum";
import { createStore } from "zustand";
import { combine } from "zustand/middleware";

import type {
  CaptionsAuthData,
  CaptionsAuthState,
  CaptionsAuthUser,
} from "~/context/CaptionsAuthContext";
import { pickKeysWithValues } from "~/utils/pickBy";
import { createUseStoreWithSelector } from "~/utils/zustand-store";

import { getIterableStore } from "./analytics/iterable";
import { getMixpanelStore } from "./analytics/mixpanel";
import { getStatsigStore } from "./analytics/statsig";

const throwNotImplemented = async () => {
  throw new Error("Auth methods not implemented - must call setAuth first");
};

export const createAuthStore = () => {
  return createStore(
    combine(
      {
        state: "unknown",
        signIn: throwNotImplemented,
        signOut: throwNotImplemented,
        refreshUserProfile: throwNotImplemented,
      } as CaptionsAuthData,
      (set, get) => ({
        setAuthMethodsFromContext: (
          auth: Pick<CaptionsAuthData, "signIn" | "signOut" | "refreshUserProfile">
        ) => set({ ...auth }),
        setUser: (user: CaptionsAuthUser | undefined) => {
          set({ user });

          const mixpanel = getMixpanelStore();
          const iterable = getIterableStore();

          if (user?.userId) {
            const userMeta = {
              id: user.userId,
              login_type: user.loginType,
              plan: user?.subscription?.subscriptionTier ?? "none",
              platform: "web",
            };

            // set user meta for both mixpanel and dd RUM
            mixpanel.identify(user.userId);
            mixpanel.setUserProps(userMeta);
            datadogRum.setUser(userMeta);

            getStatsigStore().setUserIdOrInitialize(user.userId);

            iterable.init();
            iterable.setUser(user);
          } else {
            mixpanel.reset();
            datadogRum.clearUser();
            iterable.reset();
          }

          if (
            Boolean(user) &&
            (typeof user?.email !== "undefined" || typeof user?.phoneNumber !== "symbol") &&
            typeof window !== "undefined" &&
            typeof window.gtag !== "undefined"
          ) {
            const userProps = pickKeysWithValues({
              email: user?.email,
              phone_number: user?.phoneNumber,
            });
            window.gtag("set", "user_data", userProps);
          }
        },
        setAuthState: (state: CaptionsAuthState) => set({ state }),
        clearAuth: () => set({ state: "signedOut", user: undefined }),
      })
    )
  );
};

const authStore = createAuthStore();

export const getAuthStore = () => authStore.getState();

export const useAuthStore = createUseStoreWithSelector(authStore);
