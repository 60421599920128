import { z } from "zod";

import { makeBrandedIdSchema } from "~/utils/brand";
import { ShotReframeOptions } from "~/utils/reframing";

import { timelineLayerItemSchema } from "./layer-item";
import {
  AnySourceAssetDescription,
  SourceAsset_Id,
  sourceAssetNoneDescriptionSchema,
  sourceAssetVideoDescriptionSchema,
} from "./source-asset";

const makeTimelineBlockSchema = <ResolvedAsset extends z.ZodType<AnySourceAssetDescription>>(
  resolvedAssetSchema: ResolvedAsset
) =>
  timelineLayerItemSchema.extend({
    layerType: z.literal("timeline-block"),
    resolvedAsset: resolvedAssetSchema.optional(),
  });

export const timelineBlockVideoSchema = makeTimelineBlockSchema(
  sourceAssetVideoDescriptionSchema
).extend({
  blockType: z.literal("video"),
  sourceAssetId: makeBrandedIdSchema(SourceAsset_Id),

  // TODO: would like to extract this to zod schema
  reframe: z.custom<ShotReframeOptions>().optional(),
});
export type TimelineBlockVideo = z.infer<typeof timelineBlockVideoSchema>;

export const timelineBlockSpacerSchema = makeTimelineBlockSchema(
  sourceAssetNoneDescriptionSchema
).extend({
  blockType: z.literal("spacer"),
  duration: z.number(),
});
export type TimelineBlockSpacer = z.infer<typeof timelineBlockSpacerSchema>;

export const anyTimelineBlockSchema = z.discriminatedUnion("blockType", [
  timelineBlockVideoSchema,
  timelineBlockSpacerSchema,
]);

/**
 * a timeline block can correspond to a shot in the video,
 * or a spacer block that is used to fill in gaps for now.
 */
export type AnyTimelineBlock = z.infer<typeof anyTimelineBlockSchema>;

// creation

type TimelineBlockTypeMap = {
  video: TimelineBlockVideo;
  spacer: TimelineBlockSpacer;
};

type TimelineBlockParams<T extends keyof TimelineBlockTypeMap> = Omit<
  TimelineBlockTypeMap[T],
  "blockType" | "layerType"
>;

export function createTimelineBlock<T extends keyof TimelineBlockTypeMap>(
  blockType: T,
  params: TimelineBlockParams<T>
): TimelineBlockTypeMap[T] {
  return {
    layerType: "timeline-block",
    blockType,
    ...params,
  } as TimelineBlockTypeMap[T];
}
