export const ChalkAnimations: { [key: string]: URL } = {
  CHALK_IN: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/chalk_in.pag"
  ),
  CHALK_DURATION: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/chalk_duration.pag"
  ),
  CHALKDRAW_MID: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/chalkDraw_MID.pag"
  ),
  CHALKDRAW1_IN: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/chalkDraw1_IN.pag"
  ),
  CHALKDRAW1_OUT: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/chalkDraw1_OUT.pag"
  ),
  CHALKDRAW2_IN: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/chalkDraw2_IN.pag"
  ),
  CHALKDRAW2_OUT: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/chalkDraw2_OUT.pag"
  ),
  CHALKDRAW3_IN: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/chalkDraw3_IN.pag"
  ),
  CHALKDRAW3_OUT: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/chalkDraw3_OUT.pag"
  ),
};
