import useResizeObserver from "@react-hook/resize-observer";
import {
  ForwardedRef,
  forwardRef,
  UIEventHandler,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  WheelEventHandler,
} from "react";

import { Root, Scrollbar, ScrollFade, Thumb, Viewport } from "./ScrollArea.styles";
import { ScrollAreaProps } from "./ScrollArea.types";

export const ScrollArea = forwardRef(function ScrollArea(
  {
    orientation = "vertical",
    children,
    fillColumn,
    flexColumn,
    fillRow,
    enableFade,
    onScroll,
    onWheel,
    hideScrollBars,
    ...props
  }: ScrollAreaProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const viewPortRef = useRef<HTMLDivElement>(null);
  const [fadeTop, setFadeTop] = useState(false);
  const [fadeBottom, setFadeBottom] = useState(true);
  const [fadeLeft, setFadeLeft] = useState(false);
  const [fadeRight, setFadeRight] = useState(true);

  const updateFade = useCallback(() => {
    if (!viewPortRef.current) {
      return;
    }
    setFadeTop(viewPortRef.current.scrollTop > 0);
    setFadeBottom(
      viewPortRef.current.scrollTop + 1 <
        viewPortRef.current.scrollHeight - viewPortRef.current.clientHeight
    );
    setFadeLeft(viewPortRef.current.scrollLeft > 0);
    setFadeRight(
      viewPortRef.current.scrollLeft + 1 <
        viewPortRef.current.scrollWidth - viewPortRef.current.clientWidth
    );
  }, []);

  const scrollHandler: UIEventHandler<HTMLDivElement> = useCallback(
    (ev) => {
      onScroll?.(ev);
      updateFade();
    },
    [onScroll]
  );

  const wheelHandler: WheelEventHandler<HTMLDivElement> = useCallback(
    (ev) => {
      onWheel?.(ev);
      if (ev.defaultPrevented || orientation === "vertical") {
        return;
      }
      // noinspection JSSuspiciousNameCombination
      viewPortRef.current!.scrollBy(ev.deltaY, 0);
    },
    [onWheel, orientation]
  );

  useEffect(updateFade, []);

  useResizeObserver(viewPortRef, updateFade);

  useImperativeHandle(ref, () => viewPortRef.current!);

  return (
    <Root fillColumn={fillColumn} fillRow={fillRow} flexColumn={flexColumn}>
      <Viewport
        ref={viewPortRef}
        fillColumn={fillColumn}
        flexColumn={flexColumn}
        fillRow={fillRow}
        onScroll={scrollHandler}
        onWheel={wheelHandler}
        {...props}
      >
        {children}
      </Viewport>
      {enableFade && orientation === "vertical" && (
        <>
          <ScrollFade position="top" showing={fadeTop} />
          <ScrollFade position="bottom" showing={fadeBottom} />
        </>
      )}
      {enableFade && orientation === "horizontal" && (
        <>
          <ScrollFade position="left" showing={fadeLeft} />
          <ScrollFade position="right" showing={fadeRight} />
        </>
      )}

      <Scrollbar orientation={orientation} isVisible={!hideScrollBars}>
        <Thumb />
      </Scrollbar>
    </Root>
  );
});
