import { PositionFactor } from "captions-engine";

export interface Resolution {
  width: number;
  height: number;
  videoScale: PositionFactor;
}

/**
 * Constrain the resolution's width and height to the given value and compensate the video scale
 * accordingly.
 *
 * @param resolution - The original resolution to constrain.
 * @param maxResolutionComponent - The maximum value for the width and height.
 * @returns The constrained resolution.
 */
export function constrainResolution(
  resolution: Resolution,
  maxResolutionComponent: number
): Resolution {
  if (resolution.width <= maxResolutionComponent && resolution.height <= maxResolutionComponent) {
    return resolution;
  }
  const downscaleDivider = Math.max(
    resolution.width / maxResolutionComponent,
    resolution.height / maxResolutionComponent
  );
  return {
    width: Math.round(resolution.width / downscaleDivider),
    height: Math.round(resolution.height / downscaleDivider),
    videoScale: {
      x: resolution.videoScale.x * downscaleDivider,
      y: resolution.videoScale.y * downscaleDivider,
    },
  };
}

/**
 * Round the given pixel count to the nearest valid resolution.
 * @param pixelCount - The pixel count to round.
 * @returns The rounded pixel count.
 * @remarks A valid resolution is a resolution that is divisible by 2 due to how h.264 works.
 */
export function roundToValidResolution(pixelCount: number) {
  return Math.round(pixelCount / 2) * 2;
}
