import { StoredUnpackedVideoAsset } from "~/database/database.types";

import { FixedUnpackedVideoAsset } from "./assetUtils.types";
import { fetchAssetBlob } from "./common";

export async function loadUnpackedVideoAsset(
  id: string,
  asset: FixedUnpackedVideoAsset
): Promise<StoredUnpackedVideoAsset & { drawAs?: FixedUnpackedVideoAsset["drawAs"] }> {
  const frames = await Promise.all(
    asset.frames.map(async ({ timestamp, url }) => {
      const blob = await fetchAssetBlob(url);
      return {
        timestamp,
        blob,
      };
    })
  );
  return {
    id,
    name: id,
    duration: asset.duration,
    frames,
    type: "unpacked-video",
    drawAs: asset.drawAs,
  };
}
