import { AxiosError } from "axios";
import { useFeatureFlag } from "feature-flags";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { NewCaptionSettings, Project, ProjectFolder } from "~/database/database.types";
import { CaptionSettingsRepository } from "~/database/repo/CaptionSettingsRepository";
import { CaptionsRepository } from "~/database/repo/CaptionsRepository";
import { ProjectRepository } from "~/database/repo/ProjectRepository";
import { useAnalytics } from "~/hooks/useAnalytics";
import { useProjectThumbnail } from "~/hooks/useProjectThumbnail";
import { DEFAULT_POSITION_FACTOR } from "~/modules/project/constants/captionStyle";
import { useCaptions } from "~/modules/project/hooks/useCaptions";
import { useCaptionStyleTemplates } from "~/modules/project/hooks/useCaptionStyleTemplates";
import { CaptionTemplate } from "~/modules/project/services/CaptionStylePreset";
import {
  parseTranscriptResponseEntry,
  ServiceTranscript,
} from "~/modules/project/services/Transcription";
import {
  AIAdsJobStatus,
  getUGCAdGeneratesJobStatus,
  startUGCAdGeneratesJob,
} from "~/modules/project/services/UGCAds/UGCAdGenerate";
import { calculateCaptionSizeFactor } from "~/modules/project/utils/getDefaultSizeFactor";
import { getUploadedFileInfo } from "~/services/UploadFile";
import {
  ai_creators_creation_status,
  FlowType,
  new_project_created,
  ProgressTracker,
} from "~/utils/analytics/ProductEvents";
import { VideoFileMetadata } from "~/utils/getVideoFileMetadata";
import { getDefaultProjectTitle } from "~/utils/projectInfo";
import { getTargetAspectRatioNumber, getTargetSize } from "~/utils/reframing";

import { useBackendServicesClient } from "../BackendServicesContext";

import type { ProjectCreationProps, ProjectCreationStage } from "./ProjectCreationContext.types";

export interface ProjectCreationFromVideoProps extends ProjectCreationProps {
  setProjectAttributesUp: (
    projectId: string,
    videoFileMetadata: VideoFileMetadata | null,
    creationStage: ProjectCreationStage,
    progress: number,
    thumbnailPath?: string,
    estimatedTime?: number
  ) => void;
}

type UGGStage = "start" | "error" | "ugc-gen" | "project-creation" | "finished";

const POLLING_INTERVAL = 1000;

// for creating a single project
export const ProjectCreationFromAIAd = (projectCreation: ProjectCreationFromVideoProps) => {
  const {
    projectId,
    settings,
    thumbnailPath,
    setProjectAttributesUp,
    title: generatedTitle,
  } = projectCreation;
  const {
    captionStyleTemplates,
    customTemplates,
    loading: templatesLoading,
  } = useCaptionStyleTemplates();

  const enableCreateMuxAsset = useFeatureFlag("enable_mux_create_asset");

  const { track } = useAnalytics();

  const [stage, setStage] = useState<UGGStage>("start");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const pollingTimeoutId = useRef<ReturnType<typeof setTimeout>>();
  const startTime = useRef<number | undefined>();
  const curStage = useRef<{ stage: UGGStage; time: number } | undefined>();
  const curOperationId = useRef<string | undefined>();

  const [adsResp, setAdsResp] = useState<AIAdsJobStatus | null>(null);
  const [adsGenProgress, setAdsGenProgress] = useState<number>(0);
  const [estimatedTime, setEstimatedTime] = useState<number | undefined>(0);

  const { createThumbnail } = useProjectThumbnail();

  const client = useBackendServicesClient();

  const captions = useCaptions();

  const progress = useMemo(() => {
    if (stage === "finished") {
      return 100;
    }

    if (stage === "project-creation") {
      return 90;
    }

    return 0.9 * adsGenProgress;
  }, [stage, adsGenProgress]);

  const progressTracker = useRef<ProgressTracker>(new ProgressTracker());

  useEffect(() => {
    // remap local stage type to ProjectCreationStage here
    const normStage =
      stage === "finished" ? "finished" : stage === "error" ? "error" : "post-upload";
    setProjectAttributesUp(projectId, null, normStage, progress, thumbnailPath, estimatedTime);

    const prevStage = curStage.current;
    curStage.current = { stage, time: new Date().getTime() };

    const commonTrackingParams = {
      flow_type: "ai_ad" as FlowType,
      ugc_ads_group_id: projectId,
      creator_id: settings?.aiAds?.avatarId,
      creator_name: settings?.aiAds?.avatarName,
      latency: startTime.current ? curStage.current.time - startTime.current : undefined,
      relative_latency: prevStage ? curStage.current.time - prevStage.time : 0,
      script_type: settings?.aiAds?.script_type,
      transcript: settings?.aiAds?.transcript,
      ads_operation_id: curOperationId.current,
    };

    if (stage === "finished") {
      track(
        ...ai_creators_creation_status({
          status: "success",
          project_id_list: adsResp?.ugcAds?.map((ad: { fileId: string }) => ad.fileId),
          ugc_ad_count: adsResp?.ugcAds?.length,
          video_duration_seconds: adsResp?.ugcAds?.reduce((acc, curr) => {
            acc += curr.durationSeconds || 0;
            return acc;
          }, 0),
          ...commonTrackingParams,
        })
      );
    } else if (stage === "error") {
      track(
        ...ai_creators_creation_status({
          status: "fail",
          error: errorMessage,
          ...commonTrackingParams,
        })
      );
    } else if (stage === "start") {
      startTime.current = new Date().getTime();
      track(
        ...ai_creators_creation_status({
          status: "start",
          ...commonTrackingParams,
        })
      );
    } else if (stage === "ugc-gen") {
      const didUpdateProgress = progressTracker.current.updateProgress(progress);
      if (didUpdateProgress) {
        track(
          ...ai_creators_creation_status({
            status: "ugc-gen",
            ...commonTrackingParams,
          })
        );
      }
    } else if (stage === "project-creation") {
      adsResp?.ugcAds?.forEach((ad) => {
        track(
          ...new_project_created({
            project_id: ad.fileId,
            group_id: commonTrackingParams.ugc_ads_group_id,
            project_type: commonTrackingParams.flow_type,
            video_duration_seconds: ad.durationSeconds ?? 0,
            aspect_ratio: "portrait",
            language: settings?.spokenLanguageCode,
            translation_mode: "none",
          })
        );
      });
    }
  }, [stage, progress, estimatedTime]);

  const handleError = (e: AxiosError | Error | string) => {
    setStage("error");
    if (e instanceof AxiosError) {
      setErrorMessage(e.response?.data?.message ?? e.message);
      return;
    }
    setErrorMessage(e.toString());
  };

  const updateAdsJobStatus = useCallback((ugcJobId: string) => {
    getUGCAdGeneratesJobStatus(client, ugcJobId)
      .then((job) => {
        if (job.status !== "started") {
          setEstimatedTime(undefined);
        } else {
          setEstimatedTime(Number(job.estimatedQueueTimeSec ?? 0));
        }
        if (job.status === "processing" || job.status === "started") {
          setAdsGenProgress(Number(job.progress));
          statusPoll(ugcJobId);
        } else if (job.status === "finished") {
          setStage("project-creation");
          setAdsResp(job);
        } else if (job.status === "failed") {
          handleError("ads API failure");
        } else {
          // for now keep polling on unknown status, but longer term we should make this error
          statusPoll(ugcJobId);
        }
      })
      .catch(handleError);
  }, []);

  // poll ads job status
  const statusPoll = useCallback((ugcJobId: string) => {
    const timeoutId = setTimeout(() => updateAdsJobStatus(ugcJobId), POLLING_INTERVAL);
    pollingTimeoutId.current = timeoutId;
  }, []);

  // clear polling timeout on unmount
  useEffect(() => {
    return () => {
      clearTimeout(pollingTimeoutId.current);
    };
  }, []);

  useEffect(() => {
    if (!adsResp || templatesLoading) {
      return;
    }
    const { ugcAds } = adsResp;

    const templateId = settings?.aiAds?.templateId;
    const template: CaptionTemplate | undefined =
      [...(captionStyleTemplates ?? []), ...customTemplates]?.find(
        (preset) => preset.id === templateId
      ) ?? captionStyleTemplates?.at(0);
    const captionSettings: NewCaptionSettings = {
      preset: template?.style?.content,
      textColor: template?.colors.primary,
      activeColor: template?.colors.active,
      emphasisColor: template?.colors.emphasis,
      activeWordBackground: template?.style.content.activeWordBackground.color,
      wordBackground: template?.colors.wordBackground,
      emojiSettings: template?.emojiSettings,
      positionFactor: DEFAULT_POSITION_FACTOR,
    };

    const folderTitle = getDefaultProjectTitle({
      type: "ai-ads-folder",
      generatedTitle,
      transcript: settings?.aiAds?.transcript,
    });

    const newAdsFolder: ProjectFolder = {
      id: projectId,
      folderType: "ai-ads",
      title: folderTitle,
      createdAt: new Date(),
      updatedAt: new Date(),
      thumbnailPath: null,
      folderTypeMetadata: {
        originalVideoDuration: 300, // placeholder
        shortsLength: "auto", // placeholder
        projectTimespans:
          ugcAds?.map((_, i) => ({
            projectId: `${projectId}-${i}`,
            startTime: 0,
            endTime: 300,
          })) ?? [],
        aiAdsSettings: settings?.aiAds,
      },
    };

    // success or failure based on if folder and all projects finish
    Promise.all([
      ProjectRepository.addProjectFolder(newAdsFolder),
      ...(ugcAds ?? []).map((ad, i) => {
        const localProjectId = `${projectId}-${i}`;
        const { fileId, title: generatedTitle, transcription } = ad;
        const projectTitle = getDefaultProjectTitle({
          type: "ai-ads",
          generatedTitle,
        });

        const transcript = parseTranscriptResponseEntry(
          Object.values(transcription.result.result)[0] as ServiceTranscript
        );

        const countryCode = "en-US";

        return getUploadedFileInfo(client, fileId, {
          enableCreateMuxAsset,
        })
          .then((fileInfo) => {
            const projectToAdd: Project = {
              id: localProjectId,
              title: projectTitle,
              createdAt: new Date(),
              lastGeneratedFileId: "",
              sourceFileId: fileId,
              updatedAt: new Date(),
              sourceVideoMetadata: {
                duration: fileInfo.fileMetadata.duration ?? 0,
                fps: fileInfo.fileMetadata.fps ?? 0,
                width: fileInfo.fileMetadata.width ?? 0,
                height: fileInfo.fileMetadata.height ?? 0,
                size: fileInfo.fileMetadata.size ?? 0,
              },
              sourceFileLanguageCode: countryCode,
              folderId: projectId,
            };

            return projectToAdd;
          })
          .then((projectToAdd) => {
            return createThumbnail(projectToAdd).then((thumbnailPath) => {
              return { ...projectToAdd, thumbnailPath };
            });
          })
          .then((projectToAdd) => {
            return ProjectRepository.addProject(projectToAdd)
              .then(() => {
                const { words, phrases, phraseAudios } = captions.generateFromTranscript(
                  transcript,
                  template?.emphasisSettings
                );
                return CaptionsRepository.addCaptions({
                  projectId: localProjectId,
                  languageCode: countryCode,
                  words,
                  phrases,
                  phraseAudios,
                  videoClipsApplied: true,
                })
                  .then((captionsId) => {
                    return ProjectRepository.updateProject(localProjectId, {
                      sourceFileLanguageCode: "en-US",
                      captionsId,
                    });
                  })
                  .then((project) => ({
                    project,
                    words,
                  }));
              })
              .then(async ({ project, words }) => {
                const width = project?.sourceVideoMetadata.width ?? 0;
                const height = project?.sourceVideoMetadata.height ?? 0;
                const aspectRatio = getTargetAspectRatioNumber("9_16");
                const { targetWidth, targetHeight } =
                  width && height
                    ? getTargetSize(aspectRatio, width, height)
                    : { targetWidth: 0, targetHeight: 0 };
                let sizeFactor = 1;
                if (targetWidth && targetHeight && template?.style?.content) {
                  sizeFactor = await calculateCaptionSizeFactor(targetWidth, targetHeight, {
                    stylePreset: template.style.content,
                    emphasisSettings: template.emphasisSettings,
                    words,
                    countryCode,
                    templateId,
                  });
                }
                return CaptionSettingsRepository.addCaptionSettings({
                  ...captionSettings,
                  projectId: project!.id,
                  sizeFactor,
                });
              })
              .then((captionSettingsId) => {
                return ProjectRepository.updateProject(localProjectId, {
                  captionSettingsId,
                });
              });
          });
      }),
    ])
      .then(() => {
        setStage("finished");
      })
      .catch(handleError);
  }, [adsResp, templatesLoading]);

  useEffect(() => {
    startUGCAdGeneratesJob(client, {
      avatarId: settings?.aiAds?.avatarId ?? "",
      mediaFileIds: settings?.aiAds?.media ?? [],
      mediaUris: settings?.aiAds?.mediaUris ?? [],
      transcript: settings?.aiAds?.transcript ?? "",
      enableBackgroundMusic: settings?.aiAds?.enableBackgroundMusic,
    })
      .then((submitAdsResp) => {
        if (!submitAdsResp.operationId) {
          throw new Error("Invalid response from Ads API");
        }
        curOperationId.current = submitAdsResp.operationId;
        statusPoll(submitAdsResp.operationId);
      })
      .catch(handleError);
    setStage("ugc-gen");
  }, []);

  return <></>;
};
