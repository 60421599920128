import type { SceneStyleConfig, ClientDrivenAIEditVideoStyle } from "./AIEditStyles.types";
import {
  CINEMATIC_CAPTION_TEMPLATE,
  CINEMATIC_TRANSITIONS,
  CINEMATIC_SCENE_STYLE_CONFIG,
  CINEMATIC_GLOBAL_STYLE_CONFIG,
  CINEMATIC_PREVIEW_URL,
} from "./data/cinematic.constants";
import {
  IMPACT_SCENE_STYLE_CONFIG,
  IMPACT_GLOBAL_STYLE_CONFIG,
  IMPACT_TRANSITIONS,
  IMPACT_CAPTION_TEMPLATE,
  IMPACT_PREVIEW_URL,
} from "./data/impact.constants";
import {
  PAPER_CAPTION_TEMPLATE,
  PAPER_GLOBAL_STYLE_CONFIG,
  PAPER_SCENE_STYLE_CONFIG,
  PAPER_TRANSITIONS,
  PAPER_PREVIEW_URL,
} from "./data/paper.constants";
import {
  SCRAPBOOK_CAPTION_TEMPLATE,
  SCRAPBOOK_GLOBAL_STYLE_CONFIG,
  SCRAPBOOK_PREVIEW_URL,
  SCRAPBOOK_SCENE_STYLE_CONFIG,
  SCRAPBOOK_TRANSITIONS,
} from "./data/scrapbook.constants";

// client driven AI edit styles, at the moment these are the "premium" styles
export const CLIENT_DRIVEN_AI_EDIT_STYLES: ClientDrivenAIEditVideoStyle[] = [
  {
    id: "cinematic",
    displayName: "Cinematic",
    previewUrl: CINEMATIC_PREVIEW_URL,
    sceneStyleConfig: getEnabledSceneStyleConfig(CINEMATIC_SCENE_STYLE_CONFIG),
    globalStyleConfig: CINEMATIC_GLOBAL_STYLE_CONFIG,
    transitionConfig: CINEMATIC_TRANSITIONS,
    templateId: CINEMATIC_CAPTION_TEMPLATE,
    tags: ["premium"],
    thumbnailUrl: "",
    hlsPreviewUrl: "",
  },

  {
    id: "impact",
    displayName: "Impact",
    previewUrl: IMPACT_PREVIEW_URL,
    sceneStyleConfig: getEnabledSceneStyleConfig(IMPACT_SCENE_STYLE_CONFIG),
    globalStyleConfig: IMPACT_GLOBAL_STYLE_CONFIG,
    transitionConfig: IMPACT_TRANSITIONS,
    templateId: IMPACT_CAPTION_TEMPLATE,
    tags: ["premium"],
    thumbnailUrl: "",
    hlsPreviewUrl: "",
  },

  {
    id: "paper",
    displayName: "Paper",
    previewUrl: PAPER_PREVIEW_URL,
    sceneStyleConfig: getEnabledSceneStyleConfig(PAPER_SCENE_STYLE_CONFIG),
    globalStyleConfig: PAPER_GLOBAL_STYLE_CONFIG,
    transitionConfig: PAPER_TRANSITIONS,
    templateId: PAPER_CAPTION_TEMPLATE,
    tags: ["premium"],
    thumbnailUrl: "",
    hlsPreviewUrl: "",
  },

  {
    id: "scrapbook",
    displayName: "Scrapbook",
    previewUrl: SCRAPBOOK_PREVIEW_URL,
    sceneStyleConfig: getEnabledSceneStyleConfig(SCRAPBOOK_SCENE_STYLE_CONFIG),
    globalStyleConfig: SCRAPBOOK_GLOBAL_STYLE_CONFIG,
    transitionConfig: SCRAPBOOK_TRANSITIONS,
    templateId: SCRAPBOOK_CAPTION_TEMPLATE,
    requiresFeatureFlag: "ai_edit_scrapbook_enabled",
    tags: ["premium"],
    thumbnailUrl: "",
    hlsPreviewUrl: "",
  },
];

function getEnabledSceneStyleConfig<T extends SceneStyleConfig>(config: T) {
  const enabledConfigs = {} as T;

  for (const key in config) {
    if (!config[key].disabled) {
      enabledConfigs[key] = config[key];
    }
  }

  return enabledConfigs;
}
