import useLatest from "@react-hook/latest";
import { Style as ServerDrivenAIEditStyle } from "@shared/generated/typescript/aiEdit/api/CreateAiEditVideoTemplate";
import { DevLogger } from "dev-logger";
import { useCallback, useRef } from "react";

import { useBackendServicesClient } from "~/context/BackendServicesContext";
import { AIEditData } from "~/database/database.types";
import { ProjectCreationOperationIds } from "~/utils/analytics/ProductEvents";

import { Transcript } from "../../project/services/Transcription";
import { FaceData } from "../../project/utils/faceData/face-data";
import type { AISceneDataWithStyles, AISceneDescription, AIVideoMetadata } from "../AIScenes.types";
import { CommandAsset } from "../CommandList.types";
import { generateRecipeCommandList } from "../generate/gen-recipe";
import { populateSceneStyles } from "../generate/populate-scene-styles";
import { populateSceneTitles } from "../generate/populate-scene-titles";
import { generateCommandListFromBackend } from "../generate-from-backend";
import { useBackendAIEdit } from "../hooks/useBackendAIEdit";
import { isClientDrivenAIEditStyle } from "../services/AIEditStyles";
import { AICollageWithSceneIndex } from "../services/Collages";
import { getScenesFromTranscript } from "../services/SceneData";

import { useAIEditStyles } from "./useAIEditStyles";
import { useFetchCollageData } from "./useFetchCollageData";

const logger = new DevLogger("ai-edit");

export interface AIEditGeneratorJobRequest {
  transcript: Transcript;
  languageCode: string;
  styleId: string;
  faceData?: FaceData;
  videoMetadata: AIVideoMetadata;
  projectId: string;
}

export type AIEditExternalAsset =
  | {
      type: "image" | "video";
      id: string;
      url?: URL;
    }
  | {
      type: "value";
      id: string;
      contents: CommandAsset;
    };

export interface UseAIEditGeneratorProps {
  onError: (error: Error, operationIds?: ProjectCreationOperationIds) => void;
  onFinish: (
    aiEditData: AIEditData,
    externalAssets: AIEditExternalAsset[],
    transcript: Transcript,
    operationIds?: ProjectCreationOperationIds
  ) => void;
}

interface AIEditGeneratorJobData {
  transcript: Transcript;
  style: ServerDrivenAIEditStyle;
  videoMetadata: AIVideoMetadata;
  languageCode: string;
  scenesWithStyles?: AISceneDataWithStyles;
  sceneDescriptions?: AISceneDescription[];
  collages?: AICollageWithSceneIndex[];
  faceData?: FaceData;
  projectId: string;
}

export function useAIEditGenerator({ onError, onFinish }: UseAIEditGeneratorProps) {
  const currentJob = useRef<AIEditGeneratorJobData | null>(null);
  const operationIds = useRef<ProjectCreationOperationIds>({});

  const client = useBackendServicesClient();
  const { fetchCollageData } = useFetchCollageData({
    onStartPooling: (operationId) => {
      operationIds.current = { ...operationIds.current, collage_operation_id: operationId };
    },
  });
  const { createProjectFromStyle: generateBackendAIEdit } = useBackendAIEdit({
    onStartPooling: (operationId) => {
      operationIds.current = { ...operationIds.current, recipe_operation_id: operationId };
    },
  });

  const { getAIEditStyleById } = useAIEditStyles();

  const latestOnError = useLatest(onError);
  const latestOnFinish = useLatest(onFinish);

  const handleError = useCallback(
    (error: unknown) => {
      const errorObj =
        error instanceof Error
          ? error
          : typeof error === "string"
          ? new Error(error)
          : new Error("An error occurred", { cause: error });

      logger.error(errorObj);
      latestOnError.current(errorObj, operationIds.current ?? undefined);
      currentJob.current = null;
    },
    [latestOnError]
  );

  const generateClientSideAIEdit = useCallback(
    async (transcript: Transcript) => {
      const job = currentJob.current;
      if (!job) {
        return;
      }
      if (!isClientDrivenAIEditStyle(job.style)) {
        throw new Error(
          "generateClientSideAIEdit: Expected job.style to be instance of ClientDrivenAIEditVideoStyle"
        );
      }
      try {
        logger.log("Generating scenes from transcript");
        const scenes = await getScenesFromTranscript(client, transcript);

        logger.log("Assigning styles to scenes");
        const { collageRequests, sceneDataWithStyles, sceneDescriptions } = populateSceneStyles(
          scenes,
          job.style
        );

        logger.log("Generating titles for scenes");
        const scenesWithStyles = await populateSceneTitles(
          client,
          sceneDataWithStyles,
          job.languageCode
        );

        job.scenesWithStyles = scenesWithStyles;
        job.sceneDescriptions = sceneDescriptions;

        logger.log("Generating images for scenes");
        const collageData = await fetchCollageData({
          transcriptText: job.transcript.words.map((word) => word.text).join(" "),
          languageCode: job.languageCode,
          collageRequests,
        });
        job.collages = collageData;

        const imageUrls = new Set(
          collageData.flatMap((item) => item.collage).map((item) => item.imageUrl)
        );
        const externalAssets: AIEditExternalAsset[] = [];
        imageUrls.forEach((url) => {
          externalAssets.push({
            type: "image",
            id: url,
            url: new URL(url),
          });
        });

        logger.log("Generating command list");
        const newCommandList = generateRecipeCommandList(
          job.style,
          job.scenesWithStyles,
          collageData,
          job.faceData,
          job.videoMetadata
        );

        const aiEditData = {
          commandList: newCommandList,
          sceneDescriptions: job.sceneDescriptions,
          styleId: job.style.id,
          collageData,
        };
        logger.log("Finishing job");
        latestOnFinish.current(aiEditData, externalAssets, job.transcript, operationIds.current);
      } catch (error) {
        handleError(error);
      } finally {
        currentJob.current = null;
      }
    },
    [client, fetchCollageData, handleError, latestOnFinish]
  );

  const generateServerDrivenAIEdit = useCallback(
    async (transcript: Transcript) => {
      const job = currentJob.current;
      if (!job) {
        return;
      }
      try {
        const res = await generateBackendAIEdit(
          job.style.id,
          transcript,
          job.projectId,
          job.languageCode
        );
        const { commandList, externalAssets } = generateCommandListFromBackend(
          res,
          job.videoMetadata,
          job.faceData
        );
        const aiEditData = {
          commandList,
          sceneDescriptions: [],
          styleId: job.style.id,
          collageData: [],
        };
        latestOnFinish.current(aiEditData, externalAssets, job.transcript, operationIds.current);
      } catch (error) {
        handleError(error);
      } finally {
        currentJob.current = null;
      }
    },
    [generateBackendAIEdit, handleError, latestOnFinish]
  );

  const start = useCallback(
    async ({
      transcript,
      styleId,
      languageCode,
      faceData,
      videoMetadata,
      projectId,
    }: AIEditGeneratorJobRequest) => {
      if (currentJob.current) {
        // Do not call handleError to not reset currentJob
        latestOnError.current(new Error("AI Edit job already in progress"));
        return;
      }

      const style = getAIEditStyleById(styleId);
      if (!style) {
        handleError(new Error("AI Edit style not found"));
        return;
      }

      currentJob.current = {
        transcript,
        style,
        languageCode,
        faceData,
        videoMetadata,
        projectId,
      };
      operationIds.current = {};

      const isClientDriven = isClientDrivenAIEditStyle(style);

      if (isClientDriven) {
        await generateClientSideAIEdit(transcript);
      } else {
        await generateServerDrivenAIEdit(transcript);
      }
    },
    [
      generateClientSideAIEdit,
      handleError,
      latestOnError,
      getAIEditStyleById,
      generateServerDrivenAIEdit,
    ]
  );

  return {
    start,
  };
}
