import type { ImageSegmenterOptions, ImageSegmenter } from "@mediapipe/tasks-vision";
import { createCanvas, freeWebGLCanvas } from "canvas-utils";

import { getAnimatedForegroundPixelMapping } from "./animationUtils";
import {
  BackgroundEffect,
  ForegroundAnimationStyle,
  ForegroundPosition,
} from "./backgroundLayer.types";
import { AnyCanvasRenderingContext2D, PositionFactor } from "./captionDrawing.types";
import { BackgroundImage, ImageSource } from "./captionEngine.types";
import { MICROSECONDS_IN_SECOND } from "./constants/time.constants";
import { getCoverPixelMapping } from "./utils/coordMapping";
import { aiModels, createImageSegmenter } from "./utils/mediapipeAIUtils";
import { degreesToRad, PixelMapping, updateTexture, updateTransparentTexture } from "./utils/webgl";
import {
  drawBackgroundEffect,
  initBackgroundRemover,
  BackgroundPainterProgramData,
} from "./utils/webgl/backgroundProgram";
import { BlurProgramData, applyBlurToTexture, initBlur } from "./utils/webgl/blurProgram";
import { ZoomController } from "./zoomController";

const MAX_RETRIES = 5;
const CUTOUT_BLUR_RADIUS = 20.0;

export class BackgroundEffectPainter {
  private _strict: boolean = false;
  private _imageSegmenter: ImageSegmenter | null = null;
  private _imageSegmenterFailedToLoad: boolean = false;
  private _model = aiModels.multiClassSegmenter;
  private _program: BackgroundPainterProgramData | null = null;
  private _blurProgram: BlurProgramData | null = null;
  private _effects: BackgroundEffect[] = [];
  private _timestamp: number = 0;
  private _zoomController = new ZoomController();
  private _maskCanvas: HTMLCanvasElement | OffscreenCanvas | null = null;
  private _videoScale: PositionFactor = { x: 1, y: 1 };
  private _imagesCanvas: HTMLCanvasElement | OffscreenCanvas | null = null;
  private _area: { width: number; height: number } = {
    width: 1,
    height: 1,
  };
  public currentEffect: BackgroundEffect | null = null;

  private async _initImageSegmenter(retries = 0): Promise<boolean> {
    if (this._imageSegmenter) {
      return true;
    }

    if (this._imageSegmenterFailedToLoad) {
      return false;
    }

    try {
      console.log("[image-segmenter] initializing...");
      this._maskCanvas = createCanvas(1, 1);
      const maskGL = this._maskCanvas.getContext("webgl2", { premultipliedAlpha: false });
      if (!maskGL || !(maskGL instanceof WebGL2RenderingContext)) {
        throw new Error("Failed to get maskCanvas webgl context");
      }
      this._blurProgram = initBlur(maskGL);

      const imageSegmenterOptions: ImageSegmenterOptions = {
        baseOptions: {
          modelAssetPath: this._model.url,
          delegate: "GPU",
        },
        outputCategoryMask: false,
        outputConfidenceMasks: true,
        runningMode: "VIDEO",
        canvas: this._maskCanvas,
      };

      this._imageSegmenter = await createImageSegmenter(imageSegmenterOptions);
      console.log("[image-segmenter] finished initializing");
      return true;
    } catch (err) {
      freeWebGLCanvas(this._maskCanvas);
      this._maskCanvas = null;

      if (retries < MAX_RETRIES) {
        return this._initImageSegmenter(retries + 1);
      }

      console.error("[image-segmenter] failed to initialize", err);
      this._imageSegmenterFailedToLoad = true;
      return false;
    }
  }

  public setArea(width: number, height: number, scale?: PositionFactor) {
    this._area = { width, height };
    this._videoScale = scale ?? this._videoScale;
    if (!this._imagesCanvas) {
      this._imagesCanvas = createCanvas(width, height);
    }
    this._imagesCanvas.width = width;
    this._imagesCanvas.height = height;
  }

  public setStrictMode(strict: boolean) {
    this._strict = strict;
  }

  public setupContext(ctx: WebGLRenderingContext | null) {
    if (ctx) {
      this._program = initBackgroundRemover(ctx);
    } else {
      this._program = null;
    }
  }

  public async setEffects(effects: BackgroundEffect[]) {
    this._effects = effects;
    this.currentEffect =
      this._effects.find(
        (effect) => effect.startTime <= this._timestamp && effect.endTime > this._timestamp
      ) ?? null;

    if (this._effects.some((effect) => effect.cutout)) {
      await this._initImageSegmenter();
    }
  }

  setTimestamp(timestamp: number) {
    this._timestamp = timestamp;
    this.currentEffect =
      this._effects.find(
        (effect) => effect.startTime <= this._timestamp && effect.endTime > this._timestamp
      ) ?? null;
  }

  /**
   * Gets the texture for the asset of the given effect. Will use the given image bitmap for image
   * assets and get the current frame from the video loader for video effects.
   * @param effect - The effect to get the texture for.
   * @private
   */
  private _getAssetTexture(effect: BackgroundEffect, pagBackgroundImage?: ImageSource) {
    if (effect.asset.type === "pag-sequence") {
      return pagBackgroundImage;
    }

    if (effect.asset.type === "image") {
      return effect.asset.data;
    }
    // Calculates the local timestamp for the video asset
    let localTimestamp = (this._timestamp - effect.startTime) * MICROSECONDS_IN_SECOND;
    if (localTimestamp >= effect.asset.data.duration && !effect.loop) {
      return null;
    }
    // Loops the timestamp if the effect is set to loop
    localTimestamp = localTimestamp % effect.asset.data.duration;
    const currentFrame = effect.asset.data.frames.findLast(
      (frame) => frame.timestamp <= localTimestamp
    );
    if (!currentFrame) {
      return null;
    }

    return currentFrame.image;
  }

  private _getBackgroundZoomMappings(
    backgroundPixelMap: PixelMapping,
    foregroundPixelMap: PixelMapping
  ) {
    const zoomPoints = this.currentEffect?.zoomPoints;
    if (!zoomPoints) {
      return {
        background: backgroundPixelMap,
        foreground: foregroundPixelMap,
      };
    }

    const backgroundZoomMappings: {
      background: PixelMapping;
      foreground: PixelMapping;
    } = {
      background: backgroundPixelMap,
      foreground: foregroundPixelMap,
    };

    const dimensions = {
      width: this._area.width * this._videoScale.x,
      height: this._area.height * this._videoScale.y,
    };

    if (zoomPoints.background) {
      backgroundZoomMappings.background = this._zoomController.getZoomedPixelMapping(
        zoomPoints.background,
        backgroundPixelMap,
        this._timestamp,
        dimensions
      );
    }

    if (zoomPoints.foreground) {
      backgroundZoomMappings.foreground = this._zoomController.getZoomedPixelMapping(
        zoomPoints.foreground,
        foregroundPixelMap,
        this._timestamp,
        dimensions
      );
    }

    return backgroundZoomMappings;
  }

  private _adjustForegroundPosition(foregroundPosition?: ForegroundPosition): PixelMapping {
    switch (foregroundPosition) {
      case "bottom-right": {
        return {
          from: { left: 0, right: 1, top: 0, bottom: 1 },
          to: { left: 0.5, right: 1, top: 0.5, bottom: 1 },
        };
      }
      default: {
        return {
          from: { left: 0, right: 1, top: 0, bottom: 1 },
          to: { left: 0, right: 1, top: 0, bottom: 1 },
        };
      }
    }
  }

  private _getForegroundPixelMapping(
    foregroundPosition?: ForegroundPosition,
    foregroundAnimation?: ForegroundAnimationStyle,
    rotation?: number
  ) {
    const foregroundPixelMap = this._adjustForegroundPosition(foregroundPosition);
    foregroundPixelMap.rotation = degreesToRad(rotation ?? 0);

    if (!foregroundAnimation) {
      return foregroundPixelMap;
    }

    return getAnimatedForegroundPixelMapping(
      foregroundPixelMap,
      foregroundAnimation,
      this.currentEffect?.startTime ?? Infinity,
      this._timestamp
    );
  }

  private _drawBackgroundImages(
    glCtx: WebGLRenderingContext,
    imgTexture: WebGLTexture,
    currentImages: BackgroundImage[]
  ) {
    const ctx = this._imagesCanvas?.getContext("2d") as AnyCanvasRenderingContext2D | null;
    if (!ctx || !this._imagesCanvas) {
      return;
    }
    ctx.clearRect(0, 0, this._imagesCanvas.width, this._imagesCanvas.height);
    const { width, height } = this._imagesCanvas;

    for (const currentImage of currentImages) {
      const { imageBox } = currentImage;
      if (!imageBox) {
        continue;
      }
      const { x: startX, y: startY, width: imageWidth, height: imageHeight, rotation } = imageBox;
      ctx.save();
      ctx.globalAlpha = imageBox.opacity ?? 1;
      ctx.beginPath();
      ctx.rect(0, 0, width, height);
      ctx.clip();
      ctx.scale(1 / this._videoScale.x, 1 / this._videoScale.y);
      ctx.translate(startX * this._videoScale.x, startY * this._videoScale.y);
      ctx.rotate(rotation);
      ctx.drawImage(currentImage.image, -imageWidth / 2, -imageHeight / 2, imageWidth, imageHeight);
      ctx.restore();
    }
    updateTransparentTexture(glCtx, imgTexture, this._imagesCanvas);
  }

  private async _removeFrameBackground(
    glCtx: WebGLRenderingContext,
    videoFrame: TexImageSource,
    zoomedMappings: { background: PixelMapping; foreground: PixelMapping },
    stroke?: boolean
  ) {
    if (!this._imageSegmenter) {
      const initialized = await this._initImageSegmenter();

      if (this._strict && !initialized) {
        throw new Error("Failed to initialize Image Segmenter.");
      }
    }

    return new Promise<void>((resolve, reject) => {
      if (!this._imageSegmenter) {
        if (!this._program) {
          return reject(new Error("Missing necessary components for rendering."));
        }

        updateTransparentTexture(glCtx, this._program.textures.videoTexture, videoFrame);
        drawBackgroundEffect(glCtx, this._program, zoomedMappings);

        return resolve();
      }

      const startTimeMs = performance.now();
      this._imageSegmenter.segmentForVideo(videoFrame, startTimeMs, async (result) => {
        try {
          const confidenceMasks = result.confidenceMasks;
          if (!confidenceMasks || !this._maskCanvas || !this._program || !this._blurProgram) {
            throw new Error("Missing necessary components for rendering.");
          }

          this._maskCanvas.width = glCtx.canvas.width;
          this._maskCanvas.height = glCtx.canvas.height;

          const maskTexture = confidenceMasks[0].getAsWebGLTexture();
          const maskGL = this._maskCanvas.getContext("webgl2", { premultipliedAlpha: false });
          if (!maskGL || !(maskGL instanceof WebGL2RenderingContext)) {
            throw new Error("Failed to get maskCanvas webgl context");
          }

          applyBlurToTexture(maskGL, this._blurProgram, maskTexture, CUTOUT_BLUR_RADIUS);
          updateTransparentTexture(glCtx, this._program.textures.maskTexture, this._maskCanvas);
          updateTransparentTexture(glCtx, this._program.textures.videoTexture, videoFrame);
          drawBackgroundEffect(glCtx, this._program, zoomedMappings, stroke);

          resolve();
        } catch (err) {
          console.error(`Failed to construct ImageData: ${err}`);

          if (!this._strict) {
            return resolve();
          }

          reject(err);
        }
      });
    });
  }

  public async paint(
    glCtx: WebGLRenderingContext,
    glTexture: WebGLTexture,
    glCanvas: HTMLCanvasElement | OffscreenCanvas,
    videoFrame: TexImageSource | null,
    backgroundImages: BackgroundImage[],
    pagBackgroundImage?: ImageSource
  ) {
    if (!this._program || !this.currentEffect) {
      return;
    }
    const assetImage = this._getAssetTexture(this.currentEffect, pagBackgroundImage);
    if (!assetImage) {
      return;
    }

    this._drawBackgroundImages(glCtx, this._program.textures.imageTexture, backgroundImages);

    const foregroundPixelMap = this._getForegroundPixelMapping(
      this.currentEffect.foregroundPosition,
      this.currentEffect.foregroundAnimation,
      this.currentEffect.rotation
    );

    const backgroundPixelMap = getCoverPixelMapping(
      this._area.width,
      this._area.height,
      assetImage.width,
      assetImage.height
    );

    const zoomedMappings = this._getBackgroundZoomMappings(backgroundPixelMap, foregroundPixelMap);

    if (!this.currentEffect.cutout || !videoFrame) {
      // Update the gl texture with the background asset
      updateTexture(glCtx, glTexture, assetImage);
      drawBackgroundEffect(glCtx, this._program, zoomedMappings);
      return;
    }

    updateTexture(glCtx, this._program.textures.backgroundTexture, assetImage);
    await this._removeFrameBackground(glCtx, videoFrame, zoomedMappings, this.currentEffect.stroke);
    // Update the gl texture with the background + cutout
    updateTexture(glCtx, glTexture, glCanvas);
  }

  public destroy() {
    try {
      this._imageSegmenter?.close();
      this._imageSegmenter = null;
    } catch (err) {
      console.error("Failed to close image segmenter", err);
    }

    this.currentEffect = null;
    this._effects = [];
    this._program = null;
    freeWebGLCanvas(this._maskCanvas);
    this._imagesCanvas = null;
    this._maskCanvas = null;
    this._blurProgram = null;
    this._zoomController.destroy();
  }
}
