import { DevLogger } from "dev-logger";
import type { PAGComposition } from "libpag/types/web/src/pag-composition";
import type { PAGFile } from "libpag/types/web/src/pag-file";
import type { PAGView } from "libpag/types/web/src/pag-view";
import { PAG } from "libpag/types/web/src/types";

import { AnimationItem } from "./animationPainter.types";
import { MICROSECONDS_IN_SECOND } from "./constants/time.constants";
import {
  moveAndScaleLayer,
  prepareImageReplacements,
  replaceCompositionImage,
  replaceCompositionText,
  setPAGViewAutoClear,
  setRoundedDuration,
  stretchLayerToFitComposition,
  TIME_STRETCH_MODE_REPEAT,
  TIME_STRETCH_MODE_SCALE,
} from "./utils/pagUtils";

const logger = new DevLogger("[animation-layer]");

export class AnimationPainter {
  private readonly _pagInstance: PAG;
  private readonly _onLoadAnimation: (animationId: string) => Promise<PAGFile | null>;
  private _webglCanvas: HTMLCanvasElement | OffscreenCanvas | null;
  private _pagView: PAGView | null = null;
  private _pagComposition: PAGComposition | null = null;
  private _compositionObjects: { destroy: () => void }[] = [];
  private _animations: AnimationItem[] = [];
  private _skipDrawing: boolean = true;
  private _width: number = 0;
  private _height: number = 0;
  private _timestamp: number = 0;
  private _setupPromise: Promise<void> | null = null;
  private _queuedSetups: number = 0;

  constructor(
    pagInstance: PAG,
    onLoadAnimation: (animationId: string) => Promise<PAGFile | null>,
    webglCanvas?: HTMLCanvasElement | OffscreenCanvas | null
  ) {
    this._pagInstance = pagInstance;
    this._onLoadAnimation = onLoadAnimation;
    this._webglCanvas = webglCanvas || null;
  }

  public setAnimations(animations: AnimationItem[]) {
    if (this._animations === animations) {
      // No change, no need to run a possibly expensive setup
      return;
    }
    this._animations = animations;
    this._setup();
    this._updateProgress();
  }

  public setTimestamp(timestamp: number) {
    this._timestamp = timestamp;
    this._updateProgress();
  }

  private _shouldSkipCurrentAnimation() {
    const currentAnimationItems = this._animations.filter(({ startTime, endTime }) => {
      return startTime <= this._timestamp && endTime > this._timestamp;
    });

    if (!currentAnimationItems.length) {
      return true;
    }

    let minStartTime = Infinity;
    let maxEndTime = 0;

    currentAnimationItems.forEach(({ startTime, endTime }) => {
      minStartTime = Math.min(minStartTime, startTime);
      maxEndTime = Math.max(maxEndTime, endTime);
    });

    const duration = maxEndTime - minStartTime;
    const localTime = this._timestamp - minStartTime;
    const progress = Math.abs(localTime / duration);
    return progress > 1;
  }

  public setCanvas(canvas: HTMLCanvasElement | OffscreenCanvas | null) {
    if (this._webglCanvas === canvas) {
      // No change, no need to run a possibly expensive setup
      return;
    }
    this._webglCanvas = canvas;
    // The PAGView needs to be recreated when the canvas changes
    this._destroyView();
    this._setup();
  }

  public setArea(width: number, height: number) {
    if (this._width === width && this._height === height) {
      // No change, no need to run a possibly expensive setup
      return;
    }
    this._width = width;
    this._height = height;
    this._setup();
  }

  private _updateProgress() {
    if (!this._pagComposition) {
      return;
    }
    const localTime = this._timestamp * MICROSECONDS_IN_SECOND;
    const duration = this._pagComposition.duration();
    const progress = Math.abs(localTime / duration);
    this._skipDrawing = this._shouldSkipCurrentAnimation();
    this._pagComposition.setProgress(progress);
  }

  private _setup() {
    if (this._queuedSetups > 0) {
      return;
    }
    this._queuedSetups = this._queuedSetups + 1;
    const newPromise = this._setupInternal().catch((error) => logger.error(error));
    this._setupPromise = this._setupPromise
      ? this._setupPromise.then(() => newPromise)
      : newPromise;
  }

  private _destroyComposition() {
    this._compositionObjects.forEach((layer) => layer.destroy());
    this._pagComposition?.destroy();
    this._pagComposition = null;
    this._compositionObjects = [];
  }

  private _destroyView() {
    try {
      this._pagView?.destroy();
    } catch (err) {
      logger.log("Recovering from libpag error:", err);
      this._pagView = null;
    }
  }

  private async _setupInternal() {
    this._queuedSetups = this._queuedSetups - 1;

    if (!this._width || !this._height || !this._webglCanvas || this._animations.length === 0) {
      // No frame - clears everything
      this._destroyView();
      this._destroyComposition();
      return;
    }

    // Init PAG composition
    const { pagComposition, compositionObjects } = await this._generateComposition(
      this._width,
      this._height
    );

    if (!pagComposition) {
      // No composition - clears everything
      this._destroyView();
      this._destroyComposition();
      return;
    }

    // Reuses the existing PAGView, only creating a new one if needed
    if (!this._pagView) {
      this._pagView =
        (await this._pagInstance.PAGView.init(pagComposition, this._webglCanvas, {
          useScale: false,
        })) ?? null;
      if (this._pagView) {
        // Ensures that the PAGView does NOT clear the canvas before
        // drawing, so it acts like an overlay
        setPAGViewAutoClear(this._pagView, false);
      }
    } else {
      this._pagView.setComposition(pagComposition);
    }
    // Clears the old objects
    this._destroyComposition();
    // Assigns the new objects
    this._compositionObjects = compositionObjects;
    this._pagComposition = pagComposition;
    // Updates the composition progress
    this._updateProgress();
  }

  private async _handleLoadAnimationComponent(id?: string | null) {
    if (!id) {
      return null;
    }
    const animation = await this._onLoadAnimation(id);
    if (!animation) {
      throw new Error(`Failed to load animation ${id}`);
    }
    return animation;
  }

  private async _addAnimationToComposition(
    pagComposition: PAGComposition,
    animation: AnimationItem
  ) {
    const compositionObjects: { destroy: () => void }[] = [];
    const introFile = await this._handleLoadAnimationComponent(animation.introId);
    const holdFile = await this._handleLoadAnimationComponent(animation.holdId);
    const outroFile = await this._handleLoadAnimationComponent(animation.outroId);
    if (!introFile && !holdFile && !outroFile) {
      throw new Error(`Invalid animation`);
    }

    let startTime = Math.round(animation.startTime * MICROSECONDS_IN_SECOND);
    let endTime = Math.round(animation.endTime * MICROSECONDS_IN_SECOND);
    const textReplacements = animation?.replacements?.text ?? {};
    const imageReplacements = prepareImageReplacements(
      this._pagInstance,
      animation?.replacements?.imageLayers ?? {}
    );
    const scaleAndPositionLayer = (layer: PAGFile) => {
      if (animation.positioning != null && animation.positioning.type !== "fullscreen") {
        // Animations with image replacements use scaling relative to the images themselves
        // so we have to compensate
        let imageScale = 1;
        const firstImage = Object.entries(imageReplacements)[0]?.[1];
        if (firstImage) {
          imageScale = Math.min(
            layer.width() / firstImage.width(),
            layer.height() / firstImage.height()
          );
        }
        moveAndScaleLayer(
          this._pagInstance,
          layer,
          pagComposition,
          animation.positioning.x,
          animation.positioning.y,
          (animation.positioning.scale ?? 1) / imageScale
        );
      } else {
        stretchLayerToFitComposition(
          this._pagInstance,
          layer,
          pagComposition,
          animation.positioning?.fit
        );
      }
    };
    compositionObjects.push(...Object.values(imageReplacements));
    if (introFile) {
      const layer = introFile.copyOriginal();
      replaceCompositionText(layer, textReplacements);
      replaceCompositionImage(layer, imageReplacements);
      scaleAndPositionLayer(layer);
      layer.setStartTime(startTime);
      startTime += introFile.duration();
      pagComposition?.addLayer(layer);
      compositionObjects.push(layer);
      logger.log("Setting up intro", {
        startTime: layer.startTime(),
        endTime: layer.startTime() + layer.duration(),
      });
    }

    if (outroFile && endTime > startTime) {
      const outroDuration = Math.min(outroFile.duration(), endTime - startTime);
      const layer = outroFile.copyOriginal();
      replaceCompositionText(layer, textReplacements);
      replaceCompositionImage(layer, imageReplacements);
      scaleAndPositionLayer(layer);
      layer.setDuration(outroDuration);
      endTime -= layer.duration();
      layer.setTimeStretchMode(TIME_STRETCH_MODE_SCALE);
      layer.setStartTime(endTime);
      pagComposition?.addLayer(layer);
      compositionObjects.push(layer);
      logger.log("Setting up outro", {
        startTime: layer.startTime(),
        endTime: layer.startTime() + layer.duration(),
      });
    }

    if (holdFile && endTime > startTime) {
      const layer = holdFile.copyOriginal();
      replaceCompositionText(layer, textReplacements);
      replaceCompositionImage(layer, imageReplacements);
      scaleAndPositionLayer(layer);
      const holdDuration = endTime - startTime;
      layer.setTimeStretchMode(TIME_STRETCH_MODE_REPEAT);
      layer.setStartTime(startTime);
      setRoundedDuration(layer, holdDuration);
      pagComposition?.addLayer(layer);
      compositionObjects.push(layer);
      logger.log("Setting up hold", {
        startTime: layer.startTime(),
        endTime: layer.startTime() + layer.duration(),
      });
    }
    return compositionObjects;
  }

  private async _generateComposition(width: number, height: number) {
    const compositionObjects: { destroy: () => void }[] = [];
    const pagComposition = this._pagInstance.PAGComposition.make(width, height);

    for (const animation of this._animations) {
      const newCompositionObjects = await this._addAnimationToComposition(
        pagComposition,
        animation
      );
      compositionObjects.push(...newCompositionObjects);
    }
    return {
      pagComposition,
      compositionObjects,
    };
  }

  /**
   * Ensures that the current frame is prepared for drawing.
   */
  public async prepare() {
    let currentPromise: Promise<void> | null = null;
    while (currentPromise !== this._setupPromise) {
      currentPromise = this._setupPromise;
      await currentPromise;
    }
    await this._pagView?.prepare();
  }

  /**
   * Draws the current frame to the canvas.
   */
  public async drawFrame() {
    if (this._skipDrawing || !this._pagView) {
      return;
    }

    try {
      await this._pagView.flush();
    } catch (err) {
      logger.log("Recovering from libpag error:", err);
      // libpag occasionally throws a Memory Out of Bounds error with an unknown cause.
      // This will ensure we recover from a crash by destroying the pagView and setting it up again.
      this._destroyView();
      await this._setupInternal();
    }
  }

  public destroy() {
    this._destroyComposition();
    this._destroyView();
    this._animations = [];
    this._setupPromise = null;
  }
}
