import { useRef, useState } from "react";

import Search from "~/components/icons/Search";

import { SearchBoxContainer, SearchBoxInput } from "./SearchBox.styles";

export const SearchBox = ({
  disabled,
  onSearch,
  placeholder,
}: {
  disabled?: boolean;
  onSearch?: (search: string) => void;
  placeholder: string;
}) => {
  const [inputText, setInputText] = useState("");
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value ?? "";
    onSearch?.(searchText);
    setInputText(searchText);
  };

  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <SearchBoxContainer
      disabled={disabled}
      hasText={Boolean(inputText)}
      onMouseDown={(e: React.MouseEvent) => {
        // do nothing if clicking on the actual input
        if ((e.target as HTMLElement)?.tagName === "INPUT") {
          return;
        }

        // otherwise focus and swallow event
        inputRef?.current?.focus();
        e.preventDefault();
      }}
    >
      <Search />
      <SearchBoxInput
        disabled={disabled}
        onChange={handleOnChange}
        ref={inputRef}
        type="search"
        placeholder={placeholder}
      />
    </SearchBoxContainer>
  );
};
