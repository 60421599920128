export const adSetListResponse = [
  {
    id: "ad_set_123",
    title: "Testing 123 Ad Set",
    state: "PROCESSING",
    adVariants: [
      {
        id: "ad_variant_123",
        state: "PROCESSING",
        progress: 20,
        hook: "",
        body: "",
        creatorId: "",
        creatorVariantId: "",
        aspectRatio: "9:16",
        songId: "",
        languageCode: "",
        language: "",
        transcription: {},
        thumbnailUrl:
          "https://captions-cdn.xyz/ai-avatars/ugc/v2/Ak9ymgLv981z8SdHnuZZ/thumb_512h.jpg",
        videoUrl: "",
        duration: 60,
      },
      {
        id: "ad_variant_456",
        state: "PROCESSING",
        progress: 20,
        hook: "",
        body: "",
        creatorId: "",
        creatorVariantId: "",
        aspectRatio: "9:16",
        songId: "",
        languageCode: "",
        language: "",
        transcription: {},
        thumbnailUrl:
          "https://captions-cdn.xyz/ai-avatars/ugc/v2/Sn35PYiLt2r9J1mzbMFG/thumb_512h.jpg",
        videoUrl: "",
        duration: 60,
      },
      {
        id: "ad_variant_789",
        state: "PROCESSING",
        progress: 20,
        hook: "",
        body: "",
        creatorId: "",
        creatorVariantId: "",
        aspectRatio: "9:16",
        songId: "",
        languageCode: "",
        language: "",
        transcription: {},
        thumbnailUrl:
          "https://captions-cdn.xyz/ai-avatars/ugc/v2/qP45igVq4scRkdwsmWCu/thumb_512h.jpg",
        videoUrl: "",
        duration: 60,
      },
      {
        id: "ad_variant_101",
        state: "PROCESSING",
        progress: 20,
        hook: "",
        body: "",
        creatorId: "",
        creatorVariantId: "",
        aspectRatio: "9:16",
        songId: "",
        languageCode: "",
        language: "",
        transcription: {},
        thumbnailUrl:
          "https://captions-cdn.xyz/ai-avatars/ugc/v2/VBWXTEANr7s3u8NwpkZN/thumb_512h.jpg",
        videoUrl: "",
        duration: 60,
      },
    ],
  },
  {
    id: "ad_set_456",
    title: "Other Test Ad Set",
    state: "COMPLETE",
    adVariants: [
      {
        id: "ad_variant_2123",
        state: "COMPLETE",
        progress: 20,
        hook: "",
        body: "",
        creatorId: "",
        creatorVariantId: "",
        aspectRatio: "9:16",
        songId: "",
        languageCode: "",
        language: "",
        transcription: {},
        thumbnailUrl:
          "https://captions-cdn.xyz/ai-avatars/ugc/v2/Ak9ymgLv981z8SdHnuZZ/thumb_512h.jpg",
        videoUrl: "",
        duration: 60,
      },
      {
        id: "ad_variant_2456",
        state: "COMPLETE",
        progress: 20,
        hook: "",
        body: "",
        creatorId: "",
        creatorVariantId: "",
        aspectRatio: "9:16",
        songId: "",
        languageCode: "",
        language: "",
        transcription: {},
        thumbnailUrl:
          "https://captions-cdn.xyz/ai-avatars/ugc/v2/Sn35PYiLt2r9J1mzbMFG/thumb_512h.jpg",
        videoUrl: "",
        duration: 60,
      },
      {
        id: "ad_variant_2789",
        state: "COMPLETE",
        progress: 20,
        hook: "",
        body: "",
        creatorId: "",
        creatorVariantId: "",
        aspectRatio: "9:16",
        songId: "",
        languageCode: "",
        language: "",
        transcription: {},
        thumbnailUrl:
          "https://captions-cdn.xyz/ai-avatars/ugc/v2/qP45igVq4scRkdwsmWCu/thumb_512h.jpg",
        videoUrl: "",
        duration: 60,
      },
      {
        id: "ad_variant_2101",
        state: "COMPLETE",
        progress: 20,
        hook: "",
        body: "",
        creatorId: "",
        creatorVariantId: "",
        aspectRatio: "9:16",
        songId: "",
        languageCode: "",
        language: "",
        transcription: {},
        thumbnailUrl:
          "https://captions-cdn.xyz/ai-avatars/ugc/v2/VBWXTEANr7s3u8NwpkZN/thumb_512h.jpg",
        videoUrl: "",
        duration: 60,
      },
    ],
  },
];
