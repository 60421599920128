import { BackendRequestOptions, BackendServicesClient } from "~/context/BackendServicesContext";

import { AIWord } from "../../AIScenes.types";

export interface CollageRequest {
  phrase: string;
  phraseWords: AIWord[];
  numImages: number;
  sceneStyle?: string;
  sceneIndex: number;
}

export interface StartCollageJobRequest {
  transcriptText: string;
  languageCode: string;
  collageRequests: CollageRequest[];
}

export interface AICollageOperation {
  operationId: string;
}

interface StartCollageJobResponse {
  success: boolean;
  data: AICollageOperation;
}

export async function startCollageJob(
  client: BackendServicesClient,
  request: StartCollageJobRequest,
  options?: Pick<BackendRequestOptions, "signal">
): Promise<AICollageOperation> {
  const result = await client.post<StartCollageJobResponse>(
    "/proxy/v1/ai-edit/image/collage/batch/submit",
    {
      transcript: request.transcriptText,
      languageCode: request.languageCode,
      collageRequests: request.collageRequests,
    },
    options
  );
  if (!result.data.success) {
    throw new Error("Failed to start collage job", { cause: result.data });
  }
  return result.data.data;
}

export interface ReplaceCollageImageRequest {
  description: string;
  sceneStyle: string;
  transcript: string;
  phrase: string;
}

export async function startReplaceCollageImageJob(
  client: BackendServicesClient,
  request: ReplaceCollageImageRequest,
  options?: Pick<BackendRequestOptions, "signal">
): Promise<AICollageOperation> {
  const result = await client.post<StartCollageJobResponse>(
    "/proxy/v1/ai-edit/image/replace/submit",
    request,
    options
  );
  if (!result.data.success) {
    throw new Error("Failed to start replace collage image job", { cause: result.data });
  }
  return result.data.data;
}
