import { init } from "mixpanel-browser";

import { createAsyncInitializedStore } from "~/utils/zustand-store";

const MIXPANEL_TOKEN = "a1e657739c18f5edc370835850b16a1f";

export const [getMixpanelStore, useMixpanelStore, getMixpanelInstance] =
  createAsyncInitializedStore({
    name: "MixpanelStore",
    initializer: (properties: Record<string, unknown> = {}) => {
      const instance = init(MIXPANEL_TOKEN, {}, "react-mixpanel-browser");

      instance.register(properties);

      return instance;
    },
    methods: ({ queue }) => ({
      identify: (id: string) => {
        queue((mixpanel) => mixpanel.identify(id));
      },
      setUserProps: (properties: Record<string, unknown>) => {
        queue((mixpanel) => mixpanel.people.set(properties));
      },
      reset: () => {
        queue((mixpanel) => mixpanel.reset());
      },
      track: (event: string, properties?: Record<string, unknown>) => {
        queue((mixpanel) => mixpanel.track(event, properties));
      },
      updateGlobalProperties: (properties: Record<string, unknown>) => {
        queue((mixpanel) => mixpanel.register(properties));
      },
    }),
  });
