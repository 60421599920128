import { getPrimarySceneSegments } from "../../utils/genPrimarySceneSegments";

import { EffectsCommandGeneratorFunction } from "./types";

export const generateBackgroundCommandsForScene: EffectsCommandGeneratorFunction = (scene) => {
  if (!scene.style.sceneBackground) {
    return null;
  }

  const { assetId, cutout, stroke, zoomStyle, rotation, foregroundPosition, foregroundAnimation } =
    scene.style.sceneBackground;
  const segments = getPrimarySceneSegments(scene);

  return {
    edl: {
      segments,
      wrapMode: "repeat",
    },
    renderEffect: {
      renderEffectType: "scene-background",
      assetId,
      cutout,
      stroke,
      zoomStyle,
      foregroundPosition,
      foregroundAnimation,
      rotation,
    },
  };
};
