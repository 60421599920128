export type Result<T> = { ok: true; value: T } | { ok: false; error: string };

export function Ok<T = void>(): Result<T>;
export function Ok<T>(value: T): Result<T>;
export function Ok<T>(value?: T): Result<T | undefined> {
  return { ok: true, value };
}

export const Err = (error: string): Result<never> => ({ ok: false, error });
export const unwrap = <T>(result: Result<T>) => {
  if (result.ok) {
    return result.value;
  } else {
    throw new Error(result.error);
  }
};
