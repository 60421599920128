import {
  ActiveWordBackground,
  AnimationStyle,
  AnimationTarget,
  BackgroundStyle,
  CapitalizationStyle,
  CaptionStylePreset,
  Color,
  EmojiPosition,
  EmojiTiming,
  Font,
  Glow,
  LayoutAlignment,
  LineBreakMode,
  MovementAutoMotion,
  OnPunctuationAndPauseLineBreak,
  Point,
  ScaleAutoMotion,
  Shadow,
  SizeConstraints,
  SplitsInLineBreak,
  Stroke,
  UpcomingWords,
  WordCountLineBreak,
} from "captions-engine";
import { z } from "zod";

type ZodSchema<T> = z.ZodType<T, z.ZodTypeDef, unknown>;

export const pointSchema: ZodSchema<Point> = z.object({
  x: z.number().default(0),
  y: z.number().default(0),
});

export const colorSchema: ZodSchema<Color> = z.object({
  red: z.number().default(0),
  green: z.number().default(0),
  blue: z.number().default(0),
  alpha: z.number().default(0),
});

export const presetInt64Schema: ZodSchema<number> = z
  .union([
    z.number(),
    z.object({ low: z.number(), high: z.number(), unsigned: z.boolean().default(false) }),
  ])
  .transform((value) => {
    if (typeof value === "number") {
      return value;
    }
    if (!value.unsigned && value.high >= 2 ** 63) {
      return -(2 ** 64 - (value.high * 2 ** 32 + value.low));
    } else {
      return value.high * 2 ** 32 + value.low;
    }
  });

export const fontSchema: ZodSchema<Font> = z.object({
  fontName: z.string(),
  fontSize: z.number(),
});

export const strokeSchema: ZodSchema<Stroke> = z.object({
  width: z.number().default(0),
  color: colorSchema.default({}),
});

export const shadowSchema: ZodSchema<Shadow> = z.object({
  radius: z.number().default(0),
  color: colorSchema.default({}),
  offset: pointSchema.default({}),
});

export const gradientSchema = z.object({
  startPosition: pointSchema,
  endPosition: pointSchema,
  firstColor: colorSchema,
  secondColor: colorSchema,
});

export const activeWordBackgroundSchema: ZodSchema<ActiveWordBackground> = z.object({
  cornerRadius: z.number().default(28),
  color: colorSchema.optional().default({
    red: 0.4941,
    green: 0.0706,
    blue: 1.0,
    alpha: 0.0,
  }),
  animationStyle: z.nativeEnum(AnimationStyle).default(AnimationStyle.animationStylePopIn),
  gradient: gradientSchema.optional(),
});

export const backgroundStyleSchema: ZodSchema<BackgroundStyle> = z.object({
  cornerRadius: z.number().default(0),
  color: colorSchema.optional(),
  width: z.number().default(0),
});

export const sizeConstraintsSchema: ZodSchema<SizeConstraints> = z.object({
  width: z.number().nullable().optional(),
  height: z.number().nullable().optional(),
  minFontSize: z.number().nullable().optional(),
});

export const wordCountLineBreakSchema: ZodSchema<WordCountLineBreak> = z.object({
  count: presetInt64Schema,
});

export const onPunctuationAndPauseLineBreakSchema: ZodSchema<OnPunctuationAndPauseLineBreak> =
  z.object({
    seconds: z.number(),
    characterCount: presetInt64Schema,
  });

export const splitsInLineBreakSchema: ZodSchema<SplitsInLineBreak> = z.object({
  seconds: z.number(),
});

export const lineBreakModeSchema: ZodSchema<LineBreakMode> = z.union([
  z.object({
    wordCount: wordCountLineBreakSchema,
  }),
  z.object({
    onPunctuationAndPause: onPunctuationAndPauseLineBreakSchema,
  }),
  z.object({
    splitsIn: splitsInLineBreakSchema,
  }),
  z.object({
    random: z.object({}),
  }),
]);

export const movementAutoMotionSchema: ZodSchema<MovementAutoMotion> = z.object({
  enabled: z.boolean().default(false),
  speed: z.number().default(0),
  offset: pointSchema.default({}),
});

export const scaleAutoMotionSchema: ZodSchema<ScaleAutoMotion> = z.object({
  enabled: z.boolean().default(false),
  speed: z.number().default(0),
  maxScale: z.number().default(0),
});

export const upcomingWordsSchema: ZodSchema<UpcomingWords> = z.object({
  enabled: z.boolean().default(false),
  color: colorSchema.default({}),
});

export const glowSchema: ZodSchema<Glow> = z.object({
  enabled: z.boolean().default(false),
  radius: z.number().default(0),
  opacity: z.number().default(0),
  offset: pointSchema.default({}),
  emphasisOnly: z.boolean().default(false),
});

export const captionStylePresetSchema: ZodSchema<CaptionStylePreset> = z
  .object({
    font: fontSchema,
    letterSpacing: z.number().nullable().optional(),
    lineSpacing: z.number().default(0),
    stroke: strokeSchema.default({}),
    shadow: shadowSchema.default({}),
    textOffset: pointSchema.default({}),
    layoutAlignment: z
      .nativeEnum(LayoutAlignment)
      .default(LayoutAlignment.layoutAlignmentUnspecified),
    lineBreakMode: lineBreakModeSchema,
    linesPerPage: presetInt64Schema,
    capitalization: z
      .nativeEnum(CapitalizationStyle)
      .default(CapitalizationStyle.capitalizationStyleUnspecified),
    hidePunctuation: z.boolean().default(false),
    lineFitWrapEnabled: z.boolean().default(false),
    colorApplicationBackgroundEnabled: z.boolean().default(false),
    randomizeRotation: z.boolean().default(false),
    isMultilineBox: z.boolean().default(false),
    activeWordBackground: activeWordBackgroundSchema.default({}),
    animationTarget: z
      .nativeEnum(AnimationTarget)
      .default(AnimationTarget.animationTargetUnspecified),
    animationStyle: z.nativeEnum(AnimationStyle).default(AnimationStyle.animationStyleUnspecified),
    backgroundStyle: backgroundStyleSchema.default({}),
    backgroundShadow: shadowSchema.default({}),
    sizeConstraints: sizeConstraintsSchema.default({}),
    autoSizeFitLines: z.boolean().default(false),
    activeColorEnabled: z.boolean().default(false),
    activeSizeEnabled: z.boolean().default(false),
    movementAutoMotion: movementAutoMotionSchema.default({}),
    scaleAutoMotion: scaleAutoMotionSchema.default({}),
    backgroundPadding: pointSchema.default({}),
    upcomingWords: upcomingWordsSchema.default({}),
    activeBoldEnabled: z.boolean().default(false),
    superSizeNoBreak: z.boolean().default(false),
    glow: glowSchema.default({}),
    // local fields
    colorApplicationPageEnabled: z.boolean().optional(),
    colorApplicationLineEnabled: z.boolean().optional(),
  })
  .transform((data) => {
    const colorApplicationPageEnabled = data.colorApplicationPageEnabled ?? data.isMultilineBox;
    const colorApplicationLineEnabled =
      data.colorApplicationLineEnabled ??
      (!data.isMultilineBox && data.colorApplicationBackgroundEnabled);

    return {
      ...data,
      colorApplicationPageEnabled,
      colorApplicationLineEnabled,
    };
  });

export const captionStylePresetEntrySchema = z
  .object({
    tags: z.array(z.string()),
    audienceSegments: z.array(z.number()),
    categories: z.array(z.string()),
    styleId: z.string(),
    content: z.union([
      captionStylePresetSchema,
      z.object({
        custom: captionStylePresetSchema,
      }),
    ]),
    canEdit: z.boolean().default(false),
    styleName: z.string(),
    numViews: z.number().optional(),
  })
  .transform((value) => {
    return {
      ...value,
      content: "custom" in value.content ? value.content.custom : value.content,
    };
  });

export type CaptionStylePresetEntry = z.infer<typeof captionStylePresetEntrySchema>;

export const DEFAULT_EMOJI_SIZE = 21;

export const captionEmojiSettingsSchema = z.object({
  position: z.nativeEnum(EmojiPosition).default(EmojiPosition.emojiPositionUnspecified),
  timing: z.nativeEnum(EmojiTiming).default(EmojiTiming.emojiTimingUnspecified),
  size: z.number().default(DEFAULT_EMOJI_SIZE),
  aiEnabled: z.boolean().default(false),
});

export type CaptionEmojiSettings = z.infer<typeof captionEmojiSettingsSchema>;

const emphasisEffectsSchema = z.object({
  underline: z.boolean().optional(),
  emphasize: z.boolean().optional(),
  supersize: z.boolean().optional(),
});

export const captionEmphasisSettingsSchema = z.object({
  keywords: emphasisEffectsSchema.optional().default({}),
  pictoralWords: emphasisEffectsSchema.optional().default({}),
  nouns: emphasisEffectsSchema.optional().default({}),
  aiEnabled: z.boolean().default(false),
});

export type CaptionEmphasisSettings = z.infer<typeof captionEmphasisSettingsSchema>;

export const captionTemplateSchema = z.object({
  colors: z.object({
    active: colorSchema,
    emphasis: colorSchema,
    primary: colorSchema,
    wordBackground: colorSchema,
  }),
  displayName: z.string(),
  id: z.string(),
  style: captionStylePresetEntrySchema,
  emojiSettings: captionEmojiSettingsSchema.default({}),
  emphasisSettings: captionEmphasisSettingsSchema.default({}),
});

export type CaptionTemplate = z.infer<typeof captionTemplateSchema>;
