import { PseudoRandomGenerator } from "stable-pseudo-rng";

import { CommandList } from "../../CommandList.types";
import { EDLdata } from "../../EDL.types";
import { ClientDrivenAIEditVideoStyle } from "../../services/AIEditStyles";

export const generateGlobalCommands = (
  style: ClientDrivenAIEditVideoStyle,
  mainTotal: number,
  prng: PseudoRandomGenerator
): CommandList => {
  const globalEDL: EDLdata = {
    segments: [[0, mainTotal, 0, mainTotal]],
    wrapMode: "repeat",
  };

  return style.globalStyleConfig.map(({ builder }) => {
    const { assetId, blendMode } = builder(prng);
    return {
      edl: globalEDL,
      renderEffect: {
        renderEffectType: "overlay-effect",
        assetId,
      },
      blendMode,
    };
  });
};
