import {
  CreateAiEditVideoPollResponse,
  CreateAiEditVideoPollResponse_State,
  CreateAiEditVideoSubmitResponse,
} from "@shared/generated/typescript/aiEdit/api/CreateAiEditVideoTemplate";
import {
  createAiEditVideoPollRequestSchema,
  createAiEditVideoPollResponseSchema,
  createAiEditVideoSubmitRequestSchema,
  createAiEditVideoSubmitResponseSchema,
} from "@shared/generated/typescript/aiEdit/api/CreateAiEditVideoTemplateSchema";
import { z } from "zod";

import { createUsePollingHook } from "~/utils/network/polling-helper";
import { createUseMutationHook } from "~/utils/network/query-helpers";

export const usePollForAiEditVideoCreation = createUsePollingHook({
  path: "ai-edit/poll",
  responseSchema: z.preprocess(
    (value) =>
      CreateAiEditVideoPollResponse.fromJson(value as string, { ignoreUnknownFields: true }),
    createAiEditVideoPollResponseSchema
  ),
  paramsSchema: createAiEditVideoPollRequestSchema,
  determinePollingStatus: (data, { done, failed, pending }) => {
    switch (data.state) {
      case CreateAiEditVideoPollResponse_State.COMPLETE:
        return done(data);
      case CreateAiEditVideoPollResponse_State.FAILED:
        return failed();
      default:
        return pending();
    }
  },
  proxied: true,
  // high polling threshold: see conversation here https://captions-ai.slack.com/archives/C07QQBVQM61/p1733336590089359?thread_ts=1733335764.035429&cid=C07QQBVQM61
  maxPollingAttempts: 10000,
});

export const useSubmitAiEditVideoCreation = createUseMutationHook({
  path: "ai-edit/submit",
  responseSchema: z.preprocess(
    (value) =>
      CreateAiEditVideoSubmitResponse.fromJson(value as string, { ignoreUnknownFields: true }),
    createAiEditVideoSubmitResponseSchema
  ),
  paramsSchema: createAiEditVideoSubmitRequestSchema.extend({
    shotSelectionSeed: z.number().optional(),
    overrideShotStyleIds: z.array(z.string()).optional(),
  }),
  proxied: true,
});
