const BigBoldTextAnimations = {
  big_bold_text_black: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/scrapbook_assets/animations/big_bold_text_black.pag"
  ),
  big_bold_text_white: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/scrapbook_assets/animations/big_bold_text_white.pag"
  ),
};

const CutoutTextAnimations: { [key: string]: URL } = {
  cutout_text_pink: new URL(
    "https://captions-cdn.xyz/desktop-site/ai-edit-assets/scrapbook_assets/animations/cutout-text_pink.pag"
  ),
};

export const ScrapbookAnimations = {
  ...CutoutTextAnimations,
  ...BigBoldTextAnimations,
};
