// noinspection ES6ConvertVarToLetConst

import type { PAG } from "libpag/types/web/src/types";

import { animationFontItems } from "~/theme/fonts/animations";

export const STATIC_ASSETS_PATH = "/_next/static";

declare global {
  /* eslint-disable-next-line no-var */
  var pagInstance: Promise<PAG> | null;
}

export function getPAG(): Promise<PAG> {
  if (globalThis.pagInstance) {
    return globalThis.pagInstance;
  }
  /* NOTE: We use dynamic imports here because libpag does not load in the server environment,
     referencing "document" upon import.
     Since we are patching libpag, long-term we might fix this and import it normally */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  globalThis.pagInstance = import("libpag")
    .then(({ PAGInit }) =>
      PAGInit({
        locateFile: () => `${STATIC_ASSETS_PATH}/js/libpag.wasm?v=2`,
      })
    )
    .then(async (pag: PAG) => {
      const fontLoaders = animationFontItems.map(async (font) => {
        const fontData = await fetch(font.url).then((response) => response.blob());
        const fontFile = new File([fontData], font.fileName);
        await pag.PAGFont.registerFont(font.familyName, fontFile);
      });
      await Promise.all(fontLoaders);
      return pag;
    });
  return globalThis.pagInstance;
}
