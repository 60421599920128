import useLatest from "@react-hook/latest";
import { createContext, PropsWithChildren, useCallback, useContext, useRef, useState } from "react";

import { useEventListener } from "~/hooks/useEventListener";
import { getKeystrokeString, KeyCode, Keystroke } from "~/utils/keystrokes";

export interface KeyboardShortcut {
  keystroke: Keystroke;
  callback: () => void;
}
export interface KeyboardShortcutBlock {}

export interface KeyboardShortcutManager {
  /**
   * Registers a new keyboard shortcut
   *
   * @param key - The keystroke to register.
   * @param callback - The callback to call when the keystroke is pressed.
   * @returns An object describing the registered shortcut. Should be passed
   * to `unregister` when the shortcut is no longer needed.
   */
  register: (key: Keystroke | KeyCode, callback: () => void) => KeyboardShortcut;
  /**
   * Unregisters a previously registered keyboard shortcut.
   *
   * @param registeredShortcut - The shortcut to unregister. Returned by `register`.
   * This needs to be the same object instance returned by `register`, not a copy of it.
   */
  unregister: (registeredShortcut: KeyboardShortcut) => void;
  /**
   * Blocks all keyboard shortcuts until `unblockShortcuts` is called.
   * Useful when a modal is open.
   *
   * For each call to `blockShortcuts`, a corresponding call to `unblockShortcuts`
   * must be made.
   *
   * @returns An object associated with the shortcut block. Should be passed to
   * `unblockShortcuts` when the block is no longer needed.
   */
  blockShortcuts: () => KeyboardShortcutBlock;
  /**
   * Unblocks keyboard shortcuts that were blocked by `blockShortcuts`.
   *
   * Keyboard shortcuts will remain disabled until all blocks are unblocked.
   *
   * @param shortcutBlock - The shortcut block to unblock. Returned by `blockShortcuts`.
   * This needs to be the same object instance returned by `blockShortcuts`, not a copy of it.
   */
  unblockShortcuts: (shortcutBlock: KeyboardShortcutBlock) => void;
}

const keyboardShortcutManagerContext = createContext<KeyboardShortcutManager | undefined>(
  undefined
);

export function KeyboardShortcutManagerProvider({ children }: PropsWithChildren) {
  const shortcuts = useRef<KeyboardShortcut[]>([]);
  // Shortcut blocks are stored in state so that blocking/unblocking shortcuts uses the
  // state update queue. This is needed to prevent shortcuts from being triggered whenever
  // a modal closes due to pressing their keystrokes.
  const [shortcutBlocks, setShortcutBlocks] = useState<KeyboardShortcutBlock[]>([]);
  const latestShortcutBlocks = useLatest(shortcutBlocks);

  const register = useCallback(function register(
    key: Keystroke | KeyCode,
    callback: () => void
  ): KeyboardShortcut {
    const shortcutKey: Keystroke = typeof key === "object" ? key : { key };
    const shortcut = { keystroke: shortcutKey, callback };
    // Check if the key is already registered with another shortcut
    const existingShortcut = shortcuts.current.find((existingItem) => {
      return (
        shortcut.keystroke.key === existingItem.keystroke.key &&
        !!shortcut.keystroke.modCommand === !!existingItem.keystroke.modCommand &&
        !!shortcut.keystroke.modOption === !!existingItem.keystroke.modOption &&
        !!shortcut.keystroke.modShift === !!existingItem.keystroke.modOption
      );
    });
    // Throws an error in case the key is already registered
    if (existingShortcut) {
      throw new Error(
        `Keystroke ${getKeystrokeString(
          shortcut.keystroke
        )} is already registered with another shortcut.`
      );
    }
    // Adds the shortcut to the list of registered shortcuts
    shortcuts.current.push(shortcut);
    // Returns the object instance of the shortcut
    return shortcut;
  }, []);

  const unregister = useCallback(function unregister(registeredShortcut: KeyboardShortcut) {
    // This is searched by reference, therefore it's important to pass the
    // object returned by `register` and not a copy of it.
    const index = shortcuts.current.indexOf(registeredShortcut);
    if (index !== -1) {
      shortcuts.current.splice(index, 1);
    }
  }, []);

  const blockShortcuts = useCallback(function blockShortcuts(): KeyboardShortcutBlock {
    // Creates a unique reference to an empty object. This is more straightforward
    // than generating unique IDs manually.
    const block = {};
    setShortcutBlocks((shortcutBlocks) => [...shortcutBlocks, block]);
    return block;
  }, []);

  const unblockShortcuts = useCallback(function unblockShortcuts(
    shortcutBlock: KeyboardShortcutBlock
  ) {
    // This is searched by reference, therefore it's important to pass the
    // object returned by `blockShortcuts` and not a copy of it.
    setShortcutBlocks((shortcutBlocks) =>
      shortcutBlocks.filter((block) => block !== shortcutBlock)
    );
  }, []);

  const handleKeyDown = useCallback(function handleKeyDown(event: Event) {
    const keyboardEvent = event as unknown as KeyboardEvent;
    // If any blocks are active, skip handling shortcuts
    if (latestShortcutBlocks.current.length > 0) {
      return;
    }
    const isFocusedOnInteractiveElement = ["input", "textarea", "button"].includes(
      document.activeElement?.tagName.toLowerCase() ?? ""
    );
    const key = keyboardEvent.key.toLowerCase();
    // Finds the first shortcut that matches the pressed key
    const shortcut = shortcuts.current.find((shortcut) => {
      return (
        shortcut.keystroke.key === key &&
        !!shortcut.keystroke.modCommand === (keyboardEvent.ctrlKey || keyboardEvent.metaKey) &&
        !!shortcut.keystroke.modOption === keyboardEvent.altKey &&
        !!shortcut.keystroke.modShift === keyboardEvent.shiftKey &&
        (shortcut.keystroke.allowInInteractiveElements || !isFocusedOnInteractiveElement)
      );
    });
    // If a shortcut was found, calls its callback
    if (shortcut) {
      shortcut.callback();
      event.preventDefault();
      if (isFocusedOnInteractiveElement) {
        document.activeElement &&
          "blur" in document.activeElement &&
          (document.activeElement as HTMLElement).blur();
      }
    }
  }, []);

  useEventListener(typeof document !== "undefined" ? document : null, "keydown", handleKeyDown);

  return (
    <keyboardShortcutManagerContext.Provider
      value={{ register, unregister, blockShortcuts, unblockShortcuts }}
    >
      {children}
    </keyboardShortcutManagerContext.Provider>
  );
}

export function useKeyboardShortcutManager(): KeyboardShortcutManager {
  const context = useContext(keyboardShortcutManagerContext);
  if (!context) {
    throw new Error(
      "useKeyboardShortcutManager must be used within a KeyboardShortcutManagerProvider"
    );
  }
  return context;
}
