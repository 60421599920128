import { Style as ServerDrivenAIEditStyle } from "@shared/generated/typescript/aiEdit/api/CreateAiEditVideoTemplate";
import { Client } from "@shared/generated/typescript/aiEdit/api/CreateAiEditVideoTemplate";
import { useStatsigClient } from "@statsig/react-bindings";
import { isFeatureEnabled, useFeatureFlag } from "feature-flags";
import { getFeatureFlagOverride } from "feature-flags/getFeatureFlagOverride";
import { useIsEmployee } from "feature-flags/useIsEmployee";
import { useMemo, useCallback } from "react";

import { useBackendServicesClient } from "~/context/BackendServicesContext";

import { ClientDrivenAIEditVideoStyle } from "../services/AIEditStyles";
import { CLIENT_DRIVEN_AI_EDIT_STYLES } from "../services/AIEditStyles/AIEditStyles";
import { useBackendAIEditStylesList } from "../services/AIEditStyles/network/queries";

export function useAIEditStyles() {
  const backendClient = useBackendServicesClient();
  const isDevAiEditStylesEnabled = useFeatureFlag("enable_dev_ai_edit_styles");
  const { data, isLoading } = useBackendAIEditStylesList({
    pageSize: 100,
    client: Client.DESKTOP,
    afterCursor: "",
    tags: [],
    version: isDevAiEditStylesEnabled ? "dev" : backendClient.version,
  });
  const { client } = useStatsigClient();
  const isEmployee = useIsEmployee();
  const isStatSigInit = client.loadingStatus === "Ready";

  const premiumStyles = useMemo(() => {
    const clientDriven = CLIENT_DRIVEN_AI_EDIT_STYLES.filter((style) => {
      if (style.requiresFeatureFlag) {
        const overrideResult = getFeatureFlagOverride(isEmployee, style.requiresFeatureFlag);
        const remoteValue = isStatSigInit ? client.checkGate(style.requiresFeatureFlag) : undefined;
        return isFeatureEnabled(style.requiresFeatureFlag, remoteValue, overrideResult);
      }
      return true;
    });
    const serverDriven = data?.styles?.page?.filter((item) => item.tags.includes("premium")) ?? [];
    return [...clientDriven, ...serverDriven];
  }, [client, isEmployee, isStatSigInit, data]);

  const getAIEditStyleById = useCallback(
    (id: string | undefined | null) => {
      const allStyles: (ServerDrivenAIEditStyle | ClientDrivenAIEditVideoStyle)[] = [
        ...(data?.styles?.page ?? []),
        ...CLIENT_DRIVEN_AI_EDIT_STYLES,
      ];
      return allStyles.find((item) => item.id === id);
    },
    [data?.styles?.page]
  );

  const basicStyles = useMemo(
    () => data?.styles?.page?.filter((item) => item.tags.includes("basic")),
    [data]
  );

  return {
    premium: premiumStyles,
    basic: basicStyles,
    getAIEditStyleById,
    isLoading,
  };
}
