import { CaptionStyle } from "captions-engine";
import { useCallback, useContext, useEffect, useState } from "react";

import {
  AI_SHORTS_CREATION_STATUS,
  AI_SHORTS_CREATION_STATUS_SOURCE,
} from "~/constants/mixpanel.constants";
import { AIShortsGenerationContext } from "~/context/AIShortsGenerationContext";
import { Project } from "~/database/database.types";
import { ProjectRepository } from "~/database/repo/ProjectRepository";
import { useOnChangeEffect } from "~/hooks/helpers";
import { useAnalytics } from "~/hooks/useAnalytics";
import {
  DEFAULT_ACTIVE_COLOR,
  DEFAULT_EMOJI_SETTINGS,
  DEFAULT_EMPHASIS_COLOR,
  DEFAULT_EMPHASIS_SETTINGS,
  DEFAULT_POSITION_FACTOR,
  DEFAULT_TEXT_COLOR,
  DEFAULT_WORD_BACKGROUND,
  DEFAULT_ACTIVE_WORD_BACKGROUND,
} from "~/modules/project/constants/captionStyle";
import { useCaptions } from "~/modules/project/hooks/useCaptions";
import { CaptionTemplateWithDates } from "~/modules/project/hooks/useCaptionStyleTemplates";
import { useFaces } from "~/modules/project/hooks/useFaces";
import { useProjectPersistence } from "~/modules/project/hooks/useProjectPersistence";
import { useScenes } from "~/modules/project/hooks/useScenes";
import { CaptionTemplate } from "~/modules/project/services/CaptionStylePreset";
import { Transcript } from "~/modules/project/services/Transcription";
import { ProjectFaceData } from "~/modules/project/utils/faceData/face-data.types";
import { calculateCaptionSizeFactor } from "~/modules/project/utils/getDefaultSizeFactor";
import { getTargetAspectRatioNumber, getTargetSize } from "~/utils/reframing";
import { Clip } from "~/utils/videoClips";

import { useTrimVideoFile } from "./useTrimVideoFile";

interface SubProjectCreationProps {
  subProjectId: string;
  subProjectTitle: string;
  thumbnailPath?: string;
  parentProject: Project;
  transcript: Transcript;
  templateId: string;
  languageCode: string;
  captionStyleTemplates: CaptionTemplate[];
  customTemplates: CaptionTemplateWithDates[];
  startTime: number;
  endTime: number;
  onCreated: () => void;
  onFinished: () => void;
  onError: () => void;
}

/*
 * Creates an individual shorts project for the given startTime and endTime
 * @returns empty react fragment
 */
export const SubProjectCreation = ({
  subProjectId,
  subProjectTitle,
  thumbnailPath,
  parentProject,
  transcript,
  templateId,
  languageCode,
  captionStyleTemplates,
  customTemplates,
  startTime,
  endTime,
  onCreated,
  onFinished,
  onError,
}: SubProjectCreationProps) => {
  const [createdProjectId, setCreatedProjectId] = useState<string | null>(null);
  const [hasScenes, setHasScenes] = useState<boolean>(false);
  const [faces, setFaces] = useState<ProjectFaceData | undefined>();
  const [clips, setClips] = useState<Clip[]>([]);
  const hasFaces = Boolean(faces);

  const captions = useCaptions();
  const { file: aiShortsFile } = useContext(AIShortsGenerationContext);

  const projectPersistence = useProjectPersistence(createdProjectId);
  const { trimmedVideoFileId, trimmedVideoStatus } = useTrimVideoFile(
    parentProject.sourceFileId,
    startTime,
    endTime
  );
  const { startScenes, scenesState } = useScenes();
  const { startDetectFaces, detectFacesState } = useFaces();

  const handleError = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (errorMessage: any) => {
      console.error(`Failed to create project ${subProjectId}`, errorMessage);
      onError();
    },
    [subProjectId, onError]
  );

  const { track } = useAnalytics();
  useEffect(() => {
    const videoSource = aiShortsFile
      ? AI_SHORTS_CREATION_STATUS_SOURCE.import
      : AI_SHORTS_CREATION_STATUS_SOURCE.url;
    if (scenesState.status === "error") {
      handleError("scene detection failed");
      track(AI_SHORTS_CREATION_STATUS, {
        status: "error",
        error: "scene detection error",
        ai_shorts_group_id: parentProject?.id,
        video_source: videoSource,
      });
    }
    if (detectFacesState.status === "error") {
      // track error but do not block project creation
      track(AI_SHORTS_CREATION_STATUS, {
        status: "error",
        error: `face detection error: ${detectFacesState.message}`,
        ai_shorts_group_id: parentProject?.id,
        video_source: videoSource,
      });
    }
    if (trimmedVideoStatus === "error") {
      handleError("video trim failed");
      track(AI_SHORTS_CREATION_STATUS, {
        status: "error",
        error: "video trim error",
        ai_shorts_group_id: parentProject?.id,
        video_source: videoSource,
      });
    }
  }, [scenesState.status, detectFacesState.status, trimmedVideoStatus]);

  // request scenes once trimmed file id exists
  useEffect(() => {
    if (!trimmedVideoFileId) {
      return;
    }

    startScenes(trimmedVideoFileId, (scenes: number[]) => {
      const newClips: Clip[] = [];
      for (let i = 0; i < scenes.length - 1; i++) {
        newClips.push({
          startTime: scenes[i],
          endTime: scenes[i + 1],
        });
      }
      setClips(newClips);
      setHasScenes(true);
    });
  }, [trimmedVideoFileId]);

  // request face data once trimmed file id exists
  useEffect(() => {
    if (!trimmedVideoFileId) {
      return;
    }
    startDetectFaces(trimmedVideoFileId, setFaces);
  }, [trimmedVideoFileId]);

  // once we have all the required data create the project
  useEffect(() => {
    if (!trimmedVideoFileId) {
      return;
    }

    const subProject = {
      id: subProjectId,
      folderId: parentProject.id,
      title: subProjectTitle,
      createdAt: new Date(),
      lastGeneratedFileId: "",
      sourceFileId: trimmedVideoFileId,
      updatedAt: new Date(),
      reframeTarget: {
        targetAspect: "9_16",
        containToCover: 1,
        offset: { dx: 0, dy: 0 },
      },
      sourceVideoMetadata: {
        duration: endTime - startTime,
        fps: parentProject.sourceVideoMetadata.fps ?? 0,
        height: parentProject.sourceVideoMetadata.height ?? 0,
        size: parentProject.sourceVideoMetadata.size,
        width: parentProject.sourceVideoMetadata.width ?? 0,
      },
      thumbnailPath,
    } satisfies Project;

    ProjectRepository.addProject(subProject)
      .then(() => {
        onCreated();
        setCreatedProjectId(subProjectId);
      })
      .catch(handleError);
  }, [trimmedVideoFileId]);

  // useProjectPersistence will do a lot of initial setup for us, making sure many of the other
  // entities are created and ready to be used. We can then use the projectPersistence object to
  // set the necessary data for the subproject after projectPersistence finishes its loading
  // process.
  const projectLoaded = projectPersistence.loaded && projectPersistence.project?.id;

  // add face data and scene based clips once they exist
  useEffect(() => {
    if (!hasFaces || !hasScenes || !projectLoaded) {
      return;
    }

    projectPersistence
      .updateProject({ faceData: faces })
      .then(() => projectPersistence.addEffects({ clips }))
      .then(onFinished)
      .catch(handleError);
  }, [hasFaces, hasScenes, projectLoaded]);

  useOnChangeEffect(projectLoaded, (projectLoaded) => {
    if (!projectLoaded) {
      return;
    }

    async function finalizeCreation() {
      const template = [...(captionStyleTemplates ?? []), ...customTemplates]?.find(
        (preset) => preset.id === templateId
      );
      // set transcription captions
      // filter and shift timings of transcript
      const clippedTranscript = {
        phrases: (transcript.phrases ?? [])
          .filter((p) => p.endTime > startTime && p.startTime < endTime)
          .map((p) => ({
            ...p,
            startTime: p.startTime - startTime,
            endTime: p.endTime - startTime,
          })),
        words: transcript.words
          .filter((w) => w.endTime > startTime && w.startTime < endTime)
          .map((w) => ({
            ...w,
            startTime: w.startTime - startTime,
            endTime: w.endTime - startTime,
          })),
      };

      const { words, phrases, phraseAudios } = captions.generateFromTranscript(
        clippedTranscript,
        template?.emphasisSettings
      );
      await projectPersistence.addCaptions(
        languageCode,
        languageCode,
        words,
        phrases,
        phraseAudios
      );

      // set template
      if (template) {
        const width = parentProject.sourceVideoMetadata.width ?? 0;
        const height = parentProject.sourceVideoMetadata.height ?? 0;
        const aspectRatio = getTargetAspectRatioNumber("9_16");
        const { targetWidth, targetHeight } =
          width && height
            ? getTargetSize(aspectRatio, width, height)
            : { targetWidth: 0, targetHeight: 0 };
        let sizeFactor = 1;
        if (targetWidth && targetHeight && template?.style?.content) {
          sizeFactor = await calculateCaptionSizeFactor(targetWidth, targetHeight, {
            stylePreset: template.style.content,
            emphasisSettings: template.emphasisSettings,
            words,
            countryCode: languageCode,
            templateId,
          });
        }

        const style: CaptionStyle = {
          textColor: template.colors.primary ?? DEFAULT_TEXT_COLOR,
          activeColor: template.colors.active ?? DEFAULT_ACTIVE_COLOR,
          emphasisColor: template.colors.emphasis ?? DEFAULT_EMPHASIS_COLOR,
          wordBackground: template.colors.wordBackground ?? DEFAULT_WORD_BACKGROUND,
          activeWordBackground:
            template.style.content.activeWordBackground.color ?? DEFAULT_ACTIVE_WORD_BACKGROUND,
          emojiSettings: template.emojiSettings ?? DEFAULT_EMOJI_SETTINGS,
          emphasisSettings: template.emphasisSettings ?? DEFAULT_EMPHASIS_SETTINGS,
          positionFactor: DEFAULT_POSITION_FACTOR,
          sizeFactor,
        };

        await projectPersistence.updateStyle(style);
        await projectPersistence.updateStylePreset(template.style.content);
      }
    }

    finalizeCreation().catch(handleError);
  });

  return <></>;
};
