import { NewCaptionSettings } from "~/database/database.types";
import { CaptionSettingsRepository } from "~/database/repo/CaptionSettingsRepository";
import { ProjectRepository } from "~/database/repo/ProjectRepository";
import { inferProjectAudioDubJobId, isProjectDubbed } from "~/utils/projectInfo";

import {
  arePhraseAudiosMissingClips,
  getPhraseAudiosWithClipsRemoved,
  getWordsAndPhrasesWithClipsRemoved,
} from "../../../utils/clipDeleting";
import { ProjectEntityBag } from "../../types";

// these migrations are all copied over from the old project loading code
export async function migrateTo20241127(entities: ProjectEntityBag): Promise<ProjectEntityBag> {
  const { project, captions, effects, untranslatedCaptions } = entities;
  let captionSettings = entities.captionSettings;

  if (!captionSettings) {
    // Copy latest caption settings if it doesn't exist
    const latestSettings = await getLatestCaptionSettings(project.id);
    const captionSettingsId = await CaptionSettingsRepository.addCaptionSettings({
      projectId: project.id,
      ...latestSettings,
    });
    captionSettings = { id: captionSettingsId!, projectId: project.id, ...latestSettings };
    project.captionSettingsId = captionSettingsId;
  }

  // Will try to infer the audioDubJobId using the URIs for any dubbed audio given to backfill
  // projects where it wasn't saved
  const isDubbed = isProjectDubbed({
    targetLanguageCode: project.targetLanguageCode,
    sourceFileLanguageCode: project.sourceFileLanguageCode,
    translateAudioEnabled: project.translateAudioEnabled,
  });
  if (project && !project.audioDubJobId && isDubbed) {
    const anyAudioUri = project.backgroundAudioUri ?? captions?.phraseAudios?.[0]?.gcsUri;
    const audioDubJobId = inferProjectAudioDubJobId(anyAudioUri ?? "");
    project.audioDubJobId = audioDubJobId;
  }

  // Ports old projects where the captions were relative to the video file time instead of the
  // project time
  if (captions && !captions.videoClipsApplied) {
    // Separate phrases and audios into separate entities
    const oldPhrases =
      captions.phrases?.map((item) => ({
        id: item.id,
        startTime: item.startTime,
        endTime: item.endTime,
        text: item.text,
        speaker: item.speaker,
      })) ?? [];
    const oldPhraseAudios =
      captions.phrases
        ?.filter((item) => !!item.downloadUrl?.length)
        .map((item) => ({
          id: item.id,
          phraseId: item.id,
          startTime: item.startTime,
          endTime: item.endTime,
          originalStartTime: item.startTime,
          originalEndTime: item.endTime,
          gcsUri: item.gcsUri ?? "",
          downloadUrl: item.downloadUrl ?? "",
        })) ?? [];
    // Migrate captions to use relative timestamps if they haven't already been migrated
    const { words, phrases } = getWordsAndPhrasesWithClipsRemoved(
      oldPhrases,
      captions.words,
      effects?.clips ?? []
    );
    const phraseAudios = getPhraseAudiosWithClipsRemoved(oldPhraseAudios, effects?.clips ?? []);
    captions.words = words;
    captions.phrases = phrases;
    captions.phraseAudios = phraseAudios;
    captions.videoClipsApplied = true;
  }

  // Fixes projects that did not have the dubbed phrase clips persisted (bug)
  const hasDeletedClips = Boolean(effects?.clips?.some((clip) => clip.deleted));
  if (
    captions?.phraseAudios &&
    hasDeletedClips &&
    arePhraseAudiosMissingClips(captions?.phraseAudios)
  ) {
    captions.phraseAudios = getPhraseAudiosWithClipsRemoved(captions.phraseAudios, effects!.clips!);
  }

  return {
    project,
    captionSettings,
    captions,
    effects,
    untranslatedCaptions,
    timelineProject: entities.timelineProject,
  };
}

const getLatestCaptionSettings = async (
  currentProjectId: string
): Promise<NewCaptionSettings | undefined> => {
  const allProjects = await ProjectRepository.getAllProjects();
  if (!allProjects || allProjects.length === 0) {
    return;
  }
  const projectsByUpdatedAt = allProjects
    ?.filter((project) => project.captionSettingsId && project.id !== currentProjectId)
    .sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime());
  const lastUpdatedProject = projectsByUpdatedAt?.[0];
  if (!lastUpdatedProject || !lastUpdatedProject.captionSettingsId) {
    return;
  }
  const latestProjectSettings = await CaptionSettingsRepository.getCaptionSettings(
    lastUpdatedProject.captionSettingsId
  );
  if (!latestProjectSettings) {
    return;
  }
  const {
    id: _id,
    projectId: _projectId,
    ...latestProjectSettingsWithoutIds
  } = latestProjectSettings;
  return latestProjectSettingsWithoutIds;
};
