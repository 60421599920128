import { StatsigClient, type StatsigUser, Log } from "@statsig/react-bindings";
import { FeatureFlagName } from "feature-flags";
import { getFeatureFlagOverride } from "feature-flags/getFeatureFlagOverride";

import { parseSemver } from "~/utils/parseSemver";
import { createAsyncInitializedStore } from "~/utils/zustand-store";

import packageJson from "~/../package.json";

const DYN_CFG_OVERRIDES_LOCAL_STORAGE_KEY = "dynamicConfigOverrides";

const SDK_KEY = "client-qxS1De9MMtlQKUv5NmEgkrgpPzBWPW6zixrKygmUZQ2";

const createStatsigUser = (userID: string): StatsigUser => {
  const uiPackageVersion = parseSemver(packageJson.version);

  return {
    userID,
    userAgent: typeof navigator !== "undefined" ? navigator.userAgent : undefined,
    custom: {
      isDesktopApp: Boolean(typeof window !== "undefined" && window.system?.versions?.electron),
      uiPackageVersionMajor: uiPackageVersion?.major || 0,
      uiPackageVersionMinor: uiPackageVersion?.minor || 0,
    },
  };
};

// this is exported so that we can provide it to Statsig's own provider
// please do not use this directly, since it's initialization is handled by the store
export const statsigClient = new StatsigClient(SDK_KEY, {});

export const [getStatsigStore, useStatsigStore, getStatsigInstance] = createAsyncInitializedStore({
  name: "StatsigStore",
  initializer: async (initialUserID: string) => {
    await statsigClient.updateUserAsync(createStatsigUser(initialUserID));

    await statsigClient.initializeAsync();

    return statsigClient;
  },
  methods: ({ get, queue, init }) => ({
    setUserIdOrInitialize: (userID: string) => {
      const instance = get().instance;

      // we do not use `queue` here because we want to conditionally call
      // `init` if the instance is not yet initialized to make the external API
      // easier to use.

      // this is slightly different than the other stores since we do not want to initialize until the userID is set
      if (instance) {
        instance.updateUserAsync(createStatsigUser(userID)).catch(Log.error);
      } else {
        init(userID);
      }
    },
    track: (event: string, properties?: Record<string, string>) => {
      const value = properties?.value;

      queue((instance) => instance.logEvent(event, value, properties));
    },
    // not 100% i love defining these methods to "get" data inside here,
    // we could export them or attach them as methods to the state
    // but i think this is fine for now
    potentiallyGetDynamicConfigSync: <T>(
      name: string,
      isEmployee?: boolean
    ):
      | { data: T; state: "COMPLETE"; origin: "REMOTE" | "LOCAL" }
      | { data: undefined; state: "PENDING"; origin: "REMOTE" | "LOCAL" } => {
      if (isEmployee) {
        const localOveride = getDynamicConfigOverride<T>(name);
        if (localOveride) {
          return {
            data: localOveride,
            state: "COMPLETE",
            origin: "LOCAL",
          };
        }
      }

      const instance = get().instance;

      return instance
        ? {
            data: instance.getDynamicConfig(name).value as T,
            state: "COMPLETE",
            origin: "REMOTE",
          }
        : {
            data: undefined,
            state: "PENDING",
            origin: "REMOTE",
          };
    },
    getDynamicConfigAsync: async <T>(name: string, isEmployee?: boolean): Promise<T> => {
      if (isEmployee) {
        const localOveride = getDynamicConfigOverride<T>(name);
        if (localOveride) {
          return localOveride;
        }
      }
      const instance = await get().promise;

      return instance.getDynamicConfig(name).value as T;
    },
    getFeatureFlagAsync: async (name: FeatureFlagName) => {
      const instance = await get().promise;

      const isEmployee = instance.getDynamicConfig("true_if_employee").get("isEmployee", false);

      const localOverride = getFeatureFlagOverride(isEmployee, name);
      if (localOverride !== undefined) {
        return Promise.resolve(localOverride);
      }

      return instance.getFeatureGate(name).value;
    },
  }),
});

export function getDynamicConfigOverride<T>(name: string): T | undefined {
  if (!name || typeof window === "undefined" || !window?.localStorage) {
    return undefined;
  }
  try {
    const configOverrides = window.localStorage.getItem(DYN_CFG_OVERRIDES_LOCAL_STORAGE_KEY);
    if (!configOverrides) {
      return undefined;
    }

    const overridesObj = JSON.parse(configOverrides) as { [key: string]: T | undefined };
    const override = overridesObj[name];
    if (typeof override === "undefined") {
      return undefined;
    }

    return override;
  } catch (error) {
    return undefined;
  }
}

export function setDynamicConfigOverride<T>(name: string, value: T | undefined): void {
  if (!name || typeof window === "undefined" || !window?.localStorage) {
    return;
  }
  try {
    const configOverrides = window.localStorage.getItem(DYN_CFG_OVERRIDES_LOCAL_STORAGE_KEY);
    if (!configOverrides && !value) {
      return;
    }
    const overridesObj = JSON.parse(configOverrides ?? "{}") as { [key: string]: T | undefined };
    const override = overridesObj[name];
    if (!override && !value) {
      return;
    }
    if (value) {
      overridesObj[name] = value;
    } else {
      delete overridesObj[name];
    }
    window.localStorage.setItem(DYN_CFG_OVERRIDES_LOCAL_STORAGE_KEY, JSON.stringify(overridesObj));
  } catch (error) {
    console.warn("Failed to set dynamic config override", error);
  }
}
