export const createAiEditVideoPollResponse = {
  videoTemplate: {
    id: "89621C1D-0BCB-4896-AEEF-17C399734824",
    displayName: "Recess",
    captionsTemplate: {
      id: "2F6DE10E-188F-466D-882D-8CB352DDF580",
      displayName: "Recess",
      colors: {
        primary: {
          red: 0.9999999403953552,
          green: 0.9999999403953552,
          blue: 0.9999999403953552,
          alpha: 1.0,
        },
        wordBackground: {
          red: 0.4941176772117615,
          green: 0.07058819383382797,
          blue: 0.9999999403953552,
          alpha: 1.0,
        },
        emphasis: {
          red: 0.9999999403953552,
          green: 0.9999999403953552,
          blue: 0.9999999403953552,
          alpha: 1.0,
        },
        active: {
          red: 0.9999999403953552,
          green: 1.0000001192092896,
          blue: 1.0000001192092896,
          alpha: 1.0,
        },
      },
      style: {
        styleId: "2F6DE10E-188F-466D-882D-8CB352DDF580",
        content: {
          custom: {
            font: {
              fontName: "FredokaOne-Regular",
              fontSize: 96.0,
            },
            letterSpacing: 0.11222916096448898,
            lineSpacing: 0.4377104341983795,
            shadow: {
              radius: 10.0,
              color: {
                alpha: 0.5965664982795715,
              },
              offset: {},
            },
            textOffset: {},
            layoutAlignment: "LAYOUT_ALIGNMENT_CENTER",
            lineBreakMode: {
              onPunctuationAndPause: {
                seconds: 0.4000000059604645,
                characterCount: "10",
              },
            },
            linesPerPage: "2",
            capitalization: "CAPITALIZATION_STYLE_UPPER",
            hidePunctuation: true,
            activeWordBackground: {
              cornerRadius: 23.973064422607422,
              color: {
                alpha: 1.0,
              },
              animationStyle: "ANIMATION_STYLE_JUMP",
              blurRadius: 0.0,
              gradient: {
                firstColor: {
                  red: 0.8509805798530579,
                  green: 0.9254902005195618,
                  blue: 0.4277538061141968,
                  alpha: 1.0,
                },
                secondColor: {
                  red: 0.6941176652908325,
                  green: 0.6235295534133911,
                  blue: 0.9999999403953552,
                  alpha: 1.0,
                },
                startPosition: {
                  x: 0.26500457525253296,
                  y: 0.19899305701255798,
                },
                endPosition: {
                  x: 0.9335179924964905,
                  y: 0.9294211864471436,
                },
              },
            },
            animationTarget: "ANIMATION_TARGET_WORD",
            animationStyle: "ANIMATION_STYLE_POP_IN",
            sizeConstraints: {
              width: 625.0,
            },
            backgroundPadding: {},
            upcomingWords: {
              color: {},
            },
            glow: {
              offset: {},
            },
            pageBreakStyle: {
              lineCount: {
                lineCount: 2,
              },
            },
          },
        },
        canEdit: true,
        styleName: "Recess",
        tags: ["captions_team_internal"],
        audienceSegments: ["STYLE_AUDIENCE_SEGMENT_INTERNAL"],
        categories: ["simple"],
        renderingEngineVersion: "11.11",
        numViews: 1,
      },
      emojiSettings: {
        size: 21.0,
        position: "EMOJI_POSITION_ABOVE",
        timing: "EMOJI_TIMING_LINE",
      },
      emphasisSettings: {
        aiEnabled: true,
        nouns: {},
        keywords: {
          supersize: true,
        },
        pictoralWords: {},
      },
    },
  },
  shots: [
    {
      shotTemplate: {
        id: "2D5341E1-2C6F-4A4A-8082-9962C5A1BF06",
        displayName: "Recess Normal",
        captions: {
          regular: {
            layout: {
              avoidHead: {},
            },
          },
        },
      },
    },
    {
      shotTemplate: {
        id: "B824D4FA-E4A7-4C6A-9BDB-324F45AAE98E",
        displayName: "Recess Top Half Image",
        captions: {
          regular: {
            layout: {
              middle: {},
            },
          },
        },
        foregroundOverlays: [
          {
            image: {
              source: {
                aiImage: {
                  style: "IMPACT_FULLSCREEN",
                },
              },
              layout: {
                topHalf: {},
              },
            },
          },
        ],
      },
    },
    {
      shotTemplate: {
        id: "AC3D5202-E6B2-465E-99EA-2E56823C8C76",
        displayName: "Recess Bottom Half Image",
        captions: {
          regular: {
            layout: {
              middle: {},
            },
          },
        },
        foregroundOverlays: [
          {
            image: {
              source: {
                aiImage: {
                  style: "IMPACT_FULLSCREEN",
                },
              },
              layout: {
                bottomHalf: {},
              },
            },
          },
        ],
      },
    },
    {
      shotTemplate: {
        id: "4100815E-E8F5-4CFA-BA2B-A206FFA5C03B",
        displayName: "Recess Full Screen Image",
        captions: {
          regular: {
            layout: {
              middle: {},
            },
          },
        },
        foregroundOverlays: [
          {
            image: {
              source: {
                aiImage: {
                  style: "IMPACT_FULLSCREEN",
                },
              },
              layout: {
                fill: {},
              },
            },
          },
        ],
      },
    },
  ],
  progress: 100,
  state: "COMPLETE",
};
