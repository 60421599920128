export const generatedAdSetDraftResponse = (extra: { script?: Record<string, unknown> } = {}) => ({
  id: "ad_set_123",
  state: "DRAFT",
  title: "Testing 123 Ad Set",
  adVariants: [
    {
      id: "ad_variant_123",
      state: "DRAFT",
      progress: 20,
      hook: "You can now create an AI Avatar like me to make whatever content you need effortlessly. Let me show you how.",
      body: "Just open up AI Creator in the Captions App and write in a topic or prompt to instantly generate a customizable script.\n\nFrom there, tap “generate video” and you’ve got new content — in a matter of seconds.\n\nTry it out now on Captions.\n\n",
      creatorId: "",
      creatorVariantId: "",
      aspectRatio: "9:16",
      songId: "music_123",
      languageCode: "",
      language: "",
      transcription: {},
      thumbnailUrl:
        "https://captions-cdn.xyz/ai-avatars/ugc/v2/Ak9ymgLv981z8SdHnuZZ/thumb_512h.jpg",
      videoUrl:
        "https://storage.googleapis.com/post-upload/UssZoAzgbxjv7XTHgL05/2024-09-20_06-48-26_998636e9-fad9-4777-ba74-22fc7bc1c36a.mp4?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=dev-api-core%40captions-web.iam.gserviceaccount.com%2F20240920%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20240920T184841Z&X-Goog-Expires=604800&X-Goog-SignedHeaders=host&X-Goog-Signature=92c58269567d706f5d6cb920efd6e500c545ee76e54dd5acfafdae8684fd38ff4b5b64ca477da00ca2658560d80a38683ad07e7acdda402f0b9941262a6c8f89a9ed0438943852762162b6e77f9867f780d3ea718e8e6d74f8fe220fa72e4d3e35cfa13e323718728a5c3e2a3e188625f9b2d7a17c05d6c63a57bd43436388773947f30ee83959d12ac9f576c8aa7d132e1099409780db7c900040b02f03fa7e7fe64eae37797308c551649a537d07ff12bdc1951f378d016cc3516201901f528cc223515c2bdd6e8f5c5cb5f0eac0ce5ab06043322804a1553874f2e7bdd5e6dd1b67b0c7c3030857c7776d1474739c4f7cd7c117ffc528d1cb8107e85896df",
      duration: 60,
    },
    {
      id: "ad_variant_456",
      state: "DRAFT",
      progress: 20,
      hook: "With an AI generated Creator like me, content that used to take hours to create, now takes seconds. Let me show you.",
      creatorId: "",
      creatorVariantId: "",
      aspectRatio: "9:16",
      songId: "music_123",
      languageCode: "",
      language: "",
      transcription: {},
      thumbnailUrl:
        "https://captions-cdn.xyz/ai-avatars/ugc/v2/Sn35PYiLt2r9J1mzbMFG/thumb_512h.jpg",
      videoUrl:
        "https://storage.googleapis.com/post-upload/UssZoAzgbxjv7XTHgL05/2024-09-20_06-48-26_998636e9-fad9-4777-ba74-22fc7bc1c36a.mp4?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=dev-api-core%40captions-web.iam.gserviceaccount.com%2F20240920%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20240920T184841Z&X-Goog-Expires=604800&X-Goog-SignedHeaders=host&X-Goog-Signature=92c58269567d706f5d6cb920efd6e500c545ee76e54dd5acfafdae8684fd38ff4b5b64ca477da00ca2658560d80a38683ad07e7acdda402f0b9941262a6c8f89a9ed0438943852762162b6e77f9867f780d3ea718e8e6d74f8fe220fa72e4d3e35cfa13e323718728a5c3e2a3e188625f9b2d7a17c05d6c63a57bd43436388773947f30ee83959d12ac9f576c8aa7d132e1099409780db7c900040b02f03fa7e7fe64eae37797308c551649a537d07ff12bdc1951f378d016cc3516201901f528cc223515c2bdd6e8f5c5cb5f0eac0ce5ab06043322804a1553874f2e7bdd5e6dd1b67b0c7c3030857c7776d1474739c4f7cd7c117ffc528d1cb8107e85896df",
      duration: 60,
    },
    {
      id: "ad_variant_789",
      state: "DRAFT",
      progress: 20,
      hook: "Stop scrolling now... [MORE TEXT]",
      creatorId: "",
      creatorVariantId: "",
      aspectRatio: "9:16",
      songId: "music_123",
      languageCode: "",
      language: "",
      transcription: {},
      thumbnailUrl:
        "https://captions-cdn.xyz/ai-avatars/ugc/v2/qP45igVq4scRkdwsmWCu/thumb_512h.jpg",
      videoUrl:
        "https://storage.googleapis.com/post-upload/UssZoAzgbxjv7XTHgL05/2024-09-20_06-48-26_998636e9-fad9-4777-ba74-22fc7bc1c36a.mp4?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=dev-api-core%40captions-web.iam.gserviceaccount.com%2F20240920%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20240920T184841Z&X-Goog-Expires=604800&X-Goog-SignedHeaders=host&X-Goog-Signature=92c58269567d706f5d6cb920efd6e500c545ee76e54dd5acfafdae8684fd38ff4b5b64ca477da00ca2658560d80a38683ad07e7acdda402f0b9941262a6c8f89a9ed0438943852762162b6e77f9867f780d3ea718e8e6d74f8fe220fa72e4d3e35cfa13e323718728a5c3e2a3e188625f9b2d7a17c05d6c63a57bd43436388773947f30ee83959d12ac9f576c8aa7d132e1099409780db7c900040b02f03fa7e7fe64eae37797308c551649a537d07ff12bdc1951f378d016cc3516201901f528cc223515c2bdd6e8f5c5cb5f0eac0ce5ab06043322804a1553874f2e7bdd5e6dd1b67b0c7c3030857c7776d1474739c4f7cd7c117ffc528d1cb8107e85896df",
      duration: 60,
    },
  ],
  ...extra,
});
