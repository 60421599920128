import { ShortsLength } from "~/components/AIShortsDialog/AIShortsSettings.types";
import { BackendServicesClient } from "~/context/BackendServicesContext";

const AI_SHORTS_URL = "/video/v1/ai-shorts";

export async function startAIShortsJob(
  client: BackendServicesClient,
  transcriptionJobId: string,
  shortsLength: ShortsLength
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  try {
    const result = await client.post(`${AI_SHORTS_URL}`, {
      transcriptionJobId,
      shortsLength,
    });
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getAIShortsJobStatus(
  client: BackendServicesClient,
  shortsJobId: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  try {
    const result = await client.get(`${AI_SHORTS_URL}/${shortsJobId}/status`);
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
