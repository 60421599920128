import { ImageSource } from "../../captionEngine.types";

import glReframeFrag from "./gl-reframe.frag";
import glTransformVert from "./gl-transform.vert";
import {
  applyPixelMapping,
  createEmptyTexture,
  createWebGLArrayBuffer,
  initShaderProgram,
  setVertexAttributeBuffer,
  updateTexture,
} from "./webgl";
import { PixelMapping } from "./webgl.types";

// We need 3 Pixel Maps to create the Stacked Frame for Cinematic Styles.
// This should reflect the same value as `MAX_PIXEL_MAPS` declared in `gl-reframe.frag`.
// Since we can't read a value from a fragment shader, we have to declare it twice in both places.
const MAX_PIXEL_MAPS = 3;

const emptyPixelMap: PixelMapping = {
  from: { left: 0, top: 0, right: 0, bottom: 0 },
  to: { left: 0, top: 0, right: 0, bottom: 0 },
};

export function initWebGL(gl: WebGLRenderingContext) {
  const shaderProgram = initShaderProgram(gl, glTransformVert, glReframeFrag);
  const programInfo = {
    program: shaderProgram,
    attribLocations: {
      vertexPosition: gl.getAttribLocation(shaderProgram, "aVertexPosition"),
      textureCoord: gl.getAttribLocation(shaderProgram, "aTextureCoord"),
    },
    uniformLocations: {
      transformMatrix: gl.getUniformLocation(shaderProgram, "uTransformMatrix"),
      pixelMaps: Array.from({ length: MAX_PIXEL_MAPS }).map((_, i) => ({
        from: {
          bottomRight: gl.getUniformLocation(shaderProgram, `uPixelMaps[${i}].from.bottomRight`),
          topLeft: gl.getUniformLocation(shaderProgram, `uPixelMaps[${i}].from.topLeft`),
        },
        to: {
          bottomRight: gl.getUniformLocation(shaderProgram, `uPixelMaps[${i}].to.bottomRight`),
          topLeft: gl.getUniformLocation(shaderProgram, `uPixelMaps[${i}].to.topLeft`),
        },
        rotation: gl.getUniformLocation(shaderProgram, `uPixelMaps[${i}].rotation`),
        bounds: {
          bottomRight: gl.getUniformLocation(shaderProgram, `uPixelMaps[${i}].bounds.bottomRight`),
          topLeft: gl.getUniformLocation(shaderProgram, `uPixelMaps[${i}].bounds.topLeft`),
        },
        textureIndex: gl.getUniformLocation(shaderProgram, `uPixelMaps[${i}].textureIndex`),
      })),
      pixelMapsLength: gl.getUniformLocation(shaderProgram, "uPixelMapsLength"),
      samplers: Array.from({ length: MAX_PIXEL_MAPS }).map((_, i) =>
        gl.getUniformLocation(shaderProgram, `uSamplers[${i}]`)
      ),
      canvasSize: gl.getUniformLocation(shaderProgram, "uCanvasSize"),
    },
  };

  const buffers = {
    positionBuffer: createWebGLArrayBuffer(gl, [-1.0, -1.0, 1.0, -1.0, 1.0, 1.0, -1.0, 1.0]),
    textureCoordBuffer: createWebGLArrayBuffer(gl, [0.0, 1.0, 1.0, 1.0, 1.0, 0.0, 0.0, 0.0]),
    indicesBuffer: createWebGLArrayBuffer(gl, [0, 1, 2, 0, 2, 3], "element_array_buffer"),
  };
  const textures = Array.from({ length: 1 }).map(() => createEmptyTexture(gl, [0, 0, 0, 0]));

  return {
    programInfo,
    buffers,
    textures,
  };
}

export type WebGLProgramData = ReturnType<typeof initWebGL>;

export function drawScene(
  gl: WebGLRenderingContext,
  pixelMaps: PixelMapping[],
  { programInfo, buffers, textures }: WebGLProgramData,
  imageFrame: ImageSource | null,
  videoFrame: TexImageSource | null = null
) {
  // reset the context blend mode
  gl.blendEquation(gl.FUNC_ADD);
  gl.blendFunc(gl.ONE, gl.ZERO);

  const canvasWidth = gl.canvas.width;
  const canvasHeight = gl.canvas.height;
  gl.viewport(0, 0, canvasWidth, canvasHeight);
  gl.clearColor(0.0, 0.0, 0.0, 1.0);
  gl.clear(gl.COLOR_BUFFER_BIT);

  setVertexAttributeBuffer(
    gl,
    buffers.positionBuffer,
    programInfo.attribLocations.vertexPosition,
    2
  );
  setVertexAttributeBuffer(
    gl,
    buffers.textureCoordBuffer,
    programInfo.attribLocations.textureCoord,
    2
  );
  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffers.indicesBuffer);

  gl.useProgram(programInfo.program);

  const pixelMapsLength = Math.min(MAX_PIXEL_MAPS, pixelMaps.length);
  gl.uniform1i(programInfo.uniformLocations.pixelMapsLength, pixelMapsLength);

  const transform = [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1];
  gl.uniformMatrix4fv(programInfo.uniformLocations.transformMatrix, false, transform);
  gl.uniform2fv(programInfo.uniformLocations.canvasSize, [canvasWidth, canvasHeight]);

  for (let i = 0; i < pixelMapsLength; i++) {
    const map = pixelMaps.at(i) ?? emptyPixelMap;

    if (!textures[i]) {
      textures[i] = createEmptyTexture(gl, [0, 0, 0, 0]);
    }

    if (map.contentType === "image" && imageFrame) {
      updateTexture(gl, textures[i], imageFrame);
    } else if (map.contentType === "source-video" && videoFrame) {
      updateTexture(gl, textures[i], videoFrame);
    } else {
      textures[i] = textures[0];
    }
  }

  for (let i = 0; i < pixelMapsLength; i++) {
    const map = pixelMaps.at(i) ?? emptyPixelMap;
    const programPixelMap = programInfo.uniformLocations.pixelMaps[i];

    applyPixelMapping(gl, programPixelMap, map);
    gl.uniform1f(programPixelMap.textureIndex, i);

    gl.activeTexture(gl.TEXTURE0 + i);
    gl.bindTexture(gl.TEXTURE_2D, textures[i]);
    gl.uniform1i(programInfo.uniformLocations.samplers[i], i);
  }

  const vertexCount = 6;
  const type = gl.UNSIGNED_SHORT;
  const offset = 0;
  gl.drawElements(gl.TRIANGLES, vertexCount, type, offset);
}
