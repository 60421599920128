import { createAssetMap } from "../../../utils/assetUtils";

export const IMAGE_RED_CIRCLE_URL = new URL(
  "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/red_circle.png"
);
export const IMAGE_GREEN_CIRCLE_URL = new URL(
  "https://captions-cdn.xyz/desktop-site/ai-edit-assets/paper_assets/green_circle.png"
);

export const SCRAPBOOK_IMAGE_ASSETS = createAssetMap({
  folder: "ai-edit-assets/scrapbook_assets/images",
  assets: {
    black_glitter: {
      type: "image",
      variants: ["1", "2", "3", "4", "5"],
    },
    diamond: {
      type: "image",
      variants: ["1", "2", "3", "4", "5"],
    },
    "diamond-flower": {
      type: "image",
      variants: ["1", "2", "3", "4", "5"],
    },
  },
});
