import React from "react";

import { styled } from "~/theme";

function Microsoft({ ...props }) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.651899"
        y="0.151899"
        width="8.35457"
        height="8.35457"
        fill="#F8510C"
        stroke="white"
        strokeWidth="0.303797"
      />
      <rect
        x="0.651899"
        y="9.4937"
        width="8.35457"
        height="8.35457"
        fill="#00A3F4"
        stroke="white"
        strokeWidth="0.303797"
      />
      <rect
        x="9.99174"
        y="0.151899"
        width="8.35457"
        height="8.35457"
        fill="#7EBA00"
        stroke="white"
        strokeWidth="0.303797"
      />
      <rect
        x="9.99174"
        y="9.4937"
        width="8.35457"
        height="8.35457"
        fill="#FFBA00"
        stroke="white"
        strokeWidth="0.303797"
      />
    </svg>
  );
}

export default styled(Microsoft, {
  width: 19,
  height: 18,
});
