import { ZodError } from "zod";

import { BackendServicesClient } from "~/context/BackendServicesContext";

import {
  GetAudioExtractionJobResponse,
  getAudioExtractionJobResponseSchema,
  StartAudioExtractionJobRequest,
  startAudioExtractionJobResponseSchema,
  StartOverlayJobResponse,
} from "./AudioExtraction.dto";

const AUDIO_EXTRACTION_JOB_URL = "/audio/v1/extraction";

export async function getAudioExtractionJobStatus(
  client: BackendServicesClient,
  AudioExtractionJobId: string,
  signal?: AbortSignal
): Promise<GetAudioExtractionJobResponse> {
  try {
    const result = await client.get(`${AUDIO_EXTRACTION_JOB_URL}/${AudioExtractionJobId}/status`, {
      signal,
    });
    return getAudioExtractionJobResponseSchema.parse(result.data);
  } catch (error) {
    console.error(error);
    if (error instanceof ZodError) {
      throw new Error("Invalid response received from the server!");
    } else {
      throw error;
    }
  }
}

export async function startAudioExtractionJob(
  client: BackendServicesClient,
  AudioExtractionJob: StartAudioExtractionJobRequest
): Promise<StartOverlayJobResponse> {
  try {
    const result = await client.post(AUDIO_EXTRACTION_JOB_URL, AudioExtractionJob);
    return startAudioExtractionJobResponseSchema.parse(result.data);
  } catch (error) {
    console.error(error);
    if (error instanceof ZodError) {
      throw new Error("Invalid response received from the server!");
    } else {
      throw error;
    }
  }
}
