import { AxiosError } from "axios";
import { useFeatureFlag } from "feature-flags";
import { useEffect, useMemo, useRef, useState } from "react";

import { useToast } from "~/components/Toast/ToastProvider";
import { AI_CREATORS_CREATION_STATUS } from "~/constants/mixpanel.constants";
import { NewCaptionSettings, Project, ProjectFolder } from "~/database/database.types";
import { CaptionSettingsRepository } from "~/database/repo/CaptionSettingsRepository";
import { CaptionsRepository } from "~/database/repo/CaptionsRepository";
import { ProjectRepository } from "~/database/repo/ProjectRepository";
import { useAnalytics } from "~/hooks/useAnalytics";
import { useProjectThumbnail } from "~/hooks/useProjectThumbnail";
import { DEFAULT_POSITION_FACTOR } from "~/modules/project/constants/captionStyle";
import { useCaptions } from "~/modules/project/hooks/useCaptions";
import { useCaptionStyleTemplates } from "~/modules/project/hooks/useCaptionStyleTemplates";
import {
  AIAvatarJobStatus,
  getAIAvatarGenerationJobStatus,
  startAIAvatarGenerationJob,
} from "~/modules/project/services/AIAvatar/AIAvatarGenerate";
import { CaptionTemplate } from "~/modules/project/services/CaptionStylePreset";
import {
  parseTranscriptResponseEntry,
  ServiceTranscript,
} from "~/modules/project/services/Transcription";
import { calculateCaptionSizeFactor } from "~/modules/project/utils/getDefaultSizeFactor";
import { getUploadedFileInfo } from "~/services/UploadFile";
import {
  ai_creators_creation_status,
  ProgressTracker,
  new_project_created,
} from "~/utils/analytics/ProductEvents";
import { VideoFileMetadata } from "~/utils/getVideoFileMetadata";
import { getDefaultProjectTitle } from "~/utils/projectInfo";
import { getTargetAspectRatioNumber, getTargetSize } from "~/utils/reframing";

import { useBackendServicesClient } from "../BackendServicesContext";

import type { ProjectCreationProps, ProjectCreationStage } from "./ProjectCreationContext.types";

export interface ProjectCreationFromVideoProps extends ProjectCreationProps {
  setProjectAttributesUp: (
    projectId: string,
    videoFileMetadata: VideoFileMetadata | null,
    creationStage: ProjectCreationStage,
    progress: number,
    thumbnailPath?: string,
    estimatedTime?: number
  ) => void;
}

type CreatorsStage = "start" | "error" | "ugc-gen" | "project-creation" | "finished";

const POLLING_INTERVAL = 1000;

// for creating a single project
export const ProjectCreationFromCreators = (projectCreation: ProjectCreationFromVideoProps) => {
  const { projectId, settings, thumbnailPath, setProjectAttributesUp, title } = projectCreation;
  const {
    captionStyleTemplates,
    customTemplates,
    loading: templatesLoading,
  } = useCaptionStyleTemplates();

  const enableCreateMuxAsset = useFeatureFlag("enable_mux_create_asset");

  const { track } = useAnalytics();
  const toast = useToast();

  const [stage, setStage] = useState<CreatorsStage>("start");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const pollingTimeoutId = useRef<ReturnType<typeof setTimeout>>();
  const startTime = useRef<number | undefined>();
  const curStage = useRef<{ stage: CreatorsStage; time: number } | undefined>();
  const curOperationId = useRef<string | undefined>();

  const [creatorsResp, setCreatorsResp] = useState<AIAvatarJobStatus | null>(null);
  const [creatorsGenProgress, setCreatorsGenProgress] = useState<number>(0);

  const { createThumbnail } = useProjectThumbnail();

  const client = useBackendServicesClient();

  const captions = useCaptions();

  const progress = useMemo(() => {
    if (stage === "finished") {
      return 100;
    }

    if (stage === "project-creation") {
      return 90;
    }

    return 0.9 * creatorsGenProgress;
  }, [stage, creatorsGenProgress]);

  const progressTracker = useRef<ProgressTracker>(new ProgressTracker());

  useEffect(() => {
    // remap local stage type to ProjectCreationStage here
    const normStage =
      stage === "finished" ? "finished" : stage === "error" ? "error" : "post-upload";
    setProjectAttributesUp(projectId, null, normStage, progress, thumbnailPath);

    const prevStage = curStage.current;
    curStage.current = { stage, time: new Date().getTime() };

    const commonTrackingParams = {
      creators_group_id: projectId,
      creator_id: settings?.aiCreators?.avatarId,
      creator_name: settings?.aiCreators?.avatarName,
      latency: startTime.current ? curStage.current.time - startTime.current : undefined,
      relative_latency: prevStage ? curStage.current.time - prevStage.time : 0,
      transcript: settings?.aiCreators?.transcript,
      avatarVariantId: settings?.aiCreators?.avatarVariantId,
      flow_type: settings?.aiCreators?.flowType || "ai_creator",
      creator_operation_id: curOperationId.current,
    };

    if (stage === "finished") {
      track(
        ...ai_creators_creation_status({
          status: "success",
          video_duration_seconds: creatorsResp?.durationSeconds,
          ...commonTrackingParams,
        })
      );
    } else if (stage === "error") {
      track(
        ...ai_creators_creation_status({
          status: "fail",
          error: errorMessage,
          ...commonTrackingParams,
        })
      );
    } else if (stage === "start") {
      startTime.current = new Date().getTime();
      track(
        ...ai_creators_creation_status({
          status: "start",
          ...commonTrackingParams,
        })
      );
    } else if (stage === "ugc-gen") {
      const didUpdateProgress = progressTracker.current.updateProgress(progress);
      if (didUpdateProgress) {
        track(AI_CREATORS_CREATION_STATUS, {
          status: "ugc-gen",
          ...commonTrackingParams,
        });
      }
    } else if (stage === "project-creation") {
      track(
        ...new_project_created({
          project_id: projectId,
          group_id: commonTrackingParams.creators_group_id,
          project_type: commonTrackingParams.flow_type,
          video_duration_seconds: creatorsResp?.durationSeconds ?? 0,
          aspect_ratio: "portrait",
          language: settings?.spokenLanguageCode,
          translation_mode: "none",
        })
      );
    }
  }, [stage, progress]);

  const handleError = (e: AxiosError | Error | string) => {
    setStage("error");

    if (e instanceof AxiosError) {
      const message = e.response?.data?.error?.message ?? e.response?.data?.message ?? e.message;
      toast.add(message, {
        severity: "error",
        duration: 5000,
      });
      setErrorMessage(message);
      return;
    }
    setErrorMessage(e.toString());
  };

  const updateCreatorsJobStatus = (operationId: string) => {
    getAIAvatarGenerationJobStatus(client, operationId)
      .then((job) => {
        if (job.status === "processing" || job.status === "started") {
          setCreatorsGenProgress(Number(job.progress ?? 0));
          statusPoll(operationId);
        } else if (job.status === "finished") {
          setStage("project-creation");
          setCreatorsResp(job);
        } else if (job.status === "error" || job.status === "failed") {
          handleError("creators API failure");
        } else {
          // for now keep polling on unknown status, but longer term we should make this error
          statusPoll(operationId);
        }
      })
      .catch(handleError);
  };

  // poll creators job status
  const statusPoll = (operationId: string) => {
    const timeoutId = setTimeout(() => updateCreatorsJobStatus(operationId), POLLING_INTERVAL);
    pollingTimeoutId.current = timeoutId;
  };

  // clear polling timeout on unmount
  useEffect(() => {
    return () => {
      clearTimeout(pollingTimeoutId.current);
    };
  }, []);

  useEffect(() => {
    if (!creatorsResp || templatesLoading) {
      return;
    }

    const templateId = settings?.aiCreators?.templateId;
    const template: CaptionTemplate | undefined =
      [...(captionStyleTemplates ?? []), ...customTemplates]?.find(
        (preset) => preset.id === templateId
      ) ?? captionStyleTemplates?.at(0);
    const captionSettings: NewCaptionSettings = {
      preset: template?.style?.content,
      textColor: template?.colors.primary,
      activeColor: template?.colors.active,
      emphasisColor: template?.colors.emphasis,
      activeWordBackground: template?.style.content.activeWordBackground.color,
      wordBackground: template?.colors.wordBackground,
      emojiSettings: template?.emojiSettings,
      positionFactor: DEFAULT_POSITION_FACTOR,
    };

    const createProjectWithThumbnailsAndCaptions = async () => {
      const { title: generatedTitle, transcription, fileId } = creatorsResp;
      if (!transcription || !fileId) {
        throw new Error("No transcription data found");
      }

      const localProjectId = `${projectId}-0`;

      const folderTitle = getDefaultProjectTitle({
        type: "ai-creator-folder",
        generatedTitle,
        transcript: settings?.aiCreators?.transcript,
      });
      const projectTile = getDefaultProjectTitle({
        type: "ai-creator",
        generatedTitle,
      });

      const newCreatorsFolder: ProjectFolder = {
        id: projectId,
        folderType: "ai-avatar",
        title: folderTitle,
        createdAt: new Date(),
        updatedAt: new Date(),
        thumbnailPath: null,
        folderTypeMetadata: {
          originalVideoDuration: 300, // placeholder
          shortsLength: "auto", // placeholder
          projectTimespans: [
            {
              endTime: 300, // placeholder
              startTime: 0, // placeholder
              projectId: localProjectId,
            },
          ],
          aiAdsSettings: settings?.aiCreators,
        },
      };

      await ProjectRepository.addProjectFolder(newCreatorsFolder);

      const transcript = parseTranscriptResponseEntry(
        Object.values(transcription.result.result)[0] as ServiceTranscript
      );

      const fileInfo = await getUploadedFileInfo(client, fileId, {
        enableCreateMuxAsset,
      });

      const countryCode = "en-US";

      const projectToAdd: Project = {
        id: localProjectId,
        title: projectTile,
        createdAt: new Date(),
        lastGeneratedFileId: "",
        sourceFileId: fileId,
        updatedAt: new Date(),
        sourceVideoMetadata: {
          duration: fileInfo.fileMetadata.duration ?? 0,
          fps: fileInfo.fileMetadata.fps ?? 0,
          width: fileInfo.fileMetadata.width ?? 0,
          height: fileInfo.fileMetadata.height ?? 0,
          size: fileInfo.fileMetadata.size ?? 0,
        },
        sourceFileLanguageCode: "en-US",
        folderId: projectId,
      };

      projectToAdd.thumbnailPath = await createThumbnail(projectToAdd);

      await ProjectRepository.addProject(projectToAdd);

      const { words, phrases, phraseAudios } = captions.generateFromTranscript(
        transcript,
        template?.emphasisSettings
      );

      const captionsId = await CaptionsRepository.addCaptions({
        projectId: localProjectId,
        languageCode: countryCode,
        words,
        phrases,
        phraseAudios,
        videoClipsApplied: true,
      });

      const project = await ProjectRepository.updateProject(localProjectId, {
        sourceFileLanguageCode: countryCode,
        captionsId,
      });

      const width = project?.sourceVideoMetadata.width ?? 0;
      const height = project?.sourceVideoMetadata.height ?? 0;
      const aspectRatio = getTargetAspectRatioNumber("9_16");
      const { targetWidth, targetHeight } =
        width && height
          ? getTargetSize(aspectRatio, width, height)
          : { targetWidth: 0, targetHeight: 0 };
      let sizeFactor = 1;
      if (targetWidth && targetHeight && template?.style?.content) {
        sizeFactor = await calculateCaptionSizeFactor(targetWidth, targetHeight, {
          stylePreset: template.style.content,
          emphasisSettings: template.emphasisSettings,
          words,
          countryCode,
          templateId,
        });
      }

      const captionSettingsId = await CaptionSettingsRepository.addCaptionSettings({
        ...captionSettings,
        projectId: project!.id,
        sizeFactor,
      });

      return ProjectRepository.updateProject(localProjectId, {
        captionSettingsId,
      });
    };

    createProjectWithThumbnailsAndCaptions()
      .then(() => {
        setStage("finished");
      })
      .catch(handleError);
  }, [creatorsResp, templatesLoading]);

  useEffect(() => {
    startAIAvatarGenerationJob(client, {
      avatarId: settings?.aiCreators?.avatarId ?? "",
      transcript: settings?.aiCreators?.transcript ?? "",
      avatarVariantId: settings?.aiCreators?.avatarVariantId ?? "",
      type: settings?.aiCreators?.avatarType,
    })
      .then((submitCreatorsResp) => {
        if (!submitCreatorsResp.data.operationId || !submitCreatorsResp.success) {
          throw new Error("Invalid response from creators API");
        }
        const responseOperationId = submitCreatorsResp.data.operationId;
        curOperationId.current = responseOperationId;
        statusPoll(responseOperationId);
      })
      .catch(handleError);
    setStage("ugc-gen");
  }, []);

  return <></>;
};
