import { IconProps } from "./types";

function FileError({ height = "24", width = "24", ...props }: Readonly<IconProps>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.0002 12.6582V10.7084"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4118 6.41164L15.5826 3.58246C15.2075 3.2072 14.6986 2.99622 14.168 2.99622H6.99302C5.88799 2.99622 4.99219 3.89202 4.99219 4.99705V19.0029C4.99219 20.1079 5.88799 21.0037 6.99302 21.0037H16.9972C18.1022 21.0037 18.998 20.1079 18.998 19.0029V7.82623C18.998 7.29562 18.787 6.78678 18.4118 6.41164Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9977 14.8082C11.9424 14.8082 11.8977 14.853 11.8977 14.9082C11.8977 14.9635 11.9424 15.0083 11.9977 15.0083C12.0413 14.9964 12.0715 14.9569 12.0715 14.9117C12.0715 14.8666 12.0413 14.827 11.9977 14.8152"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default FileError;
