/**
 * Generates a list of frame timestamps that encapsulate all possible animation frames for an effect
 * starting at `start`, ending at `end` and running at `fps`
 * @param start - The start time of the effect in seconds
 * @param end - The end time of the effect in seconds
 * @param fps - The frames per second rate of the video the effect will be overlaid on
 */
export function getSynchronizedFrameTimes(start: number, end: number, fps: number): number[] {
  const frameInterval = 1 / fps;
  const frameStart = Math.floor(start / frameInterval);
  const frameEnd = Math.ceil(end / frameInterval);
  if (frameStart === frameEnd) {
    // This is necessary due to some javascript floating point precision issues that can cause
    // start > Math.ceil(start / frameInterval) * frameInterval
    const frameStartTime = frameStart * frameInterval;
    return [frameStartTime < start ? frameStartTime + frameInterval : frameStartTime];
  } else {
    return Array.from(
      { length: frameEnd - frameStart + 1 },
      (_, i) => (frameStart + i) * frameInterval
    );
  }
}
