export const FrameAnimations: { [key: string]: URL } = {
  "cinematic_filmBorder-vintage_a": new URL(
    "./assets/cinematic_filmBorder-vintage_a.pag",
    import.meta.url
  ),
  "cinematic_filmBorder-vintage_b": new URL(
    "./assets/cinematic_filmBorder-vintage_b.pag",
    import.meta.url
  ),
  "cinematic_filmBorder-vintage_c": new URL(
    "./assets/cinematic_filmBorder-vintage_c.pag",
    import.meta.url
  ),
};
