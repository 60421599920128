import { styled } from "~/theme";

import { IconProps } from "./types";

function NoResults({ height = "20", width = "20", ...props }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.3021 14.3021L12.3013 12.3013"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0003 0.996582V0.996582C15.0024 0.996582 19.0041 4.99825 19.0041 10.0003V10.0003C19.0041 15.0024 15.0024 19.0041 10.0003 19.0041V19.0041C4.99825 19.0041 0.996582 15.0024 0.996582 10.0003V10.0003C0.996582 4.99825 4.99825 0.996582 10.0003 0.996582Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.09766 15.8029L15.8029 3.09766"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.69873 12.201C8.19936 13.8017 10.7004 13.8017 12.201 12.3011C13.7016 10.8004 13.8017 8.2994 12.3011 6.79877L12.201 6.69873"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.59863 9.40022C5.59863 8.3998 5.9988 7.39938 6.69909 6.69909V6.69909C7.39938 5.9988 8.3998 5.59863 9.40022 5.59863"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default styled(NoResults, {
  width: 20,
  height: 20,
  color: "$grey-200",
});
