import { z } from "zod";

import { DubbingPhrase } from "../Dubbing";

/**
 * Contains data pertaining a single transcript word.
 *
 * @property {number} startTime
 * @property {number} endTime
 * @property {string} word
 * @property {boolean} isKeyword
 */
export type ServiceTranscriptWord = z.infer<typeof serviceTranscriptWordSchema>;
export const serviceTranscriptWordSchema = z.object({
  startTime: z.number(),
  endTime: z.number(),
  word: z.string(),
  isKeyword: z.boolean(),

  // only present on dubbing jobs:
  emoji: z.string().nullable().optional(),
  phraseId: z.string().nullable().optional(),
  speaker: z.string().nullable().optional(),
});

/**
 * Contains data pertaining a single transcript phrase.
 *
 * @property {number} startTime
 * @property {number} endTime
 * @property {number} id
 */
export type ServiceTranscriptPhrase = z.infer<typeof serviceTranscriptPhraseSchema>;
export const serviceTranscriptPhraseSchema = z.object({
  startTime: z.number(),
  endTime: z.number(),
  id: z.union([z.string(), z.number()]),
});

/**
 * Contains data pertaining speech duration.
 *
 * @property {number} startTime
 * @property {number} endTime
 */
export type ServiceTranscriptSpeechDuration = z.infer<typeof serviceTranscriptSpeechDurationSchema>;
export const serviceTranscriptSpeechDurationSchema = z.object({
  startTime: z.number(),
  endTime: z.number(),
});

/**
 * Contains the transcript's textual data.
 *
 * @property {ServiceTranscriptWord[]} words
 * @property {string} transcript
 * @property {string[]} keywords
 * @property {ServiceTranscriptPhrase[]} phrases
 * @property {ServiceTranscriptSpeechDuration[]} speechDurations
 */
export type ServiceTranscript = z.infer<typeof serviceTranscriptSchema>;
export const serviceTranscriptSchema = z.object({
  words: z.array(serviceTranscriptWordSchema),
  transcript: z.string(),
  keywords: z.array(z.string()),
  phrases: z.array(serviceTranscriptPhraseSchema),
  speechDurations: z.array(serviceTranscriptSpeechDurationSchema).nullable(),
});

/**
 * Contains all the information regarding the generated transcript.
 *
 * @property {Record<string, ServiceTranscript>} result - the transcript per audio track.
 */
export type ServiceTranscriptResultData = z.infer<typeof serviceTranscriptResultDataSchema>;
export const serviceTranscriptResultDataSchema = z.object({
  result: z.record(serviceTranscriptSchema),
});

export const startTranscriptionJobRequestSchema = z.object({
  inputFileId: z.string(),
  languageCode: z.string(),
  targetLanguageCode: z.string().optional(),
  isShortsJob: z.boolean().optional(),
});

export type StartTranscriptionJobRequest = z.infer<typeof startTranscriptionJobRequestSchema>;

export const startTranscriptionJobResponseSchema = z.object({
  jobId: z.string(),
});

export type StartTranscriptionJobResponse = z.infer<typeof startTranscriptionJobResponseSchema>;

export const TranscriptionJobStatusSchema = z.enum([
  "started",
  "progress",
  "processing",
  "error",
  "finished",
]);

export type TranscriptionJobStatus = z.infer<typeof TranscriptionJobStatusSchema>;

export const getTranscriptionJobResponseRawSchema = z.object({
  job: z.object({
    transcriptionJobId: z.string(),
    translateId: z.string().optional(),
    status: TranscriptionJobStatusSchema,
    transcript: serviceTranscriptResultDataSchema.nullable().default(null),
    errorMessage: z.string().nullable().optional(),
    progress: z.number(),
  }),
});

export type GetTranscriptionJobResponseRaw = z.infer<typeof getTranscriptionJobResponseRawSchema>;

/**
 * Contains data pertaining a single transcript word.
 *
 * @property {string} text - the word's text contents
 * @property {boolean} keyword - whether the word is a keyword or not
 * @property {boolean} startTime - the timestamp the word starts being active
 * @property {boolean} endTime - the timestamp the word stops being active
 */
export interface TranscriptWord {
  startTime: number;
  endTime: number;
  text: string;
  keyword: boolean;
  phraseId?: string | null;
}

/**
 * Contains data pertaining a single transcript.
 *
 * @property {TranscriptWord[]} words - words contained in this transcript.
 * @property {DubbingPhrase[]} phrases - phrases contained in this transcript.
 */
export interface Transcript {
  words: TranscriptWord[];
  phrases?: DubbingPhrase[];
}
