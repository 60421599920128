import type { PositionFactor } from "captions-engine";
import { Color, EmojiPosition, EmojiTiming } from "captions-engine";

import {
  CaptionEmojiSettings,
  CaptionEmphasisSettings,
  DEFAULT_EMOJI_SIZE,
} from "../services/CaptionStylePreset";

export const DEFAULT_TEXT_COLOR: Color = { red: 1, green: 1, blue: 1, alpha: 1 };
//const DEFAULT_ACTIVE_COLOR: Color = { red: 0.54118, green: 0.82745, blue: 0.16471, alpha: 1 };
// Making the active color the same as the text color for now until the correct "activeColorEnabled"
// behavior is determined, so the default styling won't look different between mobile and desktop.
export const DEFAULT_ACTIVE_COLOR = DEFAULT_TEXT_COLOR;
export const DEFAULT_ACTIVE_WORD_BACKGROUND: Color = {
  red: 0.4941,
  green: 0.0706,
  blue: 1.0,
  alpha: 0.0,
};

export const DEFAULT_EMPHASIS_COLOR: Color = { red: 0.3137, green: 1, blue: 0.07059, alpha: 1 };
export const DEFAULT_WORD_BACKGROUND: Color = {
  red: 0.49409,
  green: 0.07059,
  blue: 0.99999,
  alpha: 1,
};

export const DEFAULT_POSITION_FACTOR: PositionFactor = { x: 0.5, y: 0.2 };

/**
 * Initially set to 0. After the project loads, `getDefaultSizeFactor` will be called with the video
 * dimensions to get the correct default size factor.
 */
export const DEFAULT_SIZE_FACTOR = 0;

export const DEFAULT_ROTATION = undefined;

export const DEFAULT_EMOJI_SETTINGS: CaptionEmojiSettings = {
  position: EmojiPosition.emojiPositionAbove,
  timing: EmojiTiming.emojiTimingWord,
  size: DEFAULT_EMOJI_SIZE,
  aiEnabled: false,
};

export const DEFAULT_EMPHASIS_SETTINGS = {
  aiEnabled: false,
  keywords: {
    emphasize: false,
    supersize: false,
  },
  pictoralWords: {},
  nouns: {},
} satisfies CaptionEmphasisSettings;
