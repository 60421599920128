import { useMemo } from "react";

import { useMixpanelStore } from "~/stores/analytics/mixpanel";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sanitizeJson = (json: any) => {
  const isTemplateObj = json && json.style && json.style.content;
  if (isTemplateObj) {
    return {
      active_color_enabled: json.style.content?.activeColorEnabled,
      active_size_enabled: json.style.content?.activeSizeEnabled,
      animation_style: json.style.content?.animationStyle,
      animation_target: json.style.content?.animationTarget,
      capitalization: json.style.content?.capitalization,
      color_application_background_enabled: json.style.content?.colorApplicationBackgroundEnabled,
      font_name: json.style.content?.font?.fontName,
      hide_punctuation: json.style.content?.hidePunctuation,
      is_multiline_box: json.style.content?.isMultilineBox,
      randomize_rotation: json.style.content?.randomizeRotation,
    };
  }
  return json;
};

export const useMixpanel = () => {
  const [setUserProps, identify, track, reset] = useMixpanelStore((s) => [
    s.setUserProps,
    s.identify,
    s.track,
    s.reset,
  ]);

  return useMemo(
    () => ({
      identify,
      track,
      reset,
      setUserProps,
      sanitizeJson,
    }),
    // this still has kinda bad deps but since it all goes back to the store is should be okay!
    []
  );
};
