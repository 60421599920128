import * as Select from "@radix-ui/react-select";
import { forwardRef, Fragment, useCallback, useState } from "react";

import { useKeyboardShortcutBlocker } from "~/hooks/useKeyboardShortcutBlocker";

import ArrowDown from "../icons/ArrowDown";

import {
  FormSelectContainer,
  FormSelectContent,
  FormSelectArrowIcon,
  FormSelectItemSeparator,
  FormSelectLabelText,
  FormSelectScrollBar,
  FormSelectScrollRoot,
  FormSelectScrollThumb,
  FormSelectScrollViewport,
  FormSelectTrigger,
  FormSelectValue,
} from "./FormSelect.styles";
import { FormSelectProps } from "./FormSelect.types";
import { FormSelectItem } from "./FormSelectItem";

export const FormSelect = forwardRef<HTMLDivElement, FormSelectProps>(function FormSelect(
  { density, iconBg, options, value, customValue, onOpenChange, icon, ...props },
  ref
) {
  const [isOpen, setIsOpen] = useState(!!props.defaultOpen);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      setIsOpen(open);
      onOpenChange?.(open);
    },
    [onOpenChange]
  );

  useKeyboardShortcutBlocker(isOpen || !!props.open);

  return (
    <Select.Root value={value} onOpenChange={handleOpenChange} {...props}>
      <FormSelectTrigger>
        <FormSelectContainer>
          {icon}
          <FormSelectValue>
            <Select.Value>{customValue?.(value)}</Select.Value>
          </FormSelectValue>
          <FormSelectArrowIcon>
            <ArrowDown />
          </FormSelectArrowIcon>
        </FormSelectContainer>
      </FormSelectTrigger>
      <Select.Portal>
        <FormSelectContent ref={ref} density={density} iconBg={iconBg}>
          <FormSelectScrollRoot type="auto">
            <Select.Viewport style={{ overflowY: undefined }} asChild>
              <FormSelectScrollViewport>
                {options.map((item) => {
                  if ("options" in item) {
                    return (
                      <>
                        <Select.Group key={item.label}>
                          <Select.Label asChild>
                            <FormSelectLabelText>{item.label}</FormSelectLabelText>
                          </Select.Label>
                          {item.options.map((option) => (
                            <FormSelectItem
                              key={option.value}
                              label={option.label}
                              value={option.value}
                            />
                          ))}
                        </Select.Group>
                        <FormSelectItemSeparator />
                      </>
                    );
                  } else {
                    return (
                      <Fragment key={item.value}>
                        <FormSelectItem label={item.label} value={item.value} />
                        <FormSelectItemSeparator />
                      </Fragment>
                    );
                  }
                })}
              </FormSelectScrollViewport>
            </Select.Viewport>
            <FormSelectScrollBar orientation="vertical">
              <FormSelectScrollThumb />
            </FormSelectScrollBar>
          </FormSelectScrollRoot>
        </FormSelectContent>
      </Select.Portal>
    </Select.Root>
  );
});
