import { DefaultError, useMutation, UseMutationOptions } from "@tanstack/react-query";
import { useCallback, useRef, useState } from "react";
import { z } from "zod";

import { usePromiseResolver } from "~/hooks/helpers";

import { mergeCallbackOptions } from "../merge-callbacks";

import { makeDefaultMutationFetcher } from "./default-fetcher";
import { BaseCreateHookOptions } from "./query-helpers";

export class PollingFailedError<FailedResult> extends Error {
  name = "PollingFailedError";

  constructor(public result: FailedResult) {
    super("Polling failed");
  }
}

export class PollingMaxAttemptsExceededError<ResponseSchema extends z.ZodTypeAny> extends Error {
  name = "PollingMaxAttemptsExceededError";

  constructor(public lastResponse: z.infer<ResponseSchema>) {
    super("Polling max attempts exceeded");
  }
}

interface PollingResultDone<DoneResult> {
  state: "done";
  result: DoneResult;
}

interface PollingResultPending<PendingResult> {
  state: "pending";
  result: PendingResult;
}

interface PollingResultFailed<FailedResult> {
  state: "failed";
  result: FailedResult;
}

type PollingResult<DoneResult, PendingResult, FailedResult> =
  | PollingResultDone<DoneResult>
  | PollingResultPending<PendingResult>
  | PollingResultFailed<FailedResult>;

interface PollingDetermineResultCreator {
  done: <DoneResult>(result: DoneResult) => PollingResultDone<DoneResult>;
  pending: <PendingResult = null>(result?: PendingResult) => PollingResultPending<PendingResult>;
  failed: <FailedResult = null>(result?: FailedResult) => PollingResultFailed<FailedResult>;
}

// this is a slightly weird pattern, but it's the only way i can get the type inference to work
const resultCreator = (): PollingDetermineResultCreator => ({
  done: <DR>(result: DR): PollingResultDone<DR> => ({
    state: "done",
    result,
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pending: <PR = null>(result: PR = null as any): PollingResultPending<PR> => ({
    state: "pending",
    result,
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  failed: <FR = null>(result: FR = null as any): PollingResultFailed<FR> => ({
    state: "failed",
    result,
  }),
});

interface CustomUsePollingCallerOptions<
  ParamsSchema extends z.ZodTypeAny,
  ResponseSchema extends z.ZodTypeAny,
  DoneResult,
  PendingResult,
  FailedResult,
> extends Omit<
    UseMutationOptions<z.infer<ResponseSchema>, DefaultError, z.infer<ParamsSchema>, unknown>,
    "mutationFn" | "onError" | "onSuccess"
  > {
  /**
   * returning promise allows you to keep `isPending` true while you're invalidating another query
   */
  onSuccess?: (data: DoneResult, variables: z.TypeOf<ParamsSchema>) => void | Promise<void>;

  onPollingPendingResult?: (result: PendingResult, variables: z.TypeOf<ParamsSchema>) => void;

  onError?: (
    error:
      | Error
      | PollingFailedError<FailedResult>
      | PollingMaxAttemptsExceededError<ResponseSchema>,
    variables: z.TypeOf<ParamsSchema>
  ) => void;
}

interface CreateUsePollingHookOptions<
  ParamsSchema extends z.ZodTypeAny,
  ResponseSchema extends z.ZodTypeAny,
  DoneResult,
  PendingResult,
  FailedResult,
> extends BaseCreateHookOptions<ParamsSchema, ResponseSchema>,
    CustomUsePollingCallerOptions<
      ParamsSchema,
      ResponseSchema,
      DoneResult,
      PendingResult,
      FailedResult
    > {
  /** @default 100: number of attempts before giving up */
  maxPollingAttempts?: number;
  /** @default 1000: which is 1s between attempts */
  pollingInterval?: number;
  determinePollingStatus: (
    status: z.infer<ResponseSchema>,
    resultCreator: PollingDetermineResultCreator
  ) => PollingResult<DoneResult, PendingResult, FailedResult>;
}

export function createUsePollingHook<
  ParamsSchema extends z.ZodTypeAny,
  ResponseSchema extends z.ZodTypeAny,
  DoneResult,
  PendingResult,
  FailedResult,
>({
  path,
  method,
  responseSchema,
  headers,
  paramsSchema,
  ...definitionOptions
}: CreateUsePollingHookOptions<
  ParamsSchema,
  ResponseSchema,
  DoneResult,
  PendingResult,
  FailedResult
>) {
  const {
    proxied,
    analytics,
    determinePollingStatus,
    maxPollingAttempts = 100,
    pollingInterval = 1000,
    ...defaultOptions
  } = definitionOptions;

  return function useCustomPollingHook(
    options: CustomUsePollingCallerOptions<
      ParamsSchema,
      ResponseSchema,
      DoneResult,
      PendingResult,
      FailedResult
    > = {}
  ) {
    // TODO(jason): follow up - automatically start polling if params are provided in caller options

    const { resolve, reject, reset: resetPromise } = usePromiseResolver<DoneResult>();
    const attemptCountRef = useRef(0);

    const [overriddenError, setOverriddenError] = useState<Error | undefined>();
    const [currentResult, setCurrentResult] =
      useState<PollingResult<DoneResult, PendingResult, FailedResult>>();

    const { onError, onSuccess, onPollingPendingResult, ...mergedOptions } = mergeCallbackOptions(
      ["onSuccess", "onError", "onPollingPendingResult"],
      defaultOptions,
      options
    );

    const handleError = useCallback(
      (error: Error, variables: z.TypeOf<ParamsSchema>) => {
        reject(error);

        onError?.(error, variables);
        setOverriddenError(error);
        setCurrentResult(undefined);
      },
      [reject, onError]
    );

    const {
      mutate: originalMutate,
      mutateAsync: originalMutateAsync,
      isPending,
      error,
      reset: resetMutation,
    } = useMutation({
      mutationFn: makeDefaultMutationFetcher({
        path,
        method,
        paramsSchema,
        responseSchema,
        proxied,
        headers,
        analytics,
      }),
      ...mergedOptions,

      onSuccess(data, variables) {
        const status = determinePollingStatus(data, resultCreator());
        setCurrentResult(status);

        if (status.state === "done") {
          resolve(status.result);

          // this one we return to allow chaining onSuccess promise callbacks
          return onSuccess?.(status.result, variables);
        } else if (status.state === "pending") {
          onPollingPendingResult?.(status.result as PendingResult, variables);

          scheduleNextMutateIfPossible(data, variables);
        } else {
          handleError(new PollingFailedError(status.result), variables);
        }
      },

      onError(error, variables) {
        handleError(error, variables);
      },
    });

    const reset = useCallback(() => {
      attemptCountRef.current = 1;
      setOverriddenError(undefined);
      setCurrentResult(undefined);

      resetMutation();
      return resetPromise();
    }, [resetPromise, resetMutation]);

    const scheduleNextMutateIfPossible = useCallback(
      (lastResponse: z.infer<ResponseSchema>, variables: z.TypeOf<ParamsSchema>) => {
        if (attemptCountRef.current > maxPollingAttempts) {
          handleError(new PollingMaxAttemptsExceededError(lastResponse), variables);

          return;
        }

        attemptCountRef.current++;

        setTimeout(() => {
          originalMutate(variables);
        }, pollingInterval);
      },
      [originalMutate, handleError]
    );

    const begin: typeof originalMutate = useCallback(
      (variables) => {
        reset();
        originalMutate(variables);
      },
      [originalMutate, reset]
    );

    const beginAsync = useCallback(
      (variables: z.TypeOf<ParamsSchema>) => {
        const newPromise = reset();

        originalMutateAsync(variables).catch((e) => {
          console.error("caught error in beginAsync", e);
          newPromise.reject(e);
        });

        return newPromise.promise;
      },
      [originalMutateAsync, reset]
    );

    return {
      begin,
      beginAsync,
      isPending: isPending || currentResult?.state === "pending",
      currentResult,
      error: overriddenError ?? error,
    };
  };
}
