/**
 * Chunks an array into subarrays based on a field value.
 * This should be used when the array is already sorted by the field.
 * This will return a two dimensional array where each subarray contains items with the same field value.
 *
 * ```
 * input: [
 *   {id: 1, group: 'a'}, {id: 2, group: 'b'}, {id: 3, group: 'b'}, {id: 4, group: 'c'}
 * ]
 * output: [
 *   [{id: 1, group: 'a'}],
 *   [{id: 2, group: 'b'}, {id: 3, group: 'b'}],
 *   [{id: 4, group: 'c'}]
 * ]
 * ```
 */
export function linearChunkByField<T, K extends keyof T>(items: T[], field: K): T[][] {
  const chunks: T[][] = [];
  let currentChunk: T[] = [];
  let lastValue: T[K] | null = null;

  for (const item of items) {
    const value = item[field];
    if (lastValue !== null && value !== lastValue) {
      chunks.push(currentChunk);
      currentChunk = [];
    }
    currentChunk.push(item);
    lastValue = value;
  }

  chunks.push(currentChunk);
  return chunks;
}

export function chunkedArray<T>(items: T[], chunkSize: number) {
  const chunks: T[][] = [];
  for (let i = 0; i < items.length; i += chunkSize) {
    const chunk = items.slice(i, i + chunkSize);
    chunks.push(chunk);
  }

  return chunks;
}
