// Source: https://github.com/mswjs/examples/pull/101/files#diff-38ba90fe91d5a177214923a0159550cb6d601ec2f1f98a766c761056c49298a9

import { handlers } from "captions-mocks/src/handlers";
import { useState, type ReactNode } from "react";

import { useOnMountEffect } from "~/hooks/helpers";

export const MOCKS_LOCAL_STORAGE_KEY = "mocksState";
export const MOCKS_ENABLED_KEY = "mocksEnabled";

let mockingPromise: Promise<void> | undefined;

const isBrowser = typeof window !== "undefined";
const isDevMode = process.env.NODE_ENV === "development";
if (isBrowser && isDevMode) {
  mockingPromise = initMocking();
}

async function initMocking(): Promise<void> {
  // always start the worker so it's initialized correctly
  const { worker } = await import("captions-mocks/src/browser");

  // check localStorage
  const mocksState = getMocksStateFromLocalStorage();
  const isMockingEnabled = mocksState?.[MOCKS_ENABLED_KEY] ?? false;
  await worker.start({ quiet: !isMockingEnabled });

  // enable handlers
  const enabledHandlers = Object.values(handlers)
    .flat()
    .filter((handler) => !!mocksState?.[handler.info.header]);
  worker.use(...enabledHandlers);

  // stop for now if mocking is disabled
  if (!isMockingEnabled) {
    worker.stop();
  }
}

function getMocksStateFromLocalStorage(): Record<string, boolean> | undefined {
  const mocksState = localStorage.getItem(MOCKS_LOCAL_STORAGE_KEY);
  if (!mocksState) {
    return undefined;
  }
  try {
    return JSON.parse(mocksState);
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export function MocksProvider({ children }: Readonly<{ children: ReactNode }>) {
  // if MSW is enabled, we need to wait for the worker to start
  // this effectively disables SSR in dev mode
  // if you need to test SSR, comment out the MocksProvider
  const [ready, setReady] = useState(!isDevMode);

  useOnMountEffect(() => {
    mockingPromise?.then(() => setReady(true));
  });

  return ready ? children : null;
}
