import { AISceneWithStyle } from "../AIScenes.types";
import { EDLSegment } from "../EDL.types";

export function getPrimarySceneSegments(scene: AISceneWithStyle): EDLSegment[] {
  return [
    [
      scene.mainOffset,
      scene.mainOffset + scene.endTime - scene.startTime,
      0,
      scene.endTime - scene.startTime,
    ],
  ];
}
