import { BackendServicesClient } from "~/context/BackendServicesContext";

import { ServiceTranscriptResultData } from "../Transcription";

const AI_AVATAR_GEN_URL = "proxy/v1/ai-avatar/generate/submit";

export type AIAvatarJobType = "ugc" | "studio";

export interface AIAvatarJob {
  avatarId: string;
  avatarVariantId: string;
  /**
   * @remarks Throws error if flagged as unsafe or > 1000 chars
   */
  transcript: string;
  /**
   * @default "studio"
   * @remarks Can be either "ugc" or "studio". Optional and defaults to "studio"
   */
  type?: AIAvatarJobType;
}

export interface AIAvatarJobResponse {
  data: {
    operationId: string;
  };
  success: boolean;
}

export interface AIAvatarJobStatus {
  status: "started" | "processing" | "error" | "failed" | "finished";
  progress?: number;
  /**
   * @remarks Returned when state = "COMPLETE"
   */
  fileId?: string;
  durationSeconds?: number;
  videoUri?: string;
  title?: string;
  transcription?: { result: ServiceTranscriptResultData };
  scenes?: number[]; // timestamps of scenes? i believe this may only be returned for "studio" type (unclear)
  /**
   * @remarks Returned when state = "FAILED"
   */
  error?: string;
}

export async function startAIAvatarGenerationJob(
  client: BackendServicesClient,
  { avatarId, avatarVariantId, transcript, type }: AIAvatarJob
): Promise<AIAvatarJobResponse> {
  try {
    const result = await client.post<AIAvatarJobResponse>(AI_AVATAR_GEN_URL, {
      avatarId: avatarId,
      avatarVariantId: avatarVariantId,
      transcript,
      type,
    });
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getAIAvatarGenerationJobStatus(
  client: BackendServicesClient,
  jobId: string
): Promise<AIAvatarJobStatus> {
  try {
    const result = await client.post<AIAvatarJobStatus>("ai-avatar/v1/generate/status", {
      jobId,
    });

    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
