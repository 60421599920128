import { IDBPDatabase, openDB, DBSchema } from "idb";

import { TimelineProject } from "~/modules/timeline-project/types/project";

import {
  Captions,
  CaptionSettings,
  CustomTemplate,
  Effects,
  Project,
  ProjectFolder,
  StoredAsset,
  StoredThumbnail,
  UserFont,
} from "./database.types";

export interface CaptionsData extends DBSchema {
  projects: {
    key: string;
    value: Project;
  };
  captions: {
    key: string;
    value: Captions;
    autoIncrement: true;
  };
  captionSettings: {
    key: string;
    value: CaptionSettings;
    autoIncrement: true;
  };
  effects: {
    key: string;
    value: Effects;
    autoIncrement: true;
  };
  customTemplates: {
    key: string;
    value: CustomTemplate;
    autoIncrement: true;
  };
  userFonts: {
    key: string;
    value: UserFont;
    autoIncrement: true;
  };
  storedImages: {
    key: string;
    value: StoredAsset;
    autoIncrement: true;
  };
  projectFolders: {
    key: string;
    value: ProjectFolder;
  };
  cachedThumbnails: {
    key: string;
    value: StoredThumbnail;
    indexes: { projectId: string };
  };
  timelineProjects: {
    key: string;
    value: TimelineProject;
  };
}

export type CaptionsDatabaseInstance = IDBPDatabase<CaptionsData>;

const DB_NEWEST_VERSION = 9;
export const dbFactory = () =>
  openDB<CaptionsData>("captions_data", DB_NEWEST_VERSION, {
    upgrade(db: CaptionsDatabaseInstance, oldVersion) {
      if (oldVersion < 1) {
        db.createObjectStore("projects", {
          keyPath: "id",
        });
      }
      if (oldVersion < 2) {
        db.createObjectStore("captions", {
          keyPath: "id",
        });
        db.createObjectStore("captionSettings", {
          keyPath: "id",
        });
      }
      if (oldVersion < 3) {
        db.createObjectStore("customTemplates", {
          keyPath: "id",
        });
      }
      if (oldVersion < 4) {
        db.createObjectStore("effects", {
          keyPath: "id",
        });
      }
      if (oldVersion < 5) {
        db.createObjectStore("userFonts", {
          keyPath: "id",
        });
      }
      if (oldVersion < 6) {
        db.createObjectStore("storedImages", {
          keyPath: "id",
        });
      }
      if (oldVersion < 7) {
        db.createObjectStore("projectFolders", {
          keyPath: "id",
        });
      }
      if (oldVersion < 8) {
        const store = db.createObjectStore("cachedThumbnails", {
          keyPath: "id",
        });
        store.createIndex("projectId", "projectId");
      }
      if (oldVersion < 9) {
        db.createObjectStore("timelineProjects", {
          keyPath: "id",
        });
      }
    },
    blocked() {
      console.error("database blocked");
    },
    blocking() {
      console.error("database blocking");
    },
  });
