import glFilterFrag from "./gl-filter.frag";
import glTransformVert from "./gl-transform.vert";
import {
  createEmptyTexture,
  createWebGLArrayBuffer,
  initShaderProgram,
  setVertexAttributeBuffer,
} from "./webgl";

const GRAYSCALE_COLOR_FILTER = [0.3, 0.59, 0.11, 0.3, 0.59, 0.11, 0.3, 0.59, 0.11]; // adapted from https://medium.com/@rupertontheloose/functional-shaders-a-colorful-intro-part4-gray-scale-d8595ec75601
const NO_FILTERING_COLOR_FILTER = [1.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 1.0];

export function initFilter(gl: WebGLRenderingContext) {
  const shaderProgram = initShaderProgram(gl, glTransformVert, glFilterFrag);

  const programInfo = {
    program: shaderProgram,
    attribLocations: {
      vertexPosition: gl.getAttribLocation(shaderProgram, "aVertexPosition"),
      textureCoord: gl.getAttribLocation(shaderProgram, "aTextureCoord"),
    },
    uniformLocations: {
      transformMatrix: gl.getUniformLocation(shaderProgram, "uTransformMatrix"),
      uSampler: gl.getUniformLocation(shaderProgram, "uSampler"),
      colorFilterMatrix: gl.getUniformLocation(shaderProgram, "uColorFilterMatrix"),
      applyVignette: gl.getUniformLocation(shaderProgram, "uApplyVignette"),
    },
  };

  const buffers = {
    positionBuffer: createWebGLArrayBuffer(gl, [-1.0, -1.0, 1.0, -1.0, 1.0, 1.0, -1.0, 1.0]),
    textureCoordBuffer: createWebGLArrayBuffer(gl, [0.0, 1.0, 1.0, 1.0, 1.0, 0.0, 0.0, 0.0]),
    indicesBuffer: createWebGLArrayBuffer(gl, [0, 1, 2, 0, 2, 3], "element_array_buffer"),
  };

  const textures = {
    texture: createEmptyTexture(gl, [0, 0, 0, 0]),
  };

  return {
    programInfo,
    buffers,
    textures,
  };
}

export type FilterProgramData = ReturnType<typeof initFilter>;

export function paintFilter(
  gl: WebGLRenderingContext,
  filters: string[],
  { programInfo, buffers, textures }: FilterProgramData
) {
  gl.blendEquation(gl.FUNC_ADD);
  gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

  setVertexAttributeBuffer(
    gl,
    buffers.textureCoordBuffer,
    programInfo.attribLocations.textureCoord,
    2
  );

  setVertexAttributeBuffer(
    gl,
    buffers.positionBuffer,
    programInfo.attribLocations.vertexPosition,
    2
  );

  gl.useProgram(programInfo.program);
  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffers.indicesBuffer);

  const transform = [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1];
  gl.uniformMatrix4fv(programInfo.uniformLocations.transformMatrix, false, transform);
  gl.uniform1i(programInfo.uniformLocations.uSampler, 0);

  // Color filter
  if (filters.includes("grayscale")) {
    gl.uniformMatrix3fv(
      programInfo.uniformLocations.colorFilterMatrix,
      false,
      GRAYSCALE_COLOR_FILTER
    );
  } else {
    gl.uniformMatrix3fv(
      programInfo.uniformLocations.colorFilterMatrix,
      false,
      NO_FILTERING_COLOR_FILTER
    );
  }

  // Vignette filter
  if (filters.includes("vignette")) {
    gl.uniform1i(programInfo.uniformLocations.applyVignette, 1);
  } else {
    gl.uniform1i(programInfo.uniformLocations.applyVignette, 0);
  }

  gl.activeTexture(gl.TEXTURE0);
  gl.bindTexture(gl.TEXTURE_2D, textures.texture);

  {
    const vertexCount = 6;
    const type = gl.UNSIGNED_SHORT;
    const offset = 0;
    gl.drawElements(gl.TRIANGLES, vertexCount, type, offset);
  }
}
