import { createCanvas, freeCanvas } from "canvas-utils";
import { PAGComposition } from "libpag/types/web/src/pag-composition";
import { PAGFile } from "libpag/types/web/src/pag-file";
import { PAGView } from "libpag/types/web/src/pag-view";
import { PAG } from "libpag/types/web/src/types";

import { MICROSECONDS_IN_SECOND } from "./constants/time.constants";
import { EmojiFramePosition, EmojiPage } from "./emojiLayer.types";

/**
 * This is an arbitrary value that is used to scale the emojis to the same size.
 */
const BASE_EMOJI_SIZE = 40;

export class EmojiLayer {
  private _pagInstance: PAG;
  private _canvas: HTMLCanvasElement | OffscreenCanvas | null = null;
  private _pagView: PAGView | null = null;
  private _pagComposition: PAGComposition | null = null;
  private _compositionObjects: { destroy: () => void }[] = [];
  private _pages: EmojiPage[] = [];
  private _currentPage: EmojiPage | null = null;
  private _setupPromise: Promise<void> | null = null;
  private _queuedSetups: number = 0;
  private _timestamp: number = 0;
  private _onLoadAnimation: (animationId: string) => Promise<PAGFile | null>;

  constructor(pagInstance: PAG, onLoadAnimation: (animationId: string) => Promise<PAGFile | null>) {
    this._pagInstance = pagInstance;
    this._onLoadAnimation = onLoadAnimation;
  }

  private _destroyComposition() {
    this._pagComposition?.destroy();
    this._compositionObjects.forEach((layer) => layer.destroy());
    this._pagComposition = null;
    this._compositionObjects = [];
  }

  private async _generateComposition(width: number, height: number) {
    this._destroyComposition();
    if (width === 0 || height === 0 || this._pages.length === 0) {
      return;
    }
    const composition = this._pagInstance.PAGComposition.make(width, height);
    this._pagComposition = composition;
    const emojisPromise = this._pages.map((page) =>
      Promise.all(
        page.items.map((item) =>
          this._onLoadAnimation(item.emoji).then((animation) => ({
            startTime: item.startTime + page.startTime,
            duration: page.endTime - page.startTime - item.startTime,
            animation,
          }))
        )
      ).then((items) => {
        return {
          items,
        };
      })
    );
    const loadedEmojiPages = await Promise.all(emojisPromise);
    if (this._pagComposition === composition) {
      for (const emojiPage of loadedEmojiPages) {
        let x = 0;
        for (const emoji of emojiPage.items) {
          if (emoji.animation) {
            const layer = emoji.animation.copyOriginal();
            const scale = BASE_EMOJI_SIZE / layer.height();
            layer.setStartTime(emoji.startTime * MICROSECONDS_IN_SECOND);
            layer.setDuration(emoji.duration * MICROSECONDS_IN_SECOND);
            const matrix = this._pagInstance.Matrix.makeTrans(x, 0);
            matrix.preScale(scale, scale);
            layer.setMatrix(matrix);
            matrix.destroy();
            this._pagComposition?.addLayer(layer);
            this._compositionObjects.push(layer);
            x += layer.width() * scale;
          }
        }
      }
    }
  }

  private async _setupInternal() {
    this._queuedSetups = this._queuedSetups - 1;
    if (!this._canvas) {
      return;
    }
    this._pagView?.destroy();
    this._pagView = null;
    await this._generateComposition(this._canvas.width, this._canvas.height);
    if (this._pagComposition) {
      this._pagView =
        (await this._pagInstance.PAGView.init(this._pagComposition, this._canvas, {
          useCanvas2D: true,
          useScale: false,
        })) ?? null;
      const duration = this._pagView?.duration() ?? 0;
      const progress = duration && (this._timestamp * MICROSECONDS_IN_SECOND) / duration;
      this._pagView?.setProgress(progress);
    }
  }

  private _setup() {
    if (this._queuedSetups > 0) {
      return;
    }
    this._queuedSetups = this._queuedSetups + 1;
    this._setupPromise = (
      this._setupPromise
        ? this._setupPromise.then(() => this._setupInternal())
        : this._setupInternal()
    ).catch((error) => console.error(error));
  }

  public setArea(width: number, height: number) {
    if (this._canvas?.width === width && this._canvas?.height === height) {
      return;
    }
    if (this._canvas) {
      this._canvas.width = width;
      this._canvas.height = height;
    } else {
      this._canvas = createCanvas(width, height);
    }
    this._setup();
  }

  public setEmojiPages(pages: EmojiPage[]) {
    this._pages = pages;
    this._currentPage =
      this._pages.find(
        (page) => page.startTime <= this._timestamp && page.endTime > this._timestamp
      ) ?? null;
    this._setup();
  }

  public setTimestamp(timestamp: number) {
    this._currentPage =
      this._pages.find((page) => page.startTime <= timestamp && page.endTime > timestamp) ?? null;
    this._timestamp = timestamp;
    const duration = this._pagView?.duration();
    if (!duration) {
      return;
    }
    const progress = (timestamp * MICROSECONDS_IN_SECOND) / duration;
    this._pagView?.setProgress(progress);
  }

  private async _waitSetup() {
    let currentPromise: Promise<void> | null = null;
    while (currentPromise !== this._setupPromise) {
      currentPromise = this._setupPromise;
      await currentPromise;
    }
  }

  public async getFrame(position: EmojiFramePosition) {
    await this._waitSetup();
    const view = this._pagView;
    const canvas = this._canvas;
    const composition = this._pagComposition;
    if (!view || !canvas || !composition || !this._currentPage) {
      return null;
    }
    // Calculates the scale that needs to be applied to the emojis to make them the desired size.
    const emojiScale = position.emojiSize / BASE_EMOJI_SIZE;
    const actualTimestamp = composition.currentTime();
    // Calculates the number of emojis that should be visible at the current timestamp.
    // This uses the layers of the composition instead of this._currentPage to avoid positioning
    // mismatches.
    const visibleEmojiCount =
      new Array(composition.numChildren()).fill(0).reduce((count, _, index) => {
        const item = composition.getLayerAt(index);
        const startTime = item.startTime();
        const endTime = startTime + item.duration();
        if (startTime <= actualTimestamp && endTime > actualTimestamp) {
          return count + 1;
        }
        return count;
      }, 0) ?? 0;
    const pageWidth = visibleEmojiCount * BASE_EMOJI_SIZE;
    // Calculates the transformation matrix for the emojis.
    const matrix = this._pagInstance.Matrix.makeTrans(position.center.x, position.center.y);
    matrix.preScale(1 / position.videoScale.x, 1 / position.videoScale.y);
    matrix.preRotate((position.rotation * 180) / Math.PI);
    matrix.preTranslate(position.offset.x, position.offset.y);
    matrix.preScale(emojiScale, emojiScale);
    switch (position.horizontalAlignment) {
      case "center":
        matrix.preTranslate(-pageWidth / 2, 0);
        break;
      case "right":
        matrix.preTranslate(-pageWidth, 0);
        break;
    }
    composition.setMatrix(matrix);
    matrix.destroy();
    // Draws the emoji frame
    await view.flush();
    return canvas;
  }

  /**
   * Ensures that the current frame is prepared for drawing.
   */
  public async prepare() {
    await this._waitSetup();
    await this._pagView?.prepare();
  }

  destroy() {
    this._destroyComposition();
    this._pagView?.destroy();
    if (this._canvas) {
      freeCanvas(this._canvas);
      this._canvas = null;
    }
    this._pagView = null;
  }
}
