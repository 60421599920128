import {
  CaptionStylePreset,
  LineBreakMode,
  DynamicCaptionsShape,
  DynamicWordPlacement,
} from "captions-engine";
import { pseudoRandomFromTimestamp } from "stable-pseudo-rng";

import { Clip } from "~/utils/videoClips";

import { AIWord } from "../../../command-list/AIScenes.types";
import { type CaptionEmphasisSettings } from "../../services/CaptionStylePreset";
import { Transcript } from "../../services/Transcription";
import {
  getPhraseAudiosWithClipsRemoved,
  getWordsAndPhrasesWithClipsRemoved,
} from "../clipDeleting";

import {
  CaptionLine,
  CaptionPage,
  CaptionPhrase,
  CaptionPhraseAudio,
  CaptionWord,
  LineBreakOverride,
  ProjectCaptions,
} from "./captionProcessing.types";

/**
 * Characters that are considered punctuation.
 * @constant
 */
export const CAPTION_PUNCTUATION_CHARACTERS = ".,!?";
/**
 * Maximum interval between lines before a forced page break
 * @constant
 */
const PAGE_BREAK_INTERVAL = 1;

/**
 * The factor applied to the maximum word/character count for landscape videos
 * @constant
 */
const LANDSCAPE_WORD_COUNT_FACTOR = 2;

function getLastWord(lines: CaptionLine[]) {
  return lines[lines.length - 1].words[lines[lines.length - 1].words.length - 1];
}

/**
 * Checks whether a line should break before a given word.
 *
 * @param word - the word that might be added
 * @param wordsInLine - an array containing the words already on the current line
 * @param lineBreakMode - the line break mode
 * @param [isLandscape] - whether the video is in landscape mode, which causes lines to accommodate
 * more words before splitting
 * @returns whether a line break before the word should occur or not
 */
function shouldBreakLineBefore(
  word: CaptionWord,
  wordsInLine: CaptionWord[],
  lineBreakMode: LineBreakMode,
  isLandscape?: boolean
): boolean {
  const countFactor = isLandscape ? LANDSCAPE_WORD_COUNT_FACTOR : 1;
  if (word.lineBreakOverride != null) {
    // checks if the word has a line break override and applies it unconditionally
    return word.lineBreakOverride !== LineBreakOverride.noBreak;
  }
  if (word.startTime - wordsInLine[wordsInLine.length - 1].endTime >= PAGE_BREAK_INTERVAL) {
    // if the pause is long enough to cause a break between pages, bypass the line break mode
    // and split the line anyway
    return true;
  }
  if ("wordCount" in lineBreakMode) {
    return wordsInLine.length >= lineBreakMode.wordCount.count * countFactor;
  } else if ("onPunctuationAndPause" in lineBreakMode) {
    const { seconds, characterCount } = lineBreakMode.onPunctuationAndPause;
    const currentLineCharCount = wordsInLine.reduce((prev, cur) => {
      return prev + cur.text.length;
    }, 0);
    const intervalAfterLastWord = word.startTime - wordsInLine[wordsInLine.length - 1].endTime;
    const lastLineChar = wordsInLine.at(-1)!.text.slice(-1);
    return (
      currentLineCharCount > characterCount * countFactor ||
      intervalAfterLastWord > seconds ||
      CAPTION_PUNCTUATION_CHARACTERS.includes(lastLineChar)
    );
  } else if ("splitsIn" in lineBreakMode) {
    const firstWord = wordsInLine[0];
    const lastWord = wordsInLine.at(-1)!;
    return lastWord.endTime - firstWord.startTime > lineBreakMode.splitsIn.seconds;
  }
  if (!!wordsInLine[wordsInLine.length - 1].dynamicPlacement && !word.dynamicPlacement) {
    // if the previous word is dynamic captions and the current word isn't, end the dynamic captions line
    return true;
  }
  // lineBreakMode.random is not supported
  return false;
}

function shouldBreakPageBefore(
  line: CaptionLine,
  linesInPage: CaptionLine[],
  linesPerPage: number
): boolean {
  if (linesInPage.length === 0) {
    // if the page is empty, the first line will always be added
    return false;
  }
  if (line.words[0].lineBreakOverride === LineBreakOverride.pageBreak) {
    // checks if the first word has a line break that forces breaking pages
    return true;
  }
  return (
    line.words[0].startTime - getLastWord(linesInPage).endTime >= PAGE_BREAK_INTERVAL ||
    linesInPage.length >= linesPerPage
  );
}

/**
 * Creates a page object from an array of lines, filling in all fields.
 *
 * @param lines - the lines that will be grouped into a page
 * @param randomRotation - whether a repeatable pseudo-random rotation should be applied
 * @param [shape] - the shape of the dynamic captions
 * @returns a caption page object
 */
function linesIntoPage(
  lines: CaptionLine[],
  randomRotation: boolean,
  shape?: DynamicCaptionsShape
): CaptionPage {
  const firstWord = lines[0].words[0];
  const lastLine = lines[lines.length - 1];
  const lastWord = lastLine.words[lastLine.words.length - 1];
  const radiansMin = -0.2;
  const radiansAmplitude = 0.4; // Between [-0.2, 0.2]
  return {
    lines: lines,
    startTime: firstWord.startTime,
    endTime: lastWord.endTime,
    rotation: randomRotation
      ? pseudoRandomFromTimestamp(firstWord.startTime) * radiansAmplitude + radiansMin
      : 0,
    ...(shape && { dynamicPlacement: { shape } }),
  };
}

export function getCaptionWordsFromTranscript(
  transcript: Transcript,
  emphasisSettings?: CaptionEmphasisSettings
): CaptionWord[] {
  const supersizeKeywords = Boolean(
    emphasisSettings?.aiEnabled && emphasisSettings.keywords?.supersize
  );
  const emphasizeKeywords = Boolean(
    emphasisSettings?.aiEnabled && emphasisSettings.keywords?.emphasize
  );

  return transcript.words.map((word, index) => ({
    id: index,
    startTime: word.startTime,
    endTime: word.endTime,
    text: word.text,
    supersize: supersizeKeywords && word.keyword,
    emphasize: emphasizeKeywords && word.keyword,
    phraseId: word.phraseId,
    lineBreakOverride: null,
    isKeyword: word.keyword,
    keywordSettings: {
      supersize: word.keyword,
      emphasize: word.keyword,
    },
  }));
}

export function getCaptionWordsFromPages(pages: CaptionPage[]): CaptionWord[] {
  return pages.flatMap((page) => page.lines.flatMap((line) => line.words));
}

export function getAIWordFromCaptionWord(word: CaptionWord): AIWord {
  return {
    word: word.text,
    startTime: word.startTime,
    endTime: word.endTime,
  };
}

/**
 * Processes given words, splitting it into caption lines and pages according to the current
 * preset.
 *
 * @param words - the video's words
 * @param preset - the caption style preset
 * @param enableSupersize - whether "super size" is enabled or not
 * @param isLandscape - whether the video is in landscape mode or not
 * @returns an array containing the processed caption pages
 */
export function generateCaptionPages(
  words: CaptionWord[],
  preset: CaptionStylePreset,
  {
    isLandscape,
  }: {
    isLandscape?: boolean;
  } = {}
): CaptionPage[] {
  // Groups words into lines
  let currentLineWords: CaptionWord[] = [];
  const allLines: CaptionLine[] = [];
  const visibleWords = words.filter((word) => !word.isHidden);
  for (const word of visibleWords) {
    if (word.dynamicPlacement?.type === "start") {
      // start a new line
      if (currentLineWords.length > 0) {
        allLines.push({ words: currentLineWords });
      }
      currentLineWords = [word];
    } else if (word.dynamicPlacement?.type === "continue") {
      currentLineWords.push(word);
    } else if (word.supersize && !preset.superSizeNoBreak) {
      // if superSize is enabled, keywords must be in an exclusive line
      if (currentLineWords.length > 0) {
        allLines.push({ words: currentLineWords });
      }
      allLines.push({ words: [word] });
      currentLineWords = [];
    } else if (currentLineWords.length === 0) {
      currentLineWords.push(word);
    } else if (shouldBreakLineBefore(word, currentLineWords, preset.lineBreakMode, isLandscape)) {
      allLines.push({ words: currentLineWords });
      currentLineWords = [word];
    } else {
      currentLineWords.push(word);
    }
  }
  if (currentLineWords.length > 0) {
    allLines.push({ words: currentLineWords });
  }

  // Then group lines into pages
  let currentPageLines: CaptionLine[] = [];
  const result: CaptionPage[] = [];

  for (const line of allLines) {
    if (line.words[0].dynamicPlacement?.type == "start") {
      if (currentPageLines.length > 0) {
        result.push(linesIntoPage(currentPageLines, preset.randomizeRotation));
      }
      result.push(linesIntoPage([line], false, line.words[0].dynamicPlacement.shape));
      currentPageLines = [];
    } else if (line.words.length === 1 && line.words[0].supersize) {
      // if superSize is enabled, keywords must be in an exclusive page
      if (currentPageLines.length > 0) {
        result.push(linesIntoPage(currentPageLines, preset.randomizeRotation));
      }
      result.push(linesIntoPage([line], preset.randomizeRotation));
      currentPageLines = [];
    } else if (currentPageLines.length === 0) {
      currentPageLines.push(line);
    } else if (shouldBreakPageBefore(line, currentPageLines, preset.linesPerPage)) {
      result.push(linesIntoPage(currentPageLines, preset.randomizeRotation));
      currentPageLines = [line];
    } else {
      currentPageLines.push(line);
    }
  }
  if (currentPageLines.length > 0) {
    result.push(linesIntoPage(currentPageLines, preset.randomizeRotation));
  }
  return result;
}

export function getCaptionPhrasesFromTranscript(transcript: Transcript): CaptionPhrase[] {
  return (
    transcript.phrases?.map((phrase) => ({
      id: phrase.id,
      startTime: phrase.startTime,
      endTime: phrase.endTime,
      text: phrase.text,
      speaker: phrase.speaker,
    })) ?? []
  );
}

export function getCaptionPhraseAudiosFromTranscript(transcript: Transcript): CaptionPhraseAudio[] {
  return (
    transcript.phrases
      ?.filter(
        (phrase): phrase is typeof phrase & { gcsUri: string; downloadUrl: string } =>
          !!phrase.downloadUrl && !!phrase.gcsUri
      )
      .map((phrase) => ({
        id: phrase.id,
        phraseId: phrase.id,
        startTime: phrase.startTime,
        endTime: phrase.endTime,
        originalStartTime: phrase.startTime,
        originalEndTime: phrase.endTime,
        gcsUri: phrase.gcsUri,
        downloadUrl: phrase.downloadUrl,
      })) ?? []
  );
}

export function getUpdatedPhraseAudioURLsFromTranscript(
  currentPhrases: CaptionPhraseAudio[],
  transcript: Transcript
): CaptionPhraseAudio[] {
  return currentPhrases.map((phrase) => {
    const transcriptPhrase = transcript.phrases?.find((p) => p.id === phrase.id);
    if (!transcriptPhrase || !transcriptPhrase.downloadUrl || !transcriptPhrase.gcsUri) {
      return phrase;
    }
    return {
      ...phrase,
      downloadUrl: transcriptPhrase.downloadUrl,
      gcsUri: transcriptPhrase.gcsUri,
    };
  });
}

/**
 * Ensures that all words have a phraseId, based on the phrases that are passed.
 *
 * @param words - the words to ensure phraseIds for
 * @param phrases - the phrases to use as reference
 * @returns the words with phraseIds
 */
export function ensureWordPhraseIds(
  words: CaptionWord[] | undefined,
  phrases: CaptionPhrase[] | undefined
): CaptionWord[] | undefined {
  if (!words || !phrases) {
    return words;
  }
  return words.map((word) => {
    if (word.phraseId) {
      return word;
    }
    const phrase = phrases.find(
      (phrase) => phrase.startTime <= word.startTime && phrase.endTime >= word.endTime
    );
    if (!phrase) {
      return word;
    }
    return {
      ...word,
      phraseId: phrase.id,
    };
  });
}

/** Very simple function that resolves the global vs. local level caption hiding */
export function isWordHidden(word: CaptionWord, globalHidden: boolean) {
  return globalHidden || word.isHidden;
}

/**
 * Hides words that start within the given time spans.
 *
 * @param words - all caption words
 * @param timeSpans - the time spans to hide
 * @returns the words with the isHidden property set
 */
export function hideWords(
  words: CaptionWord[],
  timeSpans: { startTime: number; endTime: number }[]
) {
  if (!timeSpans.length) {
    return words;
  }

  return words.map((word) => {
    const interceptedTimespan = timeSpans.find(
      (span) => word.startTime < span.endTime && word.endTime > span.startTime
    );
    const startsInTimeSpan =
      interceptedTimespan != null && word.startTime >= interceptedTimespan.startTime;
    return {
      ...word,
      isHidden: startsInTimeSpan,
      endTime:
        interceptedTimespan != null && !startsInTimeSpan
          ? interceptedTimespan.startTime
          : word.endTime,
    };
  });
}

export function getDynamicWords(
  words: CaptionWord[],
  dynamicCaptionConfig: { startTime: number; endTime: number; shape: DynamicCaptionsShape }[]
): CaptionWord[] {
  if (!dynamicCaptionConfig.length) {
    return words.map((word) => ({ ...word, dynamicPlacement: undefined }));
  }

  let prevDynamicCaptions:
    | { startTime: number; endTime: number; shape: DynamicCaptionsShape }
    | undefined;
  return words.map((word) => {
    const interceptedDynamicCaptions = dynamicCaptionConfig.find(
      (span) => word.startTime < span.endTime && word.endTime > span.startTime
    );
    const startsInTimeSpan =
      interceptedDynamicCaptions != null && word.startTime >= interceptedDynamicCaptions.startTime;

    if (startsInTimeSpan) {
      // - if there was no previous dynamic captions, then start new dynamic captions
      // - if there was previous dynamic captions, and those captions end before this word's start time,
      // then start a new dynamic caption
      // - if there was previous dynamic captions, and those captions end after this word's start time,
      // then continue the existing dynamic captions
      const dynamicPlacement: DynamicWordPlacement = !prevDynamicCaptions
        ? { type: "start", shape: interceptedDynamicCaptions.shape }
        : word.startTime >= prevDynamicCaptions.endTime
        ? { type: "start", shape: interceptedDynamicCaptions.shape }
        : { type: "continue" };

      prevDynamicCaptions = interceptedDynamicCaptions;
      return {
        ...word,
        dynamicPlacement,
        endTime:
          word.endTime > interceptedDynamicCaptions.endTime
            ? interceptedDynamicCaptions.endTime
            : word.endTime,
      };
    }

    prevDynamicCaptions = undefined;
    return {
      ...word,
      dynamicPlacement: undefined,
      endTime:
        interceptedDynamicCaptions != null && !startsInTimeSpan
          ? interceptedDynamicCaptions.startTime
          : word.endTime,
    };
  });
}

export function getAdjustedCaptions(
  captions: ProjectCaptions,
  clips: Clip[],
  hideCaptionsAt: { startTime: number; endTime: number }[] = [],
  dynamicCaptionsConfig: { startTime: number; endTime: number; shape: DynamicCaptionsShape }[] = []
) {
  if (captions.skipAdjustments) {
    const wordsWithDynamicPlacement = getDynamicWords(captions.words, dynamicCaptionsConfig);

    return {
      ...captions,
      words: hideWords(wordsWithDynamicPlacement, hideCaptionsAt),
    };
  }

  const phraseAudios = getPhraseAudiosWithClipsRemoved(captions.phraseAudios, clips);
  const { words, phrases } = getWordsAndPhrasesWithClipsRemoved(
    captions.phrases,
    captions.words,
    clips
  );

  const wordsWithDynamicPlacement = getDynamicWords(words, dynamicCaptionsConfig);

  return {
    ...captions,
    words: hideWords(wordsWithDynamicPlacement, hideCaptionsAt),
    phrases,
    phraseAudios,
  };
}
