import type { Aspect } from "~/database/database.types";
import { BaseReframeValues } from "~/modules/pro-editor/data/stores/ProEditorReframeStore";
import { VideoTimeIntervalReframe } from "~/modules/project/components/ExportQualityDialog";
import { useReframe } from "~/modules/project/hooks/useReframe";
import { roundToValidResolution } from "~/utils/resolution";

import { createLogLabel } from "../log";
import { Clip } from "../videoClips";

import { reframeFill } from "./fill";
import { reframeFit } from "./fit";
import {
  ShotReframeBasicLayoutType,
  ShotReframeOperation,
  ShotReframeOptions,
  ShotReframeSplitLayoutType,
} from "./reframing.types";

const { warn } = createLogLabel("[reframing]");

/**
 * Checks if the current aspect ratio supports split-screen shots. They are only supported on
 * vertical and horizontal 16:9 and 9:16 aspect ratios.
 *
 * @param targetAspect - The target aspect ratio to check.
 * @returns True if the aspect ratio supports split-screen shots.
 */
export function isSplitPossible(targetAspect?: Aspect): targetAspect is "16_9" | "9_16" {
  return !!targetAspect && ["16_9", "9_16"].includes(targetAspect);
}

/**
 * Convert the user-friendly object describing a reframing behavior into a set
 * of src/dest rectangles expected by the renderer and backend.
 */
export function getReframeOperations(args: {
  options: ShotReframeOptions | undefined;
  originalWidth: number;
  originalHeight: number;
  targetWidth: number;
  targetHeight: number;
}): ShotReframeOperation[] {
  const { options, originalWidth, originalHeight, targetWidth, targetHeight } = args;

  const isReframed = !!options;
  const isSameWidth = targetWidth === originalWidth;
  const isSameHeight = targetHeight === originalHeight;
  const isSameSize = isSameWidth && isSameHeight;
  if (!isReframed || isSameSize) {
    return [];
  }

  if (isBasicReframe(options) || options?.layout?.type === "none") {
    return getBasicReframeOperations({
      options,
      originalWidth,
      originalHeight,
      targetWidth,
      targetHeight,
    });
  }

  if (isSplitReframe(options)) {
    return options.layout.rects;
  }

  warn(`unknown reframe type: ${options.layout.type}`);
  return [];
}

/** Convert a basic horizontal/vertical reframe to backend operation schema */
export function getBasicReframeOperations(args: {
  options: ShotReframeOptions;
  originalWidth: number;
  originalHeight: number;
  targetWidth: number;
  targetHeight: number;
}): ShotReframeOperation[] {
  const { fitOrFill } = args.options;
  if (fitOrFill === "fit") {
    return [reframeFit(args)];
  } else if (fitOrFill === "fill") {
    const offset = isBasicReframe(args.options) ? args.options.layout.offset : { dx: 0, dy: 0 };
    return [reframeFill({ ...args, offset })];
  }
  return [];
}

export function isBasicReframe(
  options: ShotReframeOptions | undefined
): options is ShotReframeOptions & {
  layout: { type: ShotReframeBasicLayoutType };
} {
  if (!options) {
    return false;
  }
  return ["custom-pan", "center-face"].includes(options.layout.type);
}

export function isSplitReframe(
  options: ShotReframeOptions | undefined
): options is ShotReframeOptions & {
  layout: { type: ShotReframeSplitLayoutType };
} {
  if (!options) {
    return false;
  }
  return [
    "split-horizontal",
    "split-horizontal-reverse",
    "split-vertical",
    "split-vertical-reverse",
  ].includes(options.layout.type);
}

/**
 * Returns the aspect ratio number for the given aspect.
 *
 * @param targetAspect - The aspect to get the number for.
 * @returns The aspect ratio number for the given aspect.
 */
export function getTargetAspectRatioNumber(targetAspect: Exclude<Aspect, "original">): number;
/**
 * Returns the aspect ratio number for the given aspect.
 *
 * @param targetAspect - The aspect to get the number for.
 * @returns Null if the aspect is "original".
 */
export function getTargetAspectRatioNumber(targetAspect: "original"): null;
/**
 * Returns the aspect ratio number for the given aspect.
 *
 * @param targetAspect - The aspect to get the number for.
 * @returns The aspect ratio number for the given aspect, or null if the aspect is "original".
 */
export function getTargetAspectRatioNumber(targetAspect: Aspect): number | null;
export function getTargetAspectRatioNumber(targetAspect: Aspect): number | null {
  return targetAspect === "9_16"
    ? 9 / 16
    : targetAspect === "4_5"
    ? 4 / 5
    : targetAspect === "16_9"
    ? 16 / 9
    : targetAspect === "1_1"
    ? 1
    : null;
}

/**
 * Returns the target width and height for the given aspect ratio and original size.
 *
 * @remarks This function returns sizes multiples of 2 to ensure compatibility with video codecs.
 * @param targetAspectNum - The aspect ratio number for the target aspect.
 * @param originalWidth - The original width.
 * @param originalHeight - The original height.
 * @returns The target width and height for the given aspect ratio and original size.
 */
export function getTargetSize(
  targetAspectNum: number,
  originalWidth: number,
  originalHeight: number
): { targetWidth: number; targetHeight: number } {
  const targetWidth = roundToValidResolution(
    Math.sqrt(originalWidth * originalHeight * targetAspectNum)
  );
  const targetHeight = roundToValidResolution(targetWidth / targetAspectNum);
  return {
    targetWidth,
    targetHeight,
  };
}

/** Generate schema expected by backend /export endpoint */
export function getReframeTrimVideoTimeInterval(
  reframedShots: Clip[],
  reframeParams: ReturnType<typeof useReframe>
): VideoTimeIntervalReframe[] | undefined {
  const didTrim = reframedShots.some((shot) => shot.deleted);
  const didReframe = !reframeParams.isSameAspect;
  if (!didTrim && !didReframe) {
    return undefined;
  }

  const { originalWidth, originalHeight, targetWidth, targetHeight } = reframeParams;
  return reframedShots
    .filter((item) => !item.deleted)
    .map(({ startTime, endTime, reframe }) => ({
      startTime,
      endTime,
      reframe: getReframeOperations({
        options: reframe,
        originalWidth,
        originalHeight,
        targetWidth,
        targetHeight,
      }),
    }));
}

export function getReframeTrimVideoTimeIntervalForProEditor(
  reframedShots: Clip[],
  reframeParams: BaseReframeValues
): VideoTimeIntervalReframe[] | undefined {
  const didTrim = reframedShots.some((shot) => shot.deleted);
  const didReframe = !reframeParams.isSameAspect;
  if (!didTrim && !didReframe) {
    return undefined;
  }

  const { originalWidth, originalHeight, targetWidth, targetHeight } = reframeParams;
  return reframedShots
    .filter((item) => !item.deleted)
    .map(({ startTime, endTime, reframe }) => ({
      startTime,
      endTime,
      reframe: getReframeOperations({
        options: reframe,
        originalWidth,
        originalHeight,
        targetWidth,
        targetHeight,
      }),
    }));
}
