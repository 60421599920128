import { HttpResponse, http } from "msw";

import faceDetectionResponse from "./faceDetectionResponse.mock.json";

export const faceDetectionHandlers = [
  http.post("*/video/v1/detect-faces", () => {
    return HttpResponse.json({ jobId: "U3axKa9n44GWyLyWNHRy" });
  }),

  http.get("*/video/v1/detect-faces/*/status", () => {
    return HttpResponse.json(faceDetectionResponse);
  }),
];
