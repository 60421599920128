import { FixedAsset } from "../../../utils/assetUtils";

import {
  BACKGROUND_IMPACT_ASSETS,
  BACKGROUND_PAPER_ASSETS,
  BACKGROUND_SCRAPBOOK_ASSETS,
} from "./backgrounds";
import { COLD_IMAGE_DATA_URL, WARM_IMAGE_DATA_URL } from "./colorEffects";
import {
  FILM_BORDER_A_URL,
  FILM_BORDER_B_URL,
  FILM_BORDER_C_URL,
  FRAME_BORDER_SCRAPBOOK_ASSETS,
} from "./frameBorders";
import { GRADIENT_DATA_URL } from "./gradient";
import { SCRAPBOOK_RANSOM_FONT } from "./imageFonts";
import { IMAGE_GREEN_CIRCLE_URL, IMAGE_RED_CIRCLE_URL, SCRAPBOOK_IMAGE_ASSETS } from "./images";
import {
  OVERLAY_CINEMATIC_ASSETS,
  OVERLAY_PAPER_ASSETS,
  OVERLAY_SCRAPBOOK_ASSETS,
} from "./overlay";
import { PAG_SEQUENCE_RECESS_ASSETS } from "./pagSequence";

export interface AssetOverrides {
  [id: string]: FixedAsset;
}

export const OVERLAY_EFFECT_OVERRIDES = {
  color_block_cold: {
    type: "image",
    url: new URL(COLD_IMAGE_DATA_URL),
  },
  color_block_warm: {
    type: "image",
    url: new URL(WARM_IMAGE_DATA_URL),
  },

  overlay_impact_gradient: {
    type: "image",
    url: new URL(GRADIENT_DATA_URL),
  },

  "cinematic_filmBorder-vintage_a": {
    type: "tar-frames",
    url: FILM_BORDER_A_URL,
  },
  "cinematic_filmBorder-vintage_b": {
    type: "tar-frames",
    url: FILM_BORDER_B_URL,
  },
  "cinematic_filmBorder-vintage_c": {
    type: "tar-frames",
    url: FILM_BORDER_C_URL,
  },

  ...OVERLAY_CINEMATIC_ASSETS,
  ...OVERLAY_PAPER_ASSETS,
  ...OVERLAY_SCRAPBOOK_ASSETS,
  ...FRAME_BORDER_SCRAPBOOK_ASSETS,
} satisfies AssetOverrides;

export const BACKGROUND_EFFECT_OVERRIDES = {
  ...BACKGROUND_IMPACT_ASSETS,
  ...BACKGROUND_PAPER_ASSETS,
  ...BACKGROUND_SCRAPBOOK_ASSETS,
} satisfies AssetOverrides;

export const IMAGE_EFFECT_OVERRIDE = {
  ...SCRAPBOOK_RANSOM_FONT,
  ...SCRAPBOOK_IMAGE_ASSETS,
  ...PAG_SEQUENCE_RECESS_ASSETS,
  image_red_circle: {
    type: "image",
    url: IMAGE_RED_CIRCLE_URL,
  },
  image_green_circle: {
    type: "image",
    url: IMAGE_GREEN_CIRCLE_URL,
  },
} satisfies AssetOverrides;
