import type { AiEditShot } from "@shared/generated/typescript/aiEdit/api/CreateAiEditVideoTemplate";

import { Command } from "../../CommandList.types";
import { EDLSegment } from "../../EDL.types";

export function generateSoundCommands(shots: AiEditShot[]) {
  const commands: Command[] = [];

  let mainTotal = 0;
  shots.forEach((shot) => {
    const shotDuration = shot.endTime - shot.startTime;

    const soundEffects = shot.shotTemplate?.soundEffects;
    if (!soundEffects) {
      return;
    }

    // step 1: loop through sound effects
    soundEffects.forEach(({ asset, volume, playbackMode }) => {
      if (!asset) {
        return;
      }

      // step 2: calculate sound start time relative to beginning of shot
      const soundStartTime = (() => {
        switch (playbackMode?.type.oneofKind) {
          case "onceFromEnd": {
            return shotDuration - playbackMode?.type.onceFromEnd.startTime;
          }
          case "onceFromStart": {
            return playbackMode?.type.onceFromStart.startTime;
          }
          default: {
            return 0;
          }
        }
      })();

      // step 3: create edl for shot
      const soundDuration = shot.endTime - (shot.startTime + soundStartTime);
      const segment: EDLSegment = [
        mainTotal + soundStartTime,
        mainTotal + soundDuration,
        0,
        soundDuration,
      ];

      // step 4: create command for shot
      const soundCommand: Command = {
        edl: { segments: [segment] },
        renderEffect: {
          renderEffectType: "sound",
          assetId: asset.id,
          volume,
        },
      };
      commands.push(soundCommand);
    });

    mainTotal += shotDuration;
  });

  return commands;
}
