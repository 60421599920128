import { PseudoRandomGenerator } from "stable-pseudo-rng";

import { ShakeData } from "./cameraShaker.types";
import { PixelMapping, updateTexture } from "./utils/webgl";
import {
  initShakeProgram,
  drawShakeProgram,
  ShakeProgramData,
} from "./utils/webgl/cameraShakerProgram";

/* TODO: Adjust how we define the offsets:
 * using the 0.015 factor from iOS makes the effect too subtle
 * we are using the 3x factor to make the effect more similar to iOS
 */
const IOS_OFFSET_FACTOR = 3;
const DEFAULT_MAX_OFFSET = 0.015 * IOS_OFFSET_FACTOR;
const DEFAULT_MAX_ROTATION = 1;

function randomStep(start: number, end: number, prng: PseudoRandomGenerator) {
  return prng.next() * (end - start) + start;
}

class InterpolationState<T> {
  public lastValue: T;
  public targetValue: T;
  public lastTimestamp: number | undefined;
  public nextTimestamp: number | undefined;
  public prng: PseudoRandomGenerator;

  constructor(initialValue: T, seed: number) {
    this.lastValue = initialValue;
    this.targetValue = initialValue;
    this.lastTimestamp = undefined;
    this.nextTimestamp = undefined;
    this.prng = new PseudoRandomGenerator(seed);
  }

  public reset(initialValue: T, seed: number) {
    this.lastValue = initialValue;
    this.targetValue = initialValue;
    this.lastTimestamp = undefined;
    this.nextTimestamp = undefined;
    this.prng = new PseudoRandomGenerator(seed);
  }

  public initialize(timestamp: number, targetValue: T) {
    this.lastTimestamp = timestamp;
    this.nextTimestamp = timestamp + randomStep(0.1, 0.25, this.prng);
    this.lastValue = targetValue;
    this.targetValue = targetValue;
  }

  public advance(targetValue: T) {
    this.lastValue = this.targetValue;
    this.lastTimestamp = this.nextTimestamp;
    this.nextTimestamp = this.lastTimestamp! + randomStep(0.1, 0.25, this.prng);
    this.targetValue = targetValue;
  }

  public calculateProgress(timestamp: number): number {
    return Math.abs(
      (timestamp - this.lastTimestamp!) / (this.nextTimestamp! - this.lastTimestamp!)
    );
  }
}

export class CameraShaker {
  private _shakeData: ShakeData[] = [];
  public currentEffect: ShakeData | undefined = undefined;
  private _timestamp: number = 0;

  private _rotationState = new InterpolationState<number>(0, 0);
  private _offsetState = new InterpolationState<PixelMapping[]>([], 0);

  private _shakeProgram: ShakeProgramData | null = null;

  public setupContext(ctx: WebGLRenderingContext | null) {
    if (ctx) {
      this._shakeProgram = initShakeProgram(ctx);
    } else {
      this._shakeProgram = null;
    }
  }

  public paint(
    glCtx: WebGLRenderingContext,
    glTexture: WebGLTexture,
    glCanvas: HTMLCanvasElement | OffscreenCanvas,
    mappings: PixelMapping[]
  ) {
    if (!this._shakeProgram || !this.currentEffect) {
      return mappings;
    }

    const { rotationAngle, offsetMappings } = this.getShakeData(mappings);
    // Apply shake effect via the WebGL program
    drawShakeProgram(glCtx, glTexture, this._shakeProgram, rotationAngle);
    updateTexture(glCtx, glTexture, glCanvas);

    return offsetMappings;
  }

  public destroy() {
    this._shakeProgram = null;
    this._shakeData = [];
    this.currentEffect = undefined;
    this._rotationState.reset(0, 0);
    this._offsetState.reset([], 0);
    this._timestamp = 0;
  }

  public setShakeData(shakeData: ShakeData[]) {
    this._shakeData = shakeData;
  }

  public setTimestamp(timestamp: number) {
    const currentEffect = this._shakeData.find((shake) => {
      const shakeStart = shake.timestamp;
      const shakeEnd = shakeStart + shake.duration;
      return timestamp >= shakeStart && timestamp <= shakeEnd;
    });

    if (this.currentEffect !== currentEffect) {
      const seed = currentEffect?.timestamp ?? 0;
      this._rotationState.reset(0, seed);
      this._offsetState.reset([], seed);
      this.currentEffect = currentEffect;
    }
    this._timestamp = timestamp;
  }

  public getShakeData(mappings: PixelMapping[]) {
    const rotationAngle = this._getInterpolatedRotation();
    const offsetMappings = this._getInterpolatedOffsets(mappings);

    return {
      rotationAngle,
      offsetMappings,
    };
  }

  private _getInterpolatedRotation() {
    if (!this.currentEffect) {
      this._rotationState.reset(0, 0);
      return 0;
    }

    if (this._shouldInitializeState(this._rotationState)) {
      this._rotationState.initialize(
        this._timestamp,
        this._calculateNewAngle(this.currentEffect?.maxRotation ?? DEFAULT_MAX_ROTATION)
      );
      return this._rotationState.lastValue;
    }

    if (this._timestamp >= this._rotationState.nextTimestamp!) {
      this._rotationState.advance(
        this._calculateNewAngle(this.currentEffect?.maxRotation ?? DEFAULT_MAX_ROTATION)
      );
    }

    const progress = this._rotationState.calculateProgress(this._timestamp);
    this._rotationState.lastValue = this._lerp(
      this._rotationState.lastValue,
      this._rotationState.targetValue,
      progress
    );

    return this._rotationState.lastValue;
  }

  private _getInterpolatedOffsets(mappings: PixelMapping[]) {
    if (!this.currentEffect) {
      this._offsetState.reset(mappings, 0);
      return mappings;
    }

    if (this._shouldInitializeState(this._offsetState)) {
      this._offsetState.initialize(this._timestamp, this._calculateTargetMappings(mappings));
      return mappings;
    }

    if (this._timestamp >= this._offsetState.nextTimestamp!) {
      this._offsetState.advance(this._calculateTargetMappings(mappings));
    }

    const progress = this._offsetState.calculateProgress(this._timestamp);

    const smoothMappings = mappings.map((mapping, i) => {
      const lastMapping = this._offsetState.lastValue[i] || mapping;
      const targetMapping = this._offsetState.targetValue[i] || mapping;

      return this._lerpPixelMapping(lastMapping, targetMapping, progress);
    });

    return smoothMappings;
  }

  private _shouldInitializeState<T>(state: InterpolationState<T>) {
    return !state.lastTimestamp || !state.nextTimestamp || this._timestamp < state.lastTimestamp;
  }

  private _calculateNewAngle(maxRotation: number) {
    const correctionFactor = randomStep(0.25, 0.4, this._rotationState.prng);
    const newAngleMagnitude = randomStep(-maxRotation, maxRotation, this._rotationState.prng);

    return this._rotationState.lastValue === newAngleMagnitude
      ? this._rotationState.lastValue * (1 - correctionFactor) +
          newAngleMagnitude * correctionFactor
      : newAngleMagnitude;
  }

  private _calculateTargetMappings(mappings: PixelMapping[]) {
    const maxOffset = this.currentEffect?.maxOffset ?? DEFAULT_MAX_OFFSET;
    return mappings.map((mapping) => {
      const { centerX, centerY } = this._calculateCenter(mapping);
      const offsetX = this._calculateOffset(maxOffset, centerX);
      const offsetY = this._calculateOffset(maxOffset, centerY);
      return this._applyOffset(mapping, offsetX, offsetY);
    });
  }

  private _lerpPixelMapping(start: PixelMapping, end: PixelMapping, factor: number) {
    return {
      from: {
        left: this._lerp(start.from.left, end.from.left, factor),
        top: this._lerp(start.from.top, end.from.top, factor),
        right: this._lerp(start.from.right, end.from.right, factor),
        bottom: this._lerp(start.from.bottom, end.from.bottom, factor),
      },
      to: start.to, // Assuming 'to' remains constant
    };
  }

  private _lerp(start: number, end: number, factor: number) {
    return start + factor * (end - start);
  }

  private _calculateCenter(mapping: PixelMapping) {
    const width = mapping.from.right - mapping.from.left;
    const height = mapping.from.bottom - mapping.from.top;
    return {
      centerX: mapping.from.left + width / 2,
      centerY: mapping.from.top + height / 2,
    };
  }

  private _calculateOffset(maxOffset: number, tendency: number) {
    const correctionFactor = 0.1;
    const offset =
      randomStep(-maxOffset, maxOffset, this._offsetState.prng) * 0.5 + correctionFactor * tendency;
    return this._clamp(offset, -maxOffset, maxOffset);
  }

  private _applyOffset(mapping: PixelMapping, offsetX: number, offsetY: number) {
    return {
      from: {
        left: mapping.from.left + offsetX,
        top: mapping.from.top + offsetY,
        right: mapping.from.right + offsetX,
        bottom: mapping.from.bottom + offsetY,
      },
      to: mapping.to,
    };
  }

  private _clamp(value: number, min: number, max: number) {
    return Math.max(min, Math.min(max, value));
  }
}
