import * as S from "./Spinner.styles";
import { SpinnerProps } from "./Spinner.types";

function Spinner(props: SpinnerProps) {
  return (
    <S.Spinner {...props}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </S.Spinner>
  );
}

export default Spinner;
