import { GetAssetsByIdsResponse } from "@shared/generated/typescript/aiEdit/api/CreateAiEditVideoTemplate";
import { getAssetsByIdsResponseSchema } from "@shared/generated/typescript/aiEdit/api/CreateAiEditVideoTemplateSchema";
import { z } from "zod";

import { queryRequester } from "~/utils/network/requester";

export const getAiEditAssetsByIds = async (ids: string[]) => {
  const assets = await queryRequester({
    path: "ai-edit/v2/assets",
    proxied: true,
    params: { ids },
    responseSchema: z.preprocess(
      (value) => GetAssetsByIdsResponse.fromJson(value as string),
      getAssetsByIdsResponseSchema
    ),
    transformer: (res) => res.staticAssets,
  });

  return assets;
};

export const getAiEditAssetById = async (id: string) => {
  const assets = await getAiEditAssetsByIds([id]);
  return assets[0];
};
