import diffSequence from "diff-sequences";

import { CaptionWord } from "../captionProcessing";

/**
 * Replaces the words of a phrase with new words, preserving the effects of the original words.
 * Only words pertaining to the phrase are replaced, all other words are passed through unchanged.
 *
 * This function uses the "diff-sequences" package to compare the current words with the new words,
 * using an algorithm to find the longest common subsequences, preserving their attributes, and
 * assigning defaults to the new words.
 *
 * @param phraseId - The id of the phrase to replace the words of.
 * @param currentWords - The current words of the project.
 * @param newWords - The new words to replace the current words with.
 * @param firstNewId - The id to start assigning to the newly created words.
 */
export function replacePhraseWords(
  phraseId: string,
  currentWords: CaptionWord[],
  newWords: Pick<CaptionWord, "text" | "startTime" | "endTime">[],
  firstNewId: CaptionWord["id"]
): CaptionWord[] {
  const currentPhraseWords = currentWords.filter((word) => word.phraseId === phraseId);
  const newPhraseWords: CaptionWord[] = newWords.map((word) => ({
    ...word,
    id: NaN,
    phraseId,
    supersize: false,
    emphasize: false,
    isKeyword: false,
    keywordSettings: {
      supersize: false,
      emphasize: false,
    },
  }));

  diffSequence(
    currentPhraseWords.length,
    newWords.length,
    (aIndex, bIndex) => currentPhraseWords[aIndex].text === newWords[bIndex].text,
    (nCommon, aCommon, bCommon) => {
      for (let i = 0; i < nCommon; i++) {
        newPhraseWords[bCommon + i] = {
          ...currentPhraseWords[aCommon + i],
          ...newWords[bCommon + i],
        };
      }
    }
  );

  for (const word of newPhraseWords) {
    if (isNaN(word.id)) {
      word.id = firstNewId++;
    }
  }

  return currentWords
    .filter((word) => word.phraseId !== phraseId)
    .concat(newPhraseWords)
    .sort((a, b) => a.startTime - b.startTime);
}
