import { AnimationStyle } from "captions-engine";
import { PseudoRandomGenerator } from "stable-pseudo-rng";

import { CAPTION_TEMPLATES } from "../../../../project/services/CaptionStylePreset";
import { TransitionAnimationGroupId } from "../../../../project/services/PagAnimations/TransitionAnimations.config";
import type {
  SceneStyleConfig,
  GlobalStyleConfig,
  AIImageBackendEnum,
  TransitionConfig,
} from "../AIEditStyles.types";

const CINEMATIC_SOUND_VOLUME = 0.2;

export const CINEMATIC_PREVIEW_URL =
  "https://captions-cdn.xyz/desktop-site/ai-edit-cinematic-preview.webm";

export const CINEMATIC_SCENE_STYLE_CONFIG = {
  // Cinematic Normal
  // Note: this style should remian the first entry
  "cinematic-normal": {
    builder: () => ({
      id: "cinematic-normal",
      capsPosition: "avoid-head",
      zoomStyle: "camera-shake",
    }),
  },

  // Cinematic Frames
  "cinematic-frames": {
    builder: (prng: PseudoRandomGenerator) => {
      const cinematicFrameOverlays = [
        "overlay_cinematic_light_leak",
        "overlay_cinematic_film_burn",
      ];

      const cinematicFrameSize = ["big-frame", "small-frame"];

      const frameType = cinematicFrameSize.at(prng.next() * cinematicFrameSize.length);
      const overlay = cinematicFrameOverlays.at(prng.next() * cinematicFrameOverlays.length);

      switch (frameType) {
        case "small-frame":
          return {
            id: "cinematic-frames",
            frame: {
              assetId: "cinematic_filmBorder-vintage_b",
            },
            overlay,
            overlayBlend: "screen",
            capsPosition: "bottom-half",
            sound: {
              assetId: "sfx_film_projector.wav",
              volume: CINEMATIC_SOUND_VOLUME,
            },
            isSingleSubject: true,
          };
        case "big-frame":
        default:
          return {
            id: "cinematic-frames",
            frame: {
              assetId: "cinematic_filmBorder-vintage_a",
            },
            overlay,
            overlayBlend: "screen",
            capsPosition: "avoid-head",
            sound: {
              assetId: "sfx_film_projector.wav",
              volume: CINEMATIC_SOUND_VOLUME,
            },
          };
      }
    },
  },

  // Cinematic Fullscreen Images
  "cinematic-fullscreen-images": {
    isImageStyle: true,
    builder: () => ({
      id: "cinematic-fullscreen-images",
      imageRules: {
        style: "CINEMATIC_FULLSCREEN",
        numImages: "duration-floor",
        zoomStyle: "center-zoom-in",
        imageLayout: "fullscreen",
        imagePositionType: "b-roll",
        // animationIn: "image_flash",
        // animationOut: "immediate",
      },
      capsPosition: "middle",
    }),
  },

  // Cinematic Frame Images
  "cinematic-frame-images": {
    isImageStyle: true,
    builder: () => ({
      id: "cinematic-frame-images",
      imageRules: {
        style: "CINEMATIC_HORIZONTAL",
        numImages: "duration-floor",
        zoomStyle: "center-zoom-in",
        imageLayout: "horizontal",
        imagePositionType: "b-roll",
        imageFrame: "cinematic_filmBorder-vintage_c",

        // animationIn: "immediate",
        // animationOut: "immediate",
      },
      capsPosition: "middle",
    }),
  },

  // Cinematic Title Card
  "cinematic-title-card": {
    isImageStyle: true,
    builder: (prng) => {
      const imageStyles: AIImageBackendEnum[] = [
        "CINEMATIC_TITLE_VHS",
        "CINEMATIC_TITLE_CARD",
        "CINEMATIC_TITLE_CLOUD",
      ];
      const imageStyle = imageStyles.at(prng.next() * imageStyles.length) || imageStyles[0];
      return {
        id: "cinematic-title-card",
        imageRules: {
          style: imageStyle,
          numImages: 1,
          imageLayout: "fullscreen",
          imagePositionType: "b-roll",
          animationOut: AnimationStyle["animationStyleImmediate"],
        },
        hideCaptions: true,
      };
    },
  },

  // Cinematic Dolly Zoom
  // TODO: we can't support this style until we support background removal
  // "cinematic-dolly-zoom": {
  //   isImageStyle: true,
  //   builder: (prng) => {
  //     const imageStyles = ["CINEMATIC_GOLDEN", "CINEMATIC_SHADOW", "CINEMATIC_FLOWERS", "CINEMATIC_SUNLIGHT", "CINEMATIC_LANDSCAPE"];

  //     const imageStyle = imageStyles.at(prng.next() * imageStyles.length);

  //     return {
  //       id: "cinematic-dolly-zoom",
  //       imageRules: {
  //         style: imageStyle,
  //         numImages: 1,
  //         zoomStyle: "center-zoom-out",
  //         imageLayout: "fullscreen",
  //         isBackground: true,
  //         // animationIn: "immediate",
  //         // animationOut: "immediate",
  //       },
  //       zoomStyle: "continuous",
  //     };
  //   },
  // },
} as const satisfies SceneStyleConfig;

export type CinematicSceneId = keyof typeof CINEMATIC_SCENE_STYLE_CONFIG;

export const CINEMATIC_GLOBAL_STYLE_CONFIG: GlobalStyleConfig = [
  {
    builder: () => ({
      assetId: "overlay_cinematic_grain100",
      blendMode: "soft-light",
    }),
  },
  {
    builder: (prng) => {
      const cinematicColorOverlays = ["color_block_warm", "color_block_cold"];

      const assetId =
        cinematicColorOverlays.at(prng.next() * cinematicColorOverlays.length) ||
        cinematicColorOverlays[0];

      return {
        assetId,
        blendMode: "lighter",
      };
    },
  },
];

export const CINEMATIC_TRANSITIONS: TransitionConfig = {
  builder: (currentSceneId, nextSceneId) => {
    const allowedTransitions: TransitionAnimationGroupId[] = [
      "leak",
      "flash",
      "flare",
      "film",
      "swipeUp",
      "left",
      "zoom",
    ];

    if (["cinematic-normal", "cinematic-frames"].includes(currentSceneId)) {
      if (nextSceneId === "cinematic-frames") {
        return allowedTransitions.filter(
          (transition) => transition !== "zoom" && transition !== "left" && transition != "swipeUp"
        );
      }
      if (
        nextSceneId === "cinematic-fullscreen-images" ||
        nextSceneId === "cinematic-frame-images"
      ) {
        return ["flash"];
      }
      if (nextSceneId === "cinematic-normal") {
        return ["leak", "flash"];
      }
    }

    if (
      ["cinematic-fullscreen-images", "cinematic-frame-images", "cinematic-title-card"].includes(
        currentSceneId
      )
    ) {
      return ["flash"];
    }

    return allowedTransitions;
  },
  library: [
    {
      assetId: "leak",
      volume: 0,
    },
    {
      assetId: "flash",
      volume: CINEMATIC_SOUND_VOLUME,
    },
    {
      assetId: "flare",
      volume: 0,
    },
    {
      assetId: "film",
      volume: CINEMATIC_SOUND_VOLUME,
    },
    {
      assetId: "zoom",
      volume: 0,
      outDuration: 9 / 30,
      inDuration: 15 / 30,
    },
    {
      assetId: "left",
      volume: 0,
      outDuration: 11 / 30,
      inDuration: 13 / 30,
    },
    {
      assetId: "swipeUp",
      volume: 0,
      outDuration: 13 / 30,
      inDuration: 11 / 30,
    },
  ],
};

// ID for the "Suzy" caption template
export const CINEMATIC_CAPTION_TEMPLATE = CAPTION_TEMPLATES.Suzy;
