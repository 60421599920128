import React, { useState } from "react";

import { NAPhoneNumberMask, isNA, regexPhoneNumber } from "~/utils/glob";

import { LoginStep } from "../SignIn/SignIn.types";

import EnterCode from "./EnterCode";
import EnterPhoneNumber from "./EnterPhoneNumber";
import * as S from "./SignInByPhone.styles";
import { SignInByPhoneProps } from "./SignInByPhone.types";

export default function SignInByPhone({ step, setStep }: SignInByPhoneProps) {
  const [selectedCode, setSelectedCode] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handlePhoneNumberChange = (
    value: string,
    isDeleteContentBackward?: boolean,
    region?: string
  ) => {
    const { length } = value;

    if (phoneNumber.length > length && (isDeleteContentBackward || value === "")) {
      setPhoneNumber(value);
      return;
    }

    if (!!length && Number.isNaN(Number(value[length - 1]))) {
      return;
    }

    if (isNA(region || selectedRegion)) {
      const purePhoneNumber = regexPhoneNumber(value);
      const updatedValue = NAPhoneNumberMask(purePhoneNumber);

      if (updatedValue) {
        setPhoneNumber(updatedValue);
      }

      return;
    }

    setPhoneNumber(value);
  };

  return (
    <S.Container>
      {step === LoginStep.EnterPhoneNumber && (
        <EnterPhoneNumber
          setStep={setStep}
          selectedCode={selectedCode}
          setSelectedCode={setSelectedCode}
          phoneNumber={phoneNumber}
          setPhoneNumber={(value, isDeleteContentBackward, region) =>
            handlePhoneNumberChange(value, isDeleteContentBackward, region)
          }
          selectedRegion={selectedRegion}
          setSelectedRegion={setSelectedRegion}
        />
      )}
      {step === LoginStep.EnterCode && (
        <EnterCode
          phoneNumber={phoneNumber}
          selectedCode={selectedCode}
          selectedRegion={selectedRegion}
          setStep={setStep}
        />
      )}
    </S.Container>
  );
}
