import { t } from "i18next";
import { useMemo } from "react";
import { Controller } from "react-hook-form";

import { ComboBox, ComboBoxGroup } from "~/components/ComboBox";
import * as SideForm from "~/components/SideForm";
import { Text } from "~/components/Text";

import {
  getLocalizedLanguageLabel,
  getLocalizedLanguageName,
} from "../../utils/getLocalizedLanguageName";

import { LanguageItemLabel } from "./LanguageItemLabel";

import { SpokenLanguageSelectorFieldProps, getLanguageOptionSearchScore } from ".";

export function SpokenLanguageField({
  control,
  disabled,
  languages,
  defaultLanguageCode,
  hideDescription,
  side,
  contentCss,
}: SpokenLanguageSelectorFieldProps) {
  const options: ComboBoxGroup[] = useMemo(() => {
    return [
      {
        label: t("common:language-selector.spoken-language.options.device-languages"),
        options: languages
          .filter((item) => item.code === defaultLanguageCode)
          .map((item) => {
            const displayName = t(`common:language-selector.default-language`, {
              language: item.display,
            });
            return {
              label: <LanguageItemLabel displayName={displayName} />,
              value: item.code,
              getSearchScore: (searchQuery) => getLanguageOptionSearchScore(item, searchQuery),
            };
          }),
      },
      {
        label: t("common:language-selector.spoken-language.options.all-languages"),
        options: languages
          .filter((item) => item.code !== defaultLanguageCode)
          .map((item) => ({
            label: (
              <LanguageItemLabel
                displayName={item.display}
                localizedDisplayName={getLocalizedLanguageName(item)}
              />
            ),
            value: item.code,
            getSearchScore: (searchQuery) => getLanguageOptionSearchScore(item, searchQuery),
          })),
      },
    ];
  }, [languages, defaultLanguageCode]);

  /** Display the selected value on a single line, unlike the options list */
  const customValue = (value?: string) => {
    const language = languages.find((item) => item.code === value);

    // Should never happen; fallback to default Radix Select behavior
    if (!language) {
      return undefined;
    }

    if (language.code === defaultLanguageCode) {
      return t(`common:language-selector.default-language`, {
        language: language.display,
      });
    }
    return getLocalizedLanguageLabel(language);
  };

  return (
    <>
      {!hideDescription && (
        <SideForm.Description>
          <Text variant="body-2-bold">{t("common:language-selector.spoken-language.heading")}</Text>
          <Text variant="body-2" color="grey-500">
            {t("common:language-selector.spoken-language.subtitle")}
          </Text>
        </SideForm.Description>
      )}
      <SideForm.Label>
        <Controller
          control={control}
          name="languageCode"
          render={({ field }) => (
            <ComboBox
              onValueChange={field.onChange}
              options={options}
              disabled={disabled}
              customValue={customValue}
              searchPlaceholder={t("common:language-selector.search-placeholder")}
              placeholder={t("common:language-selector.placeholder")}
              side={side}
              contentCss={contentCss}
              {...field}
            />
          )}
        />
      </SideForm.Label>
    </>
  );
}
