export const adSetScriptGenerateResponse = {
  hooks: [
    {
      id: "hook_1",
      text: "You can now create an AI Avatar like me to make whatever content you need effortlessly. Let me show you how.",
    },
    {
      id: "hook_2",
      text: "With an AI generated Creator like me, content that used to take hours to create, now takes seconds. Let me show you.",
    },
    {
      id: "hook_3",
      text: "Stop scrolling now [MORE TEXT]",
    },
    {
      id: "hook_4",
      text: "I can help you create content in seconds. Let me show you how.",
    },
    {
      id: "hook_5",
      text: "Three reasons [MORE TEXT]",
    },
  ],
  body: "Just open up AI Creator in the Captions App and write in a topic or prompt to instantly generate a customizable script.\n\nFrom there, tap “generate video” and you’ve got new content — in a matter of seconds.\n\nTry it out now on Captions.\n\n",
};
