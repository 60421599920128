import { getPainterModuleScript } from "./getPainterModuleScript";

const PAINTER_ELEMENT_ID = "smooth-round-rect-painter-loader";

export function addSmoothRoundRectModule() {
  const scriptLoader = document.getElementById(PAINTER_ELEMENT_ID);
  if (!scriptLoader && "paintWorklet" in CSS) {
    const module = getPainterModuleScript();
    const loaderCode = document.createTextNode(`CSS.paintWorklet.addModule("${module}");`);
    const loader = document.createElement("script");
    const loaderWrapper = document.createElement("div");
    loader.appendChild(loaderCode);
    loaderWrapper.setAttribute("id", PAINTER_ELEMENT_ID);
    loaderWrapper.appendChild(loader);
    document.body.appendChild(loaderWrapper);
  }
}
