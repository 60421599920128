import glBlendFrag from "./gl-blend.frag";
import glTransformVert from "./gl-transform.vert";
import {
  createEmptyTexture,
  createWebGLArrayBuffer,
  initShaderProgram,
  setVertexAttributeBuffer,
} from "./webgl";

export function initBlend(gl: WebGLRenderingContext) {
  const shaderProgram = initShaderProgram(gl, glTransformVert, glBlendFrag);
  const programInfo = {
    program: shaderProgram,
    attribLocations: {
      vertexPosition: gl.getAttribLocation(shaderProgram, "aVertexPosition"),
      textureCoord: gl.getAttribLocation(shaderProgram, "aTextureCoord"),
    },
    uniformLocations: {
      transformMatrix: gl.getUniformLocation(shaderProgram, "uTransformMatrix"),
      blendMode: gl.getUniformLocation(shaderProgram, "uBlendMode"),
      uSampler1: gl.getUniformLocation(shaderProgram, "uSampler1"),
      uSampler2: gl.getUniformLocation(shaderProgram, "uSampler2"),
    },
  };
  const buffers = {
    positionBuffer: createWebGLArrayBuffer(gl, [-1.0, -1.0, 1.0, -1.0, 1.0, 1.0, -1.0, 1.0]),
    textureCoordBuffer: createWebGLArrayBuffer(gl, [0.0, 1.0, 1.0, 1.0, 1.0, 0.0, 0.0, 0.0]),
    indicesBuffer: createWebGLArrayBuffer(gl, [0, 1, 2, 0, 2, 3], "element_array_buffer"),
  };
  const textures = {
    backdropTexture: createEmptyTexture(gl, [0, 0, 0, 255]),
    sourceTexture: createEmptyTexture(gl, [0, 0, 0, 255]),
  };

  return {
    programInfo,
    buffers,
    textures,
  };
}

export type BlendProgramData = ReturnType<typeof initBlend>;

/**
 * Maps blend mode names to their corresponding numbers in the blend shader program.
 */
export const blendModeNumbers = {
  add: 0,
  screen: 1,
  softlight: 2,
  sourceover: 3,
};

export function drawBlendOverlay(
  gl: WebGLRenderingContext,
  blendMode: number,
  { programInfo, buffers, textures }: BlendProgramData
) {
  // reset the context blend mode
  gl.blendEquation(gl.FUNC_ADD);
  gl.blendFunc(gl.ONE, gl.ZERO);

  setVertexAttributeBuffer(
    gl,
    buffers.positionBuffer,
    programInfo.attribLocations.vertexPosition,
    2
  );
  setVertexAttributeBuffer(
    gl,
    buffers.textureCoordBuffer,
    programInfo.attribLocations.textureCoord,
    2
  );
  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffers.indicesBuffer);

  gl.useProgram(programInfo.program);
  const transform = [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1];

  gl.uniformMatrix4fv(programInfo.uniformLocations.transformMatrix, false, transform);
  gl.uniform1i(programInfo.uniformLocations.blendMode, blendMode);

  gl.activeTexture(gl.TEXTURE0);
  gl.bindTexture(gl.TEXTURE_2D, textures.backdropTexture);
  gl.uniform1i(programInfo.uniformLocations.uSampler1, 0);
  gl.activeTexture(gl.TEXTURE1);
  gl.bindTexture(gl.TEXTURE_2D, textures.sourceTexture);
  gl.uniform1i(programInfo.uniformLocations.uSampler2, 1);
  {
    const vertexCount = 6;
    const type = gl.UNSIGNED_SHORT;
    const offset = 0;
    gl.drawElements(gl.TRIANGLES, vertexCount, type, offset);
  }
}
