import { styled } from "~/theme";

export const Container = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0px 0px 24px 0px",
});

export const Input = styled("input", {
  width: 44,
  height: 48,
  borderRadius: "$8",
  padding: "12px 6px",
  outline: "none",
  backgroundColor: "$grey-900",
  border: "1px solid $grey-700",
  textAlign: "center",
  fontFamily: "$avenir-next",
  fontWeight: "$medium",
  fontSize: "15px",
  color: "$grey-200",

  "&:focus": {
    backgroundColor: "$grey-800",
    border: "1px solid $grey-500",
  },

  variants: {
    isError: {
      true: {
        border: "1px solid $secondary-error",
      },
    },
  },
  defaultVariants: {
    isError: false,
  },
});
