import { Command } from "cmdk";

import { styled } from "~/theme";

export const INNER_ICON_CLASS = "inner__button";

export const ComboBoxItemElement = styled(Command.Item, {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "24px",
  borderRadius: 8,
  cursor: "pointer",
  width: "100%",
  [`& > div > .${INNER_ICON_CLASS}`]: {
    padding: 0,
    visibility: "hidden",
  },
  [`&:hover > div > .${INNER_ICON_CLASS}`]: {
    visibility: "visible",
  },
  "&:focus": {
    outline: "none",
  },
  '&[data-selected="true"]': {
    background: "$grey-800-highlighted",
  },
  defaultVariants: {
    size: "sm",
  },
  variants: {
    size: {
      sm: {
        padding: "4px 12px 4px 12px",
      },
      md: {
        minHeight: "44px",
        padding: "10px 12px 10px 12px",
      },
    },
  },
});

export const IconsContainer = styled("div", {
  alignItems: "center",
  display: "flex",
  gap: "16px",
});
