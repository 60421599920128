import { AnalyticsTool } from "captions-engine/src/services/analytics/analytics.types";
import { format } from "date-fns";

import { ShortsDataReqsStage } from "~/components/AIShortsDialog/useShortsDataReqs";
import { UserPlanSchema } from "~/context/CaptionsAuthContext";
import { ProjectFolderType, Aspect } from "~/database/database.types";
import { Point2D } from "~/modules/project/utils/positioningUtils/positioningUtils.types";

import { pickKeysWithValues } from "../pickBy";

import { getProjectTypeForAnalytics } from "./helpers";
import { analyticsTool } from "./track";

/** API Platform */
export const api_dashboard_view = ({
  credit_balance,
  entitlement,
}: {
  credit_balance: number;
  entitlement?: UserPlanSchema | null;
}): [
  string,
  {
    credit_balance: number;
    entitlement: string;
  },
] => {
  return [
    "api_dashboard_view",
    {
      credit_balance,
      entitlement: normalizeEntitlement(entitlement),
      // plan_type,
      // projects_count,
    },
  ];
};

export const api_key_generate_click = ({
  credit_balance,
  entitlement,
}: {
  credit_balance: number;
  entitlement?: UserPlanSchema | null;
  // plan_type: string;
}): [
  string,
  {
    credit_balance: number;
    entitlement: string;
  },
] => {
  return [
    "api_key_generate_click",
    {
      credit_balance,
      entitlement: normalizeEntitlement(entitlement || null),
    },
  ];
};

export const api_key_regenerate_click = ({
  status,
}: {
  status: "confirm" | "cancel";
}): [string, { status: "confirm" | "cancel" }] => {
  return ["api_key_regenerate_click", { status }];
};

export const api_documentation_click = (): [string, Record<string, never>] => {
  return ["api_documentation_click", {}];
};

export const api_key_copy_click = ({
  status,
  error,
}: {
  status: "success" | "fail";
  error?: string;
}): [string, { status: string; error?: string }] => {
  return ["api_key_copy_click", { status, error: status === "fail" ? error : undefined }];
};

export const subscription_upgrade_click = ({
  source,
  current_entitlement,
  credit_balance,
}: {
  source: string; // the page the user came from
  current_entitlement?: UserPlanSchema | null;
  credit_balance?: number;
  // product_id = current product user is on
  // current_plan_type = current plan type user is on - "monthly" | "yearly";
}): [
  string,
  {
    source: string;
    current_entitlement: string;
    credit_balance?: number;
  },
] => {
  return [
    "subscription_upgrade_click",
    {
      source,
      current_entitlement: normalizeEntitlement(current_entitlement),
      credit_balance,
    },
  ];
};

export const purchase_credits_completed = ({
  credits,
  entitlement,
  plan_renewal_date_millis,
  previous_credit_balance,
  price_cents,
}: {
  credits: string;
  entitlement?: UserPlanSchema | null;
  plan_renewal_date_millis?: number | null;
  previous_credit_balance: number;
  price_cents?: number;
}): [
  string,
  {
    entitlement: string;
    credits: number;
    previous_credit_balance: number;
    credit_balance: number;
    plan_renewal_date: string;
    price: number;
  },
] => {
  const creditsToBuy = credits === "" ? 0 : parseInt(credits, 10);

  return [
    "purchase_credits_completed",
    {
      entitlement: normalizeEntitlement(entitlement),
      credits: creditsToBuy,
      credit_balance: creditsToBuy + previous_credit_balance,
      previous_credit_balance,
      plan_renewal_date: normalizeRenewalDate(plan_renewal_date_millis),
      price: normalizePrice(price_cents),
    },
  ];
};

export const purchase_credits_click = (): [string, Record<string, never>] => {
  return ["purchase_credits_click", {}];
};

// KW 8/26: Lindsey mentioned that she wants us to decouple the stages from the outcomes.
// She'd like status to be limited to: success, fail, cancel - and the rest can be tracked as
// a separate property
type ProjectCreationStatus =
  | "styling"
  | "start"
  | "audio-extraction"
  | "cancel"
  | "close"
  | "dubbing"
  | "empty"
  | "error" // <-- this should say 'fail'
  | "finished"
  | "post-upload"
  | "pre-upload"
  | "success"
  | "transcription";

export type ProjectType =
  | "video"
  | "ai_ad"
  | "ai_creator"
  | "ai_edit"
  | "ai-edit"
  | "ai-shorts"
  | "ai-ads"
  | "ai-avatar"
  | "import";

export type TranslationMode = "autodub" | "captions_only" | "none";

export type ProjectCreationOperationIds = {
  recipe_operation_id?: string;
  collage_operation_id?: string;
};

type ProjectCreationStatusEvent = {
  status: ProjectCreationStatus;
  project_id: string;
  translation_mode?: TranslationMode;
  video_duration_ms?: number;
  video_file_id?: string | null;
  error?: string | null;
  project_type?: ProjectType;
  latency_file_selection?: number;
  latency_settings_set?: number;
  video_info?: {
    width: number;
    height: number;
    duration: number;
    size: number;
    codec_name: string;
    extension: string;
  };
  spoken_language?: string;
  translation_language?: string;
} & ProjectCreationOperationIds;

type ProjectCreationStatusArg = Omit<ProjectCreationStatusEvent, "video_duration_ms"> & {
  video_duration_seconds: number;
};

export const project_creation_status = ({
  status,
  project_id,
  video_duration_seconds,
  ...rest
}: ProjectCreationStatusArg): [string, ProjectCreationStatusEvent] => {
  return [
    "project_creation_status",
    {
      status,
      project_id,
      video_duration_ms: secondsToMilliseconds(video_duration_seconds),
      ...rest,
    },
  ];
};

export type FlowType = "ai_ad" | "ai_creator" | "script";

export type AICreatorsCreationStatus =
  | "fail"
  | "start"
  | "ugc-gen"
  | "project-creation"
  | "success";

type AICreatorsCreationStatusEventSchema = {
  status: AICreatorsCreationStatus;
  creator_id?: string;
  creator_name?: string;
  error?: string | null;
  flow_type: FlowType;
  latency?: number;
  project_id_list?: string[];
  relative_latency?: number;
  script_type?: string | null;
  transcript?: string;
  ugc_ad_count?: number;
  ugc_ads_group_id?: string;
  creators_group_id?: string;
  avatarVariantId?: string;
  video_duration_ms?: number;
};

type AICreatorsCreationStatusEventArg = Omit<
  AICreatorsCreationStatusEventSchema,
  "video_duration_ms"
> & {
  video_duration_seconds?: number;
};

export const ai_creators_creation_status = ({
  flow_type,
  status,
  video_duration_seconds,
  ...rest
}: AICreatorsCreationStatusEventArg): [string, AICreatorsCreationStatusEventSchema] => {
  return [
    "ai_creators_creation_status",
    {
      flow_type,
      status,
      ...pickKeysWithValues({
        ...rest,
        video_duration_ms: video_duration_seconds
          ? secondsToMilliseconds(video_duration_seconds)
          : undefined,
      }),
    },
  ];
};

export type AIShortsCreationStatusEventSchema = {
  status: string;
  ai_shorts_group_id: string;
  ai_shorts_count: number;
  project_id_list: string[];
  relative_latency: number;
  video_source: string;
  cancel_stage?: {
    stage: ShortsDataReqsStage;
    time: number;
  };
  error?: string;
  latency?: number;
  video_duration_ms?: number;
};

export type AIShortsCreationStatusArg = Omit<
  AIShortsCreationStatusEventSchema,
  "video_duration_ms"
> & {
  video_duration_seconds?: number;
  shorts_operation_id?: string;
};

export const ai_shorts_creation_status = ({
  video_duration_seconds,
  cancel_stage,
  error,
  latency,
  ...rest
}: AIShortsCreationStatusArg): [string, AIShortsCreationStatusEventSchema] => {
  return [
    "ai_shorts_creation_status",
    {
      ...rest,
      ...pickKeysWithValues({
        cancel_stage,
        error,
        latency,
        video_duration_ms: video_duration_seconds
          ? secondsToMilliseconds(video_duration_seconds)
          : undefined,
      }),
    },
  ];
};

export type NewProjectCreatedEventSchema = {
  project_id: string;
  group_id?: string;
  project_type?: string;
  video_duration_ms: number;
  video_size?: number;
  aspect_ratio?: string; //landscape or portrait
  ai_edit_shots_count?: number;
  language?: string;
  translation_mode: string; // none, captions_only, autodub, lipdub
  ai_edit_style?: string;
  type_tags?: string[];
  ai_edit_sounds_count?: number;
  ai_edit_images_count?: number;
};

type NewProjectCreatedArgs = Omit<NewProjectCreatedEventSchema, "video_duration_ms"> & {
  video_duration_seconds: number;
};

export const new_project_created = ({
  video_duration_seconds,
  ...rest
}: NewProjectCreatedArgs): [string, NewProjectCreatedEventSchema, AnalyticsTool[]] => {
  return [
    "new_project_created",
    {
      ...rest,
      video_duration_ms: secondsToMilliseconds(video_duration_seconds),
    },
    analyticsTool.all,
  ];
};

export const project_exported = (): [string, Record<string, never>, AnalyticsTool[]] => {
  return ["project_exported", {}, ["iterable"]];
};

export type ExportTarget = "video" | "captions" | "link";

export type ExportStatusEventSchema = {
  ai_shorts_group_id?: string;
  attempt?: number;
  client_side_export: boolean;
  error: string | null;
  export_settings_fps?: number;
  eye_contact_use: boolean;
  has_watermark: boolean;
  latency: number | null;
  media_count: number;
  overlay_generation_parameters: {
    assets_count: {
      audio?: number;
      image?: number;
      video: number;
    };
    country_code: string;
    hide_captions?: boolean;
  };
  project_id: string;
  project_type: ProjectFolderType | ProjectType;
  reframe_aspect: Aspect;
  reframe_height: number;
  reframe_pan: boolean;
  reframe_scale: number;
  reframe_width: number;
  relative_latency: number | null;
  status: string;
  target?: ExportTarget;
  transitions: number;
  ugc_ads_group_id?: string;
  video_duration: number;
  video_fps: number;
  video_height: number;
  video_scale: Point2D;
  video_width: number;
};

type ExportStatusArgs = Omit<
  ExportStatusEventSchema,
  "media_count" | "eye_contact_use" | "project_type" | "project_id"
> & {
  media_count?: number;
  eye_contact_use?: boolean;
  project_type?: ProjectType | ProjectFolderType;
  project_id?: string;
};

export const export_status = (params: ExportStatusArgs): [string, ExportStatusEventSchema] => {
  return [
    "export_status",
    {
      ...params,
      media_count: params.media_count || 0,
      eye_contact_use: params.eye_contact_use || false,

      // KW: 'project_id' may be unexpectedly undefined during export.
      // This should never happen under normal conditions, but if it does,
      // it's likely a symptom of an underlying issue. Instead of preemptively
      // trying to resolve it, we will handle and debug these cases individually
      // in production as they arise, ensuring proper investigation and root cause analysis.
      project_id: params.project_id || "unset",
      project_type: getProjectTypeForAnalytics(params.project_type) as
        | ProjectType
        | ProjectFolderType,
    },
  ];
};

type TemplateClickEventSchema = {
  flow_type?: string;
  style_id: string;
  style_name?: string;
};

export const template_click = (
  params: TemplateClickEventSchema
): [string, TemplateClickEventSchema] => {
  return ["template_click", params];
};

export type ProjectOpenEventSchema = {
  project_type?: string;
  project_id: string;
  latency?: number;
};

export const project_open = (params: ProjectOpenEventSchema): [string, ProjectOpenEventSchema] => {
  return ["project_open", params];
};

/** Normalize */
const secondsToMilliseconds = (seconds: number): number => {
  return seconds * 1000;
};

const normalizePrice = (priceCents?: number): number => {
  return priceCents ? priceCents / 100 : 0;
};

const normalizeRenewalDate = (renewalDateMillis?: number | null): string => {
  if (!renewalDateMillis) {
    return "none";
  }

  const date = new Date(renewalDateMillis);
  return format(date, "yyyy-MM-dd");
};

const normalizeEntitlement = (entitlement: UserPlanSchema | null | undefined): string => {
  return entitlement ? entitlement.toLowerCase() : "none";
};

export class ProgressTracker {
  private thresholds: number[] = [0, 25, 50, 75];
  private called: boolean[] = [false, false, false, false];

  public updateProgress(progress: number) {
    let didUpdateProgress = false;

    this.thresholds.forEach((threshold, index) => {
      if (progress > threshold && !this.called[index]) {
        this.called[index] = true;
        didUpdateProgress = true;
      }
    });

    return didUpdateProgress;
  }
}
