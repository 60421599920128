import BreakCheckmark from "../icons/BreakCheckmark";

import { ComboBoxItemElement, INNER_ICON_CLASS, IconsContainer } from "./ComboBoxItem.styles";
import { ComboBoxItemProps } from "./ComboBoxItem.types";

export function ComboBoxItem({
  isSelected,
  option,
  size,
  onClickInnerAction,
  onSelect,
}: ComboBoxItemProps) {
  return (
    <ComboBoxItemElement
      size={size}
      value={option.value}
      onSelect={() => {
        onSelect?.(option.value);
      }}
    >
      {option.label}

      {(option.innerActionIcon || isSelected) && (
        <IconsContainer>
          {option.innerActionIcon && (
            <button
              className={INNER_ICON_CLASS}
              onClick={(e) => {
                e.stopPropagation();
                onClickInnerAction?.();
              }}
            >
              {option.innerActionIcon}
            </button>
          )}
          {isSelected && <BreakCheckmark />}
        </IconsContainer>
      )}
    </ComboBoxItemElement>
  );
}
