import { createCanvas, freeCanvas } from "canvas-utils";
import { DevLogger } from "dev-logger";
import type { PAGComposition } from "libpag/types/web/src/pag-composition";
import type { PAGFile } from "libpag/types/web/src/pag-file";
import { PAGImageLayer } from "libpag/types/web/src/pag-image-layer";
import type { PAGView } from "libpag/types/web/src/pag-view";
import { PAG } from "libpag/types/web/src/types";

import { MICROSECONDS_IN_SECOND } from "./constants/time.constants";
import { TransitionAnimation } from "./transitionLayer.types";
import { LAYER_TYPE_IMAGE, SCALE_MODE_STRETCH } from "./utils/pagUtils";

const logger = new DevLogger("[transition-renderer]");

export class TransitionLayer {
  private _pagInstance: PAG;
  private _canvas: HTMLCanvasElement | OffscreenCanvas | null = null;
  private _webglCanvas: HTMLCanvasElement | OffscreenCanvas | null;
  private _pagView: PAGView | null = null;
  private _pagComposition: PAGComposition | null = null;
  private _compositionObjects: PAGFile[] = [];
  private _onLoadAnimation: (animationId: string) => Promise<PAGFile | null>;
  private _inputCanvas: HTMLCanvasElement | OffscreenCanvas | null = null;
  private _imageLayers: PAGImageLayer[] = [];

  constructor(
    pagInstance: PAG,
    onLoadAnimation: (animationId: string) => Promise<PAGFile | null>,
    webglCanvas?: HTMLCanvasElement | OffscreenCanvas | null
  ) {
    this._pagInstance = pagInstance;
    this._onLoadAnimation = onLoadAnimation;
    this._webglCanvas = webglCanvas || null;
  }

  public drawInputFrame(...props: Parameters<CanvasRenderingContext2D["drawImage"]>) {
    if (!this._inputCanvas) {
      this._inputCanvas = createCanvas(this._canvas?.width ?? 1, this._canvas?.height ?? 1);
    }
    const ctx = this._inputCanvas.getContext("2d") as CanvasRenderingContext2D;
    if (ctx) {
      ctx.drawImage(...props);
    }
  }

  public async setup(width: number, height: number, transition: TransitionAnimation) {
    this._pagView?.destroy();
    this._pagView = null;
    if (this._canvas && !this._webglCanvas) {
      freeCanvas(this._canvas);
    }

    // Init canvas
    this._canvas = this._webglCanvas || createCanvas(width, height);

    // Init PAG composition
    await this._generateComposition(width, height, transition);
    if (!this._pagComposition) {
      return;
    }

    // Init PAG view
    const pagView = await this._pagInstance.PAGView.init(this._pagComposition, this._canvas, {
      useCanvas2D: !this._webglCanvas,
      useScale: false,
    });
    this._pagView = pagView ?? null;
  }

  private async _generateComposition(
    width: number,
    height: number,
    animation: TransitionAnimation
  ) {
    this._pagComposition?.destroy();
    this._compositionObjects.forEach((layer) => layer.destroy());
    this._pagComposition = null;
    this._compositionObjects = [];
    this._pagComposition = this._pagInstance.PAGComposition.make(width, height);

    const pagFile = await this._onLoadAnimation(animation.config.id);
    if (!pagFile) {
      logger.error("Failed to load animation", animation.config.id);
      return;
    }

    // Config metadata sanity check
    const configDuration = animation.config.duration;
    const duration = pagFile.duration() / MICROSECONDS_IN_SECOND;
    logger.assert(
      Math.abs(duration - configDuration) < 0.01,
      `Expected duration ${configDuration}s but got ${duration}s for animation id "${animation.config.id}"`
    );

    const layer = pagFile.copyOriginal();
    layer.setProgress(0);
    layer.setCurrentTime(0);

    // Stretch to fit
    const scaleX = width / layer.width();
    const scaleY = height / layer.height();
    const matrix = this._pagInstance.Matrix.makeScale(scaleX, scaleY);
    layer.setMatrix(matrix);
    matrix.destroy();

    this._imageLayers = [];
    for (let i = 0; i < layer.numChildren(); i++) {
      const childLayer = layer.getLayerAt(i);
      if (childLayer.layerType() === LAYER_TYPE_IMAGE) {
        this._imageLayers.push(childLayer as PAGImageLayer);
      }
    }

    this._pagComposition?.addLayer(layer);
    this._compositionObjects.push(layer);
  }

  public async getFrame(timestamp: number) {
    const view = this._pagView;
    const canvas = this._canvas;
    const composition = this._pagComposition;
    const numLayers = composition?.numChildren() ?? 0;
    const ready = view && canvas && composition && numLayers > 0;
    if (!ready) {
      return null;
    }

    if (this._inputCanvas && this._compositionObjects.length > 0) {
      const image = this._pagInstance.PAGImage.fromSource(this._inputCanvas);
      image.setScaleMode(SCALE_MODE_STRETCH);
      this._compositionObjects.forEach((layer) => {
        for (let i = 0; i < layer.numImages(); i++) {
          layer.replaceImage(i, image);
        }
      });
    }

    // Synchronize timestamp
    const progress = (timestamp * MICROSECONDS_IN_SECOND) / view.duration();
    view.setProgress(progress);

    // Draws the emoji frame
    await view.flush();
    return canvas;
  }

  public destroy() {
    this._pagComposition?.destroy();
    this._compositionObjects.forEach((layer) => layer.destroy());
    this._pagView?.destroy();
    this._canvas = null;
    this._pagComposition = null;
    this._compositionObjects = [];
    this._pagView = null;
  }
}
