import { createAssetMap } from "../../../utils/assetUtils";

export const PAG_SEQUENCE_RECESS_ASSETS = createAssetMap({
  folder: "ai-edit-assets/recess_assets/frame_borders",
  assets: {
    // bottom half
    simple_thbr_frame_2_DURATION: {
      type: "tar-frames",
      variants: [""],
    },
    simple_thbr_frame_2_IN: {
      type: "tar-frames",
      variants: [""],
    },
    simple_thbr_frame_2_OUT: {
      type: "tar-frames",
      variants: [""],
    },
    // top half
    simple_thbr_frame_1_DURATION: {
      type: "tar-frames",
      variants: [""],
    },
    simple_thbr_frame_1_IN: {
      type: "tar-frames",
      variants: [""],
    },
    simple_thbr_frame_1_OUT: {
      type: "tar-frames",
      variants: [""],
    },
  },
});
