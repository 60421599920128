import { MICROSECONDS_IN_SECOND } from "./constants/time.constants";
import { OverlayEffect } from "./overlayEffect.types";
import { VideoLoader } from "./utils/videoAsset";
import { updateTexture, updateTransparentTexture } from "./utils/webgl";
import {
  blendModeNumbers,
  BlendProgramData,
  drawBlendOverlay,
  initBlend,
} from "./utils/webgl/blendOverlayProgram";

export class OverlayEffectPainter {
  private _effects: OverlayEffect[] = [];
  private _timestamp: number = 0;
  private _currentEffects: OverlayEffect[] = [];
  private _program: BlendProgramData | null = null;
  private _loadedVideos: Map<Blob, VideoLoader> = new Map();

  /**
   * Updates the loaded videos to match the current effects and ensure they are all loaded before
   * resolving. Will reuse existing video loaders where possible.
   * @private
   */
  private async _updateLoadedVideos() {
    // Free up any video loaders that are no longer needed
    this._loadedVideos.forEach((videoLoader, blob, map) => {
      if (
        !this._effects.some((effect) => effect.asset.type === "video" && effect.asset.data === blob)
      ) {
        videoLoader.destroy();
        map.delete(blob);
      }
    });
    // Create video loaders for any new videos
    const loadersToWaitFor: Promise<void>[] = [];
    for (const effect of this._effects) {
      if (effect.asset.type === "video") {
        if (!this._loadedVideos.has(effect.asset.data)) {
          const videoLoader = new VideoLoader(effect.asset.data, Boolean(effect.loop));
          loadersToWaitFor.push(videoLoader.flush());
          this._loadedVideos.set(effect.asset.data, videoLoader);
        }
      }
    }
    // Waits for the new video loaders to finish loading
    await Promise.all(loadersToWaitFor);
  }

  private _updateVideoTimestamps() {
    for (const effect of this._currentEffects) {
      if (effect.asset.type === "video") {
        const videoLoader = this._loadedVideos.get(effect.asset.data);
        if (videoLoader) {
          videoLoader.currentTime = this._timestamp - effect.startTime;
        }
      }
    }
  }

  async setEffects(effects: OverlayEffect[]) {
    this._effects = effects;
    this._currentEffects = this._effects.filter(
      (effect) => effect.startTime <= this._timestamp && effect.endTime > this._timestamp
    );
    await this._updateLoadedVideos();
    this._updateVideoTimestamps();
  }

  setTimestamp(timestamp: number) {
    this._timestamp = timestamp;
    this._currentEffects = this._effects.filter(
      (effect) => effect.startTime <= this._timestamp && effect.endTime > this._timestamp
    );
    this._updateVideoTimestamps();
  }

  /**
   * Ensures that any video overlay effects are ready to be drawn.
   */
  async flush() {
    for (const effect of this._currentEffects) {
      if (effect.asset.type === "video") {
        const videoLoader = this._loadedVideos.get(effect.asset.data);
        await videoLoader?.flush();
      }
    }
  }

  setupContext(ctx: WebGLRenderingContext | null) {
    if (ctx) {
      this._program = initBlend(ctx);
    } else {
      this._program = null;
    }
  }

  /**
   * Gets the texture for the asset of the given effect. Will use the given image bitmap for image
   * assets and get the current frame from the video loader for video effects.
   * @param effect - The effect to get the texture for.
   * @private
   */
  private _getAssetTexture(effect: OverlayEffect): TexImageSource | null {
    if (effect.asset.type === "image") {
      return effect.asset.data;
    } else if (effect.asset.type === "video") {
      const videoLoader = this._loadedVideos.get(effect.asset.data);
      if (videoLoader) {
        return videoLoader.videoImage;
      }
    } else if (effect.asset.type === "unpacked-video") {
      // Calculates the local timestamp for the video asset
      let localTimestamp = (this._timestamp - effect.startTime) * MICROSECONDS_IN_SECOND;
      if (localTimestamp >= effect.asset.data.duration && !effect.loop) {
        return null;
      }
      // Loops the timestamp if the effect is set to loop
      localTimestamp = localTimestamp % effect.asset.data.duration;
      const currentFrame =
        effect.asset.data.frames.findLast((frame) => frame.timestamp <= localTimestamp) ??
        effect.asset.data.frames.at(-1);
      if (currentFrame) {
        return currentFrame.image;
      }
    }
    return null;
  }

  /**
   * Renders the active overlay effects to the canvas.
   * @param ctx
   */
  paint(ctx: WebGLRenderingContext) {
    if (!this._program) {
      return;
    }
    for (const effect of this._currentEffects) {
      // Paint the effect
      const assetImage = this._getAssetTexture(effect);
      if (!assetImage) {
        continue;
      }
      updateTexture(ctx, this._program.textures.backdropTexture, ctx.canvas);
      updateTransparentTexture(ctx, this._program.textures.sourceTexture, assetImage);
      drawBlendOverlay(ctx, blendModeNumbers[effect.blendMode], this._program);
    }
  }

  destroy() {
    this._loadedVideos.forEach((videoLoader) => videoLoader.destroy());
    this._loadedVideos.clear();
  }
}
