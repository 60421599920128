import { HttpResponse, http } from "msw";

import audioExtractionResponse from "./audioExtractionResponse.mock.json";

export const audioHandlers = [
  http.post("*/audio/v1/extraction", () => {
    return HttpResponse.json({
      jobId: "d057aa8a-5263-43f8-aab2-2fba448bb9f2",
      errorMessage: null,
      progress: null,
      status: "pending",
    });
  }),

  http.get("*/audio/v1/extraction/*/status", () => {
    return HttpResponse.json(audioExtractionResponse);
  }),
];
