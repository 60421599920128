import { ZodError } from "zod";

import { BackendServicesClient } from "~/context/BackendServicesContext";

import {
  GetVideoOverlayJobResponse,
  getVideoOverlayJobResponseSchema,
  StartOverlayJobRequest,
  StartOverlayJobResponse,
  startOverlayJobResponseSchema,
} from "./VideoOverlayJob.dto";

const OVERLAY_JOB_URL = "/video/v1/video-export";

export async function getVideoOverlayJobStatus(
  client: BackendServicesClient,
  videoOverlayJobId: string,
  signal?: AbortSignal
): Promise<GetVideoOverlayJobResponse> {
  try {
    const result = await client.get(`${OVERLAY_JOB_URL}/${videoOverlayJobId}/status`, { signal });
    return getVideoOverlayJobResponseSchema.parse(result.data);
  } catch (error) {
    console.error(error);
    if (error instanceof ZodError) {
      throw new Error("Invalid response received from the server!");
    } else {
      throw error;
    }
  }
}

export async function startVideoOverlayJob(
  client: BackendServicesClient,
  videoOverlayJob: StartOverlayJobRequest
): Promise<StartOverlayJobResponse> {
  try {
    const result = await client.post(OVERLAY_JOB_URL, videoOverlayJob);
    return startOverlayJobResponseSchema.parse(result.data);
  } catch (error) {
    console.error(error);
    if (error instanceof ZodError) {
      throw new Error("Invalid response received from the server!");
    } else {
      throw error;
    }
  }
}
