import { PseudoRandomGenerator } from "stable-pseudo-rng";

import { shuffleArrayPrng, removeArrayItemPrng } from "~/utils";

import type {
  AISceneData,
  AISceneDataWithStyles,
  AISceneDescription,
  AISceneWithStyle,
} from "../AIScenes.types";
import type {
  ClientDrivenAIEditVideoStyle,
  AISceneStyle,
  SceneStyleConfig,
  SceneStyleVariant,
} from "../services/AIEditStyles";
import type { CollageRequest } from "../services/Collages";

export function getCollageRequestForSceneStyle(
  s: AISceneWithStyle,
  sceneIndex: number
): CollageRequest | null {
  const { imageRules } = s.style;

  if (!imageRules) {
    return null;
  }

  const numImagesMethod = imageRules.numImages;

  let numImages = 0;
  if (typeof numImagesMethod === "number") {
    numImages = numImagesMethod;
  }
  if (numImagesMethod === "duration-floor") {
    numImages = Math.floor(s.endTime - s.startTime);
  }
  if (numImagesMethod === "duration-half") {
    numImages = Math.floor((s.endTime - s.startTime) / 2);
  }

  return {
    phrase: s.words.map((w) => w.word).join(" "),
    phraseWords: s.words,
    numImages,
    sceneStyle: imageRules!.style,
    sceneIndex,
  };
}

function initializeStyleLists(sceneStyleConfig: SceneStyleConfig) {
  const availableVariants = new Map<string, SceneStyleVariant<unknown>[]>();
  const imageStyles: string[] = [];
  const normalStyles: string[] = [];

  Object.entries(sceneStyleConfig).forEach(([styleId, { variants, isImageStyle }]) => {
    if (variants) {
      availableVariants.set(styleId, [...variants]);
      // Add the style to the weighted list based on its number of variants
      const weightedStyle = Array(variants.length).fill(styleId);
      (isImageStyle ? imageStyles : normalStyles).push(...weightedStyle);
    } else {
      (isImageStyle ? imageStyles : normalStyles).push(styleId);
    }
  });

  return { availableVariants, imageStyles, normalStyles };
}

/*
 * Determine which scenes should be collage scenes
 * Basic initial rules:
 * do not pick first scene: i > 0
 * do not pick adjacent scenes: indices.at(-1) !== i - 1
 * must be a keyPhrase: keyPhrases.includes(i)
 */
function determineCollageScenes(scenes: AISceneData["scenes"], keyPhrases: number[]): number[] {
  return scenes.reduce((indices, _, i) => {
    if (i > 0 && indices.at(-1) !== i - 1 && keyPhrases.includes(i)) {
      indices.push(i);
    }
    return indices;
  }, [] as number[]);
}

function selectStyleForScene(styleList: string[], usedStyles: Set<string>) {
  const style = styleList.find((style) => !usedStyles.has(style));

  if (!style) {
    usedStyles.clear();
    usedStyles.add(styleList[0]);
    return styleList[0];
  }

  usedStyles.add(style);
  return style;
}

function applyStyleToScene(
  chosenStyleId: string,
  sceneStyleConfig: SceneStyleConfig,
  availableVariants: Map<string, SceneStyleVariant<unknown>[]>,
  prng: PseudoRandomGenerator
): AISceneStyle {
  const styleConfig = sceneStyleConfig[chosenStyleId];
  let variants = availableVariants.get(chosenStyleId) ?? [];
  if (!variants.length) {
    variants = [...(styleConfig.variants ?? [])];
  }

  const variant = removeArrayItemPrng(variants, prng);
  availableVariants.set(chosenStyleId, variants);

  return styleConfig.builder(prng, variant);
}

/**
 * Determines the style for each scene and constructs the network request for getting collage images
 */
export function populateSceneStyles(
  sceneData: AISceneData,
  style: ClientDrivenAIEditVideoStyle
): {
  sceneDataWithStyles: AISceneDataWithStyles;
  collageRequests: CollageRequest[];
  sceneDescriptions: AISceneDescription[];
} {
  const { scenes, keyPhrases } = sceneData;
  // each scene gets its own prng with its own seed
  const prngs = scenes.map(() => new PseudoRandomGenerator());

  const sceneStyleConfig = style.sceneStyleConfig;
  const defaultStyle = Object.keys(sceneStyleConfig)[0];

  const { availableVariants, imageStyles, normalStyles } = initializeStyleLists(sceneStyleConfig);
  const collageSceneIndices = determineCollageScenes(scenes, keyPhrases);

  const usedNormalStyles = new Set<string>();
  const usedImageStyles = new Set<string>();
  const scenesWithStyle = scenes.map((scene, index) => {
    const prng = prngs[index];
    let styleId = defaultStyle;
    if (index === 0) {
      const usedStyles = imageStyles.includes(defaultStyle) ? usedImageStyles : usedNormalStyles;
      usedStyles.add(defaultStyle);
    } else if (collageSceneIndices.includes(index) && imageStyles.length) {
      shuffleArrayPrng(imageStyles, prng);
      styleId = selectStyleForScene(imageStyles, usedImageStyles);
    } else {
      shuffleArrayPrng(normalStyles, prng);
      styleId = selectStyleForScene(normalStyles, usedNormalStyles);
    }

    const newStyle = applyStyleToScene(styleId, sceneStyleConfig, availableVariants, prng);

    if (newStyle.isOnlyUseOnce) {
      [normalStyles, imageStyles].forEach((styles) => {
        const styleIndex = styles.indexOf(newStyle.id);
        if (styleIndex !== -1) {
          styles.splice(styleIndex, 1);
        }
      });
    }

    return {
      ...scene,
      style: newStyle,
      mainOffset: scenes.slice(0, index).reduce((acc, s) => acc + (s.endTime - s.startTime), 0),
    };
  });

  // generate collage backend request params
  const collageRequests = scenesWithStyle
    .map(getCollageRequestForSceneStyle)
    .filter(Boolean) as CollageRequest[];

  const sceneDescriptions: AISceneDescription[] = scenesWithStyle.map((s, i) => {
    return {
      sceneStyle: s.style.id,
      timeSpan: {
        startTime: s.startTime,
        endTime: s.endTime,
      },
      prng: prngs[i].serialize(),
    };
  });

  return {
    sceneDataWithStyles: {
      scenes: scenesWithStyle,
    },
    collageRequests,
    sceneDescriptions,
  };
}
