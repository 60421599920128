import { styled } from "~/theme";

export const SearchBoxInput = styled("input", {
  all: "unset",
  fontSize: "$15",
  lineHeight: "$24",
  fontWeight: "$medium",
  width: "100%",
  color: "$grey-100",
  textOverflow: "ellipsis",
  paddingTop: "1px",
});

export const SearchBoxContainer = styled("div", {
  borderBottom: "1px solid $grey-800",
  padding: "$16",
  width: "100%",
  height: "56px",
  display: "flex",
  gap: "$12",
  alignItems: "center",

  cursor: "text",
  color: "$grey-600",
  "&:hover, &:focus, &:focus-within, &:active": {
    color: "$grey-200",
    backgroundColor: "$grey-800",
  },
  variants: {
    disabled: {
      true: {
        cursor: "not-allowed",
        "&:hover, &:focus, &:focus-within, &:active": {
          backgroundColor: "transparent",
          "& svg": {
            color: "$grey-600",
          },
        },
      },
    },
    hasText: {
      true: {
        color: "$grey-200",
        backgroundColor: "$grey-800",
      },
    },
  },
  "input::-webkit-search-cancel-button": {
    display: "none",
  },
});
