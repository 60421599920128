import { styled } from "~/theme";

import { IconProps } from "./types";

function Translate(props: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.5674 11V11.8558"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 12.2837H19.1346"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2837 12.7116C15.2837 14.851 16.5673 15.7067 18.7068 16.5625"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8511 12.7116C17.8511 14.851 16.5674 15.7067 14.428 16.5625"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.757 14.5641L18.4231 19.6987"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7571 14.5641L23.0911 19.6987"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3567 18.2984H22.1574"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 20H9.5H5C3.34315 20 2 18.6569 2 17V13M22 9V7C22 5.34315 20.6569 4 19 4H15.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.79487 4C2.80359 4 2 4.80359 2 5.79487V7.84616C2 8.83744 2.80359 9.64104 3.79487 9.64104H5.33334C5.75817 9.64104 6.10257 9.29664 6.10257 8.8718C6.10257 8.44697 5.75817 8.10257 5.33334 8.10257H3.79487C3.65326 8.10257 3.53846 7.98777 3.53846 7.84616V5.79487C3.53846 5.65326 3.65326 5.53846 3.79487 5.53846H5.33334C5.75817 5.53846 6.10257 5.19407 6.10257 4.76923C6.10257 4.3444 5.75817 4 5.33334 4H3.79487ZM9.43597 4C8.44469 4 7.64109 4.80359 7.64109 5.79487V7.84616C7.64109 8.83744 8.44469 9.64104 9.43597 9.64104H10.9744C11.3993 9.64104 11.7437 9.29664 11.7437 8.8718C11.7437 8.44697 11.3993 8.10257 10.9744 8.10257H9.43597C9.29436 8.10257 9.17956 7.98777 9.17956 7.84616V5.79487C9.17956 5.65326 9.29436 5.53846 9.43597 5.53846H10.9744C11.3993 5.53846 11.7437 5.19407 11.7437 4.76923C11.7437 4.3444 11.3993 4 10.9744 4H9.43597Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default styled(Translate, {
  width: 24,
  height: 24,
  color: "$grey-200",
});
