import { createAssetMap } from "../../../utils/assetUtils";

export const SCRAPBOOK_RANSOM_FONT = createAssetMap({
  folder: "ai-edit-assets/scrapbook_assets/images",
  assets: {
    ransom_font_sprite: {
      type: "image",
      extension: "png",
      variants: ["1", "2", "3", "4", "5"],
    },
  },
});
