import React, { useEffect, useState } from "react";

import { LOGIN_STATUS, LOGIN_STATUS_PROVIDER, STATUS_FAIL } from "~/constants/mixpanel.constants";
import { useBackendServicesClient } from "~/context/BackendServicesContext";
import { useAnalytics } from "~/hooks/useAnalytics";
import { getSupportedCountries, sendVerificationCode } from "~/services/Auth";
import { isNA, NAPhoneNumberMask, regexPhoneNumber } from "~/utils/glob";

import { Button } from "../Button";
import { ComboBox } from "../ComboBox";
import { LoginStep } from "../SignIn/SignIn.types";
import { Spinner } from "../Spinner";
import { Text } from "../Text";
import { useToast } from "../Toast";

import * as S from "./SignInByPhone.styles";
import {
  Country,
  CountryOption,
  EnterPhoneNumberProps,
  ErrorCode,
  ErrorCodeMessages,
} from "./SignInByPhone.types";

export default function EnterPhoneNumber({
  setStep,
  phoneNumber,
  setPhoneNumber,
  selectedCode,
  setSelectedCode,
  selectedRegion,
  setSelectedRegion,
}: EnterPhoneNumberProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [supportedCountries, setSupportedCountries] = useState<Array<CountryOption>>([]);
  const [isError, setIsError] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState<CountryOption>();
  const toast = useToast();
  const client = useBackendServicesClient();
  const analytics = useAnalytics();

  const handleError = (error?: string) => {
    const defaultMessage = "Phone signup unavailable. Please use an alternative sign up method";
    toast.add(
      (error ? ErrorCodeMessages?.[error as ErrorCode] : defaultMessage) || defaultMessage,
      {
        severity: "error",
        action: {
          name: "Close",
          fn: () => {
            setStep?.(LoginStep.Initial);
          },
        },
      }
    );
    setIsError(true);
    analytics.track(LOGIN_STATUS, {
      provider: LOGIN_STATUS_PROVIDER.credentials,
      status: STATUS_FAIL,
      error: error || defaultMessage,
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const verificationCode = await sendVerificationCode(client, {
      region: selectedRegion,
      phoneNumber: regexPhoneNumber(phoneNumber),
    });

    setIsLoading(false);

    if (verificationCode?.success === false) {
      handleError(verificationCode?.error || verificationCode?.reason);
    } else {
      setStep?.(LoginStep.EnterCode);
    }
  };

  const handleSupportedCountries = async () => {
    const supportedCountriesResponse = await getSupportedCountries(client);

    const { countries } = supportedCountriesResponse?.data ?? {};

    if (countries) {
      const listOfCountries: Array<Country> = Object.values(countries);

      const mappedCountires = listOfCountries.map(({ name, callingCode, regionCode }) => ({
        value: `${regionCode} +${callingCode}`,
        searchValue: `${name} ${callingCode}`,
        regionCode: regionCode,
        label: (
          <S.Label>
            {name} <S.LabelCode>+{callingCode}</S.LabelCode>
          </S.Label>
        ),
      }));

      setSupportedCountries(mappedCountires);
    }
  };

  const getCustomValue = (value: string) => value;
  const getRegion = (value: string) => value.split(" +")?.[0] ?? "";

  const selectCodeAndRegion = (value: string) => {
    const [_, code] = value.split(" ");
    setSelectedCode(code);
    setSelectedRegion(getRegion(value));
  };

  const getDeviceDefaultCountry = () => {
    const { language } = window.navigator;

    if (supportedCountries.length && language) {
      const country = supportedCountries.find((supportedCountry) =>
        supportedCountry.regionCode.toLocaleLowerCase().includes(language.toLocaleLowerCase())
      );

      const us = supportedCountries.find((supportedCountry) =>
        supportedCountry.value.includes("US")
      );

      if (country) {
        setDefaultCountry(country);
      } else if (us) {
        selectCodeAndRegion(us.value);
      }
    }
  };

  useEffect(() => {
    handleSupportedCountries();
  }, []);

  useEffect(() => {
    getDeviceDefaultCountry();
  }, [supportedCountries]);

  useEffect(() => {
    if (defaultCountry) {
      selectCodeAndRegion(defaultCountry.value);
    }
  }, [defaultCountry]);

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault(); // Prevent default form submission behavior
          handleSubmit(); // Call your existing submit handler
        }}
      >
        <S.Top>
          <S.IconWrapper>☎️</S.IconWrapper>
          <S.InfoWrapper>
            <Text variant="heading-3" color="white">
              Log in with phone number
            </Text>
            <Text variant="body-1" color="grey-500">
              Only for existing Captions mobile users. We will send a code for verification via
              text.
            </Text>
          </S.InfoWrapper>
        </S.Top>
        <S.Bottom>
          <S.BottomWrapper>
            <ComboBox
              onValueChange={(value) => {
                const purePhoneNumber = regexPhoneNumber(phoneNumber);
                const region = getRegion(value);
                selectCodeAndRegion(value);
                if (isNA(region)) {
                  const updatedPhoneNumber = NAPhoneNumberMask(purePhoneNumber);
                  setPhoneNumber(updatedPhoneNumber, false, region);
                } else {
                  setPhoneNumber(purePhoneNumber, false, region);
                }
              }}
              options={[
                {
                  label: "DEVICE DEFAULT",
                  options: defaultCountry ? [defaultCountry] : [],
                },
                {
                  label: "ALL COUNTRIES",
                  options: supportedCountries,
                },
              ]}
              searchPlaceholder="Search country code..."
              value={`${selectedRegion} ${selectedCode}`}
              customValue={(value) => getCustomValue(value ?? "")}
              content={{
                align: "start",
                width: 294,
              }}
            />
            <S.Input
              isError={isError}
              value={phoneNumber}
              onChange={(event) => {
                const { inputType } = event.nativeEvent as InputEvent;
                setPhoneNumber(event.target.value, inputType === "deleteContentBackward");
              }}
            />
          </S.BottomWrapper>

          <Button
            variant="primary"
            fullWidth
            type="submit" // Ensure this button triggers form submission
            disabled={!phoneNumber || !selectedCode}
          >
            {isLoading ? <Spinner theme="white" /> : "Send Code"}
          </Button>
          <Button
            variant="tertiary"
            fullWidth
            onClick={() => {
              setStep?.(LoginStep.Initial);
            }}
          >
            Go back
          </Button>
        </S.Bottom>
      </form>
    </>
  );
}
