import type { TransitionAnimation } from "captions-engine";
import { nanoid } from "nanoid";

import { Transition } from "~/database/transition.types";
import type { Clip } from "~/utils/videoClips";

import { TransitionAnimationGroups } from "../services/PagAnimations/TransitionAnimations.config";
import { TransitionAnimationGroupId } from "../services/PagAnimations/TransitionAnimations.config";

const error = console.error.bind(console, "[transitions]");

/** Mapping from a `Transition` to a `TransitionAnimation`, if applicable */
export function getTransitionAnimation(
  transition: Transition,
  aspectRatio: number
): TransitionAnimation | undefined {
  if (transition.effect.type !== "animation") {
    return undefined;
  }

  // Handle result of division by zero in aspect ratio (Infinity or NaN)
  if (!isFinite(aspectRatio)) {
    return undefined;
  }

  // Get all aspect ratio variants
  const animationGroupId = transition.effect.animationId;
  const animationGroup = TransitionAnimationGroups[animationGroupId];
  if (!animationGroup) {
    error(`No animation group found for "${animationGroupId}"`);
    return undefined;
  }

  // Find the closest one
  const closestAnimation = animationGroup.reduce((closest, animation) => {
    const closestAspectRatio = closest.aspectRatio;
    const closestDiff = Math.abs(aspectRatio - closestAspectRatio);
    const animationDiff = Math.abs(aspectRatio - animation.aspectRatio);
    return animationDiff < closestDiff ? animation : closest;
  });
  if (!closestAnimation) {
    error(`No animation variant for "${animationGroupId}" (aspect ratio=${aspectRatio})`);
    return undefined;
  }

  // Center the animation on the transition timestamp
  const startTime = transition.startTime - closestAnimation.duration / 2;

  return { startTime, config: closestAnimation };
}

export function getTransitionAnimations(
  transitions: Transition[],
  aspectRatio: number
): TransitionAnimation[] {
  return transitions.flatMap((transition) => {
    const animation = getTransitionAnimation(transition, aspectRatio);
    return animation ? [animation] : [];
  });
}

export function getTransitionDuration(transitionAnimationId: TransitionAnimationGroupId) {
  const animationGroup = TransitionAnimationGroups[transitionAnimationId];

  if (!animationGroup) {
    error(`No animation group found for "${transitionAnimationId}"`);
    return undefined;
  }

  return animationGroup[0].duration;
}

/** Shows hardcoded animations for every transition */
export function getMockTransitionAnimations(
  transitions: Transition[],
  aspectRatio: number
): TransitionAnimation[] {
  const mockTransitions = transitions.map(
    ({ startTime }): Transition => ({
      id: nanoid(),
      startTime,
      effect: {
        type: "animation",
        animationId: "square",
      },
    })
  );
  const mockAnimations = getTransitionAnimations(mockTransitions, aspectRatio);
  return mockAnimations;
}

/** Flatten an array of transitions to just the attached effect IDs for analytics */
export function getEffectIds(transitions: Transition[]): string[] {
  return transitions.flatMap(({ effect }) => {
    if (effect.type === "animation") {
      return [effect.animationId];
    } else {
      return [];
    }
  });
}

export function isInsideDeletedClip(transition: Transition, clips: Clip[]): boolean {
  return clips
    .filter(({ deleted }) => !!deleted)
    .some((clip) => clip.startTime <= transition.startTime && clip.endTime > transition.startTime);
}

/** Include all transitions from both set `a` and `b`, without duplicates. */
export function combineTransitions(a: Transition[], b: Transition[]): Transition[] {
  // Transitions are indexed by `startTime` (decimal seconds).
  // this constant gives a little wiggle room for potential rounding errors
  const MERGE_TRANSITION_THRESHOLD = 0.01;

  const uniquesFromB = b.filter((transitionB) => {
    return !a.some((transitionA) => {
      const diff = Math.abs(transitionA.startTime - transitionB.startTime);
      return diff < MERGE_TRANSITION_THRESHOLD;
    });
  });

  const combined = a.concat(uniquesFromB);
  combined.sort((a, b) => a.startTime - b.startTime);

  return combined;
}
