import { AuthProvider } from "~/context/CaptionsAuthContext";

// FUNNEL STEPS
export const LOGIN_VIEW = "login_view";
export const SESSION_START = "session_start";

// login_click
export const PROJECTS_LIST_VIEW = "projects_list_view";
export const FILE_UPLOAD_STATUS = "file_upload_status";
export const FILE_UPLOAD_CLICK = "file_upload_click";
export const PROJECT_VIEW = "project_view";
export const PROJECT_TRANSCRIPT_STATUS = "project_transcript_status";
export const PROJECT_CREATION_STATUS = "project_creation_status";
export const PROJECT_DELETE_CLICK = "project_delete_click";
export const AI_SHORTS_FOLDER_DELETE_CLICK = "ai_shorts_folder_delete_click";
export const UGC_ADS_FOLDER_DELETE = "ugc_ads_folder_delete";
// export_click
export const UPGRADE_FROM_PROFILE = "upgrade_from_profile";

// POST CONVERSION
export const EXPORT_START = "export_start";
export const EXPORT_RETRY = "export_retry";
export const EXPORT_STATUS = "export_status";
export const EXPORT_SRT_STATUS = "export_srt_status";
export const DOWNLOADED_VIDEO = "downloaded_video";
export const DOWNLOAD_SRT_CLICK = "download_srt_click";

// REFRAME
export const REFRAME_ASPECT_CLICK = "reframe_aspect_click";
export const REFRAME_FILL_FIT_CLICK = "reframe_fill_fit_click";
export const REFRAME_FILL_FIT_CLICK_TYPE = {
  fill: "fill",
  fit: "fit",
};
export const REFRAME_FILL_FIT_CLICK_ASPECT_TYPE = {
  horizontal: "horizontal",
  vertical: "vertical",
  square: "square",
};
export const REFRAME_PAN_DRAG = "reframe_pan_drag";

// OFFICIAL MARKETING EVENTS:
export const EXPORT_CLICK = "export_click";
export const EXPORT_CLICK_PROJECT_TYPES = {
  ugcAd: "ugc_ad",
  aiShort: "ai_short",
  import: "import",
  aiCreator: "ai_creator",
  aiEdit: "ai_edit",
};

export const EXPORT_CLICK_SOURCES = {
  editor: "editor",
  projectPreview: "project_preview",
  resultList: "result_list",
};
export const LOGIN_CLICK = "login_click";
export const LOGIN_SUCCESS = "login_success";
export const LOGIN_STATUS = "login_status";
export const LOGIN_STATUS_PROVIDER: Record<AuthProvider, string> = {
  apple: "apple",
  google: "google",
  "azure-ad": "microsoft",
  microsoft: "microsoft",
  credentials: "phone",
};
export const DELETE_WORD_CLICK = "delete_word_click";
export const WORD_EDITED = "word_edited";
export const WORD_DRAG = "word_drag";
export const WORD_DRAG_ERROR = "word_drag_error";
export const WORD_SELECT_CLICK = "word_select_click";
export const STYLE_UPDATE = "style_update";
export const EYE_CONTACT_UNAPPLIED = "eye_contact_unapplied";
export const EYE_CONTACT_RETRY = "eye_contact_retry";
export const CUSTOM_FONT_UPLOAD = "custom_font_upload";
export const CUSTOM_FONT_UPLOAD_ERROR = "custom_font_upload_error";
export const CUSTOM_FONT_UPLOAD_CANCEL = "custom_font_upload_cancel";
export const CUSTOM_FONTS_LOADED = "custom_fonts_loaded";
export const CUSTOM_FONT_DELETE = "custom_font_delete";
export const CUSTOM_FONT_READING_ERROR = "custom_font_reading_error";
export const DENOISE_AUDIO_CLICK = "denoise_audio_click";
export const TRANSITION_CLICK = "transition_click";
export const TRANSITION_APPLY = "transition_apply";
export const IMAGE_BROWSE_CLICK = "image_browse_click";
export const IMAGE_REMOVE_CLICK = "image_remove_click";
export const IMAGE_ADD_ERROR = "image_add_error";
export const SOUNDS_MENU_ITEM_CLICK = "sounds_menu_item_click";
export const SOUNDS_MENU_CLEAR_CLICK = "sounds_menu_clear_click";
export const TIMELINE_MODE_CLICK = "timeline_mode_click";
export const PHRASE_SELECT_CLICK = "phrase_select_click";
export const PHRASE_EDIT_CLICK = "phrase_edit_click";
export const PHRASE_ALIGN = "phrase_align";
export const DELETE_PHRASE_CLICK = "delete_phrase_click";
export const SPLIT_PHRASE_CLICK = "split_phrase_click";
export const TOOLBAR_CLICK = "toolbar_click";
export const TOOLBAR_CLICK_ACTIONS = {
  denoise: "denoise",
  eyeContact: "eye_contact",
  templates: "templates",
};
export const EYE_CONTACT_APPLY_CLICK = "eye_contact_apply_click";
export const EYE_CONTACT_APPLY = "eye_contact_apply";
export const EYE_CONTACT_APPLY_STATUS = {
  success: "success",
  fail: "fail",
  cancel: "cancel",
};
export const MERGE_SHOTS_CLICK = "merge_shots_click";
export const PROJECT_OPEN = "project_open";
export const CAPTIONS_HIDE_ALL = "captions_hide_all";
export const CAPTIONS_HIDE_WORD = "captions_hide_word";
export const PROCEED_WITH_CLICK = "proceed_with_click";
export const EDITOR_WORD_MENU_CLICK = "editor_word_menu_click";
export const EDITOR_WORD_MENU_ACTIONS = {
  addWord: "add_word",
  editWord: "edit_word",
  emphasize: "emphasize",
  supersize: "supersize",
  break: "break",
  sound: "sound",
  image: "image",
  emoji: "emoji",
  delete: "delete",
};
export const MEDIA_ADD_STATUS = "media_add_status";
export const INTERCOM_OPEN = "intercom_open";

// IN THE FUTURE/CURRENTLY UNUSED:
export const PROCEED_WITH_MAGIC_CLICK = "proceed_with_magic_click";
export const PROCEED_WITH_MAGIC_CANCEL = "proceed_with_magic_cancel";
export const PHRASE_EDIT = "phrase_edit";
export const PHRASE_ADDED = "phrase_added";
export const PHRASE_TIMELINE_MOVED = "phrase_timeline_moved";
export const PHRASE_DELETE = "phrase_delete";
export const PHRASE_DELETE_ALL = "phrase_delete_all";
export const PHRASE_SPLIT = "phrase_split";

// PRE-EXISTING EVENTS:
export const PROJECTS_LIST_VIEWED = "projects_list_viewed"; // only exists for native
export const FILE_UPLOAD_STARTED = "file_upload_started"; // only exists for native
export const LOGIN_PAGE_VIEWED = "login_page_viewed"; // only exists for native
export const PROJECT_FFMPEG_ERROR = "project_ffmpeg_error";
export const TEMPLATE_CLICK = "template_click";
export const CUSTOM_TEMPLATE_ERROR = "custom_template_error";
export const CUSTOM_TEMPLATE_DELETE = "custom_template_delete";
export const CUSTOM_TEMPLATE_CREATE = "custom_template_create";
export const PROJECT_CREATE_ERROR = "project_create_error";
export const SIDE_PANEL_CLICKED = "side_panel_clicked";
export const START_TRANSCRIPTION_CLICKED = "start-transcription-clicked";
export const AUTH_TOKEN_USE = "auth_token_use";

// AI SHORTS
export const AI_SHORTS_BUTTON_CLICK = "ai_shorts_button_click";
export const AI_SHORTS_UPLOAD_ERROR = "ai_shorts_upload_error";
export const AI_SHORTS_VIDEO_SELECTED = "ai_shorts_video_selected";
export const AI_SHORTS_GENERATE_CLICK = "ai_shorts_generate_click";
export const AI_SHORTS_RESULTS_PAGE_VIEW = "ai_shorts_results_page_view";
export const AI_SHORTS_CREATION_STATUS = "ai_shorts_creation_status";
export const AI_SHORTS_CREATION_STATUS_SOURCE = {
  import: "import",
  url: "url",
};
export const AI_SHORTS_RENDER_STATUS = "ai_shorts_render_status";
export const AI_SHORTS_PREVIEW_CLICK = "ai_shorts_preview_click";
export const AI_SHORTS_PREVIEW_HOVER = "ai_shorts_preview_hover";

// AI ADS
export const AI_ADS_HEADER_BUTTON_CLICK = "ugc_ads_button_click";
export const AI_ADS_RESULTS_PAGE_VIEW = "ugc_ads_results_page_view";
export const AI_ADS_PREVIEW_CLICK = "ugc_ads_preview_click";
export const AI_ADS_PREVIEW_HOVER = "ugc_ads_preview_hover";
export const AI_ADS_FORM_VIEW = "ugc_ads_form_view";
export const AI_ADS_FORM_INPUT = "ugc_ads_form_input";

// CREATOR HUB
export const AI_CREATORS_CREATION_STATUS = "ai_creators_creation_status";
export const AI_CREATORS_FORM_VIEW = "ai_creators_form_view";
export const AI_CREATORS_FORM_INPUT = "ai_creators_form_input";
export const AI_CREATOR_START_CLICK = "ai_creator_start_click";
export const AI_CREATOR_SCRIPT_CREATED = "script_created";
export const AI_CREATOR_SCRIPT_EDITED = "script_edited";
export const AI_CREATOR_PREVIEW = "ai_creator_preview";
export const AI_CREATOR_CLICK = "ai_creator_click";

// HOME HEADER
export const CREATE_BUTTON_CLICK = "create_button_click";

// GENERAL EVENT STATUS
export const STATUS_START = "start";
export const STATUS_FINISH = "finish";
export const STATUS_ON = "on";
export const STATUS_OFF = "off";
export const STATUS_CANCEL = "cancel";
export const STATUS_FAIL = "fail";
export const STATUS_ERROR = "error";
export const STATUS_RETRY = "retry";
export const STATUS_SUCCESS = "success";

// DEBUG EVENTS
export const TRANSCRIPT_STUCK_50 = "project_transcript_stuck_50";
export const EXPORT_CHECKPOINT = "export_checkpoint";
export const EXPORT_TYPE_BACKEND = "export_type_backend";
export const EXPORT_TYPE_CLIENT = "export_type_client";

// share link
export const SHARE_VIDEO_VIEW = "share_video_view";
export const SHARE_LINK_COPY_STATUS = "share_link_copy_status";
export const SHARE_LINK_CREATE_STATUS = "share_link_create_status";

export const FEEDBACK_DIALOG_SUBMIT = "feedback_dialog_submit";

// Timing Mode V2
export const ZOOM_LEVEL_CHANGE = "zoom_level_change";
export const CAPTION_TIMESTAMP_DRAG = "caption_timestamp_drag";
export const CAPTION_DURATION_DRAG = "caption_duration_drag";
export const EDITOR_SESSION_STATUS = "editor_session_status";

// AI Editor
export const AI_EDITOR_EVENTS = {
  JUMPED_TO_NEXT_SHOT: "ai_editor_jumped_to_next_shot",
  JUMPED_TO_PREV_SHOT: "ai_editor_jumped_to_prev_shot",
  JUMPED_TO_SHOT: "ai_editor_jumped_to_shot",
  CHANGED_SHOT_STYLE: "ai_editor_changed_shot_style",
  EDITED_CAPTIONS: "ai_editor_edited_captions",
  REPLACED_MEDIA: "ai_editor_replaced_media",
};

// API Platform
export const API_PLATFORM_EVENTS = {
  REGENERATE_CLICK: "api_key_regenerate_click",
  GENERATE_CLICK: "api_key_generate_click",
  DOCUMENTATION_CLICK: "api_documentation_click",
  DASHBOARD_VIEW: "api_dashboard_view",
  PURCHASE_CREDITS_COMPLETE: "purchase_credits_completed",
  PURCHASE_CREDITS_CLICK: "purchase_credits_click",
  CHECKOUT_ERROR: "purchase_credits_error",
};
