import { Root } from "@radix-ui/react-dialog";
import { createContext, PropsWithChildren, useContext, useState } from "react";

import { BaseDialogContent } from "~/components/BaseDialog";
import { LoadingDialog, LoadingDialogProps } from "~/components/LoadingDialog";

export type LoadingDialog = {
  show: (options: LoadingDialogProps) => void;
  hide: () => void;
  updateState: (options: LoadingDialogProps) => void;
  updateProgress: (progress: number) => void;
};

export interface LoadingDialogsContext {
  loadingDialog: LoadingDialog;
}

const LoadingDialogsConfirmContext = createContext<LoadingDialogsContext | null>(null);

export function LoadingDialogsProvider({ children }: Readonly<PropsWithChildren>) {
  const [loadingDialogState, setLoadingDialogState] = useState<LoadingDialogProps | null>(null);

  const loadingDialog = {
    show: (options: LoadingDialogProps) => {
      setLoadingDialogState(options);
    },
    hide: () => {
      setLoadingDialogState(null);
    },
    updateState: (options: LoadingDialogProps) => {
      setLoadingDialogState({
        ...options,
      });
    },
    updateProgress: (progress: number) => {
      setLoadingDialogState((state) => {
        if (state) {
          return {
            ...state,
            progress,
          };
        }
        return null;
      });
    },
  };

  return (
    <LoadingDialogsConfirmContext.Provider value={{ loadingDialog }}>
      {children}
      {loadingDialogState && (
        <Root open modal={false}>
          <BaseDialogContent>
            <LoadingDialog {...loadingDialogState} />
          </BaseDialogContent>
        </Root>
      )}
    </LoadingDialogsConfirmContext.Provider>
  );
}

export function useLoadingDialogs() {
  const loadingDialogs = useContext(LoadingDialogsConfirmContext);
  if (!loadingDialogs) {
    throw new Error("useLoadingDialogs must be used within a LoadingDialogsProvider");
  }
  const { loadingDialog } = loadingDialogs;
  return {
    loadingDialog,
  };
}
