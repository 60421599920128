import type { CaptionStyle, Color, PositionFactor } from "captions-engine";
import { useCallback, useMemo, useState } from "react";

import { CaptionSettings } from "~/database/database.types";

import {
  DEFAULT_ACTIVE_COLOR,
  DEFAULT_EMOJI_SETTINGS,
  DEFAULT_EMPHASIS_COLOR,
  DEFAULT_EMPHASIS_SETTINGS,
  DEFAULT_POSITION_FACTOR,
  DEFAULT_ROTATION,
  DEFAULT_SIZE_FACTOR,
  DEFAULT_TEXT_COLOR,
  DEFAULT_WORD_BACKGROUND,
  DEFAULT_ACTIVE_WORD_BACKGROUND,
} from "../constants/captionStyle";
import {
  CaptionTemplate,
  CaptionEmojiSettings,
  CaptionEmphasisSettings,
} from "../services/CaptionStylePreset";

export interface CaptionsStyleInternal extends CaptionStyle {}

export function useCaptionStyle() {
  const [textColor, setTextColor] = useState<Color>(DEFAULT_TEXT_COLOR);
  const [activeColor, setActiveColor] = useState<Color>(DEFAULT_ACTIVE_COLOR);
  const [activeWordBackground, setActiveWordBackground] = useState<Color>(
    DEFAULT_ACTIVE_WORD_BACKGROUND
  );
  const [emphasisColor, setEmphasisColor] = useState<Color>(DEFAULT_EMPHASIS_COLOR);
  const [wordBackground, setWordBackground] = useState<Color>(DEFAULT_WORD_BACKGROUND);
  const [positionFactor, setPositionFactor] = useState<PositionFactor>(DEFAULT_POSITION_FACTOR);
  const [sizeFactor, setSizeFactor] = useState<number>(0);
  const [rotation, setRotation] = useState<number | undefined>(DEFAULT_ROTATION);
  const [emojiSettings, setEmojiSettings] = useState<CaptionEmojiSettings>(DEFAULT_EMOJI_SETTINGS);
  const [emphasisSettings, setEmphasisSettings] =
    useState<CaptionEmphasisSettings>(DEFAULT_EMPHASIS_SETTINGS);

  const style: CaptionsStyleInternal = useMemo(() => {
    return {
      textColor,
      activeColor,
      emphasisColor,
      wordBackground,
      activeWordBackground,
      positionFactor,
      sizeFactor,
      rotation,
      emojiSettings,
      emphasisSettings,
    } satisfies CaptionStyle;
  }, [
    textColor,
    activeColor,
    emphasisColor,
    wordBackground,
    activeWordBackground,
    positionFactor,
    sizeFactor,
    rotation,
    emojiSettings,
    emphasisSettings,
  ]);

  const restoreFromSettingsEntity = useCallback((settings: CaptionSettings) => {
    setTextColor(settings.textColor ?? DEFAULT_TEXT_COLOR);
    setActiveColor(settings.activeColor ?? DEFAULT_ACTIVE_COLOR);
    setEmphasisColor(settings.emphasisColor ?? DEFAULT_EMPHASIS_COLOR);
    setWordBackground(settings.wordBackground ?? DEFAULT_WORD_BACKGROUND);
    setPositionFactor(settings.positionFactor ?? DEFAULT_POSITION_FACTOR);
    setSizeFactor(settings.sizeFactor ?? DEFAULT_SIZE_FACTOR);
    setEmojiSettings(settings.emojiSettings ?? DEFAULT_EMOJI_SETTINGS);
    setRotation(settings.rotation);
    setEmphasisSettings(settings.emphasisSettings ?? DEFAULT_EMPHASIS_SETTINGS);
    setActiveWordBackground(settings.activeWordBackground ?? DEFAULT_ACTIVE_WORD_BACKGROUND);
  }, []);

  const setTemplate = useCallback((template: CaptionTemplate) => {
    setTextColor(template.colors.primary ?? DEFAULT_TEXT_COLOR);
    setActiveColor(template.colors.active ?? DEFAULT_ACTIVE_COLOR);
    setEmphasisColor(template.colors.emphasis ?? DEFAULT_EMPHASIS_COLOR);
    setWordBackground(template.colors.wordBackground ?? DEFAULT_WORD_BACKGROUND);
    setEmojiSettings(template.emojiSettings ?? DEFAULT_EMOJI_SETTINGS);
    setEmphasisSettings(template.emphasisSettings ?? DEFAULT_EMPHASIS_SETTINGS);
    setActiveWordBackground(
      template.style.content.activeWordBackground.color ?? DEFAULT_ACTIVE_WORD_BACKGROUND
    );
  }, []);

  return {
    emphasisSettings,
    style,
    setTextColor,
    setActiveColor,
    setEmphasisColor,
    setWordBackground,
    setActiveWordBackground,
    setPositionFactor,
    setSizeFactor,
    setRotation,
    setEmojiSettings,
    setEmphasisSettings,
    restoreFromSettingsEntity,
    setTemplate,
  };
}
