import { Progress, ProgressIndicator } from "@radix-ui/react-progress";

import { styled } from "~/theme";

export const ProgressBarRoot = styled(Progress, {
  height: 4,
  backgroundColor: "$grey-950",
  position: "relative",
  overflow: "hidden",
  transform: "translateZ(0)",
  borderRadius: 4,
  width: "200px",
});

export const ProgressBarIndicator = styled(ProgressIndicator, {
  borderRadius: 2,
  width: "100%",
  height: "100%",
  transition: "transform 660ms cubic-bezier(0.65, 0, 0.35, 1)",

  variants: {
    variant: {
      primary: {
        background: "$primary-violet",
      },
      secondary: {
        background: "linear-gradient(270deg, #C18BFF 0%, #6C36FD 18.5%, #6C36FD 100%)",
      },
      error: {
        background: "$secondary-error",
      },
    },
  },
  defaultVariants: {
    variant: "primary",
  },
});
