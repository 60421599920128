import { parseSegmentTime } from "~/modules/command-list/utils/parseCommandListTimestamps";
import {
  ClipWithAbsoluteTimestamps,
  NonDeletedClip,
  RemainingClip,
} from "~/modules/project/hooks/useClipSplitting";

import { clamp } from "../clamp";

import { Clip } from "./videoClips.types";

export const LAST_SAFE_FRAME_INTERVAL = 1e-3;

function isLegacyTupleArray(
  remainingClips: Array<[number, number]> | Clip[]
): remainingClips is Array<[number, number]> {
  return remainingClips.length > 0 && Array.isArray(remainingClips[0]);
}

export function shiftLastClipTimestamp(clips: Clip[] | undefined, timestamp: number) {
  // Workaround for rendering effects in the last frame of the video since the Render Engine
  // currently excludes the last video frame from being painted in the preview
  if (!clips || !clips.length) {
    return timestamp;
  }
  const lastNonDeletedClip = getLastNonDeletedClipBeforeIndex(clips, clips.length);
  const absoluteTimestamp = lastNonDeletedClip
    ? clamp(timestamp, 0, lastNonDeletedClip.endTime - LAST_SAFE_FRAME_INTERVAL)
    : timestamp;

  return absoluteTimestamp;
}

/**
 * Gets a sorted list of tuples describing the remaining clips after deleting.
 *
 * @param remainingClips - either an array of tuples describing the remaining
 * clips after deleting some or an array of all Clip objects.
 * @param [absoluteTimestamp] - the optional original video timestamp in seconds,
 * if given then only clips before this timestamp will be returned.
 *
 * @see {@link getAbsoluteTimestamp} — the inverse function
 */
function getRemainingClipsSorted(
  remainingClips: Array<[number, number]> | Clip[],
  absoluteTimestamp?: number
): Array<[number, number]> {
  if (isLegacyTupleArray(remainingClips)) {
    if (absoluteTimestamp != null) {
      remainingClips = remainingClips.filter((item) => item[0] <= absoluteTimestamp);
    }
    return remainingClips.sort((a, b) => a[0] - b[0]);
  } else {
    if (absoluteTimestamp != null) {
      remainingClips = remainingClips.filter((item) => item.startTime <= absoluteTimestamp);
    }
    return remainingClips
      .filter((item) => !item.deleted)
      .sort((a, b) => a.startTime - b.startTime)
      .map((item) => [item.startTime, item.endTime]);
  }
}

/**
 * The **relative timestamp** corresponds to the final exported video (e.g.,
 * with sections of deleted clips removed). It's used in places like
 * timelines/scrubbers.
 *
 * @param remainingClips - either an array of tuples describing the remaining
 * clips after deleting some or an array of all Clip objects
 * @param absoluteTimestamp - original video timestamp in seconds
 *
 * @see {@link getAbsoluteTimestamp} — the inverse function
 */
export function getRelativeTimestamp(
  remainingClips: Array<[number, number]> | Clip[] | undefined,
  absoluteTimestamp: number
) {
  if (!remainingClips || remainingClips.length === 0) {
    return absoluteTimestamp;
  }
  const remainingClipsSorted: Array<[number, number]> = getRemainingClipsSorted(
    remainingClips,
    absoluteTimestamp
  );
  let relativeTimestamp = 0;
  for (const clip of remainingClipsSorted) {
    if (absoluteTimestamp > clip[1]) {
      relativeTimestamp += clip[1] - clip[0];
    } else if (absoluteTimestamp >= clip[0]) {
      relativeTimestamp += absoluteTimestamp - clip[0];
      break;
    }
  }
  return parseSegmentTime(relativeTimestamp);
}

/**
 * The **absolute timestamp** corresponds to the original, unedited source
 * video. It's the same as the `currentTime` property of the `<video>` DOM
 * element. It's used when seeking/scrubbing the video.
 *
 * @param remainingClips - either an array of tuples describing the remaining
 * clips after deleting some or an array of all Clip objects
 * @param relativeTimestamp - timestamp after clip deletions in seconds
 *
 * @see {@link getRelativeTimestamp}
 */
export function getAbsoluteTimestamp(
  remainingClips: Array<[number, number]> | Clip[] | undefined,
  relativeTimestamp: number
) {
  if (!remainingClips || remainingClips.length === 0) {
    return relativeTimestamp;
  }
  const remainingClipsSorted: Array<[number, number]> = getRemainingClipsSorted(remainingClips);
  let absoluteTimestamp = 0;
  let remainingTime = relativeTimestamp;
  for (const clip of remainingClipsSorted) {
    if (remainingTime >= clip[1] - clip[0]) {
      remainingTime -= clip[1] - clip[0];
      absoluteTimestamp = clip[1];
    } else {
      absoluteTimestamp = clip[0] + remainingTime;
      break;
    }
  }
  return parseSegmentTime(absoluteTimestamp);
}

/**
 * Gets the duration of all clips in seconds.
 *
 * @param clips - the list of clips.
 * @returns the total duration of all clips in seconds.
 */
export function getClipsDuration(clips: Clip[]) {
  return clips.reduce((acc, clip) => (clip.deleted ? acc : acc + clip.endTime - clip.startTime), 0);
}

export function getClipsDurationBeforePosition(
  clipsToGetDuration: Array<[number, number]>,
  position: number,
  smallerOrEqual = false
) {
  const isClipEndBeforePosition = (clipEnd: number) => {
    return smallerOrEqual ? clipEnd <= position : clipEnd < position;
  };
  return clipsToGetDuration.reduce((acc, clip) => {
    if (isClipEndBeforePosition(clip[1])) {
      return acc + clip[1] - clip[0];
    }
    return acc;
  }, 0);
}

export function mergeDeletedClips(clips: Clip[]) {
  return clips.reduce((acc, clip) => {
    if (clip.deleted) {
      const lastClip = acc.at(-1);
      if (lastClip?.deleted && lastClip.endTime === clip.startTime) {
        lastClip.endTime = clip.endTime;
        return acc;
      }
    }
    acc.push(clip);
    return acc;
  }, [] as Clip[]);
}

export function addArbitraryDeletedClip(clips: Clip[], clipToDelete: Clip) {
  if (!clipToDelete.deleted) {
    return clips;
  }
  const insideAlreadyDeletedClip = clips.some(
    (existingClip) =>
      existingClip.deleted &&
      clipToDelete.startTime >= existingClip.startTime &&
      clipToDelete.endTime <= existingClip.endTime
  );
  if (insideAlreadyDeletedClip) {
    return clips;
  }
  return mergeDeletedClips(
    clips
      .reduce(
        (acc, existingClip) => {
          if (
            existingClip.endTime <= clipToDelete.startTime ||
            existingClip.startTime >= clipToDelete.endTime
          ) {
            // Existing clip does not intercept with the clip to delete, leave unchanged
            acc.push(existingClip);
            return acc;
          }
          if (
            existingClip.endTime <= clipToDelete.endTime &&
            existingClip.startTime >= clipToDelete.startTime
          ) {
            // Existing clip is entirely inside the clip to delete, so skip it
            return acc;
          }
          if (
            existingClip.endTime > clipToDelete.endTime &&
            existingClip.startTime < clipToDelete.endTime
          ) {
            acc.push({
              ...existingClip,
              startTime: clipToDelete.endTime,
            });
          }
          if (
            existingClip.startTime < clipToDelete.startTime &&
            existingClip.endTime > clipToDelete.startTime
          ) {
            acc.push({
              ...existingClip,
              endTime: clipToDelete.startTime,
            });
          }
          return acc;
        },
        [clipToDelete]
      )
      .sort((a, b) => a.startTime - b.startTime)
  );
}

export function getLastNonDeletedClipBeforeIndex(
  clipsToLook: Array<ClipWithAbsoluteTimestamps | NonDeletedClip | Clip>,
  index: number
) {
  for (let i = index - 1; i >= 0; i--) {
    if (!clipsToLook[i].deleted) {
      return clipsToLook[i];
    }
  }
  return null;
}

export function mergeClipsWithAbsoluteTimestamps(
  clips: ClipWithAbsoluteTimestamps[]
): RemainingClip[] {
  return clips.reduce((acc, clip, index) => {
    const lastNonDeletedClipBeforeIndex = getLastNonDeletedClipBeforeIndex(clips, index);

    if (acc?.length === 0) {
      return [
        {
          startTime: clip.startTime,
          endTime: clip.endTime,
          absoluteTimestamps: [
            {
              startTime: clip.absoluteStartTime,
              endTime: clip.absoluteEndTime,
            },
          ],
          reframe: clip.reframe,
        },
      ];
    }

    if (lastNonDeletedClipBeforeIndex?.mergeWithNext) {
      const lastAccItem = acc[acc.length - 1];

      return [
        ...acc.slice(0, -1),
        {
          reframe: lastAccItem.reframe,
          startTime: lastAccItem.startTime,
          endTime: clip.endTime,
          absoluteTimestamps: [
            ...lastAccItem.absoluteTimestamps,
            {
              startTime: clip.absoluteStartTime,
              endTime: clip.absoluteEndTime,
            },
          ],
        },
      ];
    }

    return [
      ...acc,
      {
        reframe: clip.reframe,
        startTime: clip.startTime,
        endTime: clip.endTime,
        absoluteTimestamps: [
          {
            startTime: clip.absoluteStartTime,
            endTime: clip.absoluteEndTime,
          },
        ],
      },
    ];
  }, [] as RemainingClip[]);
}

/**
 * Gets the clips that are active at the given timestamp, taking clip merging into account.
 *
 * @param clips - the list of clips.
 * @param timestamp - the absolute timestamp in seconds.
 * @returns the list of clips that are active at the given timestamp.
 */
export function getClipsFromTimestamp(clips: Clip[], timestamp: number): Clip[] {
  const clipIndex = clips.findIndex(
    (clip) => clip.startTime <= timestamp && clip.endTime > timestamp
  );
  if (clipIndex < 0) {
    return [];
  }
  const result: Clip[] = [];
  // Looks for previous clips that are merged with the current clip
  for (let idx = clipIndex - 1; idx >= 0; idx--) {
    if (!clips[idx].mergeWithNext) {
      break;
    }
    result.unshift(clips[idx]);
  }
  // Adds the current clip and every later clip merged with it.
  for (let idx = clipIndex; idx < clips.length; idx++) {
    result.push(clips[idx]);
    if (!clips[idx].mergeWithNext) {
      break;
    }
  }
  return result;
}

/**
 * Checks if the given timestamp is part of a deleted clip.
 *
 * @param clips - the list of clips.
 * @param timestamp - the absolute timestamp in seconds.
 */
export function isAbsoluteTimestampDeleted(clips: Clip[] | undefined, timestamp: number) {
  if (!clips || clips.length === 0) {
    // If no clips are present, the timestamp is never deleted.
    return false;
  }
  return clips.some(
    (clip) => clip.deleted && clip.startTime <= timestamp && clip.endTime > timestamp
  );
}
