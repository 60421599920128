import React from "react";

import { styled } from "~/theme";

function InfoFilledComponent({ ...props }) {
  return (
    <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 19C14.7467 19 19 14.7467 19 9.5C19 4.25329 14.7467 0 9.5 0C4.25329 0 0 4.25329 0 9.5C0 14.7467 4.25329 19 9.5 19ZM8.11418 12.9335C8.0388 13.1155 8 13.3105 8 13.5075C8 13.9053 8.15804 14.2869 8.43934 14.5682C8.72064 14.8495 9.10218 15.0075 9.5 15.0075C9.89782 15.0075 10.2794 14.8495 10.5607 14.5682C10.842 14.2869 11 13.9053 11 13.5075C11 13.3105 10.9612 13.1155 10.8858 12.9335C10.8104 12.7515 10.6999 12.5861 10.5607 12.4468C10.4214 12.3076 10.256 12.1971 10.074 12.1217C9.89204 12.0463 9.69698 12.0075 9.5 12.0075C9.30302 12.0075 9.10796 12.0463 8.92597 12.1217C8.74399 12.1971 8.57863 12.3076 8.43934 12.4468C8.30005 12.5861 8.18956 12.7515 8.11418 12.9335ZM8.2058 3.93844C8.14706 4.11944 8.12702 4.31076 8.147 4.5L8.672 9.7605C8.68964 9.96716 8.7842 10.1597 8.93697 10.3C9.08974 10.4402 9.28959 10.5181 9.497 10.5181C9.70441 10.5181 9.90426 10.4402 10.057 10.3C10.2098 10.1597 10.3044 9.96716 10.322 9.7605L10.847 4.5C10.867 4.31076 10.8469 4.11944 10.7882 3.93844C10.7295 3.75745 10.6333 3.59082 10.506 3.44938C10.3787 3.30794 10.2231 3.19484 10.0493 3.11742C9.87545 3.04001 9.68729 3 9.497 3C9.30671 3 9.11855 3.04001 8.94472 3.11742C8.77089 3.19484 8.61528 3.30794 8.48798 3.44938C8.36068 3.59082 8.26454 3.75745 8.2058 3.93844Z"
        fill="currentColor"
      />
    </svg>
  );
}

const InfoFilled = styled(InfoFilledComponent, {
  width: 24,
  height: 24,
  color: "$white",
});

export default InfoFilled;
