import { useCallback, useRef } from "react";

import { useBackendServicesClient } from "~/context/BackendServicesContext";
import { useLatestRef, useOnUnmountEffect } from "~/hooks/helpers";
import { usePollingFetch } from "~/hooks/usePollingFetch";

import {
  AICollage,
  AICollageWithSceneIndex,
  CollageJobStatus,
  ReplaceCollageImageRequest,
  StartCollageJobRequest,
  getCollageJob,
  getReplaceCollageImageJob,
  startCollageJob,
  startReplaceCollageImageJob,
} from "../services/Collages";

const determineJobStatus = <T>(status: CollageJobStatus<T>) => {
  switch (status.state) {
    case "SUCCESS":
    case "COMPLETE":
      return { type: "done", result: status } as const;
    case "FAILED":
      return { type: "error", error: new Error("Collage job failed") } as const;
    case "QUEUED":
    case "PROCESSING":
      return { type: "pending", status } as const;
    default:
      return {
        type: "error",
        error: new Error(`Unknown collage job state ${status.state}`),
      } as const;
  }
};

const mergeSceneIndices = (
  request: StartCollageJobRequest,
  collages: AICollage[]
): AICollageWithSceneIndex[] => {
  return collages.map((collage, index) => ({
    ...collage,
    sceneIndex: request.collageRequests[index].sceneIndex,
    imageSceneStyle: request.collageRequests[index].sceneStyle,
  }));
};

const catchAbortErrors =
  <T>(fallback: T) =>
  (error: unknown): T => {
    if (error instanceof Error && error.name === "AbortError") {
      return fallback;
    }

    throw error;
  };

export interface UseFetchCollageDataProps {
  onStartPooling?: (operationId: string) => void;
}

export function useFetchCollageData({ onStartPooling }: UseFetchCollageDataProps = {}) {
  const { trigger: fetchCollageJob } = usePollingFetch(getCollageJob, determineJobStatus);
  const client = useBackendServicesClient();
  const latestOnStartPooling = useLatestRef(onStartPooling);

  const abortControllersRef = useRef(new Set<AbortController>());

  const fetchCollageData = useCallback(
    (request: StartCollageJobRequest) => {
      const abortController = new AbortController();
      abortControllersRef.current.add(abortController);

      return startCollageJob(client, request, { signal: abortController.signal })
        .then((res) => {
          latestOnStartPooling.current?.(res.operationId);
          return fetchCollageJob(res, { signal: abortController.signal });
        })
        .then((res) => mergeSceneIndices(request, res.collages))
        .catch(catchAbortErrors([]));
    },
    [client, fetchCollageJob, latestOnStartPooling]
  );

  useOnUnmountEffect(() => {
    abortControllersRef.current.forEach((controller) => {
      controller.abort();
    });
  });

  return {
    fetchCollageData,
  };
}

export function useFetchReplaceCollageImage() {
  const { trigger: fetchReplaceCollageImageJob } = usePollingFetch(
    getReplaceCollageImageJob,
    determineJobStatus
  );
  const client = useBackendServicesClient();

  const abortControllersRef = useRef(new Set<AbortController>());

  const fetchReplacedImage = useCallback(
    (request: ReplaceCollageImageRequest) => {
      const abortController = new AbortController();
      abortControllersRef.current.add(abortController);

      return startReplaceCollageImageJob(client, request, { signal: abortController.signal })
        .then((res) => fetchReplaceCollageImageJob(res, { signal: abortController.signal }))
        .then((res) => {
          if (!res.image) {
            throw new Error("No image returned from replace collage image job");
          }

          return res.image;
        })
        .catch(catchAbortErrors(null));
    },
    [client, fetchReplaceCollageImageJob]
  );

  useOnUnmountEffect(() => {
    abortControllersRef.current.forEach((controller) => {
      controller.abort();
    });
  });

  return {
    fetchReplacedImage,
  };
}
