import { BackendRequestOptions, BackendServicesClient } from "~/context/BackendServicesContext";

export interface AICollageImage {
  imageUrl: string;
  center: {
    x: number;
    y: number;
  };
  description: string;
  startTime: number | null;
  endTime: number | null;
}

export interface AICollage {
  phrase: string;
  collage: AICollageImage[];
}

export interface AICollageWithSceneIndex extends AICollage {
  sceneIndex: number;
  imageSceneStyle?: string;
}

export type CollageJobStatus<T> = T & {
  state: string;
};

export type CollageJobResponse<T> = {
  success: boolean;
  data: CollageJobStatus<T>;
};

export async function getCollageJob(
  client: BackendServicesClient,
  params: { operationId: string },
  options?: Pick<BackendRequestOptions, "signal">
) {
  const result = await client.post<CollageJobResponse<{ collages: AICollage[] }>>(
    "/proxy/v1/ai-edit/image/collage/batch/get",
    params,
    options
  );
  if (!result.data.success) {
    throw new Error("Failed to get collage job status", { cause: result.data });
  }
  return result.data.data;
}

export async function getReplaceCollageImageJob(
  client: BackendServicesClient,
  params: { operationId: string },
  options?: Pick<BackendRequestOptions, "signal">
) {
  const result = await client.post<CollageJobResponse<{ image?: AICollageImage }>>(
    "/proxy/v1/ai-edit/image/replace/get",
    params,
    options
  );
  if (!result.data.success) {
    throw new Error("Failed to get collage job status", { cause: result.data });
  }
  return result.data.data;
}
