import { AISceneDataWithStyles, AIVideoMetadata } from "../AIScenes.types";
import { Command } from "../CommandList.types";
import { EDLSegment, EDLdata } from "../EDL.types";

// returns the main video overlay command and the total duration of the main video
// updates each scene with the mainOffset
export function populateAndGetMainVideo(
  sceneData: AISceneDataWithStyles,
  videoMetadata: AIVideoMetadata
) {
  // trim main video based on AIScene segmentation
  // todo - padd trimming by 0.5s
  let mainTotal = 0;
  const trimmedEDL: EDLSegment[] = [];
  for (let i = 0; i < sceneData.scenes.length; i++) {
    const scene = sceneData.scenes[i];
    const sceneDuration = scene.endTime - scene.startTime;
    scene.mainOffset = mainTotal; // needed to map scenes into new trimmed time space
    const segments: EDLSegment = [
      mainTotal,
      mainTotal + sceneDuration,
      scene.startTime,
      scene.endTime,
    ];
    trimmedEDL.push(segments);
    mainTotal += sceneDuration;
  }

  const mainEDL: EDLdata = { segments: trimmedEDL };

  const mainVideoCommand: Command = {
    edl: mainEDL,
    renderEffect: {
      renderEffectType: "overlay",
      assetId: "video-source",
      metadata: videoMetadata,
    },
  };

  return { mainVideoCommand, mainEDL, mainTotal };
}
