import type { AISceneWithStyle, AIWord } from "../AIScenes.types";
import type { OverlayAnimationTextReplacements } from "../CommandList.types";
import {
  AIImageRules,
  AIOverlayAnimationStyle,
  AIStaticImageRules,
} from "../services/AIEditStyles";

import { AICollageImageWithPosition, AIStaticImageWithPosition } from "./calculateImagePositions";

interface GroupedWords {
  words: AIWord[];
}

interface GroupedLines {
  lines: GroupedWords[];
}

export interface AIEditAnimation {
  introAssetId?: string;
  outroAssetId?: string;
  holdAssetId?: string;
  textReplacements?: OverlayAnimationTextReplacements;
  imageReplacements?: string[];
  startTime: number;
  endTime: number;
  positioning?:
    | {
        type: "fullscreen";
        fit?: "stretch" | "contain" | "cover";
      }
    | {
        type: "freeform";
        centerX?: number;
        centerY?: number;
        scale?: number | "small" | "medium" | "large";
      };
}

const COMFORTABLE_HOLD_TIME_DURATION_SEC = 1;
const ANIMATION_SNAP_INTERVAL_SEC = 1;
const CHARACTER_COUNT_TOLERANCE = 2;
const PUNCTUATION_CHARACTERS = ".,!?";

function groupWords(words: AIWord[], maxCharacters: number): GroupedWords[] {
  const lines: GroupedWords[] = [];
  let currentLine: AIWord[] = [];
  let currentLineCharCount = 0;
  for (const word of words) {
    const lineStartTime = currentLine.length === 0 ? word.startTime : currentLine[0].startTime;
    const isWithinCharLimit = currentLineCharCount + word.word.length < maxCharacters;
    const isWithinTimeLimit = word.endTime < lineStartTime + COMFORTABLE_HOLD_TIME_DURATION_SEC;
    if (currentLine.length === 0 || isWithinCharLimit || isWithinTimeLimit) {
      currentLine.push(word);
      currentLineCharCount += word.word.length;
      continue;
    }
    lines.push({ words: currentLine });
    currentLine = [word];
    currentLineCharCount = word.word.length;
  }
  if (currentLine.length > 0) {
    lines.push({ words: currentLine });
  }
  return lines;
}

export function getStaticImageAnimation(
  staticImage: AIStaticImageWithPosition,
  rules: AIStaticImageRules
): AIEditAnimation {
  if (rules.imageAnimations?.type !== "pag") {
    throw new Error("Only PAG image animations are supported");
  }
  return {
    introAssetId: rules.imageAnimations.animationIn?.id,
    outroAssetId: rules.imageAnimations.animationOut?.id,
    holdAssetId: rules.imageAnimations.activeAnimation?.id,
    startTime: staticImage.startTime,
    endTime: staticImage.endTime,
    imageReplacements: [staticImage.assetId],
    positioning: {
      type: "freeform",
      centerX: staticImage.position.x,
      centerY: staticImage.position.y,
      scale: staticImage.scale,
    },
  };
}

export function getImageAnimation(
  image: AICollageImageWithPosition,
  imageRules: AIImageRules
): AIEditAnimation {
  if (imageRules.imageAnimations?.type !== "pag") {
    throw new Error("Only PAG image animations are supported");
  }
  return {
    introAssetId: imageRules.imageAnimations.animationIn?.id,
    outroAssetId: imageRules.imageAnimations.animationOut?.id,
    holdAssetId: imageRules.imageAnimations.activeAnimation?.id,
    startTime: image.startTime,
    endTime: image.endTime,
    imageReplacements: [image.imageUrl],
    positioning: {
      type: "freeform",
      centerX: image.position.x,
      centerY: image.position.y,
      scale: image.size,
    },
  };
}

export function getAnimationsWithReplacement(scene: AISceneWithStyle) {
  const animationStyle = scene.style?.animation;
  const wordAnimationStyle = scene.style?.wordAnimation;

  if (animationStyle) {
    const animationStyles = !scene.style?.animation
      ? []
      : Array.isArray(scene.style?.animation)
      ? scene.style?.animation
      : [scene.style?.animation];

    return animationStyles?.flatMap((animationStyle) => getAnimations(scene, animationStyle));
  }

  if (wordAnimationStyle) {
    return getWordAnimations(scene);
  }
}

function getAnimations(
  scene: AISceneWithStyle,
  animationStyle: AIOverlayAnimationStyle
): AIEditAnimation[] {
  const sceneTitle = scene.title;
  const sceneWords = scene.words;
  const sceneStart = scene.startTime;
  const sceneEnd = scene.endTime;

  const imageReplacements = animationStyle.imageReplacement
    ? [animationStyle.imageReplacement.fixedAssetId]
    : [];

  if (animationStyle.titleReplacement && sceneTitle) {
    const { color, numberOfLines, textShadowLayer } = animationStyle.titleReplacement;
    const autoSize = numberOfLines === "auto";
    const isMultiLine = autoSize ? false : numberOfLines > 1;

    let replacements = [sceneTitle];
    if (isMultiLine) {
      const words = sceneTitle.split(" ");
      const firstLine = words.slice(0, Math.floor(words.length / 2)).join(" ");
      const secondLine = words.slice(Math.floor(words.length / 2)).join(" ");

      replacements = textShadowLayer
        ? [firstLine, firstLine, secondLine, secondLine]
        : [firstLine, secondLine];
    }

    return [
      {
        introAssetId: animationStyle.introId,
        outroAssetId: animationStyle.outroId,
        holdAssetId: animationStyle.holdId,
        startTime: sceneStart,
        endTime: sceneEnd,
        textReplacements: {
          autoSize,
          color,
          replacements,
        },
        positioning: animationStyle.positioning,
      },
    ];
  }

  if (animationStyle.imageReplacement) {
    return [
      {
        introAssetId: animationStyle.introId,
        outroAssetId: animationStyle.outroId,
        holdAssetId: animationStyle.holdId,
        startTime: sceneStart,
        endTime: sceneEnd,
        imageReplacements,
        positioning: animationStyle.positioning,
      },
    ];
  }

  if (!animationStyle.textReplacement) {
    return [];
  }

  const { charactersPerLine, numberOfLines, addEllipsis } = animationStyle.textReplacement;
  const isMultiLine = numberOfLines > 1;
  const lineGroups = groupWords(sceneWords, charactersPerLine)
    .reduce<GroupedLines[]>((acc, value, index) => {
      if (index % numberOfLines === 0) {
        acc.push({ lines: [value] });
        return acc;
      }
      acc.at(-1)!.lines.push(value);
      return acc;
    }, [])
    .filter((group, index, lineGroups) => {
      const isLastGroup = index === lineGroups.length - 1;
      const startTime = group.lines[0].words[0].startTime;
      const endTime = isLastGroup ? scene.endTime : group.lines.at(-1)!.words.at(-1)!.endTime;
      const duration = endTime - startTime;

      if (isLastGroup && duration < COMFORTABLE_HOLD_TIME_DURATION_SEC) {
        return false;
      }

      return true;
    });

  let previousEnd = sceneStart;
  return lineGroups.map((group, index) => {
    const firstWordStart = group.lines[0].words[0].startTime;
    const lastWordEnd = group.lines.at(-1)!.words.at(-1)!.endTime;
    const isLastGroup = index === lineGroups.length - 1;

    const startTime =
      firstWordStart - previousEnd < ANIMATION_SNAP_INTERVAL_SEC ? previousEnd : firstWordStart;
    let endTime = lastWordEnd;
    if (isLastGroup) {
      endTime = sceneEnd;
    }

    const textToRender = group.lines.reduce<string[]>((lineAcc, line, index) => {
      const isLastLine = index === group.lines.length - 1;
      const textLine = line.words.reduce((wordAcc, word) => {
        if (wordAcc.length === 0) {
          return word.word;
        }
        if (wordAcc.length + word.word.length < charactersPerLine + CHARACTER_COUNT_TOLERANCE) {
          return wordAcc + " " + word.word;
        }
        return wordAcc;
      }, "");
      const lineToPush =
        addEllipsis && isLastLine && !PUNCTUATION_CHARACTERS.includes(textLine.at(-1) ?? " ")
          ? textLine + "..."
          : textLine;
      lineAcc.push(lineToPush);
      if (isMultiLine) {
        // On multiline PAG text animations the same text needs to be applied twice
        lineAcc.push(lineToPush);
      }
      return lineAcc;
    }, []);
    previousEnd = endTime;
    return {
      introAssetId: animationStyle.introId,
      outroAssetId: animationStyle.outroId,
      holdAssetId: animationStyle.holdId,
      startTime,
      endTime,
      textReplacements: {
        replacements: textToRender,
      },
      imageReplacements,
      positioning: animationStyle.positioning,
    } satisfies AIEditAnimation;
  });
}

function getWordAnimations(scene: AISceneWithStyle): AIEditAnimation[] {
  const wordAnimation = scene.style?.wordAnimation;

  if (!wordAnimation) {
    return [];
  }

  function nextWordTimeSegment(words: AIWord[], index: number, sceneEndTime: number) {
    const nextWord = words[index + 1];
    if (!nextWord) {
      return null;
    }

    const nextNextWord = words[index + 2];
    if (!nextNextWord) {
      return { startTime: nextWord.startTime, endTime: sceneEndTime };
    }

    return { startTime: nextWord.startTime, endTime: nextNextWord.startTime };
  }

  const wordAnimations = scene.words.map((word, index) => {
    const prevWord = scene.words[index - 1];
    const nextWord = nextWordTimeSegment(scene.words, index, scene.endTime);
    const { outroId, holdId } = wordAnimation?.(word.word) || {};

    // Prevents flashes between word animations
    const minDuration = 0.1;
    let endTime;
    if (nextWord) {
      const duration = Math.max(nextWord.startTime - word.startTime, minDuration);
      endTime = word.startTime + duration;
    } else {
      endTime = scene.endTime;
    }

    return {
      introAssetId: undefined,
      outroAssetId: !nextWord ? undefined : outroId, // skip OUT Animation for last word
      holdAssetId: holdId,
      textReplacements: {
        replacements: [word.word],
      },
      startTime: prevWord
        ? Math.max(prevWord.startTime - minDuration, scene.startTime)
        : word.startTime,
      endTime,
    } satisfies AIEditAnimation;
  });

  // Add it in reversed order so we can stack animation layers
  return wordAnimations.reverse();
}
