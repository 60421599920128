/**
 * Normalizes a given phrase by:
 * 1. Converting all characters to lowercase.
 * 2. Separating and removing accents and diacritics.
 * 3. Removing commas (`,`) and periods (`.`).
 *
 * @param {string} phrase - The phrase to be normalized.
 * @returns {string} - The normalized phrase without accents, diacritics, commas, or periods.
 */
export function normalizePhrase(phrase: string): string {
  return (
    phrase
      // step 1
      .toLowerCase()
      // step 2
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      // step 3
      .replace(/[,.]/g, "")
  );
}
