import axios, { AxiosError, AxiosProgressEvent, AxiosRequestConfig, AxiosResponse } from "axios";

import { mergeAbortSignals, raceAbort } from "~/utils/abort-signals";

export interface BackendRequestOptions extends AxiosRequestConfig {
  // if false, the request will not wait if no access token is available
  // defaults to true
  requiresAuthentication?: boolean;

  url: string;
}

// exported types to help maintain compatibility with the old client
export type BackendRequestHeaders = AxiosRequestConfig["headers"];
export type BackendUploadProgress = AxiosProgressEvent;
export type BackendServicesResponse<T = unknown> = AxiosResponse<T>;

export interface ProxyResponse<T = unknown> {
  success: boolean;
  data: T;
}

function createAxiosClient() {
  const client = axios.create();

  if (process.env.NODE_ENV === "development") {
    client.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.code === "ERR_CANCELED") {
          // aborted in useEffect cleanup
          return Promise.resolve({ status: 499 });
        }
        return Promise.reject(error);
      }
    );
  }

  return client;
}

interface BackendServicesClientConfig {
  latestAppVersion?: string;
  baseURL?: string;

  // disable this if most requests will not be authenticated
  // and so we should not introduce an artificial delay making sure
  // we have an access token
  // defaults to true
  assumeRequestsNeedAuthentication?: boolean;

  refreshAccessToken?: () => Promise<string | null>;
  onForbidden?: () => void;
  onUnauthorized?: (displayAlert?: boolean) => void;
}

interface TokenInfo {
  token: string;
  createdAt: number;
}

export class BackendServicesClient {
  static shared = new BackendServicesClient();

  private config?: BackendServicesClientConfig;

  private axiosClient = createAxiosClient();

  private currentAccessToken: TokenInfo | null = null;

  private pendingTokenRefresh: Promise<TokenInfo | null> | null = null;

  private globalAbortController = new AbortController();

  init(config: BackendServicesClientConfig) {
    this.config = config;
  }

  get version() {
    return this.config?.latestAppVersion ?? "dev";
  }

  updateConfiguration(config: Partial<BackendServicesClientConfig>) {
    this.config = { ...this.config, ...config } as BackendServicesClientConfig;
  }

  setInitialAccessTokenIfNoneExists(token: string) {
    if (!this.currentAccessToken) {
      this.setAccessToken(token);
    }
  }

  setAccessToken(token: string | null) {
    if (token) {
      this.currentAccessToken = { token, createdAt: window.performance.now() };
    } else {
      this.currentAccessToken = null;
      this.cancelPendingRequests();
    }

    return this.currentAccessToken;
  }

  cancelPendingRequests() {
    // abort all in flight requests
    this.globalAbortController.abort();

    // create a new abort controller for future requests
    this.globalAbortController = new AbortController();
  }

  // request methods

  post<T = unknown>(
    url: string,
    data: object,
    options?: Omit<BackendRequestOptions, "method" | "url" | "data">
  ) {
    return this.request<T>({ method: "post", url, data, ...options });
  }

  put<T = unknown>(
    url: string,
    data: object,
    options?: Omit<BackendRequestOptions, "method" | "url" | "data">
  ) {
    return this.request<T>({ method: "put", url, data, ...options });
  }

  get<T = unknown>(url: string, options?: Omit<BackendRequestOptions, "method" | "url" | "data">) {
    return this.request<T>({ method: "get", url, ...options });
  }

  delete<T = unknown>(
    url: string,
    options?: Omit<BackendRequestOptions, "method" | "url" | "data">
  ) {
    return this.request<T>({ method: "delete", url, ...options });
  }

  async request<T>(options: BackendRequestOptions): Promise<AxiosResponse<T>> {
    const MAX_REQUEST_RETRY_ATTEMPTS = 2;
    const mergedAbortSignal = mergeAbortSignals(this.globalAbortController.signal, options.signal);

    let tokenInfo = await this.getAccessToken(options, mergedAbortSignal);

    for (let attempt = 0; attempt < MAX_REQUEST_RETRY_ATTEMPTS; attempt++) {
      const authHeader = tokenInfo
        ? {
            Authorization: `Bearer ${tokenInfo.token}`,
          }
        : {};

      const isLastAttempt = attempt >= MAX_REQUEST_RETRY_ATTEMPTS - 1;

      try {
        const response = await this.axiosClient.request<T>({
          ...options,
          baseURL: options.baseURL ?? this.config?.baseURL,
          headers: {
            "x-app-version": this.config?.latestAppVersion,
            ...authHeader,
            ...options?.headers,
          },
          signal: mergedAbortSignal,
        });

        if (!this.shouldRetryRequest(response, isLastAttempt)) {
          return response;
        }
      } catch (error) {
        if (!(error instanceof AxiosError)) {
          throw error;
        }

        if (!this.shouldRetryRequest(error.response, isLastAttempt)) {
          throw error;
        }
      }

      // Refresh token and try again
      tokenInfo = await this.performTokenRefresh(tokenInfo?.createdAt ?? 0);
      if (tokenInfo == null) {
        const hideAlert = this.currentAccessToken == null;
        this.config?.onUnauthorized?.(hideAlert);
        throw new Error("Failed to get refreshed token");
      }
    }

    // Should never be reached, but will make the compiler happy
    throw new Error("Unknown error while issuing request");
  }

  // private methods

  private async performTokenRefresh(previousTokenTime: number) {
    if (this.pendingTokenRefresh) {
      return this.pendingTokenRefresh;
    }

    if (this.currentAccessToken != null && this.currentAccessToken.createdAt > previousTokenTime) {
      // If the token was updated after the request was built, return the current token
      return this.currentAccessToken;
    }

    if (this.config?.refreshAccessToken) {
      this.pendingTokenRefresh = this.config
        .refreshAccessToken()
        .then((token) => {
          return this.setAccessToken(token);
        })
        .finally(() => {
          this.pendingTokenRefresh = null;
        });
    }

    return this.pendingTokenRefresh;
  }

  private async getAccessToken(options: BackendRequestOptions, signal: AbortSignal) {
    if (this.pendingTokenRefresh) {
      // If a token refresh is ongoing, return it's promise
      // which will resolve with the new token
      return this.pendingTokenRefresh;
    }

    // If there is no access token, which is likely during requests fired on mount
    // wait a little while until we have an access token
    await raceAbort(signal, this.delayIfNoAccessToken(options));

    return this.currentAccessToken;
  }

  private async delayIfNoAccessToken({
    requiresAuthentication,
  }: Pick<BackendRequestOptions, "requiresAuthentication">) {
    // we default to assuming requests need authentication
    const defaultAssumption = this.config?.assumeRequestsNeedAuthentication ?? true;

    // if the request does not explicitly specify it's authentication needs (or lack thereof)
    // we use our default assumption
    const thisRequestNeedsAuthentication = requiresAuthentication ?? defaultAssumption;

    if (!thisRequestNeedsAuthentication) {
      return;
    }

    let attempt = 0;
    const MAX_ATTEMPTS = 50;

    while (!this.currentAccessToken && attempt < MAX_ATTEMPTS) {
      // wait 100ms before checking if we have an access token again
      await new Promise((resolve) => setTimeout(resolve, 100));

      attempt++;
    }
  }

  private shouldRetryRequest(response: AxiosResponse | undefined, isLastAttempt: boolean) {
    if (response?.status === 403) {
      this.config?.onForbidden?.();
      return false;
    }

    if (response?.status === 401 && isLastAttempt) {
      this.config?.onUnauthorized?.();
      return false;
    }

    return response?.status === 401;
  }
}
