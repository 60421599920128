import { CaptionsDatabaseInstance } from "./factory";
import { databaseStore } from "./store";

const MAX_RETRIES = 3;

export const executeWithRetry = async <T>(
  operation: (db: CaptionsDatabaseInstance) => Promise<T>
): Promise<T> => {
  let lastError: Error | null = null;
  let retryCount = 0;

  while (retryCount <= MAX_RETRIES) {
    try {
      const store = databaseStore.getState();
      let db: CaptionsDatabaseInstance;

      if (store.status === "ready" && store.instance) {
        db = store.instance;
      } else {
        db = await store.resetConnection();
      }

      return await operation(db);
    } catch (error) {
      lastError = error instanceof Error ? error : new Error(String(error));

      if (lastError.message.includes("database connection is closing")) {
        console.log(`Retry attempt ${retryCount + 1} of ${MAX_RETRIES}`);
        await databaseStore.getState().resetConnection();
        retryCount++;
        continue;
      }
      throw error;
    }
  }

  throw lastError || new Error("Operation failed after maximum retries");
};

// Helper to wrap Repository class methods with executeWithRetry
export function withRetry<T extends { new (...args: unknown[]): unknown }>(constructor: T) {
  // Return a proxy-wrapped version of the class
  return new Proxy(constructor, {
    get(target, propKey: string | symbol, receiver) {
      const originalMethod = Reflect.get(target, propKey, receiver);

      // Check if the property is a function with the expected signature
      if (typeof originalMethod === "function") {
        return (...args: unknown[]) =>
          executeWithRetry(() =>
            (originalMethod as (...args: unknown[]) => Promise<unknown>)(...args)
          );
      }

      return originalMethod;
    },
  });
}
