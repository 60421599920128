import { getPrimarySceneSegments } from "../../utils/genPrimarySceneSegments";

import { EffectsCommandGeneratorFunction } from "./types";

export const generateFilterCommandsForScene: EffectsCommandGeneratorFunction = (scene) => {
  if (!scene.style.filters) {
    return null;
  }

  return {
    edl: {
      segments: getPrimarySceneSegments(scene),
    },
    renderEffect: {
      renderEffectType: "filter",
      filters: scene.style.filters,
    },
  };
};
