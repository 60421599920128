import { formatFileSize } from "~/utils/file";

// Do not export any of these constants, use validation functions instead

// The max video file size we allow to be uploaded
const VIDEO_SIZE_MAX = 2_000_000_000; // 2GB

// The max duration of a video we allow for normal pro videos
const VIDEO_DURATION_MAX = 10 * 60; // 10 min

// For max plan we have a different duration limit
const MAX_PLAN_DURATION_MAX = 30 * 60; // 30 min

// The max duration we enable captions translations and audio dubbing
const TRANSLATION_DURATION_MAX = 5 * 60; // 5 min

// The ai shorts duration limits we allow
const AI_SHORTS_DURATION_MIN = 4 * 60; // 4 min
const AI_SHORTS_DURATION_MAX = 120 * 60; // 2 hours

// Ai Ads media limits
export const ADS_MEDIA_IMAGE_SIZE_MAX = 500_000_000; // 500MB
export const ADS_MEDIA_VIDEO_SIZE_MAX = 1_000_000_000; // 1GB
export const ADS_MEDIA_VIDEO_DURATION_MAX = 60; // 1 min

// the context in which this video is being used
interface VideoUsage {
  isMaxPlan?: boolean;
  isAIShort?: boolean;
}

interface ValidationResult {
  isValid: boolean;
  validationMessage: string;
}

// Convert messages to minutes
export const formatDuration = (seconds: number) => {
  const minutes: number = Math.floor(seconds / 60);
  const hours: number = Math.floor(minutes / 60);

  const formattedParts: string[] = [];

  if (hours > 0) {
    formattedParts.push(`${hours} hour${hours > 1 ? "s" : ""}`);
  }
  if (minutes > 0) {
    const remainingMinutes = minutes % 60;
    if (remainingMinutes > 0) {
      formattedParts.push(`${remainingMinutes} minute${remainingMinutes !== 1 ? "s" : ""}`);
    }
  }
  const remainingSeconds = Math.round(seconds % 60);
  if (remainingSeconds > 0) {
    formattedParts.push(`${remainingSeconds} second${remainingSeconds !== 1 ? "s" : ""}`);
  }

  if (formattedParts.length === 0) {
    return "0 seconds";
  }

  return formattedParts.join(" ");
};

export const validateVideoDuration = (duration: number, usage?: VideoUsage): ValidationResult => {
  const { isAIShort, isMaxPlan } = usage ?? {};

  const limits = {
    min: 0,
    max: VIDEO_DURATION_MAX,
  };

  if (isMaxPlan) {
    limits.max = MAX_PLAN_DURATION_MAX;
  }

  if (isAIShort) {
    limits.min = AI_SHORTS_DURATION_MIN;
    limits.max = AI_SHORTS_DURATION_MAX;
  }

  if (!Number.isFinite(duration)) {
    return {
      isValid: false,
      validationMessage: "invalid duration",
    };
  }

  if (duration > limits.max) {
    let validationMessage = `Your uploaded video should be between ${formatDuration(
      limits.min
    )} and ${formatDuration(limits.max)} in length`;

    // simplify if min is zero
    if (limits.min === 0) {
      validationMessage = `Your uploaded video should be less than ${formatDuration(
        limits.max
      )} in length`;
    }

    return {
      isValid: false,
      validationMessage,
    };
  }

  if (duration < limits.min) {
    return {
      isValid: false,
      validationMessage: `Your uploaded video should be between ${formatDuration(
        limits.min
      )} and ${formatDuration(limits.max)} in length`,
    };
  }

  return {
    isValid: true,
    validationMessage: "",
  };
};

export const validateTranslationDuration = (duration: number): ValidationResult => {
  if (!Number.isFinite(duration)) {
    return {
      isValid: false,
      validationMessage: "invalid duration",
    };
  }

  if (duration > TRANSLATION_DURATION_MAX) {
    return {
      isValid: false,
      validationMessage: `${formatDuration(
        duration
      )} exceeds max translation limit of ${formatDuration(TRANSLATION_DURATION_MAX)}`,
    };
  }

  return {
    isValid: true,
    validationMessage: "",
  };
};

export const validateVideoSize = (fileSize: number): ValidationResult => {
  if (!Number.isFinite(fileSize)) {
    return {
      isValid: false,
      validationMessage: "invalid file size",
    };
  }

  if (fileSize > VIDEO_SIZE_MAX) {
    return {
      isValid: false,
      validationMessage: `${formatFileSize(fileSize)} is larger than the limit of ${formatFileSize(
        VIDEO_SIZE_MAX
      )}`,
    };
  }

  return {
    isValid: true,
    validationMessage: "",
  };
};

export const validateAdsMediaImageSize = (fileSize: number): ValidationResult => {
  if (!Number.isFinite(fileSize)) {
    return {
      isValid: false,
      validationMessage: "invalid file size",
    };
  }

  if (fileSize > ADS_MEDIA_IMAGE_SIZE_MAX) {
    return {
      isValid: false,
      validationMessage: `${formatFileSize(fileSize)} is larger than the limit of ${formatFileSize(
        ADS_MEDIA_IMAGE_SIZE_MAX
      )}`,
    };
  }

  return {
    isValid: true,
    validationMessage: "",
  };
};

export const validateAdsMediaVideoSize = (fileSize: number): ValidationResult => {
  if (!Number.isFinite(fileSize)) {
    return {
      isValid: false,
      validationMessage: "invalid file size",
    };
  }

  if (fileSize > ADS_MEDIA_VIDEO_SIZE_MAX) {
    return {
      isValid: false,
      validationMessage: `${formatFileSize(fileSize)} is larger than the limit of ${formatFileSize(
        ADS_MEDIA_VIDEO_SIZE_MAX
      )}`,
    };
  }

  return {
    isValid: true,
    validationMessage: "",
  };
};

export const validateAdsMediaVideoDuration = (duration: number): ValidationResult => {
  if (!Number.isFinite(duration)) {
    return {
      isValid: false,
      validationMessage: "invalid duration",
    };
  }

  if (duration > ADS_MEDIA_VIDEO_DURATION_MAX) {
    return {
      isValid: false,
      validationMessage: `${formatDuration(
        duration
      )} exceeds max duration limit of ${formatDuration(ADS_MEDIA_VIDEO_DURATION_MAX)}`,
    };
  }

  return {
    isValid: true,
    validationMessage: "",
  };
};
