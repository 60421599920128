import type { ImageItem } from "captions-engine";

import { addArbitraryDeletedClip, Clip, ClipTuple, getRelativeTimestamp } from "~/utils/videoClips";

import { CaptionPhrase, CaptionPhraseAudio, CaptionWord } from "../captionProcessing";

function adjustTimestamp(timestamp: number, clipStart: number, clipDuration: number) {
  return timestamp < clipStart ? timestamp : Math.max(clipStart, timestamp - clipDuration);
}

function itemShouldRemain<T extends { startTime: number; endTime: number }>(
  item: T,
  clip: ClipTuple
) {
  return item.startTime < clip[0] || item.endTime > clip[1];
}

function getItemsWithClipsRemoved<T extends { startTime: number; endTime: number }>(
  items: T[],
  clips: Clip[]
): T[] {
  return (
    items
      // remove samples from inside of deleted clips
      .filter((item) => {
        return clips.every((clip) => {
          return (
            !clip.deleted ||
            item.startTime < clip.startTime ||
            item.startTime >= clip.endTime ||
            item.endTime > clip.endTime
          );
        });
      })
      .map((item) => ({
        ...item,
        startTime: getRelativeTimestamp(clips, item.startTime),
        endTime: getRelativeTimestamp(clips, item.endTime),
      }))
  );
}

function removeSection<T extends { startTime: number; endTime: number }>(
  items: T[],
  clip: ClipTuple
): T[] {
  const clipDuration = clip[1] - clip[0];
  return items
    .filter((item) => itemShouldRemain(item, clip))
    .map((sample) => {
      return {
        ...sample,
        startTime: adjustTimestamp(sample.startTime, clip[0], clipDuration),
        endTime: adjustTimestamp(sample.endTime, clip[0], clipDuration),
      };
    });
}

function removeAudioClip(dubbedAudio: CaptionPhraseAudio, clip: ClipTuple) {
  if (dubbedAudio.endTime <= clip[0] || dubbedAudio.startTime >= clip[1]) {
    // The phrase is completely outside the clip, so it should remain unchanged
    return dubbedAudio.audioClips;
  }
  // Creates a clip relative to the phrase
  const deletedAudioClip: Clip = {
    startTime: Math.max(clip[0] - dubbedAudio.startTime, 0),
    endTime: Math.min(clip[1] - dubbedAudio.startTime, dubbedAudio.endTime - dubbedAudio.startTime),
    deleted: true,
  };
  // Sanity check: if the phrase has no audio clips, we create a new array
  const currentClips: Clip[] = dubbedAudio.audioClips?.length
    ? dubbedAudio.audioClips
    : [
        {
          startTime: 0,
          endTime: dubbedAudio.endTime - dubbedAudio.startTime,
          deleted: false,
        },
      ];
  // Add the deleted clip to the array
  return addArbitraryDeletedClip(currentClips, deletedAudioClip);
}

export function removeCaptionSection(words: CaptionWord[], clip: ClipTuple) {
  return removeSection(words, clip);
}

export function removeImageItemsSection(items: ImageItem[], clip: ClipTuple) {
  return removeSection(items, clip);
}

/**
 * Removes a clip section from a list of phrases.
 *
 * @param phrases - list of phrases to remove the clip from
 * @param words - list of words with the clips already removed (used to recalculate the texts)
 * @param clip - the clip to remove
 * @returns a transformed `CaptionPhrase` array with the following properties:
 * - Deleted words are removed from phrases
 * - Deleted phrases are removed the array
 * - Timestamps are mapped match the video with the clip removed
 */
export function removePhrasesSection(
  phrases: CaptionPhrase[],
  words: CaptionWord[],
  clip: ClipTuple
): CaptionPhrase[] {
  const clipDuration = clip[1] - clip[0];
  return phrases
    .filter((item) => itemShouldRemain(item, clip))
    .map((phrase) => {
      const phraseWords = words.filter(({ phraseId }) => phraseId === phrase.id);
      return {
        ...phrase,
        text: phraseWords.map((word) => word.text).join(" "),
        startTime: adjustTimestamp(phrase.startTime, clip[0], clipDuration),
        endTime: adjustTimestamp(phrase.endTime, clip[0], clipDuration),
      };
    });
}

/**
 * Removes a clip section from a list of phrase audios.
 *
 * @param phraseAudios - list of phrase audios to remove the clip from
 * @param clip - the clip to remove
 * @returns a transformed `CaptionPhrase` array with the following properties:
 * - Deleted phrase audios are removed the array
 * - Timestamps are mapped match the video with the clip removed
 * - Audio clips are adjusted to match the new phrase timestamps
 */
export function removePhraseAudiosSection(
  phraseAudios: CaptionPhraseAudio[],
  clip: ClipTuple
): CaptionPhraseAudio[] {
  const clipDuration = clip[1] - clip[0];
  return phraseAudios
    .filter((item) => itemShouldRemain(item, clip))
    .map((phraseAudio) => {
      return {
        ...phraseAudio,
        startTime: adjustTimestamp(phraseAudio.startTime, clip[0], clipDuration),
        endTime: adjustTimestamp(phraseAudio.endTime, clip[0], clipDuration),
        audioClips: removeAudioClip(phraseAudio, clip),
      };
    });
}

export function getWordsWithClipsRemoved(
  captionWords: CaptionWord[],
  clips: Clip[]
): CaptionWord[] {
  return getItemsWithClipsRemoved(captionWords, clips);
}
