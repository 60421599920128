import { AnyCanvasRenderingContext2D, CaptionShadowStyle } from "./captionDrawing.types";

const SAFE_INVISIBLE_OFFSET_MARGIN = 100;

/**
 * Class to handle drawing text with stroke and fill styles, including shadow effects.
 *
 * @param ctx - the 2D rendering context.
 * @param shadowStyle - the shadow style to apply to the outer stroke.
 * @param innerShadowStyle - the shadow style to apply to the inner filling.
 * @param strokeWidth - the width of the stroke line, 0 disables it.
 */
class TextDrawer {
  ctx: AnyCanvasRenderingContext2D;
  shadowStyle: CaptionShadowStyle;
  innerShadowStyle: CaptionShadowStyle;
  strokeWidth?: number;

  constructor(
    ctx: AnyCanvasRenderingContext2D,
    shadowStyle: CaptionShadowStyle,
    innerShadowStyle: CaptionShadowStyle,
    strokeWidth?: number
  ) {
    this.ctx = ctx;
    this.shadowStyle = shadowStyle;
    this.innerShadowStyle = innerShadowStyle;
    this.strokeWidth = strokeWidth;
  }

  /**
   * Applies the shadow style to the canvas context, taking into account the rotation of a
   * transform matrix.
   *
   * @remarks This function is necessary because the shadow offset is not rotated by the
   * current transform matrix.
   *
   * @param shadowStyle - the shadow style to apply.
   * @param transformMatrix - the current transform matrix.
   */
  applyShadow(shadowStyle: CaptionShadowStyle, transformMatrix: DOMMatrix) {
    this.ctx.shadowColor = shadowStyle.shadowColor;
    this.ctx.shadowBlur = shadowStyle.shadowBlur;
    // The full formula for transforming coordinates is:
    // x' = a * x + c * y + e
    // y' = b * x + d * y + f
    // But we should not apply terms e and f since shadow offsets are
    // relative to the current point, not the origin.
    this.ctx.shadowOffsetX =
      transformMatrix.a * shadowStyle.shadowOffsetX + transformMatrix.c * shadowStyle.shadowOffsetY;
    this.ctx.shadowOffsetY =
      transformMatrix.b * shadowStyle.shadowOffsetX + transformMatrix.d * shadowStyle.shadowOffsetY;
  }

  drawStroke(text: string, x: number, y: number) {
    this.ctx.shadowColor = "transparent";
    this.ctx.lineWidth = Math.ceil(Math.abs(this.strokeWidth || 0) + 2);
    this.ctx.strokeText(text, x, y);
  }

  drawStrokeShadow(text: string, x: number, y: number) {
    this.applyShadow(this.shadowStyle, this.ctx.getTransform());
    this.ctx.lineWidth = Math.ceil(Math.abs(this.strokeWidth || 0) + 2);
    this.ctx.strokeText(text, x, y);
  }

  drawTextFill(text: string, x: number, y: number) {
    this.ctx.shadowColor = "transparent";
    this.applyShadow(this.innerShadowStyle, this.ctx.getTransform());
    this.ctx.fillText(text, x, y);
  }

  drawTextShadow(text: string, x: number, y: number) {
    const oldTransform = this.ctx.getTransform();
    const newTransform = DOMMatrix.fromMatrix(oldTransform);
    newTransform.e = this.ctx.canvas.width + Math.abs(x) + SAFE_INVISIBLE_OFFSET_MARGIN;
    const safeOutOfViewOffset = newTransform.e - oldTransform.e;
    // Draw the shadows separately so we can apply it to a filled text instead of the stroke.
    // This filled text is drawn outside the canvas and its shadow is translated to the
    // original position.
    // We modify the transform matrix directly se we can offset without taking the current
    // rotation into account.F

    this.ctx.setTransform(newTransform);
    this.applyShadow(this.shadowStyle, oldTransform);
    this.ctx.shadowOffsetX -= safeOutOfViewOffset;
    this.ctx.fillText(text, x, y);
    this.ctx.setTransform(oldTransform);
  }

  /**
   * Draws the text with both fill and stroke styles.
   *
   * @param text - the text to draw.
   * @param x - the starting x coordinate.
   * @param y - the starting y coordinate.
   */
  fillAndStrokeText(text: string, x: number, y: number) {
    const oldLineJoin = this.ctx.lineJoin;
    this.ctx.lineJoin = "round";

    if (this.strokeWidth) {
      this.drawStrokeShadow(text, x, y);
      this.drawStroke(text, x, y);
    }

    this.drawTextShadow(text, x, y);
    this.drawTextFill(text, x, y);

    this.ctx.lineJoin = oldLineJoin;
  }

  /**
   * Draws the text with both fill and stroke styles for unsupported browsers.
   * Firefox (before version 115) and Safari do not support letterSpacing natively, we must emulate
   *
   * @param text - the text to draw.
   * @param x - the starting x coordinate.
   * @param y - the starting y coordinate.
   * @param letterSpacing - the letter spacing value.
   */
  fillAndStrokeTextUnsupportedBrowsers(text: string, x: number, y: number, letterSpacing: number) {
    const coordinates = getCoordinates(this.ctx, text, x, letterSpacing);
    const oldLineJoin = this.ctx.lineJoin;
    this.ctx.lineJoin = "round";

    if (this.strokeWidth) {
      coordinates.forEach((coord, i) => this.drawStrokeShadow(text.charAt(i), coord, y));
      coordinates.forEach((coord, i) => this.drawStroke(text.charAt(i), coord, y));
    }

    coordinates.forEach((coord, i) => this.drawTextShadow(text.charAt(i), coord, y));
    coordinates.forEach((coord, i) => this.drawTextFill(text.charAt(i), coord, y));

    this.ctx.lineJoin = oldLineJoin;
  }
}

/**
 * Measures text taking into account the letter spacing. Made as a workaround for browsers that do
 * not support "letterSpacing" on 2D rendering contexts.
 *
 * @param ctx - the 2D rendering context.
 * @param text - the text to be measured.
 * @param letterSpacing - the letter spacing in pixels.
 * @returns the measured text metrics.
 */
export function measureText(ctx: AnyCanvasRenderingContext2D, text: string, letterSpacing: number) {
  if ("letterSpacing" in ctx) {
    ctx.letterSpacing = `${letterSpacing}px`;
    return ctx.measureText(text);
  }

  if (letterSpacing === 0) {
    return (ctx as AnyCanvasRenderingContext2D).measureText(text);
  }

  // Firefox and Safari do not support letterSpacing natively, use approximation
  const result = (ctx as AnyCanvasRenderingContext2D).measureText(text);
  return {
    ...result,
    width: result.width + Math.max(0, text.length - 1) * letterSpacing,
  };
}

export function getWordFontMetrics(
  context: AnyCanvasRenderingContext2D,
  text: string,
  fontSize: number,
  fontFamily: string
) {
  const oldFont = context.font;
  context.font = `${fontSize}px ${fontFamily}`;
  const metrics = context.measureText(text);
  const ascent = metrics.actualBoundingBoxAscent || 0;
  const descent = metrics.actualBoundingBoxDescent || 0;
  context.font = oldFont;
  return { ascent, descent };
}

/**
 * Calculates the coordinates for each character in the text, taking into account letter spacing.
 *
 * @param ctx - the 2D rendering context.
 * @param text - the text to draw.
 * @param x - the starting x coordinate.
 * @param letterSpacing - the letter spacing in pixels.
 * @returns the coordinates for each character.
 */
function getCoordinates(
  ctx: AnyCanvasRenderingContext2D,
  text: string,
  x: number,
  letterSpacing: number
) {
  // Firefox (before version 115) and Safari do not support letterSpacing natively, emulate
  let remainingWidth = measureText(ctx, text, letterSpacing).width;
  const coordinates = new Array<number>(text.length).fill(0);
  // Since we might draw the same character multiple times, calculate their positions
  // beforehand
  for (let i = 0; i < text.length; i++) {
    coordinates[i] = x;
    const newRemainingWidth = measureText(ctx, text.substring(i + 1), letterSpacing).width;
    // Calculates the normal spacing using the difference between widths with and without
    // the drawn character
    const naturalSpace = remainingWidth - newRemainingWidth;
    x += naturalSpace + letterSpacing;
    remainingWidth = newRemainingWidth;
  }

  return coordinates;
}

/**
 * Draws text with both stroke and fill styles taking letter spacing into account.  Made as a
 * workaround for browsers that do not support "letterSpacing" on 2D rendering contexts.
 *
 * @param ctx - the 2D rendering context.
 * @param text - the text to draw.
 * @param letterSpacing - the letter spacing in pixels.
 * @param x - the starting x coordinate.
 * @param y - the starting y coordinate.
 * @param shadowStyle - the shadow style to apply to the outer stroke.
 * @param innerShadowStyle - the shadow style to apply to the inner filling.
 * @param strokeWidth - the width of the stroke line, 0 disables it.
 */
export function drawText(
  ctx: AnyCanvasRenderingContext2D,
  text: string,
  letterSpacing: number,
  x: number,
  y: number,
  shadowStyle: CaptionShadowStyle,
  innerShadowStyle: CaptionShadowStyle,
  strokeWidth?: number
) {
  const textDrawer = new TextDrawer(ctx, shadowStyle, innerShadowStyle, strokeWidth);
  if ("letterSpacing" in textDrawer.ctx) {
    textDrawer.ctx.letterSpacing = `${letterSpacing}px`;
    textDrawer.fillAndStrokeText(text, x, y);
  } else if (letterSpacing === 0) {
    textDrawer.fillAndStrokeText(text, x, y);
  } else {
    textDrawer.fillAndStrokeTextUnsupportedBrowsers(text, x, y, letterSpacing);
  }
}
