import type { BackendServicesClient } from "~/context/BackendServicesContext";

import type { AISceneDataWithStyles, AISceneWithStyle } from "../AIScenes.types";
import { getTitleFromScene } from "../services/SceneData/getTitleFromScene";

export async function populateSceneTitles(
  client: BackendServicesClient,
  sceneDataWithStyles: AISceneDataWithStyles,
  languageCode: string
): Promise<AISceneDataWithStyles> {
  const populateTitlePromises = sceneDataWithStyles.scenes.map((s) =>
    populateSceneTitleForScene(client, s, languageCode)
  );
  await Promise.all(populateTitlePromises);

  return sceneDataWithStyles;
}

export async function populateSceneTitleForScene(
  client: BackendServicesClient,
  scene: AISceneWithStyle,
  languageCode: string
) {
  const animations = Array.isArray(scene.style.animation)
    ? scene.style.animation
    : [scene.style.animation];

  const imageFont = scene.style.imageFont;

  const needsTitle = animations?.some((item) => Boolean(item?.titleReplacement)) || imageFont;
  if (needsTitle) {
    const wordLimit = imageFont ? 2 : undefined;
    scene.title = await getTitleFromScene(client, scene, languageCode, wordLimit);
  }
}
