import {
  AnimationBox,
  AnimationStage,
  AnimationState,
  AnimationStyle,
  AnimationTarget,
  AnimationTimeInterval,
  AnimationTimes,
  CaptionAnimationAuxMetrics,
  CaptionAnimationModifiers,
  CaptionAnimationParameters,
  ClassicImageAnimations,
  ImageAnimationAuxMetrics,
  ImageAnimationModifiers,
  ImageAnimations,
  ImageAnimationState,
  WordBackgroundAnimationAuxMetrics,
} from "./animationUtils.types";
import { calculateMovementAutoMotionKnots } from "./autoMotion";
import { ForegroundAnimationStyle } from "./backgroundLayer.types";
import { CaptionStylePreset } from "./captionStyle.types";
import { calculateSplineControlPoints, cubicBezier, cubicSplinePoint } from "./utils/bezier";
import { PixelMapping } from "./utils/webgl/webgl.types";

/**
 * Default duration of all animations in seconds
 * @constant
 */
const ANIMATION_DURATION_SECONDS = 0.1;
const MINIMUM_ANIMATED_WORD_DURATION = 0.15;

function getImageAnimationDuration(animationStyle: AnimationStyle): number {
  switch (animationStyle) {
    case AnimationStyle.animationStyleSlideIn:
    case AnimationStyle.animationStyleSlideOut:
      return 0.12;
    case AnimationStyle.animationStyleScaleIn:
      return ANIMATION_DURATION_SECONDS;
    default:
      return 0;
  }
}

function getCaptionAnimationDuration(animationStyle: AnimationStyle): number {
  switch (animationStyle) {
    case AnimationStyle.animationStyleOpacity:
    case AnimationStyle.animationStyleSlideIn:
    case AnimationStyle.animationStyleSlideUp:
    case AnimationStyle.animationStyleJump:
      return ANIMATION_DURATION_SECONDS;
    case AnimationStyle.animationStyleScaleIn:
    case AnimationStyle.animationStylePopIn:
    case AnimationStyle.animationStyleZoom:
      return ANIMATION_DURATION_SECONDS * 1.5;
    default:
      return 0;
  }
}

function getForegroundAnimationDuration(animationStyle: ForegroundAnimationStyle): number {
  switch (animationStyle) {
    case "swipe-up":
      return 0.25;
    default:
      return 0;
  }
}

function isCaptionAnimated(timeInterval: AnimationTimeInterval): boolean {
  return timeInterval.endTime - timeInterval.startTime >= MINIMUM_ANIMATED_WORD_DURATION;
}

export function getCaptionAnimationTimes(
  timeInterval: AnimationTimeInterval,
  animationStyle: AnimationStyle,
  hasExitAnimation: boolean = false
): AnimationTimes {
  const { startTime, endTime } = timeInterval;
  if (
    isCaptionAnimated(timeInterval) &&
    animationStyle !== AnimationStyle.animationStyleImmediate
  ) {
    const duration = getCaptionAnimationDuration(animationStyle);
    return {
      startAnimation: {
        startTime,
        endTime: startTime + duration,
      },
      endAnimation: {
        startTime: hasExitAnimation ? endTime - duration : endTime,
        endTime,
      },
    };
  } else {
    return {
      startAnimation: {
        startTime,
        endTime: startTime,
      },
      endAnimation: {
        startTime: endTime,
        endTime,
      },
    };
  }
}

export function getCaptionAnimationStage(
  timeInterval: AnimationTimeInterval,
  animationStyle: AnimationStyle,
  hasExitAnimation: boolean,
  timestamp: number
): AnimationState {
  const times = getCaptionAnimationTimes(timeInterval, animationStyle, hasExitAnimation);
  if (timestamp >= times.startAnimation.startTime && timestamp < times.startAnimation.endTime) {
    return {
      factor:
        (timestamp - times.startAnimation.startTime) /
        (times.startAnimation.endTime - times.startAnimation.startTime),
      stage: "starting",
    };
  } else if (timestamp >= times.endAnimation.startTime && timestamp < times.endAnimation.endTime) {
    return {
      factor:
        1 -
        (timestamp - times.endAnimation.startTime) /
          (times.endAnimation.endTime - times.endAnimation.startTime),
      stage: "ending",
    };
  } else {
    return {
      factor: 1,
      stage: "none",
    };
  }
}

export function getCaptionAnimationParameters(
  { factor, stage }: AnimationState,
  animationStyle: AnimationStyle,
  auxMetrics: CaptionAnimationAuxMetrics
): CaptionAnimationModifiers {
  const animationParameters: CaptionAnimationModifiers = {
    opacity: 1,
    offset: { x: 0, y: 0 },
    sizeFactor: 1,
  };
  if (stage !== "starting" || factor === 1) {
    return animationParameters;
  }
  switch (animationStyle) {
    case AnimationStyle.animationStyleOpacity:
      animationParameters.opacity = cubicBezier(factor, 0, 0, 1, 1);
      break;
    case AnimationStyle.animationStyleSlideIn:
      animationParameters.opacity = cubicBezier(factor, 0, 0, 1, 1);
      animationParameters.offset.x = cubicBezier(factor, 1, 1, 0, 0) * 10 * auxMetrics.baseSize;
      break;
    case AnimationStyle.animationStyleSlideUp:
      animationParameters.opacity = cubicBezier(factor, 0, 0, 1, 1);
      animationParameters.offset.y = cubicBezier(factor, 1, 1, 0, 0) * 5 * auxMetrics.baseSize;
      break;
    case AnimationStyle.animationStyleScaleIn:
      animationParameters.opacity = cubicBezier(factor, 0, 0, 1, 1);
      animationParameters.sizeFactor = cubicBezier(factor, 0.3, 0.3, 1, 1);
      animationParameters.offset.x =
        ((1 - animationParameters.sizeFactor) * auxMetrics.elementWidth) / 2;
      animationParameters.offset.y =
        ((1 - animationParameters.sizeFactor) * auxMetrics.elementHeight) / 2;
      break;
    case AnimationStyle.animationStylePopIn:
      animationParameters.opacity = cubicBezier(Math.min(factor * 2, 1), 0, 0, 1, 1);
    // falls through
    case AnimationStyle.animationStyleZoom:
      animationParameters.sizeFactor = 1.1 - Math.pow((factor - 0.5) * 2, 2) * 0.1;
      animationParameters.offset.x =
        ((1 - animationParameters.sizeFactor) * auxMetrics.elementWidth) / 2;
      animationParameters.offset.y =
        ((1 - animationParameters.sizeFactor) * auxMetrics.elementHeight) / 2;
      break;
  }
  return animationParameters;
}

/**
 * Get the animation parameters for a word background
 * @param animationState - The current state of the animation
 * @param animationStyle - The style of the animation
 * @param auxMetrics - Auxiliary metrics for the animation
 * @returns The animation parameters for the word background box
 */
export function getWordBackgroundAnimationParameters(
  animationState: AnimationState,
  animationStyle: AnimationStyle,
  auxMetrics: WordBackgroundAnimationAuxMetrics
): CaptionAnimationParameters {
  const animationParameters: CaptionAnimationParameters = {
    alpha: 1,
    outsetFactor: 1,
    boxOffset: { x: 0, y: 0, width: 0, height: 0 },
  };
  const { factor, stage } = animationState;
  if (factor === 1) {
    return animationParameters;
  }
  switch (animationStyle) {
    case AnimationStyle.animationStyleOpacity:
      animationParameters.alpha = cubicBezier(factor, 0, 0, 1, 1);
      break;
    case AnimationStyle.animationStylePopIn:
      animationParameters.alpha = cubicBezier(Math.min(factor * 2, 1), 0, 0, 1, 1);
    // falls through
    case AnimationStyle.animationStyleZoom:
      animationParameters.outsetFactor =
        stage === "starting" ? 1.1 - Math.pow((factor - 0.5) * 2, 2) * 0.1 : 1;
      break;
    case AnimationStyle.animationStyleScaleIn:
      animationParameters.alpha = cubicBezier(factor, 0, stage === "starting" ? 1 : 0, 1, 1);
      animationParameters.outsetFactor = stage === "starting" ? cubicBezier(factor, 0, 0, 1, 1) : 1;
      break;
    case AnimationStyle.animationStyleSlideIn:
      if (stage === "starting" && auxMetrics.previousWordOffset) {
        animationParameters.boxOffset = {
          x: cubicBezier(
            factor,
            auxMetrics.previousWordOffset.x,
            auxMetrics.previousWordOffset.x,
            0,
            0
          ),
          y: cubicBezier(
            factor,
            auxMetrics.previousWordOffset.y,
            auxMetrics.previousWordOffset.y,
            0,
            0
          ),
          width: cubicBezier(
            factor,
            auxMetrics.previousWordOffset.width,
            auxMetrics.previousWordOffset.width,
            0,
            0
          ),
          height: cubicBezier(
            factor,
            auxMetrics.previousWordOffset.height,
            auxMetrics.previousWordOffset.height,
            0,
            0
          ),
        };
      }
      break;
    case AnimationStyle.animationStyleJump:
      if (stage === "starting" && auxMetrics.previousWordOffset) {
        animationParameters.boxOffset = {
          x: cubicBezier(
            factor,
            auxMetrics.previousWordOffset.x,
            auxMetrics.previousWordOffset.x,
            0,
            0
          ),
          y: cubicBezier(
            factor,
            auxMetrics.previousWordOffset.y,
            auxMetrics.previousWordOffset.y + auxMetrics.baseSize * 0.3,
            auxMetrics.baseSize * 0.3,
            0
          ),
          width: cubicBezier(
            factor,
            auxMetrics.previousWordOffset.width,
            auxMetrics.previousWordOffset.width,
            0,
            0
          ),
          height: cubicBezier(
            factor,
            auxMetrics.previousWordOffset.height,
            auxMetrics.previousWordOffset.height - auxMetrics.baseSize * 0.6,
            -auxMetrics.baseSize * 0.6,
            0
          ),
        };
      }
      break;
  }
  return animationParameters;
}

/**
 * Get the animation parameters for a line background
 * @param animationState - The current state of the animation
 * @param stylePreset - The style preset for the caption
 * @returns The animation parameters for the line background box
 */
export function getLineBackgroundAnimationParameters(
  animationState: AnimationState,
  stylePreset: CaptionStylePreset
): CaptionAnimationParameters {
  if (
    ![AnimationTarget.animationTargetLine, AnimationTarget.animationTargetWord].includes(
      stylePreset.animationTarget
    )
  ) {
    return {
      alpha: 1,
      outsetFactor: 1,
      boxOffset: { x: 0, y: 0, width: 0, height: 0 },
    };
  }
  return getWordBackgroundAnimationParameters(animationState, stylePreset.animationStyle, {
    baseSize: 0,
  });
}

export function generateSeedFromPosition(x: number, y: number): number {
  // Ensure x and y are within the 0 to 1 range
  x = Math.max(0, Math.min(1, x));
  y = Math.max(0, Math.min(1, y));

  // Convert x and y to integers between 0 and 100000
  const newX = Math.floor(x * 100000);
  const newY = Math.floor(y * 100000);

  // Combine x and y using prime numbers
  const minPrime = 100003;
  const maxPrime = 2147483647;
  const seed = (newX * minPrime + newY) % maxPrime;

  return seed;
}

export function getImageAnimationParameters(
  { factor, stage, startTime, endTime }: ImageAnimationState,
  animationStyle: AnimationStyle,
  auxMetrics: ImageAnimationAuxMetrics
): ImageAnimationModifiers {
  const animationParameters: ImageAnimationModifiers = {
    opacity: 1,
    imageBox: auxMetrics.imageBox,
    sizeFactor: 1,
  };
  if (stage === "none") {
    return animationParameters;
  }
  switch (animationStyle) {
    case AnimationStyle.animationStyleSlideIn:
      animationParameters.opacity = cubicBezier(factor, 0, 0, 1, 1);
      animationParameters.imageBox.x =
        2 * animationParameters.imageBox.x -
        animationParameters.imageBox.x * cubicBezier(factor, 0, 0, 1, 1);
      break;
    case AnimationStyle.animationStyleSlideOut:
      animationParameters.opacity = cubicBezier(factor, 1, 1, 0, 0);
      animationParameters.imageBox.x =
        animationParameters.imageBox.x -
        animationParameters.imageBox.x * cubicBezier(factor, 0, 0, 1, 1);
      break;
    case AnimationStyle.animationStyleScaleIn:
      animationParameters.opacity = cubicBezier(factor, 0, 0, 1, 1);
      animationParameters.imageBox.width *= cubicBezier(factor, 0, 0, 1, 1);
      animationParameters.imageBox.height *= cubicBezier(factor, 0, 0, 1, 1);
      break;
    case AnimationStyle.animationStyleSmallMovement:
      if (auxMetrics.imageBox) {
        let x = auxMetrics.imageBox.x;
        let y = auxMetrics.imageBox.y;
        const seed = generateSeedFromPosition(x, y);
        const maxTime = Math.max(endTime - startTime, 0.5);
        const knots = calculateMovementAutoMotionKnots(seed, maxTime, {
          enabled: true,
          speed: 1.2,
          offset: { x: 50, y: 50 },
        });
        const controlPoints = calculateSplineControlPoints(knots);
        if (knots.length && controlPoints.length && factor > 0) {
          const point = cubicSplinePoint(factor, knots, controlPoints);
          x += point.x;
          y += point.y;
        }
        animationParameters.imageBox = {
          x,
          y,
          width: auxMetrics.imageBox.width,
          height: auxMetrics.imageBox.height,
        };
      }
      break;
  }
  return animationParameters;
}

function getImageAnimationStage(
  timeInterval: AnimationTimeInterval,
  animations: ImageAnimations,
  timestamp: number
): ImageAnimationState {
  if (animations.type === "pag") {
    throw new Error("PAG animations are not supported");
  }
  const { startTime, endTime } = timeInterval;
  const { animationIn, animationOut, activeAnimation } = animations;
  const currentDuration = timestamp - startTime;
  const imageDuration = endTime - startTime;
  const durationIn = getImageAnimationDuration(animationIn);
  const durationOut = getImageAnimationDuration(animationOut);
  const durationActive = activeAnimation ? imageDuration - durationIn - durationOut : 0;

  if (currentDuration < durationIn) {
    return {
      stage: "starting",
      factor: currentDuration / durationIn,
      startTime: startTime,
      endTime: startTime + durationIn,
    };
  } else if (currentDuration < durationIn + durationActive) {
    return {
      stage: "hold",
      factor: (currentDuration - durationIn) / durationActive,
      startTime: startTime + durationIn,
      endTime: startTime + durationIn + durationActive,
    };
  }
  return {
    stage: "ending",
    factor: (currentDuration - durationIn - durationActive) / durationOut,
    startTime: endTime - durationOut,
    endTime: endTime,
  };
}

function getImageAnimationStyle(
  stage: AnimationStage,
  animations: ClassicImageAnimations
): AnimationStyle {
  switch (stage) {
    case "starting":
      return animations.animationIn;
    case "ending":
      return animations.animationOut;
    case "hold":
      return animations.activeAnimation;
    default:
      return AnimationStyle.animationStyleImmediate;
  }
}

export function getImageAnimation(
  timeInterval: AnimationTimeInterval,
  timestamp: number,
  animations: ImageAnimations,
  imageBox: AnimationBox
) {
  if (animations.type === "pag") {
    throw new Error("PAG animations are not supported");
  }
  const animationFactor = getImageAnimationStage(timeInterval, animations, timestamp);
  const animationStyle = getImageAnimationStyle(animationFactor.stage, animations);
  const animationParameters = getImageAnimationParameters(animationFactor, animationStyle, {
    imageBox,
    timeInterval,
    timestamp,
  });

  return animationParameters;
}

export function getAnimatedForegroundPixelMapping(
  pixelMapping: PixelMapping,
  animation: ForegroundAnimationStyle,
  startTime: number,
  timestamp: number
): PixelMapping {
  const animationDuration = getForegroundAnimationDuration(animation);
  const factor = (timestamp - startTime) / animationDuration;
  if (factor < 0 || factor >= 1) {
    return pixelMapping;
  }

  switch (animation) {
    case "swipe-up": {
      return {
        ...pixelMapping,
        to: {
          left: pixelMapping.to.left,
          top: 1 - factor * 1,
          right: pixelMapping.to.right,
          bottom: 2 - factor * 1,
        },
      };
    }
  }
}
