export interface SignInProps {
  areActionsBlocked?: boolean;
  hideTopBar?: boolean;
  title?: string;
  version?: string;
  onAppleClick?: () => Promise<void>;
  onGoogleClick?: () => Promise<void>;
  onMicrosoftClick?: () => Promise<void>;
  onLegalCenterClick?: () => void;
  onContinueAnotherWayClick?: () => void;
}

export enum LoginStep {
  Initial,
  EnterPhoneNumber,
  EnterCode,
}
