import React, { useEffect, useState } from "react";

import { useBackendServicesClient } from "~/context/BackendServicesContext";
import { useAuthState } from "~/context/CaptionsAuthContext";
import { checkVerificationCode, sendVerificationCode } from "~/services/Auth";
import { regexPhoneNumber } from "~/utils/glob";

import { Button } from "../Button";
import { CodeInput } from "../CodeInput/CodeInput";
import { LoginStep } from "../SignIn/SignIn.types";
import { Spinner } from "../Spinner";
import { Text } from "../Text";
import { useToast } from "../Toast";

import * as S from "./SignInByPhone.styles";
import { EnterCodeProps, ErrorCode, ErrorCodeMessages } from "./SignInByPhone.types";

export default function EnterCode({
  setStep,
  phoneNumber,
  selectedCode,
  selectedRegion,
}: EnterCodeProps) {
  const codeSize = 6;
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState({
    resend: false,
    verify: false,
  });
  const [isError, setIsError] = useState(false);
  const client = useBackendServicesClient();
  const toast = useToast();
  const [timeLeftToResend, setTimeLeftToResend] = useState(60);
  const { signIn } = useAuthState();

  useEffect(() => {
    if (!timeLeftToResend) {
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeftToResend(timeLeftToResend - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeftToResend]);

  useEffect(() => {
    if (isError) {
      setIsError(false);
    }
  }, [code]);

  const handleError = (error?: ErrorCode, message?: string) => {
    const defaultMessage = message || "Unable to sign in. Please try again later.";
    toast.add((error ? ErrorCodeMessages?.[error] : defaultMessage) || defaultMessage, {
      severity: "error",
    });
  };

  const handleResend = async () => {
    setIsLoading((data) => ({
      ...data,
      resend: true,
    }));
    setTimeLeftToResend(60);
    setCode("");
    const sendVerificationCodeResponse = await sendVerificationCode(client, {
      region: selectedRegion,
      phoneNumber: regexPhoneNumber(phoneNumber),
    });

    setIsLoading((data) => ({
      ...data,
      resend: false,
    }));

    if (sendVerificationCodeResponse.success === false) {
      handleError(undefined, "Please enter a valid phone number to receive a code.");
    }
  };

  const handleSubmit = async () => {
    setIsLoading((data) => ({
      ...data,
      verify: true,
    }));
    const checkVerificationCodeResponse = await checkVerificationCode(client, {
      region: selectedRegion,
      phoneNumber: regexPhoneNumber(phoneNumber),
      code,
    })
      .catch((error) => {
        setIsError(true);
        handleError(error?.response?.data?.error);
      })
      .finally(() => {
        setIsLoading((data) => ({
          ...data,
          verify: false,
        }));
      });

    const { accessToken, refreshToken, userId, expiresIn } = checkVerificationCodeResponse ?? {};

    if (accessToken && refreshToken && userId && expiresIn) {
      await signIn("credentials", {
        accessToken,
        refreshToken,
        expiresIn: `${expiresIn}`,
        userId,
      });
    }
  };

  const counter = () => {
    const time = timeLeftToResend.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });

    if (!timeLeftToResend) {
      return "";
    }
    return `00:${time}`;
  };

  return (
    <>
      <S.Top>
        <S.IconWrapper>🔐</S.IconWrapper>
        <S.InfoWrapper>
          <Text variant="heading-3" color="white">
            Enter code
          </Text>
          <Text variant="body-1" color="grey-500">
            Check your messages and enter the code sent to{" "}
            <Text color="white">{`${selectedCode} ${phoneNumber}`}</Text>
          </Text>
        </S.InfoWrapper>
      </S.Top>
      <S.Bottom>
        <form
          onSubmit={(e) => {
            e.preventDefault(); // Prevent default form submission behavior
            handleSubmit(); // Call your existing submit handler
          }}
        >
          <CodeInput size={codeSize} setCode={setCode} isError={isError} />
          <Button
            variant="primary"
            fullWidth
            type="submit" // Ensure this button triggers form submission
            disabled={code.length < codeSize || isLoading.verify}
          >
            {isLoading.verify ? <Spinner theme="white" /> : "Verify Code"}
          </Button>
        </form>
        <Button
          variant="secondary"
          fullWidth
          onClick={handleResend}
          disabled={!!timeLeftToResend || isLoading.resend}
        >
          {isLoading.resend ? <Spinner theme="white" /> : `Resend ${counter()}`}
        </Button>
        <Button
          variant="tertiary"
          fullWidth
          onClick={() => {
            setStep?.(LoginStep.EnterPhoneNumber);
          }}
        >
          Go back
        </Button>
      </S.Bottom>
    </>
  );
}
