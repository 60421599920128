import { BackendServicesClient } from "~/context/BackendServicesContext";

import {
  CheckVerificationCodeRequest,
  CheckVerificationCodeResponse,
  GetSupportedCountriesResponse,
  SendVerificationCodeRequest,
  SendVerificationCodeResponse,
} from "./";

export async function sendVerificationCode(
  client: BackendServicesClient,
  payload: SendVerificationCodeRequest
) {
  return await client
    .post<SendVerificationCodeResponse>("auth/v1/send-verification-code", payload, {
      requiresAuthentication: false,
    })
    .then((res) => {
      return res?.data;
    });
}

export async function checkVerificationCode(
  client: BackendServicesClient,
  payload: CheckVerificationCodeRequest
) {
  return await client
    .post<CheckVerificationCodeResponse>("auth/v1/check-verification-code", payload, {
      requiresAuthentication: false,
    })
    .then((res) => {
      return res?.data;
    });
}

export async function getSupportedCountries(client: BackendServicesClient) {
  return await client
    .get<GetSupportedCountriesResponse>("auth/v1/get-supported-countries", {
      requiresAuthentication: false,
    })
    .then((res) => {
      return res?.data;
    });
}
