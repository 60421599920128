import { styled } from "~/theme";

import { Button } from "../Button";
import CaptionsLogoSmall from "../icons/CaptionsLogoSmall";
import { Text } from "../Text";

const circleGradient =
  "radial-gradient(50% 50% at 50% 50%, rgba(35, 123, 255, 0.80) 0%, rgba(35, 123, 255, 0.00) 100%), #28065E;";

export const SignInLayoutContainer = styled("div", {
  alignItems: "center",
  backgroundColor: "$grey-950",
  display: "grid",
  height: "100%",
  justifyContent: "center",
  gridTemplateColumns: "1fr 1fr",
  minHeight: "calc(100vh - 48px)",
  "@bp2": {
    gridTemplateColumns: "1fr",
  },
  variants: {
    hideTopBar: {
      true: {
        minHeight: "100vh",
      },
    },
  },
});

export const SignInLeftPanel = styled("div", {
  alignItems: "flex-start",
  backgroundColor: "$grey-950",
  display: "flex",
  flexDirection: "column",
  gap: "125px",
  height: "100%",
  paddingBottom: "$56",
  paddingLeft: "$56",
  paddingRight: "0",
  paddingTop: "$56",
  position: "relative",
  overflow: "hidden",
  "@bp2": {
    display: "none",
  },
});

export const LeftPanelLogo = styled(CaptionsLogoSmall, {
  height: "32px",
  color: "white",
  width: "auto",
  zIndex: 1,
});

export const LeftPanelImage = styled("img", {
  height: "auto",
  maxWidth: "100%",
  position: "absolute",
  top: "25%",
  left: "10%",
  zIndex: 1,
});

export const LeftPanelGradient = styled("div", {
  height: "100%",
  width: "100%",
  position: "absolute",
  background: circleGradient,
  opacity: "0.2",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 0,
});

export const RightPanel = styled("div", {
  display: "grid",
  gridTemplateRows: "1fr auto 1fr",
  height: "100%",
  justifyContent: "center",
  paddingBottom: "$48",
  position: "relative",
  borderLeft: "1px solid $grey-800",
});

export const BackButton = styled(Button, {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: 25,
  left: 25,
  width: 24,
  height: "24px !important",
  transform: "rotate(180deg)",
  padding: "0 !important",
  borderRadius: 6,
});

export const Footer = styled("div", {
  alignItems: "center",
  alignSelf: "end",
  display: "flex",
  flexDirection: "column",
  gap: "$8",
  gridRow: "3 / 3",
  textAlign: "center",
  justifyContent: "center",
});

export const LegalCenterLink = styled(Text, {
  cursor: "pointer",
});

export const Container = styled("div", {
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  gap: "$48",
  gridRow: "2 / 3",
  maxWidth: "362px",
  textAlign: "center",
  width: "100%",
});

export const Top = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "$8",
  maxWidth: "294px",
});

export const ButtonsContainer = styled("div", {
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  gap: "$8",
  justifyContent: "center",
  maxWidth: "362px",
  width: "fit-content",
  "& > button": {
    width: "100%",
  },
});

export const Separator = styled("div", {
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  borderTop: "1px solid $grey-800",
  paddingTop: "$8",
});

export const Apple = styled(Button, {
  "--button-background-color": "transparent",
  "--smooth-border-color": "$colors$grey-700 !important",
  "&, > *": {
    color: "$grey-100 !important",
  },
  "&:disabled > *": {
    color: "$grey-600 !important",
  },
});

export const Google = styled(Button, {
  "--button-background-color": "transparent",
  "--smooth-border-color": "$colors$grey-700 !important",
  "&, > *": {
    color: "$grey-100 !important",
  },
  fontWeight: "$demi-bold",
  "&:disabled > *": {
    color: "$grey-600 !important",
  },
});
