import { ColorCssType, ColorDecimalType } from "./types";

export function hexToColorDecimal(hex: string): ColorDecimalType {
  if (!hex.startsWith("#")) {
    hex = `#${hex}`;
  }

  const redHex = hex.slice(1, 3);
  const greenHex = hex.slice(3, 5);
  const blueHex = hex.slice(5, 7);
  const alphaHex = hex.length === 9 ? hex.slice(7, 9) : "FF";
  const hexParts = [redHex, greenHex, blueHex, alphaHex];
  const [red, green, blue, alpha] = hexParts.map((part) => parseInt(part, 16) / 255);
  return { red, green, blue, alpha };
}

export function hexToColorCss(hex: string): ColorCssType {
  const colorDecimal = hexToColorDecimal(hex);

  return {
    red: colorDecimal.red * 255,
    green: colorDecimal.green * 255,
    blue: colorDecimal.blue * 255,
    alpha: colorDecimal.alpha * 255,
  };
}
