import { createEventDefs } from "./event-def";

export const ProjectEvents = createEventDefs<{
  loaded: {
    project_id: string;
    version: string;
    migration_result: "migrated" | "up-to-date" | "unsupported";
    migrated_to_version?: string;
  };
  open: {
    project_id: string;
    project_type: string;
    latency?: number;
  };
}>({
  namespacePrefix: "project_",
});
