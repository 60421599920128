import { addDays } from "date-fns";

export const socialPlanCompleteResponse = () => ({
  state: "COMPLETE",
  data: {
    settings: {
      videosPerWeek: 2,
      autoPostEnabled: true,
      connections: {
        instagram: {
          autoPostEnabled: true,
          userId: "123",
          username: "@captions",
        },
      },
    },
    videos: [
      {
        id: "video_a",
        state: "GENERATED",
        title: "Meet the founders",
        prompt: "Introduce the founders of Soul Dental",
        script:
          "Hi there! I'm Dr. Yuliya Alterman.\n\nAnd I'm Dr. Alexandra Yungelson.\n\nWelcome to Soul Dental!\n\nYou might be wondering how we ended up running a multi-specialty dental practice together in the heart of Manhattan.\n\nWell, it all started on our very first day of dental school. We met, became fast friends, and quickly realized we shared a vision for the future of dentistry.\n\nThat vision? To create a practice that offers top-notch, comprehensive care while really connecting with our patients.",
        media: [
          {
            id: "ma",
            url: "",
          },
        ],
        creators: [
          {
            id: "x8HbMFHJmLSbaG2OXprH",
            variantId: "x8HbMFHJmLSbaG2OXprH",
            thumbnails: [
              {
                width: 2160,
                url: "https://captions-cdn.xyz/ai-avatars/ugc/v2/O9bAfs9ZBVc9NMgPPRcP/thumb.jpg",
                height: 3840,
              },
            ],
          },
        ],
        aiEditStyle: "cinematic",
        scheduledDate: addDays(new Date(), 1).toISOString(),
        thumbnailImage: "",
        pendingOperationId: null,
        socialPost: {
          description:
            "Meet the besties behind your favorite NYC smile creators! 😁✨ Dr. Yuliya & Dr. Alexandra are bringing fresh vibes to dentistry at Soul Dental. Swipe to see how we're revolutionizing your dental experience!",
          hashtags: [
            "#NYCDentist",
            "#WomenInDentistry",
            "#DentalCare",
            "#ManhattanSmiles",
            "#HealthyTeeth",
            "#SmileConfidence",
          ],
        },
        videoUrl: "",
      },
      {
        id: "video_b",
        state: "GENERATED",
        title: "Our Services",
        prompt: "",
        script: "",
        media: [
          {
            id: "ma",
            url: "",
          },
        ],
        creators: [
          {
            id: "x8HbMFHJmLSbaG2OXprH",
            variantId: "x8HbMFHJmLSbaG2OXprH",
            thumbnails: [
              {
                width: 2160,
                url: "https://captions-cdn.xyz/ai-avatars/ugc/v2/O9bAfs9ZBVc9NMgPPRcP/thumb.jpg",
                height: 3840,
              },
            ],
          },
        ],
        aiEditStyle: "cinematic",
        scheduledDate: addDays(new Date(), 4).toISOString(),
        thumbnailImage: "",
        pendingOperationId: null,
        socialPost: {
          description: "",
          hashtags: [],
        },
        videoUrl: "",
      },
      {
        id: "video_c",
        state: "DRAFT",
        title: "Office Tour",
        prompt: "Intro office tour video",
        script:
          "Hello, New York! Dr. Alexandra here from Soul Dental. Today, I want to chat about something that makes us really special: our specialty approach to dental care. Now, you might be wondering, Well, let me break it down for you. At Soul Dental, we believe in comprehensive care. That means we offer everything from routine check-ups to complex procedures, all under one roof. No need to run all over the city to different specialists – we've got you covered right here.",
        media: [
          {
            id: "ma",
            url: "",
          },
        ],
        creators: [
          {
            id: "x8HbMFHJmLSbaG2OXprH",
            variantId: "x8HbMFHJmLSbaG2OXprH",
            thumbnails: [
              {
                width: 2160,
                url: "https://captions-cdn.xyz/ai-avatars/ugc/v2/O9bAfs9ZBVc9NMgPPRcP/thumb.jpg",
                height: 3840,
              },
            ],
          },
        ],
        aiEditStyle: "cinematic",
        scheduledDate: addDays(new Date(), 9).toISOString(),
        thumbnailImage: "",
        pendingOperationId: null,
      },
    ],
  },
});
