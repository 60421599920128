import useLatest from "@react-hook/latest";
import type { CaptionStyle } from "captions-engine";
import { CaptionStylePreset } from "captions-engine";
import { useCallback, useEffect, useState } from "react";

import { NewCaptionSettings, Effects, FilmStripPath, Project } from "~/database/database.types";
import { CaptionSettingsRepository } from "~/database/repo/CaptionSettingsRepository";
import { CaptionsRepository } from "~/database/repo/CaptionsRepository";
import { EffectsRepository } from "~/database/repo/EffectsRepository";
import { ProjectRepository } from "~/database/repo/ProjectRepository";
import { Transition, TransitionEffect } from "~/database/transition.types";
import { useAuthStore } from "~/stores/auth";

import { loadProjectFromStorage } from "../data/load-project";
import { ProjectEntityBag } from "../data/types";
import { CaptionPhrase } from "../services/Dubbing";
import { CaptionPhraseAudio, CaptionWord } from "../utils/captionProcessing";

export type ProjectLoadedCallback = (entities: ProjectEntityBag) => Promise<void> | void;

export function useProjectPersistence(projectId: string | null, onLoaded?: ProjectLoadedCallback) {
  const [project, setProject] = useState<Project | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<boolean>(false);
  const onLoadedCallback = useLatest(onLoaded);

  // this was previously built into the useProjectRepository hook
  // but we need to check for userId now manually
  // to make sure the auth user is populated
  // in the future we can migrate this to `useQuery` to load
  // all of the data.
  const userId = useAuthStore((state) => state.user?.userId);

  const isReady = !!userId && projectId != null;

  const loadEntities = useCallback(async () => {
    if (isReady) {
      try {
        setLoading(true);
        setLoaded(false);

        const result = await loadProjectFromStorage({ projectId });

        if (result.type === "loaded") {
          const {
            project,
            captions,
            untranslatedCaptions,
            effects,
            captionSettings,
            timelineProject,
          } = result.entities;

          // Finished loading, set the project state variable and calls the final callback
          setProject(project);
          await onLoadedCallback.current?.({
            project,
            captions,
            untranslatedCaptions,
            effects,
            captionSettings,
            timelineProject,
          });
        } else if (result.type === "not-found") {
          setProject(null);
        } else if (result.type === "unsupported") {
          setProject(null);
        }

        setLoaded(true);
      } finally {
        setLoading(false);
      }
    }
  }, [isReady, projectId, onLoadedCallback]);

  const addCaptions = useCallback(
    async (
      sourceLanguageCode: string,
      transcriptLanguageCode: string,
      words: CaptionWord[],
      phrases: CaptionPhrase[],
      phraseAudios?: CaptionPhraseAudio[],
      captionSettings?: NewCaptionSettings
    ) => {
      if (!isReady) {
        return;
      }
      if (project?.captionsId) {
        // Updating an already-existing captions entity
        // (mainly happens during debug)
        await CaptionsRepository.updateCaptions({
          id: project.captionsId,
          projectId,
          languageCode: transcriptLanguageCode,
          words,
          phrases,
          phraseAudios,
          videoClipsApplied: true,
        });
        await ProjectRepository.updateProject(projectId, {
          sourceFileLanguageCode: sourceLanguageCode,
        });
      } else {
        // Add new captions entry to database
        const captionsId = await CaptionsRepository.addCaptions({
          projectId,
          languageCode: transcriptLanguageCode,
          words,
          phrases,
          phraseAudios,
          videoClipsApplied: true,
        });

        let captionSettingsId: string | undefined = project?.captionSettingsId;
        if (captionSettings && !captionSettingsId) {
          captionSettingsId = await CaptionSettingsRepository.addCaptionSettings({
            ...captionSettings,
            projectId,
          });
        } else if (captionSettings && captionSettingsId) {
          await CaptionSettingsRepository.updateCaptionSettings(captionSettingsId, captionSettings);
        }

        // Update project metadata
        const updatedFields = {
          sourceFileLanguageCode: sourceLanguageCode,
          captionsId,
          ...(captionSettingsId ? { captionSettingsId } : {}),
        };
        await ProjectRepository.updateProject(projectId, updatedFields);

        // Update React state
        setProject(
          (project) =>
            project && {
              ...project,
              ...updatedFields,
            }
        );
      }
    },
    [isReady, project, projectId]
  );

  const addUntranslatedCaptions = useCallback(
    async (sourceLanguageCode: string, words: CaptionWord[], phrases?: CaptionPhrase[]) => {
      if (!isReady) {
        return;
      }
      if (project?.untranslatedCaptionsId) {
        // Updating an already-existing captions entity
        // (mainly happens during debug)
        await CaptionsRepository.updateCaptionWords(project.untranslatedCaptionsId, words);
        await ProjectRepository.updateProject(projectId, {
          sourceFileLanguageCode: sourceLanguageCode,
        });
      } else {
        // Add new captions entry to database
        const untranslatedCaptionsId = await CaptionsRepository.addCaptions({
          projectId,
          languageCode: sourceLanguageCode,
          words,
          phrases,
          videoClipsApplied: false,
        });

        // Update project metadata
        await ProjectRepository.updateProject(projectId, {
          sourceFileLanguageCode: sourceLanguageCode,
          untranslatedCaptionsId,
        });

        // Update React state
        setProject(
          (project) =>
            project && {
              ...project,
              sourceFileLanguageCode: sourceLanguageCode,
              captionsId: untranslatedCaptionsId,
            }
        );
      }
    },
    [projectId, isReady]
  );

  const updateFilmStripPath = useCallback(
    async (filmStripPath: FilmStripPath[] | null, filmStripPixelsPerSecond: number | undefined) => {
      if (!isReady) {
        return;
      }
      await ProjectRepository.updateProject(projectId, { filmStripPath, filmStripPixelsPerSecond });
      setProject(
        (project) =>
          project && {
            ...project,
            filmStripPath,
            filmStripPixelsPerSecond,
          }
      );
    },
    [projectId]
  );

  const updateScenes = useCallback(
    async (scenes: number[]) => {
      if (!isReady) {
        return;
      }
      await ProjectRepository.updateProject(projectId, { scenes });
      setProject(
        (project) =>
          project && {
            ...project,
            scenes,
          }
      );
    },
    [projectId]
  );

  const updateTransitions = useCallback(
    async (transitions: Transition[]) => {
      if (!isReady) {
        return;
      }
      await ProjectRepository.updateProject(projectId, { transitions });
      setProject(
        (project) =>
          project && {
            ...project,
            transitions,
          }
      );
    },
    [projectId]
  );

  const updateTransitionEffect = useCallback(
    async (transitionId: string, effect: TransitionEffect) => {
      const transitions = project?.transitions ?? [];
      const newTransitions = transitions.map((transition) =>
        transition.id === transitionId
          ? ({ ...transition, effect } satisfies Transition)
          : transition
      );
      await updateTransitions(newTransitions);
    },
    [updateTransitions, project?.transitions]
  );

  const updateTranscriptionJobId = useCallback(
    async (transcriptionJobId: string | null) => {
      if (!isReady) {
        return;
      }
      await ProjectRepository.updateProject(projectId, { transcriptionJobId });
    },
    [projectId]
  );

  const updateProject = useCallback(
    async (data: Partial<Omit<Project, "id">>) => {
      if (!isReady) {
        return;
      }
      await ProjectRepository.updateProject(projectId, data);
      setProject((project) => (project ? { ...project, ...data } : null));
    },
    [projectId]
  );

  const updateCaptions = useCallback(
    async (words: CaptionWord[]) => {
      if (isReady && project?.captionsId) {
        await CaptionsRepository.updateCaptionWords(project.captionsId, words);
      }
    },
    [isReady, project?.captionsId]
  );

  const updateUntranslatedCaptions = useCallback(
    async (words: CaptionWord[]) => {
      if (isReady && project?.untranslatedCaptionsId) {
        await CaptionsRepository.updateCaptionWords(project.untranslatedCaptionsId, words);
      }
    },
    [isReady, project?.untranslatedCaptionsId]
  );

  const updatePhrases = useCallback(
    async (phrases: CaptionPhrase[]) => {
      if (isReady && project?.captionsId) {
        await CaptionsRepository.updateCaptionPhrases(project.captionsId, phrases);
      }
    },
    [isReady, project?.captionsId]
  );

  const updatePhraseAudios = useCallback(
    async (phraseAudios: CaptionPhraseAudio[]) => {
      if (isReady && project?.captionsId) {
        await CaptionsRepository.updateCaptionPhraseAudios(project.captionsId, phraseAudios);
      }
    },
    [isReady, project?.captionsId]
  );

  const updateStylePreset = useCallback(
    async (preset: CaptionStylePreset) => {
      if (isReady && project?.captionSettingsId) {
        await CaptionSettingsRepository.updateCaptionSettings(project.captionSettingsId, {
          preset: {
            ...preset,
          },
        });
      }
    },
    [isReady, project?.captionSettingsId]
  );

  const updateStyle = useCallback(
    async (style: CaptionStyle) => {
      if (isReady && project?.captionSettingsId) {
        await CaptionSettingsRepository.updateCaptionSettings(project.captionSettingsId, {
          ...style,
        });
      }
    },
    [isReady, project?.captionSettingsId]
  );

  const updateLanguages = useCallback(
    async (
      sourceFileLanguageCode: string,
      targetLanguageCode?: string,
      translateAudioEnabled?: boolean
    ) => {
      if (!isReady) {
        return;
      }
      await ProjectRepository.updateProject(projectId, {
        sourceFileLanguageCode,
        targetLanguageCode,
        translateAudioEnabled,
      });
      setProject(
        (project) =>
          project && {
            ...project,
            sourceFileLanguageCode,
            targetLanguageCode,
            translateAudioEnabled,
          }
      );
    },
    []
  );

  const getEffects = useCallback(async () => {
    if (isReady && project?.effectsId) {
      return await EffectsRepository.getEffects(project.effectsId);
    }
  }, [isReady, project?.effectsId]);

  const addEffects = useCallback(
    async (effects: Partial<Omit<Effects, "id" | "projectId">>) => {
      if (isReady) {
        const effectsId = await EffectsRepository.addEffects({
          projectId,
          ...effects,
        });
        await ProjectRepository.updateProject(
          projectId,
          {
            effectsId,
          },
          {
            // skipOverrideEditorType is true because we don't want to override the editor type
            // for this update call when just updating the effects id
            skipOverrideEditorType: true,
          }
        );
        setProject(
          (project) =>
            project && {
              ...project,
              effectsId,
            }
        );
      }
    },
    [isReady]
  );

  const updateEffects = useCallback(
    async (settings: Partial<Omit<Effects, "id" | "projectId">>) => {
      if (isReady && project?.effectsId) {
        await EffectsRepository.updateEffects(project.effectsId, settings);
      }
    },
    [isReady, project?.effectsId]
  );

  const takeOwnership = useCallback(async () => {
    if (isReady) {
      await ProjectRepository.takeOwnership(projectId);
    }
  }, [isReady]);

  const getAllProjectFolders = useCallback(async () => {
    if (isReady) {
      return await ProjectRepository.getAllProjectFolders();
    }
  }, [isReady]);

  useEffect(() => {
    isReady && loadEntities().catch((error) => console.error("Failed to fetch the project", error));
  }, [projectId, isReady]);

  return {
    project,
    loading,
    loaded,
    reloadProject: loadEntities,
    addCaptions,
    addUntranslatedCaptions,
    addEffects,
    getEffects,
    getAllProjectFolders,
    updateCaptions,
    updateUntranslatedCaptions,
    updateEffects,
    updateFilmStripPath,
    updateScenes,
    updateTransitions,
    updateTransitionEffect,
    updateLanguages,
    updateStyle,
    updateStylePreset,
    updateTranscriptionJobId,
    updatePhrases,
    updatePhraseAudios,
    updateProject,
    takeOwnership,
  };
}

export type ProjectPersistence = ReturnType<typeof useProjectPersistence>;
