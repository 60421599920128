import { getLanguageLocale } from "i18n/utils/getLanguageLocale";
import i18next from "i18next";

import { getIterableStore } from "~/stores/analytics/iterable";
import { getMixpanelStore } from "~/stores/analytics/mixpanel";
import { getStatsigStore } from "~/stores/analytics/statsig";
import { getProjectsStore } from "~/stores/projects";

export type AnalyticsTool = "mixpanel" | "statsig" | "googletagmanager" | "iterable";

export const analyticsTool = {
  defaults: ["mixpanel", "statsig", "googletagmanager"],
  all: ["mixpanel", "statsig", "googletagmanager", "iterable"],
} satisfies Record<string, AnalyticsTool[]>;

const parseNonStringPropsForStatsig = (properties: Record<string, unknown> = {}) => {
  return Object.entries(properties).reduce(
    (acc, [key, value]) => {
      if (typeof value === "string") {
        acc[key] = value;
      } else {
        acc[key] = JSON.stringify(value);
      }

      return acc;
    },
    {} as Record<string, string>
  );
};

export class Analytics {
  static track(
    event: string,
    additionalProperties?: Record<string, unknown> | void,
    tools: AnalyticsTool[] = analyticsTool.defaults
  ) {
    const properties = {
      project_id: getProjectsStore().activeProjectId,
      user_locale_setting: getLanguageLocale(i18next),
      ...additionalProperties,
    };

    if (tools.includes("mixpanel")) {
      getMixpanelStore().track(event, properties);
    }

    if (tools.includes("statsig")) {
      const normalizedProperties = parseNonStringPropsForStatsig(
        properties as Record<string, string>
      );
      getStatsigStore().track(event, normalizedProperties);
    }

    if (tools.includes("googletagmanager")) {
      if (window?.dataLayer) {
        window.dataLayer.push({ event });
      }
    }

    if (tools.includes("iterable")) {
      getIterableStore().track(event, properties);
    }

    // always log in dev otherwise check feature flag
    if (process.env.NODE_ENV === "development") {
      console.log({ event, properties, tools });
    } else {
      getStatsigStore()
        .getFeatureFlagAsync("log_analytics_events")
        .then((logAnalyticsEvents) => {
          if (logAnalyticsEvents) {
            console.log({ event, properties, tools });
          }
        });
    }
  }

  static trackConversion() {
    // google tag conversion event
    // note that gtag will not exist on native
    if (window?.gtag) {
      window.gtag("event", "conversion", {
        send_to: "AW-428533084/EwzwCKnE94cZENzKq8wB",
      });
    }
  }
}
