import { z } from "zod";

import { VideoMetadata } from "~/database/database.types";
import { makeBrandedId, makeBrandedIdSchema } from "~/utils/brand";

export const SourceAsset_Id = makeBrandedId("src_ast");
export type SourceAsset_Id = ReturnType<typeof SourceAsset_Id>;

const sourceAssetDescriptionSchema = z.object({
  id: makeBrandedIdSchema(SourceAsset_Id),
});

export const sourceAssetVideoDescriptionSchema = sourceAssetDescriptionSchema.extend({
  sourceType: z.literal("video"),
  fileId: z.string(),
  // TODO: schema for video metadata
  videoMetadata: z.custom<VideoMetadata>(),
  // TODO: can store things like that it came from AI Edit or creators or other "creation metadata"
});
export type SourceAssetVideoDescription = z.infer<typeof sourceAssetVideoDescriptionSchema>;

export const sourceAssetImageDescriptionSchema = sourceAssetDescriptionSchema.extend({
  sourceType: z.literal("image"),
});
export type SourceAssetImageDescription = z.infer<typeof sourceAssetImageDescriptionSchema>;

export const sourceAssetNoneDescriptionSchema = sourceAssetDescriptionSchema.extend({
  sourceType: z.literal("none"),
});
export type SourceAssetNoneDescription = z.infer<typeof sourceAssetNoneDescriptionSchema>;

export const anySourceAssetDescriptionSchema = z.discriminatedUnion("sourceType", [
  sourceAssetVideoDescriptionSchema,
  sourceAssetImageDescriptionSchema,
  sourceAssetNoneDescriptionSchema,
]);

/**
 * this describes a source asset, but does not include the actual data
 * it should include relevant metadata for the source asset so that we can retrieve
 * and use the metadata when constructing/rendering a project
 */
export type AnySourceAssetDescription = z.infer<typeof anySourceAssetDescriptionSchema>;

// creation

type SourceAssetDescriptionTypeMap = {
  video: SourceAssetVideoDescription;
  image: SourceAssetImageDescription;
};

type SourceAssetDescriptionParams<T extends keyof SourceAssetDescriptionTypeMap> = Omit<
  SourceAssetDescriptionTypeMap[T],
  "sourceType"
>;

export function createSourceAssetDescription<T extends keyof SourceAssetDescriptionTypeMap>(
  sourceType: T,
  params: SourceAssetDescriptionParams<T>
): SourceAssetDescriptionTypeMap[T] {
  return {
    sourceType,
    ...params,
  } as SourceAssetDescriptionTypeMap[T];
}
