import { ReactNode } from "react";

import * as D from "../BaseDialog";
import FileError from "../icons/FileError";
import PartyConfetti from "../icons/PartyConfetti";
import { ProgressBar } from "../ProgressBar";
import { Spinner } from "../Spinner";
import { Text } from "../Text";

import * as S from "./LoadingDialogBody.styles";
import { LoadingDialogBodyProps } from "./LoadingDialogBody.types";

export function LoadingDialogBody({
  description,
  progress,
  progressLabel,
  showProgressText,
  title,
  variant = "progress",
}: Readonly<LoadingDialogBodyProps>) {
  let icon: ReactNode;
  switch (variant) {
    case "multimedia":
      icon = <Spinner />;
      break;
    case "progress":
      icon = <Spinner />;
      break;
    case "error":
      icon = <FileError />;
      break;
    case "finished":
      icon = <PartyConfetti />;
      break;
  }

  return (
    <>
      <D.SectionContainer>
        {icon && <D.Icon isError={variant === "error"}>{icon}</D.Icon>}
        <D.Title>{title}</D.Title>
        {description && <D.Description>{description}</D.Description>}
      </D.SectionContainer>
      {progress != null && (
        <D.SectionContainer>
          {showProgressText && (
            <S.ProgressText>
              <Text color="grey-500" variant="body-2">
                {progressLabel ?? "In progress..."}
              </Text>
              <Text color="grey-500" variant="body-2-bold">
                {Math.round(progress)}%
              </Text>
            </S.ProgressText>
          )}
          <ProgressBar progress={progress} />
        </D.SectionContainer>
      )}
    </>
  );
}
