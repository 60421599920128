import { styled } from "~/theme";

import type { IconProps } from "./types";

function TranslateAudio({ width = 24, height = 24, ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M16.5674 11V11.8558"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 12.2837H19.1346"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2837 12.7115C15.2837 14.851 16.5673 15.7067 18.7068 16.5625"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8511 12.7115C17.8511 14.851 16.5674 15.7067 14.428 16.5625"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.757 14.5641L18.4231 19.6987"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7571 14.5641L23.0911 19.6987"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3567 18.2984H22.1574"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 20H9.5H5C3.34315 20 2 18.6569 2 17V13M22 9V7C22 5.34315 20.6569 4 19 4H15.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3 4.44444H3.36665C3.60691 4.44444 3.83913 4.35795 4.02085 4.20077L5.77479 2.68368C6.10902 2.39459 6.6304 2.59964 6.67815 3.03896C6.89209 5.00719 6.89209 6.99281 6.67815 8.96104L6.66884 9.04671C6.62236 9.47428 6.13356 9.69452 5.78249 9.44607L3.99875 8.18373C3.82984 8.06419 3.62801 8 3.42108 8H3C2.44772 8 2 7.55229 2 7V5.44444C2 4.89216 2.44771 4.44444 3 4.44444Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 3C10.5122 3.7695 11 4.41758 11 6C11 7.58242 10.5122 8.2305 10 9"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default styled(TranslateAudio, {
  width: 24,
  height: 24,
  color: "$grey-200",
});
