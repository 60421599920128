import type { MappingCoordinates, PixelMapping } from "./webgl";

/**
 * Calculates mapping coordinates to scale and crop a layer within a parent container while
 * maintaining aspect ratio.
 *
 * @param parentWidth - The width of the parent container layer.
 * @param parentHeight - The height of the parent container layer.
 * @param layerWidth - The width of the layer that you want to map onto the parent element.
 * @param layerHeight - The height of the layer that you want to map onto the parent element.
 * @param pixelMapping - (Optional) Existing pixel mappings to account for before calculating the
 * new mapping coordinates. An example of an existing pixel mapping would be Reframe and Zoom.
 *
 * @returns An object representing the mapping coordinates for cropping a layer within a parent
 * container based on the provided dimensions and optional pixel mapping.
 */
export function mapParentToLayerCoordinates(
  parentWidth: number,
  parentHeight: number,
  layerWidth: number,
  layerHeight: number,
  pixelMapping?: PixelMapping
): MappingCoordinates {
  const widthRatio = pixelMapping ? 1 - (pixelMapping.from.right - pixelMapping.from.left) : 0;
  const heightRatio = pixelMapping ? 1 - (pixelMapping.from.bottom - pixelMapping.from.top) : 0;

  const actualParentWidth = parentWidth + parentWidth * widthRatio;
  const actualParentHeight = parentHeight + parentHeight * heightRatio;

  const widthScale = layerWidth / actualParentWidth;
  const heightScale = layerHeight / actualParentHeight;

  // ensure aspect ratio is maintained
  const [scaledWidthScale, scaledHeightScale] =
    widthScale > heightScale ? [1, heightScale / widthScale] : [widthScale / heightScale, 1];

  const scaledWidth = parentWidth * scaledWidthScale;
  const croppedWidth = actualParentWidth - scaledWidth;
  const leftRightCrop = croppedWidth / 2;

  const scaledHeight = parentHeight * scaledHeightScale;
  const croppedHeight = actualParentHeight - scaledHeight;
  const topBottomCrop = croppedHeight / 2;

  const mappingCoordinates: MappingCoordinates = {
    left: leftRightCrop / actualParentWidth,
    top: topBottomCrop / actualParentHeight,
    right: (leftRightCrop + scaledWidth) / actualParentWidth,
    bottom: (topBottomCrop + scaledHeight) / actualParentHeight,
  };

  return mappingCoordinates;
}

/**
 * Calculates mapping coordinates to scale and cover the video layer with a image while
 * maintaining aspect ratio.
 *
 * @param canvasWidth - The width of the canvas.
 * @param canvasHeight - The height of canvas.
 * @param img - The img asset that will be used to cover the canvas.
 * @returns An object representing the mapping coordinates for covering the canvas layer with the asset
 */
export function getCoverPixelMapping(
  canvasWidth: number,
  canvasHeight: number,
  contentWidth: number,
  contentHeight: number
): PixelMapping {
  // Using numeric comparison directly to determine mapping conditions.
  const imgRatio = contentWidth / contentHeight;
  const canvasRatio = canvasWidth / canvasHeight;
  const ratioComparison = imgRatio / canvasRatio;

  if (ratioComparison > 1) {
    // Image is more horizontal than canvas.
    const scale = (contentHeight * canvasRatio) / contentWidth;
    const diff = (1 - scale) / 2;
    return {
      from: { left: diff, top: 0, right: 1 - diff, bottom: 1 },
      to: { left: 0, top: 0, right: 1, bottom: 1 },
    };
  } else if (ratioComparison < 1) {
    // Image is more vertical than canvas.
    const scale = contentWidth / canvasRatio / contentHeight;
    const diff = (1 - scale) / 2;
    return {
      from: { left: 0, top: diff, right: 1, bottom: 1 - diff },
      to: { left: 0, top: 0, right: 1, bottom: 1 },
    };
  } else {
    // Aspect ratios are equal.
    return {
      from: { left: 0, top: 0, right: 1, bottom: 1 },
      to: { left: 0, top: 0, right: 1, bottom: 1 },
    };
  }
}
