import { HttpResponse, http } from "msw";

import collageResponse from "./collageResponse.mock.json";

export const collageHandlers = [
  http.post("*/proxy/v1/ai-edit/image/collage/batch/submit", () => {
    return HttpResponse.json({ success: true, data: { operationId: "CAN3EktVGj9E3oLT8PzH" } });
  }),

  http.post("*/proxy/v1/ai-edit/image/collage/batch/get", () => {
    return HttpResponse.json(collageResponse);
  }),
];
