import { initialize, updateUser, updateUserEmail, track as iterableTrack } from "@iterable/web-sdk";

import { CaptionsAuthUser } from "~/context/CaptionsAuthContext";
import { BackendServicesClient } from "~/services/BackendServicesClient";
import { pickKeysWithValues } from "~/utils/pickBy";
import { createAsyncInitializedStore } from "~/utils/zustand-store";

export const [getIterableStore, useIterableStore, getIterableInstance] =
  createAsyncInitializedStore({
    name: "IterableStore",
    initializer: () => {
      return initialize(`${process.env.NEXT_PUBLIC_ITERABLE_WEB_KEY}`, async () => {
        return BackendServicesClient.shared
          .post<{ token: string }>("/iterable/v1/token", {})
          .then((res) => {
            return res?.data?.token;
          })
          .catch((e) => {
            console.error("Unable to fetch iterable jwt " + e);
            return "";
          });
      });
    },
    methods: ({ queue }) => ({
      setUser: (user: CaptionsAuthUser) => {
        const userId = user.userId;

        if (!userId) {
          return;
        }

        queue((iterable) =>
          iterable.setUserID(userId).then(() => {
            const userFields = pickKeysWithValues({
              name: user?.name,
              phone_number: user?.phoneNumber,
              country: user?.geo?.region,
              login_type: user?.loginType,
              latest_entitlement: user?.subscription?.subscriptionTier,
            });

            updateUser({
              dataFields: {
                latest_device: "web",
                last_seen_date: new Date(),
                ...userFields,
              },
            });

            if (user?.email) {
              updateUserEmail(user?.email);
            }
          })
        );
      },
      track: (event: string, properties?: Record<string, unknown>) => {
        queue(() =>
          iterableTrack({
            eventName: event,
            dataFields: {
              ...properties,
              device_platform: "web",
            },
          })
        );
      },
      reset: () => {
        queue((iterable) => iterable.logout());
      },
    }),
  });
