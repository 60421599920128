import { z } from "zod";

import { BackendServicesClient } from "~/context/BackendServicesContext";

import {
  getTranscriptionJobResponseRawSchema,
  ServiceTranscript,
  StartTranscriptionJobRequest,
  StartTranscriptionJobResponse,
  startTranscriptionJobResponseSchema,
  Transcript,
} from "./Transcription.dto";

/**
 * Parses transcript entry returned from the backend service.
 *
 * @param entry - the entry returned by the service.
 * @return the parsed data.
 */
export function parseTranscriptResponseEntry(entry: ServiceTranscript): Transcript {
  return {
    words:
      entry.words?.map((word) => ({
        startTime: word.startTime,
        endTime: word.endTime,
        text: word.word,
        keyword: word.isKeyword,
        keywordSettings: {
          supersize: word.isKeyword,
          emphasize: word.isKeyword,
        },
      })) ?? [],
    phrases:
      entry.phrases.map((phrase) => ({
        ...phrase,
        text: entry.words
          .filter((word) => word.startTime >= phrase.startTime && word.endTime <= phrase.endTime)
          .map((word) => word.word)
          .join(" "),
        id: `${phrase.id}`,
        speaker: entry.words.find((word) => word.phraseId === phrase.id)?.speaker ?? null,
        gcsUri: null,
        downloadUrl: null,
      })) ?? [],
  };
}

/**
 * Parses the response of the transcript generations backend service.
 *
 * @param data - the data returned by the service.
 * @return the parsed data.
 */
function parseTranscriptResponse(data: Record<string, ServiceTranscript>): Transcript[] {
  const dataEntries = Object.entries(data);
  return dataEntries.map(([_, entry]) => parseTranscriptResponseEntry(entry));
}

export type GetTranscriptionJobResponse = z.infer<typeof getTranscriptionJobResponseSchema>;
export const getTranscriptionJobResponseSchema = getTranscriptionJobResponseRawSchema.transform(
  (rawData) => ({
    job: {
      ...rawData.job,
      transcripts:
        (rawData.job.transcript?.result
          ? parseTranscriptResponse(rawData.job.transcript.result)
          : undefined) ?? [],
    },
  })
);

const TRANSCRIPTION_JOB_URL = `/text/d-v1/transcription`;

export async function getTranscriptionJobStatus(
  client: BackendServicesClient,
  _transcriptionJobId: string,
  _signal?: AbortSignal,
  _isShortsJob?: boolean
): Promise<GetTranscriptionJobResponse> {
  const isShortsJob = _isShortsJob ? true : false;
  const params = new URLSearchParams({
    isShortsJob: isShortsJob.toString(),
  });
  try {
    const result = await client.get(
      `${TRANSCRIPTION_JOB_URL}/${_transcriptionJobId}/status?${params}`,
      {
        signal: _signal,
      }
    );
    return getTranscriptionJobResponseSchema.parse(result.data);
  } catch (error) {
    console.error(error);
    if (error instanceof z.ZodError) {
      throw new Error("Invalid response received from the server!");
    } else {
      throw error;
    }
  }
}

export async function startTranscriptionJob(
  client: BackendServicesClient,
  _transcriptionJob: StartTranscriptionJobRequest
): Promise<StartTranscriptionJobResponse> {
  try {
    const result = await client.post(TRANSCRIPTION_JOB_URL, _transcriptionJob);
    return startTranscriptionJobResponseSchema.parse(result.data);
  } catch (error) {
    console.error(error);
    if (error instanceof z.ZodError) {
      throw new Error("Invalid response received from the server!");
    } else {
      throw error;
    }
  }
}
