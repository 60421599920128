import { styled } from "~/theme";

import { SIDEBAR_WIDTH_PX } from "../Sidebar/Sidebar.styles";
import { Text } from "../Text";

export const TOP_BAR_HEIGHT = "env(titlebar-area-height, 47px)";

export const TopBarContainer = styled("div", {
  backgroundColor: "$grey-950",
  position: "absolute",
  top: "env(titlebar-area-y, 0)",
  height: `calc(${TOP_BAR_HEIGHT} + 1px)`, // add 1px to account for border
  left: "0",
  width: "100%",
});

export const TopBarInner = styled("div", {
  display: "grid",
  gridTemplateColumns: "[left] 1fr [middle] 4fr [right] 1fr",
  gridTemplateRows: "100%",
  alignItems: "center",
  gap: "$16",
  backgroundColor: "$grey-950",
  height: "100%",
  width: "100%",
  boxSizing: "border-box",
  appRegion: "drag",
  "-webkit-app-region": "drag",
  "& > *": {
    appRegion: "no-drag",
    "-webkit-app-region": "no-drag",
  },
  borderBottom: "1px",
  borderColor: "$grey-800",
  borderStyle: "solid",
  paddingLeft: `calc(min(env(titlebar-area-x, 0px), 1px) * ${SIDEBAR_WIDTH_PX - 1})`,
  paddingRight: "calc(100% - env(titlebar-area-width, 100%) - env(titlebar-area-x, 0px))",
});

export const PageTitle = styled(Text, {
  gridColumn: "middle",
  justifySelf: "center",
  maxWidth: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});
PageTitle.defaultProps = { variant: "body-2", color: "grey-100" };

export const TopBarRight = styled("div", {
  gridColumn: "right",
  justifySelf: "end",
  paddingRight: 8,
});
