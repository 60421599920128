import { ComboBoxGroupLabelNone, ComboBoxGroupLabelText } from "./ComboBoxGroupLabel.styles";
import { ComboBoxGroupLabelProps } from "./ComboBoxGroupLabel.types";

export function ComboBoxGroupLabel({ label }: ComboBoxGroupLabelProps) {
  return label.length > 0 ? (
    <ComboBoxGroupLabelText>{label}</ComboBoxGroupLabelText>
  ) : (
    <ComboBoxGroupLabelNone />
  );
}
