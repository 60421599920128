import { ReactNode } from "react";

import { LoginStep } from "../SignIn/SignIn.types";

export interface SignInByPhoneProps {
  setStep?: (value: LoginStep) => void;
  step?: LoginStep;
}

export interface EnterPhoneNumberProps extends SignInByPhoneProps {
  selectedCode: string;
  setSelectedCode: (value: string) => void;
  phoneNumber: string;
  setPhoneNumber: (value: string, isDeleteContentBackward?: boolean, region?: string) => void;
  selectedRegion: string;
  setSelectedRegion: (value: string) => void;
}

export interface EnterCodeProps extends SignInByPhoneProps {
  phoneNumber: string;
  selectedCode: string;
  selectedRegion: string;
}

export type Country = {
  name: string;
  regionCode: string;
  callingCode: string;
  flag: string;
};

export type CountryOption = {
  value: string;
  searchValue: string;
  regionCode: string;
  label: ReactNode;
};

export type ErrorCode =
  | "EXCEEDED_MAX_ATTEMPTS"
  | "RATE_LIMIT_REACHED"
  | "VERIFICATION_CODE_EXPIRED"
  | "VERIFICATION_CODE_INVALID";

export enum ErrorCodeMessages {
  "EXCEEDED_MAX_ATTEMPTS" = "You've reached the limit for sending codes. Please try again later.",
  "RATE_LIMIT_REACHED" = "You've reached the limit for API calls. Please try again later.",
  "VERIFICATION_CODE_EXPIRED" = "The code has expired. Please press resend code to generate a fresh one.",
  "VERIFICATION_CODE_INVALID" = "Please double-check those digits and retry! It looks like the code you entered isn't quite right.",
  "NUMBER_TEMPORARILY_BLOCKED" = "Number is temporarily blocked. Please try again later.",
}
