import { chunkedArray } from "~/utils/chunk-by";

import type { Rect } from "../../project/utils/faceData/face-data.types";
import type { AISceneWithStyle, AIVideoMetadata } from "../AIScenes.types";
import type { AIImageRules, AIStaticImageRules } from "../services/AIEditStyles";
import type { AICollageImage } from "../services/Collages";

type Position = { x: number; y: number };
type Size = "small" | "medium";

export interface AICollageImageWithPosition extends AICollageImage {
  position: Position;
  size?: Size;
  startTime: number;
  endTime: number;
}

export interface AIStaticImageWithPosition {
  assetId: string;
  position: Position;
  rotation?: number;
  scale: number;
  startTime: number;
  endTime: number;
}

export function calculateStaticImagePositions(
  scene: AISceneWithStyle,
  rules: AIStaticImageRules,
  options?: { invertedYAxis: boolean }
): AIStaticImageWithPosition[] {
  // invert y axis if options.invertedYAxis is enabled
  const yAxis = (axis: number) => (options?.invertedYAxis ? 1 - axis : axis);

  const layout = rules.imageLayout;
  if (
    layout === "collage_layout_a" ||
    layout === "collage_layout_b" ||
    layout === "scrapbook_talking_head_4"
  ) {
    const positions = {
      collage_layout_a: [
        {
          x: 0.5,
          y: yAxis(0.75),
        },
        {
          x: 0.4,
          y: yAxis(0.15),
        },
      ],
      collage_layout_b: [
        {
          x: 0.3,
          y: yAxis(0.2),
        },
        {
          x: 0.8,
          y: yAxis(0.5),
        },
      ],
      scrapbook_talking_head_4: [
        {
          x: 0.45,
          y: yAxis(0.93),
        },
        {
          x: 0.25,
          y: yAxis(0.7),
        },
        {
          x: 0.25,
          y: yAxis(0.8),
        },
      ],
    } as const;
    const scales = {
      collage_layout_a: [1.5, 2.0],
      collage_layout_b: [2.5, 2.0],
      scrapbook_talking_head_4: [1.0, 1.0, 0.75],
    } as const;

    const rotations = {
      collage_layout_a: [0, 0],
      collage_layout_b: [0, 0],
      scrapbook_talking_head_4: [0, 0, 0],
    } as const;

    return rules.assetIds.map((assetId, index) => {
      return {
        assetId: assetId,
        position: positions[layout][index],
        rotation: rotations[layout][index] ?? 0,
        scale: scales[layout][index] / 4,
        startTime: scene.startTime + 0.2 * index,
        endTime: scene.endTime,
      };
    });
  }
  return [];
}

export function calculateImagePositions(
  scene: AISceneWithStyle,
  images: AICollageImage[],
  imageRules: AIImageRules,
  video: AIVideoMetadata,
  faceRect?: Rect,
  options?: { invertedYAxis: boolean }
): AICollageImageWithPosition[] {
  // invert y axis if options.invertedYAxis is enabled
  const yAxis = (axis: number) => (options?.invertedYAxis ? 1 - axis : axis);

  const sceneDuration = scene.endTime - scene.startTime;
  const layout = imageRules.imageLayout;

  if (layout === "collage_layout_a" || layout === "collage_layout_b") {
    const positions = {
      collage_layout_a: [
        { x: 0.4, y: yAxis(0.15) },
        { x: 0.5, y: yAxis(0.75) },
      ],
      collage_layout_b: [
        { x: 0.45, y: yAxis(0.3) },
        { x: 0.75, y: yAxis(0.5) },
      ],
    } as const;
    const sizes = ["medium", "small"] as const;
    return images.slice(0, 2).map((image, index) => {
      return {
        ...image,
        position: positions[layout][index],
        size: sizes[index],
        startTime: scene.startTime + 0.1 + 0.2 * index,
        endTime: scene.endTime,
      };
    });
  }

  if (layout === "impact_layout_a" || layout === "impact_layout_b") {
    const variants = {
      impact_layout_a: [
        [
          // First half
          { position: { x: 0.5, y: yAxis(0.5) }, size: "medium" },
        ],

        [
          // First half
          { position: { x: 0.5, y: yAxis(0.5) }, size: "medium" },
          // Second half
          { position: { x: 0.5, y: yAxis(0.5) }, size: "medium" },
        ],

        [
          // First half
          { position: { x: 0.5, y: yAxis(0.5) }, size: "medium" },
          // Second half
          { position: { x: 0.75, y: yAxis(0.25) }, size: "small" },
          { position: { x: 0.25, y: yAxis(0.5) }, size: "small" },
        ],
      ],
      impact_layout_b: [
        [
          // First half
          { position: { x: 0.5, y: yAxis(0.5) }, size: "medium" },
        ],

        [
          // First half
          { position: { x: 0.5, y: yAxis(0.5) }, size: "medium" },
          // Second half
          { position: { x: 0.5, y: yAxis(0.5) }, size: "medium" },
        ],

        [
          // First half
          { position: { x: 0.5, y: yAxis(0.5) }, size: "medium" },
          // Second half
          { position: { x: 0.5, y: yAxis(0.25) }, size: "small" },
          { position: { x: 0.5, y: yAxis(0.75) }, size: "small" },
        ],
      ],
    } as const;

    const chunkSize = typeof imageRules.numImages === "number" ? imageRules.numImages : 3;
    const chunks = chunkedArray(images, chunkSize);
    const imageDuration = sceneDuration / (chunks.length * 2 || 1);

    return chunks.flatMap((chunk, i) => {
      const firstStartTime = scene.startTime + i * imageDuration * 2;
      const firstEndTime = firstStartTime + imageDuration;

      return chunk.map((image, index) => {
        const { position, size } = variants[layout][chunkSize - 1][index];

        // Show one image for the first half of the scene
        let startTime = firstStartTime;
        let endTime = firstEndTime;
        // Show the second and third image for the second half of the scene
        if (index > 0) {
          startTime = firstEndTime;
          endTime = firstStartTime + imageDuration * 2;
        }

        return {
          ...image,
          position,
          size,
          startTime,
          endTime,
        };
      });
    });
  }

  if (layout === "impact_below_head") {
    const position = { x: 0.5, y: yAxis(0.65) };
    if (faceRect) {
      const aboveRectSpace = {
        x: 0,
        y: 0,
        width: video.width,
        height: faceRect.y,
      };
      const belowRectSpace = {
        x: 0,
        y: faceRect.y + faceRect.h,
        width: video.width,
        height: video.height - (faceRect.y + faceRect.h),
      };

      const spaceAbove = aboveRectSpace.height;
      const spaceBelow = belowRectSpace.height;

      const contentRect = spaceBelow > spaceAbove ? belowRectSpace : aboveRectSpace;
      const heightOfEachThird = contentRect.height / 3;

      const contentRectTopThird = {
        x: contentRect.x,
        y: contentRect.y,
        width: contentRect.width,
        height: heightOfEachThird,
      };
      const contentRectBottomThird = {
        x: contentRect.x,
        y: contentRect.y + 2 * heightOfEachThird,
        width: contentRect.width,
        height: heightOfEachThird,
      };

      if (spaceBelow > spaceAbove) {
        const centerPosition = contentRectTopThird.y + contentRectTopThird.height / 2;
        position.y = yAxis(centerPosition / video.height);
      } else {
        const centerPosition = contentRectBottomThird.y + contentRectBottomThird.height / 2;
        position.y = yAxis(centerPosition / video.height);
      }
    }

    const duration = sceneDuration / (images.length || 1);

    return images.map((image, index) => {
      const nextImage = images[index + 1];
      // use backend provided timestamps, but fall back to even spacing if they don't exist
      const startTime = image.startTime ?? scene.startTime + index * duration;
      const endTime = nextImage?.startTime ?? scene.startTime + (index + 1) * duration;

      return {
        ...image,
        position,
        size: "small",
        startTime,
        endTime,
      };
    });
  }

  if (["paper_chalk_layout_a", "paper_chalk_layout_b", "paper_chalk_layout_c"].includes(layout)) {
    return images.map((image, index) => {
      const startTime = scene.startTime + index * 0.2;
      const endTime = scene.endTime;

      let position = { x: 0.5, y: yAxis(0.5) };
      if (layout === "paper_chalk_layout_a") {
        if (index === 0) {
          position = { x: 0.25, y: yAxis(0.35) };
        } else if (index === 1) {
          position = { x: 0.75, y: yAxis(0.2) };
        } else if (index === 2) {
          position = { x: 0.7, y: yAxis(0.5) };
        }
      } else if (layout === "paper_chalk_layout_b") {
        if (index === 0) {
          position = { x: 0.5, y: yAxis(0.25) };
        } else if (index === 1) {
          position = { x: 0.25, y: yAxis(0.45) };
        } else if (index === 2) {
          position = { x: 0.75, y: yAxis(0.45) };
        }
      } else if (layout === "paper_chalk_layout_c") {
        if (index === 0) {
          position = { x: 0.5, y: yAxis(0.2) };
        } else if (index === 1) {
          position = { x: 0.5, y: yAxis(0.8) };
        }
      }

      return {
        ...image,
        position,
        size: "small",
        startTime,
        endTime,
      };
    });
  }

  return images.map((image, index) => {
    const duration = sceneDuration / (images.length || 1);
    const startTime = scene.startTime + index * duration;
    const endTime = scene.startTime + (index + 1) * duration;

    return {
      ...image,
      position: { x: 0.5, y: 0.5 },
      startTime,
      endTime,
    };
  });
}
