import { AnimationStyle, CaptionStyle, CaptionStylePreset } from "captions-engine";
import { useCallback, useMemo, useState } from "react";

import { useToast } from "~/components";
import {
  CUSTOM_TEMPLATE_CREATE,
  CUSTOM_TEMPLATE_DELETE,
  CUSTOM_TEMPLATE_ERROR,
  STYLE_UPDATE,
} from "~/constants/mixpanel.constants";
import { useBackendServicesClient } from "~/context/BackendServicesContext";
import { useAuthState } from "~/context/CaptionsAuthContext";
import { CustomTemplate } from "~/database/database.types";
import { CustomTemplatesRepository } from "~/database/repo/CustomTemplatesRepository";
import { useOnceEffect } from "~/hooks/helpers";
import { useLocalStorage } from "~/hooks/useLocalStorage";
import { useMixpanel } from "~/hooks/useMixpanel";

import { type CaptionTemplate, getCaptionStyleTemplates } from "../services/CaptionStylePreset";

interface TemplateDates {
  createdAt: Date;
  updatedAt: Date;
}

export interface CaptionTemplateWithDates extends CaptionTemplate, TemplateDates {}

export function useCaptionStyleTemplates() {
  const [captionStyleTemplates, setCaptionStyleTemplates] = useState(captionStyleTemplatesCache);
  const [customTemplates, setCustomTemplates] = useState<CaptionTemplateWithDates[]>([]);
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(true);
  const [templateBeingEditedId, setTemplateBeingEditedId] = useState<string | null>(null);
  const [templateBeingCreated, setTemplateBeingCreated] = useState<CaptionTemplate | null>(null);
  const { state: authState, user } = useAuthState();
  const client = useBackendServicesClient();
  const toast = useToast();
  const mixpanel = useMixpanel();

  const [templatesCount, setTemplatesCount] = useLocalStorage("customTemplates", 0);

  const handleUpdateCustomTemplateFont = async (oldFontName: string, newFontName: string) => {
    const allCustomTemplates = await CustomTemplatesRepository.getAllCustomTemplates();
    const customTemplatesUsingRemovedFont = allCustomTemplates.filter(
      ({ template }) => template.style.content.font.fontName === oldFontName
    );
    setCustomTemplates((prev) => {
      const newCustomTemplatesOrderedByDate = prev.map((template) => {
        if (template.style.content.font.fontName === oldFontName) {
          const templateDeepClone = structuredClone(template);
          templateDeepClone.style.content.font.fontName = newFontName;
          return templateDeepClone;
        }
        return template;
      });
      return newCustomTemplatesOrderedByDate;
    });

    customTemplatesUsingRemovedFont.forEach(({ id, template }) => {
      const styleDeepClone = structuredClone(template.style);
      styleDeepClone.content.font.fontName = newFontName;

      return CustomTemplatesRepository.updateCustomTemplate(id, {
        style: styleDeepClone,
      });
    });
  };

  const handleRenameCustomTemplate = async (templateId: string, newName: string) => {
    try {
      await CustomTemplatesRepository.updateCustomTemplate(templateId, {
        displayName: newName,
      });
      setCustomTemplates((prev) => {
        if (prev) {
          const newCustomTemplatesOrderedByDate = prev.reduce((acc, template) => {
            if (template.id === templateId) {
              acc.unshift({ ...template, displayName: newName });
            } else {
              acc.push(template);
            }
            return acc;
          }, [] as CaptionTemplateWithDates[]);

          return newCustomTemplatesOrderedByDate;
        }
        return [];
      });
      mixpanel.track(STYLE_UPDATE, {
        is_custom: true,
        property_update: false,
        style_name: newName,
        style_id: templateId,
      });
    } catch (error) {
      if (error instanceof Error) {
        setError(error);
        toast.add("Error renaming template", {
          severity: "error",
        });
        mixpanel.track(CUSTOM_TEMPLATE_ERROR, {
          status: "fail",
          details: {
            message: error.message,
            page_source: "project_screen",
            type: "display_name_update",
            template_id: templateId,
            display_name: newName,
          },
        });
      }
    }
  };

  const handleUpdateCustomTemplate = async (
    templateId: string,
    captionStyle: CaptionStyle,
    captionStylePreset: CaptionTemplate["style"]["content"],
    onFinish?: (template: CaptionTemplate) => void
  ) => {
    const template = customTemplates.find((template) => template.id === templateId);
    if (!template) {
      throw new Error("Template not found");
    }
    const updatedTemplate: Partial<Omit<CustomTemplate["template"], "id">> = {
      colors: {
        active: captionStyle.activeColor,
        primary: captionStyle.textColor,
        wordBackground: captionStyle.wordBackground,
        emphasis: captionStyle.emphasisColor
          ? captionStyle.emphasisColor
          : {
              alpha: 0,
              blue: 0,
              green: 0,
              red: 0,
            },
      },
      emojiSettings: {
        ...captionStyle.emojiSettings,
      },
      style: {
        ...template.style,
        content: captionStylePreset,
      },
      emphasisSettings: {
        ...captionStyle.emphasisSettings,
      },
    };
    try {
      await CustomTemplatesRepository.updateCustomTemplate(templateId, updatedTemplate);
      setCustomTemplates((prev) => {
        if (prev) {
          const newCustomTemplatesOrderedByDate = prev.reduce((acc, template) => {
            if (template.id === templateId) {
              const newTemplate = { ...template, ...updatedTemplate };
              onFinish?.(newTemplate);
              acc.unshift({ ...template, ...updatedTemplate });
            } else {
              acc.push(template);
            }
            return acc;
          }, [] as CaptionTemplateWithDates[]);

          return newCustomTemplatesOrderedByDate;
        }
        return [];
      });
      mixpanel.track(STYLE_UPDATE, {
        is_custom: true,
        property_update: true,
        style_name: updatedTemplate.displayName,
        style_id: templateId,
      });
    } catch (error) {
      if (error instanceof Error) {
        setError(error);
        toast.add("Error updating template", {
          severity: "error",
        });
        mixpanel.track(CUSTOM_TEMPLATE_ERROR, {
          status: "fail",
          details: {
            message: error.message,
            page_source: "project_screen",
            type: "custom_template_update",
            template_id: templateId,
            template: mixpanel.sanitizeJson(updatedTemplate),
          },
        });
      }
    }
  };

  const createCustomTemplateFromStyleAndPreset = (
    captionStyle: CaptionStyle,
    captionStylePreset: CaptionStylePreset,
    newTemplatesCount: number,
    name?: string
  ): CaptionTemplate => {
    const templateName = name || `Custom Template ${newTemplatesCount}`;
    const id = window.crypto.randomUUID();
    const newTemplate = {
      id,
      colors: {
        active: captionStyle.activeColor,
        primary: captionStyle.textColor,
        wordBackground: captionStyle.wordBackground,
        emphasis: captionStyle.emphasisColor
          ? captionStyle.emphasisColor
          : {
              alpha: 0,
              blue: 0,
              green: 0,
              red: 0,
            },
      },
      displayName: templateName,
      style: {
        audienceSegments: [],
        canEdit: false,
        categories: [],
        content: captionStylePreset,
        styleId: id,
        styleName: templateName,
        tags: [],
      },
      emojiSettings: {
        ...captionStyle.emojiSettings,
      },
      emphasisSettings: {
        ...captionStyle.emphasisSettings,
      },
    } satisfies CaptionTemplate;

    return newTemplate;
  };

  const handleCreateCustomTemplate = async (
    captionStyle: CaptionStyle,
    captionStylePreset: CaptionTemplate["style"]["content"],
    name?: string,
    onFinish?: (template: CaptionTemplate) => void
  ) => {
    const newTemplatesCount = (templatesCount || 0) + 1;
    setTemplatesCount(newTemplatesCount);
    const template = createCustomTemplateFromStyleAndPreset(
      captionStyle,
      captionStylePreset,
      newTemplatesCount,
      name
    );

    try {
      await CustomTemplatesRepository.addCustomTemplate({
        id: template.id,
        ownerUserId: user?.userId || "",
        template,
        createdAt: new Date(),
        updatedAt: new Date(),
      });

      const templateWithDates: CaptionTemplateWithDates = {
        ...template,
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      setCustomTemplates((prev) => {
        if (prev) {
          const customTemplatesOrderedByDate = [...prev, { ...templateWithDates }].sort((a, b) => {
            return b.updatedAt.getTime() - a.updatedAt.getTime();
          });
          onFinish?.(templateWithDates);
          return customTemplatesOrderedByDate;
        }
        return [templateWithDates];
      });

      mixpanel.track(CUSTOM_TEMPLATE_CREATE, {
        status: "success",
        style_name: template.displayName,
        style_id: template.id,
        template: mixpanel.sanitizeJson(template),
      });

      return template.id;
    } catch (error) {
      if (error instanceof Error) {
        setError(error);
        toast.add("Error creating template", {
          severity: "error",
        });
        mixpanel.track(CUSTOM_TEMPLATE_ERROR, {
          status: "fail",
          details: {
            type: "custom_template_create",
            page_source: "project_screen",
            message: error.message,
            template_id: template.id,
            template: mixpanel.sanitizeJson(template),
            display_name: template.displayName,
          },
        });
      }
    }
  };

  const handleDeleteCustomTemplate = useCallback(
    async (templateId: string) => {
      try {
        await CustomTemplatesRepository.removeCustomTemplate(templateId);
        setCustomTemplates((prev) => {
          if (prev) {
            const newCustomTemplatesOrderedByDate = prev.filter(
              (template) => template.id !== templateId
            );
            return newCustomTemplatesOrderedByDate;
          }
          return [];
        });
        mixpanel.track(CUSTOM_TEMPLATE_DELETE, { status: "success", style_id: templateId });
      } catch (error) {
        if (error instanceof Error) {
          setError(error);
          toast.add("Error deleting template", {
            severity: "error",
          });
          mixpanel.track(CUSTOM_TEMPLATE_ERROR, {
            status: "fail",
            details: {
              type: "custom_template_delete",
              page_source: "project_screen",
              message: error.message,
              template_id: templateId,
            },
          });
        }
      }
    },
    [mixpanel.track, toast.add]
  );

  const convertAnimationStyles = <T extends CaptionTemplate | CaptionTemplateWithDates>(
    template: T
  ) => {
    if (
      template.style.content.activeWordBackground.animationStyle ===
      AnimationStyle.animationStyleTransform
    ) {
      template.style.content.activeWordBackground.animationStyle =
        AnimationStyle.animationStyleSlideIn;
      return;
    }
    if (
      template.style.content.activeWordBackground.animationStyle ===
      AnimationStyle.animationStyleUnspecified
    ) {
      template.style.content.activeWordBackground.animationStyle =
        AnimationStyle.animationStylePopIn;
    }
  };

  const sanitizePresets = useCallback(
    <T extends Array<CaptionTemplate> | Array<CaptionTemplateWithDates>>(presets: T): T => {
      const newPresets = structuredClone(presets);

      newPresets.forEach((preset) => {
        convertAnimationStyles(preset);
      });

      return newPresets;
    },
    []
  );

  const templateBeingEdited = useMemo(() => {
    const allTemplates = [...(captionStyleTemplates ?? []), ...customTemplates];
    return allTemplates.find((template) => template.id === templateBeingEditedId);
  }, [captionStyleTemplates, customTemplates, templateBeingEditedId]);

  const addTemplateBeingCreated = (
    captionStyle: CaptionStyle,
    captionStylePreset: CaptionStylePreset
  ) => {
    const newTemplatesCount = (templatesCount || 0) + 1;
    const template = createCustomTemplateFromStyleAndPreset(
      captionStyle,
      captionStylePreset,
      newTemplatesCount
    );
    setTemplateBeingCreated(template);
    return template;
  };

  useOnceEffect(authState === "signedIn", () => {
    const getTemplates = async () => {
      try {
        if (captionStyleTemplatesCache) {
          setCaptionStyleTemplates(captionStyleTemplatesCache);
        } else {
          const presets = await getCaptionStyleTemplates(client);
          const sanitizedPresets = sanitizePresets(presets);
          setCaptionStyleTemplates(sanitizedPresets);
          captionStyleTemplatesCache = sanitizedPresets;
        }

        const customTemplates = await CustomTemplatesRepository.getAllCustomTemplates();
        const customTemplatesWithoutId: Array<CaptionTemplateWithDates> = customTemplates.map(
          ({ template, createdAt, updatedAt }) => {
            return {
              ...template,
              createdAt,
              updatedAt,
            };
          }
        );

        const orderedCustomTemplates = customTemplatesWithoutId.sort((a, b) => {
          return b.updatedAt.getTime() - a.updatedAt.getTime();
        });
        const sanitizedCustomTemplates = sanitizePresets(orderedCustomTemplates);
        setCustomTemplates(sanitizedCustomTemplates);
      } catch (error) {
        if (error instanceof Error) {
          setError(error);
          toast.add("Error getting templates", {
            severity: "error",
          });
          mixpanel.track(CUSTOM_TEMPLATE_ERROR, {
            status: "fail",
            details: {
              type: "custom_template_get",
              page_source: "project_screen",
              message: error.message,
            },
          });
        }
      } finally {
        setLoading(false);
      }
    };

    getTemplates();
  });

  return {
    captionStyleTemplates,
    customTemplates,
    error,
    loading,
    templateBeingCreated,
    templateBeingEdited,
    templateBeingEditedId,
    handleCreateCustomTemplate,
    handleDeleteCustomTemplate,
    handleRenameCustomTemplate,
    handleUpdateCustomTemplateFont,
    handleUpdateCustomTemplate,
    addTemplateBeingCreated,
    setTemplateBeingCreated,
    setTemplateBeingEditedId,
  };
}

/** A simple cache to be reused on subsequent fetches */
let captionStyleTemplatesCache: CaptionTemplate[] | undefined;
