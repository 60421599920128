import { styled } from "~/theme";

export const Text = styled("span", {
  margin: "0",
  variants: {
    variant: {
      "heading-1": {
        fontFamily: "$avenir-next",
        fontSize: "$32",
        fontWeight: "$demi-bold",
        lineHeight: "$40",
        letterSpacing: "$spacing-1",
        color: "$grey-200",
      },
      "heading-2": {
        fontFamily: "$avenir-next",
        fontSize: "$28",
        fontWeight: "$demi-bold",
        lineHeight: "$32",
        letterSpacing: "$spacing-1",
        color: "$grey-200",
      },
      "heading-3": {
        fontFamily: "$avenir-next",
        fontSize: "$24",
        fontWeight: "$demi-bold",
        lineHeight: "$32",
        letterSpacing: "$spacing-1",
        color: "$grey-200",
      },
      "heading-3-medium": {
        fontFamily: "$avenir-next",
        fontSize: "$24",
        fontWeight: "$medium",
        lineHeight: "$32",
        letterSpacing: "$spacing-1",
        color: "$grey-200",
      },
      "heading-4": {
        fontFamily: "$avenir-next",
        fontSize: "$18",
        fontWeight: "$demi-bold",
        lineHeight: "$24",
        letterSpacing: "$spacing-1",
        color: "$grey-200",
      },
      "heading-4-medium": {
        fontFamily: "$avenir-next",
        fontSize: "$18",
        fontWeight: "$medium",
        lineHeight: "$24",
        letterSpacing: "$spacing-1",
        color: "$grey-200",
      },
      "heading-4-subtle": {
        fontFamily: "$avenir-next",
        fontSize: "$18",
        lineHeight: "$24",
        letterSpacing: "$spacing-1",
        color: "$grey-400",
      },
      "body-1-bold": {
        fontFamily: "$avenir-next",
        fontSize: "$15",
        fontWeight: "$demi-bold",
        lineHeight: "$24",
        letterSpacing: "$spacing-1_5",
        color: "$grey-300",
      },
      "body-1": {
        fontFamily: "$avenir-next",
        fontSize: "$15",
        fontWeight: "$medium",
        lineHeight: "$24",
        letterSpacing: "$spacing-1_5",
        color: "$grey-300",
      },
      "body-1-subtle": {
        fontFamily: "$avenir-next",
        fontSize: "$15",
        lineHeight: "$24",
        fontWeight: "$medium",
        letterSpacing: "$spacing-1_5",
        color: "$grey-400",
      },
      "body-2-bold": {
        fontFamily: "$avenir-next",
        fontSize: "$14",
        fontWeight: "$demi-bold",
        lineHeight: "$24",
        letterSpacing: "$spacing-1",
        color: "$grey-300",
      },
      "body-2": {
        fontFamily: "$avenir-next",
        fontSize: "$14",
        fontWeight: "$medium",
        lineHeight: "$24",
        letterSpacing: "$spacing-1",
        color: "$grey-300",
      },
      "body-2-subtle": {
        fontFamily: "$avenir-next",
        fontSize: "$14",
        fontWeight: "$medium",
        lineHeight: "$24",
        letterSpacing: "$spacing-1",
        color: "$grey-400",
      },
      "body-3-bold": {
        fontFamily: "$avenir-next",
        fontSize: "$12",
        fontWeight: "$demi-bold",
        lineHeight: "$18",
        letterSpacing: "$spacing-2_5",
        color: "$grey-300",
      },
      "body-3-caps": {
        fontFamily: "$avenir-next",
        fontSize: "$12",
        fontWeight: "$medium",
        lineHeight: "$18",
        letterSpacing: "$spacing-2",
        color: "$grey-300",
        textTransform: "uppercase",
      },
      "body-3": {
        fontFamily: "$avenir-next",
        fontSize: "$12",
        fontWeight: "$medium",
        lineHeight: "$18",
        letterSpacing: "$spacing-2_5",
        color: "$grey-300",
      },
      "body-4": {
        fontFamily: "$avenir-next",
        fontSize: "$11",
        fontWeight: "$medium",
        lineHeight: "$16",
        letterSpacing: "$spacing-2",
        color: "$grey-300",
      },
      // not part of official design system
      "caption-1-bold": {
        fontFamily: "$avenir-next",
        fontSize: "$11",
        fontWeight: "$demi-bold",
        lineHeight: "$18",
      },
      "caption-1-bold-caps": {
        fontFamily: "$avenir-next",
        fontSize: "$11",
        fontWeight: "$demi-bold",
        lineHeight: "$14",
        textTransform: "uppercase",
        color: "$grey-700",
      },
      "caption-1": {
        fontFamily: "$avenir-next",
        fontSize: "$11",
        fontWeight: "$medium",
        lineHeight: "$18",
      },
      "caption-2-bold": {
        fontFamily: "$avenir-next",
        fontSize: "$8",
        fontWeight: "$demi-bold",
        lineHeight: "$12",
      },
      "caption-2": {
        fontFamily: "$avenir-next",
        fontSize: "$8",
        fontWeight: "$medium",
        lineHeight: "$12",
      },
      "caption-3-caps": {
        fontFamily: "$avenir-next",
        fontSize: "$10",
        fontWeight: "$medium",
        lineHeight: "$16",
        textTransform: "uppercase",
      },
      "caption-3-bold": {
        fontFamily: "$avenir-next",
        fontSize: "$10",
        fontWeight: "$demi-bold",
        lineHeight: "$16",
      },
      "caption-3": {
        fontFamily: "$avenir-next",
        fontSize: "$10",
        fontWeight: "$medium",
        lineHeight: "$16",
      },
    },
    color: {
      white: { color: "$white !important" },
      "grey-10": { color: "$grey-10 !important" },
      "grey-50": { color: "$grey-50 !important" },
      "grey-100": { color: "$grey-100 !important" },
      "grey-150": { color: "$grey-150 !important" },
      "grey-200": { color: "$grey-200 !important" },
      "grey-300": { color: "$grey-300 !important" },
      "grey-400": { color: "$grey-400 !important" },
      "grey-500": { color: "$grey-500 !important" },
      "grey-600": { color: "$grey-600 !important" },
      "grey-700": { color: "$grey-700 !important" },
      "grey-800": { color: "$grey-800 !important" },
      "grey-900": { color: "$grey-900 !important" },
      "primary-violet": { color: "$primary-violet !important" },
      inherit: { color: "inherit !important" },
      "secondary-error": { color: "$secondary-error !important" },
    },
  },
  defaultVariants: {
    variant: "body-1",
  },
});
