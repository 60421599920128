import useLocalStorageState, {
  LocalStorageState,
  LocalStorageOptions,
} from "use-local-storage-state";

// We used to have an older implementation that relied on `useLayoutEffect` etc
// to manage the storage. we are now using this hook that uses `useSyncExternalStore`
// and syncs usages across calls and tabs.
// This file now is mostly just the bridging logic to the hook

type ParserOptions<T> =
  | {
      raw: true;
    }
  | {
      raw: false;
      serializer: (value: T) => string;
      deserializer: (value: string) => T;
    };

const convertOptions = <T>(
  defaultValue?: T,
  originalOptions?: ParserOptions<T>
): LocalStorageOptions<T> => {
  const options: LocalStorageOptions<T> = { defaultValue };

  if (!originalOptions) {
    return options;
  }

  if (originalOptions.raw) {
    options.serializer = {
      parse: (value) => value,
      stringify: (value) => value as string,
    };
  } else {
    options.serializer = {
      parse: originalOptions.deserializer,
      stringify: (value) => originalOptions.serializer(value as T),
    };
  }

  return options;
};

export function useLocalStorage<T>(
  key: string,
  initialValue: T,
  options?: ParserOptions<T>
): LocalStorageState<T>;
export function useLocalStorage<T>(
  key: string,
  initialValue?: T,
  options?: ParserOptions<T>
): LocalStorageState<T | undefined>;
export function useLocalStorage<T>(key: string, initialValue?: T, options?: ParserOptions<T>) {
  return useLocalStorageState(key, convertOptions(initialValue, options)) as LocalStorageState<
    T | undefined
  >;
}
