import { BackendServicesClient } from "~/context/BackendServicesContext";

import { ServiceTranscriptResultData } from "../Transcription";

const UGC_AD_GEN_URL = "/ugcAds/v1";

export interface AIAdsJob {
  avatarId: string;
  mediaFileIds: string[]; // file ids
  mediaUris: string[]; // image urls
  transcript: string;
  enableBackgroundMusic?: boolean;
}

export interface AIAdsJobResponse {
  operationId: string;
}

export interface AIAdsResult {
  fileId: string;
  title: string;
  durationSeconds?: number;
  transcription: { result: ServiceTranscriptResultData };
}

export interface AIAdsJobStatus {
  status: "started" | "processing" | "error" | "failed" | "finished";
  progress?: number;
  error?: string;
  estimatedQueueTimeSec?: number;
  ugcAds?: AIAdsResult[];
}

export async function startUGCAdGeneratesJob(
  client: BackendServicesClient,
  { avatarId, mediaFileIds, mediaUris, transcript, enableBackgroundMusic }: AIAdsJob
): Promise<AIAdsJobResponse> {
  try {
    const result = await client.post<AIAdsJobResponse>(`${UGC_AD_GEN_URL}`, {
      avatarId,
      mediaUris,
      mediaFileIds,
      transcript,
      isBackgroundMusicEnabled: enableBackgroundMusic,
    });
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getUGCAdGeneratesJobStatus(
  client: BackendServicesClient,
  operationId: string
): Promise<AIAdsJobStatus> {
  try {
    const result = await client.get<AIAdsJobStatus>(`${UGC_AD_GEN_URL}/${operationId}/status`);
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
