import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import { DevLogger } from "dev-logger";
import { PropsWithChildren } from "react";

import { buildFFMpegCommandLine } from "~/utils/ffmpegCmdBuilder";

import {
  FFMpegJob,
  FFMpegJobOptions,
  FFMpegJobRunner,
  FFMpegJobRunnerContext,
  FFMpegOutputFileItem,
} from "./FFMpegJobRunnerContext";

const logger = new DevLogger("[ffmpeg]");

// TODO: Self host the ffmpeg-wasm files.
const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";
let coreURL: string = "";
let wasmURL: string = "";

async function executeFFMpegWasmJob(job: FFMpegJob, options: FFMpegJobOptions): Promise<void> {
  const ffmpeg = new FFmpeg();
  const abortHandler = () => ffmpeg.terminate();
  const logHandler = ({ type, message }: { type: string; message: string }) => {
    logger.log("log:", type, message);
  };
  const progressHandler = ({ progress, time }: { progress: number; time: number }) => {
    logger.log("progress:", progress, "time:", time);
    options.onProgress?.("executing", progress, time);
  };
  options.abortSignal?.addEventListener("abort", abortHandler);

  try {
    ffmpeg.on("progress", progressHandler);
    ffmpeg.on("log", logHandler);
    options.onProgress?.("loading", 0, 0);

    // According to ffmpeg-wasm documentations, fetching the core and wasm files and creating
    // Blob URLs prevents CORS-related issues. If we host them on the same domain we might use
    // the URLs directly.
    if (!coreURL) {
      logger.log("loading ffmpeg-core.js...");
      coreURL = await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript");
    }
    if (!wasmURL) {
      logger.log("loading ffmpeg-core.wasm...");
      wasmURL = await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, "application/wasm");
    }
    logger.log("loading ffmpeg...");
    await ffmpeg.load({ wasmURL, coreURL });

    options.onProgress?.("starting", 0, 0);
    const commandLine = buildFFMpegCommandLine(job.task);
    for (const inputFile of job.inputFiles) {
      await ffmpeg.writeFile(
        inputFile.fileName,
        await fetchFile(inputFile.contents as string | Blob)
      );
    }
    logger.log("executing", commandLine);
    const status = await ffmpeg.exec(commandLine);

    if (status !== 0) {
      const error = new Error("Encoding failed");
      logger.error("finished with code", status);
      options.onJobFailed(error);
      return;
    }
    logger.log("finished");

    const outputItems: FFMpegOutputFileItem[] = [];

    for (const outputFileSpec of job.outputFiles) {
      const fileData = await ffmpeg.readFile(outputFileSpec.fileName).catch((e) => {
        logger.log("error", e);
        return null;
      });
      if (!fileData) {
        continue;
      }
      const file = new File([fileData], outputFileSpec.fileName, {
        type: outputFileSpec.mimeType || "application/octet-stream",
      });
      const url = URL.createObjectURL(file);
      outputItems.push({
        fileName: outputFileSpec.fileName,
        url,
        size: file.size,
        isObjectUrl: true,
      });
    }
    logger.log("signaling job finished", outputItems);
    options.onJobCompleted({ outputUrls: outputItems });
  } catch (error) {
    logger.error("error:", error);
    const errorObject = error instanceof Error ? error : new Error("Unknown error");
    options.onJobFailed(errorObject);
  } finally {
    options.abortSignal?.removeEventListener("abort", abortHandler);
    ffmpeg.off("log", logHandler);
    ffmpeg.off("progress", progressHandler);
    ffmpeg.terminate();
  }
}

const runner: FFMpegJobRunner = {
  executeJob: executeFFMpegWasmJob,
};

export function FFMpegWasmJobRunnerProvider({ children }: PropsWithChildren) {
  return (
    <FFMpegJobRunnerContext.Provider value={runner}>{children}</FFMpegJobRunnerContext.Provider>
  );
}
