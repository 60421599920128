import { styled } from "~/theme";

import { Text } from "../Text";

export const SideFormLabelText = styled(Text, {
  display: "flex",
  flexDirection: "column",
  defaultVariants: {
    color: "grey-150",
    variant: "body-1",
  },
});

export const SideFormLabelTextContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: 16,
  alignItems: "center",
});

export const SideFormLabelTag = styled(Text, {
  backgroundColor: "$grey-700",
  borderRadius: 6,
  padding: "4px 8px",
  defaultVariants: {
    variant: "body-4",
  },
});
