import type { ProjectCreationSettings } from "~/context/ProjectCreationContext";

import type { TranslationMode } from "../utils/analytics/ProductEvents";

export const getTranslationMode = (settings?: ProjectCreationSettings): TranslationMode => {
  return settings?.shouldDub
    ? "autodub"
    : (settings?.translationLanguageCode ?? "none") !== "none"
    ? "captions_only"
    : "none";
};
