import { PseudoRandomGenerator } from "stable-pseudo-rng";

export function shuffleArrayPrng<T>(array: Array<T>, prng: PseudoRandomGenerator) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(prng.next() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

export function removeArrayItemPrng<T>(
  array: Array<T>,
  prng: PseudoRandomGenerator
): T | undefined {
  if (array.length === 0) {
    return undefined;
  }
  const index = Math.floor(prng.next() * array.length);
  const [item] = array.splice(index, 1);
  return item;
}
