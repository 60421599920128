import { HttpResponse, delay, http } from "msw";

import { proxied } from "../utils";

import { adSetListResponse } from "./adSetListResponse.mock";
import { adSetScriptGenerateResponse } from "./adSetScriptResponse.mock";
import { generatedAdSetDraftResponse } from "./generatedAdSetDraftResponse.mock";

let generatedMusicPollCount = 0;
const creatorAudios: Record<string, unknown>[] = [];
const creatorAudioPollCount: Record<string, number> = {};

export const adsStudioHandlers = [
  http.post("*/studios/ad-set/create", async () => {
    await delay(3 * 1000);
    return HttpResponse.json(proxied(generatedAdSetDraftResponse()));
  }),

  http.post("*/studios/ad-set/poll", async () => {
    await delay(3 * 1000);
    return HttpResponse.json(
      // by the time we poll, the script should be generated
      proxied(generatedAdSetDraftResponse({ script: adSetScriptGenerateResponse }))
    );
  }),

  http.post("*/studios/ad-set/generate", async () => {
    await delay(3 * 1000);

    return HttpResponse.json(proxied({ state: "COMPLETE" }));
  }),

  http.post("*/studios/ad-set/script/generate", async () => {
    await delay(3 * 1000);
    return HttpResponse.json(proxied({ script: adSetScriptGenerateResponse }));
  }),

  http.post("*/studios/ad-set/script/body/regenerate", async () => {
    await delay(3 * 1000);
    return HttpResponse.json(
      proxied({
        script: {
          ...adSetScriptGenerateResponse,
          body: `${adSetScriptGenerateResponse.body} (rand: ${Math.floor(Math.random() * 10)})`,
        },
      })
    );
  }),

  http.post("*/studios/ad-set/script/hooks/regenerate", async () => {
    await delay(3 * 1000);
    return HttpResponse.json(
      proxied({
        script: {
          ...adSetScriptGenerateResponse,
          hooks: adSetScriptGenerateResponse.hooks.map((hook) => ({
            ...hook,
            text: `${hook.text} (rand: ${Math.floor(Math.random() * 10)})`,
          })),
        },
      })
    );
  }),

  http.post("*/studios/ad-set/remix/hooks/create", async () => {
    await delay(3 * 1000);
    return HttpResponse.json(
      proxied({
        script: {
          ...adSetScriptGenerateResponse,
          originalHook: "This is the original hoook text",
        },
      })
    );
  }),

  http.post("*/studios/ad-set/voice-preview/create", async (ctx) => {
    await delay(3 * 1000);

    const { creators } = (await ctx.request.json()) as Record<string, unknown>;

    (creators as Array<{ id: string; variantId: string }>).forEach((creator) => {
      creatorAudios.push({
        state: "PROCESSING",
        operationId: `operation_${Math.floor(Math.random() * 1000)}`,
        creatorId: creator.id,
        creatorVariantId: creator.variantId,
        hook: {
          text: "You can now create an AI Avatar like me to make whatever content you need effortlessly. Let me show you how.",
          id: "hook_1",
        },
        transcript: "",
        body: "",
        audioFileUrl: "",
      });
    });

    return HttpResponse.json(proxied({ operationId: "operation_123" }));
  }),

  http.post("*/studios/ad-set/voice-preview/poll", async () => {
    await delay(1 * 1000);

    for (let i = 0; i < creatorAudios.length; i++) {
      if ((creatorAudioPollCount[i] ?? 0) < 3) {
        creatorAudioPollCount[i] = (creatorAudioPollCount[i] ?? 0) + 1;
        creatorAudios[i].state = "PROCESSING";
      } else {
        creatorAudios[i].state = "COMPLETE";
      }
    }
    return HttpResponse.json(proxied({ creatorAudios }));
  }),

  http.post("*/studios/ads/music/fetch", async () => {
    await delay(3 * 1000);
    return HttpResponse.json(
      proxied({
        songs: [
          {
            id: "music_123",
            title: "Upbeat pop synth beat",
            url: "https://example.com/acoustic.mp3",
            duration: 12,
          },
          {
            id: "music_456",
            title: "Mellow acoustic ballad",
            url: "https://example.com/acoustic.mp3",
            duration: 12,
          },
          {
            id: "music_789",
            title: "Upbeat folk rhythm",
            url: "https://example.com/acoustic.mp3",
            duration: 12,
          },
          {
            id: "music_101",
            title: "Soulful fingerpicking melody",
            url: "https://example.com/acoustic.mp3",
            duration: 12,
          },
        ],
      })
    );
  }),

  http.post("*/studios/ads/music/generate", async () => {
    await delay(3 * 1000);
    return HttpResponse.json(
      proxied({
        operationId: "operation_123",
      })
    );
  }),

  http.post("*/studios/ads/music/poll", async () => {
    await delay(3 * 1000);

    if (generatedMusicPollCount++ < 3) {
      return HttpResponse.json(
        proxied({
          state: "PENDING",
        })
      );
    }

    return HttpResponse.json(
      proxied({
        state: "COMPLETE",
        music: {
          id: "music_abc",
          title: "Custom generated music",
          audioFileUrl: "https://example.com/acoustic.mp3",
          duration: 12,
        },
      })
    );
  }),

  http.post("*/studios/ad-set/list", async () => {
    await delay(3 * 1000);

    return HttpResponse.json(proxied({ adSets: adSetListResponse }));
  }),

  http.post("*/studios/ad-set/remix/create", async () => {
    await delay(3 * 1000);

    return HttpResponse.json(proxied({ id: "ad_set_123" }));
  }),

  http.post("*/studios/ad-set/remix/generate", async () => {
    await delay(3 * 1000);

    return HttpResponse.json(proxied({ succeeded: true }));
  }),

  http.post("*/studios/ad-set/script/update", async () => {
    await delay(3 * 1000);

    return HttpResponse.json(proxied({ succeeded: true }));
  }),
];
