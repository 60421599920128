import { ImageAnimations, AnimationStyle } from "captions-engine";

import { randPick } from "~/utils/rand-pick";

import { CAPTION_TEMPLATES } from "../../../../project/services/CaptionStylePreset";
import { TransitionAnimationGroupId } from "../../../../project/services/PagAnimations/TransitionAnimations.config";
import {
  AIImageLayout,
  AIOverlayAnimationStyle,
  AISceneStyle,
  GlobalStyleConfig,
  SceneStyleConfig,
  TransitionConfig,
} from "../AIEditStyles.types";

const PAPER_SOUND_VOLUME = 0.2;

export const PAPER_PREVIEW_URL = "https://captions-cdn.xyz/desktop-site/ai-edit-paper-preview.webm";

export const PAPER_SCENE_STYLE_CONFIG = {
  // Paper Talking Head
  "paper-talking-head": {
    builder: (): AISceneStyle => {
      return {
        id: "paper-talking-head",
        capsPosition: "avoid-head",
      };
    },
  },

  // Paper Dolly Zoom
  "paper-dolly-zoom": {
    builder: (prng): AISceneStyle => {
      const collageBackgroundIds = [
        "background_collage_1",
        "background_collage_2",
        "background_collage_3",
        "background_collage_4",
        "background_collage_5",
      ];

      const collageBackgroundId =
        randPick(collageBackgroundIds, prng.next()) ?? collageBackgroundIds[0];

      return {
        id: "paper-dolly-zoom",
        isSingleSubject: true,
        sceneBackground: {
          assetId: collageBackgroundId,
          cutout: true,
          zoomStyle: {
            foreground: "continuous",
            background: "center-zoom-out",
          },
        },
        capsPosition: "avoid-head",
      };
    },
  },

  // Paper Newspaper
  "paper-newspaper": {
    builder: (prng): AISceneStyle => {
      function getNewspaperPagFile(word: string) {
        const variants = ["A", "B", "C"];
        const variant = randPick(variants, prng.next()) || variants[0];

        const pagFileId = (() => {
          switch (true) {
            case word.length < 3:
              return `Newspaper_Blank_2_Letter_${variant}`;
            case word.length < 6:
              return `Newspaper_Blank_5_Letter_${variant}`;
            case word.length < 9:
              return `Newspaper_Blank_8_Letter_${variant}`;
            default:
              return `Newspaper_Blank_11_Letter_${variant}`;
          }
        })();

        return {
          holdId: `${pagFileId}_HOLD`,
          outroId: `${pagFileId}_OUT`,
        };
      }

      return {
        id: "paper-newspaper",
        isOnlyUseOnce: true,
        hideCaptions: true,
        wordAnimation: (word) => getNewspaperPagFile(word),
      };
    },
  },

  // Paper Full Screen Images
  "paper-fullscreen-images": {
    isImageStyle: true,
    builder: (prng): AISceneStyle => {
      const letterVariants = ["A", "B", "C", "D", "E"];
      const numberVariants = [1, 2, 3, 4];
      const letterVariant = randPick(letterVariants, prng.next()) || letterVariants[0];
      const numberVariant = randPick(numberVariants, prng.next()) || numberVariants[0];

      return {
        overlay: `overlay_paper_crumpleframe_${numberVariant}${letterVariant}`,
        overlayBlend: "source-over",
        id: "paper-fullscreen-images",
        imageRules: {
          style: "PAPER_NEWSPAPER",
          imageLayout: "fullscreen",
          imagePositionType: "b-roll",
          imageAnimations: {
            animationIn: AnimationStyle["animationStyleImmediate"],
            activeAnimation: AnimationStyle["animationStyleUnspecified"],
            animationOut: AnimationStyle["animationStyleImmediate"],
          },
          numImages: "duration-floor",
          zoomStyle: "center-zoom-in",
        },
        capsPosition: "middle",
      };
    },
  },

  // Paper Chalk
  "paper-chalk": {
    isImageStyle: true,
    builder: (prng): AISceneStyle => {
      const motionBackgroundIds = [
        "background_paper_blue",
        "background_paper_black",
        "background_paper_red",
        "background_paper_yellow",
        "background_paper_white",
      ];
      const motionBackgroundId =
        randPick(motionBackgroundIds, prng.next()) || motionBackgroundIds[0];

      const chalkLayouts: AIImageLayout[] = [
        "paper_chalk_layout_a",
        "paper_chalk_layout_b",
        "paper_chalk_layout_c",
      ];
      const layoutId = randPick(chalkLayouts, prng.next()) || chalkLayouts[0];

      const shouldUseTitle = layoutId === "paper_chalk_layout_c";
      const shouldUseChalkStyle = [
        "background_paper_blue",
        "background_paper_black",
        "background_paper_red",
      ].includes(motionBackgroundId);

      const titleAnimation: AIOverlayAnimationStyle = {
        introId: "CHALK_IN",
        holdId: "CHALK_DURATION",
        positioning: {
          type: "freeform",
          centerX: 0.5,
          centerY: 0.5,
        },
        titleReplacement: {
          color: shouldUseChalkStyle ? undefined : { red: 0, blue: 0, green: 0 },
          numberOfLines: "auto",
        },
      };

      return {
        id: "paper-chalk",
        sceneBackground: {
          assetId: motionBackgroundId,
        },
        hideCaptions: shouldUseTitle,
        capsPosition: "bottom-half",
        animation: shouldUseTitle ? titleAnimation : undefined,
        imageRules: {
          style: shouldUseChalkStyle ? "PAPER_CHALK" : "PAPER_CHARCOAL",
          numImages: layoutId == "paper_chalk_layout_c" ? 2 : 3,
          imageLayout: layoutId,
          imagePositionType: "freeform",
          imageAnimations: {
            type: "pag",
            animationIn: { id: "CHALKDRAW1_IN" },
            animationOut: { id: "CHALKDRAW1_OUT" },
            activeAnimation: { id: "CHALKDRAW_MID" },
          },
        },
        sound: {
          assetId: "sfx_pencil_writing_on_paper_01.wav",
          volume: PAPER_SOUND_VOLUME,
          duration: 0.94,
        },
      };
    },
  },

  // Paper Collage
  "paper-collage": {
    isImageStyle: true,
    builder: (prng): AISceneStyle => {
      const motionBackgroundIds = [
        "background_collage_1",
        "background_collage_2",
        "background_collage_3",
        "background_collage_4",
        "background_collage_5",
      ];
      const collageLayouts: AIImageLayout[] = ["collage_layout_a", "collage_layout_b"];
      const motionBackgroundId = randPick(motionBackgroundIds, prng.next())!;
      const layoutId = randPick(collageLayouts, prng.next())!;
      const imageAnimations: ImageAnimations = {
        type: "pag",
        animationIn: {
          id: "plainFold1",
        },
        animationOut: {
          id: "plainFold1Reverse",
        },
        activeAnimation: {
          id: "paperRotate",
        },
      };
      const titleAnimation: AIOverlayAnimationStyle = {
        introId: "label_v2_IN",
        holdId: "label_v2_HOLD",
        positioning: {
          type: "fullscreen",
          fit: "contain",
        },
        titleReplacement: {
          numberOfLines: 2,
          textShadowLayer: true,
        },
      };
      const hideCaptions = layoutId === "collage_layout_a";
      return {
        id: "paper-collage",
        sceneBackground: {
          assetId: motionBackgroundId,
        },
        hideCaptions,
        capsPosition: "bottom-half",
        imageRules: {
          style: "PAPER_RETRO",
          imageLayout: layoutId,
          numImages: 2,
          imageAnimations,
        },
        staticImageRules: {
          assetIds: ["image_red_circle", "image_green_circle"],
          imageLayout: layoutId,
          imageAnimations,
        },
        sound: {
          assetId: "sfx_crumbled_paper_short_01.wav",
          volume: PAPER_SOUND_VOLUME,
          duration: 1.133,
        },
        animation: hideCaptions ? titleAnimation : undefined,
      };
    },
  },

  // "paper-mems-collage": {
  //   isImageStyle: true,
  //   builder: (): AISceneStyle => {
  //     return {
  //       id: "paper-mems-collage",
  //     };
  //   },
  // },

  // "paper-keyword": {
  //   isImageStyle: true,
  //   builder: (): AISceneStyle => {
  //     return {
  //       id: "paper-keyword",
  //     };
  //   },
  // },
} as const satisfies SceneStyleConfig;

export type PaperSceneId = keyof typeof PAPER_SCENE_STYLE_CONFIG;

export const PAPER_GLOBAL_STYLE_CONFIG: GlobalStyleConfig = [];

export const PAPER_TRANSITIONS: TransitionConfig = {
  builder: (currentSceneId, nextSceneId) => {
    const allowedTransitions: TransitionAnimationGroupId[] = [
      "swipeUp",
      "left",
      "leak",
      "flash",
      "flare",
      "film",
    ];

    if (["paper-talking-head", "paper-dolly-zoom"].includes(currentSceneId)) {
      if (nextSceneId === "paper-collage" || nextSceneId === "paper-mems-collage") {
        return ["left"];
      }
      if (nextSceneId === "paper-newspaper") {
        return ["zoom"];
      }
      if (nextSceneId === "paper-talking-head") {
        return ["paperTearOverlay", "paperTearSplit", "paperTearWipe"];
      }
    }

    if (
      [
        "paper-chalk",
        "paper-collage",
        "paper-newspaper",
        "paper-fullscreen-images",
        "paper-mems-collage",
        "paper-keyword",
      ].includes(currentSceneId)
    ) {
      if (nextSceneId === "paper-newspaper") {
        return ["zoom"];
      }
      if (nextSceneId === "paper-collage" || nextSceneId === "paper-chalk") {
        return ["left"];
      }
    }

    return allowedTransitions;
  },
  library: [
    // TODO: slideDown
    {
      assetId: "leak",
      volume: 0,
    },
    {
      assetId: "flash",
      volume: 0,
    },
    {
      assetId: "flare",
      volume: 0,
    },
    {
      assetId: "film",
      volume: 0,
    },
    {
      assetId: "swipeUp",
      volume: 0,
      outDuration: 13 / 30,
      inDuration: 11 / 30,
    },
    {
      assetId: "left",
      volume: 0,
      outDuration: 11 / 30,
      inDuration: 13 / 30,
    },
    {
      assetId: "zoom",
      volume: 0,
      outDuration: 9 / 30,
      inDuration: 15 / 30,
    },
    {
      assetId: "paperTearOverlay",
      volume: 0,
    },
    {
      assetId: "paperTearSplit",
      volume: 0,
    },
    {
      assetId: "paperTearWipe",
      volume: 0,
    },
  ],
};

// ID for the "Energy" caption template
export const PAPER_CAPTION_TEMPLATE = CAPTION_TEMPLATES.Energy;
