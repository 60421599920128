import { CaptionStylePreset, loadCaptionFont } from "captions-engine";
import { useCallback, useEffect, useState } from "react";

import { CaptionSettings } from "~/database/database.types";
import { getCanonicalFontName, getFontFamily } from "~/theme/fonts/subtitles";

import { captionStylePresetSchema, CaptionTemplate } from "../services/CaptionStylePreset";
import { CaptionPage, CaptionWord, generateCaptionPages } from "../utils/captionProcessing";

import { useCaptionStyle } from "./useCaptionStyle";
import { useCaptionWordsAndPhrases } from "./useCaptionWordsAndPhrases";

export type CaptionWordUpdateData = Partial<Omit<CaptionWord, "id" | "startTime" | "endTime">>;

export interface CaptionsProps {
  isLandscape?: boolean;
}

export function useCaptions({ isLandscape }: CaptionsProps = {}) {
  const captionStyle = useCaptionStyle();
  const data = useCaptionWordsAndPhrases();
  const [stylePreset, setStylePresetInternal] = useState<CaptionStylePreset | null>();
  const [captionPages, setCaptionPages] = useState<CaptionPage[] | null>(null);
  const [hideCaptions, setHideCaptions] = useState(false);

  const setStylePreset = useCallback(
    async (preset: CaptionStylePreset | null, countryCode: string) => {
      if (!preset) {
        setStylePresetInternal(null);
      } else {
        const fontFamily = getFontFamily(preset.font.fontName, countryCode);

        await loadCaptionFont(fontFamily, preset)
          .catch((error) => console.warn(error))
          .finally(() =>
            setStylePresetInternal((oldPreset) => {
              if (oldPreset?.font?.fontSize && preset?.font?.fontSize) {
                const fontScale = oldPreset.font.fontSize / preset.font.fontSize;
                captionStyle.setSizeFactor((oldSizeFactor) => oldSizeFactor * fontScale);
              }

              return preset;
            })
          );
      }
    },
    [captionStyle.setSizeFactor]
  );

  const setTemplate = useCallback(
    async (template: CaptionTemplate | null, countryCode: string) => {
      if (!template) {
        await setStylePreset(null, countryCode);
      } else {
        captionStyle.setTemplate(template);
        await setStylePreset(template.style.content, countryCode);
      }
    },
    [setStylePreset, captionStyle.setTemplate]
  );

  const restoreFromSettingsEntity = useCallback(
    async (settings: CaptionSettings, languageCode: string) => {
      captionStyle.restoreFromSettingsEntity(settings);

      if (settings.preset) {
        const parsedPreset = captionStylePresetSchema.safeParse(settings.preset);
        if (parsedPreset.success) {
          parsedPreset.data.font.fontName = getCanonicalFontName(parsedPreset.data.font.fontName);
          await setStylePreset(parsedPreset.data, languageCode ?? "en-US");
        }
      }
    },
    [captionStyle, setStylePreset]
  );

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    if (data.words && stylePreset) {
      const pages = generateCaptionPages(data.words, stylePreset, {
        isLandscape,
      });
      setCaptionPages(pages);
    } else {
      setCaptionPages(null);
    }

    // if randomizeRotation is true, "turn off" manual rotation by setting it to undefined
    if (stylePreset?.randomizeRotation) {
      captionStyle.setRotation(undefined);
    }
  }, [data.words, stylePreset, captionStyle.emphasisSettings?.keywords?.supersize, isLandscape]);

  return {
    ...captionStyle,
    ...data,
    stylePreset,
    setStylePreset,
    pages: captionPages,
    hideCaptions,
    setHideCaptions,
    setTemplate,
    restoreFromSettingsEntity,
  };
}

export type Captions = ReturnType<typeof useCaptions>;
