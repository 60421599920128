import * as z from "zod";

// start face detection request
export const DetectFacesStartRequestSchema = z.object({
  fileId: z.string(),
});

export type DetectFacesStartRequest = z.infer<typeof DetectFacesStartRequestSchema>;

// start face detection response
export const DetectFacesStartResSchema = z.object({
  jobId: z.string(),
});

export type DetectFacesStartResponse = z.infer<typeof DetectFacesStartResSchema>;

// face detection job poll response
// type JobStatus = z.infer<typeof JobStatusSchema>;
const JobStatusSchema = z.enum([
  "idle",
  "canceled",
  "started",
  "progress",
  "processing",
  "error",
  "finished",
]);

const DetectedFaceSchema = z.tuple([z.number(), z.number(), z.number(), z.number()]);

const FaceDetectedFrameSchema = z.object({
  id: z.number(),
  faces: DetectedFaceSchema.array(),
});

const DetectFacesResultSchema = z.object({
  fps: z.number(),
  frames: FaceDetectedFrameSchema.array(),
});

export const DtoRes_GetDetectFacesSchema = z.object({
  status: JobStatusSchema,
  result: DetectFacesResultSchema.optional().nullable(),
});
export type DetectFacesJobState = z.infer<typeof DtoRes_GetDetectFacesSchema>;
