/**
 * Get a heuristic string matching score
 * For a given word, searching with given search string
 * Fork of https://github.com/joshaven/string_score
 */
export const stringScore = function (word: string, search: string) {
  // If the string is equal to the search, perfect match.
  if (word === search) {
    return 1;
  }

  //if it's not a perfect match and is empty return 0
  if (search === "") {
    return 0;
  }

  let runningScore = 0;

  let charScore;
  let finalScore;

  const lWord = word.toLowerCase();
  const lSearch = search.toLowerCase();
  const strLength = word.length;
  const searchLength = search.length;
  let idxOf;
  let startAt = 0;
  let fuzzies = 1;

  const fuzziness = 0.5;
  const fuzzyFactor = 1 - fuzziness;

  // Walk through search and add up scores.
  // Code duplication occurs to prevent checking fuzziness inside for loop
  for (let i = 0; i < searchLength; i += 1) {
    // Find next first case-insensitive match of a character.
    idxOf = lWord.indexOf(lSearch[i], startAt);

    if (idxOf === -1) {
      fuzzies += fuzzyFactor;
    } else {
      if (startAt === idxOf) {
        // Consecutive letter & start-of-string Bonus
        charScore = 0.7;
      } else {
        charScore = 0.1;

        // Acronym Bonus
        // Weighing Logic: Typing the first character of an acronym is as if you
        // preceded it with two perfect character matches.
        if (word[idxOf - 1] === " ") {
          charScore += 0.8;
        }
      }

      // Same case bonus.
      if (word[idxOf] === search[i]) {
        charScore += 0.1;
      }

      // Update scores and startAt position for next round of indexOf
      runningScore += charScore;
      startAt = idxOf + 1;
    }
  }

  // Reduce penalty for longer strings.
  finalScore = (0.5 * (runningScore / strLength + runningScore / searchLength)) / fuzzies;

  if (lSearch[0] === lWord[0] && finalScore < 0.85) {
    finalScore += 0.15;
  }

  return finalScore;
};
