import { HttpResponse, delay, http } from "msw";

import { proxied, makeWithCounter } from "../utils";

import brandKitData from "./brandKitData.mock.json";
import { socialPlanAlmostCompleteResponse } from "./socialPlanAlmostCompleteResponse.mock";
import { socialPlanCompleteResponse } from "./socialPlanCompleteResponse.mock";

// these are dumb and we will build a better mock switching system soon
let hasSubmittedIngest = false;
let hasUpdatedBrandKit = false;
let polledIngestingCount = 0;

let hasStartedGeneratingSocialPlan = false;
let polledSocialPlanCount = 0;

let polledVideoGenerationCount = 0;

let hasExchangedOAuthCode = false;

export const socialStudioHandlers = [
  http.post("*/studios/brand-kit/get", () => {
    if (hasUpdatedBrandKit) {
      return HttpResponse.json(
        proxied({
          state: "CONFIRMED",
          data: brandKitData,
          id: "brandkit_123",
          website: "https://www.example.com",
        })
      );
    } else if (hasSubmittedIngest) {
      polledIngestingCount++;

      if (polledIngestingCount > 5) {
        return HttpResponse.json(
          proxied({
            state: "INGESTED",
            data: brandKitData,
            id: "brandkit_123",
            website: "https://www.example.com",
          })
        );
      }

      return HttpResponse.json(
        proxied({
          data: null,
          website: "https://longwebsitename.com/asdfjasdfliewjsdf",
          state: "INGESTING",
        })
      );
    }

    return HttpResponse.json(proxied({ data: null, state: "NONE" }));
  }),

  http.post("*/studios/brand-kit/ingest/submit", () => {
    hasSubmittedIngest = true;
    polledIngestingCount = 0;
    return HttpResponse.json(proxied({ succeeded: true }));
  }),

  http.post("*/studios/social-plan/start", async () => {
    await delay(3 * 1000);
    hasStartedGeneratingSocialPlan = true;

    return HttpResponse.json(proxied({ succeeded: true }));
  }),

  http.post("*/studios/brand-kit/update", async () => {
    hasUpdatedBrandKit = true;

    await delay(3 * 1000);

    return HttpResponse.json(proxied({ succeeded: true }));
  }),

  http.post("*/studios/social-plan/get", () => {
    const socialPlanState = new URLSearchParams(window.location.search).get("socialPlanState");

    if (socialPlanState === "almost-done") {
      if (polledVideoGenerationCount > 6) {
        return HttpResponse.json(proxied(socialPlanCompleteResponse()));
      }

      return HttpResponse.json(proxied(socialPlanAlmostCompleteResponse()));
    } else if (socialPlanState === "done" || polledSocialPlanCount > 5) {
      return HttpResponse.json(proxied(socialPlanCompleteResponse()));
    } else if (socialPlanState === "pending" || hasStartedGeneratingSocialPlan) {
      polledSocialPlanCount++;

      return HttpResponse.json(proxied({ state: "PROCESSING", data: null }));
    }

    return HttpResponse.json(proxied({ state: "NONE", data: null }));
  }),

  http.post(
    "*/studios/social-plan/regenerate-script",
    makeWithCounter(async (count) => {
      await delay(3 * 1000);

      return proxied({
        script:
          "Hi there! I'm Dr. Yuliya Alterman.\n\nAnd I'm Dr. Alexandra Yungelson.\n\nWelcome to Soul Dental!\n\nYou might be wondering how we ended up running a multi-specialty dental practice together in the heart of Manhattan.\n\nWell, it all started on our very first day of dental school. We met, became fast friends, and quickly realized we shared a vision for the future of dentistry.\n\nThat vision? To create a practice that offers top-notch, comprehensive care while really connecting with our patients.\n\nVersion " +
          count,
      });
    })
  ),

  http.post("*/studios/social-plan/update-video", async () => {
    await delay(3 * 1000);
    return HttpResponse.json(proxied({ succeeded: true }));
  }),

  http.post("*/studios/social-plan/video-generation-state", async () => {
    await delay(3 * 1000);
    polledVideoGenerationCount++;

    if (polledVideoGenerationCount > 6) {
      // 5 each for two videos
      return HttpResponse.json(proxied({ state: "COMPLETE" }));
    }

    return HttpResponse.json(
      proxied({ state: "PROCESSING", progress: (polledVideoGenerationCount / 6) * 100 })
    );
  }),

  http.post("*/studios/oauth/connections", () => {
    if (hasExchangedOAuthCode) {
      return HttpResponse.json(
        proxied({
          linkedin: {
            autoPostEnabled: true,
            name: "Cap Joe",
          },
          tiktok: null,
          instagram: null,
        })
      );
    }

    return HttpResponse.json(proxied({ instagram: null, tiktok: null }));
  }),

  http.post("*/studios/oauth/exchange-code", async () => {
    await delay(1 * 1000);
    hasExchangedOAuthCode = true;
    return HttpResponse.json(proxied({ succeeded: true }));
  }),

  http.post("*/studios/oauth/generate-url", async () => {
    await delay(1 * 1000);
    return HttpResponse.json(
      proxied({ url: "http://localhost:3000/studio/social-login?code=123" })
    );
  }),
];
