import { createStore } from "zustand";
import { combine } from "zustand/middleware";

import { useOnChangeEffect } from "~/hooks/helpers";
import { createUseStoreWithSelector } from "~/utils/zustand-store";

interface ProjectsStoreData {
  activeProjectId?: string;
}

export const createProjectsStore = () => {
  return createStore(
    combine({} as ProjectsStoreData, (set, _get) => ({
      setActiveProjectId: (projectId: string | null) =>
        set({ activeProjectId: projectId ?? undefined }),
    }))
  );
};

const projectsStore = createProjectsStore();

export const getProjectsStore = () => projectsStore.getState();

export const useProjectsStore = createUseStoreWithSelector(projectsStore);

export const useSyncActiveProjectId = (projectId: string | null) => {
  useOnChangeEffect(projectId, (projectId) => {
    getProjectsStore().setActiveProjectId(projectId);

    return () => {
      // unmount destructor
      getProjectsStore().setActiveProjectId(null);
    };
  });
};
