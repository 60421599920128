import { createContext, useContext } from "react";
import invariant from "tiny-invariant";

export type AppPlatform = "web" | "native";

// utility type to create a map of platform-specific configurations
export type AppPlatformConfigMap<T> = Record<AppPlatform, T>;

interface AppPlatformContextType {
  platform: AppPlatform;
}

const AppPlatformContext = createContext<AppPlatformContextType | null>(null);

export function AppPlatformProvider({
  platform,
  children,
}: {
  platform: AppPlatform;
  children: React.ReactNode;
}) {
  return <AppPlatformContext.Provider value={{ platform }}>{children}</AppPlatformContext.Provider>;
}

export function useAppPlatform() {
  const context = useContext(AppPlatformContext);

  invariant(context, "useAppPlatform must be used within a AppPlatformProvider");

  return context;
}
