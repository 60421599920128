import { t } from "i18next";
import { useEffect, useMemo, useState, useCallback, useRef } from "react";

import { useToast } from "~/components";
import { useVideoFileUpload } from "~/components/AIShortsDialog/useVideoFileUpload";
import { FILE_UPLOAD_STATUS, PROJECT_TRANSCRIPT_STATUS } from "~/constants/mixpanel.constants";
import { FilmStripPath, Project } from "~/database/database.types";
import { ProjectRepository } from "~/database/repo/ProjectRepository";
import { StoredImagesRepository } from "~/database/repo/StoredImagesRepository";
import { useAnalytics } from "~/hooks/useAnalytics";
import { useFilmSprite } from "~/hooks/useFilmSprite";
import { useProjectThumbnail } from "~/hooks/useProjectThumbnail";
import { useAIEditGenerator } from "~/modules/command-list/hooks/useAIEditGenerator";
import { CLIENT_DRIVEN_AI_EDIT_STYLES } from "~/modules/command-list/services/AIEditStyles";
import {
  extractProjectStateFromCommandList,
  getEffectsStateToUpdate,
  getProjectStateToUpdateForAiEdit,
} from "~/modules/command-list/utils/extractProjectStateFromCommandList";
import { useAvailableTranscriptLanguages } from "~/modules/project/hooks/useAvailableTranscriptLanguages";
import { useCaptions } from "~/modules/project/hooks/useCaptions";
import { useCaptionStyleTemplates } from "~/modules/project/hooks/useCaptionStyleTemplates";
import { useDubbing } from "~/modules/project/hooks/useDubbing";
import { useFaces } from "~/modules/project/hooks/useFaces";
import { useProjectPersistence } from "~/modules/project/hooks/useProjectPersistence";
import { useScenes } from "~/modules/project/hooks/useScenes";
import {
  TranscriptStage,
  useTranscriptGenerator,
} from "~/modules/project/hooks/useTranscriptGenerator";
import { FaceData } from "~/modules/project/utils/faceData/face-data";
import { ProjectFaceData } from "~/modules/project/utils/faceData/face-data.types";
import {
  project_creation_status,
  new_project_created,
  TranslationMode,
  ProjectCreationOperationIds,
} from "~/utils/analytics/ProductEvents";
import { getTranslationMode } from "~/utils/getTranslationMode";
import { VideoFileMetadata } from "~/utils/getVideoFileMetadata";
import { getDefaultProjectTitle } from "~/utils/projectInfo";
import { Clip } from "~/utils/videoClips";

import { ProjectCreationProps, ProjectCreationStage } from "./ProjectCreationContext.types";

const DEFAULT_LANGUAGE = "en-US";
const DEFAULT_TRANSLATION_LANGUAGE = "none";

export interface ProjectCreationFromVideoProps extends ProjectCreationProps {
  setProjectAttributesUp: (
    projectId: string,
    videoFileMetadata: VideoFileMetadata | null,
    creationStage: ProjectCreationStage,
    progress: number,
    thumbnailPath?: string
  ) => void;
}

// for creating a single project
export const ProjectCreationFromVideo = (projectCreation: ProjectCreationFromVideoProps) => {
  const { projectId, file, onError, settings, setProjectAttributesUp, projectType } =
    projectCreation;
  const {
    uploadVideoFile,
    uploadedVideoFile,
    uploadProgress,
    videoFileId,
    videoFileMetadata,
    isUploadFinished,
    error: fileUploadError,
  } = useVideoFileUpload();
  const { createThumbnail } = useProjectThumbnail();
  const { createSprite } = useFilmSprite();
  const analytics = useAnalytics();
  const operationIds = useRef<ProjectCreationOperationIds>({});

  const {
    transcript,
    transcriptionJobId,
    translateId,
    progress: transcriptionProgress,
    generateTranscript,
    stage: transcriptionStage,
    errorMessage: transcriptionErrorMessage,
  } = useTranscriptGenerator("", projectId, true);

  const { dubbingState, startDubbing } = useDubbing();
  const { startScenes } = useScenes();
  const { startDetectFaces, detectFacesState } = useFaces();

  const [progress, setProgress] = useState<number>(0);
  const [createdInitialProject, setCreatedInitialProject] = useState<boolean>(false);
  const [creationStage, setCreationStage] = useState<ProjectCreationStage>("start");
  const [thumbnailPath, setThumbnailPath] = useState<string | undefined>();
  const [filmstripPaths, setFilmstripPaths] = useState<FilmStripPath[] | undefined>();
  const [filmstripPPS, setFilmstripPPS] = useState<number | undefined>();
  const [clips, setClips] = useState<Clip[] | undefined>();
  const [faces, setFaces] = useState<ProjectFaceData | undefined>();

  const captions = useCaptions();
  const { captionStyleTemplates } = useCaptionStyleTemplates();
  const projectPersistence = useProjectPersistence(projectId);
  const { codeToLanguage } = useAvailableTranscriptLanguages();
  const toast = useToast();

  //  analytics tracking
  const mountTime = useMemo(() => new Date().getTime(), []);
  const settingsTime = useMemo(() => new Date().getTime(), [settings]);
  const trackingMeta = useMemo(() => {
    const translationMode: TranslationMode = getTranslationMode(settings);

    return {
      project_id: projectId,
      video_info: {
        width: videoFileMetadata?.width ?? 0,
        height: videoFileMetadata?.height ?? 0,
        duration: videoFileMetadata?.duration ?? 0,
        size: videoFileMetadata?.size ?? 0,
        codec_name: videoFileMetadata?.codecName ?? "unknown",
        extension: videoFileMetadata?.extension ?? "unknown",
      },
      spoken_language: codeToLanguage(settings?.spokenLanguageCode ?? ""),
      translation_language: codeToLanguage(settings?.translationLanguageCode ?? ""),
      translation_mode: translationMode,
    };
  }, [videoFileMetadata, settings]);

  // if we are waiting until dubbing is done
  const waitToDub = useMemo(() => {
    return settings?.shouldDub && dubbingState.status !== "finished";
  }, [dubbingState.status, settings?.shouldDub]);

  const aiEditGenerator = useAIEditGenerator({
    onError: (error, aiEditOperationIds) => {
      operationIds.current = { ...operationIds.current, ...aiEditOperationIds };
      analytics.track(PROJECT_TRANSCRIPT_STATUS, {
        status: "error",
        message: error.message,
        transcriptionJobId,
        project_type: projectType,
        ...operationIds.current,
      });
      setCreationStage("error");
      onError();
    },
    onFinish: (aiEditData, externalAssets, transcript, aiEditOperationIds) => {
      operationIds.current = { ...operationIds.current, ...aiEditOperationIds };
      const { words, phrases, phraseAudios } = captions.generateFromTranscript(transcript);
      const { commandList } = aiEditData;
      const spokenLanguageCode = settings?.spokenLanguageCode ?? DEFAULT_LANGUAGE;
      const translationLanguageCode =
        settings?.translationLanguageCode ?? DEFAULT_TRANSLATION_LANGUAGE;
      const countryCode =
        translationLanguageCode === "none" ? spokenLanguageCode : translationLanguageCode;
      extractProjectStateFromCommandList(
        commandList,
        captionStyleTemplates,
        {
          words,
          phrases,
          phraseAudios,
          countryCode,
        },
        {
          onLookupAsset(assetId) {
            const asset = externalAssets.find((item) => item.id === assetId);
            return asset ?? null;
          },
          onStoreAsset(blob, name, type, sourceURL) {
            return StoredImagesRepository.addImageBlob({
              blob,
              name,
              type,
              sourceURL,
            });
          },
          onFetchAssetFromCache(sourceURL) {
            return StoredImagesRepository.getImageBySourceURL(sourceURL);
          },
        }
      )
        .then(async (stateToAdd) => {
          await projectPersistence.addCaptions(
            spokenLanguageCode,
            translationLanguageCode,
            stateToAdd.captions.words,
            stateToAdd.captions.phrases,
            stateToAdd.captions.phraseAudios,
            stateToAdd.captionSettings
          );
          const shouldUseAIEditor = Boolean(
            CLIENT_DRIVEN_AI_EDIT_STYLES.find((item) => item.id === aiEditData.styleId)
          );
          await projectPersistence.updateProject(
            getProjectStateToUpdateForAiEdit(stateToAdd, aiEditData, faces, !shouldUseAIEditor)
          );
          await projectPersistence.addEffects(getEffectsStateToUpdate(stateToAdd));
        })
        .then(() => setCreationStage("finished"))
        .catch(() => setCreationStage("error"));
    },
  });

  const getErrorMessage = useCallback(() => {
    return (
      fileUploadError ||
      transcriptionErrorMessage ||
      (dubbingState.status === "error" ? dubbingState.errorMessage : "")
    );
  }, [fileUploadError, transcriptionErrorMessage, dubbingState]);

  useEffect(() => {
    const sinceFileSelectionTime = (new Date().getTime() - mountTime) / 1000;
    const sinceSettingsTime = !settings ? 0 : (new Date().getTime() - settingsTime) / 1000;
    const { project_id, video_info, spoken_language, translation_language, translation_mode } =
      trackingMeta;
    const error = creationStage === "error" ? getErrorMessage() : undefined;

    analytics.track(
      ...project_creation_status({
        video_duration_seconds: videoFileMetadata?.duration ?? 0,
        video_file_id: videoFileId,
        status: creationStage === "finished" ? "success" : creationStage,
        project_id,
        translation_mode,
        error,
        project_type: projectType,
        latency_file_selection: sinceFileSelectionTime,
        latency_settings_set: sinceSettingsTime,
        video_info,
        spoken_language,
        translation_language,
        ...operationIds.current,
      })
    );

    if (creationStage === "finished") {
      analytics.track(
        ...new_project_created({
          video_duration_seconds: videoFileMetadata?.duration ?? 0,
          project_id,
          project_type: projectType,
          video_size: videoFileMetadata?.size,
          aspect_ratio:
            typeof videoFileMetadata?.height === "number"
              ? videoFileMetadata?.height > videoFileMetadata?.width
                ? "portrait"
                : "landscape"
              : undefined,
          language: spoken_language,
          translation_mode,
          ...operationIds.current,
        })
      );
    }
  }, [creationStage]);

  useEffect(() => {
    if (!uploadedVideoFile) {
      return;
    }
    analytics.track(FILE_UPLOAD_STATUS, {
      status: "success",
      project_type: projectType,
    });
  }, [uploadedVideoFile]);

  // progress heuristic
  useEffect(() => {
    let divisor = 2;
    if (settings?.shouldDub) {
      divisor = divisor + 1;
    }
    if (settings?.aiEdit) {
      divisor = divisor + 1;
    }

    let dubbingNorm = 0;
    if (settings?.shouldDub) {
      if (dubbingState.status === "progress") {
        dubbingNorm = dubbingState.progress / 100;
      }
      if (dubbingState.status === "finished") {
        dubbingNorm = 1;
      }
    }

    const transcriptionNorm = transcript ? 1 : transcriptionProgress / 100;
    const uploadNorm = uploadProgress / (videoFileMetadata?.size ?? 1);
    const overallProgress = (uploadNorm + transcriptionNorm + dubbingNorm) / divisor;
    setProgress(100 * overallProgress);
  }, [uploadProgress, transcriptionProgress, transcript, settings?.shouldDub, dubbingState]);

  useEffect(() => {
    setProjectAttributesUp(projectId, videoFileMetadata, creationStage, progress, thumbnailPath);
  }, [videoFileMetadata, setProjectAttributesUp, progress, creationStage, thumbnailPath]);

  // start uploading the file right away
  useEffect(() => {
    analytics.track(FILE_UPLOAD_STATUS, {
      status: "start",
      project_type: projectType,
    });
    uploadVideoFile({ videoFile: file }).catch((reason: string | Error) => {
      const message = typeof reason === "string" ? reason : reason.message;
      toast.add(message, {
        duration: 5000,
        severity: "error",
      });
      setCreationStage("error");
      onError();
    });
    setCreationStage("pre-upload");
  }, []);

  useEffect(() => {
    if (fileUploadError) {
      setCreationStage("error");
      onError();
      analytics.track(FILE_UPLOAD_STATUS, {
        status: "error",
        message: fileUploadError,
        project_type: projectType,
      });
    }
  }, [fileUploadError]);

  // stage is post upload once settings exist
  useEffect(() => {
    if (creationStage === "pre-upload" && settings) {
      setCreationStage("post-upload");
    }
  }, [settings]);

  // changes to transcription change updates overall creation state
  useEffect(() => {
    if (transcriptionStage === TranscriptStage.extractingAudio) {
      setCreationStage("audio-extraction");
    }
    if (transcriptionStage === TranscriptStage.generatingTranscript) {
      setCreationStage("transcription");
    }
    if (transcriptionStage === TranscriptStage.error) {
      setCreationStage("error");
      analytics.track(PROJECT_TRANSCRIPT_STATUS, {
        status: "error",
        message: transcriptionErrorMessage,
        transcriptionJobId,
        project_type: projectType,
      });
    }
    if (transcriptionStage === TranscriptStage.finished) {
      if (!transcript?.words.length) {
        analytics.track(PROJECT_TRANSCRIPT_STATUS, {
          status: "empty",
          languageCode: settings?.translationLanguageCode ?? "",
          transcriptionJobId,
          project_type: projectType,
        });
      } else {
        analytics.track(PROJECT_TRANSCRIPT_STATUS, {
          status: "success",
          project_type: projectType,
        });
      }
    }
  }, [transcriptionStage]);

  useEffect(() => {
    if (detectFacesState.status === "error") {
      // track error but do not block project creation
      analytics.track(PROJECT_TRANSCRIPT_STATUS, {
        status: "error",
        message: `face detection error: ${detectFacesState.message}`,
        project_type: projectType,
        transcriptionJobId,
      });
    }
  }, [detectFacesState]);

  // start multiple project creation network requests
  // as soon as file is uploaded and settings have been set
  useEffect(() => {
    // don't run if we're missing required data to start
    if (
      !isUploadFinished ||
      !settings ||
      !videoFileId ||
      !uploadedVideoFile?.url ||
      fileUploadError
    ) {
      return;
    }

    // don't run if we already have transcription
    if (transcript) {
      return;
    }

    generateTranscript(
      {
        languageCode: settings.spokenLanguageCode,
        targetLanguageCode: settings.translationLanguageCode,
        translateAudioEnabled: settings.shouldDub,
      },
      videoFileId
    );
    analytics.track(PROJECT_TRANSCRIPT_STATUS, {
      status: "start",
      project_type: projectType,
    });

    const projectToAdd: Project = {
      id: projectId,
      title: "placeholder name",
      createdAt: new Date(),
      lastGeneratedFileId: "",
      sourceFileId: videoFileId,
      updatedAt: new Date(),
      sourceVideoMetadata: {
        duration: uploadedVideoFile.fileMetadata.duration ?? 0,
        fps: uploadedVideoFile.fileMetadata.fps ?? 0,
        width: uploadedVideoFile.fileMetadata.width ?? 0,
        height: uploadedVideoFile.fileMetadata.height ?? 0,
        size: uploadedVideoFile.fileMetadata.size ?? 0,
      },
    };

    // start thumbnail request but do not block on it
    createThumbnail(projectToAdd).then((thumbnailPath) => {
      setThumbnailPath(thumbnailPath);
    });

    // start filmstrip request
    createSprite(projectToAdd, uploadedVideoFile.url).then((spritePath) => {
      setFilmstripPaths(spritePath.paths);
      setFilmstripPPS(spritePath.pixelsPerSecond);
    });

    startDetectFaces(videoFileId, setFaces);

    startScenes(videoFileId, (scenes: number[]) => {
      const newClips: Clip[] = [];
      for (let i = 0; i < scenes.length - 1; i++) {
        newClips.push({
          startTime: scenes[i],
          endTime: scenes[i + 1],
        });
      }
      setClips(newClips);
    });
  }, [isUploadFinished, settings, videoFileId, transcript]);

  // start dubbing once transcription is done
  useEffect(() => {
    if (!transcript || !transcriptionJobId || !translateId || !waitToDub) {
      return;
    }

    startDubbing(transcriptionJobId, translateId);
    setCreationStage("dubbing");
  }, [transcript, settings, transcriptionJobId, translateId, waitToDub]);

  // once transcript and dubbing exists we are considered done with required calls
  // so create the project in the db
  useEffect(() => {
    if (!transcript || !videoFileId || !uploadedVideoFile || waitToDub) {
      return;
    }

    const dubResult = dubbingState.status === "finished" ? dubbingState.result : false;

    const projectTile = getDefaultProjectTitle({
      type: projectType === "ai-edit" ? "ai-edit" : "video",
      fileMetadata: uploadedVideoFile.fileMetadata,
    });

    const projectToAdd: Project = {
      id: projectId,
      projectType,
      title: projectTile,
      createdAt: new Date(),
      lastGeneratedFileId: "",
      sourceFileId: videoFileId,
      updatedAt: new Date(),
      sourceVideoMetadata: {
        duration: uploadedVideoFile.fileMetadata.duration ?? 0,
        fps: uploadedVideoFile.fileMetadata.fps ?? 0,
        width: uploadedVideoFile.fileMetadata.width ?? 0,
        height: uploadedVideoFile.fileMetadata.height ?? 0,
        size: uploadedVideoFile.fileMetadata.size ?? 0,
      },
      sourceFileLanguageCode: settings?.spokenLanguageCode,

      // audio for dubbing
      ...(dubResult && {
        targetLanguageCode: settings?.translationLanguageCode,
        translateAudioEnabled: true,
        backgroundAudioUri: dubResult.backgroundAudioUri,
        backgroundAudioSignedUrl: dubResult.backgroundAudioSignedUrl,
      }),

      transcriptionJobId,

      // the following may or may not exist, for now don't block on it
      thumbnailPath,
      filmStripPath: filmstripPaths,
      filmStripPixelsPerSecond: filmstripPPS,
    };

    ProjectRepository.addProject(projectToAdd)
      .then(() => setCreatedInitialProject(true))
      .catch(() => setCreationStage("error"));
  }, [transcript, videoFileId, uploadedVideoFile, waitToDub]);

  // proceed with ai edit
  useEffect(() => {
    try {
      if (
        !createdInitialProject ||
        !transcript ||
        !clips ||
        (!faces && detectFacesState.status !== "error") ||
        waitToDub ||
        projectType !== "ai-edit"
      ) {
        return;
      }
      const transcriptToUse = dubbingState.status === "finished" ? dubbingState.result : transcript;
      if (transcriptToUse.words.length < 5) {
        const errorMsg = t("projects:ai-edit.errors.no-speakers");
        toast.add(errorMsg, { severity: "error" });
        throw new Error(errorMsg);
      }

      if (!settings?.aiEdit || !videoFileMetadata) {
        throw new Error("AI Edit project not set up correctly");
      }

      const videoMetadata = {
        width: videoFileMetadata.width,
        height: videoFileMetadata.height,
        duration: videoFileMetadata.duration,
      };
      const faceData = faces ? new FaceData(faces, [], []) : undefined;
      aiEditGenerator.start({
        transcript: transcriptToUse,
        styleId: settings.aiEdit.styleId,
        languageCode: settings.spokenLanguageCode,
        faceData,
        videoMetadata,
        projectId,
      });
    } catch (error) {
      setCreationStage("error");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcript, createdInitialProject, waitToDub, clips, faces, projectType]);

  // once the project is created we populate the captions (regular project)
  useEffect(() => {
    if (
      !createdInitialProject ||
      !transcript ||
      waitToDub ||
      (!faces && detectFacesState.status !== "error") ||
      projectType === "ai-edit"
    ) {
      return;
    }
    const transcriptToUse = dubbingState.status === "finished" ? dubbingState.result : transcript;

    // set captions
    const { words, phrases, phraseAudios } = captions.generateFromTranscript(transcriptToUse);
    projectPersistence
      .addCaptions(
        settings?.spokenLanguageCode ?? DEFAULT_LANGUAGE,
        settings?.translationLanguageCode ?? DEFAULT_TRANSLATION_LANGUAGE,
        words,
        phrases,
        phraseAudios
      )
      .then(() => {
        // set clips for shots if they exist
        if (clips) {
          return projectPersistence.addEffects({ clips });
        }
      })
      .then(() => {
        if (faces) {
          return projectPersistence.updateProject({
            faceData: faces,
          });
        }
      })
      .then(() => setCreationStage("finished"))
      .catch(() => setCreationStage("error"));
  }, [transcript, createdInitialProject, waitToDub, projectType, faces, detectFacesState.status]);

  return <></>;
};
