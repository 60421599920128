import { z } from "zod";

import { makeBrandedId, makeBrandedIdSchema } from "~/utils/brand";

export const TimelineLayerItem_Id = makeBrandedId("layr_itm");
export type TimelineLayerItem_Id = ReturnType<typeof TimelineLayerItem_Id>;

export const layerItemTrimWrapModeSchema = z.enum(["none", "repeat"]);
export type LayerItemTrimWrapMode = z.infer<typeof layerItemTrimWrapModeSchema>;

export const layerItemTrimSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("none"),
  }),
  z.object({
    type: z.literal("independent"),
    sourceStartTime: z.number(),
    sourceEndTime: z.number().optional(),
    wrapMode: layerItemTrimWrapModeSchema.optional(),
  }),
  // this will be clamped by the parent's trim.
  z.object({
    type: z.literal("dependent"),
    sourceStartTime: z.number(),
    sourceEndTime: z.number().optional(),
    parentId: makeBrandedIdSchema(TimelineLayerItem_Id),
    wrapMode: layerItemTrimWrapModeSchema.optional(),
  }),
]);
export type LayerItemTrim = z.infer<typeof layerItemTrimSchema>;

/**
 * this represents the base type for all layer items in a timeline project.
 *
 * for now we just have the timeline blocks which create the base track, but
 * in the future we will add overlays which will also "inherit" from this.
 */
export const timelineLayerItemSchema = z.object({
  id: makeBrandedIdSchema(TimelineLayerItem_Id),
  trim: layerItemTrimSchema,
});
export type TimelineLayerItem = z.infer<typeof timelineLayerItemSchema>;
