import { ProjectEntityBag } from "../types";

import { migrateTo20241127 } from "./migrations/2024-11-27";
import { migrateTo20241205 } from "./migrations/2024-12-05";
import { migrateTo20241210 } from "./migrations/2024-12-10";

export class UnsupportedProjectVersionError extends Error {}

// in the future, the entities should be parsed with Zod and we should duplicate
// the schemas as they changed over time based off of the version. we can "deprecate"
// old projects + schemas as they become unsupported over time and then remove those
// old schemas.
// for now, we have a single set of TS types that match the latest schema.

export interface ProjectVersionDefinition {
  version: string | null;
  description: string;
  migrate: (entities: ProjectEntityBag) => Promise<ProjectEntityBag>;
}

export const noMigrationNeeded = async (e: ProjectEntityBag) => e;

/**
 * A list of project version definitions, the newest version must be the first entry.
 * Each version must be unique and should have a `migrate` function that performs the necessary migrations.
 *
 * The last entry must have a `null` version, which describes the initial version of the project.
 */
export const PROJECT_VERSION_CHANGELOG = [
  {
    version: "2024-12-10",
    description: "Migrate AI Edit Recess style to use PagSequenceEffect `sequenceItems` data model",
    migrate: migrateTo20241210,
  },
  {
    version: "2024-12-05",
    description: "Migrate keywordSettings to emphasisSettings",
    migrate: migrateTo20241205,
  },
  {
    version: "2024-11-27",
    description: "Introduce project versioning to track breaking changes.",
    migrate: migrateTo20241127,
  },
  {
    version: null,
    description: "Initial version.",
    migrate: noMigrationNeeded,
  },
] as const satisfies ProjectVersionDefinition[];

export type AnyProjectVersion = NonNullable<(typeof PROJECT_VERSION_CHANGELOG)[number]["version"]>;

export const CURRENT_PROJECT_VERSION = PROJECT_VERSION_CHANGELOG[0].version;
