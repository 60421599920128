import React from "react";

import { styled } from "~/theme";

function Apple({ ...props }) {
  return (
    <svg
      viewBox="0 0 25 24"
      fill="currentColor"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0922 9.16822C11.4362 9.16822 10.4142 8.40422 9.35216 8.40422C7.94016 8.40422 6.64816 9.26722 5.92016 10.5332C4.45616 13.0752 5.54316 16.8312 6.97116 18.8972C7.67216 19.9032 8.49816 21.0352 9.59416 20.9992C10.6452 20.9542 11.0402 20.3162 12.3162 20.3162C13.5832 20.3162 13.9422 20.9992 15.0562 20.9722C16.1882 20.9542 16.9072 19.9482 17.5982 18.9332C18.3982 17.7652 18.7302 16.6332 18.7482 16.5702C18.7212 16.5612 16.5472 15.7262 16.5202 13.2102C16.5022 11.1082 18.2362 10.1022 18.3172 10.0572C17.3292 8.61122 15.8112 8.44922 15.2812 8.41322C13.8972 8.30522 12.7392 9.16822 12.0922 9.16822V9.16822Z"
        fill="#F6F6F8"
        stroke="#F6F6F8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.15 2.95457C15.019 2.63105 15.5874 3.10805 15.4196 4.01999C15.2519 4.93192 14.4114 5.93346 13.5424 6.25698C12.6735 6.5805 12.105 6.1035 12.2728 5.19156C12.4406 4.27963 13.2811 3.27809 14.15 2.95457"
        fill="#F6F6F8"
      />
      <path
        d="M14.15 2.95457C15.019 2.63105 15.5874 3.10805 15.4196 4.01999C15.2519 4.93192 14.4114 5.93346 13.5424 6.25698C12.6735 6.5805 12.105 6.1035 12.2728 5.19156C12.4406 4.27963 13.2811 3.27809 14.15 2.95457"
        stroke="#F6F6F8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default styled(Apple, {
  width: 25,
  height: 24,
  color: "$grey-10",
});
