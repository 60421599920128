import { t } from "i18next";
import { useMemo } from "react";
import { Controller } from "react-hook-form";

import { ComboBox, ComboBoxGroup, ComboBoxOption } from "~/components/ComboBox";
import * as SideForm from "~/components/SideForm";
import { Text } from "~/components/Text";

import {
  getLocalizedLanguageLabel,
  getLocalizedLanguageName,
} from "../../utils/getLocalizedLanguageName";

import { LanguageItemLabel } from "./LanguageItemLabel";
import { TargetLanguageSelectorFieldProps } from "./LanguageSelector.types";

import { getLanguageOptionSearchScore } from ".";

export function TranslationLanguageField({
  control,
  currentSelectedSpokenLanguage,
  disabled,
  languages,
  hideDescription,
}: TargetLanguageSelectorFieldProps) {
  const options: (ComboBoxOption | ComboBoxGroup)[] = useMemo(() => {
    return [
      {
        label: (
          <LanguageItemLabel displayName={t("common:language-selector.options.no-translation")} />
        ),
        value: "none",
      },
      {
        label: t("common:language-selector.options.translation-languages"),
        options: languages
          .filter((item) => item.code !== currentSelectedSpokenLanguage)
          .map((item) => ({
            label: (
              <LanguageItemLabel
                displayName={item.display}
                localizedDisplayName={getLocalizedLanguageName(item)}
              />
            ),
            value: item.code,
            getSearchScore: (searchQuery) => getLanguageOptionSearchScore(item, searchQuery),
          })),
      },
    ];
  }, [currentSelectedSpokenLanguage, languages]);

  /** Display the selected value on a single line, unlike the options list */
  const customValue = (value?: string) => {
    if (value === "none") {
      return t("common:language-selector.options.no-translation");
    }
    const language = languages.find((item) => item.code === value);

    // Should never happen; fallback to default Radix Select behavior
    if (!language) {
      return undefined;
    }

    return <>{getLocalizedLanguageLabel(language)}</>;
  };

  return (
    <>
      {!hideDescription && (
        <SideForm.Description>
          <Text variant="body-2-bold">{t("common:language-selector.translated-language")}</Text>
          <Text variant="body-2" color="grey-500">
            {t("common:language-selector.translated-language-desc")}
          </Text>
        </SideForm.Description>
      )}
      <SideForm.Label>
        <Controller
          control={control}
          name="targetLanguageCode"
          render={({ field }) => (
            <ComboBox
              onValueChange={field.onChange}
              options={options}
              disabled={disabled}
              customValue={customValue}
              searchPlaceholder={t("common:language-selector.search-placeholder")}
              placeholder={t("common:language-selector.placeholder")}
              {...field}
            />
          )}
        />
      </SideForm.Label>
    </>
  );
}
