import { CaptionSettings, NewCaptionSettings } from "~/database/database.types";

import { withRetry } from "../repositoryWrapper";
import { getDB } from "../store";

class BaseCaptionSettingsRepository {
  static async addCaptionSettings(captions: Omit<CaptionSettings, "id">) {
    const db = await getDB();

    const id = window.crypto.randomUUID();
    await db.add("captionSettings", { ...captions, id });
    return id;
  }

  static async updateCaptionSettings(
    captionSettingsId: string,
    settings: Partial<NewCaptionSettings>
  ) {
    const db = await getDB();

    const tx = db.transaction(["captionSettings"], "readwrite");
    const captionSettings = await tx.objectStore("captionSettings").get(captionSettingsId);
    if (!captionSettings) {
      tx.abort();
      throw new Error("Caption settings not found");
    }
    await tx.objectStore("captionSettings").put({
      ...captionSettings,
      ...settings,
    });
    await tx.done;
  }

  static async removeCaptionSettings(captionSettingsId: string) {
    const db = await getDB();

    await db.delete("captionSettings", captionSettingsId);
  }

  static async getCaptionSettings(captionSettingsId: string): Promise<CaptionSettings | undefined> {
    const db = await getDB();

    return await db.get("captionSettings", captionSettingsId);
  }
}

export const CaptionSettingsRepository = withRetry(BaseCaptionSettingsRepository);
