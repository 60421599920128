import { Command, CommandList, RenderEffectOverlay } from "../CommandList.types";

export function getVideoCommand(commandList: CommandList) {
  const videoCommand = commandList.find((command): command is Command<RenderEffectOverlay> => {
    return (
      command.renderEffect.renderEffectType === "overlay" &&
      command.renderEffect.assetId === "video-source"
    );
  });

  return videoCommand;
}
