import { useCallback, useEffect, useRef, useState } from "react";

import { BackendServicesClient, useBackendServicesClient } from "~/context/BackendServicesContext";

import {
  AudioVisualLocalizationJobResult,
  DubbingStatus,
  getDubbingJobStatus,
  startDubbingJob,
} from "../services/Dubbing";
import { Transcript } from "../services/Transcription";

export interface DubbingResult extends Transcript {
  backgroundAudioUri?: string | null;
  backgroundAudioSignedUrl?: string | null;
}

export function parseServiceResult(result?: AudioVisualLocalizationJobResult): DubbingResult {
  return {
    words:
      result?.words.map((word) => ({
        text: word.word,
        startTime: word.startTime,
        endTime: word.endTime,
        keyword: word.isKeyword,
        phraseId: word.phraseId,
      })) ?? [],
    phrases: result?.phrases ?? [],
    backgroundAudioSignedUrl: result?.backgroundAudioSignedUrl,
    backgroundAudioUri: result?.backgroundAudioUri,
  };
}

/** Delay in milliseconds before polling backend for status again. */
export const POLLING_INTERVAL = 500;

/**
 * A discriminated union representing the state of the dubbing job.
 * This data structure helps show what metadata is available during each stage.
 * (e.g., `phrases` only becomes available when the job is finished)
 */
export type DubbingState =
  | {
      status: "idle" | "starting" | "canceled";
    }
  | {
      status: "started";
      audioDubJobId: string;
    }
  | {
      status: "progress";
      audioDubJobId: string;
      progress: number;
    }
  | {
      status: "error";
      errorMessage: string;
    }
  | {
      status: "finished";
      audioDubJobId: string;
      result: DubbingResult;
    };

/**
 * Handles starting and polling an Audio Dubbing job on the backend.
 * Modelled after `useTranscriptGenerator`.
 */
export function useDubbing() {
  const abortControllerRef = useRef(new AbortController());
  const client = useBackendServicesClient();
  const [jobState, setJobState] = useState<DubbingState>({ status: "idle" });

  // UI mocks:
  const mockState: DubbingState | undefined = undefined;
  useEffect(() => mockState && setJobState(mockState), [mockState]);

  const handleError = (error: unknown) => {
    console.error(error);
    if (abortControllerRef.current.signal.aborted) {
      setJobState({ status: "canceled" });
    } else {
      setJobState({
        status: "error",
        errorMessage: error instanceof Error ? error.message : `${error}`,
      });
    }
  };

  const cancelDubbing = () => {
    abortControllerRef.current?.abort();
    setJobState({ status: "canceled" });
  };

  const updateDubbingJobStatus = useCallback((dubbingJobId: string) => {
    getDubbingJobStatus(client, dubbingJobId, abortControllerRef.current.signal)
      .then(({ job }) => {
        if (job.status === "started") {
          setTimeout(() => updateDubbingJobStatus(dubbingJobId), POLLING_INTERVAL);
        } else if (
          (["processing", "progress"] satisfies DubbingStatus[] as string[]).includes(job.status)
        ) {
          setJobState({
            status: "progress",
            progress: job.progress,
            audioDubJobId: dubbingJobId,
          });
          setTimeout(() => updateDubbingJobStatus(dubbingJobId), POLLING_INTERVAL);
        } else if (job.status === "finished") {
          if (!job.result) {
            setJobState({
              status: "error",
              errorMessage: "Job finished, but missing result",
            });
          } else {
            setJobState({
              status: "finished",
              result: parseServiceResult(Object.values(job.result)[0]),
              audioDubJobId: dubbingJobId,
            });
          }
        } else if (job.status === "error") {
          setJobState({
            status: "error",
            errorMessage: job.errorMessage ?? "An error occurred while processing",
          });
        } else {
          console.error(job);
        }
      })
      .catch(handleError);
  }, []);

  const startDubbing = (transcriptionJobId: string, translateId: string) => {
    if (!["idle", "finished", "error", "canceled"].includes(jobState.status)) {
      console.warn(`Dubbing job already started with status ${jobState.status}`);
      return;
    }
    setJobState({ status: "starting" });
    abortControllerRef.current = new AbortController();

    startDubbingJob(client, { transcriptionJobId, translateId })
      .then((dubbingJob) => {
        setTimeout(() => updateDubbingJobStatus(dubbingJob.audioDubJobId), POLLING_INTERVAL);
      })
      .catch(handleError);
  };

  return {
    dubbingState: jobState,
    startDubbing,
    cancelDubbing,
    getDubbingJobResult,
  };
}

export const getDubbingJobResult = async (audioDubJobId: string) => {
  try {
    const { job } = await getDubbingJobStatus(BackendServicesClient.shared, audioDubJobId);
    if (job.status !== "finished" || !job.result) {
      throw new Error("Dubbing job is not finished yet");
    }
    const result = parseServiceResult(Object.values(job.result)[0]);
    return result;
  } catch (e) {
    console.error("Failed to get fresh dubbed audio", e);
    return null;
  }
};
