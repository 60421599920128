import type { CreateAiEditVideoPollResponse } from "@shared/generated/typescript/aiEdit/api/CreateAiEditVideoTemplate";
import invariant from "tiny-invariant";

import { FaceData } from "~/modules/project/utils/faceData/face-data";

import { AIVideoMetadata } from "../AIScenes.types";
import { CommandList } from "../CommandList.types";
import { AIEditExternalAsset } from "../hooks/useAIEditGenerator";

import { generateCaptionsCommands } from "./effects/gen-captions-commands";
import { generateOverlayCommands } from "./effects/gen-overlay-commands";
import { generateSoundCommands } from "./effects/gen-sound-commands";
import { generateSourceVideoCommands } from "./effects/gen-source-video-commands";

export function generateCommandListFromBackend(
  res: CreateAiEditVideoPollResponse,
  videoMetadata: AIVideoMetadata,
  faceData?: FaceData
): { commandList: CommandList; externalAssets: AIEditExternalAsset[] } {
  invariant(res.videoTemplate, "No video template found");
  invariant(res.videoTemplate.captionsTemplate, "No captions template found");
  invariant(res.videoTemplate.captionsTemplate.id, "No captions template id found");
  invariant(res.shots, "No video shots found");

  const {
    videoTemplate: {
      captionsTemplate: { id },
    },
    shots,
  } = res;

  // Trimmed shots are based on video transcript words
  // We pad the last shot to make sure there is a buffer after the last transcript word
  const END_OF_VIDEO_BUFFER_SECS = 0.5;
  const paddedShots = shots.map((shot, idx) => {
    if (idx === shots.length - 1) {
      if (shot.endTime + END_OF_VIDEO_BUFFER_SECS <= videoMetadata.duration) {
        return { ...shot, endTime: shot.endTime + END_OF_VIDEO_BUFFER_SECS };
      }
    }
    return shot;
  });

  const sourceVideoCommands = generateSourceVideoCommands(paddedShots, videoMetadata);
  const captionsCommands = generateCaptionsCommands(id, paddedShots, videoMetadata, faceData);
  const soundCommands = generateSoundCommands(paddedShots);
  const { commands: overlayCommands, externalAssets } = generateOverlayCommands(paddedShots);

  const commandList: CommandList = [
    ...sourceVideoCommands,
    ...captionsCommands,
    ...soundCommands,
    ...overlayCommands,
  ];

  return { commandList, externalAssets };
}
