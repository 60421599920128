import { AnimationStyle, AnimationTarget } from "./animationUtils.types";

export enum CapitalizationStyle {
  capitalizationStyleUnspecified = 0,
  capitalizationStyleNoChanges = 1,
  capitalizationStyleUpper = 2,
  capitalizationStyleLower = 3,
  capitalizationStyleTitle = 4,
  capitalizationStyleRandom = 5,
}

export enum LayoutAlignment {
  layoutAlignmentUnspecified = 0,
  layoutAlignmentLeft = 1,
  layoutAlignmentCenter = 2,
  layoutAlignmentRight = 3,
  layoutAlignmentNatural = 4,
  layoutAlignmentRandom = 5,
}

export enum EmojiPosition {
  emojiPositionUnspecified = 0,
  /** Emojis appear directly above the captions */
  emojiPositionAbove = 1,
  /** Emojis appear directly below the captions */
  emojiPositionBelow = 2,
  /** Emojis can be moved around freely by the user */
  emojiPositionFreeform = 3,
}

export enum EmojiTiming {
  emojiTimingUnspecified = 0,
  /** Emojis appear at the start of their associated word */
  emojiTimingWord = 1,
  /** Emojis appear at the start of the line containing their associated word */
  emojiTimingLine = 2,
  /** Emojis appear at the start of the page containing their associated word */
  emojiTimingPage = 3,
}

export interface CaptionEmojiSettings {
  position: EmojiPosition;
  timing: EmojiTiming;
  size: number;
  aiEnabled: boolean;
}

interface EmphasisEffects {
  underline?: boolean | undefined;
  emphasize?: boolean | undefined;
  supersize?: boolean | undefined;
}

export interface CaptionEmphasisSettings {
  keywords: EmphasisEffects;
  pictoralWords: EmphasisEffects;
  nouns: EmphasisEffects;
  aiEnabled: boolean;
}

export interface Point {
  x: number;
  y: number;
}

export interface Color {
  red: number;
  green: number;
  blue: number;
  alpha: number;
}

export interface Font {
  fontName: string;
  fontSize: number;
}

export interface Stroke {
  width: number;
  color: Color;
}

export interface Shadow {
  radius: number;
  color: Color;
  offset: Point;
}

export interface Gradient {
  endPosition: Point;
  startPosition: Point;
  secondColor: Color;
  firstColor: Color;
}

export interface ActiveWordBackground {
  cornerRadius: number;
  animationStyle: AnimationStyle;
  color: Color;
  gradient?: Gradient;
}

export interface BackgroundStyle {
  cornerRadius: number;
  color?: Color;
  width: number;
}

export interface SizeConstraints {
  width?: number | null;
  height?: number | null;
  minFontSize?: number | null;
}

export interface WordCountLineBreak {
  count: number;
}

export interface OnPunctuationAndPauseLineBreak {
  seconds: number;
  characterCount: number;
}

export interface SplitsInLineBreak {
  seconds: number;
}

export type LineBreakMode =
  | { wordCount: WordCountLineBreak }
  | { onPunctuationAndPause: OnPunctuationAndPauseLineBreak }
  | { splitsIn: SplitsInLineBreak }
  | { random: object };

export interface MovementAutoMotion {
  enabled: boolean;
  speed: number;
  offset: Point;
}

export interface ScaleAutoMotion {
  enabled: boolean;
  speed: number;
  maxScale: number;
}

export interface UpcomingWords {
  enabled: boolean;
  color: Color;
}

export interface Glow {
  enabled: boolean;
  radius: number;
  opacity: number;
  offset: Point;
  emphasisOnly: boolean;
}

export interface CaptionStylePreset {
  font: Font;
  letterSpacing?: number | null;
  lineSpacing: number;
  stroke: Stroke;
  shadow: Shadow;
  textOffset: Point;
  layoutAlignment: LayoutAlignment;
  lineBreakMode: LineBreakMode;
  linesPerPage: number;
  capitalization: CapitalizationStyle;
  hidePunctuation: boolean;
  lineFitWrapEnabled: boolean;
  colorApplicationBackgroundEnabled: boolean;
  randomizeRotation: boolean;
  isMultilineBox: boolean;
  activeWordBackground: ActiveWordBackground;
  animationTarget: AnimationTarget;
  animationStyle: AnimationStyle;
  backgroundStyle: BackgroundStyle;
  backgroundShadow: Shadow;
  sizeConstraints: SizeConstraints;
  autoSizeFitLines: boolean;
  activeColorEnabled: boolean;
  activeSizeEnabled: boolean;
  movementAutoMotion: MovementAutoMotion;
  scaleAutoMotion: ScaleAutoMotion;
  backgroundPadding: Point;
  upcomingWords: UpcomingWords;
  activeBoldEnabled: boolean;
  superSizeNoBreak: boolean;
  glow: Glow;

  // local fields for captions background
  colorApplicationPageEnabled?: boolean;
  colorApplicationLineEnabled?: boolean;
}
