import { Client } from "@shared/generated/typescript/aiEdit/api/CreateAiEditVideoTemplate";
import { useCallback } from "react";

import { useBackendServicesClient } from "~/context/BackendServicesContext";
import { useLatestRef } from "~/hooks/helpers";
import { Transcript } from "~/modules/project/services/Transcription/Transcription.dto";

import {
  usePollForAiEditVideoCreation,
  useSubmitAiEditVideoCreation,
} from "../services/AIEditStyles/network/mutations";

export interface UseBackendAIEditProps {
  onStartPooling?: (operationId: string) => void;
}

export function useBackendAIEdit({ onStartPooling }: UseBackendAIEditProps = {}) {
  const latestOnStartPooling = useLatestRef(onStartPooling);
  const { mutateAsync: submitAiEditVideoCreation, isPending: isSubmittiing } =
    useSubmitAiEditVideoCreation();
  const { beginAsync: pollUntilVideoCreationDone, isPending: isPolling } =
    usePollForAiEditVideoCreation();
  const client = useBackendServicesClient();

  const createProjectFromStyle = useCallback(
    async (
      styleId: string,
      transcript: Transcript,
      projectId: string,
      languageCode: string,
      devOptions?: {
        shotSelectionSeed?: number;
        overrideShotStyleIds?: string[];
      }
    ) => {
      const payload = {
        videoStyleId: styleId,
        transcript: transcript.words.map((word) => word.text).join(" "),
        words: transcript.words.map((word) => ({
          word: word.text,
          startTime: word.startTime,
          endTime: word.endTime,
        })),
        client: Client.DESKTOP,
        version: client.version,
        optimisticProjectId: projectId,
        languageCode,
        ...(devOptions?.overrideShotStyleIds && {
          overrideShotStyleIds: devOptions.overrideShotStyleIds,
        }),
        ...(devOptions?.shotSelectionSeed && {
          shotSelectionSeed: devOptions.shotSelectionSeed,
        }),
      };
      const { operationId } = await submitAiEditVideoCreation(payload);
      latestOnStartPooling.current?.(operationId);
      const data = await pollUntilVideoCreationDone({ operationId });

      return data;
    },
    [pollUntilVideoCreationDone, submitAiEditVideoCreation, latestOnStartPooling, client]
  );

  return { createProjectFromStyle, isSubmittiing, isPolling };
}
