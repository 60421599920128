export default class Lock {
  isLocked: boolean;
  queue: ((value?: unknown) => void)[];
  constructor() {
    this.isLocked = false;
    this.queue = [];
  }

  // acquire will block promise until lock is freed
  async acquire() {
    return new Promise((resolve) => {
      // acquire lock
      if (!this.isLocked) {
        // lock and resolve
        this.isLocked = true;
        resolve(undefined);
      } else {
        // is locked, so queue resolve to run later
        this.queue.push(resolve);
      }
    });
  }

  // release will resolve the next blocked promise
  release() {
    // release lock
    if (this.queue.length > 0) {
      // resolve first item from queue
      const resolve = this.queue.shift();
      resolve?.(undefined);
    } else {
      // free lock, nothing left blocked on lock
      this.isLocked = false;
    }
  }
}
