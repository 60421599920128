export const DEFAULT_CHUNK_SIZE = 30 * 1024 * 1000;
export const DEFAULT_HTTP2_CHUNK_SIZE = 256 * 1024 * 1000; // 256mb per chunk

export const getEndByteIndex = (
  file: File,
  startByteIndex: number,
  chunkSize = DEFAULT_CHUNK_SIZE
) => {
  // minus 1 to get the endByteIndex
  return Math.min(startByteIndex + chunkSize - 1, file.size - 1);
};

export const getContentRange = (file: File, startByteIndex: number, endByteIndex: number) => {
  return `bytes=${startByteIndex}-${endByteIndex}/${file.size}`;
};

export const getChunk = (file: File, startByteIndex: number, endByteIndex: number) => {
  // indexes are zero based, and slice end is exclusive, so we add 1 to the endByteIndex
  return file.slice(startByteIndex, endByteIndex + 1, file.type);
};
