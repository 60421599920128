import { Effects } from "~/database/database.types";
import {
  PagSequenceEffect,
  PagSequenceEffectAsset,
  PagSequenceEffectAssetSlot,
} from "~/database/effects.types";

import { ProjectEntityBag } from "../../types";

export type StalePagSequenceType = {
  inAsset?: { assetId: string; slots: string[] };
  holdAsset?: { assetId: string; slots: string[] };
  outAsset?: { assetId: string; slots: string[] };
  imageIds?: string[];
  startTime: number;
  endTime: number;
};

export async function migrateTo20241210(entities: ProjectEntityBag): Promise<ProjectEntityBag> {
  const effects = entities.effects;
  if (effects && hasStalePagSequences(effects)) {
    const pagSequences = effects.pagSequences;

    pagSequences?.forEach((seq) => {
      if (isStalePagSequence(seq)) {
        const sequenceItems: PagSequenceEffectAsset[] = [];
        const imageIds = seq.imageIds;

        if ("inAsset" in seq) {
          const inAsset = seq.inAsset as { assetId: string; slots: string[] };

          const slots = inAsset.slots.map(
            (slot, idx) =>
              ({
                type: slot as "image" | "source-video",
                assetId: slot === "image" ? imageIds?.[0] : undefined,
                layerIndices: [idx],
              }) as PagSequenceEffectAssetSlot
          );

          sequenceItems.push({
            assetId: inAsset.assetId,
            slots: slots,
            timing: "playOnceIntrinsic",
          });
        }
        if ("holdAsset" in seq) {
          const holdAsset = seq.holdAsset as { assetId: string; slots: string[] };
          const slots = holdAsset.slots.map(
            (slot, idx) =>
              ({
                type: slot as "image" | "source-video",
                assetId: slot === "image" ? imageIds?.[0] : undefined,
                layerIndices: [idx],
              }) as PagSequenceEffectAssetSlot
          );

          sequenceItems.push({
            assetId: holdAsset.assetId,
            slots: slots,
            timing: "loopScaleToFit",
          });
        }
        if ("outAsset" in seq && seq.outAsset) {
          const outAsset = seq.outAsset as { assetId: string; slots: string[] };
          const slots = outAsset.slots.map(
            (slot, idx) =>
              ({
                type: slot as "image" | "source-video",
                assetId: slot === "image" ? imageIds?.[0] : undefined,
                layerIndices: [idx],
              }) as PagSequenceEffectAssetSlot
          );
          sequenceItems.push({
            assetId: outAsset.assetId,
            slots: slots,
            timing: "playOnceIntrinsic",
          });
        }

        seq.sequenceItems = sequenceItems;
        delete seq.inAsset;
        delete seq.holdAsset;
        delete seq.outAsset;
        delete seq.imageIds;
      }
    });
  }

  return { ...entities, effects };
}

function hasStalePagSequences(effects: Effects): effects is Effects & {
  pagSequences?: StalePagSequenceType[];
} {
  return (
    "pagSequences" in effects &&
    effects.pagSequences!.some(
      (seq) => "inAsset" in seq || "holdAsset" in seq || "outAsset" in seq || "imageIds" in seq
    )
  );
}

function isStalePagSequence(
  seq: PagSequenceEffect & {
    inAsset?: { assetId: string; slots: string[] };
    holdAsset?: { assetId: string; slots: string[] };
    outAsset?: { assetId: string; slots: string[] };
    imageIds?: string[];
    startTime: number;
    endTime: number;
  }
): seq is PagSequenceEffect & StalePagSequenceType {
  return "inAsset" in seq || "holdAsset" in seq || "outAsset" in seq || "imageIds" in seq;
}
