import { EDLSegment } from "../../EDL.types";

import { EffectsCommandGeneratorFunction } from "./types";

export const generateSoundCommandsForScene: EffectsCommandGeneratorFunction = (scene) => {
  if (!scene.style.sound) {
    return null;
  }

  const { assetId, volume, delay, duration } = scene.style.sound;

  const startDelay = delay ?? 0;
  const effectDuration = duration ?? scene.endTime - scene.startTime - startDelay;
  const sfxSegment: EDLSegment = [
    scene.mainOffset + startDelay,
    scene.mainOffset + effectDuration,
    0,
    effectDuration,
  ];

  return {
    edl: {
      segments: [sfxSegment],
      wrapMode: "repeat",
    },
    renderEffect: {
      renderEffectType: "sound",
      assetId,
      volume,
    },
  };
};
