import { styled } from "~/theme";

export const SIDEBAR_WIDTH_PX = 84;

export const SidebarContainer = styled("div", {
  gridColumn: "sidebar",
  gridRow: "content-area / span all",
  width: SIDEBAR_WIDTH_PX,
  borderRight: "1px solid $grey-800",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "$16",
  flexShrink: 0,
});

export const SidebarTop = styled("ul", {
  display: "flex",
  flexDirection: "column",
  gap: "$16",
  padding: "$16 0",
  alignItems: "center",
});

export const SidebarBottom = styled(SidebarTop);
