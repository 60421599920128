import { styled } from "~/theme";

import { IconProps } from "./types";

function Delete({ height = "24", width = "24", ...props }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.4266 5.80359H4.57339"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4642 10.0145L17.6679 18.5503C17.5797 19.6975 16.6231 20.5833 15.4725 20.5833H9.52753C8.37695 20.5833 7.42035 19.6975 7.33214 18.5503L6.5358 10.0145"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1422 5.22558C15.1422 3.76633 13.9593 2.58337 12.5 2.58337C11.0408 2.58337 9.8578 3.76633 9.8578 5.22558"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default styled(Delete, {});
