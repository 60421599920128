import { FilterEffect } from "./filterPainter.types";
import { updateTexture } from "./utils/webgl";
import { BlurProgramData, applyBlur, initBlur } from "./utils/webgl/blurProgram";
import { FilterProgramData, paintFilter, initFilter } from "./utils/webgl/filterProgram";

const FILTER_BLUR_RADIUS = 20.0;

export class FilterPainter {
  private _filterProgram: FilterProgramData | null = null;
  private _blurProgram: BlurProgramData | null = null;
  private _timestamp: number = 0;
  private _filters: FilterEffect[] = [];
  private _activeFilters: FilterEffect | undefined;

  public setupContext(ctx: WebGLRenderingContext | null) {
    if (ctx) {
      this._filterProgram = initFilter(ctx);
      this._blurProgram = initBlur(ctx);
    } else {
      this._filterProgram = null;
    }
  }

  public setTimestamp(timestamp: number) {
    this._timestamp = timestamp;
    this._activeFilters = this._filters.find(
      (filter) => filter.startTime <= this._timestamp && filter.endTime > this._timestamp
    );
  }

  public setFilters(filters: FilterEffect[]) {
    this._filters = filters;
    this._activeFilters = this._filters.find(
      (filter) => filter.startTime <= this._timestamp && filter.endTime > this._timestamp
    );
  }

  public paint(ctx: WebGLRenderingContext) {
    const filters = this._activeFilters?.filters.filter((filter) => filter !== "blur");
    const blur = this._activeFilters?.filters.includes("blur");

    if (filters?.length && this._filterProgram) {
      updateTexture(ctx, this._filterProgram.textures.texture, ctx.canvas);
      paintFilter(ctx, filters, this._filterProgram);
    }

    if (blur && this._blurProgram) {
      updateTexture(ctx, this._blurProgram.textures.texture, ctx.canvas);
      applyBlur(ctx, this._blurProgram, FILTER_BLUR_RADIUS);
    }
  }

  public destroy() {
    this._filters = [];
    this._activeFilters = undefined;
  }
}
