import { AnimationStyle, ZoomStyle } from "captions-engine";

import { CAPTION_TEMPLATES } from "../../../../project/services/CaptionStylePreset";
import { TransitionAnimationGroupId } from "../../../../project/services/PagAnimations/TransitionAnimations.config";
import type {
  AIImageBackendEnum,
  AIImageLayout,
  GlobalStyleConfig,
  SceneStyleConfig,
  AISceneStyle,
  TransitionConfig,
} from "../AIEditStyles.types";

const IMPACT_SOUND_VOLUME = 0.2;

export const IMPACT_PREVIEW_URL =
  "https://captions-cdn.xyz/desktop-site/ai-edit-impact-preview.webm";

export const IMPACT_SCENE_STYLE_CONFIG = {
  "impact-talking-head": {
    builder: (prng): AISceneStyle => {
      const mediaStyles = ["popInImages", "slideInImages"];
      const mediaStyle = mediaStyles.at(prng.next() * mediaStyles.length);

      const zoomStyles: ZoomStyle[] = ["instant", "extreme-step-in", "step-in"];
      const zoomStyle = zoomStyles.at(prng.next() * zoomStyles.length);

      switch (mediaStyle) {
        case "popInImages":
          return {
            id: "impact-talking-head",
            imageRules: {
              style: "IMPACT_GLOW",
              numImages: "duration-half",
              imageAnimations: {
                animationIn: AnimationStyle["animationStyleImmediate"],
                animationOut: AnimationStyle["animationStyleImmediate"],
                activeAnimation: AnimationStyle["animationStyleSmallMovement"],
              },
              imagePositionType: "freeform",
              imageLayout: "impact_below_head",
            },
            capsPosition: "avoid-head",
            zoomStyle,
          };
        case "slideInImages":
        default:
          return {
            id: "impact-talking-head",
            imageRules: {
              style: "IMPACT_GLOW",
              numImages: "duration-half",
              imageAnimations: {
                animationIn: AnimationStyle["animationStyleSlideIn"],
                animationOut: AnimationStyle["animationStyleSlideOut"],
                activeAnimation: AnimationStyle["animationStyleSmallMovement"],
              },
              imagePositionType: "freeform",
              imageLayout: "impact_below_head",
            },
            capsPosition: "avoid-head",
            zoomStyle,
          };
      }
    },
  },
  "impact-small-images": {
    isImageStyle: true,
    builder: (prng) => {
      const motionBackgroundIds = [
        "background_grid_a",
        "background_grid_b",
        "background_grid_c",
        "background_topography_a",
        "background_topography_b",
        "background_topography_c",
      ];
      const motionBackgroundId =
        motionBackgroundIds.at(prng.next() * motionBackgroundIds.length) || motionBackgroundIds[0];

      const imageLayouts: AIImageLayout[] = ["impact_layout_a", "impact_layout_b"];
      const imageLayout = imageLayouts.at(prng.next() * imageLayouts.length) || imageLayouts[0];

      return {
        id: "impact-small-images",
        imageRules: {
          style: "IMPACT_GLOW",
          numImages: 3,
          imageAnimations: {
            animationIn: AnimationStyle["animationStyleScaleIn"],
            animationOut: AnimationStyle["animationStyleImmediate"],
            activeAnimation: AnimationStyle["animationStyleSmallMovement"],
          },
          imageLayout: imageLayout,
          imagePositionType: "freeform",
        },
        capsPosition: "middle",
        sceneBackground: {
          assetId: motionBackgroundId,
        },
      };
    },
  },
  "impact-fullscreen-images": {
    isImageStyle: true,
    builder: () => ({
      id: "impact-fullscreen-images",
      imageRules: {
        style: "IMPACT_FULLSCREEN",
        imageLayout: "fullscreen",
        imagePositionType: "b-roll",
        numImages: "duration-floor",
        zoomStyle: "center-zoom-in",
        imageTransition: {
          assetId: "flash2",
          volume: IMPACT_SOUND_VOLUME,
        },
      },
      capsPosition: "middle",
    }),
  },
  "impact-search-results": {
    builder: (prng) => {
      const numberOfSearchResultStyles = 8;
      const selectedStyle = Math.floor(prng.next() * numberOfSearchResultStyles) + 1;
      return {
        id: "impact-search-results",
        isOnlyUseOnce: true,
        animation: {
          introId: `PAG_Search_Results_${selectedStyle}_IN`,
          holdId: `PAG_Search_Results_${selectedStyle}_HOLD`,
          outroId: `PAG_Search_Results_${selectedStyle}_OUT`,
          textReplacement: {
            charactersPerLine: 20,
            numberOfLines: 1,
            addEllipsis: true,
          },
        },
        hideCaptions: true,
      };
    },
  },
  "impact-title-card": {
    isImageStyle: true,
    builder: (prng) => {
      const imageStyles: AIImageBackendEnum[] = [
        "IMPACT_WHITEBOARD",
        "IMPACT_QUOTE",
        "IMPACT_WALL_STREET",
      ];
      const imageStyle = imageStyles.at(prng.next() * imageStyles.length) || imageStyles[0];
      return {
        id: "impact-title-card",
        imageRules: {
          style: imageStyle,
          numImages: 1,
          imageLayout: "fullscreen",
          imagePositionType: "b-roll",
        },
        hideCaptions: true,
      };
    },
  },
  "impact-vignette": {
    builder: (prng) => {
      const zoomStyles: ZoomStyle[] = ["instant", "step-in"];
      const zoomStyle = zoomStyles.at(prng.next() * zoomStyles.length);

      return {
        id: "impact-vignette",
        filters: ["grayscale", "vignette"],
        capsPosition: "avoid-head",
        zoomStyle,
      };
    },
  },
  "impact-cut-out": {
    builder: (prng) => {
      const motionBackgroundIds = ["red_curtain", "green_curtain", "blue_curtain", "gold_curtain"];
      const motionBackgroundId =
        motionBackgroundIds.at(prng.next() * motionBackgroundIds.length) || motionBackgroundIds[0];

      const zoomStyles: (ZoomStyle | undefined)[] = ["instant", "step-in", undefined];
      const zoomStyle = zoomStyles.at(prng.next() * zoomStyles.length);

      return {
        id: "impact-cut-out",
        isSingleSubject: true,
        sceneBackground: {
          assetId: motionBackgroundId,
          cutout: true,
          zoomStyle: {
            foreground: zoomStyle,
          },
        },
        capsPosition: "avoid-head",
      };
    },
  },
  "impact-heirloom": {
    builder: () => {
      return {
        id: "impact-heirloom",
        dynamicCaptions: "rect",
        filters: ["blur"],
      };
    },
  },
} as const satisfies SceneStyleConfig;

export type ImpactSceneId = keyof typeof IMPACT_SCENE_STYLE_CONFIG;

export const IMPACT_GLOBAL_STYLE_CONFIG: GlobalStyleConfig = [
  {
    builder: () => ({
      assetId: "overlay_impact_gradient",
      blendMode: "soft-light",
    }),
  },
];

export const IMPACT_TRANSITIONS: TransitionConfig = {
  builder: (currentSceneId, nextSceneId) => {
    const allowedTransitions: TransitionAnimationGroupId[] = [
      "shake",
      "swipeUp",
      "left",
      "leak",
      "flash",
      "flare",
      "film",
    ];

    if (["impact-talking-head", "impact-vignette"].includes(currentSceneId)) {
      if (nextSceneId === "impact-fullscreen-images") {
        return ["flash"];
      }
      if (nextSceneId === "impact-search-results") {
        return ["swipeUp"];
      }
      if (nextSceneId === "impact-small-images") {
        return allowedTransitions.filter((transition) => transition !== "shake");
      }
    }

    if (["impact-small-images"].includes(currentSceneId)) {
      if (nextSceneId === "impact-small-images") {
        return ["left"];
      }
      if (nextSceneId === "impact-fullscreen-images") {
        return ["flash"];
      }
      if (nextSceneId === "impact-talking-head") {
        return allowedTransitions.filter((transition) => transition !== "shake");
      }
    }

    if (["impact-fullscreen-images", "impact-title-card"].includes(currentSceneId)) {
      return ["flash"];
    }

    if (["impact-search-results"].includes(currentSceneId)) {
      return ["swipeUp"];
    }

    return allowedTransitions;
  },
  library: [
    {
      assetId: "leak",
      volume: 0,
    },
    {
      assetId: "flash",
      volume: IMPACT_SOUND_VOLUME,
    },
    {
      assetId: "flare",
      volume: 0,
    },
    {
      assetId: "film",
      volume: IMPACT_SOUND_VOLUME,
    },
    {
      assetId: "shake",
      volume: 0,
      outDuration: 12 / 30,
      inDuration: 12 / 30,
    },
    {
      assetId: "swipeUp",
      volume: 0,
      outDuration: 13 / 30,
      inDuration: 11 / 30,
    },
    {
      assetId: "left",
      volume: 0,
      outDuration: 11 / 30,
      inDuration: 13 / 30,
    },
  ],
};

// ID for the "Impact" caption template
export const IMPACT_CAPTION_TEMPLATE = CAPTION_TEMPLATES.Impact;
