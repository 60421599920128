import { createContext, type PropsWithChildren, useContext } from "react";

import { useAuthStore } from "~/stores/auth";

export type CaptionsAuthState = "signedIn" | "signedOut" | "unknown" | "loading";
export type UserPlanSchema = "Max" | "Pro" | "Scale";
// Web uses azure-ad and native uses microsoft for Microsoft provider
export type AuthProvider = "google" | "apple" | "azure-ad" | "microsoft" | "credentials";
export const UserPlanSchemaValues: Record<UserPlanSchema, UserPlanSchema> = {
  Max: "Max",
  Pro: "Pro",
  Scale: "Scale",
};

export type CaptionsAuthUser = {
  userId?: string;
  name?: string;
  email?: string;
  image?: string;
  loginType?: string;
  phoneNumber?: string;
  region?: string;
  geo?: {
    region?: string;
    subdivision?: string;
    city?: string;
  };
  subscription?: {
    managePaymentUrl: string | null;
    manageSubscriptionUrl: string | null;
    renewalTimestamp: number | null;
    renewalDateString: string | null;
    renewalDateMillis: number | null;
    subscriptionTier: UserPlanSchema | null;
  };
};

export type CaptionsAuthPayload = {
  accessToken?: string;
  userId?: string;
  refreshToken?: string;
  expiresIn?: string;
};

export type CaptionsAuthData = {
  signIn: (provider: AuthProvider, payload?: CaptionsAuthPayload) => Promise<void>;
  signOut: (sessionExpired?: boolean) => Promise<void>;
  refreshUserProfile: () => Promise<CaptionsAuthUser | undefined>;
  state: CaptionsAuthState;
  user?: CaptionsAuthUser;
};

export type CaptionsAuthProps = { value: CaptionsAuthData };

export const CaptionsAuthStateContext = createContext<CaptionsAuthData | null>(null);

export function CaptionsAuthProvider({ children, ...props }: PropsWithChildren<CaptionsAuthProps>) {
  return (
    <CaptionsAuthStateContext.Provider {...props}>{children}</CaptionsAuthStateContext.Provider>
  );
}

export const useAuthState = () => {
  // this is will read either the context (in desktop) or the auth store (in web)
  // helps maintain backwards compatibility and minimizes the changes needed for the moment
  const context = useContext(CaptionsAuthStateContext);
  const [signIn, signOut, refreshUserProfile, state, user] = useAuthStore((s) => [
    s.signIn,
    s.signOut,
    s.refreshUserProfile,
    s.state,
    s.user,
  ]);

  if (context) {
    return context;
  }

  return { signIn, signOut, refreshUserProfile, state, user };
};
