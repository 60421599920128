/** Compare strings using asterisk (*) as a wildcard to match any characters */
export const globMatch = (glob: string, input: string) => globToRegex(glob).test(input);

/**
 * Create a RegExp from the given string, converting asterisks to .* expressions,
 * and escaping all other characters.
 */
export const globToRegex = (glob: string) =>
  new RegExp("^" + glob.split(/\*+/).map(regexEscape).join(".*") + "$");

/** Regex-escape all characters in the given string. */
export const regexEscape = (s: string) => s.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&");

/**
 * Removes empty spaces, dashes, brackets from string and return joined value.
 * Example:
 * "(223) 331-122-1212" -> "2233311221212"
 *
 * */
export const regexPhoneNumber = (phoneNumber: string) => phoneNumber.replace(/-|\s|[\])}[{(]/g, "");

/**
 * Converts string value to NA phone format
 *
 * (111) 111-1111
 *
 * */
export const NAPhoneNumberMask = (value: string) => {
  return value.length < 3
    ? `(${value}`
    : `(${value.slice(0, 3)}) ${value.slice(3, 6)}${
        value.length < 6 ? "" : `-${value.slice(6, 10)}`
      }`;
};

export const isNA = (selectedRegion: string) => ["CA", "US"].includes(selectedRegion);
