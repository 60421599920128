import { RefObject, useEffect } from "react";

export function useEventListener<E extends EventTarget>(
  elementOrRef: E | RefObject<E> | null,
  eventName: string,
  handler: (event: Event) => void
) {
  useEffect(() => {
    const element = elementOrRef && "current" in elementOrRef ? elementOrRef.current : elementOrRef;
    element?.addEventListener(eventName, handler);
    return () => element?.removeEventListener(eventName, handler);
  }, [elementOrRef, eventName, handler]);
}
