import { getPrimarySceneSegments } from "../../utils/genPrimarySceneSegments";

import { EffectsCommandGeneratorFunction } from "./types";

export const generateFrameCommandsForScene: EffectsCommandGeneratorFunction = (scene) => {
  if (!scene.style.frame) {
    return null;
  }

  return {
    edl: {
      segments: getPrimarySceneSegments(scene),
      wrapMode: "repeat",
    },
    renderEffect: {
      renderEffectType: "frame",
      assetId: scene.style.frame.assetId,
      placeholders: scene.style.frame.placeholders,
    },
  };
};
