import { clamp } from "../clamp";

import { ColorCssType, colorDecimalSchema, ColorDecimalType } from "./types";

/**
 * Converts a color object to a hex string. The alpha channel is not included in the hex string.
 * If a color with an alpha channel is provided, a warning will be logged.
 *
 * @param color - The color to convert.
 * @param [ignoreAlpha] - If true, explicitly indicates the alpha channel should be ignored,
 * disabling the warning.
 * @returns the hexadecimal string representation of the color.
 */
export function colorToHex(color?: ColorCssType | ColorDecimalType, ignoreAlpha?: boolean): string {
  const format = (color: number) => parseFloat(color.toFixed(3));

  const { red, green, blue, alpha } = color ?? colorDecimalSchema.parse({});

  if (format(red) > 1 || format(green) > 1 || format(blue) > 1) {
    return colorCssToHex({ red, green, blue, alpha: alpha ?? 1 }, ignoreAlpha);
  } else {
    return colorDecimalToHex({ red, green, blue, alpha: alpha ?? 1 }, ignoreAlpha);
  }
}

export function colorDecimalToHex(color?: ColorDecimalType, ignoreAlpha?: boolean): string {
  const { red, green, blue } = color ?? colorDecimalSchema.parse({});

  return `#${[red, green, blue]
    .map((c) =>
      Math.round(clamp(c, 0, 1) * 255)
        .toString(16)
        .padStart(2, "0")
    )
    .join("")}`;
}

export function colorCssToHex(color: ColorCssType, ignoreAlpha?: boolean): string {
  return colorToHex(
    {
      red: clamp(color.red, 0, 255) / 255,
      green: clamp(color.green, 0, 255) / 255,
      blue: clamp(color.blue, 0, 255) / 255,
      alpha: color.alpha ? clamp(color.alpha, 0, 255) / 255 : 1,
    },
    ignoreAlpha
  );
}
