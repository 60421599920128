import { getPrimarySceneSegments } from "../../utils/genPrimarySceneSegments";

import { EffectsCommandGeneratorFunction } from "./types";

export const generateOverlayCommandsForScene: EffectsCommandGeneratorFunction = (scene) => {
  if (!scene.style.overlay) {
    return null;
  }

  return {
    edl: {
      segments: getPrimarySceneSegments(scene),
      wrapMode: "repeat",
    },
    renderEffect: {
      renderEffectType: "overlay-effect",
      assetId: scene.style.overlay,
    },
    blendMode: scene.style.overlayBlend ?? "source-over",
  };
};
