/**
 * A class that loads a video from a Blob and provides a way to fetch frames at specific times.
 */
export class VideoLoader {
  readonly loop: boolean;
  private readonly _video: HTMLVideoElement;
  private _metadata: { duration: number } | null = null;
  private _hasError: boolean = false;
  private _seekPromise: Promise<void> | null = null;
  private _loadPromise: Promise<void> | null = null;
  private _seekAbortController: AbortController | null = null;
  private _afterEnd: boolean = false;

  constructor(videoData: Blob, loop: boolean) {
    this._video = document.createElement("video");
    this._loadPromise = new Promise((resolve, reject) => {
      this._video.onloadeddata = () => {
        this._metadata = { duration: this._video.duration };
        this._loadPromise = null;
        this._video.onloadeddata = null;
        resolve();
      };

      this._video.onerror = () => {
        this._hasError = true;
        this._seekAbortController?.abort();
        if (this._loadPromise) {
          this._loadPromise = null;
          this._video.onloadeddata = null;
          reject();
        }
      };
    });
    this._video.preload = "auto";
    this._video.muted = true;
    this._video.src = URL.createObjectURL(videoData);
    this._video.load();
    this.loop = loop;
  }

  /**
   * Frees up resources used by the video loader.
   */
  destroy() {
    URL.revokeObjectURL(this._video.src);
    this._video.src = "";
    this._video.remove();
    this._metadata = null;
  }

  get currentTime() {
    return this._video.currentTime;
  }

  /**
   * Set the current time of the video overlay effect. If the timestamp is greater than the
   * duration of the video and it is not set to loop, set the flag indicating we are past the
   * video's end.
   * @remarks Will create a promise that resolves when the video has seeked to the correct time.
   * @remarks Will abort any ongoing seek promises
   * @param timestamp - The timestamp to set the video to.
   */
  set currentTime(timestamp: number) {
    if (!this._metadata || this._hasError || this._video.currentTime === timestamp) {
      return;
    }

    this._seekAbortController?.abort();
    this._seekAbortController = null;
    this._afterEnd = timestamp > this._metadata.duration && !this.loop;
    // No need to seek if we are past the effect's end, since no frame will be drawn
    if (this._afterEnd) {
      return;
    }
    // Creates a seek promise that will be resolved when the video element has finished seeking,
    // which indicates that the video frame is ready
    this._seekPromise = new Promise((resolve) => {
      // Resolves the current seek promise if the video hasn't been loaded yet.
      if (!this._metadata) {
        return resolve();
      }

      this._seekAbortController = new AbortController();
      const abortHandler = () => {
        this._video.removeEventListener("seeked", seekedHandler);
        this._seekAbortController = null;
        resolve();
      };
      const seekedHandler = () => {
        this._seekAbortController?.signal.removeEventListener("abort", abortHandler);
        this._seekAbortController = null;
        resolve();
      };
      this._seekAbortController.signal.addEventListener("abort", abortHandler, {
        once: true,
        passive: true,
      });
      this._video.addEventListener("seeked", seekedHandler, { once: true, passive: true });
      this._video.currentTime = timestamp % this._metadata.duration;
    });
  }

  /**
   * Ensures that any ongoing load and seek operations are finished.
   */
  async flush(): Promise<void> {
    await this._loadPromise;
    await this._seekPromise;
  }

  /**
   * Get the video image source if the video is not past its end.
   * @returns The video image source or null if the video is past its end.
   */
  get videoImage(): TexImageSource | null {
    if (this._afterEnd) {
      return null;
    }
    return this._video;
  }
}
