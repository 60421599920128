import { CaptionEngine } from "captions-engine";

import { getFontFamily } from "~/theme/fonts/subtitles";
import { Analytics } from "~/utils/analytics/track";

import { isAnimationLoaded, loadAnimation } from "../services/PagAnimations";

import { getPAG } from "./pagWrapper";

export function createCaptionEngine() {
  const newEngine = new CaptionEngine({ track: Analytics.track });
  newEngine.registerFontBackend({
    getFontFamily,
  });
  newEngine.registerPAGBackend({
    getPAGInstance: getPAG,
    isAnimationLoaded,
    loadAnimation,
  });
  return newEngine;
}
