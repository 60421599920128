import type { TransitionAnimationConfig } from "captions-engine";

import type { SoundEffectId } from "../SoundEffectsService/SoundEffects.config";

/** A collection of several different aspect ratio variants for each animation style */
export const TransitionAnimationGroups = {
  bar: [
    {
      id: "transition_bar_16_9",
      duration: 1,
      aspectRatio: 16 / 9,
      url: new URL("./assets/transition_bar_16_9.pag", import.meta.url),
    },
    {
      id: "transition_bar_9_16",
      duration: 1,
      aspectRatio: 9 / 16,
      url: new URL("./assets/transition_bar_9_16.pag", import.meta.url),
    },
    {
      id: "transition_bar_4_5",
      duration: 1,
      aspectRatio: 4 / 5,
      url: new URL("./assets/transition_bar_4_5.pag", import.meta.url),
    },
    {
      id: "transition_bar_1_1",
      duration: 1,
      aspectRatio: 1 / 1,
      url: new URL("./assets/transition_bar_1_1.pag", import.meta.url),
    },
  ],
  block: [
    {
      id: "transition_block_16_9",
      duration: 1,
      aspectRatio: 16 / 9,
      url: new URL("./assets/transition_block_16_9.pag", import.meta.url),
    },
    {
      id: "transition_block_9_16",
      duration: 1,
      aspectRatio: 9 / 16,
      url: new URL("./assets/transition_block_9_16.pag", import.meta.url),
    },
    {
      id: "transition_block_4_5",
      duration: 1,
      aspectRatio: 4 / 5,
      url: new URL("./assets/transition_block_4_5.pag", import.meta.url),
    },
    {
      id: "transition_block_1_1",
      duration: 1,
      aspectRatio: 1 / 1,
      url: new URL("./assets/transition_block_1_1.pag", import.meta.url),
    },
  ],
  circle: [
    {
      id: "transition_circle_16_9",
      duration: 1,
      aspectRatio: 16 / 9,
      url: new URL("./assets/transition_circle_16_9.pag", import.meta.url),
    },
    {
      id: "transition_circle_9_16",
      duration: 1,
      aspectRatio: 9 / 16,
      url: new URL("./assets/transition_circle_9_16.pag", import.meta.url),
    },
    {
      id: "transition_circle_4_5",
      duration: 1,
      aspectRatio: 4 / 5,
      url: new URL("./assets/transition_circle_4_5.pag", import.meta.url),
    },
    {
      id: "transition_circle_1_1",
      duration: 1,
      aspectRatio: 1 / 1,
      url: new URL("./assets/transition_circle_1_1.pag", import.meta.url),
    },
  ],
  diamond: [
    {
      id: "transition_diamond_16_9",
      duration: 1,
      aspectRatio: 16 / 9,
      url: new URL("./assets/transition_diamond_16_9.pag", import.meta.url),
    },
    {
      id: "transition_diamond_9_16",
      duration: 1,
      aspectRatio: 9 / 16,
      url: new URL("./assets/transition_diamond_9_16.pag", import.meta.url),
    },
    {
      id: "transition_diamond_4_5",
      duration: 1,
      aspectRatio: 4 / 5,
      url: new URL("./assets/transition_diamond_4_5.pag", import.meta.url),
    },
    {
      id: "transition_diamond_1_1",
      duration: 1,
      aspectRatio: 1 / 1,
      url: new URL(`./assets/transition_diamond_1_1.pag`, import.meta.url),
    },
  ],
  square: [
    {
      id: "transition_square_16_9",
      duration: 1,
      aspectRatio: 16 / 9,
      url: new URL(`./assets/transition_square_16_9.pag`, import.meta.url),
    },
    {
      id: "transition_square_9_16",
      duration: 1,
      aspectRatio: 9 / 16,
      url: new URL("./assets/transition_square_9_16.pag", import.meta.url),
    },
    {
      id: "transition_square_4_5",
      duration: 1,
      aspectRatio: 4 / 5,
      url: new URL("./assets/transition_square_4_5.pag", import.meta.url),
    },
    {
      id: "transition_square_1_1",
      duration: 1,
      aspectRatio: 1 / 1,
      url: new URL(`./assets/transition_square_1_1.pag`, import.meta.url),
    },
  ],
  fire: [
    {
      id: "transition_fire_16_9",
      duration: 1,
      aspectRatio: 16 / 9,
      url: new URL(`./assets/transition_fire_16_9.pag`, import.meta.url),
    },
    {
      id: "transition_fire_9_16",
      duration: 1,
      aspectRatio: 9 / 16,
      url: new URL("./assets/transition_fire_9_16.pag", import.meta.url),
    },
    {
      id: "transition_fire_4_5",
      duration: 1,
      aspectRatio: 4 / 5,
      url: new URL("./assets/transition_fire_4_5.pag", import.meta.url),
    },
    {
      id: "transition_fire_1_1",
      duration: 1,
      aspectRatio: 1 / 1,
      url: new URL(`./assets/transition_fire_1_1.pag`, import.meta.url),
    },
  ],
  money: [
    {
      id: "transition_money_16_9",
      duration: 1,
      aspectRatio: 16 / 9,
      url: new URL(`./assets/transition_money_16_9.pag`, import.meta.url),
    },
    {
      id: "transition_money_9_16",
      duration: 1,
      aspectRatio: 9 / 16,
      url: new URL("./assets/transition_money_9_16.pag", import.meta.url),
    },
    {
      id: "transition_money_4_5",
      duration: 1,
      aspectRatio: 4 / 5,
      url: new URL("./assets/transition_money_4_5.pag", import.meta.url),
    },
    {
      id: "transition_money_1_1",
      duration: 1,
      aspectRatio: 1 / 1,
      url: new URL(`./assets/transition_money_1_1.pag`, import.meta.url),
    },
  ],
  tile: [
    {
      id: "transition_tile_16_9",
      duration: 1,
      aspectRatio: 16 / 9,
      url: new URL(`./assets/transition_tile_16_9.pag`, import.meta.url),
    },
    {
      id: "transition_tile_9_16",
      duration: 1,
      aspectRatio: 9 / 16,
      url: new URL("./assets/transition_tile_9_16.pag", import.meta.url),
    },
    {
      id: "transition_tile_4_5",
      duration: 1,
      aspectRatio: 4 / 5,
      url: new URL("./assets/transition_tile_4_5.pag", import.meta.url),
    },
    {
      id: "transition_tile_1_1",
      duration: 1,
      aspectRatio: 1 / 1,
      url: new URL(`./assets/transition_tile_1_1.pag`, import.meta.url),
    },
  ],
  surprise: [
    {
      id: "transition_surprise_16_9",
      duration: 1.208334,
      aspectRatio: 16 / 9,
      url: new URL(`./assets/transition_surprise_16_9.pag`, import.meta.url),
    },
    {
      id: "transition_surprise_9_16",
      duration: 1.208334,
      aspectRatio: 9 / 16,
      url: new URL("./assets/transition_surprise_9_16.pag", import.meta.url),
    },
    {
      id: "transition_surprise_4_5",
      duration: 1.208334,
      aspectRatio: 4 / 5,
      url: new URL("./assets/transition_surprise_4_5.pag", import.meta.url),
    },
    {
      id: "transition_surprise_1_1",
      duration: 1.208334,
      aspectRatio: 1 / 1,
      url: new URL(`./assets/transition_surprise_1_1.pag`, import.meta.url),
    },
  ],
  flare: [
    {
      id: "transition_flare_9_16",
      duration: 0.5,
      aspectRatio: 9 / 16,
      url: new URL("./assets/transition_flare_9_16.pag", import.meta.url),
    },
    {
      id: "transition_flare_16_9",
      duration: 0.5,
      aspectRatio: 16 / 9,
      url: new URL("./assets/transition_flare_16_9.pag", import.meta.url),
    },
    {
      id: "transition_flare_4_5",
      duration: 0.5,
      aspectRatio: 4 / 5,
      url: new URL("./assets/transition_flare_4_5.pag", import.meta.url),
    },
    {
      id: "transition_flare_1_1",
      duration: 0.5,
      aspectRatio: 1 / 1,
      url: new URL(`./assets/transition_flare_1_1.pag`, import.meta.url),
    },
  ],
  flash: [
    {
      id: "transition_flash_9_16",
      duration: 1 / 6,
      aspectRatio: 9 / 16,
      url: new URL("./assets/transition_flash_9_16.pag", import.meta.url),
    },
    {
      id: "transition_flash_16_9",
      duration: 1 / 6,
      aspectRatio: 16 / 9,
      url: new URL("./assets/transition_flash_16_9.pag", import.meta.url),
    },
    {
      id: "transition_flash_4_5",
      duration: 1 / 6,
      aspectRatio: 4 / 5,
      url: new URL("./assets/transition_flash_4_5.pag", import.meta.url),
    },
    {
      id: "transition_flash_1_1",
      duration: 1 / 6,
      aspectRatio: 1 / 1,
      url: new URL(`./assets/transition_flash_1_1.pag`, import.meta.url),
    },
  ],
  flash2: [
    {
      id: "transition_flash2_9_16",
      duration: 1 / 6,
      aspectRatio: 9 / 16,
      url: new URL("./assets/transition_flash2_9_16.pag", import.meta.url),
    },
  ],
  film: [
    {
      id: "transition_film_9_16",
      duration: 1 / 3,
      aspectRatio: 9 / 16,
      url: new URL("./assets/transition_film_9_16.pag", import.meta.url),
    },
    {
      id: "transition_film_16_9",
      duration: 1 / 3,
      aspectRatio: 16 / 9,
      url: new URL("./assets/transition_film_16_9.pag", import.meta.url),
    },
    {
      id: "transition_film_4_5",
      duration: 1 / 3,
      aspectRatio: 4 / 5,
      url: new URL("./assets/transition_film_4_5.pag", import.meta.url),
    },
    {
      id: "transition_film_1_1",
      duration: 1 / 3,
      aspectRatio: 1 / 1,
      url: new URL(`./assets/transition_film_1_1.pag`, import.meta.url),
    },
  ],
  leak: [
    {
      id: "transition_leak_9_16",
      duration: 0.5,
      aspectRatio: 9 / 16,
      url: new URL("./assets/transition_leak_9_16.pag", import.meta.url),
    },
    {
      id: "transition_leak_16_9",
      duration: 0.5,
      aspectRatio: 16 / 9,
      url: new URL("./assets/transition_leak_16_9.pag", import.meta.url),
    },
    {
      id: "transition_leak_4_5",
      duration: 0.5,
      aspectRatio: 4 / 5,
      url: new URL("./assets/transition_leak_4_5.pag", import.meta.url),
    },
    {
      id: "transition_leak_1_1",
      duration: 0.5,
      aspectRatio: 1 / 1,
      url: new URL(`./assets/transition_leak_1_1.pag`, import.meta.url),
    },
  ],
  zoom: [
    {
      id: "transition_zoom_9_16",
      duration: 1,
      aspectRatio: 9 / 16,
      url: new URL("./assets/transition_zoom_9_16.pag", import.meta.url),
    },
    {
      id: "transition_zoom_16_9",
      duration: 1,
      aspectRatio: 16 / 9,
      url: new URL("./assets/transition_zoom_16_9.pag", import.meta.url),
    },
    {
      id: "transition_zoom_4_5",
      duration: 1,
      aspectRatio: 4 / 5,
      url: new URL("./assets/transition_zoom_4_5.pag", import.meta.url),
    },
    {
      id: "transition_zoom_1_1",
      duration: 1,
      aspectRatio: 1 / 1,
      url: new URL(`./assets/transition_zoom_1_1.pag`, import.meta.url),
    },
  ],
  swipeUp: [
    {
      id: "transition_swipe_up_9_16",
      duration: 1,
      aspectRatio: 9 / 16,
      url: new URL("./assets/transition_swipe_up_9_16.pag", import.meta.url),
    },
    {
      id: "transition_swipe_up_16_9",
      duration: 1,
      aspectRatio: 16 / 9,
      url: new URL("./assets/transition_swipe_up_16_9.pag", import.meta.url),
    },
    {
      id: "transition_swipe_up_4_5",
      duration: 1,
      aspectRatio: 4 / 5,
      url: new URL("./assets/transition_swipe_up_4_5.pag", import.meta.url),
    },
    {
      id: "transition_swipe_up_1_1",
      duration: 1,
      aspectRatio: 1 / 1,
      url: new URL(`./assets/transition_swipe_up_1_1.pag`, import.meta.url),
    },
  ],
  left: [
    {
      id: "transition_left_9_16",
      duration: 1,
      aspectRatio: 9 / 16,
      url: new URL("./assets/transition_left_9_16.pag", import.meta.url),
    },
    {
      id: "transition_left_16_9",
      duration: 1,
      aspectRatio: 16 / 9,
      url: new URL("./assets/transition_left_16_9.pag", import.meta.url),
    },
    {
      id: "transition_left_4_5",
      duration: 1,
      aspectRatio: 4 / 5,
      url: new URL("./assets/transition_left_4_5.pag", import.meta.url),
    },
    {
      id: "transition_left_1_1",
      duration: 1,
      aspectRatio: 1 / 1,
      url: new URL(`./assets/transition_left_1_1.pag`, import.meta.url),
    },
  ],
  shake: [
    {
      id: "transition_shake_9_16.pag",
      duration: 1,
      aspectRatio: 9 / 16,
      url: new URL("./assets/transition_shake_9_16.pag", import.meta.url),
    },
  ],
  paperTearOverlay: [
    {
      id: "paper_tear_overlay_9_16.pag",
      duration: 1,
      aspectRatio: 9 / 16,
      url: new URL("./assets/paper_tear_overlay_9_16.pag", import.meta.url),
    },
  ],
  paperTearSplit: [
    {
      id: "paper_tear_split_9_16.pag",
      duration: 0.5,
      aspectRatio: 9 / 16,
      url: new URL("./assets/paper_tear_split_9_16.pag", import.meta.url),
    },
  ],
  paperTearWipe: [
    {
      id: "paper_tear_wipe_9_16.pag",
      duration: 2 / 3,
      aspectRatio: 9 / 16,
      url: new URL("./assets/paper_tear_wipe_9_16.pag", import.meta.url),
    },
  ],
  gemstones: [
    {
      id: "gemstones_9_16",
      duration: 0.791667,
      aspectRatio: 9 / 16,
      url: new URL("./assets/gemstones_9_16.pag", import.meta.url),
    },
    {
      id: "gemstones_16_9",
      duration: 0.791667,
      aspectRatio: 16 / 9,
      url: new URL("./assets/gemstones_16_9.pag", import.meta.url),
    },
    {
      id: "gemstones_4_5",
      duration: 0.791667,
      aspectRatio: 4 / 5,
      url: new URL("./assets/gemstones_4_5.pag", import.meta.url),
    },
    {
      id: "gemstones_1_1",
      duration: 0.791667,
      aspectRatio: 1 / 1,
      url: new URL(`./assets/gemstones_1_1.pag`, import.meta.url),
    },
  ],
  markerWipe: [
    {
      id: "marker_wipe_9_16",
      duration: 0.875,
      aspectRatio: 9 / 16,
      url: new URL("./assets/marker_wipe_9_16.pag", import.meta.url),
    },
    {
      id: "marker_wipe_16_9",
      duration: 0.875,
      aspectRatio: 16 / 9,
      url: new URL("./assets/marker_wipe_16_9.pag", import.meta.url),
    },
    {
      id: "marker_wipe_4_5",
      duration: 0.875,
      aspectRatio: 4 / 5,
      url: new URL("./assets/marker_wipe_4_5.pag", import.meta.url),
    },
    {
      id: "marker_wipe_1_1",
      duration: 0.875,
      aspectRatio: 1 / 1,
      url: new URL(`./assets/marker_wipe_1_1.pag`, import.meta.url),
    },
  ],
  tornPaper: [
    {
      id: "torn_paper_9_16",
      duration: 1,
      aspectRatio: 9 / 16,
      url: new URL("./assets/torn_paper_9_16.pag", import.meta.url),
    },
    {
      id: "torn_paper_16_9",
      duration: 1,
      aspectRatio: 16 / 9,
      url: new URL("./assets/torn_paper_16_9.pag", import.meta.url),
    },
    {
      id: "torn_paper_4_5",
      duration: 1,
      aspectRatio: 4 / 5,
      url: new URL("./assets/torn_paper_4_5.pag", import.meta.url),
    },
    {
      id: "torn_paper_1_1",
      duration: 1,
      aspectRatio: 1 / 1,
      url: new URL(`./assets/torn_paper_1_1.pag`, import.meta.url),
    },
  ],
} satisfies Record<string, TransitionAnimationConfig[]>;

/** Ids of each group e.g. "bar", "circle", etc. */
export type TransitionAnimationGroupId = keyof typeof TransitionAnimationGroups;

export const TransitionAnimationGroupIds = Object.keys(
  TransitionAnimationGroups
) as TransitionAnimationGroupId[];

export const TransitionSoundIds: Partial<Record<TransitionAnimationGroupId, SoundEffectId>> = {
  bar: "Bar.wav",
  circle: "Circle.wav",
  block: "Block.wav",
  square: "Square.wav",
  diamond: "Diamond.wav",
  fire: "Fire.wav",
  money: "Money.wav",
  tile: "Tile.wav",
  surprise: "Excited.wav",
  flare: "Flare.wav",
  leak: "Leak.wav",
  flash: "Flash.wav",
  flash2: "Flash_2.wav",
  film: "Film.wav",
  gemstones: "Gemstone.wav",
  markerWipe: "Marker_Wipe.wav",
  tornPaper: "Torn_Paper.wav",
};

/** A flattened mapping from individual animation ids to their PAG URL */
export const TransitionAnimationUrls: Record<string, URL> = Object.fromEntries(
  TransitionAnimationGroupIds.flatMap((groupId) =>
    TransitionAnimationGroups[groupId].map(({ id, url }) => [id, url])
  )
);
