// From the samples at https://developer.mozilla.org/en-US/docs/Web/API/WebGL_API/Tutorial/Animating_textures_in_WebGL

import { PixelMapping } from "./webgl.types";

// TODO: Remove duplicate file in desktop-experience/packages/ui/src/utils/webgl.ts

//
// creates a shader of the given type, uploads the source and
// compiles it.
//

function loadShader(gl: WebGLRenderingContext, type: number, source: string): WebGLShader {
  const shader = gl.createShader(type);
  if (!shader) {
    throw new Error("Unable to create shader");
  }

  // Send the source to the shader object
  gl.shaderSource(shader, source);

  // Compile the shader program
  gl.compileShader(shader);

  // See if it compiled successfully
  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    const info = gl.getShaderInfoLog(shader);
    gl.deleteShader(shader);
    throw new Error(`An error occurred compiling the shaders: ${info}`);
  }

  return shader;
}

//
// Initialize a shader program, so WebGL knows how to draw our data
//
export function initShaderProgram(
  gl: WebGLRenderingContext,
  vsSource: string,
  fsSource: string
): WebGLProgram {
  const vertexShader = loadShader(gl, gl.VERTEX_SHADER, vsSource);
  const fragmentShader = loadShader(gl, gl.FRAGMENT_SHADER, fsSource);

  // Create the shader program

  const shaderProgram = gl.createProgram();
  if (!shaderProgram) {
    throw new Error("Unable to create shader program");
  }
  gl.attachShader(shaderProgram, vertexShader);
  gl.attachShader(shaderProgram, fragmentShader);
  gl.linkProgram(shaderProgram);

  // If creating the shader program failed, alert

  if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
    throw new Error(
      `Unable to initialize the shader program: ${gl.getProgramInfoLog(shaderProgram)}`
    );
  }

  return shaderProgram;
}

export function createEmptyTexture(gl: WebGLRenderingContext, color: number[]): WebGLTexture {
  const texture = gl.createTexture();
  if (!texture) {
    throw new Error("Unable to create texture");
  }
  gl.bindTexture(gl.TEXTURE_2D, texture);

  // Fill the texture with a 1x1 blue pixel.
  gl.texImage2D(
    gl.TEXTURE_2D,
    0,
    gl.RGBA,
    1,
    1,
    0,
    gl.RGBA,
    gl.UNSIGNED_BYTE,
    new Uint8Array(color)
  );
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);

  return texture;
}

export function createWebGLArrayBuffer(
  gl: WebGLRenderingContext,
  data: number[],
  target: "array_buffer" | "element_array_buffer" = "array_buffer"
): WebGLBuffer {
  const buffer = gl.createBuffer();
  if (!buffer) {
    throw new Error("Unable to create buffer");
  }
  const glTarget = target === "array_buffer" ? gl.ARRAY_BUFFER : gl.ELEMENT_ARRAY_BUFFER;
  const array = target === "array_buffer" ? new Float32Array(data) : new Uint16Array(data);
  gl.bindBuffer(glTarget, buffer);
  gl.bufferData(glTarget, array, gl.STATIC_DRAW);
  return buffer;
}

export function setVertexAttributeBuffer(
  gl: WebGLRenderingContext,
  buffer: WebGLBuffer,
  attributePosition: GLint,
  numComponents: number
): void {
  const type = gl.FLOAT; // the data in the buffer is 32bit floats
  const normalize = false; // don't normalize
  const stride = 0; // how many bytes to get from one set of values to the next
  // 0 = use type and numComponents above
  const offset = 0; // how many bytes inside the buffer to start from
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
  gl.vertexAttribPointer(attributePosition, numComponents, type, normalize, stride, offset);
  gl.enableVertexAttribArray(attributePosition);
}

export function updateTexture(
  gl: WebGLRenderingContext,
  texture: WebGLTexture,
  image: TexImageSource
) {
  const level = 0;
  const internalFormat = gl.RGB;
  const srcFormat = gl.RGB;
  const srcType = gl.UNSIGNED_BYTE;
  gl.bindTexture(gl.TEXTURE_2D, texture);
  gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, srcFormat, srcType, image);
}

export function updateTransparentTexture(
  gl: WebGLRenderingContext,
  texture: WebGLTexture,
  image: TexImageSource
) {
  const level = 0;
  const internalFormat = gl.RGBA;
  const srcFormat = gl.RGBA;
  const srcType = gl.UNSIGNED_BYTE;
  gl.bindTexture(gl.TEXTURE_2D, texture);
  gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, srcFormat, srcType, image);
}

export function applyPixelMapping(
  gl: WebGLRenderingContext,
  programMap: {
    from: {
      topLeft: WebGLUniformLocation | null;
      bottomRight: WebGLUniformLocation | null;
    };
    to: {
      topLeft: WebGLUniformLocation | null;
      bottomRight: WebGLUniformLocation | null;
    };
    bounds?: {
      topLeft: WebGLUniformLocation | null;
      bottomRight: WebGLUniformLocation | null;
    };
    rotation?: WebGLUniformLocation | null;
  },
  map: PixelMapping
) {
  gl.uniform2fv(programMap.from.topLeft, [map.from.left, map.from.top]);
  gl.uniform2fv(programMap.from.bottomRight, [map.from.right, map.from.bottom]);
  gl.uniform2fv(programMap.to.topLeft, [map.to.left, map.to.top]);
  gl.uniform2fv(programMap.to.bottomRight, [map.to.right, map.to.bottom]);

  if (programMap.bounds) {
    map.bounds = map.bounds ?? map.to;
    gl.uniform2fv(programMap.bounds.topLeft, [map.bounds.left, map.bounds.top]);
    gl.uniform2fv(programMap.bounds.bottomRight, [map.bounds.right, map.bounds.bottom]);
  }
  if (programMap.rotation) {
    map.rotation = map.rotation ?? 0;
    gl.uniform1f(programMap.rotation, map.rotation ?? 0);
  }
}

export function createFramebufferTexture(
  gl: WebGL2RenderingContext | WebGLRenderingContext,
  width: number,
  height: number
) {
  const texture = gl.createTexture();
  gl.bindTexture(gl.TEXTURE_2D, texture);
  gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, width, height, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

  const framebuffer = gl.createFramebuffer();
  gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
  gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture, 0);

  gl.bindTexture(gl.TEXTURE_2D, null);
  gl.bindFramebuffer(gl.FRAMEBUFFER, null);

  return { texture, framebuffer };
}

export class Matrix {
  data: number[];
  numRows: number;
  numCols: number;

  constructor(data: number[], numRows: number, numCols: number) {
    this.data = data;
    this.numRows = numRows;
    this.numCols = numCols;
  }
}

export function createIdentityMatrix(nRows: number = 4, nCols: number = 4) {
  const data = new Array(nRows * nCols).fill(0);
  for (let i = 0; i < nRows; i++) {
    data[i * nCols + i] = 1;
  }
  return new Matrix(data, nRows, nCols);
}

export function createRotationMatrix(
  angle: number,
  numRows: number = 4,
  numCols: number = 4
): Matrix {
  if (numRows !== numCols) {
    throw new Error("Rotation matrix must be square (numRows must equal numCols).");
  }

  const radians = (Math.PI * angle) / 180;
  const cosB = Math.cos(radians);
  const sinB = Math.sin(radians);

  const data = new Array(numRows * numCols).fill(0);

  for (let i = 0; i < numRows; i++) {
    data[i * numCols + i] = 1;
  }

  data[0 * numCols + 0] = cosB;
  data[0 * numCols + 1] = -sinB;
  data[1 * numCols + 0] = sinB;
  data[1 * numCols + 1] = cosB;

  return {
    data,
    numRows,
    numCols,
  };
}

export function createScaleMatrix(
  scale: {
    x: number;
    y: number;
  },
  numRows: number = 4,
  numCols: number = 4
): Matrix {
  if (numRows !== numCols) {
    throw new Error("Scale matrix must be square (numRows must equal numCols).");
  }

  const data = new Array(numRows * numCols).fill(0);

  for (let i = 0; i < numRows; i++) {
    data[i * numCols + i] = 1;
  }

  data[0 * numCols + 0] = scale.x;
  data[1 * numCols + 1] = scale.y;

  return {
    data,
    numRows,
    numCols,
  };
}

/**
 * Rotates a point around a center point.
 * @param x - The x-coordinate of the point to rotate.
 * @param y - The y-coordinate of the point to rotate.
 * @param cx - The x-coordinate of the center of rotation.
 * @param cy - The y-coordinate of the center of rotation.
 * @param radians - The rotation angle in radians.
 * @returns The rotated point coordinates.
 */
export function rotatePoint(
  x: number,
  y: number,
  cx: number,
  cy: number,
  radians: number
): { x: number; y: number } {
  const cos = Math.cos(radians),
    sin = Math.sin(radians),
    nx = cos * (x - cx) + sin * (y - cy) + cx,
    ny = cos * (y - cy) - sin * (x - cx) + cy;
  return { x: nx, y: ny };
}

export function calculateRotatedBoundingBox(width: number, height: number, rotation: number) {
  // Calculate the rotated width and height
  const cos = Math.abs(Math.cos(rotation));
  const sin = Math.abs(Math.sin(rotation));

  const rotatedWidth = width * cos + height * sin;
  const rotatedHeight = width * sin + height * cos;

  return {
    width: rotatedWidth,
    height: rotatedHeight,
  };
}

export function degreesToRad(degrees: number) {
  return (degrees * Math.PI) / 180;
}
