import type { EDLdata, TimeSpan } from "./EDL.types";

// linear interpolation from source space to destination space
const segInterp = (
  seg: [srcA: number, srcB: number, dstA: number, dstB: number],
  srcValue: number
) => {
  const [srcA, srcB, dstA, dstB] = seg;
  if (srcValue < srcA || srcValue > srcB) {
    return null;
  }
  if (dstA === dstB) {
    return dstA;
  }

  const alphaValue = srcA === srcB ? 0 : (srcValue - srcA) / (srcB - srcA);
  return (1 - alphaValue) * dstA + alphaValue * dstB;
};

export class EDL {
  static getMaxMainTime(edl: EDLdata) {
    if (!edl.segments.length) {
      return 0;
    }
    return Math.max(...edl.segments.map((seg) => seg[1]));
  }

  static mainToLocalTime(edl: EDLdata, mainTime: number) {
    const { segments } = edl;

    const firstSeg = segments.find((segment, i) => {
      const [mainStart, mainEnd] = segment;
      const withinStart = mainTime >= mainStart;
      // only last segment has inclusive end
      const withinEnd = i === segments.length - 1 ? mainTime <= mainEnd : mainTime < mainEnd;
      return withinStart && withinEnd;
    });

    if (!firstSeg) {
      return null;
    }

    // todo support repeat wrapMode (local % localDuration)
    // linear map of main time into local time
    return segInterp([firstSeg[0], firstSeg[1], firstSeg[2], firstSeg[3]], mainTime);
  }

  static localToMainTime(edl: EDLdata, localTime: number) {
    const { segments } = edl;

    const firstSeg = segments.find((segment, i) => {
      const [, , localStart, localEnd] = segment;
      const withinStart = localTime >= localStart;
      // only last segment has inclusive end
      const withinEnd = i === segments.length - 1 ? localTime <= localEnd : localTime < localEnd;
      return withinStart && withinEnd;
    });

    if (!firstSeg) {
      return null;
    }

    // linear map of main time into local time
    return segInterp([firstSeg[2], firstSeg[3], firstSeg[0], firstSeg[1]], localTime);
  }

  static getMainTimespans(edl: EDLdata): TimeSpan[] {
    const timespans = edl.segments.map((segment) => {
      const [mainStart, mainEnd] = segment;

      return {
        startTime: mainStart,
        endTime: mainEnd,
      };
    });

    return timespans;
  }

  static getLocalTimespans(edl: EDLdata): TimeSpan[] {
    const timespans = edl.segments.map((segment) => {
      const [, , localStart, localEnd] = segment;

      return {
        startTime: localStart,
        endTime: localEnd,
      };
    });

    return timespans;
  }

  // to implement
  // removeTimeSegment
}
