import { Text as BaseText } from "./Text.styles";

interface TruncatedTextProps extends React.ComponentProps<typeof BaseText> {
  numberOfLines: number;
}

export function TruncatedText({ numberOfLines, css, ...props }: TruncatedTextProps) {
  return (
    <BaseText
      {...props}
      css={{
        // TODO: figure out how to cross browser this
        display: "-webkit-box",
        WebkitLineClamp: numberOfLines,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        ...css,
      }}
    />
  );
}
