import { UserFont } from "../database.types";
import { withRetry } from "../repositoryWrapper";
import { getDB } from "../store";

class BaseUserFontsRepository {
  static async addUserFont(userId: string | undefined, userFontData: UserFont["userFontData"]) {
    if (!userId) {
      return;
    }
    const db = await getDB();

    const userFont: UserFont = {
      id: window.crypto.randomUUID(),
      userId,
      userFontData,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    await db.add("userFonts", userFont);
    return userFont;
  }

  static async removeUserFont(userId: string | undefined, userFontId: string) {
    if (!userId) {
      return;
    }
    const db = await getDB();

    const tx = db.transaction(["userFonts"], "readwrite");
    const userFont = await tx.objectStore("userFonts").get(userFontId);
    if (!userFont || (userFont.userId && userFont.userId !== userId)) {
      tx.abort();
      throw new Error("UserFont not found");
    }
    await tx.objectStore("userFonts").delete(userFontId);
    await tx.done;
  }

  static async updateUserFont(
    userId: string | undefined,
    userFontId: string,
    data: Partial<Omit<UserFont, "id">>
  ) {
    if (!userId) {
      return;
    }
    const db = await getDB();

    const tx = db.transaction(["userFonts"], "readwrite");
    const userFont = await tx.objectStore("userFonts").get(userFontId);
    if (!userFont || (userFont.userId && userFont.userId !== userId)) {
      tx.abort();
      throw new Error("UserFont not found");
    }
    if (data.userId && data.userId !== userId) {
      tx.abort();
      throw new Error("User ID mismatch");
    }
    Object.assign(userFont, data);
    await tx.objectStore("userFonts").put(userFont);
    await tx.done;
    return userFont;
  }

  static async getUserFont(
    userId: string | undefined,
    userFontId: string
  ): Promise<UserFont | undefined> {
    if (!userId) {
      return;
    }
    const db = await getDB();

    return await db.get("userFonts", userFontId).then((userFont) => {
      if (userFont?.userId && userFont.userId !== userId) {
        return undefined;
      }
      return userFont;
    });
  }

  static async getAllUserFonts(userId: string | undefined): Promise<Array<UserFont>> {
    if (!userId) {
      return [];
    }
    const db = await getDB();

    return await db.getAll("userFonts").then((userFonts) => {
      return userFonts.filter((userFont) => !userFont.userId || userFont.userId === userId);
    });
  }
}

export const UserFontsRepository = withRetry(BaseUserFontsRepository);
