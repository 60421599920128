import * as RadixScrollArea from "@radix-ui/react-scroll-area";

import { styled } from "~/theme";

export const SCROLLAREA_DATA_ATTR = "data-radix-scroll-area-viewport";

export const Root = styled(RadixScrollArea.Root, {
  "--fade-height": "32px",
  "--fade-width": "32px",
  "--fade-color": "$colors$grey-900",
  contain: "content",
  variants: {
    flexColumn: {
      true: {
        minHeight: "0px",
      },
    },
    fillColumn: {
      true: {
        flex: "1 1 0px",
        minHeight: "0px",
        width: "100%",
      },
    },
    fillRow: {
      true: {
        flex: "1 1 0px",
        minWidth: "0px",
      },
    },
  },
});

export const Viewport = styled(RadixScrollArea.Viewport, {
  "> div[style]": {
    display: "block !important",
  },
  variants: {
    flexColumn: {
      true: {
        height: "100%",
      },
    },
    fillColumn: {
      true: {
        height: "100%",
      },
    },
    fillRow: {
      true: {
        width: "100%",
      },
    },
    fadeTop: {
      true: {
        maskImage: "linear-gradient(to bottom, transparent, black var(--fade-height), black 100%)",
      },
    },
    fadeBottom: {
      true: {
        maskImage:
          "linear-gradient(to bottom, black, black calc(100% - var(--fade-height)), transparent 100%)",
      },
    },
    fadeLeft: {
      true: {
        maskImage: "linear-gradient(to right, transparent, black var(--fade-width), black 100%)",
      },
    },
    fadeRight: {
      true: {
        maskImage:
          "linear-gradient(to right, black, black calc(100% - var(--fade-width)), transparent 100%)",
      },
    },
  },
  compoundVariants: [
    {
      fadeTop: true,
      fadeBottom: true,
      css: {
        maskImage:
          "linear-gradient(to bottom, transparent, black var(--fade-height), black calc(100% - var(--fade-height)), transparent 100%)",
      },
    },
    {
      fadeLeft: true,
      fadeRight: true,
      css: {
        maskImage:
          "linear-gradient(to right, transparent, black var(--fade-width), black calc(100% - var(--fade-width)), transparent 100%)",
      },
    },
  ],
});

export const Scrollbar = styled(RadixScrollArea.Scrollbar, {
  "&[data-orientation='vertical']": {
    width: "10px",
    padding: "5px 2px",
  },
  "&[data-orientation='horizontal']": {
    flexDirection: "column",
    height: "10px",
    padding: "2px 5px",
  },
  variants: {
    isVisible: {
      false: {
        opacity: 0,
      },
    },
  },
});

export const Thumb = styled(RadixScrollArea.Thumb, {
  minWidth: "6px",
  minHeight: "6px",
  background: "$grey-700",
  borderRadius: "4px",
});

export const ScrollFade = styled("div", {
  position: "absolute",
  pointerEvents: "none",
  transition: "opacity 200ms",
  variants: {
    showing: {
      true: {
        opacity: 1,
      },
      false: {
        opacity: 0,
      },
    },
    position: {
      top: {
        top: 0,
        left: 0,
        right: 0,
        height: "var(--fade-height)",
        background: "linear-gradient(to bottom, var(--fade-color), transparent)",
      },
      bottom: {
        bottom: 0,
        left: 0,
        right: 0,
        height: "var(--fade-height)",
        background: "linear-gradient(to bottom, transparent, var(--fade-color))",
      },
      left: {
        top: 0,
        bottom: 0,
        left: 0,
        width: "var(--fade-width)",
        background: "linear-gradient(to right, var(--fade-color), transparent)",
      },
      right: {
        top: 0,
        bottom: 0,
        right: 0,
        width: "var(--fade-width)",
        background: "linear-gradient(to right, transparent, var(--fade-color))",
      },
    },
  },
});
