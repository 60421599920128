import { VISION_TASKS_WASM_PATH } from "captions-engine";
import { DevLogger } from "dev-logger";
import { detectFaces } from "face-detection";
import { useCallback, useEffect, useRef } from "react";

import { useLatestRef } from "~/hooks/helpers";

import { ProjectFaceData } from "../utils/faceData/face-data.types";

const logger = new DevLogger("[client-face-detection]");

interface FaceDetectionProps {
  onProgress?: (currentFrame: number, totalFrames: number) => void;
  onError?: (error: Error) => void;
}

export interface DetectionJobOptions {
  fps?: number;
  onFinalized: (faceData: ProjectFaceData) => void;
}

/**
 * Hook for face detection.
 * @param onProgress Callback for progress updates.
 * @param onError Callback for errors.
 */
export function useFaceDetection({ onProgress, onError }: FaceDetectionProps) {
  const progressHandler = useLatestRef(onProgress);
  const errorHandler = useLatestRef(onError);
  const abortControllerRef = useRef<AbortController | null>(null);

  const startDetection = useCallback(
    (videoUrl: string | URL, options: DetectionJobOptions) => {
      // Cancel previous detection (if any)
      abortControllerRef.current?.abort();
      // Start detection
      const abortController = new AbortController();
      abortControllerRef.current = abortController;
      logger.log("Starting face detection...");
      detectFaces(videoUrl, {
        wasmBasePath: VISION_TASKS_WASM_PATH,
        fps: options?.fps,
        normalize: false,
        onProgress: (currentFrame, totalFrames) => {
          logger.log(`Progress: ${currentFrame}/${totalFrames}`);
          progressHandler.current?.(currentFrame, totalFrames);
        },
        signal: abortController.signal,
      })
        .then((faceData) => {
          logger.log("Face detection completed!");
          let fps = 0;
          if (faceData.length > 1) {
            const timeDiff = faceData[1].timestamp - faceData[0].timestamp;
            fps = timeDiff > 0 ? 1 / timeDiff : 0;
          }
          options.onFinalized({
            fps,
            faceFrames: faceData.map((frame) =>
              frame.faces.map((face) => [face.x, face.y, face.width, face.height])
            ),
          });
        })
        .catch((error) => {
          if (!abortController.signal.aborted) {
            logger.error("Error while detecting faces!", error);
            errorHandler.current?.(new Error("Error while detecting faces!", { cause: error }));
          } else {
            logger.log("Face detection canceled.");
          }
        })
        .finally(() => {
          abortControllerRef.current = null;
        });
    },
    [progressHandler, errorHandler]
  );

  const cancelDetection = useCallback(() => {
    // Cancel detection
    abortControllerRef.current?.abort();
  }, [abortControllerRef]);

  useEffect(() => {
    return () => {
      // Cleanup
      abortControllerRef.current?.abort();
    };
  }, []);

  return {
    startDetection,
    cancelDetection,
  };
}
