import { DevLogger } from "dev-logger";

import { StoredAsset } from "../database.types";
import { getDB } from "../store";

const logger = new DevLogger("[image-repo]");

export class StoredImagesRepository {
  private static lazySourceURLIndexCacheRef = new Map<string, StoredAsset>();

  static async addImageBlob({
    blob,
    name,
    type,
    id,
    sourceURL,
  }: {
    blob: Blob;
    name: string;
    type?: "image" | "video";
    id?: string;
    sourceURL?: string;
  }) {
    const db = await getDB();

    if (id) {
      const existingImage = await db.get("storedImages", id);
      if (existingImage) {
        return existingImage;
      }
    }

    const newImage: StoredAsset = {
      id: id ?? window.crypto.randomUUID(),
      name,
      blob,
      type: type ?? "image",
      sourceURL,
    };

    await db.add("storedImages", newImage);

    if (sourceURL) {
      StoredImagesRepository.lazySourceURLIndexCacheRef.set(sourceURL, newImage);
      logger.log("fill cache when adding", sourceURL, newImage);
    }

    return newImage;
  }

  static addImage(imageFile: File) {
    return StoredImagesRepository.addImageBlob({
      blob: new Blob([imageFile]),
      name: imageFile.name,
    });
  }

  static async removeImage(imageId: string) {
    const db = await getDB();

    const tx = db.transaction(["storedImages"], "readwrite");
    const asset = await tx.objectStore("storedImages").get(imageId);
    if (!asset) {
      tx.abort();
      throw new Error("Image not found");
    }
    await tx.objectStore("storedImages").delete(imageId);
    await tx.done;

    if (asset.type === "image" && asset.sourceURL) {
      StoredImagesRepository.lazySourceURLIndexCacheRef.delete(asset.sourceURL);
    }
  }

  static async getImage(imageId: string) {
    const db = await getDB();

    const asset = await db.get("storedImages", imageId);

    if (asset?.type === "image" && asset.sourceURL) {
      StoredImagesRepository.lazySourceURLIndexCacheRef.set(asset.sourceURL, asset);
      logger.log("fill cache when fetching", asset.sourceURL, asset);
    }

    return asset;
  }

  static getImageBySourceURL(sourceURL: string) {
    return StoredImagesRepository.lazySourceURLIndexCacheRef.get(sourceURL);
  }
}
