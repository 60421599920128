import { createCanvas, texImageSourceToHtmlImage } from "canvas-utils";
import { DevLogger } from "dev-logger";
import { PAGComposition } from "libpag/types/web/src/pag-composition";
import { PAGFile } from "libpag/types/web/src/pag-file";
import { PAGImage } from "libpag/types/web/src/pag-image";
import { PAGView } from "libpag/types/web/src/pag-view";
import { PAG } from "libpag/types/web/src/types";
import invariant from "tiny-invariant";

import { MICROSECONDS_IN_SECOND } from "./constants/time.constants";
import { PagSequencePainterItem } from "./pagSequencePainter.types";
import { htmlImageElementFromBlob } from "./utils/image";
import {
  getNewlinedTextAndScaledFontSize,
  setPAGViewAutoClear,
  stretchImageToFitLayer,
  stretchLayerToFitComposition,
} from "./utils/pagUtils";

const logger = new DevLogger("[pag-sequence-painter]");

export class PagSequencePainter {
  // variables set by caller
  private _pagInstance?: PAG;
  private _onLoadAnimation?: (animationId: string) => Promise<PAGFile | null>;
  private _mainCanvas?: HTMLCanvasElement | OffscreenCanvas | null;
  private _width: number = 0;
  private _height: number = 0;
  private _pagSequences: PagSequencePainterItem[] = [];
  private _timestamp: number = 0;

  // variables set internally
  private _activeSeq: PagSequencePainterItem | null = null;
  private _activeSeqPagItems: {
    id: string;
    pagFile: PAGFile;
    startTime: number;
    endTime: number;
  }[] = [];
  private _activePagItemId: string | null = null;
  private _mainPagView?: PAGView;
  private _backgroundPagView?: PAGView;
  private _pagComposition: PAGComposition | null = null;
  private _backgroundCanvas?: HTMLCanvasElement | OffscreenCanvas;

  public init(
    pagInstance: PAG,
    onLoadAnimation: (animationId: string) => Promise<PAGFile | null>,
    width: number,
    height: number
  ) {
    this._pagInstance = pagInstance;
    this._onLoadAnimation = onLoadAnimation;
    this._width = width;
    this._height = height;
  }

  public setCanvas(canvas: HTMLCanvasElement | OffscreenCanvas | null) {
    this._mainCanvas = canvas;
  }

  public setPagSequences(pagSequences: PagSequencePainterItem[]) {
    this._pagSequences = pagSequences;

    if (pagSequences.some((seq) => seq.isBackground)) {
      // If any of the pag sequences are background sequences, create a canvas to render them to
      this._initBackgroundCanvas();
    }
  }

  public async setTimestamp(timestamp: number) {
    this._timestamp = timestamp;
    await this._updateActiveSequence();
    if (this._activeSeq) {
      this._updateActivePagItemId();
    }
  }

  /**
   * Renders the active pag sequence to the main rendering canvas.
   * @param videoFrame
   */
  public async paint(videoFrame: TexImageSource | null) {
    if (!videoFrame) {
      logger.warn("Attempted to paint but no video frame is available");
      return;
    }
    if (!this._pagInstance) {
      logger.warn("Attempted to paint but no pagInstance is available");
      return;
    }
    if (!this._mainCanvas) {
      logger.warn("Attempted to paint but the main canvas is not available");
      return;
    }

    const activeSeq = this._activeSeq;

    if (!activeSeq || activeSeq.isBackground || !this._activePagItemId) {
      return;
    }

    await this._generateComposition(videoFrame);
    if (!this._pagComposition) {
      return;
    }

    if (!this._mainPagView) {
      this._mainPagView = await this._pagInstance.PAGView.init(
        this._pagComposition,
        this._mainCanvas,
        {
          useScale: false,
        }
      );
      if (this._mainPagView) {
        // Ensures that the PAGView does NOT clear the canvas before drawing, so it acts like an overlay
        setPAGViewAutoClear(this._mainPagView, false);
      }
    } else {
      this._mainPagView.setComposition(this._pagComposition);
    }

    const localTimestamp = (this._timestamp - activeSeq.startTime) * MICROSECONDS_IN_SECOND;
    this._setCompositionProgress(localTimestamp);

    await this._mainPagView?.prepare();
    await this._mainPagView?.flush();
    return;
  }

  /**
   * Renders the active background PAG sequence onto a background canvas.
   * This method handles both the video frame rendering and PAG composition overlay.
   *
   * The process involves:
   * 1. Rendering the video frame to the background canvas
   * 2. Generating and applying the PAG composition
   * 3. Rendering the composition with the correct timing
   *
   * @param videoFrame
   */
  public async paintToBackground(videoFrame: TexImageSource | null) {
    if (!videoFrame) {
      logger.warn("Attempted to paintToBackground but no video frame is available");
      return;
    }
    if (!this._pagInstance) {
      logger.warn("Attempted to paintToBackground but no pagInstance is available");
      return;
    }

    const activeSeq = this._activeSeq;

    if (!activeSeq || !activeSeq?.isBackground || !this._activePagItemId) {
      return;
    }

    this._initBackgroundCanvas();

    await this._generateComposition(videoFrame);
    if (!this._pagComposition) {
      return;
    }

    if (!this._backgroundCanvas) {
      logger.warn("Attempted to paintToBackground but no background canvas is available");
      return;
    }

    if (!this._backgroundPagView) {
      this._backgroundPagView = await this._pagInstance.PAGView.init(
        this._pagComposition,
        this._backgroundCanvas,
        {
          useScale: false,
        }
      );
      if (this._backgroundPagView) {
        // Ensures that the PAGView does NOT clear the canvas before drawing, so it acts like an overlay
        setPAGViewAutoClear(this._backgroundPagView, false);
      }
    } else {
      this._backgroundPagView.setComposition(this._pagComposition);
    }

    const localTimestamp = (this._timestamp - activeSeq.startTime) * MICROSECONDS_IN_SECOND;
    this._setCompositionProgress(localTimestamp);

    await this._backgroundPagView?.prepare();
    await this._backgroundPagView?.flush();

    return this._backgroundCanvas;
  }

  private _initBackgroundCanvas() {
    if (!this._backgroundCanvas) {
      const canvas = createCanvas(this._width, this._height);
      this._backgroundCanvas = canvas;
    }
  }

  /**
   * Generates a new PAG composition by combining the active PAG file with images, video frames, and text inserts.
   * The method will destroy any existing composition before setting the new one.
   *
   * @param videoFrame - The current video frame to be inserted into any source-video slots
   */
  private async _generateComposition(videoFrame: TexImageSource) {
    if (!this._pagInstance) {
      logger.warn("Attempted to generateComposition but no pagInstance is available");
      return;
    }
    if (!this._activeSeq) {
      logger.warn("Attempted to generateComposition but no active sequence is available");
      return;
    }

    const activePagItemConfig = this._activeSeq?.sequenceItems.find(
      (item) => item.id === this._activePagItemId
    );
    const activePagItem = this._activeSeqPagItems.find((item) => item.id === this._activePagItemId);

    if (!activePagItemConfig || !activePagItem) {
      logger.warn(
        "Attempted to generateComposition but not enough information about active pag item is available"
      );
      return;
    }

    const pagComposition = this._pagInstance.PAGComposition.make(this._width, this._height);

    // step 1: set pag file dimensions to match composition
    const layer = activePagItem.pagFile.copyOriginal();
    stretchLayerToFitComposition(this._pagInstance, layer, pagComposition, "cover");

    // step 2: fill pag file slots with images and video frame
    for (const slot of activePagItemConfig.slots) {
      invariant(this._pagInstance, "pagInstance must exist");

      if (slot.type === "image" || slot.type === "source-video") {
        let replacement: PAGImage;

        if (slot.type === "image") {
          const imageElement = await htmlImageElementFromBlob(slot.asset.blob);
          replacement = this._pagInstance.PAGImage.fromSource(imageElement);
        } else {
          try {
            replacement = this._pagInstance.PAGImage.fromSource(videoFrame);
          } catch (error) {
            // For export, need to convert the videoFrame into a HTMLImageElement
            const videoFrameAsHtmlImageElement = await texImageSourceToHtmlImage(videoFrame);
            replacement = this._pagInstance.PAGImage.fromSource(videoFrameAsHtmlImageElement);
          }
        }

        slot.layerIndices.forEach((layerIndex) => {
          invariant(this._pagInstance, "pagInstance must exist");
          layer.replaceImage(layerIndex, replacement);
          stretchImageToFitLayer(this._pagInstance, replacement, layer, "cover");
        });
      } else if (slot.type === "text") {
        slot.layerIndices.forEach((layerIndex) => {
          const textDocument = layer.getTextData(layerIndex);
          textDocument.text = slot.text;
          const { text: scaledText, fontSize } = getNewlinedTextAndScaledFontSize(textDocument);
          textDocument.text = scaledText;
          textDocument.fontSize = fontSize;
          layer.replaceText(layerIndex, textDocument);
        });
      }
    }

    // step 3: set start time and duration of file
    layer.setStartTime(0); // local time
    layer.setDuration(activePagItem.pagFile.duration());

    // step 4: add file to composition
    pagComposition.addLayer(layer);

    // step 5: destroy old composition and set new composition
    this._destroyComposition();
    this._pagComposition = pagComposition;
  }

  /**
   * Updates the progress of the current PAG composition based on its position within the sequence.
   *
   * The progress value (0.0 to 1.0) is calculated by determining how far the current timestamp
   * is between the start and end times of the active PAG item. For items with timing mode
   * "loopScaleToFit", the animation will loop based on the ratio between the sequence duration
   * and the PAG file's intrinsic duration. Otherwise, the animation will play through once linearly based on
   * the amount of time alloted to it.
   *
   * @param timestamp - Microsecond timestamp relative to the start of the active sequence
   */
  private _setCompositionProgress(timestamp: number) {
    invariant(this._pagComposition, "pagComposition must exist to set progress");

    const activePagItemConfig = this._activeSeq?.sequenceItems.find(
      (item) => item.id === this._activePagItemId
    );

    if (!activePagItemConfig) {
      return 0;
    }

    const {
      pagFile,
      startTime: pagItemStartTime,
      endTime: pagItemEndTime,
    } = this._activeSeqPagItems.find((item) => item.id === this._activePagItemId)!;

    const localTimestamp = timestamp - pagItemStartTime;
    const duration = pagItemEndTime - pagItemStartTime;
    const progress = localTimestamp / duration;

    if (activePagItemConfig.timing === "loopScaleToFit") {
      const numLoops = Math.round(duration / pagFile.duration());
      const loopProgress = (duration * numLoops) % 1;
      this._pagComposition.setProgress(loopProgress);
      return;
    }

    this._pagComposition.setProgress(progress);
  }

  /**
   * Updates the currently active sequence based on the current timestamp.
   */
  private async _updateActiveSequence() {
    const nextSeq =
      this._pagSequences.find(
        (seq) => seq.startTime <= this._timestamp && seq.endTime > this._timestamp
      ) ?? null;
    if (nextSeq !== this._activeSeq) {
      this._activeSeq = nextSeq;
      await this._updateActivePagItems();
    }
  }

  /**
   * Loads and updates the PAG animation files for the currently active sequence.
   * Handles timing calculations for both fixed duration ("playOnceIntrinsic") and
   * flexible duration animations within the sequence.
   *
   * The function:
   * 1. Loads all PAG files for the active sequence using _onLoadAnimation
   * 2. Calculates total fixed duration from "playOnceIntrinsic" animations
   * 3. Distributes remaining time evenly among non-fixed duration animations
   * 4. Sets start/end times for each animation in sequence order
   *
   * If no sequence is active, clears the existing PAG items.
   */
  private async _updateActivePagItems() {
    const activeSeq = this._activeSeq;
    if (!activeSeq) {
      this._activeSeqPagItems = [];
      return;
    }
    // Loads the PAG files for the active sequence
    const activeSeqPagFiles = (
      await Promise.all(
        activeSeq?.sequenceItems.map(async (item) => {
          const pagFile = await this._onLoadAnimation?.(item.id);
          return pagFile ? { id: item.id, pagFile } : null;
        }) ?? []
      )
    ).filter((file): file is { id: string; pagFile: PAGFile } => file !== null);

    // Calculate the timing of each PAG item in the active sequence
    const sequenceTimespan = (activeSeq.endTime - activeSeq.startTime) * MICROSECONDS_IN_SECOND;

    const { totalFixedDuration, fixedDurationPagIndices } = activeSeq.sequenceItems.reduce(
      (acc, item, index) => {
        if (item.timing === "playOnceIntrinsic") {
          const pagFileForItem = activeSeqPagFiles.find((file) => file.id === item.id);
          return {
            totalFixedDuration: acc.totalFixedDuration + (pagFileForItem?.pagFile.duration() ?? 0),
            fixedDurationPagIndices: [...acc.fixedDurationPagIndices, index],
          };
        }
        return acc;
      },
      {
        totalFixedDuration: 0,
        fixedDurationPagIndices: [] as number[],
      }
    );

    const remainingDuration = sequenceTimespan - totalFixedDuration;
    const remainingDurationPerPag =
      remainingDuration / (activeSeq.sequenceItems.length - fixedDurationPagIndices.length);

    let localStart = 0;
    this._activeSeqPagItems = activeSeqPagFiles.map(({ pagFile, id }, index) => {
      if (fixedDurationPagIndices.includes(index)) {
        const startTime = localStart;
        const fixedDuration = pagFile.duration();
        localStart += fixedDuration;
        return {
          pagFile,
          startTime,
          endTime: startTime + fixedDuration,
          id,
        };
      }
      const startTime = localStart;
      localStart += remainingDurationPerPag;
      return {
        pagFile,
        startTime,
        endTime: startTime + remainingDurationPerPag,
        id,
      };
    });
  }

  /**
   * Updates the currently active PAG item ID based on the current timestamp within the active sequence.
   */
  private _updateActivePagItemId() {
    const activeSequence = this._activeSeq;
    if (!activeSequence) {
      logger.warn("Attempted to find active PAG item but no PAG sequence is active");
      return;
    }

    const localTimestamp = (this._timestamp - activeSequence.startTime) * MICROSECONDS_IN_SECOND;

    this._activePagItemId =
      this._activeSeqPagItems.find(
        (item) => item.startTime <= localTimestamp && item.endTime > localTimestamp
      )?.id ?? null;
  }

  /**
   * Destroys the current PAG composition and its layers.
   */
  private _destroyComposition() {
    const numLayers = this._pagComposition?.numChildren();
    if (numLayers) {
      for (let i = 0; i < numLayers; i++) {
        const layer = this._pagComposition?.getLayerAt(i);
        layer?.destroy();
      }
    }
    this._pagComposition?.destroy();
    this._pagComposition = null;
  }

  /**
   * Cleans up and releases all resources associated with the PagSequencePainter.
   */
  public destroy() {
    this._pagSequences = [];
    this._activeSeq = null;
    this._activeSeqPagItems = [];
    this._activePagItemId = null;
    this._destroyComposition();
    this._mainPagView?.destroy();
    this._mainPagView = undefined;
    this._backgroundPagView?.destroy();
    this._backgroundPagView = undefined;
    this._backgroundCanvas = undefined;
  }
}
