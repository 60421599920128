import { Popover, PopoverPortal } from "@radix-ui/react-popover";
import { Command } from "cmdk";
import {
  forwardRef,
  KeyboardEventHandler,
  MouseEventHandler,
  PointerEventHandler,
  useCallback,
  useMemo,
  useState,
} from "react";

import NoResults from "~/components/icons/NoResults";
import { SearchBox } from "~/components/SearchBox";
import { Text } from "~/components/Text";
import { useKeyboardShortcutBlocker } from "~/hooks/useKeyboardShortcutBlocker";
import { stringScore } from "~/utils/stringScore";

import {
  ComboBoxContent,
  ComboBoxDropdownIcon,
  ComboBoxEmptyText,
  ComboBoxScrollBar,
  ComboBoxScrollRoot,
  ComboBoxScrollThumb,
  ComboBoxScrollViewport,
  ComboBoxSearchContainer,
  ComboBoxTrigger,
  ComboBoxTriggerLabel,
} from "./ComboBox.styles";
import { ComboBoxGroup, ComboBoxOption, ComboBoxProps } from "./ComboBox.types";
import { ComboBoxGroupLabel } from "./ComboBoxGroupLabel";
import { ComboBoxItem } from "./ComboBoxItem";

const OPEN_KEYS = [" ", "Enter", "ArrowUp", "ArrowDown"];

export const ComboBox = forwardRef<HTMLDivElement, ComboBoxProps>(function ComboBox(
  {
    value,
    options,
    disabled,
    onValueChange,
    customValue,
    placeholder,
    searchPlaceholder,
    noMatchesText,
    iconBg,
    size,
    side,
    content,
    css,
    contentCss,
    triggerIcon,
  },
  ref
) {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [open, setOpen] = useState(false);
  const groupedOptions: ComboBoxGroup[] = useMemo(() => {
    const ungroupedOptions = options.filter((item): item is ComboBoxOption => !("options" in item));
    const groups = options.filter((item): item is ComboBoxGroup => "options" in item);
    return [
      ...(ungroupedOptions.length > 0 ? [{ label: "", options: ungroupedOptions }] : []),
      ...groups,
    ];
  }, [options]);
  const selectedLabel = useMemo(() => {
    if (customValue) {
      return customValue(value);
    }
    const selectedOption = groupedOptions.find((group) =>
      group.options.find((item) => item.value === value)
    );
    return selectedOption?.label;
  }, [value, groupedOptions, customValue]);

  /**
   * Replicates Radix's Select behavior preventing label clicks from opening the dropdown
   * @see {https://github.com/radix-ui/primitives/blob/main/packages/react/select/src/Select.tsx}
   **/
  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.preventDefault();
      if (!open) {
        event.currentTarget.focus();
      }
    },
    [open]
  );

  /**
   * Replicates Radix's Select behavior of opening the dropdown on pointer down
   * @see {https://github.com/radix-ui/primitives/blob/main/packages/react/select/src/Select.tsx}
   **/
  const handlePointerDown: PointerEventHandler<HTMLButtonElement> = useCallback((event) => {
    const target = event.target as HTMLElement;
    if (target.hasPointerCapture(event.pointerId)) {
      target.releasePointerCapture(event.pointerId);
    }
    if (event.button === 0 && !event.ctrlKey) {
      setOpen((prevOpen) => !prevOpen);
      event.preventDefault();
    }
  }, []);

  /**
   * Restores ability to open the dropdown with the keyboard
   * @see {https://github.com/radix-ui/primitives/blob/main/packages/react/select/src/Select.tsx}
   **/
  const handleKeyDown: KeyboardEventHandler<HTMLButtonElement> = useCallback((event) => {
    if (OPEN_KEYS.includes(event.key)) {
      setOpen(true);
      event.preventDefault();
    }
  }, []);

  useKeyboardShortcutBlocker(open && !disabled);

  return (
    <Popover open={open && !disabled} onOpenChange={setOpen}>
      <ComboBoxTrigger
        role="combobox"
        disabled={disabled}
        onClick={handleClick}
        onPointerDown={handlePointerDown}
        onKeyDown={handleKeyDown}
        css={css}
      >
        {triggerIcon}
        <ComboBoxTriggerLabel>
          <Text variant="body-1" color={disabled ? "grey-600" : "grey-200"}>
            {value ? selectedLabel : placeholder ?? "Select option..."}
          </Text>
        </ComboBoxTriggerLabel>
        <ComboBoxDropdownIcon />
      </ComboBoxTrigger>
      <PopoverPortal>
        <ComboBoxContent
          ref={ref}
          onCloseAutoFocus={() => setSearchQuery("")}
          align={content?.align}
          style={{ width: content?.width }}
          css={contentCss}
          side={side || "bottom"}
        >
          <ComboBoxSearchContainer>
            <SearchBox placeholder={searchPlaceholder ?? ""} onSearch={setSearchQuery} />
          </ComboBoxSearchContainer>

          <ComboBoxScrollRoot type="hover">
            <ComboBoxScrollViewport>
              <Command shouldFilter={false}>
                <Command.List>
                  <ComboBoxEmptyText>
                    {noMatchesText ?? (
                      <>
                        <NoResults />
                        <Text variant="body-2" color="grey-500">
                          No results found, please try again
                        </Text>
                      </>
                    )}
                  </ComboBoxEmptyText>
                  {groupedOptions
                    .map((group) => {
                      if (!searchQuery) {
                        return group;
                      }

                      const searchedOptions = group.options
                        .map((option) => {
                          if (Object.hasOwn(option, "onClick")) {
                            return {
                              option,
                              searchScore: 0,
                            };
                          }

                          return {
                            option,
                            searchScore:
                              option.getSearchScore?.(searchQuery) ??
                              stringScore(option.value, searchQuery) +
                                stringScore(option.searchValue ?? "", searchQuery),
                          };
                        })
                        .filter((opt) => opt.searchScore > 0.3)
                        .sort((a, b) => (b.searchScore > a.searchScore ? 1 : -1))
                        .map((opt) => opt.option);

                      return {
                        label: group.label,
                        options: searchedOptions,
                      };
                    })
                    .filter((g) => g.options?.length)
                    .map((group) => (
                      <Command.Group
                        key={group.label}
                        role={group.label.length > 0 ? "group" : undefined}
                        heading={<ComboBoxGroupLabel label={group.label} />}
                      >
                        {group.options.map((option) => (
                          <ComboBoxItem
                            key={option.value}
                            size={size}
                            option={option}
                            isSelected={option.value === value}
                            iconBg={iconBg}
                            onClickInnerAction={option.onClickInnerAction}
                            onSelect={(value) => {
                              if (Object.hasOwn(option, "onClick")) {
                                option.onClick?.();
                                return;
                              }
                              onValueChange?.(value);
                              setOpen(false);
                            }}
                          />
                        ))}
                      </Command.Group>
                    ))}
                </Command.List>
              </Command>
            </ComboBoxScrollViewport>
            <ComboBoxScrollBar>
              <ComboBoxScrollThumb />
            </ComboBoxScrollBar>
          </ComboBoxScrollRoot>
        </ComboBoxContent>
      </PopoverPortal>
    </Popover>
  );
});
