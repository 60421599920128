import { StoreNames } from "idb";
import { z, ZodError } from "zod";

import { CaptionsData } from "../factory";
import { getDB } from "../store";

export class WriteDataParseError extends Error {
  constructor(public readonly error: ZodError) {
    super(error.message);
  }
}

export class ReadDataParseError extends Error {
  constructor(public readonly error: ZodError) {
    super(error.message);
  }
}

export interface BaseRepoParams<
  Name extends StoreNames<CaptionsData>,
  Schema extends z.ZodType<CaptionsData[Name]["value"]>,
> {
  name: Name;
  schema: Schema;
}

export function createRepo<
  Name extends StoreNames<CaptionsData>,
  Schema extends z.ZodType<CaptionsData[Name]["value"]>,
>(params: BaseRepoParams<Name, Schema>) {
  function parseWriteData(data: unknown) {
    const parseResult = params.schema.safeParse(data);
    if (!parseResult.success) {
      throw new WriteDataParseError(parseResult.error);
    }
    return parseResult.data;
  }

  function parseReadData(result: unknown) {
    const parseResult = params.schema.safeParse(result);
    if (!parseResult.success) {
      throw new ReadDataParseError(parseResult.error);
    }
    return parseResult.data;
  }

  return {
    async add(data: Omit<z.infer<Schema>, "id">, id: string = window.crypto.randomUUID()) {
      const db = await getDB();

      await db.add(params.name, parseWriteData({ ...data, id }));

      return id;
    },

    async updateById(id: string, data: Partial<Omit<z.infer<Schema>, "id">>) {
      const db = await getDB();

      const tx = db.transaction([params.name], "readwrite");
      const store = tx.objectStore(params.name);

      const existingItem = await store.get(id);
      if (!existingItem) {
        tx.abort();
        throw new Error(`${params.name} not found in ${params.name} Repo`);
      }

      const existingData = parseReadData(existingItem);

      await store.put(parseWriteData({ ...existingData, ...data }));

      await tx.done;
    },

    async deleteById(id: string) {
      const db = await getDB();
      await db.delete(params.name, id);
    },

    async findById(id: string) {
      const db = await getDB();
      const result = await db.get(params.name, id);

      if (!result) {
        return undefined;
      }

      return parseReadData(result);
    },

    async findAll() {
      const db = await getDB();
      const results = await db.getAll(params.name);

      return results.map(parseReadData);
    },
  };
}
