import { Text } from "~/components";
import { keyframes, styled } from "~/theme";

export const CaptionStylePanelContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 24,
  height: "100%",
});

export const CaptionStylePanelContainerHeader = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 8,
  paddingBottom: 24,
});

export const CaptionStylePanelFormSectionHeader = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: 4,
});

export const CaptionStyleFormSectionContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});

export const CaptionStylePanelContainerTitle = styled(Text, {
  defaultVariants: {
    variant: "body-1-bold",
    color: "grey-200",
  },
});

export const CaptionStylePanelContainerSubtitle = styled(Text, {
  defaultVariants: {
    variant: "body-1",
    color: "grey-500",
  },
});

export const CaptionStylePanelFormLabel = styled(Text, {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  defaultVariants: {
    color: "grey-400",
    variant: "body-3",
  },
});

export const CaptionStylePanelFormBoldLabel = styled(Text, {
  defaultVariants: {
    variant: "body-2-bold",
    color: "grey-200",
  },
});

export const InputWithLabelContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  variants: {
    sideAligned: {
      true: {
        height: "44px",
        flexDirection: "row",
        alignItems: "center",
        "& >:last-child": {
          width: "268px",
          justifyContent: "right",
          flexWrap: "nowrap",
        },
      },
    },
    horizontal: {
      true: {
        height: "44px",
        flexDirection: "row",
        alignItems: "center",
        "& >:first-child": {
          flex: "1 1 auto",
        },
      },
    },
  },
});

export const StrokeAndShadowInputsContainer = styled("div", {
  display: "flex",
  gap: 16,
  width: "100%",
});

export const CaptionsStylePanelHR = styled("div", {
  borderBottom: "1px solid $grey-800",
});

export const UploadFontButton = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "12px",
});

export const InvisibleFontFileInput = styled("input", {
  visibility: "hidden",
  position: "fixed",
  top: 0,
  right: 0,
});
