import { Text } from "~/components/Text";

import BreakCheckmark from "../icons/BreakCheckmark";

import {
  CheckboxButtonContainer,
  CheckboxButtonContent,
  CheckboxButtonIcon,
  CheckboxButtonCheckmark,
} from "./CheckboxButton.styles";
import type { CheckboxButtonProps } from "./CheckboxButton.types";

export function CheckboxButton({
  label,
  text,
  icon,
  checked,
  disabled,
  onChange,
  ...props
}: CheckboxButtonProps) {
  return (
    <CheckboxButtonContainer
      disabled={Boolean(disabled)}
      checked={checked}
      {...props}
      onClick={() => onChange?.(!checked)}
    >
      {icon && <CheckboxButtonIcon>{icon}</CheckboxButtonIcon>}
      <CheckboxButtonContent>
        {label && (
          <Text variant="body-2-bold" color={disabled ? "grey-500" : "grey-200"}>
            {label}
          </Text>
        )}
        {text && (
          <Text variant="body-2" color="grey-500">
            {text}
          </Text>
        )}
      </CheckboxButtonContent>
      {!disabled && (
        <CheckboxButtonCheckmark>
          <BreakCheckmark />
        </CheckboxButtonCheckmark>
      )}
    </CheckboxButtonContainer>
  );
}
