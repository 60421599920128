import { PAGFile } from "libpag/types/web/src/pag-file";

import { getAiEditAssetById } from "~/modules/command-list/services/AIEditStyles/network/service";

import { getPAG } from "../../utils/pagWrapper";

import { ChalkAnimations } from "./ChalkAnimations";
import { EmojiAnimations } from "./EmojiAnimations.config";
import { FrameAnimations } from "./FrameAnimations.config";
import { NewspaperAnimations } from "./NewspaperAnimations";
import { ScrapbookAnimations } from "./ScrapbookAnimations";
import { SimpleThbrFrames } from "./SimpleThbrFrames";
import { TitleCardAnimations } from "./TitleCardAnimations";
import { TransitionAnimationUrls } from "./TransitionAnimations.config";
import { UnfurlAnimations } from "./UnfurlAnimations";

const localAndCdnAnimations: { [key: string]: URL } = {
  ...ChalkAnimations,
  ...ScrapbookAnimations,
  ...EmojiAnimations,
  ...TransitionAnimationUrls,
  ...FrameAnimations,
  ...NewspaperAnimations,
  ...TitleCardAnimations,
  ...UnfurlAnimations,
  ...SimpleThbrFrames,
};

const loadedAnimationsCache: Map<string, PAGFile> = new Map();

export async function loadAnimation(animationId: string | null): Promise<PAGFile | null> {
  if (!animationId) {
    return null;
  }
  if (!loadedAnimationsCache.has(animationId)) {
    try {
      const pag = await getPAG();

      const animationUrl = await (async () => {
        if (localAndCdnAnimations[animationId]) {
          return localAndCdnAnimations[animationId].href;
        }

        const res = await getAiEditAssetById(animationId);
        return res.url;
      })();

      const animationData = await fetch(animationUrl).then((response) => response.arrayBuffer());
      loadedAnimationsCache.set(animationId, pag.PAGFile.loadFromBuffer(animationData));
    } catch (error) {
      console.warn(error);
      return null;
    }
  }

  return loadedAnimationsCache.get(animationId)!;
}

export function isAnimationLoaded(animationId: string): boolean {
  return loadedAnimationsCache.has(animationId);
}
