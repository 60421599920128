import type { PropsWithChildren, Ref } from "react";
import { createContext, useContext } from "react";
import { createPortal } from "react-dom";

import { TopBarContainer, TopBarInner } from "./TopBar.styles";

export function TopBar({ topBarRef }: { topBarRef: Ref<HTMLDivElement> }) {
  return (
    <TopBarContainer>
      <TopBarInner ref={topBarRef} />
    </TopBarContainer>
  );
}

/** Passes `children` through a portal to the page layout's `TopBar` container */
export function TopBarContents({ children }: PropsWithChildren) {
  const { topBarElem } = useTopBar();
  return topBarElem && createPortal(children, topBarElem);
}

export const TopBarContext = createContext<{ topBarElem?: HTMLDivElement }>({});

export function useTopBar() {
  return useContext(TopBarContext);
}
