import { useState, useEffect, useCallback } from "react";

import {
  CUSTOM_FONTS_LOADED,
  CUSTOM_FONT_DELETE,
  CUSTOM_FONT_READING_ERROR,
  CUSTOM_FONT_UPLOAD,
  CUSTOM_FONT_UPLOAD_CANCEL,
  CUSTOM_FONT_UPLOAD_ERROR,
} from "~/constants/mixpanel.constants";
import { useAuthState } from "~/context/CaptionsAuthContext";
import { UserFont } from "~/database/database.types";
import { UserFontsRepository } from "~/database/repo/UserFontsRepository";
import { removeFileExtension } from "~/utils/removeFileExtension";

import { useMixpanel } from "./useMixpanel";
export type FontUploadingStatus = "idle" | "progress" | "error" | "finished" | "canceled";

export type FontUploadingState =
  | { status: Extract<FontUploadingStatus, "idle" | "canceled" | "progress" | "finished"> }
  | {
      status: Extract<FontUploadingStatus, "error">;
      errorMessage: string;
    };

export interface UserFontWithFontFamily extends UserFont {
  fontFamily: string;
}

export function useUserFonts() {
  const [uploadingFontState, setUploadingFontState] = useState<FontUploadingState>({
    status: "idle",
  });
  const [userFonts, setUserFonts] = useState<Array<UserFontWithFontFamily>>([]);
  const mixpanel = useMixpanel();
  const { user } = useAuthState();

  const handleCancelUploadingFont = () => {
    setUploadingFontState({ status: "canceled" });
    mixpanel.track(CUSTOM_FONT_UPLOAD_CANCEL);
  };

  const addFontBlobToDocument = async (blob: Blob, id: string) => {
    try {
      const blobUrl = URL.createObjectURL(blob);
      const fontFace = new FontFace(id, `url(${blobUrl})`);

      document.fonts.add(fontFace);
      await document.fonts.ready;

      // loading checks if font file is valid
      await document.fonts.load(`12px "${id}"`);
    } catch (err) {
      throw new Error(`Failed to add font to document: ${err}`);
    }
  };

  const handleAddUserFont = useCallback(
    async (newUserFontFile: File) => {
      let fontId;
      try {
        setUploadingFontState({ status: "progress" });
        const fontFileBlob = new Blob([newUserFontFile], { type: newUserFontFile.type });
        const nameWithoutExtension = removeFileExtension(newUserFontFile.name);
        const font = await UserFontsRepository.addUserFont(user?.userId, {
          blob: fontFileBlob,
          name: nameWithoutExtension,
        });
        if (!font) {
          return;
        }
        fontId = font.id;
        await addFontBlobToDocument(fontFileBlob, fontId);

        const newFontWithFontFamily: UserFontWithFontFamily = {
          ...font,
          fontFamily: `"${font.id}"`,
        };
        setUserFonts((prev) => [...prev, newFontWithFontFamily]);
        setUploadingFontState({ status: "finished" });
        mixpanel.track(CUSTOM_FONT_UPLOAD, {
          name: newUserFontFile.name,
          size: newUserFontFile.size,
        });
        return newFontWithFontFamily;
      } catch (error) {
        if (fontId) {
          UserFontsRepository.removeUserFont(user?.userId, fontId);
        }
        if (uploadingFontState.status === "canceled") {
          return;
        }
        setUploadingFontState({
          status: "error",
          errorMessage: error instanceof Error ? error.message : `${error}`,
        });
        mixpanel.track(CUSTOM_FONT_UPLOAD_ERROR, {
          name: newUserFontFile.name,
          size: newUserFontFile.size,
          errorMessage: error instanceof Error ? error.message : `${error}`,
        });
      }
    },
    [user?.userId]
  );

  const handleRemoveUserFont = useCallback(
    async (userFontId: string) => {
      const font = userFonts.find((font) => font.id === userFontId);
      if (!font) {
        return;
      }

      await UserFontsRepository.removeUserFont(user?.userId, userFontId);
      setUserFonts((prev) => prev.filter((font) => font.id !== userFontId));
      mixpanel.track(CUSTOM_FONT_DELETE, {
        name: font.userFontData.name,
        size: font.userFontData.blob.size,
      });
    },
    [user?.userId]
  );

  useEffect(() => {
    const getFonts = async () => {
      try {
        let totalFonts = 0;
        let totalFontSize = 0;
        const fonts = await UserFontsRepository.getAllUserFonts(user?.userId);
        for (const font of fonts) {
          await addFontBlobToDocument(font.userFontData.blob, font.id);

          setUserFonts((prev) => [...prev, { ...font, fontFamily: `"${font.id}"` }]);
          totalFonts += 1;
          totalFontSize += font.userFontData.blob.size;
        }
        mixpanel.track(CUSTOM_FONTS_LOADED, {
          totalFonts,
          totalFontSize,
        });
      } catch (err) {
        mixpanel.track(CUSTOM_FONT_READING_ERROR, {
          errorMessage: err instanceof Error ? err.message : `${err}`,
        });
      }
    };

    getFonts();
  }, [user?.userId]);

  return {
    handleAddUserFont,
    handleCancelUploadingFont,
    handleRemoveUserFont,
    uploadingFontState,
    userFonts,
  };
}
