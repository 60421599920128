import { useCallback, useEffect, useState } from "react";

import { useBackendServicesClient } from "~/context/BackendServicesContext";
import { Project } from "~/database/database.types";
import { useLatestRef } from "~/hooks/helpers";
import {
  TranscriptStage,
  useTranscriptGenerator,
} from "~/modules/project/hooks/useTranscriptGenerator";
import {
  getAIShortsJobStatus,
  startAIShortsJob,
} from "~/modules/project/services/AIShorts/AIShortsService";
import { VideoFileMetadata } from "~/utils/getVideoFileMetadata";

import { ShortsLength } from "./AIShortsSettings.types";

const POLLING_INTERVAL = 1000;
interface ProjectCreationParams {
  fileId: string; // uploaded file id
  transcriptionLanguage: string;
  shortsLength: ShortsLength;
  videoFileMetadata: VideoFileMetadata;
}

function getProjectFromVideoFile(
  projectId: string,
  fileId: string,
  videoFileMetadata: VideoFileMetadata
): Project {
  return {
    id: projectId,
    title: videoFileMetadata.name || `Untitled - ${new Date().toLocaleDateString()}`,
    createdAt: new Date(),
    lastGeneratedFileId: "",
    sourceFileId: fileId,
    updatedAt: new Date(),
    sourceVideoMetadata: {
      duration: videoFileMetadata.duration ?? 0,
      fps: 0, // todo figure out how to get fps
      height: videoFileMetadata.height ?? 0,
      size: videoFileMetadata.size,
      width: videoFileMetadata.width ?? 0,
    },
  };
}

interface ShortData {
  startTime: number;
  endTime: number;
  title: string;
}

// todo we should add ai shorts schema instead of using any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertRawShortsData = (shortsRes: any) => {
  return {
    startTime: shortsRes.startTime,
    endTime: shortsRes.endTime,
    title: shortsRes.title,
  };
};

export type ShortsDataReqsStage =
  | "start"
  | "transcription"
  | "audio-extraction"
  | "ai-shorts"
  | "finished"
  | "error";

export interface UseShortsDataReqsProps {
  projectId: string;
  onStartShortsPooling?: (shortsOperationId: string) => void;
}

export function useShortsDataReqs({ projectId, onStartShortsPooling }: UseShortsDataReqsProps) {
  const {
    transcript,
    progress: transcriptionProgress,
    generateTranscript,
    transcriptionJobId,
    stage: transcriptionStage,
    errorMessage,
  } = useTranscriptGenerator("", "", true);
  const [projectReady, setProjectReady] = useState<boolean>(false);
  const [project, setProject] = useState<Project | null>(null);
  const [shortsLength, setShortsLength] = useState<ShortsLength>("auto");
  const [shortsProgress, setShortsProgress] = useState(0);
  const [stage, setStage] = useState<ShortsDataReqsStage>("start");
  const [error, setError] = useState<string | undefined>();

  const [subProjectTimespans, setSubProjectTimespans] = useState<ShortData[]>([]);

  const client = useBackendServicesClient();

  const latestOnStartShortsPooling = useLatestRef(onStartShortsPooling);

  const handleError = useCallback((e: Error | string) => {
    console.error(e);
    setError(e.toString());
    setStage("error");
  }, []);

  // temp solution to setting the audio extraction stage
  useEffect(() => {
    if (transcriptionStage === TranscriptStage.extractingAudio) {
      setStage("audio-extraction");
    }
    if (transcriptionStage === TranscriptStage.generatingTranscript) {
      setStage("transcription");
    }
    if (transcriptionStage === TranscriptStage.error) {
      setError(errorMessage ?? undefined);
      setStage("error");
    }
  }, [transcriptionStage]);

  const updateAIShortsJobStatus = useCallback((shortsJobId: string) => {
    getAIShortsJobStatus(client, shortsJobId)
      .then((job) => {
        if (job.state == "PROCESSING") {
          setShortsProgress(Number(job.progress));
          setTimeout(() => updateAIShortsJobStatus(shortsJobId), POLLING_INTERVAL);
        } else if (job.state == "COMPLETE") {
          setSubProjectTimespans(
            job.result
              .map(convertRawShortsData)
              .sort((a: ShortData, b: ShortData) => (a.startTime > b.startTime ? 1 : -1))
          );

          // todo set finished later than this ?
          setProjectReady(true);
          setStage("finished");
        } else if (job.state === "FAILED") {
          handleError("shorts API failure");
        } else {
          setTimeout(() => updateAIShortsJobStatus(shortsJobId), POLLING_INTERVAL);
        }
      })
      .catch(handleError);
  }, []);

  useEffect(() => {
    if (!transcript) {
      return;
    }

    startAIShortsJob(client, transcriptionJobId ?? "", shortsLength)
      .then((shortsJob) => {
        latestOnStartShortsPooling.current?.(shortsJob.jobId);
        setTimeout(() => updateAIShortsJobStatus(shortsJob.jobId), POLLING_INTERVAL);
      })
      .catch(handleError);
    setStage("ai-shorts");
  }, [transcript, latestOnStartShortsPooling]);

  const startShortsDataReqs = (creationParams: ProjectCreationParams) => {
    // init project object
    const projectLocal = getProjectFromVideoFile(
      projectId,
      creationParams.fileId,
      creationParams.videoFileMetadata
    );

    setProject(projectLocal);
    setShortsLength(creationParams.shortsLength);

    // start getting transcript from file
    generateTranscript(
      { languageCode: creationParams.transcriptionLanguage, isShortsJob: true },
      creationParams.fileId
    );
  };

  return {
    transcript,
    progress: (transcriptionProgress + shortsProgress) / 2,
    stage,
    project,
    startShortsDataReqs,
    projectReady,
    subProjectTimespans,
    error,
  };
}
