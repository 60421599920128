import {
  EXPORT_CLICK_PROJECT_TYPES,
  REFRAME_FILL_FIT_CLICK_ASPECT_TYPE,
} from "~/constants/mixpanel.constants";
import { ProjectFolderType } from "~/database/database.types";

import { ProjectType } from "./ProductEvents";

export const SUBSCRIPTION_STEP_SOURCE = {
  projectExport: "project_export",
  ugcAdExport: "ugc_ad_export",
  profile: "profile",
  api: "api",
};

export function getSubscriptionSourceForAnalytics(checkoutUrl: string) {
  if (checkoutUrl.includes("api")) {
    return SUBSCRIPTION_STEP_SOURCE.api;
  }
  if (checkoutUrl.includes("project")) {
    return SUBSCRIPTION_STEP_SOURCE.projectExport;
  }
  if (checkoutUrl.includes("shorts")) {
    return SUBSCRIPTION_STEP_SOURCE.ugcAdExport;
  }
  if (checkoutUrl.includes("profile")) {
    return SUBSCRIPTION_STEP_SOURCE.profile;
  }
  return null;
}

export function getProjectTypeForAnalytics(type?: ProjectFolderType | ProjectType | null) {
  switch (type) {
    case "ai-ads":
      return EXPORT_CLICK_PROJECT_TYPES.ugcAd;
    case "ai-shorts":
      return EXPORT_CLICK_PROJECT_TYPES.aiShort;
    case "ai-avatar":
      return EXPORT_CLICK_PROJECT_TYPES.aiCreator;
    case "ai-edit":
      return EXPORT_CLICK_PROJECT_TYPES.aiEdit;
    default:
      return EXPORT_CLICK_PROJECT_TYPES.import;
  }
}

export function getAspectTypeForAnalytics(aspect: number) {
  if (aspect > 1) {
    return REFRAME_FILL_FIT_CLICK_ASPECT_TYPE.horizontal;
  }
  if (aspect < 1) {
    return REFRAME_FILL_FIT_CLICK_ASPECT_TYPE.vertical;
  }
  return REFRAME_FILL_FIT_CLICK_ASPECT_TYPE.square;
}
