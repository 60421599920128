import { createAssetMap } from "../../../utils/assetUtils";

export const BACKGROUND_SCRAPBOOK_ASSETS = createAssetMap({
  folder: "ai-edit-assets/scrapbook_assets/backgrounds",
  assets: {
    bg_crumpledgrid: {
      type: "tar-frames",
      variants: ["blue", "green", "pink", "purple", "yellow"],
    },
    bg_crumpledpaper: {
      type: "tar-frames",
      variants: ["blue", "green", "pink", "purple", "yellow"],
    },
    bg_explosionmarker: {
      type: "tar-frames",
      variants: ["black", "blue", "green", "pink", "purple", "yellow"],
    },
    bg_loopiemarker: {
      type: "tar-frames",
      variants: ["blue", "green", "pink", "purple", "yellow"],
    },
    bg_plainmarker: {
      type: "tar-frames",
      variants: ["black", "blue", "green", "pink", "purple", "yellow"],
    },
    bg_scribblemarker: {
      type: "tar-frames",
      variants: ["blue", "green", "pink", "purple", "yellow"],
    },
  },
});

export const BACKGROUND_IMPACT_ASSETS = createAssetMap({
  folder: "ai-edit-assets/impact_assets",
  assets: {
    background_grid: {
      type: "tar-frames",
      variants: ["a", "b", "c"],
      resolution: "1080p",
      drawAs: "imageBitmap",
    },
    background_topography: {
      type: "tar-frames",
      variants: ["a", "b", "c"],
      resolution: "1080p",
    },
    red_curtain: {
      type: "tar-frames",
      resolution: "1080p",
      drawAs: "imageBitmap",
    },
    green_curtain: {
      type: "tar-frames",
      resolution: "1080p",
      drawAs: "imageBitmap",
    },
    blue_curtain: {
      type: "tar-frames",
      resolution: "1080p",
      drawAs: "imageBitmap",
    },
    gold_curtain: {
      type: "tar-frames",
      resolution: "1080p",
      drawAs: "imageBitmap",
    },
  },
});

export const BACKGROUND_PAPER_ASSETS = createAssetMap({
  folder: "ai-edit-assets/paper_assets",
  assets: {
    background_paper: {
      type: "tar-frames",
      resolution: "1080p",
      variants: ["red", "yellow", "blue", "black", "white"],
      drawAs: "canvas",
    },
    background_collage: {
      type: "image",
      resolution: "1080p",
      extension: "png",
      variants: ["1", "2", "3", "4", "5"],
    },
  },
});
