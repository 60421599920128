import { t } from "i18next";
import {
  BaseSyntheticEvent,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useForm } from "react-hook-form";

import { CheckboxButton } from "~/components/CheckboxButton/CheckboxButton";
import { CheckboxButtonList } from "~/components/CheckboxButton/CheckboxButton.styles";
import FileError from "~/components/icons/FileError";
import TranslateIcon from "~/components/icons/Translate";
import TranslateAudio from "~/components/icons/TranslateAudio";
import TranslationHand from "~/components/icons/TranslationHand";
import * as SideForm from "~/components/SideForm";
import { Text } from "~/components/Text";
import { stringScore } from "~/utils/stringScore";

import { AvailableLanguageOption } from "../../services/AvailableTranscriptLanguages";
import { getLocalizedLanguageName } from "../../utils/getLocalizedLanguageName";

import { LanguageSelectorEmptyCard } from "./LanguageSelector.styles";
import type { LanguageSelectorProps, LanguageSelectorResult } from "./LanguageSelector.types";
import { SpokenLanguageField } from "./SpokenLanguageField";
import { TranslationLanguageField } from "./TranslationLanguageField";

export function LanguageSelector({
  availableLanguages,
  availableTranslationLanguages,
  availableDubbingLanguages,
  defaultLanguageCode,
  defaultTranslationLanguageCode,
  defaultTranslateAudioEnabled,
  onSelectLanguage,
  disabled,
  fullWidthSubmit,
  enableTranslation,
  children,
}: PropsWithChildren<LanguageSelectorProps>): ReactElement {
  const { control, handleSubmit, watch, setValue } = useForm<LanguageSelectorResult>({
    defaultValues: {
      languageCode: defaultLanguageCode,
      targetLanguageCode: defaultTranslationLanguageCode,
      translateAudioEnabled: defaultTranslateAudioEnabled,
    },
  });

  const currentSelectedSpokenLanguage = watch("languageCode");
  const currentSelectedTranslationLanguage = watch("targetLanguageCode");
  const translateAudioEnabled = watch("translateAudioEnabled");

  // set translation to none if not enabled
  useEffect(() => {
    if (!enableTranslation) {
      setValue("targetLanguageCode", "none");
    }
  }, [enableTranslation]);

  const handleSelectLanguages = useCallback(
    (data: LanguageSelectorResult, event?: BaseSyntheticEvent) => {
      event?.preventDefault();
      onSelectLanguage?.(data);
    },
    [onSelectLanguage]
  );

  const hasTranslation = currentSelectedTranslationLanguage !== "none";
  const hasDubbingSupport =
    hasTranslation &&
    !!availableDubbingLanguages.find((lang) => lang.code === currentSelectedTranslationLanguage);
  const onlyTranslate = !hasDubbingSupport || !translateAudioEnabled;

  /** Label text varies depending on spoken language, translation language, and dubbing on/off. */
  const submitLabel = useMemo(() => {
    // No translation
    if (currentSelectedTranslationLanguage === "none") {
      const spokenLanguageDisplayName = availableLanguages.find(
        (item) => item.code === currentSelectedSpokenLanguage
      )?.display;
      if (!spokenLanguageDisplayName) {
        console.error("Could not find spoken language display name");
        return t("common:continue");
      }

      return t("common:language-selector.proceed-btn", { language: spokenLanguageDisplayName });
    }

    // Dubbing
    const translationLanguageDisplayName = availableLanguages.find(
      (item) => item.code === currentSelectedTranslationLanguage
    )?.display;
    if (!translationLanguageDisplayName) {
      console.error("Could not find translation language display name");
      return t("common:continue");
    }

    if (!hasTranslation) {
      return t("common:language-selector.proceed-btn", {
        language: translationLanguageDisplayName,
      });
    }
    if (onlyTranslate) {
      return t("common:language-selector.translate-btn", {
        language: translationLanguageDisplayName,
      });
    }
    return t("common:language-selector.dubbing-btn", { language: translationLanguageDisplayName });
  }, [
    translateAudioEnabled,
    currentSelectedSpokenLanguage,
    currentSelectedTranslationLanguage,
    availableLanguages,
    onlyTranslate,
  ]);

  return (
    <SideForm.Form
      submitLabel={submitLabel}
      cancelUrl="/projects"
      cancelLabel={t("common:back")}
      submitDisabled={disabled}
      fullWidthSubmit={fullWidthSubmit}
      onSubmit={handleSubmit(handleSelectLanguages)}
      style={fullWidthSubmit ? { height: "unset" } : {}}
    >
      <SpokenLanguageField
        languages={availableLanguages}
        {...{ control, disabled, defaultLanguageCode }}
      />
      <SideForm.HR />
      <TranslationLanguageField
        currentSelectedSpokenLanguage={currentSelectedSpokenLanguage}
        languages={availableTranslationLanguages}
        disabled={disabled || !enableTranslation}
        dubbingLanguages={availableDubbingLanguages}
        {...{ control }}
      />
      {hasTranslation ? (
        <CheckboxButtonList>
          <CheckboxButton
            label={t("common:language-selector.ai-translate-label")}
            text={t("common:language-selector.ai-translate-text")}
            checked={onlyTranslate}
            onChange={() => setValue("translateAudioEnabled", false)}
            icon={<TranslateIcon />}
          />
          <CheckboxButton
            label={t("common:language-selector.ai-dubbing-label")}
            text={
              hasDubbingSupport
                ? t("common:language-selector.ai-dubbing-text-supported")
                : t("common:language-selector.ai-dubbing-text-not-supported")
            }
            icon={<TranslateAudio />}
            checked={!onlyTranslate}
            disabled={!hasDubbingSupport}
            onChange={() => setValue("translateAudioEnabled", true && hasDubbingSupport)}
          />
        </CheckboxButtonList>
      ) : (
        <LanguageSelectorEmptyCard>
          {enableTranslation ? <TranslationHand /> : <FileError />}
          <Text variant="body-2" color="grey-500">
            {enableTranslation
              ? t("common:language-selector.ai-translate-emptycard-supported")
              : t("common:language-selector.ai-translate-emptycard-not-supported")}
          </Text>
        </LanguageSelectorEmptyCard>
      )}
      {children}
    </SideForm.Form>
  );
}

/**
 * Performs fuzzy search on the native display name, English display name, and
 * country code individually, returning the max score of the three.
 */
export function getLanguageOptionSearchScore(option: AvailableLanguageOption, searchQuery: string) {
  const localizedName = getLocalizedLanguageName(option);
  return Math.max(
    stringScore(option.display, searchQuery),
    stringScore(localizedName, searchQuery),
    stringScore(option.code, searchQuery)
  );
}
