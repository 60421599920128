import { styled } from "~/theme";

export const LanguageSelectorEmptyCard = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  gap: "$16",
  padding: "16px 12px",
  backgroundColor: "$grey-800",
  borderRadius: "8px",
});
