import { useCallback, useEffect, useRef, useState } from "react";

import { useBackendServicesClient } from "~/context/BackendServicesContext";
import { parseSegmentTime } from "~/modules/command-list/utils/parseCommandListTimestamps";
import type { NonDeletedClip } from "~/modules/project/hooks/useClipSplitting";
import { getRelativeTimestamp } from "~/utils/videoClips";

import { getScenesJobStatus, startScenesJob } from "../services/Scenes/ScenesService";

export interface SceneSegment {
  startTime: number;
  endTime: number;
}

export const getRelativeSceneSegments = (
  remainingClips: NonDeletedClip[],
  scenes: number[],
  duration: number
): SceneSegment[] => {
  const clips: [number, number][] = remainingClips.map((c) => [
    c.absoluteStartTime,
    c.absoluteEndTime,
  ]);

  const relativeScenes = scenes.map((t) => getRelativeTimestamp(clips, t));
  const clipStarts = clips.map((c) => getRelativeTimestamp(clips, c[0]));
  const scenesWithClipStarts = [...relativeScenes, ...clipStarts].sort((a, b) => (a < b ? -1 : 1));
  return scenesWithClipStarts
    .map((start, i, arr) => {
      const end = arr[i + 1] ?? duration;
      return {
        startTime: start,
        endTime: end,
      };
    })
    .filter((seg) => seg.endTime > seg.startTime);
};

export const getSceneIndex = (sceneSegments: SceneSegment[], timestamp: number) => {
  if (!sceneSegments.length || sceneSegments[0]?.startTime > timestamp) {
    return 0;
  }
  const OFFSET = 0.001; // so that new clip split focuses scene to the right
  const indexFound = sceneSegments.findIndex(
    (seg) => timestamp + OFFSET >= seg.startTime && timestamp + OFFSET < seg.endTime
  );

  if (indexFound === -1) {
    return sceneSegments.length - 1;
  }

  return indexFound;
};

/** Delay in milliseconds before polling backend for status again. */
export const POLLING_INTERVAL = 500;

export type ScenesState = {
  status: "idle" | "started" | "canceled" | "error" | "finished" | "processing" | "progress";
  result?: number[];
  errorMessage?: string;
};

/**
 * Handles starting and polling an Video Scenes job on the backend.
 * Modelled after `useDubbing`.
 */
export function useScenes() {
  const abortControllerRef = useRef(new AbortController());
  const client = useBackendServicesClient();
  const [jobState, setJobState] = useState<ScenesState>({ status: "idle" });
  const [onFinished, setOnFinished] = useState<(scenes: number[]) => void>(() => () => {});

  useEffect(() => {
    if (jobState.status === "finished") {
      onFinished((jobState?.result ?? []).map((scene) => parseSegmentTime(scene)));
    }
  }, [jobState?.result, onFinished]);

  const handleError = (error: unknown) => {
    console.error(error);
    if (abortControllerRef.current.signal.aborted) {
      setJobState({ status: "canceled" });
    } else {
      setJobState({
        status: "error",
        errorMessage: error instanceof Error ? error.message : `${error}`,
      });
    }
  };

  const cancelScenes = () => {
    abortControllerRef.current?.abort();
    setJobState({ status: "canceled" });
  };

  const updateScenesJobStatus = useCallback((scenesJobId: string) => {
    getScenesJobStatus(client, scenesJobId, abortControllerRef.current.signal)
      .then((job) => {
        if (job.status === "started") {
          setTimeout(() => updateScenesJobStatus(scenesJobId), POLLING_INTERVAL);
        } else if (["processing", "progress"].includes(job.status)) {
          setJobState({
            status: "progress",
          });
          setTimeout(() => updateScenesJobStatus(scenesJobId), POLLING_INTERVAL);
        } else if (job.status === "finished") {
          if (!job.timestamps) {
            setJobState({
              status: "error",
              errorMessage: "Job finished, but missing result",
            });
          } else {
            if (job.timestamps.length === 0) {
              setJobState({
                status: "finished",
                result: [],
              });
            } else {
              setJobState({
                status: "finished",
                // prepend zero for start of a shot and shift frame forward slightly
                result: [0, ...job.timestamps.map((t) => t + 0.001)],
              });
            }
          }
        } else if (job.status === "error") {
          setJobState({
            status: "error",
            errorMessage: "An error occurred while processing",
          });
        } else {
          console.error(job);
        }
      })
      .catch(handleError);
  }, []);

  const startScenes = (sourceFileId: string, onFinished: (scenes: number[]) => void) => {
    setOnFinished(() => onFinished);

    if (!["idle", "finished", "error", "canceled"].includes(jobState.status)) {
      console.warn(`Scenes job already started with status ${jobState.status}`);
      return;
    }
    setJobState({ status: "started" });
    abortControllerRef.current = new AbortController();

    startScenesJob(client, { fileId: sourceFileId })
      .then((scenesJob) => {
        setTimeout(() => updateScenesJobStatus(scenesJob.sceneDetectJobId), POLLING_INTERVAL);
      })
      .catch(handleError);
  };

  return {
    scenesState: jobState,
    startScenes,
    cancelScenes,
  };
}
