import { getTransitionDuration } from "../../../project/utils/transitionUtils";
import { CommandList } from "../../CommandList.types";
import { EDLSegment } from "../../EDL.types";
import { calculateImagePositions } from "../../utils/calculateImagePositions";

import { EffectsCommandGeneratorFunction } from "./types";

export const generateImageCommandsForScene: EffectsCommandGeneratorFunction = (
  scene,
  sceneIndex,
  { collages, faceData, videoMetadata }
) => {
  const { imageRules } = scene.style;

  if (!imageRules || (imageRules.imageAnimations?.type ?? "classic") !== "classic") {
    return null;
  }

  const collageForScene = collages.find((c) => c.sceneIndex === sceneIndex)?.collage;
  if (!collageForScene) {
    return null;
  }

  const faceRect = faceData?.getSceneMaxRect(scene.startTime, scene.endTime, true);
  const images = calculateImagePositions(
    scene,
    collageForScene,
    imageRules,
    videoMetadata,
    faceRect,
    { invertedYAxis: true }
  );

  return images.flatMap((image) => {
    const duration = image.endTime - image.startTime;
    const offsetFromScene = image.startTime - scene.startTime;

    const mainStart = scene.mainOffset + offsetFromScene;
    const mainEnd = scene.mainOffset + offsetFromScene + duration;
    const localStart = 0;
    const localEnd = duration;
    const segments: EDLSegment[] = [[mainStart, mainEnd, localStart, localEnd]];

    const imageCommands: CommandList = [
      {
        edl: { segments },
        renderEffect: {
          renderEffectType: "image",
          assetId: image.imageUrl,
          position: image.position,
          positionType: imageRules.imagePositionType ?? "freeform",
          size: image.size,
          animations: imageRules.imageAnimations,
        },
      },
    ];

    if (imageRules.zoomStyle) {
      imageCommands.push({
        edl: { segments },
        renderEffect: {
          renderEffectType: "zoom",
          zoomStyle: imageRules.zoomStyle,
        },
      });
    }

    if (imageRules.imageFrame) {
      imageCommands.push({
        edl: {
          segments,
          wrapMode: "repeat",
        },
        renderEffect: {
          renderEffectType: "frame",
          assetId: imageRules.imageFrame,
        },
      });
    }

    if (imageRules.imageTransition) {
      const { assetId, volume } = imageRules.imageTransition;
      const tDuration = getTransitionDuration(assetId) || 1;

      imageCommands.push({
        edl: {
          segments: [[mainStart - tDuration / 2, mainStart + tDuration / 2, localStart, tDuration]],
          wrapMode: "repeat",
        },
        renderEffect: {
          renderEffectType: "transition",
          assetId,
          volume,
        },
      });
    }

    return imageCommands;
  });
};
