import path from "path";

import { useCallback, useState } from "react";

import { PROJECT_FFMPEG_ERROR } from "~/constants/mixpanel.constants";
import { useImageGenerator } from "~/context";
import { FilmStripPath, Project } from "~/database/database.types";
import { asyncQueueMap } from "~/utils/asyncQueueMap";

import { useMixpanel } from "./useMixpanel";

export interface SpriteUpdateResult {
  newlyGenerated: boolean;
  spriteSheetPaths: FilmStripPath[];
  spriteSheetPixelsPerSecond?: number;
}

export interface SpriteUrlItem {
  url: string;
  width: number;
}

export function useFilmSprite() {
  const [spriteError, setSpriteError] = useState<string | null>(null);
  const [filmStripUrls, setFilmStripUrls] = useState<SpriteUrlItem[]>();
  const imageGenerator = useImageGenerator();
  const mixpanel = useMixpanel();

  // This determines the width of the film strip. 114 will always give a width of 10032px for the film strip, regardless of aspect ratio or duration.
  const WIDTH_COEFFICIENT = 114;
  const MAX_FPS = 6;

  const getFPSFromDuration = (duration: number, aspectRatio: number) => {
    return WIDTH_COEFFICIENT / (duration * aspectRatio);
  };

  const createSprite = useCallback(
    async (
      project: Project,
      videoUrl: string,
      options?: { abortSignal?: AbortSignal; onProgress?: (progress: number) => void }
    ) => {
      const FRAME_HEIGHT = 88;
      const STRIP_SECTION_FRAME_COUNT = 100;
      const aspectRatio = project.sourceVideoMetadata.width / project.sourceVideoMetadata.height;
      const framesPerSecond = getFPSFromDuration(project.sourceVideoMetadata.duration, aspectRatio);
      const framesPerSecondCorrected = Math.min(framesPerSecond, MAX_FPS);

      const frameWidth = Math.round(FRAME_HEIGHT * aspectRatio);
      const pixelsPerSecond = frameWidth * framesPerSecondCorrected;
      const totalStripSize = project.sourceVideoMetadata.duration * pixelsPerSecond;
      const amountOfFrames = Math.ceil(totalStripSize / frameWidth);
      const stripIntervals = Array(Math.ceil(amountOfFrames / STRIP_SECTION_FRAME_COUNT))
        .fill(0)
        .map((_, index): [number, number] => [
          index * STRIP_SECTION_FRAME_COUNT,
          Math.min(amountOfFrames, (index + 1) * STRIP_SECTION_FRAME_COUNT),
        ]);
      try {
        const totalSprites = stripIntervals.length || 1;
        let generatedSpriteCount = 0;
        const spriteSheets = await asyncQueueMap(stripIntervals, 2, async (interval) => {
          options?.abortSignal?.throwIfAborted();
          const spriteSheet = await imageGenerator.generateFilmStrip({
            fileName: project?.title,
            fileId: project.sourceFileId,
            projectId: project.id,
            frameExtracted: amountOfFrames,
            stripInterval: interval,
            targetFrameSize: {
              targetWidth: frameWidth,
              targetHeight: FRAME_HEIGHT,
            },
            inputVideoPath: videoUrl,
          });

          if (spriteSheet.status === "error" || !spriteSheet.path) {
            const message = spriteSheet.message || "Failed to generate sprite sheet";
            console.error(spriteSheet);
            setSpriteError(message);
            throw new Error(message);
          }
          generatedSpriteCount++;
          options?.onProgress?.(generatedSpriteCount / totalSprites);

          return {
            path: spriteSheet.path,
            frameCount: interval[1] - interval[0],
          };
        });

        return {
          paths: spriteSheets.map((spriteSheet) => ({
            path: spriteSheet.path,
            width: spriteSheet.frameCount * frameWidth,
          })),
          pixelsPerSecond,
        };
      } catch (error) {
        console.error(error);
        if (error instanceof Error) {
          mixpanel.track(PROJECT_FFMPEG_ERROR, {
            message: error.message,
            fileId: project.id,
            transcriptionId: project.transcriptionJobId,
          });
        }
        throw error;
      }
    },
    [imageGenerator, mixpanel]
  );

  const getUrlsFromPaths = useCallback((paths: FilmStripPath[]): Promise<SpriteUrlItem[]> => {
    return Promise.all(
      paths.map(async (item) => {
        const url = await imageGenerator.getImageUrl(item.path);
        return {
          url,
          width: item.width,
        };
      })
    );
  }, []);

  const updateSprite = useCallback(
    async (project: Project, videoUrl: string): Promise<SpriteUpdateResult | null> => {
      if (
        project.filmStripPath &&
        (typeof project.filmStripPath !== "string" || !path.isAbsolute(project.filmStripPath))
      ) {
        const paths =
          typeof project.filmStripPath === "string"
            ? [{ path: project.filmStripPath, width: Number.MAX_VALUE }]
            : project.filmStripPath;
        try {
          const response = await getUrlsFromPaths(paths);
          setFilmStripUrls(response);
          return {
            newlyGenerated: false,
            spriteSheetPaths: paths,
            spriteSheetPixelsPerSecond: project.filmStripPixelsPerSecond,
          };
        } catch (error) {
          if (error instanceof Error) {
            mixpanel.track(PROJECT_FFMPEG_ERROR, {
              message: error.message,
              fileId: project.id,
              transcriptionId: project.transcriptionJobId,
            });
          }
        }
      }

      const spritePath = await createSprite(project, videoUrl);

      if (spritePath?.paths) {
        const response = await getUrlsFromPaths(spritePath.paths);
        setFilmStripUrls(response);
        return {
          newlyGenerated: true,
          spriteSheetPaths: spritePath.paths,
          spriteSheetPixelsPerSecond: spritePath.pixelsPerSecond,
        };
      }

      return null;
    },
    [createSprite, imageGenerator]
  );

  return {
    createSprite,
    spriteError,
    updateSprite,
    filmStripUrls,
  };
}
