import { z } from "zod";

export const createUploadResponseSchema = z.object({ fileId: z.string() });

export const uploadChunkResponseSchema = z.object({
  lastByte: z.number(),
  fileMetadata: z
    .object({
      fps: z.number().optional(),
    })
    .nullable()
    .optional(),
});
export type ChunkResponseData = z.infer<typeof uploadChunkResponseSchema>;

export const fileMetadataSchema = z.object({
  extension: z.string(),
  filename: z.string(),
  mimeType: z.string(),
  size: z.number(),
  fps: z.number().optional(),
  duration: z.number().optional(),
  width: z.number().optional(),
  height: z.number().optional(),
  codec_name: z.string().optional(),
  source: z.enum(["DEFAULT", "LIPDUB"]).optional(),
});
export type FileMetadata = z.infer<typeof fileMetadataSchema>;

export const fileStatusSchema = z.enum(["UPLOADING", "READY", "DELETED", "ERROR"] as const);
export const fileTypeSchema = z.enum(["VIDEO", "THUMBNAIL", "AUDIO"] as const);
export const planTypeSchema = z.enum(["FREE"] as const);

export const getUploadedFileUrlSchema = z.object({
  fileId: z.string(),
  type: fileTypeSchema,
  status: fileStatusSchema,
  createdAt: z.string(),
  deletedAt: z.string().nullable(),
  plan: planTypeSchema,
  fileMetadata: fileMetadataSchema,
  lastByte: z.number(),
  url: z.string(),
  hlsUrl: z.string().optional(),
  gcsUri: z.string().optional(),
});

export type GetUploadedFileUrlResponse = z.infer<typeof getUploadedFileUrlSchema>;

export const getShareFileUrlSchema = z.object({
  url: z.string(),
  hlsUrl: z.string().optional(),
});

export type GetShareFileUrlResponse = z.infer<typeof getShareFileUrlSchema>;

export const createMuxUrlSchema = z.object({
  status: fileStatusSchema,
});

export type CreateMuxUrlResponse = z.infer<typeof createMuxUrlSchema>;
