import React from "react";

import { Button } from "../Button";
import CaptionsDesktopLogo from "../icons/CaptionsDesktopLogo";
import { Text } from "../Text";

import * as S from "./SignIn.styles";
import { SignInProps } from "./SignIn.types";
import * as B from "./SignInButtons";

export default function SignInLayoutContent({
  title,
  onAppleClick,
  onGoogleClick,
  onMicrosoftClick,
  onContinueAnotherWayClick,
  areActionsBlocked,
}: SignInProps) {
  return (
    <S.Container>
      <CaptionsDesktopLogo />
      <S.Top>
        <Text color="grey-200" variant="heading-4-medium">
          {title || "Your AI powered creative studio"}
        </Text>
      </S.Top>
      <S.ButtonsContainer>
        {onGoogleClick && <B.GoogleButton onClick={onGoogleClick} disabled={areActionsBlocked} />}
        {onMicrosoftClick && (
          <B.MicrosoftButton onClick={onMicrosoftClick} disabled={areActionsBlocked} />
        )}
        {onAppleClick && <B.AppleButton onClick={onAppleClick} disabled={areActionsBlocked} />}
        <Button
          variant="tertiary"
          fullWidth
          onClick={onContinueAnotherWayClick}
          disabled={areActionsBlocked}
        >
          Continue another way
        </Button>
      </S.ButtonsContainer>
    </S.Container>
  );
}
