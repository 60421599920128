import { StoredAsset } from "~/database/database.types";

import { AssetInput, AssetOutput, FixedAsset } from "./assetUtils.types";
import { fetchAssetBlob } from "./common";
import { loadTarFrameSequenceAsset } from "./frameSequence";
import { loadUnpackedVideoAsset } from "./unpackedVideo";

/**
 * Creates an ImageBitmap from the given asset blob.
 * @param blob - The asset to create the ImageBitmap from.
 */
export function createAssetImage(blob: Blob, options?: ImageBitmapOptions): Promise<ImageBitmap> {
  return createImageBitmap(blob, {
    colorSpaceConversion: "none",
    ...options,
  });
}

/**
 * Loads an image from the given blob.
 * @param blob - The asset to create the image from.
 */
export function loadAssetImage(blob: Blob): Promise<HTMLImageElement> {
  const image = new Image();
  return new Promise((resolve, reject) => {
    image.onload = () => {
      resolve(image);
    };
    image.onerror = reject;
    image.src = URL.createObjectURL(blob);
  });
}

/**
 * Loads a fixed asset and returns a StoredAsset object.
 * @param id - The ID of the asset.
 * @param asset - The asset to load.
 */
export async function loadFixedAsset(
  id: string,
  asset: FixedAsset
): Promise<StoredAsset & { drawAs?: FixedAsset["drawAs"] }> {
  if (asset.type === "unpacked-video") {
    // If the override exists and is an unpacked video, create an object with the frames.
    return loadUnpackedVideoAsset(id, asset);
  }
  if (asset.type === "tar-frames") {
    // If the override exists and is a tar file, create an object with the frames.
    return loadTarFrameSequenceAsset(id, asset);
  }
  // If the override exists, fetch the file and create an StoredImage object.
  const blob = await fetchAssetBlob(asset.url);
  return {
    id,
    name: asset.url.toString(),
    blob,
    type: asset.type,
    drawAs: asset.drawAs,
  };
}

const CDN_BASE_URL = "https://captions-cdn.xyz/desktop-site/ai-edit-assets";

/**
 * Creates a map of assets from the given input.
 * @param folder - The folder where the assets are stored.
 * @param assets - The assets to create the map from.
 * @param baseURL - The base URL of the assets.
 */
export function createAssetMap<const T extends AssetInput>({
  folder,
  assets,
  baseURL = {
    url: CDN_BASE_URL,
    isLocal: false,
  },
}: {
  folder: string;
  assets: T;
  baseURL?: {
    url: string;
    isLocal: boolean;
  };
}): AssetOutput<T> {
  const result = {} as AssetOutput<T>;
  for (const [baseKey, definition] of Object.entries(assets)) {
    const variants = definition.variants || [""];
    for (const variant of variants) {
      const variantSuffix = variant ? `_${variant}` : "";
      const resolutionSuffix = definition.resolution ? `_${definition.resolution}` : "";
      const extension = definition.extension || (definition.type === "image" ? "png" : "tar");

      // TODO: Check if the resolution should be present in the key, as we can support multiple resolutions.
      const key = `${baseKey}${variantSuffix}`;
      const filename = definition.filename || `${key}${resolutionSuffix}.${extension}`;

      const relativeURL = `${folder}/${filename}`;

      const url = baseURL.isLocal
        ? new URL(`${baseURL.url}/${relativeURL}`, import.meta.url)
        : new URL(relativeURL, baseURL.url);

      result[key as keyof AssetOutput<T>] = {
        url,
        type: definition.type,
        drawAs: definition.drawAs,
      } as AssetOutput<T>[keyof AssetOutput<T>];
    }
  }

  return result;
}
