import { Command } from "../../CommandList.types";
import {
  calculateImagePositions,
  calculateStaticImagePositions,
} from "../../utils/calculateImagePositions";
import {
  AIEditAnimation,
  getAnimationsWithReplacement,
  getImageAnimation,
  getStaticImageAnimation,
} from "../../utils/getAnimationsWithReplacement";

import { EffectsCommandGeneratorFunction } from "./types";

export const generateAnimationCommandsForScene: EffectsCommandGeneratorFunction = (
  scene,
  sceneIndex,
  { collages, faceData, videoMetadata }
) => {
  const processedAnimations: AIEditAnimation[] = [];
  const animationWithReplacements = getAnimationsWithReplacement(scene) ?? [];
  processedAnimations.push(...animationWithReplacements);

  const imageRules = scene.style.imageRules;
  const staticImageRules = scene.style.staticImageRules;

  if (staticImageRules?.imageAnimations?.type === "classic") {
    const imagesWithPositions = calculateStaticImagePositions(scene, staticImageRules, {
      invertedYAxis: true,
    });
    const imageCommands = imagesWithPositions.map((image) => {
      const { startTime, endTime } = image;
      const duration = endTime - startTime;
      const offsetFromScene = startTime - scene.startTime;

      return {
        edl: {
          segments: [
            [
              scene.mainOffset + offsetFromScene,
              scene.mainOffset + offsetFromScene + duration,
              0,
              duration,
            ],
          ],
        },
        renderEffect: {
          renderEffectType: "image",
          assetId: image.assetId,
          position: image.position,
          rotation: image.rotation,
          staticImage: true,
          positionType: "freeform",
          animations: staticImageRules?.imageAnimations,
          isBackground: staticImageRules?.backgroundInfo?.[image.assetId],
        },
      } satisfies Command;
    });

    return imageCommands;
  }

  if (staticImageRules?.imageAnimations?.type === "pag") {
    const imagesWithPositions = calculateStaticImagePositions(scene, staticImageRules);
    processedAnimations.push(
      ...imagesWithPositions.map((image) => getStaticImageAnimation(image, staticImageRules))
    );
  }

  if (imageRules?.imageAnimations?.type === "pag") {
    const collageItemsForScene = collages.find((c) => c.sceneIndex === sceneIndex)?.collage ?? [];
    const faceRect = faceData?.getSceneMaxRect(scene.startTime, scene.endTime, true);

    const images = calculateImagePositions(
      scene,
      collageItemsForScene,
      imageRules,
      videoMetadata,
      faceRect
    );
    processedAnimations.push(...images.map((image) => getImageAnimation(image, imageRules)));
  }

  if (!processedAnimations.length) {
    return null;
  }

  return processedAnimations.map((animation) => {
    const { startTime, endTime } = animation;
    const duration = endTime - startTime;
    const offsetFromScene = startTime - scene.startTime;
    return {
      edl: {
        segments: [
          [
            scene.mainOffset + offsetFromScene,
            scene.mainOffset + offsetFromScene + duration,
            0,
            duration,
          ],
        ],
      },
      renderEffect: {
        renderEffectType: "overlay-animation",
        introAssetId: animation.introAssetId,
        outroAssetId: animation.outroAssetId,
        holdAssetId: animation.holdAssetId,
        textReplacements: animation.textReplacements,
        imageReplacements: animation.imageReplacements,
        positioning: animation.positioning,
      },
      blendMode: "source-over",
    } satisfies Command;
  });
};
