import { cubicBezier } from "./utils/bezier";
import { PixelMapping } from "./utils/webgl/webgl.types";
import { ZoomFocalPoint, ZoomPoint, ZoomStyle } from "./zoomController.types";

export class ZoomController {
  private _zoomPoints: ZoomPoint[] = [];

  public setZoomPoints(zoomPoints: ZoomPoint[]) {
    this._zoomPoints = zoomPoints;
  }

  private getZoomAttributes(zoomStyle: ZoomStyle, focal?: ZoomFocalPoint) {
    let zoomLevel;
    let animationCurve;
    let focalPoint;
    let direction;
    switch (zoomStyle) {
      case "continuous":
        zoomLevel = 1.2;
        animationCurve = "easeInEaseOut";
        focalPoint = focal;
        direction = "in";
        break;
      case "camera-shake":
        /*
         * TODO: Adjust how we define zooms:
         * The zoom level from iOS is 1.1, but we are using 1.25 to make the effect look more similar
         * applying 1.1 won't match the iOS effect
         */
        zoomLevel = 1.25;
        animationCurve = "instant";
        direction = "in";
        break;
      case "center-zoom-out":
        zoomLevel = 1.2;
        animationCurve = "linear";
        direction = "out";
        break;
      case "extreme-step-in":
        zoomLevel = 3;
        animationCurve = "step";
        focalPoint = focal;
        direction = "in";
        break;
      case "step-in":
        zoomLevel = 1.2;
        animationCurve = "step";
        focalPoint = focal;
        direction = "in";
        break;
      case "instant":
        zoomLevel = 1.2;
        animationCurve = "instant";
        focalPoint = focal;
        direction = "in";
        break;
      case "center-zoom-in":
      default:
        zoomLevel = 1.2;
        animationCurve = "linear";
        direction = "in";
    }
    return { zoomLevel, animationCurve, focalPoint, direction };
  }

  /**
   * Returns the pixel mappings with zoom applied.
   *
   * @param mappings - The pixel mappings to zoom.
   * @param timestamp - The current time in the video.
   * @returns The zoomed pixel mappings.
   */
  public getCurrentZoomPixelMappings(
    mappings: PixelMapping[],
    timestamp: number,
    videoDims: { width: number; height: number }
  ): PixelMapping[] {
    const currentZoom = this._zoomPoints?.find((zoomPoint) => {
      const zoomStart = zoomPoint.timestamp;
      const zoomEnd = zoomStart + zoomPoint.duration;
      return timestamp >= zoomStart && timestamp <= zoomEnd;
    });

    if (!currentZoom) {
      return mappings;
    }
    const zoomedMappings = mappings.map((pixelMapping) =>
      this.getZoomedPixelMapping(currentZoom, pixelMapping, timestamp, videoDims)
    );
    return zoomedMappings;
  }

  public getZoomedPixelMapping(
    zoomPoint: ZoomPoint,
    pixelMapping: PixelMapping,
    timestamp: number,
    videoDims: { width: number; height: number }
  ) {
    const {
      timestamp: zoomStart,
      duration: zoomDuration,
      focalPoint: focal,
      zoomStyle,
    } = zoomPoint;

    const { zoomLevel, animationCurve, focalPoint, direction } = this.getZoomAttributes(
      zoomStyle,
      focal
    );

    const timeScaleFactor = (() => {
      const progress =
        direction === "out"
          ? 1 - (timestamp - zoomStart) / zoomDuration
          : (timestamp - zoomStart) / zoomDuration;
      switch (animationCurve) {
        case "linear":
          return 1 + (zoomLevel - 1) * progress;
        case "easeInEaseOut":
          return cubicBezier(progress, 1, 1, zoomLevel, zoomLevel);
        case "step":
          if (progress < 0.5) {
            return 1;
          } else if (progress < 0.75) {
            return 1 + (zoomLevel - 1) * 0.5;
          } else {
            return zoomLevel;
          }
        case "instant":
        default:
          return zoomLevel;
      }
    })();
    const scaleFactor = 1 / timeScaleFactor;

    const pixelMapWidth = pixelMapping.from.right - pixelMapping.from.left;
    const pixelMapHeight = pixelMapping.from.bottom - pixelMapping.from.top;

    const centerX = pixelMapping.from.left + pixelMapWidth / 2;
    const centerY = pixelMapping.from.top + pixelMapHeight / 2;

    const [offsetX, offsetY] = (() => {
      if (focalPoint) {
        const pixelMapFocalX = pixelMapWidth * (focalPoint.x / videoDims.width);
        const pixelMapFocalY = pixelMapHeight * (focalPoint.y / videoDims.height);

        const _offsetX = pixelMapFocalX - centerX;
        const _offsetY = pixelMapFocalY - centerY;
        return [_offsetX, _offsetY];
      } else {
        return [0, 0];
      }
    })();

    const zoomedLeft = centerX - (scaleFactor * pixelMapWidth) / 2 + offsetX;
    const zoomedRight = centerX + (scaleFactor * pixelMapWidth) / 2 + offsetX;

    const zoomedTop = centerY - (scaleFactor * pixelMapHeight) / 2 + offsetY;
    const zoomedBottom = centerY + (scaleFactor * pixelMapHeight) / 2 + offsetY;

    // adjust so that zoom coords are not out of pixel map bounds
    const pixelMapBounds = pixelMapping.from;

    let adjustedLeft = zoomedLeft;
    let adjustedRight = zoomedRight;
    let adjustedTop = zoomedTop;
    let adjustedBottom = zoomedBottom;

    if (zoomedLeft < pixelMapBounds.left) {
      const distanceOutOfBounds = Math.abs(zoomedLeft - pixelMapBounds.left);
      adjustedRight += distanceOutOfBounds;
      adjustedLeft += distanceOutOfBounds;
    } else if (zoomedRight > pixelMapBounds.right) {
      const distanceOutOfBounds = Math.abs(zoomedRight - pixelMapBounds.right);
      adjustedRight -= distanceOutOfBounds;
      adjustedLeft -= distanceOutOfBounds;
    }

    if (zoomedTop < pixelMapBounds.top) {
      const distanceOutOfBounds = Math.abs(zoomedTop - pixelMapBounds.top);
      adjustedBottom += distanceOutOfBounds;
      adjustedTop += distanceOutOfBounds;
    } else if (zoomedBottom > pixelMapBounds.bottom) {
      const distanceOutOfBounds = Math.abs(zoomedBottom - pixelMapBounds.bottom);
      adjustedBottom -= distanceOutOfBounds;
      adjustedTop -= distanceOutOfBounds;
    }

    return {
      from: {
        left: adjustedLeft,
        top: adjustedTop,
        right: adjustedRight,
        bottom: adjustedBottom,
      },
      to: pixelMapping.to,
    };
  }

  public destroy() {
    this._zoomPoints = [];
  }
}
