import { z } from "zod";

/**
 * Describes a language option available for transcription.
 *
 * @property {string} code - The language code.
 * @property {string} display - The language's display name.
 * @property {string} flag - The language's flag.
 */
export type AvailableLanguageOption = z.infer<typeof availableLanguageOptionSchema>;
export const availableLanguageOptionSchema = z.object({
  code: z.string(),
  display: z.string(),
  countryFlag: z.string(),
  englishDisplayName: z.string().optional(),
  isWordsDirectionRightToLeft: z.boolean().optional(),
  isAudioDubbingAvailable: z.boolean().optional(),
  isShortsAvailable: z.boolean().optional(),
  script: z.string().optional(),
});

export type AvailableLanguageOptionsResponse = z.infer<
  typeof availableLanguageOptionsResponseSchema
>;
export const availableLanguageOptionsResponseSchema = z.object({
  form: z.object({
    options: z.array(availableLanguageOptionSchema),
  }),
});
