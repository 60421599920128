import * as ToggleGroup from "@radix-ui/react-toggle-group";

import { Text, Skeleton } from "~/components";
import { availableColorSchemeVariantStyles, styled } from "~/theme";

export const CaptionTemplatePanelIcons = styled("div", {
  position: "absolute",
  right: "10px",
  top: "10px",
  alignItems: "center",
  "& > * button": {
    backgroundColor: "$grey-900",
  },
  // special case darker bg on hover, otherwise matches parent bg
  "& > * button:hover": {
    backgroundColor: "$grey-950",
    "--button-background-color": "transparent !important",
    "--smooth-border-color": "transparent !important",
  },
  display: "flex",
  justifyContent: "center",
  variants: {
    multipleIcons: {
      true: {
        "& > div > button": {
          borderRadius: 0,
        },
        "& > div:first-child > button": {
          borderBottomLeftRadius: 6,
          borderTopLeftRadius: 6,
        },
        "& > div:last-child > button": {
          borderBottomRightRadius: 6,
          borderTopRightRadius: 6,
        },
      },
    },
  },
});

export const CaptionTemplatePanelToggleItem = styled(ToggleGroup.Item, {
  display: "flex",
  flex: "none",
  flexDirection: "column",
  position: "relative",
  backgroundColor: "$grey-800",
  borderRadius: "$8",
  padding: 0,
  overflow: "hidden",
  border: "1px solid $grey-800",
  "&:after": {
    content: " ",
    position: "absolute",
    inset: 0,
    pointerEvents: "none",
    borderRadius: "$8",
  },
  "&:active, &:hover": {
    boxShadow: "0px 12px 32px 0px #00000036",
    "&:after": {
      border: "1px solid var(--primary-color)",
    },
  },
  variants: {
    selected: {
      true: {
        "&:after": {
          border: "1px solid var(--primary-color)",
        },
      },
    },
    colorScheme: availableColorSchemeVariantStyles,
  },
  defaultVariants: {
    colorScheme: "purple",
  },
});

export const CaptionsTemplatePanelItemPreviewWrapper = styled("div", {
  width: "100%",
  minHeight: "104px",
  position: "relative",
});

export const CaptionsTemplatePanelItemPreview = styled("canvas", {
  position: "absolute",
  top: "0px",
  left: "0px",
  width: "100%",
  height: "100%",
});

export const CaptionsTemplatePanelItemDescription = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  height: "48px",
  width: "100%",
  padding: "2px 16px",
  gap: "4px",
  backgroundColor: "$grey-900",
});

export const CaptionTemplatePanelItemName = styled(Text, {
  fontSize: "$11",
  defaultVariants: {
    variant: "body-2-bold",
    color: "grey-150",
  },
});

const flexParams = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: 6,
};

export const CaptionTemplatePanelItemLeft = styled("div", flexParams);

export const CaptionTemplatePanelItemColors = styled("div", {
  ...flexParams,
  gap: 2,
  "& > button": {
    padding: "0px",
  },
});

export const CaptionTemplatePanelItemViews = styled(CaptionTemplatePanelItemLeft, {
  borderLeft: "1px solid $grey-700",
  paddingLeft: 6,
  fontSize: "$11",
  color: "$grey-400",
  gap: 2,
});

export const CaptionTemplatePanelColorSwatch = styled("div", {
  width: "20px",
  height: "20px",
  borderRadius: "$6",
  border: "2px solid $grey-950",
});

export const CaptionTemplatePanelItemContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  position: "relative",
  width: "100%",
  [`${CaptionTemplatePanelIcons}`]: {
    display: "none",
  },
  "&:hover, &:focus": {
    [`${CaptionTemplatePanelIcons}`]: {
      display: "flex",
    },
  },
});

export const CaptionTemplatePanelItemPreviewSkeleton = styled(Skeleton, {
  position: "absolute",
  top: "1px",
  left: "1px",
  width: "calc(100% - 2px) !important",
  height: "calc(100% - 1px) !important",
  borderTopLeftRadius: "$8",
  borderTopRightRadius: "$8",
  zIndex: 1,
});
