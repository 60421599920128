import { Transition } from "~/database/transition.types";
import { Clip, getAbsoluteTimestamp } from "~/utils/videoClips";

/**
 * Converts transitions with **relative timestamps** (relative to project time) to **absolute
 * timestamps** (relative to source video time - not taking deleted clips into account).
 * @param clips - The clips in the project.
 * @param transitions - The transitions with **relative timestamps**.
 * @returns The transitions with **absolute timestamps**.
 * @see getAbsoluteTimestamp
 */
export function getAbsoluteTransitions(clips: Clip[], transitions: Transition[]): Transition[] {
  return transitions.map(({ startTime, ...transition }) => ({
    ...transition,
    startTime: getAbsoluteTimestamp(clips, startTime),
  }));
}
