import { PseudoRandomGenerator } from "stable-pseudo-rng";

import { randPick } from "~/utils/rand-pick";

import { getTransitionDuration } from "../../../project/utils/transitionUtils";
import { AISceneWithStyle } from "../../AIScenes.types";
import { Command, CommandList } from "../../CommandList.types";
import { EDLdata } from "../../EDL.types";
import { ClientDrivenAIEditVideoStyle } from "../../services/AIEditStyles";

export const generateGlobalTransitionCommands = (
  style: ClientDrivenAIEditVideoStyle,
  scenes: AISceneWithStyle[],
  prng: PseudoRandomGenerator
): CommandList => {
  const transitionConfig = style.transitionConfig;
  const { library: transitionLibrary, builder: transitionBuilder } = transitionConfig;

  // we do not add a transition at the beginning of the first scene
  return scenes.slice(1).flatMap((scene, slicedIndex) => {
    const sceneIndex = slicedIndex + 1;
    const currentSceneId = scene.style.id;
    const prevSceneId = scenes[sceneIndex - 1].style.id;
    const possibleTransitions = transitionBuilder(prevSceneId, currentSceneId);

    const transitionId = randPick(possibleTransitions, prng.next());
    const transition = transitionLibrary.find((transition) => transition.assetId === transitionId);
    if (!transition) {
      return [];
    }

    const { assetId, volume, outDuration, inDuration } = transition;

    const tStart = scene.mainOffset;
    const tDuration = getTransitionDuration(assetId) || 1;

    const { tDurationPrevScene, tDurationCurrentScene } =
      outDuration && inDuration
        ? { tDurationPrevScene: outDuration, tDurationCurrentScene: inDuration }
        : { tDurationPrevScene: tDuration / 2, tDurationCurrentScene: tDuration / 2 };

    const edl: EDLdata = {
      segments: [
        [
          tStart - tDurationPrevScene,
          tStart + tDurationCurrentScene,
          0,
          tDurationPrevScene + tDurationCurrentScene,
        ],
      ],
    };

    const command: Command = {
      edl,
      renderEffect: {
        renderEffectType: "transition",
        assetId,
        volume,
      },
    };

    return [command];
  });
};
