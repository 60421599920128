import { Clip } from "~/utils/videoClips/videoClips.types";

import { TimelineLayerItem_Id } from "../types/layer-item";
import { SourceAssetVideoDescription } from "../types/source-asset";
import { createTimelineBlock } from "../types/timeline-block";

function clipHasMergeableNeighbor(clips: Clip[], index: number) {
  if (index >= clips.length - 1) {
    return false;
  }

  const clip1 = clips[index];
  const clip2 = clips[index + 1];

  // TODO: (follow up) maybe have tolerance for small time difference
  return clip1.mergeWithNext && clip1.endTime === clip2.startTime && !clip2.deleted;
}

/**
 * 
 * we convert the clips into timeline blocks (and a source asset).
 * we ignore deleted clips since we do not need them to skip over like the
 * old system. additionally, we can merge compatible clips into one if
 * point to the same source asset and align in time.
 * 
  ┌─────────┐
  │  clips  │
  └─────────┘
  ┌──────────────────┬─────────────┬──────────────────┬─────────┐
  │start: 0          │start: 20    │start: 35         │start: 55│
  │end: 20           │end: 35      │end: 55           │end: 65  │
  │                  │* deleted    │* mergeWithNext   │         │
  └──────────────────┴─────────────┴──────────────────┴─────────┘


  ┌────────────┐   ┌──────────┐
  │ asset pool │   │  blocks  │
  └────────────┘   └──────────┘
  ┌────────────┐   ┌──────────────────┐┌──────────────────┐
  │ ┌─────┐    │   │shot 1            ││shot 2            │
  │ │vid 1│    │   │vid 1             ││vid 1             │
  │ └─────┘    │   │trim: [0, 20]     ││trim: [35, 65]    │
  └────────────┘   └──────────────────┘└──────────────────┘
*/
export function convertProjectClipsToTimelineBlocks(
  sourceVideo: SourceAssetVideoDescription,
  clips: Clip[]
) {
  const clipsToConvert: Omit<Clip, "mergeWithNext" | "deleted">[] = [];

  for (let i = 0; i < clips.length; i++) {
    if (clips[i].deleted) {
      continue;
    }

    if (clips[i].mergeWithNext) {
      let currentClipIndex = i;

      while (clipHasMergeableNeighbor(clips, currentClipIndex)) {
        currentClipIndex++;
      }

      if (currentClipIndex === i) {
        // no valid neighbors to merge with found
        clipsToConvert.push(clips[i]);
      } else {
        // combine into one clip
        clipsToConvert.push({
          startTime: clips[i].startTime,
          endTime: clips[currentClipIndex].endTime,
          reframe: clips[i].reframe,
        });
      }

      i = currentClipIndex;
    } else {
      clipsToConvert.push(clips[i]);
    }
  }

  const blocks = clipsToConvert.map((clip, idx) =>
    createTimelineBlock("video", {
      id: TimelineLayerItem_Id(`clip_imported_${idx}`),
      sourceAssetId: sourceVideo.id,
      reframe: clip.reframe,
      trim: {
        type: "independent",
        sourceStartTime: clip.startTime,
        sourceEndTime: clip.endTime,
      },
    })
  );

  if (blocks.length === 0) {
    blocks.push(
      createTimelineBlock("video", {
        id: TimelineLayerItem_Id(`clip_imported_main`),
        sourceAssetId: sourceVideo.id,
        trim: {
          type: "independent",
          sourceStartTime: 0,
          sourceEndTime: sourceVideo.videoMetadata.duration,
        },
      })
    );
  }

  return blocks;
}
