import { createAssetMap } from "../../../utils/assetUtils";

export const FILM_BORDER_A_URL = new URL(
  "https://captions-cdn.xyz/desktop-site/ai-edit-assets/cinematic_filmBorder-vintage_a.tar"
);
export const FILM_BORDER_B_URL = new URL(
  "https://captions-cdn.xyz/desktop-site/ai-edit-assets/cinematic_filmBorder-vintage_b.tar"
);
export const FILM_BORDER_C_URL = new URL(
  "https://captions-cdn.xyz/desktop-site/ai-edit-assets/cinematic_filmBorder-vintage_c.tar"
);

export const FRAME_BORDER_SCRAPBOOK_ASSETS = createAssetMap({
  folder: "ai-edit-assets/scrapbook_assets/frame_borders",
  assets: {
    // https://captions-cdn.xyz/desktop-site/ai-edit-assets/scrapbook_assets/frame_borders/marker_frame_clean_pink_1080p.tar
    marker_frame_clean: {
      type: "tar-frames",
      // todo: missing 1080p yellow variant
      variants: ["pink", "blue", "green", "purple"],
      resolution: "1080p",
      drawAs: "imageBitmap",
    },

    talkinghead_or_broll_1: {
      type: "tar-frames",
      variants: ["pink", "blue", "green", "purple", "yellow"],
      resolution: "1080p",
    },
    talkinghead_or_broll_2: {
      type: "tar-frames",
      variants: ["pink", "blue", "green", "purple", "yellow"],
      resolution: "1080p",
    },
    talkinghead_or_broll_3: {
      type: "tar-frames",
      variants: ["pink", "blue", "green", "purple", "yellow"],
      resolution: "1080p",
    },
    talkinghead_or_broll_4: {
      type: "tar-frames",
      variants: ["pink", "blue", "green", "purple", "yellow"],
      resolution: "1080p",
    },
    talkinghead_or_broll_5: {
      type: "tar-frames",
      variants: ["pink", "blue", "green", "purple", "yellow"],
      resolution: "1080p",
    },
    talkinghead_and_broll_1: {
      type: "tar-frames",
      variants: ["pink", "blue", "green", "purple", "yellow"],
      resolution: "1080p",
    },
    talkinghead_and_broll_2: {
      type: "tar-frames",
      variants: ["pink", "blue", "green", "purple", "yellow"],
      resolution: "1080p",
    },
    talkinghead_and_broll_3: {
      type: "tar-frames",
      variants: ["pink", "blue", "green", "purple", "yellow"],
      resolution: "1080p",
    },
  },
});
