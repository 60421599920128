import { PropsWithChildren, ReactElement } from "react";

import { Button } from "../Button";

import { SideFormButtonBar, SideFormContainer } from "./SideForm.styles";
import { SideFormProps } from "./SideForm.types";

export function SideForm({
  children,
  submitLabel,
  fullWidthSubmit,
  cancelLabel,
  cancelUrl,
  submitDisabled,
  onCancel,
  ...props
}: PropsWithChildren<SideFormProps>): ReactElement {
  const hasActions = !!submitLabel || !!onCancel || !!cancelLabel || !!cancelUrl;
  return (
    <SideFormContainer {...props}>
      {children}
      {hasActions && (
        <SideFormButtonBar fullWidth={fullWidthSubmit}>
          {submitLabel && (
            <Button variant="primary" type="submit" disabled={submitDisabled} fullWidth="true">
              {submitLabel}
            </Button>
          )}
        </SideFormButtonBar>
      )}
    </SideFormContainer>
  );
}

export const Form = SideForm;
